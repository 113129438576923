
function displayHelper () {
	this.updateCompareType = function(){
		var divs = $('.compareType');
		divs.hide('slow');
		// read the values and append to header
		var compareType = $('input[name=compare-type]:checked').val();
		if(compareType == 'dual'){
			var appendText = '( dual fuel )';
			var supplyType = $('input[name=supply-type]:checked').val();
			if(supplyType != 'dual'){
			//	appendText += 'single-supplier )';
				$('#mutliSupplier').hide();
			}
		}
		else{
			var appendText = '( '+compareType+' only )';
		}
		$('#compareTypeAppendText').html(appendText);
		this.setSupplierVisibility();
	},
	this.setSupplierVisibility = function(){
		var comparetype = $("input[name=compare-type]:checked").val();
		var mutlisupplier = $("input[name=supply-type]:checked").val();
		if(comparetype == 'dual'){
			if(mutlisupplier == 'dual'){
				$('#currentSupplier-dual').show();
				$('#currentSupplier-dual h3').siblings().show('slow');
				$('.supplierselect').each(function(){
					if(this.id != 'currentSupplier-dual'){
						$(this).hide();
					}
				});
			}
			if(mutlisupplier == 'single'){
				$('#currentSupplier-dual').hide();
				$('#currentSupplier-gas').show();
				$('#currentSupplier-gas h3').siblings().show('slow');
			}
		}
		if(comparetype == 'electricity' || comparetype == 'gas'){
			$('#currentSupplier-'+comparetype).show();
			$('#currentSupplier-'+comparetype+' h3').siblings().show('slow');
			$('.supplierselect').each(function(){
				if(this.id != 'currentSupplier-'+comparetype){
					$(this).hide();
				}
			});
		}
	},
	this.updateCurrentSupplier = function(type,callback){
		$('#currentSupplier-'+type+' h3').siblings().hide('slow');
		var compareType = $("input[name=compare-type]:checked").val();
		this.updateSupplySummary(type);
		if(type == 'gas' && compareType == 'dual'){
			$('#currentSupplier-electricity').show('slow');
			$('#currentUsage').hide();
			callback({stage:'current_supply'});
		}
		else{
			var mutlisupplier = $("input[name=supply-type]:checked").val();
			if(compareType === 'dual' && mutlisupplier === 'single'){
				// this will be my comparison of payment types condition 
				// DONT let the user proceed if we are comparing two meter types.
				// EG : one supplier is prepay and the other is normal billable
				var gas_payment = $("#gas-payment-option option:selected").val();
				var electric_payment = $("#electricity-payment-option option:selected").val();
				if((gas_payment != electric_payment) && (gas_payment == 3 || electric_payment  == 3 )){
					$('#currentSupplier-electricity').show();
					$('#currentSupplier-electricity h3').siblings().show();
					$('#currentSupplier-electricity').show();
					alert('Two meter types detected -> Please run two seperate quotes.');
					callback({error:true});
				}
				else{
					if($('#warmHomeDiscountSection').length){
						$('#warmHomeDiscountSection').show('slow');
						callback({stage: 'warm_home_discount'});
					}
					else{
						if($('#smartMeterSection').length == 0) {
							window.lead_script.handleStep($("input[name ^='currentSupplierContinue-']").data('success-step'));
						}
						else {
							$('#smartMeterSection').show('slow');
							callback({stage: 'smart_meter'});
						}
					}
				}
					
			}
			else{
				if($('#warmHomeDiscountSection').length){
					$('#warmHomeDiscountSection').show('slow');
					callback({stage: 'warm_home_discount'});
				}
				else{
					if($('#smartMeterSection').length == 0) {
						window.lead_script.handleStep($("input[name ^='currentSupplierContinue-']").data('success-step'));
					}
					else {
						$('#smartMeterSection').show('slow');
						callback({stage: 'smart_meter'});
					}
				}
			}
			
		}
	},
	this.updateSupplySummary = function(type){
		var appendText =  '( '+$('#'+type+'-supplierSelect option:selected').text()+', ';
		appendText +=  $('#'+type+'-tariffSelect option:selected').text()+', ';
		appendText += $('#'+type+'-payment-option option:selected').text()+' )';
		$('.supplier-name').text($('#'+type+'-supplierSelect option:selected').text());
		$('#currentSupplierAppendText-'+type).html(appendText.toLowerCase());
	},
	this.updateUsageInputs = function(type){
		if(type != 'dual'){
			if(type == 'electricity'){
				$('#electricity-current-usage').show();
				$('#gas-current-usage').hide();
			}
			else{
				$('#electricity-current-usage').hide();
				$('#gas-current-usage').show();
				$('.eco7').hide();
			}
		}
		else{
			$('#electricity-current-usage').show();
			$('#gas-current-usage').show();
		}
	},
	this.displayTariffs = function(type,tariffs){
		$('body #tariffList_'+type).html(''); // truncate the existing tariffs 
		$.each(tariffs,function(index,tariff){
			$('body #tariffList_'+type).append(tariff);
		});
		$('#tariffListContainer').show();
		
		
	};
}
module.exports = displayHelper;
