$(function() {
    $('body').on('click', '.depth-search-fallback-region-group', function () {
        let keyword = $('.list-search').val();
        makeRequest('/regions/find/' + keyword, {}, function (response) {
            if(isset(response.success) && response.success && isset(response.html)) {
                $('.html-attachment').html(response.html);
                $('.html-attachment').find('.new-region-fallback-placeholder').val(keyword);
                success_message();
                return;
            }
            if(isset(response.error)) {
                error_message(response.error);
                return;
            }
            error_message('No Error Returned');
        }, 'GET')
    });

    $('body').on('click', '.add-fallback-region', function () {
        let data = {
            'keyword': $('.new-region-fallback-placeholder').val(),
            'region_id': $('.region-id-selector').val()
        };

        makeRequest('/regions/store', data, function (response) {
            if(isset(response.success) && response.success) {
                success_message();
                location.reload();
                return;
            }
            if(isset(response.error)) {
                error_message(response.error);
                return;
            }
            error_message('No Error Returned');
        });
    });

    $('body').on('click', '.edit-fallback-region', function () {
        let fallback_region_id = $(this).data('fallback-region-id');
        makeRequest('/regions/edit/' + fallback_region_id, {}, function (response) {
            if(isset(response.success) && response.success && isset(response.html)) {
                $('.html-attachment').html(response.html);
                $('.html-attachment').find('.new-region-fallback-placeholder').val(fallback_region_id);
                success_message();
                return;
            }
            if(isset(response.error)) {
                error_message(response.error);
                return;
            }
            error_message('No Error Returned');
        }, 'GET')
    });

    $('body').on('click', '.update-fallback-region', function () {
        let data = {
            'fallback_region_id': $('.fallback_region_id').val(),
            'region_id': $('.region-id-selector').val()
        };

        makeRequest('/regions/update', data, function (response) {
            if(isset(response.success) && response.success) {
                success_message();
                location.reload();
                return;
            }
            if(isset(response.error)) {
                error_message(response.error);
                return;
            }
            error_message('No Error Returned');
        });
    });

    $('body').on('click', '.show-region', function () {
        let region_id = $(this).data('region-id');
        makeRequest('/regions/show/' + region_id, {}, function (response) {
            if(isset(response.success) && response.success && isset(response.html)) {
                $('.html-attachment').html(response.html);
                success_message();
                return;
            }
            if(isset(response.error)) {
                error_message(response.error);
                return;
            }
            error_message('No Error Returned');
        }, 'GET');
    });

    $('body').on('click', '.add-fallback-region-preset', function () {
        let data = {
            'keyword': $(this).data('keyword'),
            'region_id':  $('.preset-region-id').text()
        }
        makeRequest('/regions/store', data, function (response) {
            if(isset(response.success) && response.success) {
                success_message();
                location.reload();
                return;
            }
            if(isset(response.error)) {
                error_message(response.error);
                return;
            }
            error_message('No Error Returned');
        });
    });
});