$(document).ready(function() {
    var resetView = function () {
        $.each($('.summary-page-items').find('.summary-flow'), function (k, container) {
            if (!$(container).hasClass('hide')) {
                $(this).addClass('hide');
            }
        });

        $.each($('.breadcrumb').find('.breadcrumb-item'), function (k, item) {
            $(item).removeClass('active');
        });
        updateLedgerTotals();
    };

    var updateLedgerTotals = function () {
        var total = 0;
        $('.ledger-table').each(function (k, table) {
            $(table).find('.ledger-value').each(function (key, row_value) {
                total += parseInt($(row_value).text(), 10);
            });
            $(table).find('.ledger-total-value').text(total.toString());
        });
    };

    $('body').on('click', '.summary-item-clickable', function () {
        var that = $(this);
        var target = $(this).data('target');
        var state = $(that).closest('.summary-page-items').find("[data-item='" + target + "']");

        resetView();

        $(that).closest('li.breadcrumb-item').addClass('active');

        if(!$(state).hasClass('hide')) {
            $(state).addClass('hide');
        } 
		else {
            $(state).removeClass('hide');
        }
    });

    $('body').on('click', '.group-nav-back', function () {
        var that = $(this);
        var position = $('ol.breadcrumb').find('li.active');
        var prev_selector = $(position).prev();
        var asset = $(prev_selector).data('asset');

        resetView();

        $(prev_selector).addClass('active');

        $(that).closest('.summary-page-items').find("[data-item='" + asset + "']").removeClass('hide');
    });

    $('body').on('click', '.group-nav-next', function () {
        var that = $(this);
        var position = $('ol.breadcrumb').find('li.active');
        var next_selector = $(position).next();
        var asset = $(next_selector).data('asset');

        resetView();

        $(next_selector).addClass('active');

        $(that).closest('.summary-page-items').find("[data-item='" + asset + "']").removeClass('hide');
    });

    $('body').on('click', '.script-save-button', function () {
        var target = $(this).data('target');
        if(target == 'generic.summary') {
            lead_script.handleForm(function () {
                window.location.reload();
            });
        }
    });
});