$(document).ready(function() {

	var delete_schedule = function(scheduleID,schedule_element) {
		$.ajax({
			data: {'schedule_id': scheduleID},
			type: 'POST',
			url: '/action-group/schedule/delete-schedule',
			dataType: 'json',
			success: function(response) {
				schedule_element.remove();
			},
			error: function (error) {
				//console.log(error);
			}
		});	
	};	
	
	//update the given action schedule link list entry with new action schedule link id 
	var updateActionScheduleLinkID = function (actionScheduleLink, newScheduleLinkID) {
		//console.log(actionScheduleLink);
		actionScheduleLink.attr('id', 'action-schedule-link-id-'+newScheduleLinkID);
	};

	//find the specific trigger rule or orphan 
	var getActionScheduleLinkByID = function (listsToCheck,targetID) {
		var scheduleLinkTarget;
		//for each list to check
		$.each(listsToCheck, function (index, list) {
			//can we find an element id for a trigger rule with no id?
			if ($(list).find('#action-schedule-link-id-'+targetID)) {
				//that's the one
				scheduleLinkTarget = $('#action-schedule-link-id-'+targetID);
			}
		});

		return scheduleLinkTarget;
	};

	$('#actions-schedule-container').on('click', '#add_schedule', function() {
		var schedule_id =  $(this).parent().data('schedule_id');
		// we need to know the id of the scedule we are looking at
		$.ajax({
			data: {schedule_id:schedule_id},
			type: 'POST',
			url: '/action-group/schedule/add-item',
			dataType: 'json',
			success: function(response) {
				if(response.success){
					// maybe we want to return the html
					$('#actions-schedule-container').find('.schedule-group').append(response.html);
				}
			},
			error: function (error) {
				
			}
		});
	});
	
	$('#actions-schedule-container').on('click', '#save_schedule', function() {
		var schedule_id = $(this).closest('.schedule-toolbar').data('schedule_id'); // we need to get this from in the page
		var container = $(this).parent().parent();
		var data = [];
		container.find('.scheduler-container').each(function(){
			var item_id = $(this).data('schedule_item_id');
			var obj = {item_id:item_id};
			$(this).find(':input').each(function(){
				var key = $(this).attr('name');
				obj[key] = $(this).val();
			});
			data.push(obj);
		});
		var run_on_bank_holidays = $(container).find('#schedule_run_on_bank_holidays').is(':checked');
		$.ajax({
			data: {
				'schedule_id':schedule_id,
				'data' : data,
				'run_on_bank_holidays' : run_on_bank_holidays 
			},
			type: 'POST',
			url: '/action-group/schedule/save',
			dataType: 'json',
			success: function(response) {
				if(response.success){
					$('#rule-value-edit').html(response.html);
					$('.update-error').hide();
					$('.update-success').fadeIn().text('Schedule saved');
					setTimeout(function(){
						$('.update-success').fadeOut();
					},5000);
					$('#actions-schedule-container').hide();
				}
			},
			error: function (error) {
//				console.log(error);
			}
		});
	});
	
	$('#schedule-saved-container').on('click','#create-schedule',function(){
		var parent = $(this).parent();
		parent.parent().find('.module-error').hide().text('');	
		var name = $(this).parent().find('input').val();
		if(name.length < 3){
			parent.parent().find('.module-error').fadeIn('fast').text('a new schedule needs a longer name');
		}
		else{
			// lets post what we have and setup a new rule
			$.ajax({
			data: {name:name},
			type: 'POST',
			url: '/action-group/schedule/create',
			dataType: 'json',
			success: function(response) {
				if(response.success){
					$('#rule-value-edit').html(response.html);
					$('.update-error').hide();
					$('.update-success').fadeIn().text('Created - '+name);
					setTimeout(function(){
						$('.update-success').fadeOut();
					},5000);
					// maybe we want to return the html
					$('#saved-schedules ul').append(response.html);
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		}
	});
	
	// search while typing for schedules
	$('#schedule-saved-container').on('keyup','.search-schedules',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-schedules ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
	   };
	});
	
	$('#schedule-saved-container, #schedule-drop-zone').on('click','.edit-schedule',function(){
		var schedule_id = $(this).parent().data('schedule_id');
		// perform ajax to get data and html
		$.ajax({
			url: '/action-group/schedule/edit/'+schedule_id,
			dataType: 'json',
			success: function(response) {
				if(response.success){
					// maybe we want to return the html
					$('#actions-schedule-container').html(response.html);
					$('#actions-schedule-container').show();
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		
	});
	
	//delete a saved schedule
	$('#schedule-drop-zone').on('click','.delete-schedule',function(){
		//console.log('deleting saved schedule');
		
		var action_schedule_link = $(this).parent();
		
		//remove visually and from drop zone
		$(action_schedule_link).remove();
		
		var schedule_link_ids = $('#schedule-drop-zone').sortable('toArray');
		var action_group =$("input[name='action-group-name']").attr('id');
		var new_schedule_id = $(this).parent().data('schedule_id');
		
		//remove from db
		$.ajax({
			data: {
				'schedule_link_ids' : schedule_link_ids,
				'action_group' : action_group, 
				'new_schedule_id' : new_schedule_id
			},
			type: 'POST',
			url: '/action-group/schedule/update-schedules',
			dataType: 'json',
			success: function(response) {
				//reinstate the parent schedule on the right as it's available again...
				$.ajax({
					url: '/action-group/schedule/refresh_saved/'+action_group,
					dataType: 'json',
					success: function(response) {
						if(response.success){
							// maybe we want to return the html - plus footer
							response.html += '<div class="schedules-footer"></div>';
							$('#schedule-saved-container').html(response.html);
							//refresh the sortable
							$( "#schedule-drop-zone, #schedule-grab" ).sortable({
								connectWith: ".scheduleSortable",
							});
						}
					},
					error: function (error) {
						//console.log(error);
					}
				});
			},
			error: function (error) {
				//console.log(error);
			}
		});		
	});
	
	$('#actions-schedule-container').on('click','.delete-trigger',function(){
		var schedule_id = $(this).parent().data('schedule_item_id');
		var parent = $(this).parent();
		// perform ajax to get data and html
		$.ajax({
			type: 'POST',
			url: '/action-group/schedule/delete-item',
			data: { schedule_id : schedule_id },
			dataType: 'json',
			success: function(response) {
				if(response.success){
					parent.remove();
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		
	});
	
	// remove schedule and warn if it's already linked to something
	$('#schedule-saved-container').on('click','.delete-schedule',function(){
		//console.log('deleting a core schedule');
		var schedule_id = $(this).parent().data('schedule_id');
		var schedule_element = $(this).parent();
		$.ajax({
			data: {'schedule_id': schedule_id},
			type: 'POST',
			url: '/action-group/schedule/get-action-groups',
			dataType: 'json',
			success: function(response) {
				var affectedActions = response.action_groups;
				if (affectedActions) {
					var affectedActionsList = "<ol>"; 
					$.each(affectedActions, function( index, action ) {
						affectedActionsList = affectedActionsList + '<li><b>'+action.name+' </b>:<i>'+action.description+'</i></div>';
					});
					affectedActionsList = affectedActionsList + "</ol>"; 
					$('#action-warning-content').html(affectedActionsList);
					$('#action-warning-qty').html(affectedActions.length+' action group(s):');

					$( "#delete-schedule-warning-dialog" ).dialog({
						resizable: false,
						height: "auto",
						width: 400,
						modal: true,
						buttons: {
							"Delete this schedule": function() {
								//delete the schedule
								delete_schedule(schedule_id, schedule_element);
								$( this ).dialog( "close" );
							},
							Cancel: function() {
								$( this ).dialog( "close" );
						  }
						}
					});
				} 
				else {
					//delete the schedule - nothing affected so go ahead
					delete_schedule(schedule_id, schedule_element);
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});
	
	$('#actions-schedule-container').on('click', '#update-schedule-name', function() {
		var parent = $(this).parent();
		var schedule_id = $(this).data('schedule_id');
		parent.find('span.errors').html('');
		var schedule_group_name = parent.find('input[name="schedule-name"]').val();
		if(schedule_group_name.length <= 5) {
			parent.find('span.errors').html('You must enter a name');
		} 
		else {
			$.ajax({ cache: false,
				url: "/action-group/schedule/update-name/"+schedule_id,
				type:"POST",
				dataType: 'json',
				data: { "schedule-name" : schedule_group_name },
				success: function (response) {
 					//console.log(response);
					if(response.success === true) {
						//redirects to the create new action group page
						$('#rule-value-edit').html(response.html);
						$('.update-error').hide();
						$('.update-success').fadeIn().text('Updated - '+schedule_group_name);
						setTimeout(function(){
							$('.update-success').fadeOut();
						},5000);
						$("#saved-schedules ul").find(`[data-schedule_id='${schedule_id}']`).find('.schedule').text(schedule_group_name);
					} 
					else {
						if(response.error) {
							parent.find('span.errors').html(response.error);
						}
					}
				},
				error: function (error) {
					//console.log(error);
				}
			});
		};
	});

	// jquery drag and drop function
	$( "#schedule-drop-zone, #schedule-grab" ).sortable({
		connectWith: ".scheduleSortable",
		update: function (event, ui) {
			
			//returning an action group schedule link (deleting it)
			if($(event.target).attr('id') === 'schedule-grab') {
			   $(ui.item).attr('id','action-schedule-link-id-0');
			}
			
			//add new action group schedule link
			if($(event.target).attr('id') === 'schedule-drop-zone') {
				var schedule_link_ids = $('#schedule-drop-zone').sortable('toArray');
				var action_group =$("input[name='action-group-name']").attr('id');
				var new_schedule_id = $(ui.item).data('schedule_id');
				$.ajax({
					data: {
						'schedule_link_ids' : schedule_link_ids,
						'action_group' : action_group, 
						'new_schedule_id' : new_schedule_id
					},
					type: 'POST',
					url: '/action-group/schedule/update-schedules',
					dataType: 'json',
					success: function(response) {
						//console.log(response);
						//update the schedulelinkID
						if (response.scheduleLinkID) {
							if (response.action == 'add') {
								//console.log('finding the link and updating it...');
								var lists = ['#schedule-drop-zone'];
								var thisScheduleLink = getActionScheduleLinkByID(lists,0);
								updateActionScheduleLinkID(thisScheduleLink, response.scheduleLinkID);
							}
						}
					},
					error: function (error) {
						//console.log(error);
					}
				});
			}
		}
	}).disableSelection();
	
});

