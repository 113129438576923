

var addComponentLoader = function(component){

	if($(component).find('.dashboard-component-heading').length && !$(component).find('.dashboard-component-heading').find('.reloader').length){

		$(component).find('.dashboard-component-heading').append('<i class="fa-regular fa-circle-notch fa-spin reloader" aria-hidden="true"></i>')

	}

}

var addComparisonDateRangePicker = function(input){
	$(input).daterangepicker({

		"locale": {
			"format": 'DD/MM/YYYY HH:mm a',
			"separator": " - "
		},
		"linkedCalendars":false,
		"showDropdowns": true,
		"showCustomRangeLabel" : true,

		"ranges": {
			'Today': [moment(), moment()],
			'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
			'This Week': [moment().startOf('week'), moment().endOf('week')],
			'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
			'This Month': [moment().startOf('month'), moment().endOf('month')],
			'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
		}
		},
		function(start, end) {
			reloadComponent($(this.element).closest('.dashboard-grid-item'))
		}
	);
}


var setupCalendar = function(component){

	var data_source = $(component).find('.data-source');
	var data = $(data_source).data('source');

	var title = $(data_source).data('title');

	var calendar_element = $(component).find('.dashboard-calendar');

	if($(calendar_element).length){

		var month_to_show = moment();
		if(data.length){
			var month_to_show = moment(data[0].start);
		}

		new FullCalendar.Calendar($(calendar_element)[0], {
			'events' : data,
			'initialView': 'dayGridMonth',
			 headerToolbar: {
				start: 'dayGridMonth,timeGridWeek,timeGridDay',
				center: 'title',
				end: 'prev,next',
			},
			'height': '100%',
			'aspectRatio': 2.5,
		}).render();
	}

}


var reloadComponent = function(component){

	addComponentLoader(component);

	var data = {
		'start_date' : Date.parse($('#dashboard_range').data('daterangepicker').startDate._d),
		'end_date' : Date.parse($('#dashboard_range').data('daterangepicker').endDate._d),
		'pivot_id' : $(component).data('pivot-id')
	};

	if($(component).find('.dashboard-comparison-date-picker').length){
		data.comparison_start_date = Date.parse($(component).find('.dashboard-comparison-date-picker').data('daterangepicker').startDate._d);
		data.comparison_end_date = Date.parse($(component).find('.dashboard-comparison-date-picker').data('daterangepicker').endDate._d);
	}

	$(component).data('loading', true);


	makeRequest('/dashboard/component/render', data, function(response){
		$(component).data('loading', false);
		if(isset(response.success) && response.success && response.html){

			$(component).find('.grid-stack-item-content').html(response.html);

			if($(component).find('.dashboard-comparison-date-picker').length){
				addComparisonDateRangePicker($(component).find('.dashboard-comparison-date-picker'));
			}

			if($(component).find('.dashboard-calendar')){
				setupCalendar(component);
			}



		}
		else{

			var error = 'Issue loading dashboard component ' + $(component).data('component-name');
			if(isset(response.error)){
				error = response.error;
			}
			error_message(error);
		}

	})
}


var reloadComponents = function(components){

	$.each(components, function(i, component){

		reloadComponent(component);
	
	});

}

var reloadDashboardData = function(){

	
	var active_tab = $('.tab-pane.active');

	var components = $(active_tab).find('.dashboard-grid-item');

	reloadComponents(components);

	var dead_components = $('.tab-pane:not(.active) .dashboard-grid-item');

	dead_components.find('.grid-stack-item-content').append('<div class="dashboard-loader hide" >\
								<i class="fa-regular fa-circle-notch fa-spin" aria-hidden="true"></i>\
							</div>\
						')

}



$(document).ready(function() {
	var start = moment().startOf('day');
	var end = moment().endOf('day');

	function cb(start, end) {
		$('#dashboard_range span').html(start.format('D, MMMM, YYYY h:mm a') + ' - ' + end.format('D, MMMM, YYYY h:mm a'));
	}

	var dash_date_picker_config = {
		showCustomRangeLabel : true,
		startDate: start,
		timePicker: true,
		endDate: end,
		ranges: {
			'Today': [start, end],
			'Yesterday': [moment(start).subtract(1, 'days'), moment(end).subtract(1, 'days')],
			'This Week': [moment(start).startOf('week'), moment(end).endOf('week')],
			'Last Week': [moment(start).subtract(1, 'week').startOf('week'), moment(end).subtract(1, 'week').endOf('week')],
			'This Month': [moment(start).startOf('month'), moment(end).endOf('month')],
			'Last Month': [moment(start).subtract(1, 'month').startOf('month'), moment(end).subtract(1, 'month').endOf('month')]
		}
	}

	//if today is a monday then lets show friday as a preset range
	if(moment().day() == 1){
		dash_date_picker_config['ranges']['Friday'] = [moment(start).subtract(3, 'days'), moment(end).subtract(3, 'days')];
		//we want friday to appear second in the list below today
		dash_date_picker_config['ranges'] = Object.assign({'Today' : null, 'Friday' : null}, dash_date_picker_config['ranges']);
	}

	if($('#dashboard_range').data('limit-date')){
		dash_date_picker_config.maxSpan = {
			"days": 7
		};
		delete dash_date_picker_config.ranges["This Month"];
		delete dash_date_picker_config.ranges["Last Month"];
	}



	$('#dashboard_range').daterangepicker(dash_date_picker_config, cb);

	cb(start, end);
	$('#dashboard_range').on('apply.daterangepicker', function(ev, picker) {
		reloadDashboardData();
	});

	$('#dashboard-container').on('click', '.dashboard-tab', function() {
		if($(this).closest('li').is(':not(.active)')){
			setTimeout(function(){
				var active_tab = $('.tab-pane.active');

				var components = $(active_tab).find('.dashboard-grid-item');
				if($(components).find('.dashboard-loader').length){
					
					var to_refresh = {};
					$.each($(components).find('.dashboard-loader'), function(i, loader){
						var component = $(loader).closest('.dashboard-grid-item');
						if(!$(component).data('loading')){
							to_refresh[$(component).data('pivot-id')] = component;
						}
					})
					reloadComponents(to_refresh);
				}
			}, 50)
		}
	});
	
	$('#dashboard-container').on('keyup', '.dashboard-table-search', function() {
		var table = $(this).parent().find('table');

		var value = $(this).val();
		var rows = table.find('tbody tr');

		if(value == ''){
			$(rows).show();
		}
		else{
			$.each(rows, function(i, row){
				if($(row).text().toLowerCase().indexOf(value.toLowerCase()) === -1){
					$(row).hide();
				}
				else{
					$(row).show();
				}
			})
		}
	});

	$('#dashboard-container').on('click', '.toggle_my_children_please', function(){
		var table = $(this).closest('table');
		var self = $(this).data('self');
		var children = $(table).find("tr[class^='" + self + "-']");
		var first_child = $(children).first();
		if(!first_child.length){
			var td = $(this).find('td').first()
			if(td.length){
				td.css('animation', 'heartbeat 1s ease');
				setTimeout(function(){td.css('animation', '')},1000);
			}
			return;
		}
		if($(first_child).is(':visible')){
			$(children).hide();
		}
		else{
			var children_class = $(first_child).data('parent');
			$(table).find("tr[data-parent='"+ children_class +"']").show();
		}
		
	});
});

var winning = function(msg){
	$('#winning-content').html(msg);
	$('#winning').show();
	setTimeout(function(){
		$('#winning').hide();
		$('#winning-content').html('');
	},3000);
};
var dashboardRequest = function(method, target, tab, callback, c_start, c_end){
	var start = '';
	var end = '';
	var start = Date.parse($('#dashboard_range').data('daterangepicker').startDate._d);
	var end = Date.parse($('#dashboard_range').data('daterangepicker').endDate._d);
	if(c_start && c_end){
		var comparison_str = '&cstart='+c_start+'&cend='+c_end;
	}
	else{
		var comparison_str = '';
	}
	//may need some protection on this I think
	var product_type_id = $('#product-select').val();
	$.ajax({ cache: false,
			url: "/get/dashboard/"+method+"?start=+"+start+"&end="+end+"&ptid="+product_type_id+"&tab=" + tab + comparison_str,
			dataType: 'json',
			success: function (response) {
				if(response.success === true){
					$('#'+target).html(response.html);
					if(response.winning.status === true){
						winning(response.winning.message);
					}
					if($('#' + target + ' ' + 'input.dash-comparison').length){
						makeComparisonDatePicker($('#' + target + ' ' + 'input.dash-comparison'));
					}
				}
				else{
					console.log(response);
				}
				if(callback) {
					callback();
				}
			},
			error: function (error) {
				if(error.responseText){
					if(error.responseText.indexOf('Base table or view not found') != -1){
						 $.ajax(this);
					}
				}
				if(callback) {
					callback();
				}
			}
		});
};
var fullParallel = function(callbacks, last) {
		var results = [];
		var result_count = 0;
		$.each(callbacks,function(index,callback) {
		  callback( function() {
			results[index] = Array.prototype.slice.call(arguments);
			result_count++;
			if(result_count == callbacks.length) {
			  last(results);
			}
		  });
		});
	}
	

var processSeries = function(callbacks) {
	function next() {
		var callback = callbacks.shift();
		if(callback) {
			callback(function() {
				next();
			});
		} 
	}
	next();
};
$(document).ready(function() {
	
	$('#master-product-select').on('change',function(){
		$.ajax({
			type: "POST",
			dataType: 'json',
			data: {
				'product-types' : $(this).val(),
				'dashboard' : true
			},
			url: '/user/updateprofile',
			success: function(response){
				success_message();
				window.location.reload();
			},
			error: function (error) {
				alert('something went wrong');
			}
		});
	});

	$('#master-company-select').on('change', function () {
		window.location.href = this.value;
	});
	
	var updateDashboard = function(){
		var callbackStack = [];
		$('.dashboard-component-lazy').each(function(index,element){
			var target = $(element).attr('id');
			var method = target.replace('dashboard-','').replace('-wrapper','');
			var tab = method.split('-')[0];
			method = method.split('-')[1];
			if($('#' + target + ' input.dash-comparison').length){
				
				var cstart= $('#' + target + ' input.dash-comparison').data('daterangepicker').startDate;
				var cend= $('#' + target + ' input.dash-comparison').data('daterangepicker').endDate;
			}
			else{
				var cstart = false;
				var cend = false;
			}
			if($(element).data('async') === 1){
				dashboardRequest(method, target, tab,  function(){}, cstart, cend);
			}
			else{
				callbackStack.push(function(next){dashboardRequest(method, target, tab, next, cstart, cend);});
			}
			//dashboardRequest(method,target);
			
		});
		fullParallel(callbackStack,function(){
			//console.log(done);
		});
	};
	setTimeout(function(){ 
		updateDashboard();
	},100);
	
	$('#dashboard-container').on('click','.rankGlobalButton',function(){
		$('.rankGlobalPage').hide();
		$($(this)).toggleClass('active');
		$($(this).data('target')).show();
		$($(this).data('target')).toggleClass('active');
	});
	
	$('#dashboard-container').on('click','.rankTeamButton',function(){
		$('.rankTeamPage').hide();
		$($(this)).toggleClass('active');
		$($(this).data('target')).show();
		$($(this).data('target')).toggleClass('active');
	});
	$('#dashboard-container').on('change','#product-select',function(){
		updateDashboard();
	});
	
	$('#dashboard-container').on('click', '.sales-details-view', function(){
		var index = $(this).data('index');
		$(this).parent().parent().parent().find('tr#collapse' + index).collapse('toggle');
	});
	
	$('#dashboard-container').on('click','.status-summary-view', function(){
		var index = $(this).data('index');
		$(this).parent().find('ul' + index).collapse('toggle');
	});
	
	$('#dashboard-container').on('click', '#ticket-component-details .ticket-queue', function(){
		var queue = $(this).data('queue');
		$(this).parent().find('div#ticket-'+queue+'-statuses').toggle();
	});
	
	$('#dashboard-container').on('click', '#ticket-component-details .ticket-status', function(){
		$(this).parent().find('div.ticket-users').toggle();
	});
	
	$('#dashboard-container').on('click', '.agent-details-view', function(){
		var index = $(this).data('index');
		$(this).parent().parent().parent().find('tr#collapse' + index).collapse('toggle');

	});       
});


function makeComparisonDatePicker(element){
	element.daterangepicker({

		"locale": {
			"format": 'DD/MM/YYYY HH:mm A',
			"separator": " - "
		},
		"linkedCalendars":false,
		"showDropdowns": true,
		"showCustomRangeLabel" : true,

		"ranges": {
			'Today': [moment(), moment()],
			'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
			'This Week': [moment().startOf('week'), moment().endOf('week')],
			'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
			'This Month': [moment().startOf('month'), moment().endOf('month')],
			'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
		}
		},
		function(start, end) {
			var target = $(element).parent().parent().parent().parent().attr('id');
			var method = target.replace('dashboard-','').replace('-wrapper','');
			var tab = method.split('-')[0];
			method = method.split('-')[1];
			dashboardRequest(method, target, tab, function(){}, start, end);
		}
	);
}

var updateTabOrder = function(){

	var items = $('.dashboard-tab-config li');

	var order = [];

	$.each(items, function(i, elem){
		order.push($(elem).find('a').data('tab-id'));
	});

	var data = {
		'role_id' : $('#dashboard_role_select').val(),
		'product_type_id' : $('#dashboard_product_type_select').val(),
		'sort_order' : order
	};

	makeRequest('/dashboard/tab/reorder', data, function(response){

		if(isset(response.success) && response.success){
			success_message('tabs reordered');
		}
		else{
			var error = 'whoops';
			if(isset(response.error)){
				error = response.error;
			}
			error_message(error);
		}
	})
}

var adding_new = false;
var queue = [];

var updateItems = function(grid){
	var components = [];

	var bail = false;
	$.each($(grid).children(), function(i, child){
		var node = $(child).data('_gridstack_node');
		var pivot_id = $(child).data('pivot-id');
		if(pivot_id){
			components.push({
				'id' : pivot_id,
				'x' : node.x,
				'y': node.y,
				'width': node.width,
				'height': node.height
			})
		}
		else{
			queue.push(function(){updateItems(grid)});
			bail = true;
			return false;
		}
	});
	if(bail){
		return false;
	}

	var data = {
		'tab_id' : $(grid).data('tab-id'),
		'components' : components
	};

	makeRequest('/dashboard/tab/components/update', data, function(response){
		if(isset(response.success) && response.success){
			success_message();
		}
		else{
			var error = 'whoops';
			if(isset(response.error)){
				error = response.error;
			}
			error_message(error);
		}

	});
}

var addComponentToTab = function(grid, new_item){

	var node = $(new_item).data('_gridstack_node');

	var data = {
		'tab_id' : $(grid).data('tab-id'),
		'component_id' : $(new_item).data('component-id'),
		'x' : node.x,
		'y': node.y,
		'width': node.width,
		'height': node.height
	};
	showLoader();
	makeRequest('/dashboard/tab/components/add', data, function(response){
		hideLoader();
		if(isset(response.success) && response.success && isset(response.link_id)){
			new_item.data('pivot-id', response.link_id);
			new_item.find('.edit-dash-component').data('pivot-id', response.link_id);
			new_item.find('.remove-dash-component').data('pivot-id', response.link_id);
			success_message();
			adding_new = false;
			$.each(queue, function(i, method){
				method();
			});
			queue = [];
		}
		else if(isset(response.success) && response.success && isset(response.html)){
			createModal('edit-dashboard-component-attributes-modal', response.html);
			$('.select2:not(.multi)').select2({
				'width': '100%'
			});
			$('.select2.multi').select2({
				'tags': true,
				'tokenSeparators': [',', ' '],
				'width':'100%'
			});
			$('#edit-dashboard-component-attributes-modal').on('hidden.bs.modal', function (e) {
				$(new_item).remove();	
				adding_new = false;
				$.each(queue, function(i, method){
					method();
				});
				queue = [];
			})
			$('#edit-dashboard-component-attributes-modal #save_values').data('new_item', new_item);
		}
		else{
			var error = 'whoops';
			if(isset(response.error)){
				error = response.error;
			}
			error_message(error);
		}

	});
}

var makeSortable = function(){
	$('.dashboard-tab-config').sortable({
		'items' : 'li:not(.add-new)',
		'update': updateTabOrder
	});
}


var makeSingleGrid = function(element){

	var options = {
        'cellHeight': 80,
		'verticalMargin': 20,
		'acceptWidgets': '.grid-stack-item',
		'animate' : true,
		'float': true
    };
	$(element).gridstack(options).data('gridstack');
	$('.grid-stack.dashboard-grid-stack').on('dropped', function(event, ui) {
		adding_new = true;
		var new_item = $(event.target).children().last();
		var clone = $(new_item).clone();
		component_grid.addWidget(clone, $(new_item).data('og-x'), $(new_item).data('og-y'));
		addComponentToTab($(event.target), new_item);
	});
	$('.grid-stack.dashboard-grid-stack').on('change', function(event, items) {
		if(adding_new){
			queue.push(function(){
				updateItems(event.target);
			});
		}
		else{
			updateItems(event.target);
		}
	});

}

var component_grid = false;
var makeGrid = function(){
	var second_options = {
		'disableResize' : true,
		'cellHeight' : 80,
		'verticalMargin' : 20,
		'animate' : true,
		'width' : 3
	}
	component_grid = $('.grid-stack.dashboard-component-grid-stack').gridstack(second_options).data('gridstack');
	makeSingleGrid($('.grid-stack.dashboard-grid-stack'));
	$('.grid-stack.dashboard-component-grid-stack').on('dragstart', function(event, ui) {
		var element = event.target;
		var node = $(element).data('_gridstack_node');
		$(element).attr('data-og-x', node.x);
		$(element).attr('data-og-y', node.y);
	});

}

var window_fields = [
	'specific-from',
	'specific-from-metric',
	'specific-to',
	'specific-to-metric',
	'custom-from-date',
	'custom-to-date',
	'predefined-from-date',
	'predefined-to-date'
];

var readAttributeValues = function(form){
	var form_data = form.serializeArray();

	var data = {};

	$.each(form_data, function(i, serialized_data) {
		if(isset(data[serialized_data['name']])){
			if(typeof(data[serialized_data['name']]) != 'object'){
				data[serialized_data['name']] = [data[serialized_data['name']]];
			}
			data[serialized_data['name']].push(serialized_data['value']);
		}
		else{
			data[serialized_data['name']] = serialized_data['value'];
		}
	});

	form = $(form).closest('form');
	//unchecked checkboxes do not get returned as part of serializeArray -- because that makes all the sense. So lets do that by hand
	var uncheckedboxes = $(form).find(':input[type="checkbox"]:not(:checked)');
	$.each(uncheckedboxes, function(i, checkbox){
		data[$(checkbox).attr('name')] = 0;
	});

	//handle window fields
	if($(form).find('.time-selectors-1 .btn:visible').length){
		var predefined_window_values = $(modal_content).find('.time-selectors-1 input').serializeArray();
		$.each(predefined_window_values, function(i, component_data){
			data[component_data.name] = component_data.value;
		});
	}

	$.each(data, function(key, value){
		if(window_fields.indexOf(key) !== -1){
			if(!isset(data.window)){
				data.window = {};
			}
			var key_parts = key.split('-');
			data.window.type = key_parts.shift();
			data.window[key_parts.join('-')] = value;
			delete data[key];
		}
	});
	return data;
};


$(document).ready(function() {

	if($('.dashboard-tab-config').length){
		makeSortable();
		makeGrid();
	}

	$('#dashboard-config-content').on('click', '.dashboard-new-tab', function(e){
		e.preventDefault();
		$(this).text('');
	});

	$('#dashboard-config-content').on('keyup', '.dashboard-new-tab', function(e){
		if($(this).html().match(/<[^>]*>?/gm)){
			$(this).text($(this).text());
		}
	});
	
	$('#dashboard-config-content').on('blur', '.dashboard-new-tab', function(e){
		e.preventDefault();
		var name = $(this).text();
		if(!name.length){
			$(this).html('+');
			return;
		}
		var that = $(this);

		var data = {
			'role_id' : $('#dashboard_role_select').val(),
			'product_type_id' : $('#dashboard_product_type_select').val(),
			'tab_name' : name
		};

		makeRequest('/dashboard/tab/new', data, function(response){
			if(isset(response.success) && response.success && isset(response.tab_id)){
				var clone = $(that).closest('li').clone();
				clone.find('a').html('+');
				$(clone).insertAfter($(that).closest('li'));
				$(that).removeClass('dashboard-new-tab');
				$(that).addClass('edit-dashboard-tab');
				$(that).removeAttr('contenteditable');
				$(that).parent().removeClass('add-new')
				$(that).data('tab-id', response.tab_id);
				$(that).attr('href', '#tab_' + response.tab_id);
				$(that).attr('data-toggle', 'tab');
				$(that).html($(that).html() + ' <div class="remove-dashboard-tab" data-tab-id="'+response.tab_id +'"><i class="fa-solid fa-trash" aria-hidden="true"></i></div>')
				if(isset(response.html)){
					$('.tab-pane.active').removeClass('active');
					$('.curved-top-corners.active').removeClass('active');
					$('#tab-content').append(response.html);
					$('#tab_' + response.tab_id).addClass('active')
					$(that).closest('.curved-top-corners').addClass('active');
					makeSingleGrid($('#tab_' + response.tab_id + ' .grid-stack'));

				}
				success_message(name + ' tab created');
			}
			else{
				var error = 'whoops';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
	
	$('#dashboard-config-content').on('click', '.remove-dashboard-tab', function(e){
		e.preventDefault();
		e.stopPropagation();
		var tab_id = $(this).data('tab-id');
		var that = $(this);

		var data = {
			'role_id' : $('#dashboard_role_select').val(),
			'product_type_id' : $('#dashboard_product_type_select').val(),
			'tab_id' : tab_id
		};
		if(confirm('Are you sure you want to delete the ' + $(that).text().trim() + ' tab')){
			makeRequest('/dashboard/tab/remove', data, function(response){
				if(isset(response.success) && response.success){
					$(that).closest('li').remove();
					$('#tab_' + tab_id).remove();
					success_message(name + ' tab deleted');
				}
				else{
					var error = 'whoops';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			});
		}
	});
	
	$('#dashboard-config-content').on('dblclick', '.edit-dashboard-tab', function(e){
		e.preventDefault();

		$(this).attr('contenteditable', true);
		$(this).addClass('editing');
		$(this).trigger('focus');
		$(this).data('original-html', $(this).html());
		$(this).data('original-text', $(this).text());
	});
	
	$('#dashboard-config-content').on('blur', '.edit-dashboard-tab.editing', function(e){
		e.preventDefault();

		$(this).removeAttr('contenteditable');
		$(this).removeClass('editing');

		var new_name = $(this).text().trim();
		if(!new_name || new_name == $(this).data('original-text').trim()){
			$(this).html($(this).data('original-html'));
			return;
		}

		var data = {
			'tab_id' : $(this).data('tab-id'),
			'new_name' : new_name
		};

		makeRequest('/dashboard/tab/rename', data, function(response){
			if(isset(response.success) && response.success){
				success_message('tab renamed');
			}
			else{
				var error = 'whoops';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
	
	$('#dashboard_change_role_product_type').on('click', function(e){
		e.preventDefault();

		var data = {
			'role_id' : $('#dashboard_role_select').val(),
			'product_type_id' : $('#dashboard_product_type_select').val()
		};
		showLoader();
		makeRequest('/dashboard/view/change', data, function(response){
			hideLoader();
			if(isset(response.success) && response.success && isset(response.html)){
				$('#dashboard-config-content').html(response.html)
				makeGrid();
				makeSortable();
			}
			else{
				var error = 'whoops';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
	
	
	$('#dashboard-config-content').on('click', '#tab-content .remove-dash-component', function(e){
		e.preventDefault();

		var data = {
			'pivot_id' : $(this).data('pivot-id')
		};

		var that = this;

		if(confirm('Are you sure you want to remove this component?')){
			makeRequest('/dashboard/tab/component/remove', data, function(response){
				if(isset(response.success) && response.success){
					$(that).closest('div.grid-stack-item').remove();
					success_message();
				}
				else{
					var error = 'whoops';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			});
		}
	});
	
	$('#dashboard-config-content').on('click', '#dashboard_components .remove-dash-component', function(e){
		e.preventDefault();

		var data = {
			'component_id' : $(this).data('component-id')
		};

		var that = this;

		if(confirm('Are you sure you want to remove this component?  -- this will remove all of it\'s links to tabs')){
			makeRequest('/dashboard/component/remove', data, function(response){
				if(isset(response.success) && response.success){
					$(that).closest('div.grid-stack-item').remove();
					if(isset(response.links_to_remove)){
						$.each(response.links_to_remove, function(i, id){
							$('div.grid-stack-item[data-pivot-id="' + id + '"]').remove();
						});
					}
					success_message();
				}
				else{
					var error = 'whoops';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			});
		}
	});
	
	$('#dashboard-config-content').on('click', '#dashboard_components .edit-dash-component', function(e){
		var id = $(this).data('component-id');

		makeRequest('/dashboard/component/edit/'+id, {}, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				createModal('edit-dashboard-component-modal', response.html)
				success_message();
			}
			else{
				var error = 'whoops';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});

	$('#dashboard-config-content').on('keyup', '.grid-stack-search', function(e){
		var container = $(this).closest('.module-inner');

		var grid = $(container).find('.grid-stack');

		var items = grid.find('.grid-stack-item');
		var value = $(this).val();
		$.each(items, function(i, item){

			if(value !== ''){
				if($(item).text().indexOf(value) === -1){
					$(item).hide();
				}
				else{
					$(item).show();
				}
			}
			else{
				$(item).show();
			}
		})

	})
	
	$('#dashboard-config-content').on('click', '.create-new-button', function(e){
		var container = $(this).closest('.dash-component-create');

		var input = $(container).find('input');

		var name = $(input).val();

		if(name.length < 4){
			error_message('please enter a name of at least 4 characters');
			return false;
		}

		var data = {
			'name' : name
		};

		makeRequest('/dashboard/component/new', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				createModal('create-dashboard-component-modal', response.html)
				success_message();
			}
			else{
				var error = 'whoops';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
		
	})
	


	$('#modal_container').on('click', '#create-dashboard-component-modal #next', function(e){
		var modal_content = $(this).closest('.modal-content');

		var name = $(modal_content).find('.name-input').val();

		if(name.length < 4) {
			error_message('please enter a descriptive name');
			return false;
		}
		var attributes = [];
		var editable = [];
		$(modal_content).find("input[name='has_attribute']:checked").each(function() {
			attributes.push($(this).val());
		});
		$(modal_content).find("input[name='is_editable']:checked").each(function() {
			editable.push($(this).val());
		});

		var data = {
			'name': name,
			'attributes': attributes,
			'ediable_attributes': editable
		};

		var that = this;

		makeRequest('/dashboard/component/create', data, function(response){
			if(response.success && response.html) {
				modal_content.find('.values-section').html(response.html).collapse('show');
				modal_content.find('.config-section').collapse('hide');
				$(that).text('update');
				$(that).attr('id', 'save_values');
				$(that).data('component-id', response.component_id);
				$('.select2').select2({
					'width': '100%'
				});
				$('.select2.mutli').select2({
					'tags': true,
					'tokenSeparators': [',', ' '],
					'width':'100%'
				});
				success_message();
			}
			else{
				var error = 'whoops';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});

	$('#modal_container').on('click', '#create-dashboard-component-modal #save_values', function(e){
		var component_id = $(this).data('component-id');
		var modal_content = $(this).closest('.modal-content');
		var form = $(modal_content).find('#attribute-table').find('form');

		var data = readAttributeValues(form);

		var that = this;
		makeRequest('/dashboard/component/update/' + component_id, data, function(response){
			if(isset(response.success) && response.success) {
				if(response.html) {
					var grid = $('.grid-stack.dashboard-component-grid-stack').data('gridstack');
					grid.addWidget(response.html);
				}
				var modal = $(that).closest('.modal');
				$(modal).modal('hide');
				$(modal).find('.values-section').html('');
				success_message();
			}
			else{
				var error = 'whoops';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
	$('#modal_container').on('click', '#edit-dashboard-component-modal #save_values', function(e){
		var component_id = $(this).data('component-id');
		var modal_content = $(this).closest('.modal-content');
		var form = $(modal_content).find('#attribute-table').find('form');

		var data = readAttributeValues(form);

		var that = this;
		makeRequest('/dashboard/component/update/' + component_id, data, function(response){
			if(isset(response.success) && response.success) {
				var modal = $(that).closest('.modal');
				$(modal).modal('hide');
				$(modal).find('.values-section').html('');
				success_message();
			}
			else{
				var error = 'whoops';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});

	$('#modal_container').on('click', '#edit-dashboard-component-modal #next', function(e){
		var modal_content = $(this).closest('.modal-content');

		var name = $(modal_content).find('.name-input').val();

		if(name.length < 4) {
			error_message('please enter a descriptive name');
			return false;
		}
		var attributes = [];
		var editable = [];
		$(modal_content).find("input[name='has_attribute']:checked").each(function() {
			attributes.push($(this).val());
		});
		$(modal_content).find("input[name='is_editable']:checked").each(function() {
			editable.push($(this).val());
		});

		var component_id = $(this).data('component-id');

		var data = {
			'component_id' : component_id,
			'name': name,
			'attributes': attributes,
			'ediable_attributes': editable
		};

		var that = this;

		makeRequest('/dashboard/component/edit_existing', data, function(response){
			if(response.success && response.html) {
				modal_content.find('.values-section').html(response.html).collapse('show');
				modal_content.find('.config-section').collapse('hide');
				$(that).text('update');
				$(that).attr('id', 'save_values');
				$(that).data('component-id', response.component_id);
				$('.select2:not(.multi)').select2({
					'width': '100%'
				});
				$('.select2.multi').select2({
					'tags': true,
					'tokenSeparators': [',', ' '],
					'width':'100%'
				});
				success_message();
			}
			else{
				var error = 'whoops';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});

	$('#modal_container').on('click', '#edit-dashboard-component-attributes-modal #save_values', function(e){
		var component_id = $(this).data('component-id');
		var tab_id = $(this).data('tab-id');
		var modal_content = $(this).closest('.modal-content');
		var form = $(modal_content).find('#attribute-table').find('form :input:visible');
		
		var attribute_data = readAttributeValues(form);

		var data = {
			'component_id' : component_id,
			'attributes' : attribute_data,
			'tab_id' : tab_id,
			'x' :  $(this).data('x'),
			'y' :  $(this).data('y'),
			'width' :  $(this).data('width'),
			'height' :  $(this).data('height')
		};

		var that = this;
		makeRequest('/dashboard/component/update_existing_attributes', data, function(response){
			if(isset(response.success) && response.success && isset(response.pivot_id)){
				$('#edit-dashboard-component-attributes-modal').unbind('hidden.bs.modal');
				$('#edit-dashboard-component-attributes-modal').modal('hide');
				var new_item = $(that).data('new_item');
				var tmp_html = $(response.html)
				$(new_item).find('.item-body').html($(tmp_html).find('.item-body').html());
				new_item.data('pivot-id', response.pivot_id);
				new_item.find('.edit-dash-component').data('pivot-id', response.pivot_id);
				new_item.find('.remove-dash-component').data('pivot-id', response.pivot_id);
				if(isset(response.label)){
					new_item.find('.component-name').text(response.label);
					new_item.find('.component-name').attr('title', response.label);
				}
				new_item.find('.component-name').text();
				success_message();
				adding_new = false;
				$.each(queue, function(i, method){
					method();
				});
				queue = [];
			}
			else{
				var error = 'whoops';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}

		});
	});
	
	
	$('#dashboard-config-content').on('click', '#tab-content .edit-dash-component', function(e){
		e.preventDefault();

		var data = {
			'pivot_id' : $(this).data('pivot-id')
		};

		var that = this;

		makeRequest('/dashboard/tab/component/edit', data, function(response){
			if(isset(response.success) && response.success){
				if(isset(response.html)){
					createModal('edit-dashboard-tab-component-attributes-modal', response.html);
					$('.select2:not(.multi)').select2({
						'width': '100%'
					});
					$('.select2.multi').select2({
						'tags': true,
						'tokenSeparators': [',', ' '],
						'width':'100%'
					});
				}
				else{
					success_message('component has no editable attributes');
				}
			}
			else{
				var error = 'whoops';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});


	$('#modal_container').on('click', '#edit-dashboard-tab-component-attributes-modal #save_values', function(e){
		var link_id = $(this).data('link-id');
		var modal_content = $(this).closest('.modal-content');
		var form = $(modal_content).find('#attribute-table').find('form :input:visible');
		
		var attribute_data = readAttributeValues(form);

		var data = {
			'link_id' : link_id,
			'attributes' : attribute_data
		};

		var that = this;
		makeRequest('/dashboard/component/update_existing_link_attributes', data, function(response){
			if(isset(response.success) && response.success){
				$('#edit-dashboard-tab-component-attributes-modal').modal('hide');
				success_message();
			}
			else{
				var error = 'whoops';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}

		});
	});

	if($('.dashboard-render-grid-stack').length){

		var options = {
			'cellHeight': 80,
			'verticalMargin': 20,
			'disableDrag' : true,
			'disableResize' : true,
			'float': true
		};
		$('.dashboard-render-grid-stack').gridstack(options);
		reloadDashboardData();
	}

	$('#modal_container').on('change', '.color-picker', function(){
		$(this).parent().find('.hex-color').val($(this).val());
	});
	
	$('#modal_container').on('change', '.hex-color', function(){
		if(new RegExp($(this).attr('pattern')).test($(this).val())){
			$(this).parent().find('.color-picker').val($(this).val());
		}
		else{
			error_message('please only enter hex colors in this input');
			$(this).val($(this).parent().find('.color-picker').val());
		}
	});

	function comparer(index) {
		return function(a, b) {
			var val_a = getCellValue(a, index), val_b = getCellValue(b, index)
			return $.isNumeric(val_a) && $.isNumeric(val_b) ? val_a - val_b : val_a.toString().localeCompare(val_b)
		}
	}
	function getCellValue(row, index){ return $(row).children('td').eq(index).text() }
	
	$('#dashboard-container').on('click', 'div.dashboard-component-body th', function(){

		
		var table = $(this).closest('table');

		var sort_direction = 'desc';
		var current_sort = $(this).data('sorted');
		if(current_sort == 'desc'){
			sort_direction = 'asc';
		}

		var body = $(table).find('tbody').first()[0];
		var save_to_end = [];

		var table = $(this).parents('table').eq(0);
		var rows = table.find('tbody tr').toArray().sort(comparer($(this).index()));
		if (sort_direction == 'desc'){
			rows = rows.reverse();
		}
		$.each(rows, function(i, row){
			if($(row).children().first().text().trim() == 'Total'){
				save_to_end.push(row);
			}
			else{
				body.append(row)
			}
		});
		$.each(save_to_end, function(i, saved){
			body.append(saved);
		});

		$(this).data('sorted', sort_direction);

		//need to show an icon to show sort direction
		$.each($(this).siblings(), function(i, sibling){
			$(sibling).find('i.sort-direction').remove();
		});

		if($(this).find('i.sort-direction').length){
			$(this).find('i.sort-direction').removeClass('fa-arrow-circle-down');
			$(this).find('i.sort-direction').removeClass('fa-arrow-circle-up');
		}
		else{
			$(this).append('<i class="fa sort-direction" aria-hidden="true"></i>');
		}
		if(sort_direction == 'desc'){
			$(this).find('i.sort-direction').addClass('fa-arrow-circle-down');
		}
		else{
			$(this).find('i.sort-direction').addClass('fa-arrow-circle-up');
		}

	});


});


