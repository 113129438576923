$(document).on('ready', function(){
	$('.script').on('change', '.broadband-product-selector', function(){
		
		var name = $(this).attr('name');
		var parts = name.split('_');
		var type = parts[0];
		if(name.indexOf('_package') !== -1){
			//we are toggling a top level - if this is off we need to hide children if not then we show
			var children = $('.script :input[name^="' + type + '"]:not([name="' + name + '"])');
			var value = $(this).val();
			if(!value){
				//lets hide 
				$.each(children, function(i, child){
					$(child).closest('.form-group').hide();
				});
			}
			else{
				$.each(children, function(i, child){
					$(child).closest('.form-group').show();
				});
			}
		}

	});
});