$(document).ready(function() {
    $('.script').on('click', '.carbon-offset-select-button', function () {
        var product_id = $(this).data('product_id');
        var that = this;
		var script = $(this).closest('.script');
		window[$(script).data('script-name') + '_script'].handleForm(function(){
            var data = {};
            data['ident'] = $('#active_step').data('script-ident');
            data['quote_id'] = $(that).data('quote-id');
            data['product'] = 'carbonoffset';
            data['product_id'] = product_id;

            makeRequest("/basket/addtobasket", data, function(response) {
                if(response.success) {
                    if(response.success == true) {
						var quote_id =  $(that).closest('.script').data("quoteid");
				
						makeRequest('/script/unskip/carbon-offset', {'quote_id' : quote_id}, function(response){
							if(response.success) {
								window[$(script).data('script-name') + '_script'].checkStep($(script).find('#active_step').data('script-ident'), function(response){
									if(response.step){
										window[$(script).data('script-name') + '_script'].handleStep(response.step)
									}
									else{
										window[$(script).data('script-name') + '_script'].handleStep($(that).data('success-step'))
									}
								});		
							}
							else{
								error_message(response.error);
							}
						})
                    }
                    else {
                        if(response.error) {
                            error_message(response.error);
                        }
                        else {
                            error_message('error, turn it off and on again');
                        }
                    }
                }
            });
        });
    });
   
	$('#lead_script').on('click', '#carbon-offset-customer-details-continue', function () {
		var that = $(this);
		var customer_validator = new customerValidator();
		var quote_id =  $(this).closest('#lead_script').data("quoteid");
		

		//because the customer validator allows blank emails 
		if(!$('#lead_script #customer-details-email').val().length){
			$('#lead_script #email-error').html("Please enter a valid email address");
			return false;
		}

		var validation = [
			function(next) { customer_validator.validateTitle($('#lead_script #customer-details-title').val(),next); },	
			function(next) { customer_validator.validateFirstName($('#lead_script #customer-details-firstname').val(),next); },
			function(next) { customer_validator.validateLastName($('#lead_script #customer-details-lastname').val(),next); },
			function(next) { customer_validator.validatePhone($('#lead_script #customer-details-phone').val(),next); },
			function(next) { customer_validator.validateEmail($('#lead_script #customer-details-email').val(),next); }
		];

		customer_validator.validateAll(validation,function(errors,data){
			if(errors !== null){
				$.each(errors,function(index,error){
					$('#lead_script #'+error.target).html(error.message);
				});
			}
			else{
				var post_data = {
					'quote_id' : quote_id,
					'data' : {}
				};
				$.each(data, function(i, item){
					post_data['data'][item.field] = item.value;
				});
				makeRequest("/script/customer-details", post_data, function(response) {
					if(response.success) {
						if(response.success == true) {
							lead_script.handleStep($(that).data('success-step'));
						}
						else {
							if(response.error) {
								error_message(response.error);
							}
							else {
								error_message('error, turn it off and on again');
							}
						}
						//refresh the contact info on the left hand side
						refeshContactDetails();
					}
					else if(isset(response.errors)){
						$.each(response.errors, function(field, error){
							error_message(error);
						})
					}
					else{
						var error = 'an unknown error occurred, please refresh';
						if(isset(response.error)){
							error = response.error;
						}
						error_message(error);
					}
				});
			}
		});
    });
	
	$('.script').on('click', '#carbon-offset-confirm-address-continue', function () {

		var script = $(this).closest('.script');
		if($(this).data('quote-id')){
			var quote_id = $(this).data('quote-id');
		}
		else{
			var quote_id =  $(script).data("quoteid");
		}
		var that = $(this);
			
		var data = {};
		if($('#gbg_section').length && $('#gbg_section').is(':visible')){
			//we are dealling with an ehl address
			if(!$('#gbg_addresses').val() || $('#gbg_addresses').val() == 'PLEASE SELECT AN ADDRESS HERE'){
				error_message('you MUST select an address here');
				return false;
			} 

			data.type = 'gbg';
			data.external_address_id = $('#gbg_addresses').val();
			data.postcode = $('#gbg_addresses option:selected').data('postcode');
			data.address_text = $('#gbg_addresses option:selected').text();
			data.quote_id = quote_id;
		}
		else if($('#energy-address-manual').is(':visible')){
			var address_validator = new addressValidator();
			address_validator.validateManualAddress(function(errors,data){
				if(errors !== null){
					$.each(errors,function(index,error){
						if($('#'+error.target).length){
							$('#'+error.target).html(error.message);
						}
						else{
							$('#error-other').html(error.message.text);
						}
					});
				}
				else{

					//clean the data from the validator 
					var clean_data = {};
					$.each(data, function(i, item){
						clean_data[item.field] = item.value;
					});

					var post_data = {};
					//the manual address is all good
					post_data.type = 'manual';
					post_data.number = clean_data['house-number'];
					post_data.line_1 = clean_data['address-1'];
					post_data.line_2 = clean_data['address-2'];
					post_data.town = clean_data['town'];
					post_data.county = clean_data['county'];
					post_data.postcode = clean_data['postcode'];
					post_data.quote_id = quote_id;
					makeRequest('/address/send_dynamic_address', post_data, function(response){
						if(response.success) {
							window[$(script).data('script-name') + '_script'].handleStep($(that).data('success-step'));
						}
						else{
							error_message(response.error);
						}
					});
				}
			}, script);
		}
		if(isset(data.type)){
			makeRequest('/address/send_dynamic_address', data, function(response){
				if(response.success) {
					window[$(script).data('script-name') + '_script'].handleStep($(that).data('success-step'));
				}
				else{
					error_message(response.error);
				}
			});
		}
	});
	
	$('.script').on('click', '.skip-carbon-offset', function () {
		var script = $(this).closest('.script');
		var quote_id =  $(script).data("quoteid");
		var that = $(this);

		makeRequest('/script/skip/carbon-offset', {'quote_id' : quote_id}, function(response){
			if(response.success) {
				window[$(script).data('script-name') + '_script'].handleStep($(that).data('success-step'));
			}
			else{
				error_message(response.error);
			}
		})
	});
	
	$('#lead_script').on('click', '.show-co-text2', function () {
		$('.co-text2').show();
		$('#tariffListContainer').show();
		$('.co-text1').hide();
	});
	
	$('#lead_script').on('click', '.show-skip-carbon-offset-objection-text', function () {
		$('.carbon-offset-objection-text').show();
		$('.carbon-offset-skip-button').show();
	});
});