$(function() {

	registerCallbackOnAllScripts('boiler-care.introduction', function(){
		$('.script .select2').select2({
			'width': '100%'
		});
	});
	
	registerCallbackOnAllScripts('boiler-care.fact_find', function(){
		$('.script .select2').select2({
			'width': '100%'
		});
	});
	
	registerCallbackOnAllScripts('boiler-care.pitch', function(){
		$('.script [data-toggle="tooltip"]').tooltip({
			'container':"body"
		});
	});
	
	registerCallbackOnAllScripts('boiler-care-aftersale.additional_questions', function(){
		$('.script .select2').select2({
			'width': '100%'
		});
	});

	registerCallbackOnAllScripts('boiler-care.correspondence_address', function(){
		
		$('.script .select2').select2({
			'width': '100%'
		});
	});
});