
var sort_update = function(e, ui){
	showLoader();
	if(this === ui.item.parent()[0]) {
		var steps_in_group = [];
		var hit_group = false;
		var group_id = $('#script-group-steps-container').data('script-group-id');
		$.each($('#script-group-steps-container > #script_group_steps_sort > li'), function(i, element){
			if($(element).data('step-id')){
				steps_in_group.push($(element).data('step-id'));
			}
			else if($(element).data('script-group')){
				getStepsForGroup($(element));
				hit_group = true;
			}
		});
		if(!hit_group){
			update_group(group_id,steps_in_group);
		}
	}
}

var script_groups_sortable = function() {
	$( "ul.sort-list" ).sortable({
		connectWith: ".reportSortable",
//		appendTo: "body",
//		helper: "clone",
//		containment: "window",
		update: sort_update
	}).disableSelection();
};

var success_message = function(){
	$('.update-success').fadeIn().text('Updated');
	setTimeout(function(){
		$('.update-success').fadeOut();
	},5000);
};

var update_group = function(group_id,steps_in_group){
	showLoader();
	var data = {
		steps_in_group : steps_in_group
	};
	makeRequest('/script-groups/update-group/'+group_id, data, function(response){
		if(isset(response.html)){
			$('.update-error').hide(); //this is here just incase the errors are actually visible
			$('#script-group-steps').html(response.html);
			script_groups_sortable();
			$('.script-group-edit-panel').show();
			$('#all-script-steps  div.delete-trigger').css('display', 'none');
			success_message();
		}
		else{
			var error = 'unable to update script group';
			if(isset(response.error)){
				error = response.error;
			}
			error_message(error);
		}
		hideLoader();
	});
};


var getStepsForGroup = function(element){
	$.ajax({
		type: "GET",
		dataType: 'json',
		url: '/script-groups/group-steps/'+$(element).data('script-group'),
		success: function(response) {
			if(response){
				element.replaceWith(response.html);
				sort_update();
			}
		},
		error: function (error) {
			alert(error);
		}
	});
	
}

$(function() {
	if($('#script-groups').length){
		script_groups_sortable();
	}
	
	$('#script-groups').on('click', '.edit-trigger', function() {
		var script_group_id = $(this).data('script-group-id');
		$.ajax({
			url: '/script-groups/edit/'+script_group_id,
			dataType: 'json',
			success: function(response) {
				if(response.success){
					$('#script-group-steps').html(response.html);
					script_groups_sortable();
					$('.script-group-edit-panel').show();
					$('#all-script-steps  div.delete-trigger').css('display', 'none');
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});

	});

	$('#script-group-steps').on('click', '.delete-trigger', function() {
		var steps_in_group = [];
		var to_remove = $(this).data('step');
		var group_id = $('#script-group-steps-container').data('script-group-id');
		$('#script-group-steps-container > #script_group_steps_sort > li').each(function(){
			if($(this).data('step-id') != to_remove){
				steps_in_group.push($(this).data('step-id'));
			}
		});	
		update_group(group_id,steps_in_group);
		$('#script-group-steps-container > #script_group_steps_sort > li[data-step-id='+ to_remove +']').remove();
	});

	$('#script-group-steps').on('keyup','.search-script-group-steps',function(){
		var valThis = $(this).val().toLowerCase();
		var collapseIn = $('#script-group-steps').children().children();
		var target = $(this).parent().parent().find('#script_group_steps_sort');
		if(valThis === ""){
			collapseIn.removeClass('in');
			$(target).find('li').show();
		} else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
				$(this).parent().parent().addClass('in');
			});
		}
	});
	
	
	$('.script-group-edit-panel').on('click', '.edit-trigger', function(){
		var step_id = $(this).data('step');
		showEditBox(step_id);
		
	})
	
	$('#script-groups').on('keyup', '.search-script-groups', function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#script_groups_sort');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		}
	});

	$('#script-all-steps').on('keyup', '.search-script-all-steps', function () {
		var valThis = $(this).val().toLowerCase();
		var new_step_type = $('#new_step_type').val();
		var target = $(this).parent().parent().find('#script_group_steps_sort');

		if (valThis.startsWith('/', 0)) {
			valThis = valThis.substring(1);

			if(valThis === ""){
				$(target).find('li').show();
			} 
			else {
				$(target).find('li').each(function(){
					var text = $(this).text();
					var compare = text.toLowerCase();
					(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
					var target_id = $(this).parent().parent().attr('id');
					if($(this).parent().find('li:hiddenByParent, li:visible').length) {
						$('button[data-target="#' + target_id + '"]').show();
					}
					else {
						$('button[data-target="#' + target_id + '"]').hide();
					}
				});
			}
		}
		else {
			if (valThis === "") {
				$(target).find('li').show();
				$(target).find('button').each(function () {
					$(this).show();
					$(this).next().removeClass('in');
				});
			}
			else {
				if (valThis.length > 3) {
					$(target).find('button').each(function () {
						var btn_text = $(this).text().toLowerCase().trim();
						if (new_step_type == btn_text) {
							var list = $(this).next();
							$(list).addClass('in');

							$(list).find('li').each(function () {
								var text = $(this).text();
								var compare = text.toLowerCase();
								(compare.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
							});
						}
						else {
							$(this).hide();
						}
					});
				}
			}
		}
	});

	$('#script_inputs').on('keyup', '.search-script-all-steps', function () {
		var valThis = $(this).val().toLowerCase();
		var target = $(this).closest('.searchable-script-list').find('#all_script_sort');

		if (valThis === "") {
			$(target).find('li').show();
			$(target).find('button').each(function () {
				$(this).show();
				$(this).next().removeClass('in');
			});
		}
		else {
			if (valThis.length > 3) {
				$(target).find('li').each(function () {
					var text = $(this).find('.name').text();
					var compare = text.toLowerCase();
					(compare.indexOf(valThis) >= 0) ? $(this).show() : $(this).hide();
				});
			}
		}
	});
	
	$('#script-groups').on('click', '#add-script-group', function() {
		var parent = $(this).parent(); 
//		parent.find('span.errors').html('');
		parent.parent().find('.module-error').hide().text('');
		var script_group_name = parent.find('input[name="new-script-group-name"]').val();
		if(script_group_name.length <= 5) {
			parent.parent().find('.module-error').fadeIn('fast').text('You must enter a name');
		} 
		else {
			$.ajax({ cache: false,
				url: "/script-groups/create-group",
				type:"POST",
				dataType: 'json',
				data: { "script-group-name" : script_group_name },
				success: function (response) {
 					if(response.success === true) {
						//redirects to the create new action group page
						window.location.reload();
					} 
					else {
						if(response.error) {
							parent.parent().find('.module-error').fadeIn('fast').text(response.error);
						}
					}
				},
				error: function (error) {
					//console.log(error);
				}
			});
		};
	});
	
	$('#script-groups').on('click', '#add_condition', function() {
		
		var group_id = $(this).data('group');
		var parent = $(this).parent().parent();
		var new_holder = parent.find('#new_condition');
		var html = new_holder.html();
		new_holder.before(html);
		new_holder.prev().find('select').select2({
			'width': '100%',
		});
		
	});
	
	$('#script-groups').on('click', '#save_condition', function() {
		
		var group_id = $(this).data('group');
		var condition = $(this).data('condition');
		var parent = $(this).parent().parent();
		
		var key = parent.find('.profile_key').val();
		var value = parent.find('#condition_value').val();
		var action = parent.find('#condition_action').val();
		var cascade = parent.find('#condition_cascade').val();
		
		$.ajax({ cache: false,
			url: "/script-groups/save-condition/" + group_id,
			type:"POST",
			dataType: 'json',
			data: { 
				"condition_id" : condition,
				"key" : key,
				"value" : value,
				"action" : action,
				"cascade" : cascade
			},
			success: function (response) {
				if(response.success === true) {
					parent.replaceWith(response.html);
					success_message()
				} 
				else {
					if(response.error) {
						parent.parent().parent().parent().parent().find('.module-error').fadeIn('fast').text(response.error);
					}
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		
	});
	
	$('#script-groups').on('click', '#delete_condition', function(){
		var group_id = $(this).data('group');
		var condition = $(this).data('condition');
		var parent = $(this).parent().parent();
		if(condition == 'new'){
			// it is a new one - just remove the html 
			parent.remove();
			return;
		}
		else{
			$.ajax({ cache: false,
			url: "/script-groups/delete-condition/" + group_id,
			type:"POST",
			dataType: 'json',
			data: { 
				"condition_id" : condition,
			},
			success: function (response) {
				if(response.success === true) {
					parent.remove();
					success_message();
				} 
				else {
					if(response.error) {
						parent.parent().parent().parent().parent().find('.module-error').fadeIn('fast').text(response.error);
					}
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		}
		
	});
	
	$('#script-groups').on('change', '.profile_key', function(){
		
		var selected = $(this).find('option:selected');
		var type = selected.data('type');
		var key = selected.val();
		var parent = $(this).parent().parent();
		
		if(type == 2){
			//we need to make the input value a select box
			$.ajax({ cache: false,
				url: "/script-groups/condition_predefined_values/" + key,
				type:"GET",
				dataType: 'json',
				success: function (response) {
					if(response.success === true) {
						parent.find('#condition_value').replaceWith(response.html);
					} 
					else {
						if(response.error) {
							parent.parent().parent().parent().parent().find('.module-error').fadeIn('fast').text(response.error);
						}
					}
				},
				error: function (error) {
					//console.log(error);
				}
			});
		}	
		else{
			parent.find('select#condition_value').replaceWith('<input id="condition_value" type="text" value="">');
		}
		
	});

	
	$('body').on('click', '#all-script-steps #create_script_step', function() {
		var parent = $(this).parent();
		var type = parent.find('#new_step_type').val();
		var ident = parent.find('#new_scrpit_step_name').val();
		showCreateBox(type + '.' + ident);
		return;
	});
	
	var updateAllSteps = function(){
		$.ajax({
			url: '/script-groups/get-all-steps',
			dataType: 'json',
			success: function(response) {
				if(response.success){
					$('#all-script-steps').replaceWith(response.html);
					script_groups_sortable();
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
	};
	
	
	var showCreateBox = function(step_ident) {
        const message = new MessageBox('editscript', 'Create step ' + step_ident);
        message.setData({
			before : createBoxBefore.bind(this, step_ident),
            after: editBoxAfter.bind(this, message),
            extra: function(form) { 
				form.push({ 
					name: 'html', value: $('#step-edit-wysiwyg').summernote('code') 
				}); 
				form.push({
					name:'view_ident', value : $('#type_select').val() + '.' + $('#ident_name').val()
				});
				form.push({
					name:'friendly_name', value : $('#friendly_name').val() 
				});				},
            ajax: { 
				url: '/script-groups/update-step/' + step_ident, 
				method: 'POST',
				success: function (response) {
					//console.log(response);	
					if ( response.render_status === 200) {
						updateAllSteps();
						alert("Successful update of this script step");
					} 
					else {
						alert("Update of this script step has failed; look for:\n\n"+response.render_error);
					}
				},
				error: function(response) {
					alert("Update of this script step has failed:\n\nLikely cause is 422 - Unprocessable Entity, so check for incorrect objects, methods etc.\n\nFix this error before attempting to refresh.");		
				},
			}
		});	
	
        message.run();
    };
	
	var createBoxBefore = function(step_ident, cb) {
        const request = $.ajax({
            method: 'get',
            dataType: 'json',
            url: '/script-groups/create-step/' + step_ident 
        });

        request.done(function(data) {
            cb(data);
			
			if(!$('#type_select').find('option[value="' + step_ident.split('.')[0] + '"]').length){
				var new_option = new Option(step_ident.split('.')[0], step_ident.split('.')[0], false, false);
				$('#type_select').append(new_option)
			}
			$('#type_select').val(step_ident.split('.')[0]);
			$('#ident_name').val(step_ident.split('.')[1]);
			
        });
    };
	
	var showEditBox = function(step_ident) {
        const message = new MessageBox('editscript', 'Edit step ' + step_ident);
        message.setData({
            before: editBoxBefore.bind(this, step_ident),
            after: editBoxAfter.bind(this, message),
            extra: function(form) { 
				form.push({ 
					name: 'html', value: $('#step-edit-wysiwyg').summernote('code') 
				}); 
				form.push({
					name:'view_ident', value : $('#type_select').val() + '.' + $('#ident_name').val()
				});
				form.push({
					name:'friendly_name', value : $('#friendly_name').val() 
				});			},
            ajax: { 
				url: '/script-groups/update-step/' + step_ident, 
				method: 'POST',
				success: function (response) {
					//console.log(response);	
					if ( response.render_status === 200) {
						updateAllSteps();
						alert("Successful update of this script step");
					} 
					else {
						alert("Update of this script step has failed; look for:\n\n"+response.render_error);
					}
				},
				error: function(response) {
					alert("Update of this script step has failed:\n\nLikely cause is 422 - Unprocessable Entity, so check for incorrect objects, methods etc.\n\nFix this error before attempting to refresh.");		
				},
			}
		});	
	
        message.run();
    };
    
    var editBoxBefore = function(step_ident, cb) {
        const request = $.ajax({
            method: 'get',
            dataType: 'json',
            url: '/script-groups/edit-step/' + step_ident 
        });

        request.done(function(data) {
            cb(data);
			
			$('#type_select').val(step_ident.split('.')[0]);
			$('#ident_name').val(step_ident.split('.')[1]);
			
        });
    };
    
    var editBoxAfter = function(message) {
        $('#step-edit-wysiwyg').summernote({
            height: 400,
            minHeight: 300,
            maxHeight: 600,
            focus: true,
            toolbar: [
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['font', ['fontsize', 'color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['meta', ['codeview', 'undo', 'redo']]
            ]
        });

        message.root.form.on('click', 'a', function(e) {
            e.preventDefault();
            $('#step-edit-wysiwyg').summernote('focus');
            const text = "{{ $s('" + $(this).data('value') + "') }}";
            $('#step-edit-wysiwyg').summernote('insertText', text);
        });
    };
	
});