$(function() {
	
	registerCallbackOnAllScripts('insurance.fact_find', function(){
		$('.script .select2').select2({
			'width': '100%'
		});

		$('.script input[name="insurance-renewal-date"]').daterangepicker({
			"singleDatePicker": true,
			"locale": {
				"format": 'DD/MM/YYYY',
				"separator": "-"
			},
			"showDropdowns": true
		});
	});

	registerCallbackOnAllScripts('insurance.welcome_step', function(){
		$('.script .select2').select2({
			'width': '100%'
		});
	});
});