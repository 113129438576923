$(function () {
    if ($('#admin_dialler_inbound_service').length > 0) {

        //region Constants

        let $admin_dialler_inbound_service = $('#admin_dialler_inbound_service');
        let $services = $('#services');
        let $service = $('#service');

        //endregion

        //region Functions

        function setupSearch(input_id, item_id) {
            // Hide/Display divs depending on text search input
            $(input_id).on('keyup', function () {
                let search = $(this).val().toLowerCase();
                let $products = $(item_id);

                // Make sure all are shown if the search input is empty
                if (search.length === 0) {
                    $products.show();
                } 
				else {
                    // Check each div to see if any of the text matches the search input
                    $products.each(function () {
                        let $this = $(this);

                        if ($this.text().toLowerCase().indexOf(search) === -1) {
                            $this.hide();
                        } 
						else {
                            $this.show();
                        }
                    });
                }
            });
        }

        function loadInboundService(service_id) {
            showLoader();

            let data = {
                service_id
            };

            $service.hide();
            $service.data('service-id', service_id);

            makeRequest('/admin/dialler/services/inbound/service', data, function (response) {
                if (response.success) {
                    let $html = $(response.html);

                    $service.html($html).show();
                    $service.show();

                    $("#phone-numbers .connectedSortable").sortable({
                        connectWith: ".connectedSortable"
                    }).disableSelection();
                } 
				else {
                    let error = 'Unable to load inbound service';
                    if (isset(response.error)) {
                        error = response.error;
                    }

                    error_message(error);
                }
                hideLoader();
            });
        }

        //endregion

        setupSearch('#inbound_service_search', '#services .team-container');

        $services.on('click', '.edit-service', function() {
            let $parent = $(this).parent();
            let inbound_service_id = $parent.data('inbound-service-id');

            loadInboundService(inbound_service_id);
        });

        $services.on('click', '.delete-service', function() {
            let $parent = $(this).parent();
            let service_id = $parent.data('inbound-service-id');

            let data = {
                service_id
            }

            let message = 'Are you sure you want to delete this service?';
            if (confirm(message)) {
                $service.hide();
                makeRequest('/admin/dialler/services/inbound/service/delete', data, function (response) {
                    if (response.success) {
                        $parent.remove();

                        success_message('Service deleted');
                    } 
					else {
                        let error = 'Unable to delete inbound service';
                        if (isset(response.error)) {
                            error = response.error;
                        }

                        error_message(error);
                    }
                });
            }
        });

        $services.on('click', '#create_inbound_service', function() {
            $service.hide();
            showModal('dialler_inbound_service_modal');
        });

        $('#confirm_dialler_inbound_service_creation').on('click', function() {
            let $name = $('#dialler_service_name');
            let $dialler_id = $('#dialler_service_dialler');
            let name = $name.val();
            let dialler_id = $dialler_id.val();

            let data = {
                name,
                dialler_id
            }

            makeRequest('/admin/dialler/services/inbound/service/create', data, function (response) {
                if (response.success) {
                    success_message('Service created');

                    $('#inbound_services_list').append(response.html);

                    $name.val('');
                    $dialler_id.val('');
                } 
				else {
                    let error = 'Unable to create service';
                    if (isset(response.error)) {
                        error = response.error;
                    }

                    error_message(error);
                }
            });
        });

        $service.on('sortreceive', '#numbers', function(event, ui) {
            let number_id = ui.item.data('number-id');
            let service_id = $service.data('service-id');

            let data = {
                number_id,
                service_id
            }

            makeRequest('/admin/dialler/services/inbound/service/number/add', data, function (response) {
                if (response.success) {
                    success_message('Number added to service');
                } 
				else {
                    let error = 'Unable to add number to service';
                    if (isset(response.error)) {
                        error = response.error;
                    }

                    error_message(error);
                }
            });
        });

        $service.on('sortreceive', '#available_numbers', function(event, ui) {
            let number_id = ui.item.data('number-id');
            let service_id = $service.data('service-id');

            let data = {
                number_id,
                service_id
            }

            makeRequest('/admin/dialler/services/inbound/service/number/remove', data, function (response) {
                if (response.success) {
                    success_message('Number removed from service');
                } 
				else {
                    let error = 'Unable to remove number from service';
                    if (isset(response.error)) {
                        error = response.error;
                    }

                    error_message(error);
                }
            });
        });

        $service.on('click', '#create_number', function() {
            showModal('dialler_phone_number_modal');
        });

        $service.on('click', '.delete-number', function() {
            let $this = $(this);
            let $parent = $this.parent();

            let number_id = $parent.data('number-id');

            let data = {
                number_id
            }

            let message = 'Are you sure you want to delete this phone number?';
            if (confirm(message)) {
                makeRequest('/admin/dialler/services/number/delete', data, function (response) {
                    if (response.success) {
                        $parent.remove();

                        success_message('Phone number deleted');
                    } 
					else {
                        let error = 'Unable to delete phone number';
                        if (isset(response.error)) {
                            error = response.error;
                        }

                        error_message(error);
                    }
                });
            }
        });

        $('#confirm_dialler_phone_number_creation').on('click', function() {
            let $name = $('#dialler_number_name');
            let $number = $('#dialler_number');

            let name = $name.val();
            let number = $number.val();
            let service_id = $service.data('service-id');

            let data = {
                name,
                number,
                service_id
            }

            makeRequest('/admin/dialler/services/number/create', data, function (response) {
                if (response.success) {
                    success_message('Phone number added');

                    $('#available_numbers').append(response.html);

                    $name.val('');
                    $number.val('');
                } 
				else {
                    let error = 'Unable to create service';
                    if (isset(response.error)) {
                        error = response.error;
                    }

                    error_message(error);
                }
            });
        });
    }
	$('.dialler-list-management-page').on('click', '.finish-stale-file-history-item', function(){
		var item_id = $(this).data('item-id');

		var data = {
			'item_id' : item_id
		};

		var that = this;
		makeRequest('/dialler/history/mark/finished', data, function(response){
			if(isset(response.success) && response.success){
				success_message('marked as finished');
				$(that).closest('.stale-item').remove();
			}
			else{
				var error = 'unable to mark as finished';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
	
	$('.dialler-list-management-page').on('click', '.fail-stale-file-history-item', function(){
		var item_id = $(this).data('item-id');

		var data = {
			'item_id' : item_id
		};

		var that = this;
		makeRequest('/dialler/history/mark/failed', data, function(response){
			if(isset(response.success) && response.success){
				success_message('marked as failed');
				$(that).closest('.stale-item').remove();
			}
			else{
				var error = 'unable to mark as failed';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
});