const twilio_interface = require('./twilio_interface');

var showCallNotification = function(number){

	var container = $('.call-notification-holder ');
	$(container).find('.number').text(number);
	$(container).addClass('active');

	desktopNotification('Incoming call', number);
}

var hideCallNotification = function(){
	var container = $('.call-notification-holder ');
	$(container).removeClass('active');
}

var setInCall = function(phone_number){
	$('#softphone_input').val(phone_number);
	$('#softphone_input').addClass('active');
}

var setNotInCall = function(){
	$('#softphone_input').val('');
	$('#softphone_input').removeClass('active');
	$('#softphone_input').removeClass('connected');
}


var available_states = ['ringing', 'in-progress', 'connected', 'ended'];
var setCallState = function(state){
	setSubCallState('.call-status-indicator', state);
}

var setSubCallState = function(element, state){
	$.each(available_states, function(i, available_state){
		if(available_state !== state){
			$(element).removeClass(available_state);		
		}
	})
	$(element).addClass(state);
}

var available_contact_states = ['available', 'offline', 'on-call'];
var setContactState = function(user_short_code, state){
	var contact_item = $('.contact-row[data-user-short-code="' + user_short_code +'"]');
	$.each(available_contact_states, function(i, available_state){
		if(available_state !== state){
			$(contact_item).removeClass(available_state);		
		}
	})
	$(contact_item).addClass(state);
}


var openSideBarSubMenu = function(to_open){
	$('div.slide-side-bar .back-button').show();
	$('div.slide-side-bar').find('.side-bar-sub-level').removeClass('open');
	$('div.side-menu').addClass('sub-open');
	$('div.slide-side-bar .' + to_open).addClass('open');
}

var closeSideBarSubMenu = function(){
	$('div.slide-side-bar .back-button').hide();
	$('div.slide-side-bar').find('.side-bar-sub-level').removeClass('open');
	$('div.side-menu').removeClass('sub-open');
}


var ee_methods = {
	'katyperry' : function(){
		$.each($('.keypad-item'), function(i, item){
			var rand = (Math.random()*5).toFixed(3);
			$(item).css('animation', 'bang 1s ease ' + rand + 's')
		});
	},
	'beyblade' : function(){
		var rand = (Math.random()*5).toFixed(3);
		$.each($('.keypad-item'), function(i, item){
			$(item).addClass('fa-spin')	
			setTimeout(function(){
				$(item).removeClass('fa-spin')	
			}, rand*1000);
		});
	},
	'shakeit' : function(){
		$.each($('.keypad-item'), function(i, item){
			var rand = (Math.random()*5).toFixed(3);
			$(item).css('animation', 'nudge-animation 1s ease ' + rand + 's')
		});
	}

}
var easterEgg = function(phone_number){
	var key = phone_number.toLowerCase();
	if(isset(ee_methods[key])){
		ee_methods[key]();
		return true;
	}
	return false;
}

var load_actions = {
	'call': function(params){
		var url_number = params.phone_number;
		if(url_number && url_number.length){
			$('#softphone_input').val(url_number);
			return function(){
				twilio_interface.makeCall(url_number);
			}
		}
	},
	'listen_in': function(params){
		var external_id = params.external_id;
		if(external_id && external_id.length){
			return function(){
				twilio_interface.listenIn(external_id);
			}
		}
	},
	'barge_in': function(params){
		var external_id = params.external_id;
		if(external_id && external_id.length){
			return function(){
				twilio_interface.bargeIn(external_id);
			}
		}
	},
	'join_conference': function(params){
		var external_id = params.external_id;
		if(external_id && external_id.length){
			return function(){
				twilio_interface.joinConference(external_id);
			}
		}
	}
}

$(document).ready(function(){

	if($('#call_action_button').length){


		window.twilio_interface = require('../softphone/twilio_interface');

		var token = $('#softphone_container').data('token');

		var connect_callback = function(){};

		var action = urlParam('action');
		if(isset(load_actions[action])){
			var params = getAllUrlParams();
			connect_callback = load_actions[action](params);
		}
		window.history.replaceState(null, null, window.location.pathname);
		twilio_interface.connect(token, connect_callback);

		$('#call_action_button').on('click', function(){
			if($(this).hasClass('loading')){
				return false;
			}
			if($(this).hasClass('end_call')){
				twilio_interface.hangup();
			}
			else{
				var phone_number = $('#softphone_input').val();
				if(phone_number.length){
					if(!easterEgg(phone_number)){
						makeLoadingIcon($(this).find('i'));
						$(this).addClass('loading');
						var that = this;
						twilio_interface.makeCall(phone_number, function(){
							restoreIcon($(that).find('i'));
							$(that).removeClass('loading');
						});
					}
				}
			}
		});


		twilio_interface.onAccept(function(connection){
			$('#call_action_button').addClass('end_call');
			$('#call_options').fadeIn();
			hideCallNotification();
			var caller_id = 'unkown';
			if(isset(connection.parameters) && connection.parameters.From){
				caller_id = connection.parameters.From;
			}
			else if(isset(connection.message) && connection.message.To){
				caller_id = connection.message.To;
				if(typeof(caller_id) == 'string'){
					if(caller_id.indexOf('44') == 0){
						caller_id = caller_id.replace('44', '0');	
					}
				}
				else{
					var contact_item = $('.contact-row[data-user-id="' + caller_id + '"]').first();
					caller_id = $(contact_item).find('.contact-name').text().trim();
				}
			}
			setInCall(caller_id);
		});
		
		twilio_interface.onDisconnect(function(connection){
			$('#call_action_button').removeClass('end_call');
			$('#call_options').fadeOut();
			$('[data-quick-action="hold"]').button('reset');
			$('[data-quick-action="hold"]').text('Hold');
			$('[data-quick-action="hold"]').data('quick-action', 'hold');
			hideCallNotification();
			setNotInCall();
		});

		twilio_interface.onIncoming(function(connection){
			showCallNotification(connection.parameters.From);
			connection.accept(function() {
				$('#call_status').text("In call with customer");
			});
		
			$('.accept-call').on('click', function(){
				connection.accept();
			});
			
			$('.reject-call').on('click', function(){
				connection.reject();
				$('#call_action_button').removeClass('end_call');
				$('#call_options').fadeOut();
				$('[data-quick-action="hold"]').button('reset');
				$('[data-quick-action="hold"]').text('Hold');
				$('[data-quick-action="hold"]').data('quick-action', 'hold');
				hideCallNotification();
				setNotInCall();
			});
		});


		$('#ready').on('change', function(){
			var user_id = $(this).data('user-id');
			twilio_interface.setAvailable(user_id);
		});

		$('body').on('keyup', function(e){
			if ($(e.target).closest("input").length) {
				return false;
			}
			var interesting_keys = ['0','1','2','3','4','5','6','7','8','9','#','*'];
			var key_pressed = e.key;
			if(interesting_keys.indexOf(key_pressed) !== -1){
				var target_button = $('.keypad-item[data-key="' + key_pressed + '"]');
				$(target_button).removeClass('simulate-click');
				setTimeout(function(){
					$(target_button).addClass('simulate-click');
				}, 10);
				$(target_button).trigger('click');
			}
			else{
				if (key_pressed == 'Enter') {
					e.preventDefault();
					// lets trigger call button press
					$('#call_action_button').trigger('click');
				}
				else if(key_pressed == 'Escape'){
					$('#call_action_button.end_call').trigger('click');
				}
				else if(key_pressed == 'Backspace'){
					var current_value = $('#softphone_input').val();
					if(current_value.length){
						$('#softphone_input').val(current_value.substr(0, current_value.length -1));
					}
				}
			}
		});
		
		$('#softphone_input').on('keyup', function(e){
			if (e.key == 'Enter') {
				e.preventDefault();
				// lets trigger call button press
				$('#call_action_button').trigger('click');
			}
			else if(e.key == 'Escape'){
				$('#call_action_button.end_call').trigger('click');
			}
		});

		var quick_call_actions = {
			'quickHold' : function(callback = function(){}){

				$('.call-option.hold').button('loading');
				twilio_interface.quickHold(function(response){
					if(isset(response.success) && response.success){
						$('.call-option.hold').button('held')
						$('.call-option.hold').data('quick-action', 'unhold');
					}
					else{
						error_message('unable to place call on hold')
						$('.call-option.hold').button('ready')
					}
				});

			},
			
			'quickUnhold' : function(callback = function(){}){
				$('.call-option.hold').button('loading')
				twilio_interface.quickUnhold(function(response){
					if(isset(response.success) && response.success){
						$('.call-option.hold').button('ready')
						$('.call-option.hold').data('quick-action', 'hold');
					}
					else{
						error_message('unable to take call off hold')
						$('.call-option.hold').button('held')
					}
				});
			},
			
			'quickTransfer' : function(callback = function(){}){
				// we need to show a list of contacts with a search bar - that search you can put any number into to call an external number
				$('.transfer-modal-container').addClass('open');
			}


		}

		$('.call-options-menu').on('click', '.call-option', function(){
			var action = $(this).data('quick-action');
			var method_name = 'quick'  + ucfirst(action);
			if(isset(quick_call_actions[method_name])){
				var that = $(this);
				quick_call_actions[method_name](function(){
					
				});
			}
		});
	

		twilio_interface.onRinging(function(call_sid){
			var listeners = $('[data-call-sid="' + call_sid + '"');
			if(listeners.length){
				setSubCallState('[data-call-sid="' + call_sid + '"', 'ringing');
			}
			else{
				$('.call-status-indicator').text('Ringing');
				setCallState('ringing');
			}
		});

		twilio_interface.onCallConnect(function(call_sid){
			var listeners = $('[data-call-sid="' + call_sid + '"');
			if(listeners.length){
				setSubCallState('[data-call-sid="' + call_sid + '"', 'connected');
			}
			else{
				$('.call-status-indicator').text('');
				setCallState('in-progress');
				$('#softphone_input').addClass('connected');
			}
		});

		twilio_interface.onCallEnd(function(call_sid){
			var listeners = $('[data-call-sid="' + call_sid + '"');
			if(listeners.length){
				setSubCallState('[data-call-sid="' + call_sid + '"', 'ended');
			}
			else{
				setNotInCall();
			}
		});
		
		twilio_interface.onUserBusy(function(call_sid){
			var listeners = $('[data-call-sid="' + call_sid + '"');
			if(listeners.length){
				setSubCallState('[data-call-sid="' + call_sid + '"', 'ended');
			}
			else{
				setNotInCall();
			}
			error_message('user busy');
		});

		twilio_interface.onContactStatusUpdate(function(user_short_code, state){
			setContactState(user_short_code, state);
		});

	}

	var side_bar_state = 'closed';
	$('#close_softphone_side_bar').on('click', function(){
		$('#softphone_side_bar').css('width', '0px');
		$('#open_softphone_side_bar .animated-nav-icon').removeClass('open');
		side_bar_state = 'closed';
		closeSideBarSubMenu();
	});

	$('#open_softphone_side_bar').on('click', function(){
		if(side_bar_state == 'closed'){
			$('#softphone_side_bar').css('width', '300px');
			$('#open_softphone_side_bar .animated-nav-icon').addClass('open');
			side_bar_state = 'open';
		}
		else{
			$('#softphone_side_bar').css('width', '0px');
			$('#open_softphone_side_bar .animated-nav-icon').removeClass('open');
			side_bar_state = 'closed';
			closeSideBarSubMenu();
		}
	});

	$('div.slide-side-bar .show-sub-menu').on('click', function(){
		if(side_bar_state == 'closed'){
			$('#softphone_side_bar').css('width', '300px');
			$('#open_softphone_side_bar .animated-nav-icon').addClass('open');
			side_bar_state = 'open';
		}
		var target = $(this).data('target');
		openSideBarSubMenu(target);
	});

	$('div.slide-side-bar .back-button').on('click', function(){
		closeSideBarSubMenu();
	})

	$('.edit-profile-image').on('click', function(){
		$(this).parent().find('.hidden-file-input').trigger('click');
	});

	$('.hidden-file-input').on('change', function(){

		makeLoadingIcon($(this).closest('.settings-profile-image').find('.edit-profile-image i'));
		var file = $(this)[0].files[0];
		console.log(file);
		var form_data = new FormData();
		form_data.append('file', file);
		var that = this;
		var callback = function(response){
			if(isset(response.success) && response.success){
				$('.settings-profile-image img').attr('src', response.file_path);
			}
			else{
				var error = 'unable to upload image';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			restoreIcon($(that).closest('.settings-profile-image').find('.edit-profile-image i'));
		}
		$.ajax({ cache: false,
			url: '/user/upload/profile-image',
			type:"POST",
			data: form_data,
			processData: false,
			contentType: false,
			success: function (response) {
				callback(response);
			},
			error: function (error) {
				var result = {
					'success': false,
					'errors': ['an unknown error orccured']
				};
				if (isset(error.responseText)) {
					result.details = error.responseText;
				}
				callback(result);
			}
		});
		$(this).val(null);
	});

	$('.refresh-contacts-button').on('click', function(){
		if($(this).hasClass('loading')){
			return false;
		}
		$(this).addClass('loading');
		$(this).find('i').addClass('fa-spin');
		var that = this;
		makeRequest('/dialler/refresh/contacts', {}, function(response){
			var success = false;
			if(isset(response.success) && response.success && isset(response.html)){
				$('.contact-list').replaceWith(response.html);
				success = true;
				//lets now message to the websocket to update our set of users how care about us
				softphoneInterface.updateUserStateMap();
			}
			else{
				var error = 'refresh failed';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			$(that).find('i').removeClass('fa-spin');
			var outcome_class = success ?  'success-tick' : 'failed-cross';
			$(that).find('i').addClass(outcome_class);
			setTimeout(function(){
				$(that).find('i').removeClass(outcome_class)
				$(that).removeClass('loading');
			}, 2000);
		})

	})

	$('#contact_search_input').on('keyup', function(){
		var container = $(this).closest('.contact-search-section');
		var value = $(this).val().toLowerCase();
		if(value.length){
			$(container).find('.clear-input').show();
		}
		else{
			$(container).find('.clear-input').hide();
		}
		var contact_container = $(this).closest('.contacts');
		$.each($(contact_container).find('.contact-name'), function(i, element){
			if($(element).text().toLowerCase().indexOf(value) !== -1){
				$(element).closest('.contact-row').show();
			}
			else{
				$(element).closest('.contact-row').hide();
			}
		});
	});
	
	$('#transfer_to_input').on('keyup', function(){
		var container = $(this).closest('.transfer-modal-container');
		var value = $(this).val().toLowerCase();
		if(value.length){
			$(container).find('.clear-input').show();
		}
		else{
			$(container).find('.clear-input').hide();
		}
		var contact_container = $(container).find('.contacts');
		$.each($(contact_container).find('.contact-name'), function(i, element){
			if($(element).text().toLowerCase().indexOf(value) !== -1){
				$(element).closest('.contact-row').show();
			}
			else{
				$(element).closest('.contact-row').hide();
			}
		});
	});

	$('.clear-input').on('click', function(){
		var container = $(this).parent();
		var input = $(container).find('input');
		$(input).val('');
		$(input).trigger('keyup');
		$(this).hide();
	})

	$('.keypad-item').on('click', function(){
		var key = $(this).data('key');
		if(isset(key)){
			$('#softphone_input').val($('#softphone_input').val().concat(key));	
			twilio_interface.sendDTMF(key);
		}
	});


	$('#click_to_call_contact').on('click', function(e){
		e.preventDefault();

		var number_input = $(this).parent().find('#customer-details-telephone');
		var number = $(number_input).val();
		softphoneInterface.clickToCall(number);


	});

	$('.slide-side-bar').on('click', '.contact-row', function(){
		$(this).find('.contact-actions').collapse('toggle');
		$(this).find('.contact-caret').toggleClass('opened');// css('transform', 'rotate(88deg)');
	});
	
	$('.transfer-modal-container').on('click', '.contact-row', function(){
		//we need to make a call to the user inviting them to our conference
		if($(this).hasClass('loading') || $(this).hasClass('connected')){
			return false;
		}
		makeLoadingIcon($(this).find('.contact-caret i'));
		$(this).addClass('loading');
		var user_id = $(this).data('user-id');
		var that = this;
		twilio_interface.innternalTransferRequest(user_id, function(response){
			if(isset(response.success) && response.success){
				if(isset(response.new_call_sid)){
					$(that).attr('data-call-sid', response.new_call_sid);
				}
			}
			else{
				var error = 'unable to create call';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			restoreIcon($(that).find('.contact-caret i'));
			$(that).removeClass('loading');

		});
	});
	
	$('.contacts').on('click', '.contact-row .contact-action.call', function(e){
		//we need to make a call to the selected user
		e.stopPropagation();
		if($(this).hasClass('loading')){
			return false;
		}
		makeLoadingIcon($(this).find('i'));
		$(this).addClass('loading');
		var that = this;
		var user_id = $(this).data('user-id');
		twilio_interface.makeInternalCall(user_id, function(){
			restoreIcon($(that).find('i'));
			$(that).removeClass('loading');
		});
	});

	$('.contacts').on('click', '.contact-row .contact-action.listen', function(e){
		//we need to make a call to the selected user
		e.stopPropagation();
		if($(this).hasClass('loading')){
			return false;
		}
		makeLoadingIcon($(this).find('i'));
		$(this).addClass('loading');
		var that = this;
		var user_id = $(this).data('user-id');
		twilio_interface.listenInToUser(user_id, function(){
			restoreIcon($(that).find('i'));
			$(that).removeClass('loading');
		});
	});

	$('.contacts').on('click', '.contact-row .contact-action.barge', function(e){
		//we need to make a call to the selected user
		e.stopPropagation();
		if($(this).hasClass('loading')){
			return false;
		}
		makeLoadingIcon($(this).find('i'));
		$(this).addClass('loading');
		var that = this;
		var user_id = $(this).data('user-id');
		twilio_interface.bargeInToUser(user_id, function(){
			restoreIcon($(that).find('i'));
			$(that).removeClass('loading');
		});
	});

	$('.contacts').on('click', '.contact-row .contact-action.end-call', function(e){
		//we need to make a call to the selected user
		e.stopPropagation();
		if($(this).hasClass('loading')){
			return false;
		}
		makeLoadingIcon($(this).find('i'));
		$(this).addClass('loading');
		var that = this;
		var user_id = $(this).data('user-id');
		twilio_interface.endSpecificConferenceLeg(user_id, function(){
			restoreIcon($(that).find('i'));
			$(that).removeClass('loading');
		});
	});
	
	$('.contacts').on('click', '.contact-row .contact-action.connect-transfer', function(e){
		//we need to make a call to the selected user
		e.stopPropagation();
		if($(this).hasClass('loading')){
			return false;
		}
		makeLoadingIcon($(this).find('i'));
		$(this).addClass('loading');
		var that = this;
		twilio_interface.completeTransfer(function(response){
			if(isset(response.success) && response.success){
				$(that).closest('.transfer-modal-container').removeClass('open');
				setSubCallState($(that).closest('.contact-row'), '');
				$(that).closest('.contact-row').attr('data-call-sid', '');
			}
			else{
				var error = 'unable to complete transfer';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			restoreIcon($(that).find('i'));
			$(that).removeClass('loading');

		});
	});


	$('.transfer-modal-container').on('click', '.close-icon', function(){
		$(this).closest('.transfer-modal-container').removeClass('open');
	});

	$('.transfer-modal-container').on('click', '#confirm_transfer_button', function(e){
		
		e.stopPropagation();
		if($(this).hasClass('loading')){
			return false;
		}
		makeLoadingIcon($(this).find('i'));
		$(this).addClass('loading');
		var container = $(this).closest('.transfer-input-container');
		var that = this;
		var input = $(container).find('.transfer-to-input');
		var number = $(input).val();
		if(number.indexOf('0') == 0){
			number = number.replace('0', '44');	
		}
		twilio_interface.externalTransferRequest(number, function(response){
			if(isset(response.success) && response.success){
				if(isset(response.new_call_sid)){
					var external_calls = $(container).parent().find('.external-transfer-calls');
					var clonable = $(external_calls).children().first();
					var new_call = $(clonable).clone();
					external_calls.append(new_call);
					$(new_call).find('.external-number').text(number);
					$(new_call).show();
					$(new_call).css('transform', 'none');
					$(new_call).attr('data-call-sid', response.new_call_sid);
					$(input).val('');
					$(input).trigger('keyup');
				}
			}
			else{
				var error = 'unable to create call';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			restoreIcon($(that).find('i'));
			$(that).removeClass('loading');

		});
	});
	
	$('.transfer-modal-container').on('keyup', '.transfer-to-input', function(e){
		if (e.key == 'Enter') {
			e.preventDefault();
			var container = $(this).closest('.transfer-input-container');
			$(container).find('#confirm_transfer_button').trigger('click');
		}
	});
	
	$('.transfer-modal-container').on('click', '.external-transfer-call.connected .call-action.end-action', function(e){
		
		e.stopPropagation();
		if($(this).hasClass('loading')){
			return false;
		}
		makeLoadingIcon($(this).find('i'));
		$(this).addClass('loading');
		var that = this;
		var container = $(this).closest('.external-transfer-call');
		var call_sid = $(container).data('call-sid');
		twilio_interface.endSpecificConferenceLegByExternalId(call_sid, function(){
			restoreIcon($(that).find('i'));
			$(that).removeClass('loading');
		});
	});
	
	$('.transfer-modal-container').on('click', '.external-transfer-call.ringing .call-action.end-action', function(e){
		
		e.stopPropagation();
		if($(this).hasClass('loading')){
			return false;
		}
		makeLoadingIcon($(this).find('i'));
		$(this).addClass('loading');
		var that = this;
		var container = $(this).closest('.external-transfer-call');
		var call_sid = $(container).data('call-sid');
		twilio_interface.endTransferCallRequest(call_sid, function(){
			restoreIcon($(that).find('i'));
			$(that).removeClass('loading');
		});
	});
	
	$('.transfer-modal-container').on('click', '.external-transfer-call .complete-action', function(e){
		
		e.stopPropagation();
		if($(this).hasClass('loading')){
			return false;
		}
		makeLoadingIcon($(this).find('i'));
		$(this).addClass('loading');
		var that = this;
		twilio_interface.completeTransfer(function(response){
			if(isset(response.success) && response.success){
				$(that).closest('.transfer-modal-container').removeClass('open');
				setSubCallState($(that).closest('.external-transfer-call'), 'completed');
				$(that).closest('.external-transfer-call').attr('data-call-sid', '');
			}
			else{
				var error = 'unable to complete transfer';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			restoreIcon($(that).find('i'));
			$(that).removeClass('loading');

		});
	});
});
