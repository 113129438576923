$(document).ready(function() {
    $('div.script').on('click', '#insurance_welcome_step_next', function () {
		var that= this;
		var data = {};
		var quote_id = $(this).closest('div.script').data('quoteid');
		data.quote_id = quote_id;
		var unchecked_checkboxes = $(this).closest('div.script').find('.insurance-enforcer:not(:checked)');
		if (unchecked_checkboxes.length > 0) {
			error_message('please accept the privacy policy');
			return;
		}
		data.policy_id =  $(this).data('policy-id');
		data.policy_confirmed =  true;
		handleWelcomeStepAddress(quote_id, function(address_data){;
			makeRequest("/policies/accept-policy", data, function(response) {
				if(isset(response.success)) {
					if(response.success == true) {
						lead_script.handleStep($(that).data('success-step'));
						return;
					}
				}
				var error  = 'please refresh and try again';
				if(isset(response.error)) {
					error = response.error;
				}
				else {
					error_message(error);
				}
			});
		});
    });

	$('.script').on('change', '.has-insurance-input-section input', function () {

		if($(this).val() == 'Yes'){
			$('.insurance-has-cover-section').show();
		}
		else{
			$('.insurance-has-cover-section').hide();
		}

	});

	$('.script').on('click', '.submit-insurance-fact-find', function () {

		var serial_data = $('.insurance-fact-find-questions :input').serializeArray();

		if(!serial_data.length){
			error_message('Please fill in the required fields');
			return false;
		}

		var data = sanitiseSerialedArray(serial_data);

		var required = {
			'has-insurance' : {
				'error' : 'Does the customer have insurance?'
			}
		};
		
		if(data['has-insurance'] == 'Yes'){
			required['current-insurer'] = {
				'error' : 'Please confirm the current insurer'
			};
			required['insurance-renewal-date'] = {
				'error' : 'Please confirm the insurance renewal date'
			};
		}

		var bail = false;
		$.each(required, function(key, validation){
			if(!isset(data[key]) || !data[key].length){
				error_message(validation['error']);
				bail = true;
				return false;
			}
		});

		if(bail){
			return false;
		}

		showLoader();
		var script_element = $(this).closest('.script');
		var quote_id =  $(script_element).data('quoteid');
		var to_send = {
			'quote_id' : quote_id,
			'data' : data
		};

		var that = this;
		makeRequest('/insurance/fact-find', to_send, function(response){
			if(response.success){
				window[$(script_element).data('script-name') + '_script'].handleStep($(that).data('success-step'));
			}
			else{
				var error = 'unable to submit details';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});


	});
});