$(document).ready(function(){
	var api = new apiInterface();
	var quote_id = $('#lead_script').data("quoteid");
	var address_validator = new addressValidator();
	$('.script').on('change','#energy-address',function(){
		var address_id = $('#energy-address option:selected').val();
		var type = $('#energy-address option:selected').data('type');
		var text = $('#energy-address option:selected').text();
		var quote_to_send = quote_id;
		if($(this).data('quote-id')){
			quote_to_send = $(this).data('quote-id');
		}
		var data = {
			'address_id' : address_id,
			'type' : type,
			'address_text' : text
		};
		api.get_address_by_id(quote_to_send,data,function(response){
			if(response.success == true){
				//console.log(response);
				$('#energy-address-overview').html(response.html);
			}
			else{
				if(response.error){
					error_message(response.error);
				}
			}
			
		});
	});
	$('#lead_script').on('click','#unknown_meter_numbers',function(){
		$('#meter_numbers').hide();
		$('#hidden_meter_numbers').show();
	});
	$('#lead_script').on('click','#remembered_meter_numbers',function(){
		$('#meter_numbers').show();
		$('#hidden_meter_numbers').hide();
	});
	
	$('#lead_script').on('click','#manual_unknown_meter_numbers',function(){
		$('#manual_meter_numbers').hide();
		$('#manual_hidden_meter_numbers').show();
	});
	$('#lead_script').on('click','#manual_remembered_meter_numbers',function(){
		$('#manual_meter_numbers').show();
		$('#manual_hidden_meter_numbers').hide();
	});
	
	$('#lead_script').on('click','#confirm-address-continue',function(){
		var that = this;
		var data = [];

		var script = $(this).closest('.script');
		$('.error-message').html('');
		
		// bit of a hack to set boiler cover profile key hijacking this request
		if($(this).data('boiler-serviceable')){
			data.push({field : 'boiler_serviceable', value : $(this).data('boiler-serviceable')});
		}
		
		data.push({field : 'script_step', value: $('#active_step').data('script-ident')});
		if($('#homeowner-yes').length){
			if($('#homeownerOptions').find("input[type='radio']:checked").length == 0){
				$('#homeownerError').html('please select homeowner status first');
				setTimeout(function(){
					$('#homeownerError').html('');
				},2000);
				return;
			}
			var home_owner = $("[name='homeowner-type']:checked").val();
			data.push({field:'home_owner',value:home_owner});
		}
		if($('#has_insurance_question-yes').length){
			if (!document.getElementById('has_insurance_question-yes').checked && !document.getElementById('has_insurance_question-no').checked) {
				$('#has_insurance_questionError').html('please select has insurance question first');
				setTimeout(function(){
					$('#has_insurance_questionError').html('');
				},2000);
				return;
			} 
			var has_insurance = $("[name='has_insurance_question']:checked").val();
			data.push({field:'has_insurance',value:has_insurance});
		}
		if($('#has-boiler-cover-yes').length){
			if (!document.getElementById('has-boiler-cover-yes').checked && !document.getElementById('has-boiler-cover-no').checked) {
				$('#has-boiler-coverError').html('please select has boiler cover first');
				setTimeout(function(){
					$('#has-boiler-coverError').html('');
				},2000);
				return;
			} 
			var has_boiler_cover = $("[name='has-boiler-cover']:checked").val();
			data.push({field:'has_boiler_cover',value:has_boiler_cover});
		}
		data = getBoilerCareData(data, script);
		// check time at address 
		var time_at_address = $('#time_at_address');
		if(time_at_address.length){
			// we should be asking for Time at addres as the div has content
			var address_years = time_at_address.find('#address-years').val();
			var address_months = time_at_address.find('#address-months').val();
			if(isNaN(parseInt(address_years)) || isNaN(parseInt(address_months)) || (address_months < 0 || address_years < 0)){
				var message = new MessageBox('errorDialog', 'Please enter a time at address');
				message.setData({});
				message.run();
				return false;
			}
			else{
				data.push({field:'address_years',value:address_years});
				data.push({field:'address_months',value:address_months});
				
			}
		}
		
		if($('#energy-address-select').is(':visible')){
			
			if(!$('#selected-energy-address-id').val()){
				var message = new MessageBox('errorDialog', 'Please select an address');
				message.setData({});
				message.run();
				return false;
			}
			//var address_id = $('#selected-energy-address-id').val();
			data.push({field : 'address_id',value : $('#selected-energy-address-id').val()});
			data.push({field : 'address_type',value : $('#selected-energy-address-type').val()});
 
			if($('#meter_numbers').is(":visible")){
				// we have a need to capture meter numbers 
				if($('#meter_numbers #gas_meter').length ){
					data.push({field:'gas_meter',value:$('#meter_numbers #gas_meter').val()});
				}
				if($('#meter_numbers #electricity_meter').length){
					data.push({field:'electricity_meter',value:$('#meter_numbers #electricity_meter').val()});
				}
			}
			if($('#hidden_meter_numbers').is(":visible")){
				if($('#hidden_meter_numbers #gas_meter').length ){
					data.push({field:'gas_meter',value:$('#hidden_meter_numbers #gas_meter').val()});
				}
				if($('#hidden_meter_numbers #electricity_meter').length){
					data.push({field:'electricity_meter',value:$('#hidden_meter_numbers #electricity_meter').val()});
				}
			}
			api.confirm_address_selection(quote_id,data,function(response){
				if(response.success === true){
					if($(that).data('success-step')){
						
						window.lead_script.checkStep($('#active_step').data('script-ident'), function(response){
							if(response.step){
								window.lead_script.handleStep(response.step)
							}
							else{
								window.lead_script.handleStep($(that).data('success-step'))
							}
						});
						
					}
					else{
						window.lead_script.handleStep(window.lead_script.magicPlease('smart_meter'));
					}
				}
				else{
					if(response.errors.length){
						$.each(response.errors,function(index,error){
							if($('#'+error.target).length){
								$('#'+error.target).html(error.message);
							}
							else{
								$('#error-other').html(error.message.text);
							}
						});
					}
				}
			});
		}else if($('#energy-address-manual').is(':visible')){
			address_validator.validateManualAddress(function(errors,data){
				if(errors !== null){
					$.each(errors,function(index,error){
						if($('#'+error.target).length){
							$('#'+error.target).html(error.message);
						}
						else{
							$('#error-other').html(error.message.text);
						}
					});
				}
				else{
					// we're all good send the address to energy helpline
					if($(this).data('boiler-serviceable')){
						data.push({field : 'boiler_serviceable', value : $(this).data('boiler-serviceable')});
					}
					if($('#homeowner-yes').length){
						if (!$("[name='homeowner-type']:checked").length) {
							$('#homeownerError').html('please select homeowner status first');
							setTimeout(function(){
								$('#homeownerError').html('');
							},2000);
							return;
						} 
						var home_owner = $("[name='homeowner-type']:checked").val();
						data.push({field:'home_owner',value:home_owner});
					}
					data.push({field : 'script_step', value: $('#active_step').data('script-ident')});
					
					data = getBoilerCareData(data, script);

					api.confirm_manual_address(quote_id,data,function(response){
						//console.log(response);
						if(response.success === true){
							if($(that).data('success-step')){
								window.lead_script.checkStep($('#active_step').data('script-ident'), function(response){
									if(response.step){
										window.lead_script.handleStep(response.step)
									}
									else{
										window.lead_script.handleStep($(that).data('success-step'))
									}
								})
							}
							else{
								window.lead_script.handleStep(window.lead_script.magicPlease('smart_meter'));
							}
						}
						else{
							if(isset(response.errors) && response.errors.length){
								$.each(response.errors,function(index,error){
									if($('#'+error.target).length){
										$('#'+error.target).html(error.message);
									}
									else{
										$('#error-other').append(error.message.text+'<br/>');
									}
								});
							}
							if(isset(response.error)){
								$('#error-other').append(response.error+'<br/>');
								error_message(response.error);
							}
						}
					});
				}
			});
		}
	});
		
		
		
	$('.script').on('click','#address-not-found-button',function(){
		$('#address-not-found-button').hide();
		$('#show-address-list-button').show();
		$('#energy-address-manual').show();
		$('#energy-address-select').hide();
		$('.hide-on-manual').hide();
		$('.show-on-manual').show();
	});
	$('.script').on('click','#show-address-list-button',function(){
		$('#address-not-found-button').show();
		$('#show-address-list-button').hide();
		$('#energy-address-manual').hide();
		$('#energy-address-select').show();
		$('.hide-on-manual').show();
		$('.show-on-manual').hide();
	});
	
	$('#main_modal').on('click','.toggle-boilercare-follow-up-questions',function(){
		//console.log("Just changed the homeowner options");
        var selected_value = this.value;
        //console.log(selected_value);

		if(selected_value != 'No'){
			$('#main_modal .boiler-care-follow-up-questions').show();
			$('#main_modal .boiler-care-warning').hide();

		}
		else{
			$('#main_modal .boiler-care-follow-up-questions').hide();
			$('#main_modal .boiler-care-warning').show();
		}
		
	});
    // capture the change in home owner
	$('.script').on('click','.homeowner-type',function(){
		//console.log("Just changed the homeowner options");
        var selected_value = this.value;
        //console.log(selected_value);
                
		api.updateHomeowner(quote_id,selected_value,false,function(response) {
			//console.log("Should be updated");
		});

		
		
	});
	
	$('.script, #main_modal').on('click','.has-boiler-cover',function(){
		var container = $(this).closest('.follow-up-question-section');
		if(this.value != 'No'){
			$(container).find('.advice-text').show();
			$(container).find('.no-boiler-cover-text').hide();
			$('.boiler-care-home-insurance-question').hide();

		}
		else{
			$(container).find('.advice-text').hide();
			$(container).find('.no-boiler-cover-text').show();
			$('.boiler-care-home-insurance-question').show();
		}
	});

	$('.script, #main_modal').on('click','.has_insurance_question',function(){
		var container = $(this).closest('.follow-up-question-section');
		if(this.value != 'No'){
			$(container).find('.insurance-advice-text').show();
			$(container).find('.no-insurance-advice-text').hide();
		}
		else{
			$(container).find('.insurance-advice-text').hide();
			$(container).find('.no-insurance-advice-text').show();
		}
	});
	
	// var showAddressLoader = function(){
    //     $('#address_loader').show();
    // }

    // var hideAddressLoader = function(){
    //     $('#address_loader').hide();
    // }

    // var addressSearch = function(search_term,callback){
    //     var url = "https://api.addressy.com/Capture/Interactive/Find/v1.00/json3.ws?";
    //     //url = url + '&Text='+search_term;
    //     //var url = "/address/search";
    //     showAddressLoader();
    //     $.ajax({ 
    //         cache: false,
    //         url: url,
    //         dataType: 'json',
    //         type:"POST",
    //         data : {text:search_term,Countries:'GBR',Key:'ED93-YG38-ZH88-GJ99',Origin:'GBR'}, //,system:system},
    //         success: function (response) {
    //             hideAddressLoader();
    //             if(search_term == $('#address').val()){
    //                 callback(response);
    //             }
    //         },
    //         error: function (error) {
    //             //console.log(error);
    //             hideAddressLoader();
    //         }
    //     });
    // };

    // var containerSearch = function(search_term, id, callback =function(){}){
    //     var url = "https://api.addressy.com/Capture/Interactive/Find/v1.00/json3.ws?";
    //     showAddressLoader();
    //     $.ajax({ 
    //         cache: false,
    //         url: url,
    //         dataType: 'json',
    //         type:"POST",
    //         data : {text:search_term,Countries:'GBR',Key:'ED93-YG38-ZH88-GJ99',Origin:'GBR', Container: id }, //,system:system},
    //         success: function (response) {
    //             hideAddressLoader();
    //             if(search_term == $('#address').val()){
    //                 callback(response);
    //             }
    //         },
    //         error: function (error) {
    //             //console.log(error);
    //             hideAddressLoader();
    //         }
    //     });
    // }

    // $('.script').on('keyup', 'input#address', function(){
    //     addressSearch($(this).val(), function(response){

    //         if(isset(response.Items)){
    //             $('div.address-results-container ul.address-results li').each(function(){
    //                 $(this).remove();
    //             });
    //             $.each(response.Items, function(index, item){                
    //                 var element = document.createElement('li');
    //                 $(element).addClass('address-result-item');
    //                 $('div.address-results-container ul.address-results').append(element)
    //                 $(element).data('id', item.Id);
    //                 $(element).attr('data-id', item.Id);
    //                 $(element).data('type', item.Type);
    //                 $(element).attr('data-type', item.Type);
    //                 $(element).data('text', item.Text);
    //                 $(element).attr('data-text', item.Text);
    //                 $(element).data('description', item.Description);
    //                 $(element).attr('data-description', item.Description);
    //                 var postcode = item.Description.split(', ').pop();
    //                 $(element).data('postcode', postcode);
    //                 $(element).attr('data-postcode', postcode);
    //                 $(element).text(item.Text + ', ' + item.Description);
                    
    //             })
    //         }
    //     })
    // });

    // $('.script').on('click', 'div.address-results-container ul.address-results li', function(){

    //     $('div.address-results-container ul.address-results li.active').removeClass('active');
    //     var that = this;
    //     if($(this).data('type') != 'Address'){
    //         $('div.address-results-container ul.address-results li.sub-item').remove();
    //         var id = $(this).data('id');
    //         var search_term = $('#address').val();
    //         containerSearch(search_term, id, function(response){
    //             if(isset(response.Items)){
    //                 $.each(response.Items, function(index, item){                
    //                     var element = document.createElement('li');
    //                     $(element).addClass('address-result-item');
    //                     $(element).addClass('sub-item');
    //                     $(that).after(element)
    //                     $(element).data('id', item.Id);
    //                     $(element).data('type', item.Type);
    //                     $(element).data('text', item.Text);
    //                     $(element).data('description', item.Description);
    //                     var postcode = item.Description.split(', ').pop();
    //                     $(element).data('postcode', postcode);
    //                     $(element).attr('data-postcode', postcode);
    //                     $(element).text(item.Text + ', ' + item.Description);
                        
    //                 })
    //             }
    //         });
    //         return;

    //     }
    //     $(this).addClass('active');

    // })


        
});
