//this runs the drag and drop from unallocated users into the teams block
var user_sortable = function() {
	$( "#usersort, #usersort2" ).sortable({
		connectWith: ".userSortable",
		receive: function () {
			var users_in_team = [];
			$('#usersort2').find('li').each(function(){
				var user_id = $(this).data('user-id');
				users_in_team.push(user_id);
			});
			var team_id = $('#edit-team').find('#team-edit-container').data('main_team_id');
			update_team(team_id,users_in_team);
		}
	}).disableSelection();
};

var update_team = function(team_id,users){
	$.ajax({
		type: "POST",
		dataType: 'json',
		data: {
			users_in_team : users,
			team_id : team_id
		},
		url: '/admin/teams/update-users/'+team_id,
		success: function(response) {
			$('.update-error').hide(); //this is here just incase the errors are actually visible
			$('.update-success').fadeIn().text('Updated');
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

//this runs the drag and drop from unallocated teams into the units block
var unit_sortable = function() {
	$( "ul[id^=teamsort_]" ).sortable({
		connectWith: ".teamSortable",
//		appendTo: "body",
//		helper: "clone",
//		containment: "window",
		receive: function () {
			$('.unit-wrapper').each(function(){
				var teams_in_unit = [];
				var unit_id = $(this).parent().parent().data('unit-id');
				$('#teamsort_'+unit_id).find('li').each(function(){
	//				console.log(unit_id);
					var team_id = $(this).data('team');
					teams_in_unit.push(team_id);
				});
	//			var team_id = $('#saved-units').find('#teamsort_'+unit_id).data('team');
				update_unit(unit_id,teams_in_unit);
			});
			
		}
	}).disableSelection();
};

var create_user = function(data){
	makeRequest('/user/create',data,function(response){
		if(isset(response.errors)){
			$('.modal-body').find('.error-bag').html('<ul></ul>');
			$.each(response.errors,function(key,error){
				$('.modal-body').find('.error-bag ul').append('<li>'+error+'</li>');
			});
			$('.modal-body').find('.error-bag').show();
		}
		else if(response.success){
			if(response.redirect && response.redirect.length){
				window.location.href = response.redirect;
			}
		}
	});
};

var update_unit = function(unit_id,teams_in_unit){
	$.ajax({
		type: "POST",
		dataType: 'json',
		data: {
			unit_id : unit_id,
			teams_in_unit : teams_in_unit
		},
		url: '/admin/teams/update-unit/'+unit_id,
		success: function(response) {
			$('.update-error').hide(); //this is here just incase the errors are actually visible
			$('.update-success').fadeIn().text('Updated');
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var updateUserAttributes = function(data,callback){
	makeRequest('/user/updateattributes', data, function(response){
		success_message('User Updated');
	});
}

$( document ).ready(function() {
	
	user_sortable();
	unit_sortable();
	
	
//	var count_users = $("#usersort2").children('li').length;
//	$('#users-in-team').appendTo(count_users);
	
//	$('#teams').on('change', '#usersort2', function() {
//		var team_id = $('#edit-team').find('#team-edit-container').data('main_team_id');
//		var user_id = $('#edit-team').find('#usersort2').children().data('user_id');
//		console.log(team_id);
//		console.log(user_id);
//		user_sortable();
		
//	});

	$('#users, #user-incentives').on('change','#agent_next_prize',function(){
		// post to server and save
		// show success message for feedback
		var data = {attributes:{}};
		
		data.attributes[$(this).attr('name')] = $(this).val();
		
		//var user_stretch_target = $(this).parent().parent().find("input[name='stretch_target']").val();
		data.user_id = $('#user-incentives').data('user_id');
		updateUserAttributes(data);
	});
	
	$('#users,#user-incentives').on('change','#is_barred',function(){
		// post to server and save
		var data = {attributes:{}};
		
		data.attributes[$(this).attr('name')] = $(this).is(':checked')  ? 1 : 0;
		
		//var user_stretch_target = $(this).parent().parent().find("input[name='stretch_target']").val();
		data.user_id = $('#user-incentives').data('user_id');
		updateUserAttributes(data);
	});
	
	$('#users, #user-incentives').on('change','#incentive_opt_out',function(){
		// post to server and save
		var data = {attributes:{}};
		
		data.attributes[$(this).attr('name')] = $(this).is(':checked') ? 1 : 0;
		
		//var user_stretch_target = $(this).parent().parent().find("input[name='stretch_target']").val();
		data.user_id = $('#user-incentives').data('user_id');
		updateUserAttributes(data);
	});
	
	$('#users,#user-incentives').on('click','.remove-token',function(){
		// post to server and save
		var data = {};
		data.user_id = $('#user-incentives').data('user_id');
		var that = this;
		showLoader();
		$.ajax({
			data: data,
			type: 'POST',
			url: '/incentives/token/remove',
			dataType: 'json',
			success: function(response) {
				hideLoader();
				if(response.success && response.tokens){
					$(that).parent().find('input').val(response.tokens);
					error_message('token removed');
				}
				else{
					error_message('something went wrong');
				}
			},
			error: function (error) {
				hideLoader();
				error_message('speak to Jay');
				//console.log(error);
			}
		});	
	});
	$('#users, #user-incentives ').on('click','.add-token',function(){
		// post to server and save
		var data = {};
		var that = this;
		showLoader();
		data.user_id = $('#user-incentives').data('user_id');
		$.ajax({
			data: data,
			type: 'POST',
			url: '/incentives/token/add',
			dataType: 'json',
			success: function(response) {
				hideLoader();
				if(response.success && response.tokens){
					$(that).parent().find('input').val(response.tokens);
					success_message('token added');
				}
				else{
					error_message('something went wrong');
				}
			},
			error: function (error) {
				//console.log(error);
				hideLoader();
				error_message('speak to Jay');
			}
		});
	});

	//edit the team which are within a unit
	$('#units-active, #unallocated-teams').on('click', '.edit-trigger', function() {
		var team_id = $(this).data('team-id');
		$.ajax({
			url: '/admin/teams/edit/'+team_id,
				success: function(response) {
					if(response.success){
						$('#edit-team').show().html(response.html);
						unit_sortable();
						user_sortable();
						$('.select2-multi').select2({'width' : '100%'});
					}
			},
			error: function (error) {
			}
		});	
	});
	
	$('#users').on('click', '#create-user', function() {
		$.ajax({
			url: '/admin/teams/add-user',
				success: function(response) {
					if(response.success){
						$('.modal-body').html(response.html);
						user_sortable();
						$('#new-user-create').on('submit',function(e){
							e.preventDefault();
							var data = $(this).serialize();
							create_user(data);
						});
				}
			},
			error: function (error) {
				error_message(error);
			}
		});	
	});
	
	$('#unallocated-teams').on('click', '#add-team', function() {
		var team_name = $(this).parent().parent().find("input[name='new_team_name']").val();
		var product_type_id = $(this).parent().find("select[name='product_type']").val();
		var script_id = $(this).parent().find("select[name='script_type']").val();;
		$.ajax({
			data: { 
				new_team_name : team_name,
				product_type : product_type_id,
				script_type : script_id
			},
			type: 'POST',
			url: '/admin/teams/create',
			dataType: 'json',
			success: function(response) {
				$('#teamsort_').append(response.html);
				$('.update-error').hide(); //this is here just incase the errors are actually visible
				$('.update-success').fadeIn().text('Created - '+team_name);
				setTimeout(function(){
					$('.update-success').fadeOut();
				},5000);
			},
			error: function (error) {
				$('.update-error').fadeIn().text('Team already exists');
				$('.update-success').hide();
				setTimeout(function(){
					$('.update-error').fadeOut();
				},5000);
			}
		});
	});
	
	$( 'ul[id^=teamsort_]' ).sortable({
		connectWith: ".teamSortable"
	}).disableSelection();

	$('#teams').on('click', '#update-team-name', function() {
		var team_name = $(this).parent().parent().parent().find("input[name='team-name']").val();
		var product_type = $(this).parent().parent().parent().find("select[name='product_type']").val();
		var script = $(this).parent().parent().find("select[name='script_type']").val();
		var morning_target = $(this).parent().parent().find("input[name='morning_target']").val();
		var afternoon_target = $(this).parent().parent().find("input[name='afternoon_target']").val();
		var team_id = $(this).data('team_id');
		var commision_values = $('.team-commission-attributes').find(':input').serializeArray();
		var product_type_links = $(this).closest('#edit-team').find('.product_type_links-input').val();
		var data = { 
			'team_name' : team_name,
			'product_type' : product_type,
			'script' : script,
			'morning_target' : morning_target,
			'afternoon_target' : afternoon_target,
			'product_type_links': product_type_links,
			'commission_data' : {}
		}
		$.each(commision_values, function(i, serialized){
			data.commission_data[serialized.name] = serialized.value;
		});

		makeRequest('/admin/teams/update/'+team_id, data, function(response){
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Updated - '+team_name);
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		});
	});

	$('#users').on('click', '#user-attributes-save', function(){
		
		// there may be any number of attributes so deal with it
		
		var data = {attributes:{}};
		
		$('#user-attributes input').each(function(){
			data.attributes[$(this).attr('name')] = $(this).val();
		});
		
		//var user_stretch_target = $(this).parent().parent().find("input[name='stretch_target']").val();
		data.user_id = $(this).data('user-id');
		updateUserAttributes(data);
	});

	$('#users').on('keyup', '.search-users', function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#user-container ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});
	
	$('#users, #edit-team').on('click', '.edit-trigger', function() {
		var user_id = $(this).parent().data('user-id');
		$.ajax({
			url: '/admin/users/edit/'+user_id,
			success: function(response) {
				$('#edit-user-main').html(response.html);
				$('#edit-user-main').show();
				buildAbsenceCalendar();
				$('#edit-user-main').find('.select2').select2({'width' : '100%'});

				$('.select2-multi').select2({'width' : '100%'});
//				$('.update-error').hide(); //this is here just incase the errors are actually visible
//				$('.update-success').fadeIn().text('Updated');
//				setTimeout(function(){
//					$('.update-success').fadeOut();
//				},5000);
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});
	
	$('#edit-user-main').on('submit', 'form', function(e) {
		e.preventDefault();
		var form = $(this);
		var url = $(this).attr('action');
		$.ajax({
			url: url,
			type: 'POST',
			dataType: 'json',
			data: form.serialize(),
			success: function(response) {
				$('#edit-user-main').html('');
				$('#edit-user-main').hide();
				$('.update-error').hide();
				$('.update-success').fadeIn().text('User updated');
				setTimeout(function(){
					$('.update-success').fadeOut();
				},5000);
			},
			error: function (error) {
				var error_message = '<ul>';
				$.each(error.responseJSON,function(field,error_text){
					error_message = error_message + '<li> '+error_text +'</li>';
				});
				error_message = error_message + '</ul>';
					$('.update-success').hide(); //this is here just incase the errors are actually visible
					$('.update-error').fadeIn().html(error_message);
					$('.update-error').css('height:');
					setTimeout(function(){
						$('.update-error').fadeOut();
					},5000);
				// look for responseJSON
				// loop over each item
				// find the form input with the same name 
				// display errors 
				
//				console.log(error);
			}
		});
	});
	
	$('#users').on('click', '#cancel-edit', function() {
		$('#edit-user-main').html('');
		$('#edit-user-main').hide();
		$("html, body").animate({ scrollTop: 0 }, "fast");
	});
	
	$('#units-active').on('click', '#add-unit', function() {
		var unit_name = $(this).parent().parent().find("input[name='unit_name']").val();
		var product_type_id = $(this).parent().find("select[name='product_type']").val();
		$.ajax({
			data: { 
				unit_name : unit_name,
				product_type_id : product_type_id
			},
			type: 'POST',
			url: '/admin/teams/add-unit',
			dataType: 'json',
			success: function(response) {
				$('#saved-units').append(response.html);
				unit_sortable();
				$('.update-error').hide(); //this is here just incase the errors are actually visible
				$('.update-success').fadeIn().text('Created - '+unit_name);
				setTimeout(function(){
					$('.update-success').fadeOut();
				},5000);
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});
	
	$('#teams').on('click', '.cancel-button', function() {
		$('#edit-team').hide();
	});

	$('#teams').on('click', '#unallocate-user', function() {
		var user_id = $(this).data('team_user_id');
		var team_id = $('#team-edit-container').data('main_team_id');
		var username = $(this).parent().find('.team').text();
		$.ajax({
			data: { 
				user_id : user_id,
				team_id : team_id
			},
			type: 'POST',
			url: '/admin/teams/unallocate-user',
			dataType: 'json',
			success: function(response) {
				$('#edit-team').html(response.html);
				$('#users').html(response.html2);
				$('.update-error').hide(); //this is here just incase the errors are actually visible
				$('.update-success').fadeIn().text('Removed '+username);
				user_sortable();
				setTimeout(function(){
					$('.update-success').fadeOut();
				},5000);
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});
	
	$('#units-active').on('keyup','.search-units',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-units ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});
});