class emailSender  {
	
	constructor(){
		this.tos = [];
		this.bccs = [];
		this.ccs = [];
		
		this.sent_log = [];
		
		this.render_mode = 'rendered';
		
		this.flow = [];
		this.temp_body = '';
		
		this.attachments = [];

		this.use_signature = false;
		
		return this.emailSender;
	}

	//setEntityId takes a int data type 
	setEntityId(id){
		this.entity_id = id;
	}

	//setType takes a string data type (i.e 'contact' or 'ticket')
	setType(type){
		this.type = type;
	}
	
	getType(){
		return this.type;
	}
	
	//setFrom takes a string data type
	setFrom(from){
		this.from = from;
	}
	
	getFrom(){
		return this.from;
	}
	
	//setTos takes an array of emails
	setTos(tos){
		this.tos = tos;
	}
	
	//pushes an array of strings onto current tos
	addTo(to){
		this.tos.push(to);
	}
	
	removeTo(index){
		delete this.tos[index];
		this.tos = this.tos.filter(function(){ return true; });		
		return this.tos;
	}
	
	getTos(){
		return this.tos;
	}
	
	//setBccs takes an array of strings
	setBccs(bccs){
		this.bccs = bccs;
	}
	
	//pushes an array of strings onto current bccs
	addBcc(bcc){
		this.bccs.push(bcc);
	}
	
	getBccs(){
		var output = [];
		$.each(this.bccs, function(i, bcc){
			output.push([bcc.email, '']);
		});
		return output;
	}
	
	//setCcs takes an array of strings
	setCcs(ccs){
		this.ccs = ccs;
	}
	
	//pushes an array of strings onto current ccs
	addCc(cc){
		this.ccs.push(cc);
	}
	
	getCcs(){
		var output = [];
		$.each(this.ccs, function(i, cc){
			output.push([cc.email, '']);
		});
		return output;
	}
		
	//setTemplate takes an int value
	setTemplate(template) {
		this.template = template;
	}
	
	getTemplate() {
		return this.template;
	}
	
	//setSubject takes a string data type
	setSubject(subject) {
		this.subject = subject;
	}
	
	getSubject() {
		return this.subject;
	}
	
	//setSubject takes a string data type
	setBody(body){
		this.body = body;
	}
	
	getBody(){
		return this.body;
	}
	
	setAttachments(attachments, callback = function(){}){
		this.attachments = attachments;
		callback();
	}
	
	getAttachments() {
		return this.attachments;
	}
	
	setRenderMode(mode){
		this.render_mode = mode;
	}
	
	getRenderMode(){
		return this.render_mode;
	}
	
	
	ajaxWrapper(url, data, callback, email_attachment_flag = true){
		var that = this;
		$.ajax({
			url : url,
			method : 'POST',
			dataType : 'json',
			cache: (email_attachment_flag) ? true : false,
			contentType: (email_attachment_flag) ? 'application/x-www-form-urlencoded; charset=UTF-8' : false, // false as jQuery will tell the server its a query string request
			processData : (email_attachment_flag) ? true : false, // false doesn't process the files
			data : data,
			success : function(response){
				if(checkErrors(response)){
					if(response.target && response.target != 'create_email_preview_modal'){
						that.flow.push(response.target);
					}
					callback(response);
				}
				else{
					hideLoader();
					//do error stuff
				}
			}
		});
	}
	
	
	loadTemplate(template_id, callback){
		this.template = template_id;
		
		var contact_id = null;
		var to = this.getNextTo();
		if(to !== false){
			contact_id = to.contact_id;
		}
		
		var ticket_id = '';
		if(this.type == 'ticket') {
			ticket_id = this.entity_id;
		}
		
		var render_mode = this.render_mode;
		
		var data = {
					'render_mode' : render_mode,
					'template_id' : template_id,
					'contact_id' : contact_id,
					'ticket_id' : ticket_id,
				   };		
		
		var url = '/mail/get-contact-email-template';
		//ajax pass to server
		this.ajaxWrapper(url, data, callback);		
	}
	
	renderNextEmail(){
		var that = this;
		if(this.tos.length > 0){
			var next_contact = this.tos[0];
			//this.getRender(next_contact['contact_id']);
			this.renderPreview(function(response){
				createModal(response.target,response.html);
				if(response.body){
					that.temp_body = response.body;
				}
			}, next_contact['email']);
		}
		else{
			var text = '';
			$.each(this.sent_log, function(i, v){
				
				text += v.email + ', ';
			});
			success_message('Sent email(s) to ' + text);
			//we are finished
		}
		
		
	}
	
	addLog(contact){
		this.sent_log.push(contact);
		
	}
	
	//getrender makes modal, onclick of that will send email, but needs to know to remove email from tos and then go back to nextemail
	
	getRender(contact){
		var template_id = this.template;
		var data = {
					'template_id' : template_id,
					'contact_id' : contact
				   };		
		var url = '/email/get-render';
		//ajax pass to server
		this.ajaxWrapper(url, data, function(response){
			createModal(response.target,response.html);
		});	
		
	}
	
	uploadFiles(callback){
		
		var url = '/email/upload-temporary-files';
		var attachments_data = new FormData($('#email_attachments_form')[0]);
		attachments_data.append('entity_type', this.type);
		attachments_data.append('entity_id', this.entity_id);
		var email_attachment_flag = false;
		
		/* check whats in the formdata (console log for it, as you can't console.log formdata)
		   doesn't work with gulp because of some magicString error, but used in google inspector
				for(var value of attachments_data.values()){
					console.log(value);
				}		
		*/
	    this.ajaxWrapper(url, attachments_data, callback, email_attachment_flag);
	}
	
	renderPreview(callback){	
		var template_id = this.template;
		var subject = this.subject;
		var body =  this.body;
		
		var next = this.getNextTo();
		var contact = next.email;
		var contact_id = next.id;

		var entity_type = this.type; // string
		var entity_id = this.entity_id; // int

		var data = {
					'template_id' : template_id,
					'subject' : subject,
					'body' : body,
					'contact' : contact,
					'contact_id' : contact_id,
					'entity_name' : entity_type, 
					'link_id' : entity_id,
					'use_signature' : this.use_signature,
				   };		

		var url = '/email/get-contact-email-preview';
		//ajax pass to server
		
		this.ajaxWrapper(url, data, callback);		
	}
	
	sendEmail(to, callback){
		var entity_type = this.type; // string
		var entity_id = this.entity_id; // int
		var subject = this.subject; // string
		var body = this.body; // string
		if(this.temp_body.length > 2){
			body = this.temp_body;
		}
		var template_id = this.template; // int

		if(!isset(subject) || subject.length === 0) {
			alert('no subject');
			error_message('Please add a subject');
			hideLoader();
			return;
		}

		var ccs = this.getCcs();
		var bccs = this.getBccs();
		
		var attachments = this.getAttachments();

		var data = {
			'entity_name' : entity_type, 
			'template_id' : template_id,
			'link_id' : entity_id,
			'to' : to,
			'subject' : subject,
			'body' : body,
			'ccs' : ccs,
			'bccs' : bccs,
			'attachments' : attachments,
		};
		
		var url = '/mail/send-template-email';
		//ajax pass to server
		
		this.removeNextTo();
				
		this.ajaxWrapper(url, data, callback);
		
	}
	
	sendNextEmail(callback){
		var that = this;
		var to = this.getNextTo();
		this.addLog(to);
		this.sendEmail(to.email, function(response){callback(response);  that.renderNextEmail()});
	}
	
	getContactSelector(callback = function(response){}){
		var entity_id = this.entity_id;
		
		var data = {
			'entity_id' : entity_id,
			'template_id' : this.template
		};
		
		var url = '/' + this.type + '/email-contacts';
		
		
		var that = this;
		var callback_callback = function(response){
			callback(response);
			//now lets make our magic email select 2s
			that.createEmailRecipientSelects();
		}

		//ajax pass to server
		this.ajaxWrapper(url, data, callback_callback);	
		
	}


	formatEmailRecipient(recipient){
		if (!recipient.id) {
			return recipient.text;
		}
	
		console.log(recipient);
		var html = $(`<span class='magic-recipient-container'>
			<span class='recipient-display-section'>
				<span class="email-recipient-text">` + recipient.text + `</span>
			</span>
		</span>`);

	
		return html;
	}

	createEmailRecipientSelects(){

		$('.email-selection-select2').select2({
			'width': '100%',
			'tags' : true,
			'templateSelection': this.formatEmailRecipient
		})

	}
	
	addItemToSelector(id, email, type){
		
		var html = '<div id="email-recipient-container" class="email-contact-container">';
		
		var checkbox = '<input type="checkbox" id="select-recipient" class="select-recipient" data-contact-id="'+ id +'">';
		var email_section = '<span id="recipient-email" class="contact-email" data-recipient-email="' + email + '"> ' + email + ' </span>';
		var select = '<select name="recipient-field-types" class="contact-send-type form-control field-type">';
		var options = '<option value="To">TO</option><option value="Bcc">BCC</option><option value="Cc">CC</option>'
		
		html += select;
		html += options;
		html += '</select><label>';
		
		html += email_section;
		html += checkbox;
		
		html += '</label></div>';
		
		
		$('#add_contacts_modal #all-recipients').append(html);
		
		$('#add_contacts_modal #all-recipients select:last').val(type);
		
		
	}
	
	removeNextTo(){
		this.tos.shift();
	}
	
	getNextTo(){
		var tos = this.getTos();
		if(tos.length){
			return tos[0];
		}
		return false;
	}
	
	startFlow(){
		showModal('email_template_picker');
		this.flow.push('email_template_picker');
	}
	
	clear(){
		this.tos = [];
		this.bccs = [];
		this.ccs = [];
		
		this.sent_log = [];
		this.flow = [];
		
		this.temp_body = '';
		this.attachments = [];
		
		this.render_mode = 'rendered';
	}
	
	back(){
		this.flow.pop();
		showModal(this.flow[this.flow.length -1]);
	}
	
}

$(document).ready(function() {
	
	window.success_message = function(text){
		if(!text){
			text = 'Updated';
		}
		$('.update-success').fadeIn().text(text);
		setTimeout(function(){
			$('.update-success').fadeOut();
		},5000);
	};
	
	window.error_message = function(text){
		if(!text){
			text = 'Error';
		}
		$('.update-error').fadeIn().text(text);
		setTimeout(function(){
			$('.update-error').fadeOut();
		},5000);
	};
	
	window.checkErrors = function(response){
		if(response.success != true){
			if(response.error){
				if(response.error.length > 1){
					error_message(response.error);
					hideLoader();

					if(isset(response.fix_url) && isset(response.fix_text)){
						showNotification(response.fix_text, 'click me', function(){
							window.open(response.fix_url, '_blank');
						}, 30);
					}

					return false;
				}
			}
			error_message('an unknown error occurred');
			hideLoader();
			return false;
		}
		return true;
		
	}
	
	
	//event listeners for the email send flow
	$('#modal_container').on('click', '#email_template_picker #next', function(e) {
		var template_id = $('#email_template_picker #template-id').val();
		email_obj.loadTemplate(template_id, function(response){
			if (response.success){
				if (response.html){
					email_obj.body = response.body;
					createModal(response.target, response.html);
					$('#wysiwyg-email-template-text').summernote({
						maxHeight: 500,
					});
					email_obj.createEmailRecipientSelects();
				}
			}
		});
	});
	
	$("#modal_container").on('click',"#create_email_preview_modal #send_email_btn", function(e){	
		//console.log('here');
		showLoader(['Sending Email..']);
		var email_subject = email_obj.getSubject();
		if(!isset(email_subject)){
			email_obj.setSubject($('#create_email_preview_modal_subject').html());
		}
		email_obj.sendNextEmail(function(response){
			$('#create_email_preview_modal').modal('hide');				
			success_message('Email sent');
			hideLoader();
		});
		
	});
	
	$('#modal_container').on('click', '#create_email_wysiwyg_modal #next', function(){
		
		if($('#create_email_wysiwyg_modal #email-template-subject').length > 0){
			var new_subject_text = $('#create_email_wysiwyg_modal #email-template-subject').val();
			email_obj.setSubject(new_subject_text);
		}
		if($('#create_email_wysiwyg_modal .wysiwyg-email-template-text').length > 0){
			var new_email_text = $('#create_email_wysiwyg_modal .wysiwyg-email-template-text').val();
			email_obj.setBody(new_email_text);
		}

		var email_attachments = $('#email-attachments').prop('files');
		if(email_attachments.length > 0 ){
			email_obj.setAttachments(email_attachments,function(){
				email_obj.uploadFiles(function(response){
					if(response.success){
						success_message(response.implode_files + " uploaded");
						email_obj.setAttachments(response.file_paths);
					}
				});
			});
		}
		
		email_obj.clear();
		// var tos = email_obj.getTos();
		// if(tos.length == 0){
			email_obj.getContactSelector(function(response){
				if(response.html){
					createModal(response.target,response.html);
				}
			});
		// }
		// else{
		// 	email_obj.renderPreview(function(response){
		// 		if(response.success){
		// 			createModal(response.target,response.html);
		// 			if(response.body){
		// 				email_obj.temp_body = response.body;
		// 			}
		// 		}
		// 	} );
		// }
		
	});
	
	
	$('#modal_container').on('click', '#add_contacts_modal #add_contacts_preview_button', function(){
		$.each($('.email-selection-select2'), function(i, select){
			var type = $(select).data('type');
			var selected_to_options = $(select).find('option:selected');
		
			$.each(selected_to_options, function(i, option){
				var contact_id = $(option).data('id');
				var contact_email = $(option).val();
				var item = { id:contact_id, email: contact_email};
				email_obj['add' + type](item);
			});
		})
		
		
		if(email_obj.getTos().length == 0){
			error_message('please select someone to send the email to');
			return;
		}
		
		var use_signature = $('#add_contacts_modal #use_signature').is(':checked');
		email_obj.use_signature = use_signature;

		email_obj.renderPreview(function(response){
			if(response.success){
				createModal(response.target,response.html);
				if(response.body){
					email_obj.temp_body = response.body;
				}
			}
		} );
		
	});
	

	var chill = false;
	$('#modal_container').on('click', '#add_contacts_modal .ccs-section-toggle', function(){
		if(chill){
			return false;
		}
		var expanded = $(this).attr('aria-expanded') == 'true';
		if(expanded){
			$(this).attr('aria-expanded', 'false')
			$(this).text('Show CCs');
		}
		else{
			$(this).attr('aria-expanded', 'true')
			$(this).text('Hide CCs');
		}
		chill = true;
		setTimeout(function(){chill = false}, 500);
	});

	$('#modal_container').on('click','#add-recipient',function(e){
		e.preventDefault();
		var field_type = $(this).parent().find('#field-type').val();
		var recipient_email = $(this).parent().find('input[name=recipient-email]').val();
		
		//use below on save - this add is just for adding to the list
//		email_obj['add' + field_type]({id : null, email : recipient_email});
		
		email_obj.addItemToSelector(null, recipient_email, field_type);
				
	});
	
	$('#modal_container').on('change','#select-all-recipients',function(e){
		e.preventDefault();
		if($('#select-all-recipients:checked').length > 0) {
			$(this).parent().parent().parent().find('.select-recipient').prop("checked", "checked");
		}
		else {
			$(this).parent().parent().parent().find('.select-recipient').prop("checked", "");
		}		
	});
	
	$('#modal_container').on('click', '#email_modals_back', function(){
		email_obj.back()
	});
	
	
});


window.emailSender = emailSender;

window.email_obj = new emailSender();
