$(document).ready(function(){
	var api = new apiInterface();
	var quote_id = $('#lead_script').data("quoteid");
	var view = new displayHelper();
	var customer_validator = new customerValidator();

	$('#lead_script').on('click','.go-to-current-usage-step',function(){
		var target_step = lead_script.step.split('.').slice(0, 1).join('') + '.current_usage';
		window.lead_script.handleStep(target_step);
	});

	$('#lead_script').on('click','#customer-details-continue',function(){
		var that = $(this);
		var update = false;
		//if we are in a boiler cover quote we need to set this for the energy quote 
		if($('#energy_quote').val()){
			quote_id = $('#energy_quote').val();
			update = true;
		}
		
		var script_ident = $('#active_step').data('script-ident');
		
		var validation = [
			function(next) { customer_validator.validateTitle($('#lead_script #customer-details-title').val(),next); },	
			function(next) { customer_validator.validateFirstName($('#lead_script #customer-details-firstname').val(),next); },
			function(next) { customer_validator.validateLastName($('#lead_script #customer-details-lastname').val(),next); },
			function(next) { customer_validator.validatePhone($('#lead_script #customer-details-phone').val(),next); },
			function(next) { customer_validator.validateEmail($('#lead_script #customer-details-email').val(),next); },
		];

		if($('#dob_container').length){
			if($('#dob_container').data('required') || 
			$('#lead_script #customer-details-dob-day').val().toLowerCase() !== 'day' ||
			$('#lead_script #customer-details-dob-month').val().toLowerCase() !== 'month' ||
			$('#lead_script #customer-details-dob-year').val().toLowerCase() !== 'year' 
			){
				validation.push(function(next) { customer_validator.validateDOB(
					$('#lead_script #customer-details-dob-day').val(),
					$('#lead_script #customer-details-dob-month').val(),
					$('#lead_script #customer-details-dob-year').val()
					,next); });
			}
		} 

		var script_element = $(this).closest('.script');
		var script = window[$(script_element).data('script-name') + '_script'];

		customer_validator.validateAll(validation,function(errors,data){
			if(errors !== null){
				$.each(errors,function(index,error){
					$('#lead_script #'+error.target).html(error.message);
					//console.log(error.message);
				});
//				$('#lead_script span.error-message').show();
//				setTimeout(function(){ 
//					$('#lead_script span.error-message').hide();
//				}, 1000);
			}
			else{
				data.push({'field' : 'update', 'value' : update});
				data.push({'field' : 'script_step', 'value' : script_ident});
				if($('#paper_billing').length){
					data.push({'field' : 'paper_billing', 'value' : $('#paper_billing').is(':checked')});
				}
				if($('#contract_start_date:visible').length){
					data.push({'field' : 'contract_start_date', 'value' : $('#contract_start_date').val()});
				}
				api.set_customer_details(quote_id,data,function(response){
					if(response.renewal){
						script.handleStep('energy.terms_cancelations');
						return;
					}
					if($(that).data('success-step')){
						script.handleStep($(that).data('success-step'));
					}
					else{
						script.handleStep(script.magicPlease('address_confirmation'));
					}
					
					//refresh the contact info on the left hand side
					refeshContactDetails();
					
				});
				
			}
		});
	});

	$('#lead_script').on('click','#inline_customer_details_continue',function(){
		var that = $(this);
		var update = false;
		//if we are in a boiler cover quote we need to set this for the energy quote 
		if($('#energy_quote').val()){
			quote_id = $('#energy_quote').val();
			update = true;
		}
		
		var script_ident = $('#active_step').data('script-ident');
		
		var validation = [
			function(next) { customer_validator.validateTitle($('#lead_script #customer-details-title').val(),next); },	
			function(next) { customer_validator.validateFirstName($('#lead_script #customer-details-firstname').val(),next); },
			function(next) { customer_validator.validateLastName($('#lead_script #customer-details-lastname').val(),next); },
			function(next) { customer_validator.validatePhone($('#lead_script #customer-details-phone').val(),next); },
			function(next) { customer_validator.validateEmail($('#lead_script #customer-details-email').val(),next); },
		];

		if($('#dob_container').length){
			if($('#dob_container').data('required') || 
			$('#lead_script #customer-details-dob-day').val().toLowerCase() !== 'day' ||
			$('#lead_script #customer-details-dob-month').val().toLowerCase() !== 'month' ||
			$('#lead_script #customer-details-dob-year').val().toLowerCase() !== 'year' 
			){
				validation.push(function(next) { customer_validator.validateDOB(
					$('#lead_script #customer-details-dob-day').val(),
					$('#lead_script #customer-details-dob-month').val(),
					$('#lead_script #customer-details-dob-year').val()
					,next); });
			}
		} 

		var script_element = $(this).closest('.script');
		var script = window[$(script_element).data('script-name') + '_script'];

		customer_validator.validateAll(validation,function(errors,data){
			if(errors !== null){
				$.each(errors,function(index,error){
					$('#lead_script #'+error.target).html(error.message);
					//console.log(error.message);
				});
//				$('#lead_script span.error-message').show();
//				setTimeout(function(){ 
//					$('#lead_script span.error-message').hide();
//				}, 1000);
			}
			else{
				data.push({'field' : 'update', 'value' : update});
				data.push({'field' : 'script_step', 'value' : script_ident});
				if($('#paper_billing').length){
					data.push({'field' : 'paper_billing', 'value' : $('#paper_billing').is(':checked')});
				}
				if($('#contract_start_date:visible').length){
					data.push({'field' : 'contract_start_date', 'value' : $('#contract_start_date').val()});
				}
				showLoader();
				api.set_customer_details(quote_id,data,function(response){
					// go get the inline customer details content again and update the right div.
					makeRequest('/quote/get-inline-customer-details-html/' + quote_id, {}, function(response){
						hideLoader();
						if(isset(response.success) && response.success && isset(response.contact_html)){
							$('.customer-information-dynamic-text').html(response.contact_html);
						}
					}, 'GET');
					//refresh the contact info on the left hand side
					refeshContactDetails();
					
				});
				
			}
		});
	});
	
	window.refeshContactDetails = function(){
		
		var contact_id = $('#contact-details').data('contact-id');
		
		if(parseInt(contact_id) > 0){

			makeRequest('/contacts/get-details/' + contact_id, {}, function(response){
				if(response.errors == null) {		
					if(response.data != null){
						$.each(response.data, function(key, value){
							$('#contact-details').parent().find('[name="' + key + '"]').val(value);
						});
					}
				}
			}, 'GET');
		}
	};
	
	window.refeshContactProfile = function(){
		
		var contact_id = $('#contact-details').data('contact-id');
		
		if(parseInt(contact_id) > 0){
			makeRequest('/contacts/get-profile/' + contact_id, {}, function(response){
				if(isset(response.html)){
					$('#client-profile').html(response.html);
				}
			}, 'GET');
		}
	};
	
});
