/*Edit a Ticker Item*/
var editTicker = function(ticker_id,callback) {
	$.ajax({
		url: '/admin/ticker/edit/'+ticker_id,
			success: function(response) {
				if(response.success){
					$('#edit-ticker-container').html(response.html);
					$('body,html').animate({
						scrollTop: 0
					}, 800);
					callback();
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};
		
/*Save a Ticker Item*/
var saveTicker = function(ticker_data, callback) {
	$.ajax({
		url: '/admin/ticker/save',
		data: ticker_data,
		type: 'POST',
		dataType: 'json',
		success: function(response) {
			if(response.success){
					$('#edit-ticker-container').hide();
					$('body,html').animate({
						scrollTop: 0
					}, 800);
					callback();
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

/*Create a Ticker Item*/
var createTicker = function(name,target){
	var parent = target.parent();
	var input = $('.search-tickers').val();
	parent.find('.errors-container').hide();
	parent.find('span.errors').html('');
	$.ajax({
			data: {
				'ticker-name' : name
			},
			type: 'POST',
			url: '/admin/ticker/create',
			dataType: 'json',
			success: function(response) {
				parent.find('input[name="ticker-name"]').val('');
				$('#saved-tickers ul').append(response.html);
				$('.update-error').hide();
				$('.update-success').fadeIn().text('Created '+input);
				setTimeout(function(){
					$('.update-success').fadeOut();
				},5000);
				if(response.error) {
					$('.update-success').hide();
					parent.find('span.errors').html(response.error);
					parent.find('.errors-container').show();
				}
			},
			error: function (error) {
				//console.log(error);
			}
	});
};
	
/*Toggle a Ticker Item*/
var toggleTicker = function(id,state,callback){
	$.ajax({
		url: '/admin/ticker/toggle-active',
		data: {
			ticker_id:id,
			state:state
		},
		type: 'POST',
		dataType: 'json',
		success: function(response) {
			if(response.success){
				var ticker_target = '#data-ticker-id-'+id;
				var new_enable = $('#ticker_name').val();
				$(ticker_target).children().eq(1).html(new_enable);
				callback();
				//console.log("success woop woo");
		}
			//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

/*Checking Messages for a Ticker Item*/
var checkTickers = function(callback) {
	if($('#ticker-navbar-top').length == 0){
		return;
	}
	$.ajax({
		url: '/tickers/check',
		dataType: 'json',
		success: function(response) {
			if(response.success){
				callback(response.messages);
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var displayTickers = {
    max : 0, 
    current : 0,
    timeout : null,
	display_time : 5, // time to show a message in seconds
    go : function(messages) {
		this.max = messages.length;
		let parent = $('#ticker-messages').parent();
		if(isset(messages[this.current].type) && messages[this.current].type == 'appointment'){
			$(parent).find('.appointments-due-img').show();
			$(parent).find('.breaking-news-img').hide();
		}
		else{
			$(parent).find('.appointments-due-img').hide();
			$(parent).find('.breaking-news-img').show();
		}
		$('#ticker-messages').html(messages[this.current].text);
        this.current = (this.current+1) % this.max;//increment current position
        this.timeout = setTimeout(function(){displayTickers.go(messages);}, (this.display_time*1000));
    },
    stop : function(){
        clearTimeout(this.timeout);
    }
}


$( document ).ready(function() {
	//checking for ticker messages
	checkTickers(function(messages){
		//var display_duration = messages.length*displayTickers.display_time;
		$('#ticker-navbar-top').show();
		displayTickers.go(messages);
		
	//this will timeout the ticker so that it hides after all the ticker messages are shown
	//		setTimeout(function(){
	//			displayTickers.stop();
	//			$('#ticker-navbar-top').hide();
	//		}, (display_duration*1000));
	});

	//editing a ticker item
	$('#saved-tickers').on('click', '.edit-event', function() {
		//console.log('editing a ticker');
		var tickerID = $(this).parent().data('ticker-id');
		editTicker(tickerID,function() {
			$('.module-error').hide();
			$('#edit-ticker-container').show();
		});
	});
	
	//saving a ticker item
	$('#edit-ticker-container').on('click', '#save-ticker', function() {
		//console.log('saving ticker');	
		var ticker_name = $(this).parent().parent().find('#ticker_name').val();
		var ticker_text = $(this).parent().parent().find('#ticker_text').val();
		
		if(ticker_name.length < 3) {
			alert("ticker name needs to be 3 or more characters long");
		}
		else if(ticker_text.length > 160) {
			alert("ticker text needs to be 160 characters or less");
		}
		else {
			var team_inputs  = $(this).parent().parent().find('tr#ticker-teams input[type=checkbox]:checked');
			var visbileTickerTeams = [];
			$(team_inputs).each(function(){
				visbileTickerTeams.push($(this).data('team-id'));
			});

			var role_inputs = $(this).parent().parent().find('tr#ticker-roles input[type=checkbox]:checked');
			var visbileTickerRoles = [];
			$(role_inputs).each(function(){
				visbileTickerRoles.push($(this).data('role-id'));
			});
			var ticker_data = {
				tickerID : $(this).parent().parent().data('ticker-id'),
				tickerName : $('#ticker_name').val(),
				tickerText : $('#ticker_text').val(),
				tickerTeams : visbileTickerTeams,
				tickerRoles : visbileTickerRoles
			};
			//console.log(ticker_data);
			saveTicker(ticker_data, function() {            
				$('#edit-ticker-container').hide();
				$('#saved-tickers').load(location.href + " #saved-tickers");
			});
		}
	});
	
	//creating a ticker item
	$('#tickers-wrapper').on('click','#create-ticker', function() {
		//console.log('creating a ticker');
		var name = $(this).parent().find('input[name="ticker-name"]').val();
		var target = $(this).parent();
		
		if(name.length < 3) {
			target.parent().find('.module-error').fadeIn('fast').text("Please enter a longer name");
		} 
		else {
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Created - '+name);
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
			createTicker(name, target);
		}
	});
	
	//toggling a ticker item on and off
	$('#saved-tickers').on('click', '#toggle-ticker input', function() {
		var ticker_id = $(this).parent().parent().data('ticker-id');
		var state = $(this).is(':checked');
		toggleTicker(ticker_id,state,function(){
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Ticker toggled');
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		});
	});
	
});