var getContactActionLogCount = function(action_group_id,callback){
	$.ajax({
		url: '/action-group/history/'+action_group_id,
		dataType: 'json',
		success: function(response) {
			if(response.success){
				callback(response);
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var getActionTriggerStats = function(trigger_log_id,callback){
	$.ajax({
		url: '/action-trigger/history/'+trigger_log_id,
		dataType: 'json',
		success: function(response) {
			if(response.success){
				callback(response);
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

$( document ).ready(function() {
	// we go off to the server and grab all the things to show in the statistics column
	$('#action-group-active').on('click', '.action-group-stats-nav', function() {
		// grab id from data attribute
		var action_group_id = $(this).closest('.group-li').data('action_group_id');
		getContactActionLogCount(action_group_id,function(response){
			if(response.success){
				var element = document.getElementById('action-statistics');
				var positionInfo = element.getBoundingClientRect();
				var width = positionInfo.width;

				if(width < 99) {
					$('#action-statistics').animate({width: 'toggle'}, 100);
				}
				$('#groups-statistics-container').html(response.html);
			}
			// this is where you will update the html on the page
		});
	});
	
	// we go off to the server and grab all the things to show in the rule statistics column
	$('#action-statistics').on('click', '.action-group-trigger', function() {
		
		// we need to target the "a" tag here for the containing triggers
		
		// grab id from data attribute
		var trigger_log_id = $(this).data('trigger_log_id');
		getActionTriggerStats(trigger_log_id,function(response){
			if(response.success){
				$('#action-group-details').animate({width:'toggle'},100);
				$('#rule-statistics-container').html(response.html);
			}
			// this is where you will update the html on the page
		});
	});
	
	// this shows and hides the related triggers within the action statistics tables
	$('#action-statistics').on('click', '.view-triggers', function() {
		var target = $(this).parents().eq(2).find('.action-trigger-container-row');
		var button = $(this);
		target.slideToggle('fast', function() {
			// this applies the active class to the button of the active toggle
			if (target.is(':visible')) {
				button.addClass("view-active-triggers");
			}
			else {
				button.removeClass("view-active-triggers");
			}
		});
	});

	$('#filterChecked').on('click', function() {
		$('.group-li').each(function () {
			var item = $(this);
			if(!$(this).find('.active-action-group-switch').is(':checked')){
				item.toggle();
			}
		});
	});
});
