var createNewRssProvider = function(name, target){
	var parent = target.parent();
	var input = $('.search-providers').val();
	parent.find('.errors-container').hide();
	parent.find('span.errors').html('');

	$.ajax({
		data: {
			'rss-provider-name' : name
		},
		type: 'POST',
		url: '/rss/create-provider',
		dataType: 'json',
		success: function(response) {
			parent.find('input[name="rss-provider-name"]').val('');
			$('#saved-rss-providers ul').append(response.html);
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Created RSS Provider : '+input);
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
			if(response.error) {
				$('.update-success').hide();
				parent.find('span.errors').html(response.error);
				parent.find('.errors-container').show();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});	
};

var editRssProvider = function(rss_provider_id, callback){
	$.ajax({
		url: '/rss/edit/'+rss_provider_id,
			success: function(response) {
				if(response.success){
					$('#rss-feeds-wrapper').html(response.html);
					rss_feeds_sortable();
					$('body,html').animate({
						scrollTop: 0
					}, 800);
					callback();
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var toggleActiveRssProvider = function(rss_provider_id, state, callback) {
	$.ajax({
		url: '/rss/toggle-active',
		data: {
			'rss-provider-id':rss_provider_id,
			'state':state
		},
		type: 'POST',
		dataType: 'json',
			success: function(response) {
				if(response.success){
					var rss_provider_target = '#rss-provider-id-'+rss_provider_id;
					var new_enable = $(rss_provider_target).find('.saved-rss-provider-name .group-name').text();
					$(rss_provider_target).children().eq(1).find('.group-name').text(new_enable);
					callback();
					//console.log("success woop woo");
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var toggleActiveRssFeed = function(rss_feed_id, state, callback) {
	$.ajax({
		url: '/rss/feed/toggle-active',
		data: {
			'rss-feed-id':rss_feed_id,
			'state':state
		},
		type: 'POST',
		dataType: 'json',
			success: function(response) {
				if(response.success){
					var rss_feed_target = '#rss-feed-id-'+rss_feed_id;
					var new_enable = $(rss_feed_target).find('.saved-rss-feed-name .group-name').text();
					$(rss_feed_target).children().eq(1).find('.group-name').text(new_enable);
					callback();
					//console.log("success woop woo");
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var toggleActiveRssFeedItem = function(rss_feed_item_id, callback) {
	$.ajax({
		url: '/rss/item/toggle-active',
		data: {
			'rss-feed-item-id':rss_feed_item_id,
		},
		type: 'POST',
		dataType: 'json',
			success: function(response) {
				if(response.success){
					var rss_feed_item_target = '#rss-feed-item-id-'+rss_feed_item_id;
					var new_enable = $(rss_feed_item_target).find('.saved-rss-feed-item-name .rss-item-name').text();
					$(rss_feed_item_target).children().eq(1).find('.rss-item-name').text(new_enable);
					callback();
					//console.log("success woop woo");
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var createNewRssFeed = function(name, data, target){
	var parent = target.parent();
	var input = $('.search-feeds').val();
	parent.find('.errors-container').hide();
	parent.find('span.errors').html('');

	$.ajax({
		data: {
			'rss-feed-name' : name,
			'rss-provider-id' : data
		},
		type: 'POST',
		url: '/rss/create-feed',
		dataType: 'json',
		success: function(response) {
			parent.find('input[name="rss-feed-name"]').val('');
			$('#saved-rss-feeds ul').append(response.html);
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Created RSS Feed : '+input);
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
			if(response.error) {
				$('.update-success').hide();
				parent.find('span.errors').html(response.error);
				parent.find('.errors-container').show();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});	
};

var editRssFeed = function(rss_feed_id, callback){
	$.ajax({
		url: '/rss/feed/edit/'+rss_feed_id,
			success: function(response) {
				if(response.success){
					$('#rss-feed-editor-wrapper').html(response.html);
					$('body,html').animate({
						scrollTop: 0
					}, 800);
					callback();
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

//save a rss feed
var saveRssFeed = function(rss_feed_id, rss_feed_form_data, callback) {
	$.ajax({
		url: '/rss/feed/save',
		data: {
			'rss-feed-id' : rss_feed_id,
			'rss-feed-form-data' : rss_feed_form_data
		},
		type: 'POST',
		dataType: 'json',
		success: function(response) {
			if(response.success){
				//rename title in rss feed list if changed
				var rss_feed_name_target = '#rss-feed-id-'+rss_feed_id;
				var new_title = $('#rss-name').val();
				$(rss_feed_name_target).children().eq(1).children().text(new_title);
				callback();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});	
};

var update_feeds_order = function(data){
	$.ajax({
		type: "POST",
		dataType: 'json',
		data: {
			'feeds' : data,
		},
		url: '/rss/update-feeds-order',
		success: function(response) {
			if(checkErrors(response)){
				success_message();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var sort_feeds_update = function(li){
	var data = [];
	var list = $(li).closest('ul');

	$(list).find('li').each(function(){
		var id = $(this).data('rss-feed-id')
		
		if(data.indexOf(id) == -1){
			data.push(id);
		}
		else{
			$(this).remove();
		}
	});
	update_feeds_order(data);
}


var rss_feeds_sortable = function() {
	$( "#sortable-rss-feeds" ).sortable({
		update: function (e, ui) {
			sort_feeds_update(ui.item);
		}
	});
}

$(document).ready(function() {
		
	//rss provider search
	$('#rss-wrapper').on('keyup','.search-providers',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-rss-providers ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$('.module-error').hide();
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});	
	
	//create a new rss provider 
	$('#rss-wrapper').on('click','#create-provider',function(){
		var name = $(this).parent().find('input[name="rss-provider-name"]').val();
		var target = $(this).parent();
		if (name.length < 2) {
			target.parent().find('.module-error').fadeIn('fast').text("Please enter a longer name");
			return;
		} 
		else {
			createNewRssProvider(name, target);
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Created RSS Provider: '+name);
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		}
	});
	
	//editing a rss provider 
	$('#saved-rss-providers').on('click', '.edit-trigger', function() {
		var target = $(this).parent().attr('id').replace('rss-provider-id-','');
		editRssProvider(target, function() {
            $('#rss-feeds-wrapper').show();
        });
	});
	
	//toggle active an rss provider
	$('#saved-rss-providers').on('click', '.rss-provider-toggle-trigger input', function() {
		var rss_provider_id = $(this).parent().parent().attr('id').replace('rss-provider-id-','');
		var state = $(this).is(':checked');
		toggleActiveRssProvider(rss_provider_id,state,function(){
			$('.update-error').hide();
			$('.update-success').fadeIn().text('RSS Provider toggled');
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		});
	});
	
	//rss feed search
	$('#rss-wrapper').on('keyup','.search-feeds',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-rss-feeds ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$('.module-error').hide();
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});	
	
	//create a new rss feed 
	$('#rss-wrapper').on('click','#create-feed',function(){
		var name = $(this).parent().find('input[name="rss-feed-name"]').val();
		var data = $(this).data('rss-provider-id');
		var target = $(this).parent();
		if (name.length < 2) {
			target.parent().find('.module-error').fadeIn('fast').text("Please enter a longer name");
			return;
		} 
		else {
			createNewRssFeed(name, data, target);
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Created RSS Provider: '+name);
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		}
	});
	
	//editing a rss feed 
	$('#rss-wrapper').on('click', '#saved-rss-feeds .edit-trigger', function() {
		var target = $(this).parent().attr('id').replace('rss-feed-id-','');
		editRssFeed(target, function() {
            $('#rss-feed-editor-wrapper').show();
        });
	});
	
	//saving an rss feed
	$('#rss-feed-editor-wrapper').on('click','#feed-editor-save', function(e) {
		e.preventDefault();
		var feed_form_data = $('#form-feed-editor').serializeArray();
		var rss_feed_id = $('#rss-feed-id').data('rss-feed-id');
		saveRssFeed(rss_feed_id,feed_form_data,function() {
			$('#rss-feed-editor-wrapper').hide();
		});
		$('.update-error').hide();
		$('.update-success').fadeIn().text('RSS Feed saved');
		setTimeout(function(){
			$('.update-success').fadeOut();
		},5000);
	});
	
	
	//toggle active an rss feed
	$('#rss-wrapper').on('click', '#saved-rss-feeds .rss-feed-toggle-trigger input', function() {
		var rss_feed_id = $(this).parent().parent().attr('id').replace('rss-feed-id-','');
		var state = $(this).is(':checked');
		toggleActiveRssFeed(rss_feed_id,state,function(){
			$('.update-error').hide();
			$('.update-success').fadeIn().text('RSS Feed toggled');
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		});
	});

	
	//toggle active an rss feed item
	$('#rss-wrapper').on('click', '#saved-rss-feed-items .rss-feed-item-toggle-trigger input', function() {
		var rss_feed_item_id = $(this).parent().parent().parent().attr('id').replace('rss-feed-item-id-','');
		toggleActiveRssFeedItem(rss_feed_item_id,function(){
			$('.update-error').hide();
			$('.update-success').fadeIn().text('RSS Feed Item toggled');
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		});
	});
	
	
	//rss feed item search
	$('#rss-wrapper').on('keyup','.search-items',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-rss-feed-items ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$('.module-error').hide();
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});	
	
});