var sliderOutput = function () {
    $('.campaign-config-options').on('input', 'input[type="range"]', function () {
        $(this).parent().find('output').val($(this).val());
    });

    $('.campaign-config-options').on('focusin', 'input[type="range"]', function () {
        $(this).data('val', $(this).val());
    });


    $('#switch_site_config').on('input', 'input[type="range"]', function () {
        $(this).parent().find('output').val($(this).val());
    });

    $('#switch_site_config').on('focusin', 'input[type="range"]', function () {
        $(this).data('val', $(this).val());
    });
};

$(document).ready(function () {

    $('body').on('click', '.remove-config-item', function () {
        var item = $(this).data('item');
        var parent = $(this).closest('li').closest('div');
        var template = $(this).closest('li').closest('div.bobbins-123').find('.template-config-item');

        $(this).closest('li').hide();

        // var name = $(this).attr('name')
        console.log('here');

        var label = $(this).parent().find('label').text().trim();
        var element = $(template).clone().appendTo(parent);

        console.log(label);
        console.log(element);

        $(element).removeClass('template-config-item').removeClass('hide');
        $(element).find('span.select-campaign-config').html(label);
        $(element).find('a.add-campaign-config').attr('data-target', label);
    });

    $('body').on('click', '.select-campaign-config', function () {
        var item = $(this).find('a').text().trim();

        $('#switch_site_campaign_config_items div.collapse').each(function () {
            var id = $(this).attr('id');
            if (!'campaign_' + item.indexOf(id) === -1) {
                $(this).removeClass('in');
            }
        })
    });

    $('body').on('click', '.removed-config', function () {
        var that = $(this);
        var target = $(this).data('target');
        var inactive = $('.campaign-config-container').find('.team-container.ui-sortable-handle');

        $(inactive).each(function () {
            var current = $(this);
            var input = $(this).find('input').attr('name');
            if (input == target) {
                current.show();
                that.closest('.campaign-config-container-add-single').remove();
            }
        });
    });

    if ($('#switch_site_config').length) {
        sliderOutput();

        $('body').on('click', 'label.switch', function () {
            var input = $(this).find('input');

            if(input.is(':checked')) {
                input.val(1);
            }
            else {
                input.val(0);
            }
        });

        $('.campaign-config-panel').on('click', '#switch_config_save', function () {

                var campaign_item = $('.campaign-config-item-body:visible');
                var data = {};
                var basic_form_inputs = $(campaign_item).find('.field-list').find(':input');
                var config_form_inputs = $(campaign_item).find('.campaign-config-options').find('input:visible');

                $(basic_form_inputs).each(function () {
                    var that = $(this);
                    if($(this).attr('name') == 'name') {
                        var input = $(this);
                        data['old_name'] = input.data('old');
                        input.attr('data-old', $(that).val());
                    }
                    data[$(this).attr('name')] = $(that).val();
                });

                var json_block = {};
                $(config_form_inputs).each(function () {
                    json_block[$(this).attr('name')] = $(this).val();

                    data['config_items'] = json_block;
                });

                console.log('here');

                $.ajax({
                    cache: false,
                    url: 'admin/switch-site-config/save_campaign',
                    type: "POST",
                    dataType: 'json',
                    data: data,
                    success: function (response) {
                        if (response.error == null) {
                            success_message('Updated Campaign: '+data.name);
                        } 
						else {
                            error_message(response.error)
                        }
                    },
                    error: function (error) {
                        loaderClear();
                    }
                });
            }
        );

        $('.campaign-creation-container').on('click', '#add_campaign', function () {
            var that = $(this);
            $.ajax({
                cache: false,
                url: 'admin/switch-site-config/make_new_campaign',
                type: "GET",
                dataType: 'json',
                success: function (response) {
                    if (response.error == null) {
                        if (response.html) {
                            $('#switch_site_campaign_config_items').append(response.html);

                            var single = $('.campaign-holder .campaign-single').first();
                            var clone = single.clone().appendTo($('.campaign-holder'));
                            $(clone).removeClass('hide');

                            var item_a = $(clone).find('span').find('a');
                            item_a.attr('href', '#campaign_template');
                            item_a.html('template');

                            var item_b = $(clone).find('a.remove_campaign_button');
                            item_b.attr('data-target', 'template');

                            sliderOutput();



                            $(that).hide();
                        }
                    } 
					else {
                        error_message(response.error)
                    }
                },
                error: function (error) {
                    loaderClear();
                }
            });
        });

        $('body').on('click', '.remove_campaign_button', function () {
            var target = $(this).data('rm');
            var that = $(this);
            var stupid_test = confirm('are you sure you know what you are doing?');

            if (stupid_test) {

                $.ajax({
                    cache: false,
                    url: 'admin/switch-site-config/remove_campaign',
                    type: "POST",
                    dataType: 'json',
                    data: {name: target},
                    success: function (response) {
                        if (response.error == null) {
                                $(that).closest('div.campaign-single').remove();
                        } 
						else {
                            error_message(response.error)
                        }
                    },
                    error: function (error) {
                        loaderClear();
                    }
                });
            }
        })
    }

    // $('body').on('click', '.remove-config-item', function () {
    //     var item = $(this).data('item');
    //     var parent = $(this).closest('li').closest('div');
    //     var template = $(this).closest('li').closest('div').children('.template-config-item');
    //
    //     $(this).closest('li').hide();
    //
    //     // var name = $(this).attr('name');
    //     var label = $(this).parent().find('label').text().trim();
    //     var element = $(template).clone().appendTo(parent);
    //     $(element).removeClass('template-config-item').removeClass('hide');
    //     $(element).find('span.select-campaign-config').html(label);
    //     $(element).find('a.add-campaign-config').attr('data-target', label);
    // });

//campaigns config collapse
//     $('body').on('click', '.select-campaign-config', function () {
//         var item = $(this).find('a').text().trim();
//
//         $('#switch_site_campaign_config_items div.collapse').each(function () {
//             var id = $(this).attr('id');
//             if (!'campaign_' + item.indexOf(id) === -1) {
//                 $(this).removeClass('in');
//             }
//         })
//     });
//
//     $('body').on('click', '.removed-config', function () {
//         var that = $(this);
//         var target = $(this).data('target');
//         var inactive = $('.campaign-config-container').find('.team-container.ui-sortable-handle');
//
//         $(inactive).each(function () {
//             var current = $(this);
//             var input = $(this).find('input').attr('name');
//             if (input == target) {
//                 current.show();
//                 that.closest('.campaign-config-container-add-single').remove();
//             }
//         });
//     });
//
    $('body').on('click', '.add-campaign-config', function () {
        var target = $(this).data('target');
        $(this).closest('.add-item-' + target).addClass('hide');
        $(this).closest('.campaign-config-container-add-single').find('.input-hidden-' + target).removeClass('hide');
        $(this).closest('.input-hidden-'+target).removeClass('hide');
    });

    $('.global-campaign-config').on('click', '#switch_config_save', function () {
        var data = {};

        $('#switch_site_config_items input').each(function () {
            if ($(this).data('type') == 'json') {
                //its a bit hacky but json fields will be added seperately
                return;
            }

            data[$(this).attr('name')] = $(this).val();
        });

        $('.switch-config-item[data-type="json"]').each(function () {
            var json_block = {};
            var index = 0;

            $(this).find('div[data-type="json_item"]').each(function () {
                var json_item = {};

                $(this).find('input').each(function () {
                    json_item[$(this).attr('name')] = $(this).val();
                });
                $(this).find('select').each(function () {
                    json_item[$(this).attr('name')] = $(this).val();
                });
                json_block[index] = json_item;
                index++;
            });

            data[$(this).data('item-name')] = json_block;
        });

        $.ajax({
            cache: false,
            url: 'admin/switch-site-config/save',
            type: "POST",
            dataType: 'json',
            data: data,
            success: function (response) {
                if (response.error == null) {
                    success_message();
                } 
				else {
                    error_message(response.error)
                }
            },
            error: function (error) {
                loaderClear();
            }
        });
    });

    $('body').on('click', '.conversion_go', function(){

        var parent = $(this).closest('.config-section');
        
        var data = {};

        data.url = parent.find('#url_selection').val();
        var time_range = parent.find('#date_range').val();
        data.start = time_range.split('-')[0];
		data.end = time_range.split('-')[1];
		data.site_id = $(this).data('site-id');
		if($(this).data('campaign-id')){
			data.campaign_id = $(this).data('campaign-id');
		}
        var that = this;
        makeRequest('/get_tracking_data', data, function(response){
            if(isset(response.html)){
                $(that).closest('#conversion_tracking').replaceWith(response.html);
                makeDateRangePicker();
                success_message();
            }
            else{
                error_message('turn it off and on again please');
            }
        });
    });

    $('body').on('click', '#filter-leads-by-date', function () {
        var data = {};
        var date_range = $('input[name="lead-date-filter-picker-unique-name"]').val();
        data.start = date_range.split('-')[0];
        data.end = date_range.split('-')[1];
        var that = this;

        makeRequest('/get_missing_leads_data', data, function(response){
            if(isset(response.html)){
                $(that).closest('.missed-leads-container').replaceWith(response.html);
                // makeLeadDateRangePicker();
                makeLeadDateRangePicker();
                success_message();
            }
            else{
                error_message('turn it off and on again please');
            }
        });
    });

    $('body').on('click', '.journey-row', function(){
        $(this).find('.journey-break-down').show();
        $(this).find('.journey-other-data').show();

    });
   
   
    $('body').on('click', '.reporting-item', function(){
        $(this).find('.reporting-sub-item').toggle();

    });

    var makeLeadDateRangePicker = function (){
        $('input[name="lead-date-filter-picker-unique-name"]').daterangepicker({
            "locale": {
                "format": 'DD/MM/YYYY HH:mm',
                "separator": "-"
            },
            "timePicker": true,
            "linkedCalendars": false,
            "showDropdowns": true
        });
    };

    if($('input[name="lead-date-filter-picker-unique-name"]').length > 0){
        makeLeadDateRangePicker();
    }

    var makeDateRangePicker = function (){
        $('input[name="conversion-date-filter"]').daterangepicker({
            "locale": {
                "format": 'DD/MM/YYYY HH:mm',
                "separator": "-"
            },
            "timePicker": true,
            "linkedCalendars": false,
            "showDropdowns": true
		});
    };

    if($('input[name="conversion-date-filter"]').length > 0){
		makeDateRangePicker();
	}

    $('body').on('click', '#manually-reset-leads', function () {
        var data = {};
        var date_range = $('input[name="lead-date-filter-picker-unique-name"]').val();
        data.start = date_range.split('-')[0];
        data.end = date_range.split('-')[1];

        var Jedi = confirm('are you sure, this could cause duplicate leads!');
        if (Jedi === true) {
            makeRequest('/admin/super-secret-james/resend-leads', data, function (response) {
                if (isset(response.success)) {
                    success_message(response.message);
                    //window.location.reload();
                } 
				else {
                    alert('something went wrong');
                }
            });
        }
    });

    $('body').on('click', '#download-missed-leads', function () {
        var data = {};
        var date_range = $('input[name="lead-date-filter-picker-unique-name"]').val();
        data.start = date_range.split('-')[0];
        data.end = date_range.split('-')[1];

        var Jedi = confirm('are you sure, this could cause duplicate leads!');
        if (Jedi === true) {
            makeRequest('/admin/super-secret-james/download-leads', data, function (response) {
                if (response.success) {
                    success_message(response.message);
                    //success_message('file generated');

                    if (response.file) {
                        window.location = '/download/file/' + response.file;
                    }
                    //window.location.reload();
                } 
				else {
                    error_message(response.message);
                }
            });
        }
    });
   
	$('#super-secret-actions').on('click', '#update_switch_site_tariffs', function () {
		$('#super-secret-actions .action-ouput').text('');
        if (confirm('are you sure you want to do this, it risks breaking the switch site - one does not simply update tariffs - you might also want to have the network inspector open for this')) {
			$(this).html('<i class="fa-regular fa-circle-notch fa-spin"></i>');
			var that = this;
            makeRequest('/admin/super-secret-james/update-tariffs', {}, function (response) {
				var message = 'oops, something didn\'t work, try again maybe? or look in the inspector';
                if (isset(response.success)) {
					var success_string = 'All done, better check the switch site is still up...';
					if(isset(response.result)){
						$.each(response.result, function(type, items){
							$.each(items, function(action, count){
								success_string += ' ' + type + ' ' + action + ' ' + count + ' -- ';
							});
						});
					}
					message = success_string;
				}
				else {
					message = 'failed to update tariffs -- please go see if the switch site is still working!';
					if(isset(response.error)){
						message = response.error;
					}
                }
				$('#super-secret-actions .action-ouput').text(message);
				$(that).html('Update Tariffs');
            });
        }
	});
	
	$('#crm_config_save').on('click', function () {
		
		var serialized = $('#crm_config_items :input').serializeArray();
		$.each($('#crm_config_items').find(':checkbox:not(:checked)'), function (i, item){ 
			serialized.push({ name: item.name, value: 0 }); 
		});
		var data = sanitiseSerialedArray(serialized);
		var to_send = {
			'data' : data
		};
		
		customConfirm('Are you sure you want to update the config?', function(){
			showLoader();
			makeRequest('/admin/super-secret-james/update-crm-config', to_send, function (response) {
				if (isset(response.success)) {
					success_message();
				}
				else {
					message = 'failed to update config';
					if(isset(response.error)){
						message = response.error;
					}
					error_message(message);
				}
			});
			hideLoader();
		});


	});

	$('.switch-site-edit-panel').on('click', '.get-default-config-value', function (e) {
		e.preventDefault();
		var config_item_name = $(this).data('config-option');
		var campaign_id = $(this).data('campaign-id');

		var data = {
			'config_item_name' : config_item_name,
			'campaign_id' : campaign_id
		};

		makeRequest('/admin/switch-site/get-default-config-value', data, function (response) {
			if(isset(response.value)){
				customConfirm('The value is ' + response.value);
			}
			else{
				error_message('failed to get value');
			}
		});

	});
	
	$('.switch-site-edit-panel').on('click', '.delete-campaign-button', function (e) {
		e.preventDefault();
		
		var campaign_id = $(this).data('campaign-id');
		customConfirm('Are you sure you want to delete this campaign?', function(){


			var data = {
				'campaign_id' : campaign_id
			};

			makeRequest('/admin/switch-site/campaign/delete', data, function (response) {
				if(isset(response.success) && response.success){
					$('#campaign_config').hide();
					$('#campaign_edit_select option[value="' + campaign_id + '"]').remove()
					$('#campaign_edit_select').val('').trigger('change');
					success_message('campaign deleted');
				}
				else{
					var error = 'failed to delete campaign';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			});
		});

	});


});