$(function() {

	registerCallbackOnAllScripts('broadband.data_capture', function(){
		$('.script .select2').select2({
			'width': '100%'
		});

		$('.date-picker').daterangepicker({
			"locale": {
				"format": 'DD-MM-YYYY',
				"separator": "-" 
			},
			"singleDatePicker": true,
			"showDropdowns": true
		});
	});
	
	registerCallbackOnAllScripts('broadband.broadband-details', function(){
		$('.script .select2').select2({
			'width': '100%'
		});

		$('.date-picker').daterangepicker({
			"locale": {
				"format": 'DD-MM-YYYY',
				"separator": "-" 
			},
			"singleDatePicker": true,
			"showDropdowns": true
		});
	});
	
	registerCallbackOnAllScripts('broadband.external', function(){
		$('.script .select2').select2({
			'width': '100%'
		});
	});
});
