window.handleRequestError = function(response, callback = null) {
	if(isset(response.error)) {
		error_message(response.error);
		if(typeof callback === 'function') {
			callback();
		} 
		else {
			return;
		}
	}
	error_message();
	if(typeof callback === 'function') {
		callback();
	}
}

window.makeRequest = function (url, data, callback = function () { }, method = 'POST') {
	$.ajax({
		url: url,
		type: method,
		data: data,
		dataType: 'json',
		cache: false,
		headers : {
			'X-CSRF-Token': $('body').data("token")
		},
		success: function (response) {
			callback(response);
		},
		error: function (error) {
			var result = {
				'success': false,
				'errors': ['an unknown error occurred']
			};
			if (isset(error.responseText)) {
				result.details = error.responseText;
			}
			callback(result);
		}
	});
};

window.makeFileRequest = function(url, data, callback = function () { }) {
	$.ajax({
		url: url,
		type: 'POST',
		data: data,
		enctype: 'multipart/form-data',// multipart
		contentType: false,       // The content type used when sending data to the server.
		cache: false,             // To unable request pages to be cached
		processData:false,        // To send DOMDocument or non processed data file it is set to false
		headers : {
			'X-CSRF-Token': $('body').data("token")
		},
		success: function(response) {
			callback(response);
		},
		error: function (error) {
			var result = {
				'success': false,
				'errors': ['an unknown error occurred']
			};
			if (isset(error.responseText)) {
				result.details = error.responseText;
			}
			callback(result);
		}
	});
}

window.isset = function(value) {
	if(value != undefined && value != null) {
		return true;
	}
	else {
		return false;
	}
};

//adds a jquery selector to detect if an element should be visible but isn't becuase of a parent 
//WANRING - this is pretty slow so use with caution
jQuery.expr[':'].hiddenByParent = function(a) {
	return jQuery(a).is(':hidden') && jQuery(a).css('display') != 'none';
};

//helper to grab url GET Params 
window.urlParam = function (name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)')
                      .exec(window.location.href);
	
	if(results != null){
	    return results[1] || 0;
	}
	else{
		return '';
	}
}

window.getAllUrlParams = function(){
	var url = window.location.href;
	var url_parts = url.split('?');
	var result = {};
	if(url_parts.length > 1) {
		var query_string = url_parts.pop();
		var data = query_string.split('&');
		$.each(data, function(i, string_item){
			var string_parts = string_item.split('=');
			if(string_parts.length == 2){
				result[string_parts.shift()] = string_parts.pop();
			}
		})
	}
	return result;
}


window.customConfirm = function(message, callback = function(){}){

	$('#confirm_modal #really_confirm_confirmation_4_realz').off();
	$('#confirm_modal #custom_message').html(message);
	$('#confirm_modal #really_confirm_confirmation_4_realz').on('click', function(){callback()});
	//apparently someone somewhere decided it would be a good idea to hide all of the modal footers for all of the modals on the page
	$('#confirm_modal div.modal-footer').show();
	$('#confirm_modal').modal();

}

window.customPrompt = function(message, input_label, callback = function(input_value){}){
	if(typeof(input_label) == 'function'){
		callback = input_label;
		input_label = '';
	}
	$('#prompt_modal #really_confirm_confirmation_4_realz').off();
	$('#prompt_modal #custom_message').html(message);
	$('#prompt_modal label').html(input_label);
	$('#prompt_modal #really_confirm_confirmation_4_realz').on('click', function(){callback($('#prompt_modal #custom_prompt').val())});
	//apparently someone somewhere decided it would be a good idea to hide all of the modal footers for all of the modals on the page
	$('#prompt_modal div.modal-footer').show();
	//move the prompt modal to the bottom of the modal container so that it will appear on top of other modals 
	$('#prompt_modal').appendTo($('#prompt_modal').parent());
	$('#prompt_modal').modal();

}



window.safe_json_decode = function(json_string){
    var decoded = {};
    try{
        decoded = JSON.parse(json_string);
    }
    catch(e){}
    return decoded;
}

window.isString = function(potential_string){
	return typeof(potential_string) == 'string';
}

window.ucfirst = function(string){
	if(isString(string)){
	    return string.charAt(0).toUpperCase() + string.slice(1);
	}
	return string;
}

window.lcfirst = function(string){
	if(isString(string)){
    	return string.charAt(0).toLowerCase() + string.slice(1);
	}
	return string;
}

window.ucwords = function(string){
	if(isString(string)){
		return string.split(/[ -_]/g).map(function(word){
			return ucfirst(word.toLowerCase());
		}).join(' ');
	}
	return string;
}

window.toCamelCase = function(string){
	return lcfirst(ucwords(string).replace(/ /g,''));
}

window.showNotification = function(title, body, clickCallBack = function(){}, show_seconds = 4){
	var notication_container = $('.notification-overlay-container');
	var current_timeout = $(notication_container).data('timeout');
	if(current_timeout){
		clearTimeout(current_timeout);
		$(notication_container).data('timeout', false);
	}
	$(notication_container).removeClass('slide-in-right');
	$(notication_container).removeClass('slide-out-right');
	//remove any existing event listeners
	$(notication_container).off();
	$(notication_container).find('.notification-title').html(title);
	$(notication_container).find('.notification-body').html(body);
	$(notication_container).addClass('slide-in-right');
	var timeout = setTimeout(function(){
		$(notication_container).removeClass('slide-in-right');
		$(notication_container).addClass('slide-out-right');
		$(notication_container).data('timeout', false);
	}, show_seconds * 1000);
	$(notication_container).data('timeout', timeout);
	$(notication_container).on('click', function(){clickCallBack()});
}

window.copyToClipboard = function(string){
	var el = document.createElement('textarea');
	el.value = string;
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
};


window.makeLoadingIcon = function(element){
	$(element).data('pre-load-class', $(element).attr('class'));
	$(element).attr('class', 'fa-regular fa-circle-notch fa-spin');
}

window.restoreIcon = function(element){
	var old_class = $(element).data('pre-load-class');
	$(element).attr('class', old_class);
}

window.makeLoadingIconFromText = function(element){
	var prev_text = $(element).html();
	$(element).data('pre-load-text', prev_text);
	$(element).html('<i class="fa-regular fa-circle-notch fa-spin"></i>');
}

window.restoreTextFromLoadingIcon = function(element){
	var old_text = $(element).data('pre-load-text');
	$(element).html(old_text);
}

window.copyTextFromNode = function (id) {
	if($('#' + id).length) {
		var range_ = document.createRange();
		range_.selectNode(document.getElementById(id));
		window.getSelection().removeAllRanges();
		window.getSelection().addRange(range_);
		document.execCommand("copy");
		window.getSelection().removeAllRanges();
	}
}

$(function () {
	$('body').on('click', '.toggle-input-from-label', function() {
		$('[name="' + $(this).attr('for') + '"]').attr('checked', true);
	});

	$('body').on('click', '.copy-text-from-node', function () {
		copyTextFromNode($(this).data('copy-id'));
		success_message('coppied')
	});

	$('[data-toggle="tooltip"]').tooltip({
		container: 'body',
		trigger : 'hover'
	});

	$('body').on('click', '[data-toggle="accordion"]', function () {
		var target = $(this).data('target');
		var accordion = $(target).closest('.accordion');
		$(accordion).find('.collapse').collapse('hide');
		$(target).collapse('show');
	});



	var downloadPDF = function (html, brand) {
		showLoader();
		var data = {
			html: html,
			brand: brand
		};

		makeRequest('/download/pdf', data, function (response) {
			if (response.success) {
				if (response.file) {
					hideLoader();
					success_message('PDF Generated');
					window.location = '/download/file/' + response.file;
				}
			} 
			else {
				error_message(response.errors);
				hideLoader();
			}
			hideLoader();
		});
	};

	$('body').on('keyup', '.3-column-search', function(){
		var container = $(this).closest('.module-inner');
		var search_items = container.find('li.ui-sortable-handle, li.three-column-item');
		var text = $(this).val().toLowerCase();

		if(text.length == 0){
			$(search_items).show();
			return;
		}

		$.each(search_items, function(i, item){
			var compare = $(item).text().toLowerCase();

			if(compare.indexOf(text) > -1){
				$(item).show();
			}
			else{
				$(item).hide();
			}
		});
	});

	$('body').on('click', '.magic_pdf_download', function () {
		var html = $(this).closest('.exportable-container').html();
		var brand = $(this).data('type');

		downloadPDF(html, brand);
	});

	window.chatInterface = require('../websockets/chat_websocket');
	if(!$('.login-panel').length && $('#show_messages_icon').length) {
		chatInterface.start();
	}
	
	window.softphoneInterface = require('../websockets/softphone_websocket');
	if(!$('.login-panel').length && $('#softphone_control').length) {
		softphoneInterface.start({
			'connection_type' : 'crm'
		});
	}
	else if($('#call_action_button').length){
		//lets set up our websocket
		softphoneInterface.start({
			'connection_type' : 'softphone'
		});
	}

	$('body').on('click', '.click-to-copy', function(){
		var to_copy = $(this).data('to-copy');
		if(to_copy){
			copyToClipboard(to_copy);
		}
	});

	window.desktopNotification = function(title, body = '', clickCallBack = function(){}){
		if (isset(window.Notification)) {

			var send_function = function(){
				var notification = new Notification(title, {
					'body' : body
				});
				notification.onclick = function(){
					window.focus();
					clickCallBack();
				};
			}

			if (Notification.permission === "granted") {
				send_function();
			}
			else{
				Notification.requestPermission().then(function (permission) {
					if (permission === "granted") {
						send_function();
					}
				});
			}
		}
	}

	$('body').on('keyup', '.list-search', function () {
		var search_term = $(this).val().toLowerCase();
		var target = $(this).closest('.search-container').find('.searchable-list');
		if(search_term === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(search_term) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});

	window.sanitiseSerialedArray = function(array, name_key = 'name', value_key = 'value'){
		var result = {};
		$.each(array, function(i, input){
			if(isset(result[input[name_key]])){
				//multi value 
				if(typeof(result[input[name_key]]) != 'object'){
					result[input[name_key]] = [result[input[name_key]]];
				}
				result[input[name_key]].push(input[value_key]);
			}
			else{
				result[input[name_key]] = input[value_key];
			}
		});
		return result;
	}

	window.sanitiseSerializedArray = function(array, name_key = 'name', value_key = 'value'){
		return sanitiseSerialedArray(array, name_key, value_key);	
	}
});


