

$(document).ready(function() {
		
	$('#database_controls').on('click', '#database_run', function() {
		var db_1 = $('#database_1').val();
		var db_2 = $('#database_2').val();
		
		$.ajax({
			url: '/compare-db/run',
			type: "POST",
			dataType: 'json',
			data: {
				"db_1" : db_1,
				"db_2" : db_2
			},
			success: function(response) {
				if(response.success){
					$('#database_tables').html(response.html);
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});
	
	$('#database_tables').on('click', '.view-action', function(){
		
		var table_name = $(this).data('table-name');
		var db_1 = $('#database_1').val();
		var db_2 = $('#database_2').val();
		
		$.ajax({
			url: '/compare-db/compare-table/' + table_name,
			type: "POST",
			dataType: 'json',
			data: {
				"db_1" : db_1,
				"db_2" : db_2
			},
			success: function(response) {
				if(response.success){
					$('#database_view_panel').html(response.html).show();
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		
	});
});