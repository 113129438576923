var update_script = function(script_group_id,questions_in_group){
	$.ajax({
		type: "POST",
		dataType: 'json',
		data: {
			questions_in_group : questions_in_group,
			script_group_id : script_group_id
		},
		url: '/compliance/admin/update-script/'+script_group_id,
		success: function(response) {
			$('.update-error').hide(); //this is here just incase the errors are actually visible
			$('.update-success').fadeIn().text('Updated');
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

//this runs the drag and drop from unallocated teams into the units block
var script_sortable = function() {
	$( "ul[id^=scriptsort_]" ).sortable({
		connectWith: ".scriptSortable",
//		appendTo: "body",
//		helper: "clone",
//		containment: "window",
		update: function () {
			$('.unit-wrapper').each(function(){
				var questions_in_group = [];
				var script_group_id = $(this).parent().parent().data('script_group_id');
				$('#scriptsort_'+script_group_id).find('li').each(function(){
					var question_id = $(this).data('question');
					questions_in_group.push(question_id);
				});
	//			var team_id = $('#saved-units').find('#teamsort_'+unit_id).data('team');
				update_script(script_group_id,questions_in_group);
			});
			
		}
	}).disableSelection();
};

$( document ).ready(function() {
	
	script_sortable();
	
	$('#script-steps, #questions').on('click', '.edit-trigger', function() {
		var question_id = $(this).parent().data('question');
		$.ajax({
			url: '/compliance/admin/edit-question/'+question_id,
				success: function(response) {
					if(response.success){
						$('#edit-question').show().html(response.html);
						script_sortable();
				}
			},
			error: function (error) {
			}
		});
	});
	
	$('#questions').on('click', '#save-question', function() {
		var form = $('#question-edit-container').children().closest("form");
		var question_id = $('#question-edit-container').data('question_id');
		var data = [];
		form.find(':input').each(function(){
			if($(this).attr('type') == 'checkbox'){
				var value  = $(this).is(':checked') ? 1 : 0;
			}
			else{
				var value = $(this).val();
			}
			var input = {name:$(this).attr('name'),value:value};
			data.push(input);
			
		});
		$.ajax({
			type: "POST",
			dataType: 'json',
			data: {
				data : data
			},
			url: '/compliance/admin/update-question/'+question_id,
				success: function(response) {
					if(response.success){
						$('#question-'+question_id).html(response.html);
						$('#edit-question').hide();
						$('.update-error').hide();
						$('.update-success').fadeIn().text('Updated');
						setTimeout(function(){
							$('.update-success').fadeOut();
						},5000);
					}
				},
			error: function (error) {
		
			}
		});
	});
	
	$('#questions').on('click', '#add-question', function() {
		var question_name = $(this).parent().find("input[name='new_question_name']").val();
		console.log(question_name);
		$.ajax({
			type: 'POST',
			url: '/compliance/admin/create-question',
			dataType: 'json',
			data: { 
				question_name : question_name
			},
			success: function(response) {
				$('scriptsort_').append(response.html);
				$('.update-error').hide(); //this is here just incase the errors are actually visible
				$('.update-success').fadeIn().text('Created - '+question_name);
				setTimeout(function(){
					$('.update-success').fadeOut();
				},5000);
			},
			error: function (error) {
				$('.update-error').fadeIn().text('Question already exists');
				$('.update-success').hide();
				setTimeout(function(){
					$('.update-error').fadeOut();
				},5000);
			}
		});
	});
	
	$(window).scroll(function(){
		if ($(this).scrollTop() > 75) {
			$('#fixed-top').addClass('affix');
		} 
		else {
			$('#fixed-top').removeClass('affix');
		}
	});
	
	$('#questions').on('click', '#cancel-edit-question', function() {
		$('#edit-question').html('');
		$('#edit-question').hide();
	});
	
	$('#questions').on('keyup','.search-questions',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#teams-container ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});
	
});