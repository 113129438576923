$(document).ready(function(){
	var api = new apiInterface();
	var quote_id = $('#lead_script').data("quoteid");
	var view = new displayHelper();
	var validator = new usageValidator();
	$('#lead_script').on('click',"#supplyPreferencesContinue", function(){
		var payment_preference = $('select[name="futurePayment"]').val();
		// var smart_prepay = $('select[name="futurePayment"] option:selected').text() == 'Smart PrePay';
		var billing_preference = $('select[name="futureBilling"]').val();
		var has_ev = $('.ev-option:checked').val();
		var values = {
			'payment_preference' : payment_preference,
			'billing_preference': billing_preference,
			'customer:has_electric_vehicle' : has_ev
			// 'smart_prepay': smart_prepay
		};
		var that = this;
		api.set_supply_preferences(quote_id,values,function(){
			if($(that).data('success-step')){
				window.lead_script.handleStep($(that).data('success-step'));
			}
			else{
				window.lead_script.handleStep(window.lead_script.magicPlease('get_available_tariffs'));
			}
			// set preferences then go to next step
//			api.get_available_tarrifs(quote_id,function(response){
//                $('#tariffListContainer').html(response.html);
//				$('#tariffListContainer').show();
//			});
		});
		
	});

	$('#lead_script').on('click',"#tsmChangePaymentMethodOverride", function(){
		var payment_preference = $('select[name="futurePayment"]').val();
		// var smart_prepay = $('select[name="futurePayment"] option:selected').text() == 'Smart PrePay';
		
		var values = {
			'billing:payment_preference' : payment_preference,
			'electricity:current_payment_method' : payment_preference,
			'gas:current_payment_method' : payment_preference,
			// 'smart_prepay': smart_prepay
		};
		api.set_tsm_payment_override(quote_id,values,function(){
			window.lead_script.handleStep(window.lead_script.magicPlease('tariff_pitch'));
		});
		
	});

	$('#lead_script').on('change','.energy-bundle-pricing-calculator input',function() {
		// if we're changing this we should probably be updating profile too
		var new_container = $('#lead_script .new-energy-bundle-pricing-calculator');
		var new_total = 0;
		$(new_container).find('input').each(function(index,element){
			if(!isNaN(parseInt($(element).val()))){
				new_total+= parseInt($(element).val());
			}
		});

		var old_container = $('#lead_script .energy-bundle-pricing-calculator');
		var old_total = 0;
		$(old_container).find('input').each(function(index,element){
			if(!isNaN(parseInt($(element).val()))){
				old_total+= parseInt($(element).val());
				if($(element).attr('name') == 'current_energy_monthly_spend'){
					$('.monthly-spend-fact-find-container input[name="current_energy_spend"]').val(parseInt($(element).val()));
				}
				else if($(element).attr('name') == 'current_broadband_monthly_spend'){
					$('.monthly-spend-fact-find-container input[name="current_broadband_spend"]').val(parseInt($(element).val()));
				}
			}
		});
		$('#lead_script').find('.new-total-value-cell').text('£'+new_total);
		$('#lead_script').find('.total-value-cell').text('£'+old_total);
		// use this to update the script and show some savings
		$('#lead_script').find('.new-spend-total-span').text('£'+new_total);
		$('#lead_script').find('.old-spend-total-span').text('£'+old_total);
		var saving = parseInt(old_total-new_total);
		if(isNaN(saving)){
			saving = 0;
		}
		$('#lead_script').find('.difference-total-spend-span').text('£'+saving);
		// then udpate the script to show the new values.
	});

	$('#lead_script').on('change','.new-energy-bundle-pricing-calculator input',function() {
		// we now have the suggested new monthly spend from UW
		// grab the total for all the inputs in this secion
		var new_container = $('#lead_script .new-energy-bundle-pricing-calculator');
		var new_total = 0;
		$(new_container).find('input').each(function(index,element){
			if(!isNaN(parseInt($(element).val()))){
				new_total+= parseInt($(element).val());
			}
		});

		var old_container = $('#lead_script .energy-bundle-pricing-calculator');
		var old_total = 0;
		$(old_container).find('input').each(function(index,element){
			if(!isNaN(parseInt($(element).val()))){
				old_total+= parseInt($(element).val());
			}
		});
		$('#lead_script').find('.new-total-value-cell').text('£'+new_total);
		// use this to update the script and show some savings
		$('#lead_script').find('.new-spend-total-span').text('£'+new_total);
		$('#lead_script').find('.old-spend-total-span').text('£'+old_total);
		var saving = parseInt(old_total-new_total);
		if(isNaN(saving)){
			saving = 0;
		}
		$('#lead_script').find('.difference-total-spend-span').text('£'+saving);
	});

	$('#lead_script').on('click','#show_ev_tariffs_data',function() {
		$(this).hide();
		$('#show_smart_meter_text').show();
		$('#show_standard_text').show();

		$('#normal_script_text').hide();
		$('#electric_vehicle_text').show();
		$('#smart_meter_text').hide();

		$('#tariffListContainer').find('.tariff-box').hide();
		$('#tariffListContainer').find('.smart-prepay-tariff').hide();
		$('.ev-tariffs-container').show();
		$('.ev-tariffs-container .tariff-box').show();
	});

	$('#lead_script').on('click','.create-price-alert',function(e) {
		e.preventDefault();

		//grab quoteID from the order list
		var quote_id = $('#lead_script').data('quoteid');
//
//		//patch the correct quote into the script header (memory and DOM)
		$('#price_alert_script').data('quoteid',quote_id);
		$('#price_alert_script').attr('data-quoteid',quote_id);

		setTimeout(function() {
			//initialise the script flow for coaching
			window.price_alert_script = new ScriptFlow($("#price_alert_script"));//,'price-alert-energy');

			//show the populated modal
			$('#price_alert_modal').modal();
		}, 500);

	});
	
	$('#lead_script').on('click','.tariff-details-button',function(el){
		var tariff_id = $(this).data('tariffid');
		if($('#tariff-detail-parent-'+tariff_id).find('.tariff-info-box').html().length == 0){
			api.get_tariff_details(quote_id,$(this).data('details'),$(this).data('json'),function(response){
				$('#tariff-detail-parent-'+tariff_id).find('.tariff-info-box').html(response.details);
				$('#sms-'+tariff_id).data('shortcode',response.short_code);
				$('#email-'+tariff_id).data('shortcode',response.short_code);
				$('#sms-'+tariff_id).show();
				$('#email-'+tariff_id).show();
				//console.log(response.raw);
			});
		}
	});
	
	$('#lead_script').on('click','.sms-tariff-details-button',function(el){
		var short_code = $(this).data('shortcode');
		var number = prompt("Please enter a mobile numer",'');
		if (number !== null) {
			api.sms_tariff_details(quote_id,short_code,number,function(response){
				alert('sms sent');
				//$('#tariff-detail-parent-'+tariff_id).find('.tariff-info-box').html(response.details);
				//console.log(response.raw);
			});
		}
	});
	
	$('#lead_script').on('click','.email-tariff-details-button',function(el){
		var short_code = $(this).data('shortcode');
		var email = prompt("Please enter an email address",'');
		if (email !== null) {
			api.email_tariff_details(quote_id,short_code,email,function(response){
				alert('email sent');
				//$('#tariff-detail-parent-'+tariff_id).find('.tariff-info-box').html(response.details);
				//console.log(response.raw);
			});
		}
	});
	

	var handlePromo = function(that){
		// customPrompt('Would you like to apply a discount code?', 'Discount Code', function(input_value){
			var json = $(that).data('json');
			// if(input_value && input_value.length){
			// 	//we have a promo code
			// 	json.promo_code = input_value;
			// }
			json.auto_apply_promo = true;
			var tariff_id = $(that).data('tariffid');
			var brand_id = $(that).data('brandid');
			json.details = $(that).parent().find('.tariff-details-button').data('details');
			api.set_tariff_selection(quote_id,tariff_id,brand_id,json,function(response){
				if(isset(response.success) && response.success){
					window.lead_script.checkStep($('#active_step').data('script-ident'), function(response){
						if(response.step){
							window.lead_script.handleStep(response.step)
						}
						else{
							window.lead_script.handleStep(window.lead_script.magicPlease('customer_details'));
						}
					});
				}
				else{
					var error = 'failed to set product';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
				
			});

		// });
	}

	function getEVUsage(){
		return  $('.ev-usage-toggle.active').data('value');
	}

	$('#lead_script').on('click','.show-upgrade-text-button',function(){
		$('.upgrade-text-section').removeClass('hide');
		$('.show-upgrade-text-button').addClass('hide');
	});

	$('#lead_script').on('click','.tariff-details-select-button',function(){
		showLoader();
		var that = this;
		if($(this).hasClass('promo-available')){
			//need to make a modal to apply a promo code maybe?
			handlePromo(that);
			hideLoader();
			return false;
		}
		var tariff_id = $(this).data('tariffid');
		var brand_id = $(this).data('brandid');
		var json = $(this).data('json');
		json.details = $(this).parent().find('.tariff-details-button, .save-loss-tariff-details-button').data('details');
		api.set_tariff_selection(quote_id,tariff_id,brand_id,json,function(response){
			if(isset(response.success) && response.success){
				window.lead_script.checkStep($('#active_step').data('script-ident'), function(response){
					if(response.step){
						window.lead_script.handleStep(response.step)
					}
					else{
						window.lead_script.handleStep(window.lead_script.magicPlease('customer_details'));
					}
				});
			}
			else{
				var error = 'failed to set product';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});
	});
	
	$('.script').on('click','.email-quote-button',function(){

		var data = {};
		data.quote_id = quote_id;
		data.product_id = $(this).data('product-id');
		data.type = $(this).data('type');

		customConfirm('Are you sure you want to email this quote to the customer?', function(){
			showLoader();
			makeRequest('/api/email-quote',data,function(response){
				if(response.success){
					success_message('email sent');
				}
				else{
					var error = 'failed to send email';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
				hideLoader();
			});
		});
	});

	$('.script').on('click','.email-results-button',function(){

		var data = {};
		data.quote_id = quote_id;

		//so for type here we need to look at which tab is currently active to reflex what the agent is seeing 
		var type = $('#tariff-tabs li.active').data('type');
		data.type = type;

		customConfirm('Are you sure you want to email these results to the customer?', function(){
			showLoader();
			makeRequest('/api/email-results',data,function(response){
				if(response.success){
					success_message('email sent');
				}
				else{
					var error = 'failed to send email';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
				hideLoader();
			});
		});
	});

	$('#lead_script').on('click', '.select-price-alert', function(){

		api.add_price_alert(quote_id, function(response){
			if(response.success){
				window.lead_script.checkStep($('#active_step').data('script-ident'), function(response){
					if(response.step){
						window.lead_script.handleStep(response.step);
					}
					else{
						window.lead_script.handleStep('price-alert-energy.welcome_step');
					}
				});
			}
		});

	});
	
	$('#lead_script').on('click', 'div.tarrif-header', function(){
		
		$(this).parent().find('.select_tariff_image, .select_tariff_image_unlimited, .select_tariff_image_price_alert').trigger('click');
		
		
	});
	
	
	$('#lead_script').on('click', '.select_tariff_image', function(){
		if($('#tariff_pitch_text').length == 0){
			return false;
		}
		var that = this;
		var data = {
			'quote_id' : quote_id,
			'detail_link' : $(this).data('details'),
			'percentage' : $(this).data('percentage'),
			'monthly_spend' : $(this).data('monthly-spend'),
			'product_id' : $(this).data('product-id'),
			'monthly_saving' : $(this).data('monthly-saving'),
			'included_products': $(this).data('included_products')
		};
		showLoader();
		api.getTariffPitchText(data, function(response){
			//need to replace the text at the top of the pitch page with the response
			if(response.html){
				$('#tariff_pitch_text').html(response.html);
				
				//change the styled selected tariff
				
				$('.selected-tariff-notice').hide();
				$('div.tariff-box.selected').removeClass('selected');
				$('.energy-tariff-included-products-section').removeClass('selected');
				$('div.tarrif-header.selected').removeClass('selected');
				
				
				var container = $(that).closest('div.tariff-box');
				
				$(container).addClass('selected');
				$(container).find('div.tarrif-header').addClass('selected');
				$(container).closest('.tariff-box').find('.energy-tariff-included-products-section').addClass('selected');
				$(container).find('div.selected-tariff-notice').show();
				hideLoader();
				success_message();
			}			
		})
		
	});
	
	
	$('#lead_script').on('click', '.select_tariff_image_unlimited', function(){
		var that = this;
		var data = {
			'quote_id' : quote_id,
			'term' : $(this).data('term'),
			'tariff_name' : $(this).data('tariff-name'),
			'supplier_name' : $(this).data('supplier-name'),
			'percentage' : $(this).data('percentage'),
			'monthly_spend' : $(this).data('monthly-spend'),
			'supply_type' : $(this).data('supply-type'),
			'tariff_id' : $(this).data('tariff-id'),
			'monthly_saving' : $(this).data('monthly-saving'),
		};
		showLoader();
		api.getUnlimitedTariffPitchText(data, function(response){
			//need to replace the text at the top of the pitch page with the response
			if(response.html){
				$('#tariff_pitch_text').html(response.html);
				
				//change the styled selected tariff
				
				$('.selected-tariff-notice').hide();
				$('div.tariff-box.selected').removeClass('selected');
				$('div.tarrif-header.selected').removeClass('selected');
				
				
				var container = $(that).closest('div.tariff-box');
				
				$(container).addClass('selected');
				$(container).find('div.tarrif-header').addClass('selected');
				$(container).find('div.selected-tariff-notice').show();
				hideLoader();
				success_message();
			}			
		})
		
	});


	$('#lead_script').on('click', '.select_tariff_image_price_alert', function(){
		var that = this;
		var data = {
			'quote_id' : quote_id,
		};
		showLoader();
		api.getPriceAlertPitchText(data, function(response){
			//need to replace the text at the top of the pitch page with the response
			if(response.html){
				$('#tariff_pitch_text').html(response.html);
								
				$('.selected-tariff-notice').hide();
				$('div.tariff-box.selected').removeClass('selected');
				$('div.tarrif-header.selected').removeClass('selected');
				
				var container = $(that).closest('div.tariff-box');
				
				$(container).addClass('selected');
				$(container).find('div.tarrif-header').addClass('selected');
				$(container).find('div.selected-tariff-notice').show();
				hideLoader();
				success_message();
			}			
		})
		
	});
	
	$('#lead_script').on('click','#tariff-tabs li',function(){
		$(this).siblings().removeClass('active');
		$(this).addClass('active');
		var clicked_tab = $(this).attr('id').replace("tariff-tabs-","");
		var target_tariffs = $('#tariff-container-'+clicked_tab);
		target_tariffs.siblings().hide();
		target_tariffs.show();
	});
	
	$('#lead_script').on('click','.show-more-tariffs',function(e) {
                var tariffButton = $('.show-more-tariffs');
                e.preventDefault();
                var contact_id = $('#contact-details').data('contact-id');
                var first_name = $('#contact-details-edit input[name="first_name"]').val();
                var last_name = $('#contact-details-edit input[name="last_name"]').val();
                var message = 'Unlock additional tariffs for '+first_name+' '+last_name+'?  (Contact #'+contact_id+')';
                var message = new MessageBox('unlockTariffs', message);
                message.setData({
                ajax: { 
                    cache: false,
                    url: '/quote/unlockTariffs',
                    type: 'POST',
                    success: function(response) {
                        if (response.status===200) {
				var skipped = false;
				$.each($('.bad-tariff-container'), function(i, container){
					if($(container).find('.tariff-box ').length == 0){
						$('.very-bad-tariff-container').show();
						skipped = true;
					}
				});
				if(!skipped){
					$('.bad-tariff-container').show();
				}
                            	$('.show-more-tariffs').hide();
                        }
                    },
                    error: function (error) {
                        //console.log(error);
                    }
                 
                }
                });
                message.run();
                
                
	});
	
	$('#lead_script').on('click','.show-womp-tariffs',function(e) {
                var tariffButton = $('.show-more-tariffs');
                e.preventDefault();
                var contact_id = $('#contact-details').data('contact-id');
                var first_name = $('#contact-details-edit input[name="first_name"]').val();
                var last_name = $('#contact-details-edit input[name="last_name"]').val();
                var message = 'Unable to help? Offer price alert tariffs for '+first_name+' '+last_name+'?  (Contact #'+contact_id+')';
                var message = new MessageBox('unlockTariffs', message);
                message.setData({
                ajax: { 
                    cache: false,
                    url: '/quote/unlockTariffs',
                    type: 'POST',
                    success: function(response) {
                        if (response.status===200) {
                            $('.womp-tariff-container').show();
                            $('.show-womp-tariffs').hide();
							$('.womp-tariff-container').siblings().hide();
							var active_type = $('#tariff-tabs').find('.active').attr('id').replace('tariff-tabs-','');
							$('#tariff-container-'+active_type).find('.womp-tariff-container').find('.tarrif-header').first().trigger('click');
                        }
                    },
                    error: function (error) {
                        //console.log(error);
                    }
                 
                }
                });
                message.run();
                
                
	});
	
	
	$('#lead_script').on('click','.show-bad-tariffs',function(e) {
		var tariffButton = $('.show-bad-tariffs');
		e.preventDefault();
		var contact_id = $('#contact-details').data('contact-id');
		var first_name = $('#contact-details-edit input[name="first_name"]').val();
		var last_name = $('#contact-details-edit input[name="last_name"]').val();
		var message = 'Unlock bad tariffs for '+first_name+' '+last_name+'?  (Contact #'+contact_id+')';
		var message = new MessageBox('unlockTariffs', message);
		message.setData({
		ajax: { 
			cache: false,
			url: '/quote/unlockBadTariffs',
			type: 'POST',
			success: function(response) {
				if (response.status===200) {
					$('.very-bad-tariff-container').toggle();
					$('.show-bad-tariffs').hide();
				}
			},
			error: function (error) {
				//console.log(error);
			}

		}
		});
		message.run();
	});

	$('#lead_script').on('click','#show_standard_text',function(e) {
		$(this).hide();
		$('#show_smart_meter_text').show();
		$('#show_ev_tariffs_data').show();

		$('#normal_script_text').show();
		$('#electric_vehicle_text').hide();
		$('#smart_meter_text').hide();

		$('#tariffListContainer').find('.tariff-box').show();
		$('#tariffListContainer').find('.tariff-box.smart-prepay-required-tariff').hide();
		$('.ev-tariffs-container').hide();
		$('#tariffListContainer').find('show_smart_meter_text').hide();
	});

	$('#lead_script').on('click','#show_smart_meter_text',function(e) {
		$(this).hide();
		$('#show_standard_text').show();
		$('#show_ev_tariffs_data').show();

		$('#normal_script_text').hide();
		$('#electric_vehicle_text').hide();
		$('#smart_meter_text').show();

		$('#tariffListContainer').find('.tariff-box').hide();
		$('#tariffListContainer').find('show_smart_meter_text').show();
		$('#tariffListContainer').find('.smart-prepay-tariff').show();
		$('.ev-tariffs-container').hide();

	});


	$('#lead_script').on('click', '.ev-usage-toggle', function(){
		$('.ev-usage-toggle[data-value="' + $(this).data('value') + '"]' ).addClass('active');
		$('.ev-usage-toggle[data-value="' + $(this).data('value') + '"]' ).siblings().removeClass('active');
		var message_selector = $(this).data('message-to-show');
		$(message_selector).show()
		$(message_selector).siblings().hide();
		//need to update pricing
		var data = {
			'group' : 'ev_tariffs',
			'quote_id' : quote_id,
			'profile_to_set' : {
				'electricity:ev_additional_annual_usage' : getEVUsage()
			}
		}
		showLoader();
		makeRequest('/refresh/product_group_pricing', data, function(response){
			if(isset(response.html_results)){
				$.each(response.html_results, function(fuel, html){
					$('#tariff-container-' + fuel).find('.ev-tariffs-container .tariff-list').replaceWith(html);
				});
				success_message();
			}
			else{
				error_message('upable to update pricing');
			}
			hideLoader();
		})
	})
	
});
