$(document).ready(function() {
	if($('#leads-table').length){

		const datatable = $('#leads-table').DataTable({
			ajax: "/get/leads",
			dom: '<"top"iflp<"clear">>rt<"bottom"iflp<"clear">>',
			pageLength: 40,
			ordering: true,
			order: [[ 0, "desc" ]],
			responsive: true,
			bAutoWidth:false,
			autoWidth: false,
			lengthMenu: [25, 40, 100, 500],
			columnDefs: [
				{"type": "date", "targets": 5}
			],
			initComplete: function () {
					this.api().columns([4, 7]).every( function () {
					var column = this;
					var title = $(column.header()).html();
					var select = $('<br><select style="text-align: center; margin-right:20px; border-radius:4px; color:black; width:100%;"><option></option></select>')
						.on( 'change', function () {
							var val = $.fn.dataTable.util.escapeRegex(
								$(this).val()
							);
	
							column
								.search( val ? '^'+val+'$' : '', true, false )
								.draw();
						} );
					column.data().unique().sort().each( function ( d, j ) {
					if(column.search() === '^'+d+'$'){
						select.append( '<option value="'+d+'" selected="selected">'+d+'</option>' )
					} 
					else {
						select.append( '<option value="'+d+'">'+d+'</option>' )
					}
					} );
					$(column.header()).html('<span>'+title+'</span>');
					$(column.header()).append(select);
				});
			}
		});
		$('#leads-table').on('click', '.delete-lead', function(e) {
			e.preventDefault();
			const id = $(this).data('id');
			const message = new MessageBox('deletelead', 'Delete lead id ' + id);
			message.setData({
				ajax: { url: '/leads/' + id },
				success: function(data) {
					datatable.ajax.reload();
				}
			});
			message.run();
		});
		if($('input[name="leads-date-filter"]').length > 0){
			$('input[name="leads-date-filter"]').daterangepicker({
	
			"locale": {
				"format": 'DD/MM/YYYY',
				"separator": "-"
			},
			"linkedCalendars": false,
			"showDropdowns": true
			},
			function(start, end, label) {
	
				//console.log("Ok, changing date range...");
	
				const datatable = $('#leads-table').DataTable({
					destroy: true,
					// dom: 'flrtiBp',
					ajax: "/get/leads?start="+start+"&end="+end,
					pageLength: 40,
					dom: '<"top"iflp<"clear">>rt<"bottom"iflp<"clear">>',
					ordering: true,
					responsive: true,
					bAutoWidth:false,
					autoWidth: false,
					lengthMenu: [25, 40, 100],
					select: true,
					buttons: [
						'copyHtml5',
						'print',
						{
							extend: 'collection',
							text: 'Export',
							buttons: [
								'csvHtml5',
								'pdfHtml5'   
							]
						}
					],
	
					initComplete: function () {
						this.api().columns([4, 8]).every( function () {
						var column = this;
						var title = $(column.header()).find('span').text();
						var select = $('<select style="float:right; margin-right:20px; border-radius:4px; color:black;"><option></option></select>')
							.on( 'change', function () {
								var val = $.fn.dataTable.util.escapeRegex(
									$(this).val()
								);
	
								column
									.search( val ? '^'+val+'$' : '', true, false )
									.draw();
							} );
						column.data().unique().sort().each( function ( d, j ) {
						if(column.search() === '^'+d+'$'){
							select.append( '<option value="'+d+'" selected="selected">'+d+'</option>' )
						} 
						else {
							select.append( '<option value="'+d+'">'+d+'</option>' )
						}
						} );
						$(column.header()).html('<span>'+title+'</span>');
						$(column.header()).append(select);
					});
					} 
				});
	
			});
		}
	}
	if($('#broadband-leads-table').length){

		const broadband_datatable = $('#broadband-leads-table').DataTable({
			ajax: "/get/leads",
			pageLength: 40,
			ordering: true,
			order: [[ 0, "desc" ]],
			responsive: true,
			bAutoWidth:false,
			autoWidth: false,
			lengthMenu: [25, 40, 100, 500],
			columnDefs: [
				{"type": "date", "targets": 5}
			],
		initComplete: function () {
					this.api().columns([4,5,6]).every( function () {
					var column = this;
					var title = $(column.header()).html();
					var select = $('<br><select style="text-align: center; margin-right:20px; border-radius:4px; color:black; width:100%;"><option></option></select>')
						.on( 'change', function () {
							var val = $.fn.dataTable.util.escapeRegex(
								$(this).val()
							);
	
							column
								.search( val ? '^'+val+'$' : '', true, false )
								.draw();
						} );
					column.data().unique().sort().each( function ( d, j ) {
					if(column.search() === '^'+d+'$'){
						select.append( '<option value="'+d+'" selected="selected">'+d+'</option>' )
					} 
					else {
						select.append( '<option value="'+d+'">'+d+'</option>' )
					}
					} );
					$(column.header()).html('<span>'+title+'</span>');
					$(column.header()).append(select);
				});
			}
		});
		$('#broadband-leads-table').on('click', '.delete-lead', function(e) {
			e.preventDefault();
			const id = $(this).data('id');
			const message = new MessageBox('deletelead', 'Delete lead id ' + id);
			message.setData({
				ajax: { url: '/leads/' + id },
				success: function(data) {
					broadband_datatable.ajax.reload();
				}
			});
			message.run();
		});
		if($('input[name="broadband-leads-date-filter"]').length > 0){
			$('input[name="broadband-leads-date-filter"]').daterangepicker({
	
			"locale": {
				"format": 'DD/MM/YYYY',
				"separator": "-"
			},
			"linkedCalendars": false,
			"showDropdowns": true
			},
			function(start, end, label) {
	
				//console.log("Ok, changing date range...");
	
				const broadband_datatable = $('#broadband-leads-table').DataTable({
					destroy: true,
					dom: 'flrtiBp',
					ajax: "/get/leads?start="+start+"&end="+end,
					pageLength: 40,
					ordering: true,
					responsive: true,
					bAutoWidth:false,
					autoWidth: false,
					lengthMenu: [25, 40, 100],
					select: true,
					buttons: [
						'copyHtml5',
						'print',
						{
							extend: 'collection',
							text: 'Export',
							buttons: [
								'csvHtml5',
								'pdfHtml5'   
							]
						}
					],
	
					initComplete: function () {
						this.api().columns([4]).every( function () {
						var column = this;
						var title = $(column.header()).find('span').text();
						var select = $('<select style="float:right; margin-right:20px; border-radius:4px; color:black;"><option></option></select>')
							.on( 'change', function () {
								var val = $.fn.dataTable.util.escapeRegex(
									$(this).val()
								);
	
								column
									.search( val ? '^'+val+'$' : '', true, false )
									.draw();
							} );
						column.data().unique().sort().each( function ( d, j ) {
						if(column.search() === '^'+d+'$'){
							select.append( '<option value="'+d+'" selected="selected">'+d+'</option>' )
						} 
						else {
							select.append( '<option value="'+d+'">'+d+'</option>' )
						}
						} );
						$(column.header()).html('<span>'+title+'</span>');
						$(column.header()).append(select);
					});
					} 
				});
	
			});
		}
	}

	
    
    if($("div.script").length) {
		window.scripts = [];
		$.each($("div.script"), function(i, script){
			if($(script).data('quoteid')) {
				window[$(script).data('script-name') + '_script'] = new ScriptFlow($(script));
				window.scripts.push(window[$(script).data('script-name') + '_script']);
			}
			// window.lead_script = new ScriptFlow($(script));
		})
    }
    
    
	
	

	
	
});
