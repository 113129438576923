$(document).ready(function(){
	
	$('#customer-details-save').on('click', function(e){
		e.preventDefault();
		var contact_id = $('#contact-details').data('contact-id');
		var form = $(this).closest('form');
		var formdata = form.serialize();
		var customer_validator = new customerValidator();
		var validation = [
			function(next) { customer_validator.validateTitle($('#contact-details-edit #customer-details-title').val(),next); },	
			function(next) { customer_validator.validateFirstName($('#contact-details-edit #customer-details-first_name').val(),next); },
			function(next) { customer_validator.validateLastName($('#contact-details-edit #customer-details-last_name').val(),next); },
			function(next) { customer_validator.validatePhone($('#contact-details-edit #customer-details-telephone').val(),next); },
			function(next) { customer_validator.validateEmail($('#contact-details-edit #customer-details-email').val(),next); },
		];
		customer_validator.validateAll(validation,function(errors,data){
			//console.log(results);
			if(errors !== null){
				$.each(errors,function(index,error){
					$('#contact-details-edit #'+error.target).html(error.message);
					//console.log(error.message);
				});
				$('#contact-details-edit span.error-message').show();
				setTimeout(function(){ 
					$('#contact-details-edit span.error-message').hide();
				}, 5000);
			}
			else{
				$.ajax({ cache: false,
					url: "/contact/update/"+contact_id,
					type:"POST",
					dataType: 'json',
					data: formdata,
					success: function(response){
						$('.details-saved').fadeIn();
						setTimeout(function(){ $('.details-saved').fadeOut(); }, 3000);
						return true;
						//console.log(response);

					},
					error: function (error) {
						//console.log(error);
					}
				});
				
			}
		});
		//console.log(formdata);
		
	});
	
});
