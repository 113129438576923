

//parent to everything -- if you are inclined to copy pastey this file changing this variable might be helpful to you
var scope = 'affiliate_portal_admin_container';

var column_1 = 'affiliates';
var column_2 = 'affiliate_users';
var column_3 = 'affiliate_user';

var edit_panel = 'edit_panel';

var base_url = 'affiliate-portal';


var validation_errors = {};

var validation_rules = {
	'username' : function(value){
		if(value.length > 5){
			if(value.indexOf('@') > -1){
				if(value.match(/(.+)@(.+){2,}\.(.+){2,}/) !== null){
					return true;
				}
				else{
					validation_errors['username'] = 'Please enter a valid email address';
				}
			}
			else{
				validation_errors['username'] = 'Your email must contain an @';
			}
		}
		else{
			validation_errors['username'] = 'Please enter an email address of at least 5 characters';
		}
		return false;
	},
	'password' : function(value){
		if(value.length == 0){
			return true;
		}
		if(value.length > 7){
			if(value.match(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z])(?=.*[!@#\$%\^&|]).{8,}$/) !== null){
				return true;
			}
			else{	
				validation_errors['password'] = 'Passwords must contain, an upper case, a lower case, a number and a special character';
			}
		}
		else{
			validation_errors['password'] = 'Passwords must be at least 8 characters long';
		}
		return false;
	},
	'confirm_password' : function(value){
		if(value == $('input[name="password"]').val()){
			return true;
		}
		validation_errors['confirm_password'] = 'Passwords do not match';
		return false;
	},
	'firstname' : function(value){
		if(value.trim().length >= 2){
			return true;
		}
		validation_errors['firstname'] = 'Please enter a firstname longer than 2 characters';
		return false;
	},
	'lastname' : function(value){
		if(value.trim().length >= 2){
			return true;
		}
		validation_errors['lastname'] = 'Please enter a lastname longer than 2 characters';
		return false;
	},
	'role_id' : function(value){
		if(value > 0 && value < 100){
			return true;
		}
		validation_errors['role_id'] = 'Please select a role';
		return false;
	},
	'telephone' : function(value){
		if(value.length == 0){
			return true;
		}
		if(value.length > 9 && value.length < 13){
			if(value.match(/^(((44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/) !== null){
				return true;
			}
		}
		validation_errors['telephone'] = 'Please enter a valid telephone number';
		return false;
	},
	'mobile' : function(value){
		if(value.length == 0){
			return true;
		}
		if(value.length > 9 && value.length < 13){
			if(value.match(/^07\d{9,10}$/) !== null){
				return true;
			}
		}
		validation_errors['mobile'] = 'Please enter a valid mobile number';
		return false;
	}
};

var validateUserData = function(data){
	$.each(data, function(name, value){

	});
}

$(document).ready(function() {
	
	$('#' + scope).on('click', '#' + column_1 + ' .edit-trigger', function(){

		var id = $(this).data('id');

		var data = {
			'id' : id
		};

		makeRequest('/'+base_url + '/' + column_1 + '/edit', data, function(response){
			if(response.success && response.html){
				$('#' + column_2).html(response.html);
				$('#' + edit_panel).show();
			}
		});
	});
	
	$('#' + scope).on('click', '#' + column_2 + ' .edit-trigger', function() {
		var id = $(this).data('id');

		var data = {
			'id' : id
		};

		makeRequest('/'+base_url + '/' + column_2 + '/edit', data, function(response){
			if(response.success && response.html){
				$('#' + column_3).html(response.html);
				$('.select2').select2({
					'width': '100%'
				});
			}
		});
	});
	
	
	$('#' + scope).on('click', '#' + column_3 + ' .toggle-user-lock-state', function() {


		var user_id = $(this).closest('#affiliate_user').data('id');

		var data = {
			'id' : user_id
		};
		var that = this;
		makeRequest('/'+base_url +'/' +column_3 +'/toggle-lock', data, function(response){
			if(isset(response.success) && response.success){
				$(that).toggleClass('fa-lock');
				$(that).toggleClass('fa-unlock');
			}
			else{
				error_message('unable to change user lock state');
			}
		});
		
	});

	$('body').on('submit', '#edit_user_form', function(e){
		e.preventDefault();
	});

	
	
	$('#' + scope).on('click', '#' + column_3 + ' #edit_user', function() {

		validation_errors = {};
		$('#edit_user_form span.error').remove();
		$('#edit_user_form .form-group').removeClass('has-error');
		var user_id = $(this).data('user-id');


		var form_data = $('#edit_user_form').serializeArray();
		var indexed = {};
		$.each(form_data, function(i, data){
			if(isset(validation_rules[data.name])){
				if(validation_rules[data.name](data.value)){
					indexed[data.name] = data.value.trim();
				}
			}
			else{
				indexed[data.name] = data.value.trim();
			}
		})

		if(Object.keys(validation_errors).length){
			$.each(validation_errors, function(name, error){
				var group = $('#edit_user_form :input[name="' + name + '"]').closest('.form-group');
				$(group).addClass('has-error');
				$(group).append('<span class="error">' + error + '</span>');
			})
			return false;
		}

		var data = {
			'id' : user_id,
			'data' :indexed
		};


		showLoader();

		makeRequest('/' +base_url + '/'+column_3+'/save', data, function(response){
			hideLoader();
			if(isset(response.success) && response.success){
				success_message('updated');
			}
			else{
				var error = 'unable to update user';
				if(isset(response.errors)){
					$.each(response.errors, function(name, error){
						var group = $('#edit_user_form :input[name="' + name + '"]').closest('.form-group');
						$(group).addClass('has-error');
						$(group).append('<span class="error">' + error + '</span>');
					});
				}
				if(response.error){
					error = response.error;
				}
				error_message(error);
			}
		})

		
	});


	$('#' + scope).on('click', '#' + column_2 + ' #create_user', function() {
		var id = $(this).data('id');

		var data = {
			'id' : id,
			'name' : $('#new_user_name').val()
		};

		makeRequest('/'+base_url + '/' + column_3 + '/create', data, function(response){
			if(response.success && response.html){
				$('#' + column_3).html(response.html);
				$('.select2').select2({
					'width': '100%'
				});
			}
		});
	});

	$('#' + scope).on('click', '#' + column_3 + ' #create_user', function() {

		validation_errors = {};
		$('#edit_user_form span.error').remove();
		$('#edit_user_form .form-group').removeClass('has-error');
		var affiliate_id = $(this).data('affiliate-id');
		var form_data = $('#edit_user_form').serializeArray();
		var indexed = {};
		$.each(form_data, function(i, data){
			if(isset(validation_rules[data.name])){
				if(validation_rules[data.name](data.value)){
					indexed[data.name] = data.value.trim();
				}
			}
			else{
				indexed[data.name] = data.value.trim();
			}
		})

		if(Object.keys(validation_errors).length){
			$.each(validation_errors, function(name, error){
				var group = $('#edit_user_form :input[name="' + name + '"]').closest('.form-group');
				$(group).addClass('has-error');
				$(group).append('<span class="error">' + error + '</span>');
			})
			return false;
		}

		var data = {
			'id' : affiliate_id,
			'data' :indexed
		};


		showLoader();
		makeRequest('/' +base_url + '/'+column_3+'/new', data, function(response){
			hideLoader();
			if(isset(response.success) && response.success){
				success_message('updated');
			}
			else{
				var error = 'unable to create user';
				if(isset(response.errors)){
					$.each(response.errors, function(name, error){
						var group = $('#edit_user_form :input[name="' + name + '"]').closest('.form-group');
						$(group).addClass('has-error');
						$(group).append('<span class="error">' + error + '</span>');
					});
				}
				if(response.error){
					error = response.error;
				}
				error_message(error);
			}
		})

		
	});

	$('#' + scope).on('click', '#' + column_3 + ' #delete', function() {
		if(confirm('are you sure you want to delete this user?')){
			var id = $(this).data('user-id');

			var data = {
				'id' : id,
			};

			makeRequest('/'+base_url + '/' + column_3 + '/delete', data, function(response){
				if(response.success){
					$('#' + column_3).html('');
					$('#'+column_2+' li.ui-sortable-handle[data-user-id="' + data.id + '"]').remove()
					success_message;
				}
				else{
					var error = 'unable to delete user';
					if(response.error){
						error = response.error;
					}
					error_message(error);
				}
			});
		}
	});


});