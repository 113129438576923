const ScriptFlow = require("../components/script_system");


$(function () {

	$('#lead_script').on('change','#insurance_product_selection_form select[name="cover_type"]',function(){
		var cover_type = $(this).val();
		if (cover_type.toLowerCase().includes("contents") === true && cover_type.toLowerCase().includes("building") === false){
			$('.contents-cover-questions').removeClass('hide');
			$('.buildings-cover-questions').addClass('hide');
		}
		else if(cover_type.toLowerCase().includes("contents") === false && cover_type.toLowerCase().includes("building") === true){
			$('.contents-cover-questions').addClass('hide');
			$('.buildings-cover-questions').removeClass('hide');
		} 
		else if (cover_type.toLowerCase().includes("contents") === true && cover_type.toLowerCase().includes("building") === true){
			$('.contents-cover-questions').removeClass('hide');
			$('.buildings-cover-questions').removeClass('hide');
		}
		else {
			$('.contents-cover-questions').addClass('hide');
			$('.buildings-cover-questions').addClass('hide');
		}
	});

	$('#lead_script').on('change','#insurance_product_selection_form select[name="billing_preference"]',function(){
		var billing_preference = $(this).val();
		if(billing_preference == 'Monthly Direct Debit'){
			$('.monthly-premium-wrapper').removeClass('hide');
		}
		else {
			$('.monthly-premium-wrapper').addClass('hide');
		}
	});

	
	
	$('#lead_script').on('click','#insurance_product_select_submit',function(){
		var form_data = $('#insurance_product_selection_form').serializeArray();
		var quote_id = $('#lead_script').data('quoteid');
		var next_step = $(this).data('success_step');
		console.log(next_step);
		console.log(form_data);
		makeRequest('/insurance/handle-product-selection/'+quote_id,form_data,function(response){
			if(isset(response.success) && response.success){
				lead_script.handleStep(next_step);
			}
			else{
				if(isset(response.error) && response.error.length){
					error_message(response.error);
				}
				if(isset(response.errors) && response.errors.length){
					console.log(response.errors);
					$.each(response.errors,function(index,error){
						console.log(error);
						$('#'+error.target).html(error.message);
						$('#'+error.target).removeClass('hide');
						$('#'+error.target).addClass('shake');
					});
					setTimeout(function(){
						$('.error').removeClass('shake');
						$('.error').addClass('hide');
					},5000);
				}
			}
		},'POST');

	});
	
});
