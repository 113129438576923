var loadScripts = function(){
	$( "#prizelist_active, #prizelist_all" ).sortable({
		connectWith: ".triggerSortable",
		update: function (event, ui) {
			if($(event.target).attr('id') === 'prizelist_active'){
				// we're adding a new prize, go get the associated mark up to render
				var incentive_id = $('#incentive-container').data('incentive_id');
				var prize_id = $(ui.item.context).attr('id').replace('prize-','');
				configure_prize(incentive_id,prize_id,function(){
					// lets do something here
				});
				
			}
		}
	}).disableSelection();
};

var add_date_picker = function(target){
		
		$(target).daterangepicker({
			locale: {
			format: 'DD/MM/YYYY h:mm A' },
			"singleDatePicker": true,
			"timePicker24Hour": true,
			"timePicker": true,
			"timePickerIncrement": 10,
			"autoApply": true,
			"showCustomRangeLabel": false	
	//		"startDate": "03/08/2017",
	//		"endDate": "02/23/2017"
		},function(start,end,label){
			$(target).val(start.format('D, MMMM, YYYY'));
		});
	};

var configure_prize = function(incentive_id,prize_id,callback){
	$.ajax({
		data: {incentive_id:incentive_id,prize_id:prize_id},
		type: 'POST',
		url: '/incentive/configure_prize',
		dataType: 'json',
		success: function(response) {
			if(response.success && response.html){
				// update the pageincentive_id
				$('#prize-edit-container').html(response.html).show();
				add_date_picker('#custom-from-date');
				add_date_picker('#custom-to-date');
				callback();
			}
			else{
				if(response.error){
					error_message(response.error);
				}
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

$( document ).ready(function() {
	
	if($('#custom-from-date').length){
		add_date_picker('#custom-from-date');
		add_date_picker('#custom-to-date');
	}
	$('#incentives-active').on('click', '#add-incentive', function() {
		var parent = $(this).parent(); 
//		parent.find('span.errors').html('');
		parent.parent().find('.module-error').hide().text('');
		var incentive_name = parent.find('input[name="incentive-name"]').val();
		if(incentive_name.length <= 5) {
			parent.parent().find('.module-error').fadeIn('fast').text('You must enter a name');
		} 
		else {
			$.ajax({ cache: false,
				url: "/incentive/create",
				type:"POST",
				dataType: 'json',
				data: { "incentive_name" : incentive_name },
				success: function (response) {
 					if(response.success === true) {
						//redirects to the create new action group page
						window.location.href = "/incentive/"+response.incentive.id;
					} 
					else {
						if(response.error) {
							parent.parent().find('.module-error').fadeIn('fast').text(response.error);
						}
					}
				},
				error: function (error) {
					//console.log(error);
				}
			});
		};
	});
	
	$('#incentives-active').on('change', '.active-incentive-switch', function() {
		var parent = $(this).parent().parent().parent().data("incentive_id");
		var active = $(this).is(":checked");
		$.ajax({ 
			cache: false,
			url: "/incentive/toggle-active/"+parent,
			type:"POST",
			dataType: 'json',
			data: { "active-state" : active },
			success: function (response) {
				if(response.success === true) {
					if(active) {
						success_message('Activated');
					} 
					else {
						error_message('Deactivated');
					}
				} 
				else {
					if(response.error) {
						error_message(response.error);
					}
				}
			},
			error: function (error) {
				console.log(error);
			}
		});
				
	});
	
	$('#incentives-active').on('keyup','.search-incentives',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-incentives ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});
	$( "#prizelist_active, #prizelist_all" ).sortable({
		connectWith: ".triggerSortable",
		zIndex: 1004,
		update: function (event, ui) {
			if($(event.target).attr('id') === 'prizelist_active'){
				// we're adding a new prize, go get the associated mark up to render
				var prize_id = $(ui.item.context).attr('id').replace('prize-','');
				var incentive_id = $('#incentive-container').data('incentive_id');
				configure_prize(incentive_id,prize_id,function(){
					// lets do something here
				});
				
			}
		}
	}).disableSelection();
	
	$('#prize-incentive-edit').on('click','#add_prize',function(){
		var table = $('#attribute-table');
		var incentive_id = table.data('incentive_id');
		var prize_id = table.data('prize_id');
		var form = handle_special_fields(table);
		$.ajax({
			data: {
				form:form,
				incentive_id:incentive_id,
				prize_id:prize_id
			},
			type: 'POST',
			url: '/incentives/update-incentive-prize',
			dataType: 'json',
			success: function(response) {
				$('#prize-edit-container').html('').hide();
				//$('#rule-value-edit').html(response.html);
				success_message('updated');
			},
			error: function (error) {
				error_message('something went wrong');
			}
		});
		// post everything to server and save in the link table
		// if all good hide the edit box.
		// if not show feeback
		
	});
	
	$('#saved-prizes-container').on('click','#add-incentive-prize',function(){
		var name = $(this).parent().find('input').val();
		if(name.length < 3){
			errror_message('a new rule needs a longer name');
		}
		else{
			// lets post what we have and setup a new rule
			$.ajax({
			data: {name:name},
			type: 'POST',
			url: '/incentives/create-prize',
			dataType: 'json',
			success: function(response) {
				if(response.success && response.prize_id){
					// redirect to the edit rule page
					window.location.href = '/incentives/edit-prize/'+response.prize_id;
				}
				else{
					error_message('something went wrong unable to create');
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		}
	});
	
	$('#page-content-wrapper').on('click', '#save_and_preview_prize', function() {
		var parent = $(this).parent().parent();
		var target_prize = $('#rule-edit-wrapper').data('prize_id');
		var data = {};
		data.prize_name = parent.find("input[name*='action-group-name']").val();
//		data.rule_type = parent.find("option:selected").val();
		data.attr = [];
		data.edit = [];
		$("input[name='has_attribute']:checked").each( function () {
			data.attr.push($(this).val());
		});
		$("input[name='is_editable']:checked").each( function () {
			data.edit.push($(this).val());
		});
		$.ajax({
			data: data,
			type: 'POST',
			url: '/incentives/update-prize/'+target_prize,
			dataType: 'json',
			success: function(response) {
				if(response.success){
					$('#prize-value-edit').html(response.html);
					add_date_picker('#custom-from-date');
					add_date_picker('#custom-to-date');
					success_message('updated');
				}
				else{
					error_message(response.error);
				}
			},
			error: function (error) {
				//console.log(error);
				error_message('something went wrong');
			}
		});
		
	});
	
	$('#prize-value-edit').on('click', '#save_prize_defaults', function() {
		var target_prize = $('#rule-edit-wrapper').data('prize_id');
		var form_container = $('#attribute-table');
		var form = handle_special_fields(form_container);
//		$('#rule-value-edit').find('#attribute-table :input').each(function(){
//			form.append($(this).clone());
//		});
		$.ajax({
			data: form,
			type: 'POST',
			url: '/incentives/update-prize-defaults/'+target_prize,
			dataType: 'json',
			success: function(response) {
				$('#rule-value-edit').html(response.html);
				success_message('updated');
			},
			error: function (error) {
				error_message('something went wrong');
			}
		});
	});
	
	$('#prizelist_active').on('click','.delete-trigger',function(){
		var container = $(this).closest('li');
		var prize_id = container.attr('id').replace('prize-','');
		var incentive_id = $('#incentive-container').data('incentive_id');
		$.ajax({
			data: {
				incentive_id:incentive_id,
				prize_id:prize_id
			},
			type: 'POST',
			url: '/incentives/remove_prize',
			dataType: 'json',
			success: function(response) {
				$(container).remove();
				//$('#rule-value-edit').html(response.html);
				success_message('prize removed');
			},
			error: function (error) {
				error_message('something went wrong');
			}
		});
	});
	
	$('#prizelist_active').on('click','.edit-trigger',function(){
		var container = $(this).closest('li');
		var prize_id = container.attr('id').replace('prize-','');
		var incentive_id = $('#incentive-container').data('incentive_id');
		configure_prize(incentive_id,prize_id,function(){
			// lets do something here
		});
	});
	
	$('#saved-prizes-container').on('click','.edit-trigger',function(){
		var prize_id = $(this).closest('li').attr('id').replace('prize-','');
		window.location.href = '/incentives/edit-prize/'+prize_id;
	});
	
	$('#incentive-container').on('click','.toggle-trigger',function(){
		var target = $(this).data('target');
		$('.toggle-target').hide();
		$('#'+target).show();
	});
	
	$( "#teamlist_active, #teamlist_all" ).sortable({
		connectWith: ".actionSortable",
		zIndex: 1004,
		update: function (event, ui) {
			if($(event.target).attr('id') === 'teamlist_active'){
				var container = $('#teamlist_active');
				var incentive_teams = [];
				$.each(container.find('li'),function(index,el){
					incentive_teams.push($(el).attr('id').replace('team-',''));
				});
				// we're adding a new prize, go get the associated mark up to render
				var incentive_id = $('#incentive-container').data('incentive_id');
				$.ajax({
					data: {
						incentive_id:incentive_id,
						teams:incentive_teams
					},
					type: 'POST',
					url: '/incentives/link_teams',
					dataType: 'json',
					success: function(response) {
						//$('#teams-edit-container').html(response.html);
						success_message('teams updated');
					},
					error: function (error) {
						error_message('something went wrong');
					}
				});
				
			}
		}
	}).disableSelection();
	
	$( "#gameslist_active, #gameslist_all" ).sortable({
		connectWith: ".scheduleSortable",
		zIndex: 1004,
		update: function (event, ui) {
			if($(event.target).attr('id') === 'gameslist_active'){
				var container = $('#gameslist_active');
				var incentive_games = [];
				$.each(container.find('li'),function(index,el){
					incentive_games.push($(el).attr('id').replace('game-',''));
				});
				// we're adding a new prize, go get the associated mark up to render
				var incentive_id = $('#incentive-container').data('incentive_id');
				$.ajax({
					data: {
						incentive_id:incentive_id,
						games:incentive_games
					},
					type: 'POST',
					url: '/incentives/link_games',
					dataType: 'json',
					success: function(response) {
						//$('#teams-edit-container').html(response.html);
//						success_message('prize removed');
					},
					error: function (error) {
						error_message('something went wrong');
					}
				});
				
			}
		}
	}).disableSelection();
	
	$('#file_menu_games').on('click','.start-game',function(){
		showLoader();
		var game_id = $(this).data('game_id');
		$.ajax({
			data: {
				game_id:game_id
			},
			type: 'POST',
			url: '/game/build',
			dataType: 'json',
			success: function(response) {
				hideLoader();
				if(response.success){
					
					if(response.modal_html){
						createModal('game_modal', response.modal_html);
					}
					
					if(response.game_name){
						var method_name = response.game_name.toLowerCase() + 'Game';
						if(window[method_name] && typeof(window[method_name]) == 'function' && response.prizes){
							window[method_name](response.prizes);
						}
					}
					
				}
				else if(response.errors){
					error_message(response.errors[0]);
					hideModal('game_modal');
				}
		
			},
			error: function (error) {
				error_message('something went wrong');
				hideLoader();
			}
		});
	});
	
	$('#prize-value-edit, #prize-incentive-edit').on('click', '.time-selectors-1 .btn', function(e) {
		e.preventDefault();
		var button_clicked = $(this);
		var button_text = $(this).text();
		var button_clicked_container = button_clicked.parent().parent();
		var allow_change = true;
		
		button_clicked_container.siblings().each(function(){
//			if($(this).attr('id') !== button_clicked_container.attr('id')){
				var buttons = $(this).find('.btn');
				var inactive_buttons = $(this).find('.inactive , .disabled');
				if(inactive_buttons.length === buttons.length -1){
					buttons.each(function(){
						if($(this).text() === button_clicked.text() && !$(this).hasClass('inactive') && !$(this).hasClass('disabled')){
							allow_change = false;
						}
					});
				}
//			}
		});
		if(allow_change === true){
			$(button_clicked).parent().find('input[name=times-from-time]').val(button_text);
			$(button_clicked).parent().find('input[name=times-to-time]').val(button_text);
			$(button_clicked).parent().find('.btn').addClass('inactive');
			$(button_clicked).removeClass('inactive');
			// now go disable the matching button on the other side
			button_clicked_container.siblings().each(function(){
			//	if($(this).attr('id') !== button_clicked_container.attr('id')){
					var buttons = $(this).find('.btn');
					buttons.each(function(){
						if($(this).text() === button_clicked.text()){
							// add your class here
							$(this).addClass('disabled');
						} 
						else {
							if($(this).hasClass('disabled')){
								$(this).removeClass('disabled');
								$(this).addClass('inactive');
							}
							
						}
					});
			//	}
			});
		}
	});
	
	//this code makes the time range selector show an active state
	$('#prize-value-edit, #prize-edit-container').on('click', '#time-controller button', function() {           
		$(this).addClass('active-tab').siblings().removeClass('active-tab');           
	});
	
});