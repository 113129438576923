

$(document).ready(function() {

	var createTestSuiteRecord = function (config_items) {
		$('.config-loading-text').html('Deploying Site to Test Suite');
		$.ajax({
			url: '/admin/testsuite/storesite',
			type: 'post',
			data: {
				url: config_items['main-config']['url'],
				name: config_items['main-config']['name']
			},
			success: function(response){
				if(response.success) {
				success_message('Successfully Saved Configuration');
				$('.config-loader-overlay').hide();
				}
				else {
				if(response.error) {
					error_message(response.error);
				}
				else {
					error_message('WARN: Failed to Create Test Suite Record');
				}
				$('.config-loader-overlay').hide();
				}
			},
			error: function(response){
				$('.config-loader-overlay').hide();
				error_message('WARN: Failed to Create Test Suite Record');
			}
		});
	};

	var imageRequest = function(form_data){
		//makeRequest is not used here due to special parameters - contentType and processData
		return $.ajax({
			cache: false,
			url: '/admin/switch-site/storeimg',
			type: "POST",
			data: form_data,
			contentType: false,
			processData: false
		});
	};

   	var imageUploadRequests = function(images, config_items, config_id, callback){
		var requests = [];
		$.each(images, function(i, img_selector){
			var fd = new FormData();
			var files = $(img_selector)[0].files[0];
			fd.append('asset-upload-img', files);
			fd.append('prefix', $(img_selector).data('for'));
			fd.append('site_id', config_id);
			if(isset(config_items.campaign_id)){
				fd.append('campaign_id', config_items.campaign_id);
			}
			// fd.append('asset-directory', 'switch_site\\'+config_items['main-config']['url'].replace(/[\.-]/g, '_'));
			fd.append('config_item_id',  $(img_selector).data('id'));

			requests.push(imageRequest(fd));
		});

		$.when.apply(null, requests).done(function(args){
			var results = [];
			$.each(arguments, function(index, responseData){
				if(requests.length == 1){
					results.push(responseData);
					return false;
				}
				results.push(responseData[0]);
			});
			callback(results);
		}).fail(function(){
			$('.config-loader-overlay').hide();
			error_message('Failed to Upload Images in core request, speak to IT.');
		});
   };

	var confirmChanges = function(site_id, config_items, images){
		var data = {
			'site_id' : site_id,
			'data' : config_items
		};
		makeRequest('/admin/switch-site/compare-changes', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				createModal('switch_site_config_coparison_modal', response.html);
				$('.config-loader-overlay').hide();
				$('#switch_site_config_coparison_modal').on('click', '#confirm_site_config_changes', function(){
					var extra_check = $(this).data('extra-check');
					if(extra_check){
						if(prompt('Looks like you are trying to push your config from you local to live, I\'m not going to stop you... but could you please enter "I am doing a bad thing" to config you understand your actions').toLowerCase().replace(/ /g, '') == 'iamdoingabadthing'){
							saveConfig(site_id, config_items, images, function(){
								$('#switch_site_config_coparison_modal').modal('hide');
							});
						}
					}
					else{
						saveConfig(site_id, config_items, images, function(){
							$('#switch_site_config_coparison_modal').modal('hide');
						});
					}
				});
			}
			else{
				var error = 'unable to verify config';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
				$('.config-loader-overlay').hide();
			}
		});
	}
	
	var confirmCampaignChanges = function(site_id, campaign_id, config_items, images){
		var data = {
			'site_id' : site_id,
			'data' : config_items,
			'campaign_id' : campaign_id
		};
		makeRequest('/admin/switch-site/compare-changes', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				createModal('switch_site_config_coparison_modal', response.html);
				$('.config-loader-overlay').hide();
				$('#switch_site_config_coparison_modal').on('click', '#confirm_site_config_changes', function(){
					var extra_check = $(this).data('extra-check');
					if(extra_check){
						if(prompt('Looks like you are trying to push your config from you local to live, I\'m not going to stop you... but could you please enter "I am doing a bad thing" to config you understand your actions').toLowerCase().replace(/ /g, '') == 'iamdoingabadthing'){
							saveCampaignConfig(site_id, campaign_id, config_items, images, function(){
								$('#switch_site_config_coparison_modal').modal('hide');
							});
						}
					}
					else{
						saveCampaignConfig(site_id, campaign_id, config_items, images, function(){
							$('#switch_site_config_coparison_modal').modal('hide');
						});
					}
				});
			}
			else{
				var error = 'unable to verify config';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
				$('.config-loader-overlay').hide();
			}
		});
	}

  	var saveConfig = function(site_id, config_items, images, callback = function(){}){
		showLoader();
		var data = {
			'config_id' : site_id,
			'data' : config_items
		};
	    makeRequest('/admin/switch-site/store', data, function(response){
			if(response.success) {
				if (images.length) {
					imageUploadRequests(images, config_items, site_id, function(responses){
						hideLoader();
						var fail = false;
						var error = 'ERROR: Failed to store images';
						$.each(responses, function(i, response){
							if(!isset(response.success) || !response.success){
								fail = true;
								if(isset(response.error)){
									error = response.error;
								}
							}
						});
						if(fail){
							$('.config-loader-overlay').hide();
							error_message(error);
						}
						else{
							callback();
							createTestSuiteRecord(config_items);
							if(isset(response.site_list_html)){
								$('#switch-site-list-inner').replaceWith(response.site_list_html);
								$('.switch-site-edit-panel').hide();
							}
						}
					});
				}
				else {
					callback();
					hideLoader();
					createTestSuiteRecord(config_items);
					// $('.config-loader-overlay').hide();
					if(isset(response.site_list_html)){
						$('#switch-site-list-inner').replaceWith(response.site_list_html);
						$('.switch-site-edit-panel').hide();
					}
				}
			}
			else {
				hideLoader();
				if(response.error) {
					error_message(response.error);
				}
				$('.config-loader-overlay').hide();
			}
		});
   	}
  	var saveCampaignConfig = function(site_id, campaign_id, config_items, images, callback = function(){}){
		
		showLoader();
		var data = {
			'config_id' : site_id,
			'data' : config_items,
			'campaign_id': campaign_id
		};
		
	    makeRequest('/admin/switch-site/store-campaign-config', data, function(response){
			if(response.success) {
				if (images.length) {
					config_items.campaign_id = campaign_id;
					imageUploadRequests(images, config_items, site_id, function(responses){
						hideLoader();
						var fail = false;
						var error = 'ERROR: Failed to store images';
						$.each(responses, function(i, response){
							if(!isset(response.success) || !response.success){
								fail = true;
								if(isset(response.error)){
									error = response.error;
								}
							}
						});
						if(fail){
							$('.config-loader-overlay').hide();
							error_message(error);
						}
						else{
							callback();
							createTestSuiteRecord(config_items);
						}
					});
				}
				else {
					callback();
					hideLoader();
					createTestSuiteRecord(config_items);
					// $('.config-loader-overlay').hide();
				}
			}
			else {
				hideLoader();
				if(response.error) {
					error_message(response.error);
				}
				$('.config-loader-overlay').hide();
			}
		});
	}

	var setSelectedSite = function(site_id){
		$('#all_switch_site_list li').removeClass('selected');
		$('#all_switch_site_list li[data-id="'+site_id+'"').addClass('selected');
	}

	var updateFromSlider = function(slider, position_one, position_two, min = 0, max = 100){
		
		//account for stange mins
		max = max + min;

		//we need to work out the percentages 
		var lower_percetage = Math.round((position_one / max) *100);
		var second_percentage = Math.round(((position_two - position_one)/max)*100);
		var remainer =  Math.round(((max - position_two)/max) *100);
		var gradient_string = 'linear-gradient(90deg, rgba(58,72,92,1) '+lower_percetage+'%, rgba(14,118,188,1) '+lower_percetage+'%, rgba(100,174,207,1) '+second_percentage+'%, rgba(135,197,214,1) '+second_percentage+'%)'
		$(slider).css('background', gradient_string);
		var percentages = [lower_percetage, second_percentage, remainer];
		var container = $(slider).closest('.switch-site-input-row');
		var outputs = $(container).find('output');
		var result = {};
		$.each(outputs, function(i, output){
			var percentage = percentages[i];
			$(output).text(percentage + '%');
			$(output).data('value', percentage);
			result[$(output).attr('name')] = percentage;
		});

		//we also need to update the hidden input 
		//so we need to bundle up the data
		$(container).find('input').val(JSON.stringify(result));
	}

	var updateFromOutput = function(output, value){

		var max = isset($(slider).data('max')) ? $(slider).data('max') : 100;
		if(value > max){
			value = max;
		}
		$(output).data('value', value);
		$(output).text(value +'%');

		//we need to get all of the output values
		var container = $(output).closest('.switch-site-input-row');
		var outputs = $(container).find('output');
		var slider = $(container).find('.slider-multi-range');

		var total = 0;
		$.each(outputs, function(i, other_output){
			total += $(other_output).data('value');
		})
		var target_output_name = $(output).attr('name');
		var percentages = [];
		var result = {};
		var found = false;
		var adjusted = false;
		var overflow = false;
		$.each(outputs, function(i, other_output){
			var other_output_name = $(other_output).attr('name');
			if(other_output_name == target_output_name){
				found = true;
			}
			else{
				if(overflow){
					var other_value = $(other_output).data('value');
					other_value = other_value - overflow;
					if(other_value < 0){
						other_value = 0;
					}
					overflow = false;
					$(other_output).data('value', other_value);
					$(other_output).text(other_value +'%');
				}
				else if(found && !adjusted){
					var other_value = $(other_output).data('value');
					other_value = other_value - (total - max);
					if(other_value < 0){
						other_value = 0;
						//we need to adjust another ouput
						overflow = Math.abs(other_value - (total - max));
					}
					$(other_output).data('value', other_value);
					$(other_output).text(other_value +'%');
					adjusted = true;
				}
			}
			var other_output_value = $(other_output).data('value');
			result[$(other_output).attr('name')] = other_output_value;
			percentages.push(other_output_value);
		});
		if(found && !adjusted){
			var other_output = outputs[1];
			var other_value = $(other_output).data('value');
			other_value = other_value - (total - max);
			if(other_value < 0){
				other_value = 0;
				//we need to adjust another ouput
				overflow = Math.abs(other_value - (total - max));
			}
			percentages[1] = other_value;
			$(other_output).data('value', other_value);
			$(other_output).text(other_value +'%');
			adjusted = true;
		}
		if(overflow){
			var other_output = outputs[0];
			var other_value = $(other_output).data('value');
			other_value = other_value - overflow;
			if(other_value < 0){
				other_value = 0;
			}
			percentages[0] = other_value;
			$(other_output).data('value', other_value);
			$(other_output).text(other_value +'%');
		}


		$(slider).slider('values', [percentages[0], percentages[0] + percentages[1]]);
		var gradient_string = 'linear-gradient(90deg, rgba(58,72,92,1) '+percentages[0]+'%, rgba(14,118,188,1) '+percentages[0]+'%, rgba(100,174,207,1) '+percentages[1]+'%, rgba(135,197,214,1) '+percentages[1]+'%)'
		$(slider).css('background', gradient_string);
		$(container).find('input').val(JSON.stringify(result));


	}

	$('.switch-site-edit-panel').on('blur', 'div.multi-range-options output', function(){
		var value = parseInt($(this).text().replace('%', ''));
		if(!value){
			value = 0;
		}
		updateFromOutput(this, value);
	});

	var createMultiRangeSliders = function(){

		$( ".slider-multi-range" ).each(function(){
			var container = $(this).closest('.switch-site-input-row');
			var outputs = $(container).find('output')
			var values = [];
			$.each(outputs, function(i, output){
				values.push($(output).data('value'));
			});
			values = [values[0],values[0] + values[1]];
			var min = isset($(this).data('min')) ? $(this).data('min') : 0;
			var max = isset($(this).data('max')) ? $(this).data('max') : 100;
			var step = isset($(this).data('step')) ? $(this).data('step') : 1;
			$(this).slider({
				range: true,
				min: min,
				max: max,
				step: step,
				animate: true,
				values: values,
				slide: function( event, ui ) {
					updateFromSlider(this, ui.values[ 0 ], ui.values[ 1 ], min, max);
				}
			});
			updateFromSlider(this, values[0], values[1], min, max);
		});
	}
	
	var makeMultiSelectWithDynamicAttributeSortables = function(){

		$(".linked-items").sortable({
			'handle' : '.linked-item-grab-handle',
			'helper': "clone",
			'appendTo':$("body")
		});
	}

	$('body').on('click', '#switch-site-list .edit-trigger', function () {
		var site_id = $(this).data('id');
		setSelectedSite(site_id);
		makeRequest('/admin/switch-site/edit/'+site_id, {},  function(response) {
			if(response.html) {
				$('.switch-site-edit-panel').html(response.html);
				$('.select2').select2({'width': '100%'});
				$('.create-new-site-config').removeAttr('disabled');
				createMultiRangeSliders();
				makeMultiSelectWithDynamicAttributeSortables();
				$('.switch-site-edit-panel').show();
			}
			else {
				if(response.error) {
					error_message(response.error);
				}
				else {
					error_message('ERROR: please speak to IT. Could not find View');
				}
			}
		}, 'GET');
	});

   $('body').on('click', '.create-new-site-config', function () {
      var site_id = $(this).data('id');
	$('.switch-site-item.selected').removeClass('selected');
      $.ajax({
         type: "GET",
         url: '/admin/switch-site/new',
         success: function(response) {
            if(response.html) {
               $('.switch-site-edit-panel').html(response.html);
               $('.source_id').select2({'width': '100%'});
               $('.create-new-site-config').removeAttr('disabled');
               $('.switch-site-edit-panel').show();
            }
            else {
               if(response.error) {
                  error_message(response.error);
               }
               else {
                  error_message('ERROR: please speak to IT. Could not find View');
               }
            }
         },
         error: function (error) {
            error_message('ERROR: please speak to IT. Unknown Response or Could Not Find Site.')
         }
      });
   });

   $('body').on('click', '.reset-config', function () {
	   	if(confirm('are you sure you know what you are doing?')){
			$('.config-loading-text').html('Resetting Site Configuration to Default . . .');
			$('.config-loader-overlay').show();
			var site_id = $(this).data('id');
			$.ajax({
				type: "GET",
				url: '/admin/switch-site/reset/'+site_id,
				success: function(response) {
					if(response.success) {
					location.reload();
					}
					else {
					if(response.error) {
						error_message(response.error);
					}
					else {
						error_message('ERROR: please speak to IT. Could not find View');
					}
					}
				},
				error: function (error) {
					error_message('ERROR: please speak to IT. Unknown Response or Could Not Find Site.')
				}
			});
			$('.config-loader-overlay').hide();
		}
   });

   $('body').on('click', '.switch-site-tab', function () {
      if(!$(this).hasClass('switch-site-selected-tab')) {
         var that = $(this);
		 var target = $(that).data('target');
		 var container = $(this).closest('.switch-site-details-container');

         $(container).find('.switch-site-tab').each(function (i, input) {
            if($(input).data('target') != $(that).data('target')) {
               var temp_target = $(input).data('target');
               $(input).removeClass('switch-site-selected-tab');
			   $(container).find('div[data-type="' + temp_target + '"]').hide();
            }
         });

         $(that).addClass('switch-site-selected-tab');
         $(container).find('div[data-type="' + target + '"]').show();
      }
   });

   var config_item_getters = {
	   'multi_select_with_dynamic_attributes' : function(row){
			var container = $(row).find('.switch-site-config-multi-dynamic-outer-container');
			var result = {
				'name' : $(container).data('name'),
				'value' : {}
			}

			var linked_items = $(container).find('.linked-items .linked-item');
			$.each(linked_items, function(index, item){
				var item_result = {};
				var value = $(item).data('value');
				item_result.value = value;
				item_result.text = $(item).data('text');
				//now we are on to the dynamic items
				var dynamic_attributes = $(item).find('.edit-section-input-holder input');
				$.each(dynamic_attributes, function(i, attribute_input){
					item_result[$(attribute_input).attr('name')] = $(attribute_input).val().trim();
				});
				result['value'][value] = item_result;
			});

			return result;
	   }
   }

   var buildSwitchSiteConfig = function(form){
	   var config_items = {};
	   var images = [];
		$.each($(form).find('.config-item-panel'), function (index, config_type) {

			//set array key equal to 'config type'
			var tab_name = $(config_type).data('type');
			if(!isset(config_items[tab_name])) {
				config_items[tab_name] = {};
			}

			//find all inputs for specific config type and add them to array
			$.each($(config_type).find(':input:not(button):not(output):not(.not-a-config-item)'), function (i, input) {
				var row = $(input).closest('.input-row');
				if (!$(row).hasClass('blocked-input')) {
					var config_item_type = $(row).data('type');
					if(isset(config_item_getters[config_item_type]) && typeof(config_item_getters[config_item_type]) == 'function'){
						var result = config_item_getters[config_item_type](row);
						if(isset(result.name) && isset(result.value)){
							config_items[tab_name][result.name] = result.value;
							return; // continue
						}
					}
					if ($(input).attr('type') != 'file') {
						if ($(input).hasClass('icon-config')) {
							if($(input).hasClass('set-config-icon')) {
								var style = $(input).closest('.input-row').find('.icon-style').val();
								config_items[tab_name][$(input).data('name')] = style + ' fa-' + $(input).val();
							}
						}
						else {
							if ($(input).attr('type') == 'checkbox') {
								var val = 0;
								if ($(input).is(':checked')) {
									val = 1;
								}
								config_items[tab_name][$(input).data('name')] = val;
							}
							else {
								config_items[tab_name][$(input).data('name')] = $(input).val();
							}
						}
					}
					else {
						if( $(input)[0].files.length){
							images.push($(input));
							config_items[tab_name][$(input).data('name')] = $(input)[0].files[0].name;
						}
						else{
							if($(input).parent().find('.switch-site-image-holder').length){
								config_items[tab_name][$(input).data('name')] = $(input).parent().find('.switch-site-image-holder').data('value');
							}
							else{
								error_message('please select an image for ' + $(input).data('name'));
								valid = false;
								return false;
							}
						}
					}
				}
			});
		});
		return {
			'config' : config_items,
			'images' : images
		};
   }

   	$('body').on('click', '.apply-config', function () {
		$('.config-loading-text').html('Compiling Configuration...');
		$('.config-loader-overlay').show();
		var that = $(this);
		var form = $(this).closest('.configuration-form');
		// var img_selector = '';
		//find all config panels
		
		var result = buildSwitchSiteConfig(form);
		 
		if(!result){
			$('.config-loader-overlay').hide();
			return false;
		}
		var config_items = result.config;
		var images = result.images;

      	$('.config-loading-text').html('Uploading Configuration');

		var config_id = $(that).data('id');


		confirmChanges(config_id, config_items, images);
   	});
	   
	$('body').on('click', '.apply-campaign-config', function () {
		var container = $(this).closest('.edit-site-config-form-container');
		$(container).find('.config-loading-text').html('Compiling Configuration...');
		$(container).find('.config-loader-overlay').show();
		var that = $(this);
		var form = $(this).closest('.configuration-form');
		
		var result = buildSwitchSiteConfig(form);
		if(!result){
			$('.config-loader-overlay').hide();
			return false;
		}
		var config_items = result.config;
		var images = result.images;

		$(container).find('.config-loading-text').html('Uploading Configuration');

		var site_id = $(that).data('site-id');
		var campaign_id = $(that).data('campaign-id');

		confirmCampaignChanges(site_id, campaign_id, config_items, images);
   	});

   // $('body').on('click', '.create-new-site-config', function () {
   //    $('#switch-sites .edit-site-config-form-container').remove();
   //    $('#switch-sites .switch-site-new-panel').show();
   // });

   $('body').on('click', '.remove-new-config-layer', function () {
      $('.create-new-site-config').attr('disabled', 'disabled');
   });

   $('body').on('click', '.reset-config-row', function () {
      var that = $(this);
      $(that).removeClass('reset-config-row');
      $(that).addClass('add-config-row').html('Use Custom Config');

      var container = $(that).closest('.switch-site-input-row');

      $(container).find('.input-row').addClass('blocked-input').hide();
      $(container).find('.default-option').show();
   });

   $('body').on('click', '.add-config-row', function () {
      var that = $(this);
      $(that).removeClass('add-config-row');
      $(that).addClass('reset-config-row').html('Reset to Default');

      var container = $(that).closest('.switch-site-input-row');

      $(container).find('.input-row').removeClass('blocked-input').show();
      $(container).find('.default-option').hide();
   });

   $('body').on('click', 'label.switch', function () {
      var input = $(this).find('input');

      if(input.is(':checked')) {
         input.val(1);
      }
      else {
         input.val(0);
      }
   });

   $('body').on('input', '.config-switch', function () {
      var value = $(this).val();
      $(this).closest('.range-container').next().find('#slidervalue').html(value + '%');
   });

   $('body').on('blur', '.set-config-icon', function () {
      var that = $(this),
          base_url = 'https://fontawesome.com/icons/',
          icon = $(that).val(),
          style = $(that).closest('.input-row').find('.icon-style option:selected').text().toLowerCase(),
          button = $(that).closest('.input-row').find('.icon-preview');

      if (icon.length) {
         $(button).attr('href', base_url + icon + '?style=' + style);
         $(button).removeClass('disabled');
      }
   });

   $('.switch-site-edit-panel').on('click', '.switch-site-image-holder', function(){
	   	$('#image_lightbox').find('img.light-box-image').attr('src', $(this).find('img').attr('src'));
		$('#image_lightbox').show();
   });
   
   $('.light-box, .light-box .close').on('click', function(){
		$('#image_lightbox').hide();
   });

   $('.switch-site-edit-panel').on('click', '#get_verbose_logging', function(){

		var site_id = $(this).data('site-id');

		var data = {
			'site_id' : site_id
		};	
		if($(this).data('campaign-id')){
			data.campaign_id = $(this).data('campaign-id');
		}

		var that = this;
		makeRequest('/admin/switch-site/get-tracking', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$(that).closest('.verbose-logging-container').html(response.html);
				$('input[name="conversion-date-filter"]').daterangepicker({
					"locale": {
						"format": 'DD/MM/YYYY HH:mm',
						"separator": "-"
					},
					"timePicker": true,
					"linkedCalendars": false,
					"showDropdowns": true
				});
			}
			else{
				var error = 'failed to load tracking info - please refresh and try again';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});

	});
   
	$('.switch-site-edit-panel').on('click', '.make-new-campaign', function(){

		var site_id = $(this).data('site-id');

		var container = $(this).closest('#make_new_campaign');

		var name = $(container).find('#campaign_name').val().trim();
		var url = $(container).find('#campaign_url').val().trim();
		var to_copy = $(container).find('#campaign_copy_select').val();
		if(name.length < 5){
			error_message('please enter a descriptive name for the campaign');
		}
		//remove any leading slashes
		//if the user entered /save for example we only want to store save 
		if(url.charAt(0) == '/'){
			url = url.substr(1);
		}
		if(!url){
			error_message('please enter a url for the campaign to be accessible on');
		}

		var data = {
			'site_id' : site_id,
			'campaign_name' : name,
			'campaign_url' : url,
			'campaign_to_copy' : to_copy
		};	

		makeRequest('/admin/switch-site/make-new-campaign', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('.switch-site-details-container.collapse.in').removeClass('in');
				// $(response.html).find('.switch-site-details-container.collapse').addClass('in');
				$('#campaign_config').replaceWith(response.html);
				$('.switch-site-details-container.collapse').last().addClass('in');
				$('.select2').select2({'width' : '100%'});
				if(isset(response.campaign_id)){
					var select_option = new Option(name + ' - /' + url, response.campaign_id, false, true);
					$('#campaign_edit_select').append(select_option).trigger('change');
				}

			}
			else{
				var error = 'failed to create campaign - please refresh and try again';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});

	});
	
	$('.switch-site-edit-panel').on('change', '#campaign_edit_select', function(){

		var campaign_id = $(this).val();
		if(!campaign_id){
			return;
		}
		
		var data = {
			'campaign_id' : campaign_id
		};	

		makeRequest('/admin/switch-site/view-campaign', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('.switch-site-details-container.collapse.in').removeClass('in');
				// $(response.html).find('.switch-site-details-container.collapse').addClass('in');
				$('#campaign_config').replaceWith(response.html);
				$('.switch-site-details-container.collapse').last().addClass('in');
				$('.select2').select2({'width' : '100%'});
			}
			else{
				var error = 'failed to load campaign - please refresh and try again';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});

	});
	
	$('#switch-site-list').on('click', '#revert_switch_site_config', function(){

		var container = $(this).closest('#advanced_site_controls');
		var hash = $(container).find('#revert_hash').val();
		if(hash.length < 12){
			error_message('please enter a valid hash, this can be found in the switch_site_config_log table, if that doesn\'t make sense to you then this control is not intended for you');
			return false;
		}
		showLoader();
		var data = {
			'hash' : hash
		};
		makeRequest('/admin/switch-site/compare-revert', data, function(response){
			hideLoader();
			if(isset(response.success) && response.success && isset(response.html)){
				createModal('switch_site_revert_coparison_modal', response.html);
				$('#switch_site_revert_coparison_modal').on('click', '#confirm_site_revert_changes', function(){
					makeRequest('/admin/switch-site/revert', data, function(response){
						if(isset(response.success) && response.success){
							success_message('Reverted');
							$('#switch_site_revert_coparison_modal').modal('hide');
							window.location.reload();
						}
						else{
							var error = 'unable to revert changes';
							if(isset(response.error)){
								error = response.error;
							}
							error_message(error);			
						}
					});
				});
			}
			else{
				var error = 'unable to verify changes';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});

	$('.switch-site-edit-panel').on('click', '.linked-item-edit-button', function(){
		var container = $(this).closest('.linked-item');
		$(container).find('.linked-item-edit-section').collapse('toggle');
	});
	
	$('.switch-site-edit-panel').on('click', '.linked-item-remove-button', function(){
		var container = $(this).closest('.linked-item');
		customConfirm('are you sure you want to remove this item?', function(){
			$(container).remove();
		});
	});
	
	$('.switch-site-edit-panel').on('click', '.add-new-config-multi-item', function(){
		var container = $(this).closest('.switch-site-config-multi-dynamic-outer-container');
		var selected_item = $(container).find('.new-item-select').find(':selected');
		var new_value = $(selected_item).attr('value');
		var new_display_text = $(selected_item).text();
		var existing = $(container).find('.linked-item[data-value="' + new_value + '"]');
		if(existing.length){
			error_message('looks like ' + new_display_text + ' has already been linked');
			return false;
		}
		var template = $(container).find('.switch-site-config-multi-dynamic-item-template').clone();
		$(template).removeClass('switch-site-config-multi-dynamic-item-template');
		$(template).attr('data-value', new_value);
		$(template).attr('data-text', new_display_text);
		$(template).find('.linked-item-label').text(new_display_text);
		$(container).find('.linked-items').append(template);
		$(template).show();
		
	});

	$('.switch-site-edit-panel').on('keyup', '.search-config-items', function () {
		let that = $(this),
			search_value = $(that).val(),
			$container = $(that).closest('.config-item-panel');

		if(search_value.length === 0) {
			$($container).find('.searchable-name-container').closest('.switch-site-input-row').show();
		} 
		else {
			let $items = $($container).find('.searchable-name-container');
			$.each($($items), function (i, $element) {
				let item_value = $($element).data('search-name').toLowerCase();
				if (item_value.indexOf(search_value) >= 0) {
					$($element).closest('.switch-site-input-row').show();
				} 
				else {
					$($element).closest('.switch-site-input-row').hide();
				}
			});
		}
	});
	
	$('.switch-site-edit-panel').on('click', '.view-config-item-history', function () {
		var site_id = $(this).data('site-id');
		var config_item_id = $(this).data('config-item-id');
		
		var data = {
			'site_id' : site_id,
			'config_item_id' : config_item_id
		};
		
		var campaign_id = $(this).data('campaign-id');
		if(campaign_id){
			data.campaign_id = campaign_id;
		}

		var icon = $(this).find('i');
		makeLoadingIcon(icon);
		makeRequest('/admin/switch-site/config-item-history', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				createModal('switch_site_config_item_history_modal', response.html);
			}
			else{
				var error = 'unable to view history';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			restoreIcon(icon);
		});
	});

});