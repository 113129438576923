$(document).ready(function(){
	var order_id = $('div[name^="compliance"][id="lead_script"]').data("quoteid");
	var ident = $('div[name^="compliance"][id="lead_script"]').data("scriptid");
	var script_name = 'compliance_script';
	$('div[name^="compliance"][id="lead_script"]').on('click','#compliance-start',function(){
		
		//grab user id connected to the script this may be overridden by a manager
		var user_id = $('#script-tabs').find("[data-truename='compliance-energy']").data('scriptuserid');
		
		// update the order to compliance started.  //
		$.ajax({ 
			cache: false,
			url: "/compliance/start/"+order_id+"/"+user_id,
			dataType: 'json',
			success: function (response) {
				if(response.success === true){
					window[script_name].handleStep(ident+'.introduction');
				}
				else{
					alert(response.message);
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		//		'/compliance/start/'+order_id;
	});
	
	$('div[name^="compliance"][id="lead_script"]').on('click','#compliance-complete',function(){
		var notes = $('#compliance_additional_notes').val();
		// update the order to compliance started.  //
		$.ajax({ cache: false,
			url: "/compliance/completed/"+order_id,
			type:"POST",
			dataType: 'json',
			data : {additional_notes: notes },
			success: function (response) {
				if(response.success === true){
					window[script_name].handleStep(ident+'.show_report');
				}
				else{
					alert(response.message);
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		//		'/compliance/start/'+order_id;
	});
	
	$('div[name^="compliance"][id="lead_script"]').on('click','#compliance-form-continue',function(){
		$('.compliance-form-group').submit();
	});
	
	
	$('div[name^="compliance"][id="lead_script"]').on('submit','.compliance-form-group', function(e){
		e.preventDefault();
		$('.error').html('');
		var form_name = this.id;
		var data = $(this).serialize();
		var action = $(this).attr('action');
		$.ajax({ cache: false,
			url: "/compliance/validate/"+order_id,
			type:"POST",
			dataType: 'json',
			data: { form_name : form_name, data : data, action:action },
			success: function (response) {
				if(response.success === true){
					window[script_name].handleStep(action);
				}
				else{
					if(response.errors){
						$.each(response.errors, function(target,message){
							$('#'+target).html(message.error);
						});
					
					}
					else{
						alert(response.message);
					}
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		
	});
	
	//quick report print, without resorting to plugins.
	$('div[name^="compliance"][id="lead_script"]').on('click','#compliance-print-report', function(e) {
		var mywindow = window.open('', 'PRINT', 'height=800,width=800');
		mywindow.document.write('<html><head><title>' + document.title +' | Compliance Report </title>');
		mywindow.document.write('</head><body >');
		mywindow.document.write('<h1>' + document.title  + '</h1>');
		mywindow.document.write($('div[name^="compliance"][id="lead_script"]').html());
		mywindow.document.write('</body></html>');

		mywindow.document.close(); // necessary for IE >= 10
		mywindow.focus(); // necessary for IE >= 10*/

		mywindow.print();
		mywindow.close();

		return true;
	});
	
	
	var fixed = $('.affix');
	fixed.on('touchmove', function(e) {
        e.preventDefault();
	}, false);
	
});
