
var sort_update = function(){
	var teams_in_queue = [];
	var tags_in_queue = [];
	var events_in_queue = [];
	var statuses_in_queue = [];
	
	$('#active_team_sort li').each(function(){
		if(teams_in_queue.indexOf($(this).data('team-id')) == -1){
			teams_in_queue.push($(this).data('team-id'));
		}
		else{
			$(this).remove();
		}
	});
	$('#active_tag_sort li').each(function(){
		if(tags_in_queue.indexOf($(this).data('tag-id')) == -1){
			tags_in_queue.push($(this).data('tag-id'));
		}
		else{
			$(this).remove();
		}
	});
	$('#active_event_sort li').each(function(){
		if(events_in_queue.indexOf($(this).data('event-id')) == -1){
			events_in_queue.push($(this).data('event-id'));
		}
		else{
			$(this).remove();
		}
	});
	$('#active_status_sort li').each(function(){
		if(statuses_in_queue.indexOf($(this).data('status-id')) == -1){
			statuses_in_queue.push($(this).data('status-id'));
		}
		else{
			$(this).remove();
		}
	});
	
	var queue_id = $('#save_queue').data('queue');
	
	update_queue(queue_id, teams_in_queue, tags_in_queue, events_in_queue,statuses_in_queue);
	
}

var ticket_queues_sortable = function() {
	$( "ul#all_teams_sort" ).sortable({
		connectWith: "#active_team_sort",
//		appendTo: "body",
//		helper: "clone",
//		containment: "window",
		update: function (e, ui) {
			sort_update();
		}
	});
	$( "ul#all_tags_sort" ).sortable({
		connectWith: "#active_tag_sort",
		update: function () {
			sort_update();
		}
	}).disableSelection();
	$('ul#all_events_sort').sortable({
		connectWith : '#active_event_sort',
		update : function(){
			sort_update();
		}
	}).disableSelection();
	$('ul#all_statuses_sort').sortable({
		connectWith : '#active_status_sort',
		update : function(){
			sort_update();
		}
	}).disableSelection();
	$(".reportSortable").sortable();
};

var update_queue = function(queue_id, teams, tags, events,statuses){
	var data = {
		'teams' : teams,
		'tags' : tags,
		'events' : events,
		'statuses' : statuses
	};

	makeRequest('/ticket/queues/update_links/'+queue_id, data, function(response){
		if(checkErrors(response)){
			success_message();
			$('#active_team_sort  div.delete-trigger').show();
			$('#active_tag_sort  div.delete-trigger').show();
			$('#active_event_sort  div.delete-trigger').show();
			$('#active_status_sort  div.delete-trigger').show();
		}
	});
};

var success_message = function (text) {
	if (!text) {
		text = 'Updated';
	}
	$('.update-success').fadeIn().text(text);
	setTimeout(function () {
		$('.update-success').fadeOut();
	}, 5000);
};

var error_message = function (text) {
	$('.update-error').fadeIn().text(text);
	setTimeout(function () {
		$('.update-error').fadeOut();
	}, 5000);
};

var checkErrors = function (response) {
	if (response.success != true) {
		if (response.error) {
			if (response.error.length > 1) {
				error_message(response.error);
				return false;
			}
		}
		error_message('an unknown error occurred');
		return false;
	}
	return true;

}

$(document).ready(function() {
		
	ticket_queues_sortable();

	$('#tickets-container').on('click', '#expand-ticket', function(){
		var parent = $(this).parent();
		parent.toggleClass('remove-bottom-border-radius');
	});
		
	var correctView = function(){
		var active_button = $('#queue_container #view_toggle button[aria-expanded=true]');
		$('#active_accordion .collapse').collapse('hide');
		$('#active_accordion '+ $(active_button).data('target')).collapse('show');
	}
		
	$('#ticket_queues').on('click', '.edit-trigger', function() {
		var queue_id = $(this).data('queue-id');
		$.ajax({
			url: '/ticket/queues/edit/'+queue_id,
			dataType: 'json',
			success: function(response) {
				if(response.success){
					$('#edit-queue').html(response.html);
					ticket_queues_sortable();
					$('.ticket-queue-edit-panel').show();
					$('#tickets-all-teams  div.delete-trigger').hide();
					$('#tickets-all-tags  div.delete-trigger').hide();
					$('.select2').select2({width:'100%'});
					correctView();
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});

	});
	
	$('#ticket_queues').on('click', '#add-ticket-queue', function() {
		var parent = $(this).parent(); 
//		parent.find('span.errors').html('');
		parent.parent().find('.module-error').hide().text('');
		var queue_name = parent.find('input[name="new-queue-name"]').val();
		if(queue_name.length <= 5) {
			parent.parent().find('.module-error').fadeIn('fast').text('You must enter a GOOD name');
		} 
		else {
			$.ajax({ cache: false,
				url: "/ticket/queues/add",
				type:"POST",
				dataType: 'json',
				data: { "queue_name" : queue_name },
				success: function (response) {
 					if(response.success === true) {
						//redirects to the create new action group page
						window.location.reload();
					} 
					else {
						if(response.error) {
							parent.parent().find('.module-error').fadeIn('fast').text(response.error);
						}
					}
				},
				error: function (error) {
					//console.log(error);
				}
			});
		};
	});
	
	$('#ticket_queues').on('keyup','.search-queues',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#teams-container ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});
	
	$('#edit-queue').on('click', '#save_queue', function() {
		var parent = $(this).parent().parent(); 
//		parent.find('span.errors').html('');
		parent.parent().find('.module-error').hide().text('');
		var queue_name = parent.find('input#queue_name').val();
		var queue_email = parent.find('input#queue_email').val();
		
		var master_user_id = $('#queue_master').val();
		var domain_id = $('#queue_domain').val();
		
		if(queue_name.length <= 5) {
			error_message('You must enter a GOOD name');
			return;
		}
		if(queue_email.length <= 5) {
			error_message('You must enter a GOOD email');
			return;
		}
		var queue_id = $(this).data('queue');

		var set_password = false;
		var password = '';
		var password_field = parent.find('input#queue_password');
		if(password_field.is(':visible')) {
			password = password_field.val();
			if(password.length == 0) {
				if(!confirm('You have not set a password for this queue. Are you sure you want to remove the password already set?')) {
					return;
				}
			}
			set_password = true;
		}
		
		var data = {
			'name' : queue_name,
			'email' : queue_email,
			'master_user_id' : master_user_id,
			'domain_id' : domain_id,
		};

		if(set_password) {
			data['password'] = password;
		}

		makeRequest('/ticket/queues/update/'+queue_id, data, function(response) {
			if(checkErrors(response)){
				success_message();
			}
		});

	});
	
	$('#edit-queue').on('click', '.delete-trigger', function() {
		
		var item_id = $(this).data('id');
		var item_type = $(this).data('type');
		var queue_id = $('#save_queue').data('queue');
		
		var parent = $(this).parent();
		
		var data = {
			'item_type' : item_type,
			'item_id' : item_id
		};
		$.ajax({cache: false,
			url: "/ticket/queues/remove_link/"+queue_id,
			type: "POST",
			dataType: 'json',
			data: data,
			success: function (response) {
				if(checkErrors(response)){
					$(parent).remove();
					success_message();
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});
	
	$('#all-teams').on('keyup','.search-teams',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#all_teams_sort');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});
	
	$('#all-tags').on('keyup','.search-tags',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#all_tags_sort');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});
	
	$('#all-events').on('keyup','.search-events',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#all_events_sort');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});
	
	$('#all-tags').on('click', '#add-tag', function() {
		var parent = $(this).parent(); 
//		parent.find('span.errors').html('');
		parent.parent().find('.module-error').hide().text('');
		var tag_name = parent.find('input[name="new-tag-name"]').val();
		if(tag_name.length < 4) {
			parent.parent().find('.module-error').fadeIn('fast').text('You must enter a GOOD name');
		} 
		else {
			$.ajax({ cache: false,
				url: "/ticket/tags/add",
				type:"POST",
				dataType: 'json',
				data: { "name" : tag_name },
				success: function (response) {
 					if(response.success === true) {
						if(response.html){
							$('#all_tags_sort').append(response.html);
							$('#all-tags  div.delete-trigger').hide();
						}
					} 
					else {
						if(response.error) {
							parent.parent().find('.module-error').fadeIn('fast').text(response.error);
						}
					}
				},
				error: function (error) {
					//console.log(error);
				}
			});
		};
	});
	
	$('#ticket_management').on('click', '#create-new-ticket', function(e){
		e.preventDefault();
		
		$('#ticket_title_edit').val('');
		$('#ticket_description_edit').val('');
		
		$('#createTicket').modal();
		
	});
	
	
	var insertNewTicket = function(html, queue_id, priority){
		var queue = $('div.queue[data-queue-id=' + queue_id +']');
		
		queue.find('div.ticket-item').each(function(){
			if($(this).data('priority') > priority){
				$(html).insertBefore($(this));
				return false;
			}
			if($(this).is(":last-child" )){
				$(html).insertAfter($(this));
			}
		});
		
		$('#createTicket').modal('hide');
		
		
	};
	
	var makeNewTicket = function(title, description, queue_id, priority, contact_id = false, open = false){
				
		var data = {
			'title' : title,
			'description' : description,
			'queue_id' : parseInt(queue_id),
			'priority' : parseInt(priority)
		};

		var stop = false;
		$.each(data, function(type, value){
			if(typeof value ==  "string"){
				if(value.length < 4){
					error_message('please enter a decent ' + type);
					stop = true;
				}
			}
		});
		if(stop){
			return false;
		}

		if(contact_id !== false){
			data.contact_id = contact_id;
		}
		
		makeRequest('/ticket/new', data, function(response){
			if(checkErrors(response)){
				if(open){
					if(response.ticket_id){
						$('#ticket_title_edit').val('');
						$('#ticket_description_edit').val('');
						var url = '../ticket/' + response.ticket_id;
						window.location.href = url;
					}
				}
				if(response.html){
					$('#ticket_title_edit').val('');
					$('#ticket_description_edit').val('');
					insertNewTicket(response.html, queue_id, priority);
					
				}
				success_message();
			}
		})
	};
	
	$('body').on('click', '#add-ticket', function(){
		$('#createTicket #ctm_ticket_title_edit').val('');
		$('#createTicket #ctm_ticket_description_edit').val('');
		//leaving this commented out for now - but you can use this to change the selected value in the queue select box by name
//		$('#createTicket #ctm_ticket-queue option').each(function(){
//			if($(this).text().indexOf('Customer Care') !== -1){
//				$('#createTicket #ctm_ticket-queue').val($(this).val()).trigger('change');
//			}
//		});


	});

	$('#createTicket').on('shown.bs.modal', function (e) {
		$('#ctm_ticket_title_edit').focus();
	});

	
	$('#createTicket').on('click', '#save', function(e){
		
		var title =  $('#createTicket #ctm_ticket_title_edit').val();
		var description = $('#createTicket #ctm_ticket_description_edit').val();
		var queue_id = $('#createTicket #ctm_ticket-queue').val();
		var priority = $('#createTicket #ctm_ticket_priority_select').val();
		
		var contact_id = false;
		if($('#createTicket #ctm_ticket_linked_contact:checked').length){
			contact_id = $('#createTicket #ctm_ticket_linked_contact:checked').data('contact-id');
		}
		
		makeNewTicket(title, description, queue_id, priority, contact_id);
		
	});
	
	$('#createTicket').on('click', '#save_open', function(e){
		
		var title =  $('#createTicket #ctm_ticket_title_edit').val();
		var description = $('#createTicket #ctm_ticket_description_edit').val();
		var queue_id = $('#createTicket #ctm_ticket-queue').val();
		var priority = $('#createTicket #ctm_ticket_priority_select').val();
		
		var contact_id = false;
		if($('#createTicket #ctm_ticket_linked_contact:checked').length){
			contact_id = $('#createTicket #ctm_ticket_linked_contact:checked').data('contact-id');
		}
		
		var open = true;
		
		makeNewTicket(title, description, queue_id, priority, contact_id, open);
		
	});
	
	$('.no-collapsable').on('click', function (e) {
		e.stopPropagation();
	});
	
	$('.ticket-item').on('click', '.btn-snooze', function(e){
		e.stopPropagation();
		
		var parent = $(this).closest('.ticket-item');
		var ticket_id = $(this).data('ticket-id');
		var minutes = $(this).data('length');
		
		var data = {
			'minutes' : minutes
		};
		
		$.ajax({
			url: '/ticket/snooze/' + ticket_id,
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					success_message();
					parent.remove();
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
	});
	
	$('#tickets-container').on('change', '.assignee-change-select', function(){
		var ticket_id = $(this).data('ticket-id');
		
		var new_user_id = $(this).val();
		
		var data = {
			'user' : new_user_id
		};
		
		$.ajax({
			url: '/ticket/edit/assignee/' + ticket_id,
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					success_message();
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
	});
	
	$('#tickets-container').on('change', '.priority-change-select', function(){
		var ticket_id = $(this).data('ticket-id');
		
		var priority = $(this).val();
		
		var data = {
			'priority' : priority
		};
		
		$.ajax({
			url: '/ticket/edit/priority/' + ticket_id,
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					success_message();
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
	});
	
	$('#tickets-container').on('change', '.status-change-select', function(){
		var ticket_id = $(this).data('ticket-id');
		
		var status_id = $(this).val();
		
		var data = {
			'status_id' : status_id
		};
		
		$.ajax({
			url: '/ticket/edit/status/' + ticket_id,
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					success_message();
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
	});
	
	$('button#take_ticket_button').click(function(){
		var ticket_id = $(this).data('ticket-id');
		
		var user_id = $(this).data('user-id');
		
		var data = {
			'user' : user_id
		};
		
		var parent = $(this).parent();
		var span = $(parent).find('span');
		var button = $(parent).find('button');
		
		$.ajax({
			url: '/ticket/edit/assignee/' + ticket_id,
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					success_message();
					if(response.new_assignee){
						$(span).text(response.new_assignee);
						$(button).remove();
					}
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
	});
	
	
	var toggleView = function(button){
		$(button).siblings().attr('aria-expanded', 'false');
		$(button).attr('aria-expanded', 'true');
		
		$('#queue_container .collapse').collapse('hide');
		$('#queue_container ' + $(button).data('target')).collapse('show');
	}
	
	$('#queue_container').on('click', '#view_toggle button', function(){

		toggleView($(this));
		
	});
	
	
});