$( document ).ready(function() {
	
    // Tooltips
	// Tooltips end
	
	// Hover animation for main navigation
	$("a.list-group-item").hover(
		function(){
			$(this).filter(':not(:animated)').animate({
				paddingLeft:'25px'},'fast');
	
			},
		function() {
			$(this).animate({
				paddingLeft:'10px'
			},'fast');
	});	
	// end
	
	 $(window).scroll(function () {
		if ($(this).scrollTop() > 50) {
			$('#back-to-top').fadeIn();
		} 
		else {
			$('#back-to-top').fadeOut();
		}
	});
	// scroll body to 0px on click
	$('#back-to-top').click(function () {
		$('#back-to-top').tooltip('hide');
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});

//        $('#back-to-top').tooltip('show');

	$(".nav-button").mouseenter(function()
		{
		$(this).find(".diy-tooltip").stop(true, true).show(
			{container: 'body'}
		);		
	});
	$(".nav-button").mouseleave(function()
		{
		$(this).find(".diy-tooltip").stop(true, true).hide();		
	});


	if($('#reportrange').length > 0){

		var start = moment();
		var end = moment();

		function cb(start, end) {
			$('#reportrange span').html(start.format('D, MMMM, YYYY') + ' - ' + end.format('D, MMMM, YYYY'));
		}

		$('#reportrange').daterangepicker({
			showCustomRangeLabel : true,
			startDate: start,
			endDate: end,
			ranges: {
				'Today': [moment(), moment()],
				'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
				'This Week': [moment().startOf('week'), moment().endOf('week')],
				'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
				'This Month': [moment().startOf('month'), moment().endOf('month')],
				'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
			}
		}, cb);

		cb(start, end);

	}

	
	// sidebar navigation
	$('[data-toggle=offcanvas]').click(function() {
        $('.row-offcanvas').toggleClass('active');
        $('#sidebar .collapse').toggleClass('in').toggleClass('visible-xs').toggleClass('hidden-xs');
        $('#wrapper').toggleClass('active');
    });
	
	$("a.list-group-item").hover(function() {
		$(this).find(".popover1").toggleClass('hidden-xs').toggleClass('visible-xs');
	});
	
	// notification icons
	 if($("#not").html()<= "1"  || $("#to-do").html()<= "1" ){
		 $("#not, #to-do" ).hide();
	}
	 
	if($("#not").html() > "0"  || $("#to-do").html() > "0" ){
		$("#profile-notify").show();
	}
	else{
		$("#profile-notify").hide();
	}
	
	// sidebar navigation logo image change for open sidebar
	
	$("#close-ledger").click(function(){
		$("#user-ledger-pop").fadeOut();
	});
	
//	$(".dropdown-sidebar").mouseenter(function(e){
//		e.stopPropagation();
//		$(".dropdown-content-orders").stop(true, true).slideDown({
//			duration: 400,
//			easing: "easeInQuad"
//		});
//	});
//	
//	$(".dropdown-sidebar").mouseleave(function(e){
//		e.stopPropagation();
//		$(".dropdown-content-orders").stop(true, true).slideUp({
//			duration: 400,
//			easing: "easeInQuad"
//		});
//	});
//	
//	$(".dropdown-sidebar-2").mouseenter(function(e){
//		e.stopPropagation();
//		$(".dropdown-content-remarket").stop(true, true).slideDown({
//			duration: 400,
//			easing: "easeInQuad"
//		});
//	});
//	
//	$(".dropdown-sidebar-2").mouseleave(function(e){
//		e.stopPropagation();
//		$(".dropdown-content-remarket").stop(true, true).slideUp({
//			duration: 400,
//			easing: "easeInQuad"
//		});
//	});
//	
//	$(".dropdown-sidebar-admin").mouseenter(function(e){
//		e.stopPropagation();
//		$(".dropdown-content-admin").stop(true, true).slideDown({
//			duration: 400,
//			easing: "easeInQuad"
//		});
//	});
//	
//	$(".dropdown-sidebar-admin").mouseleave(function(e){
//		e.stopPropagation();
//		$(".dropdown-content-admin").stop(true, true).slideUp({
//			duration: 400,
//			easing: "easeInQuad"
//		});
//	});
	
	

	
});


var add_user_task = function(user_task, due_date, callback) {
	var user_id = $('#profile-header').data('user-id');
	$.ajax({ cache: false,
		url: "/user/"+user_id+"/add_user_task",
		type:"POST",
		dataType: 'json',
		data: { text : user_task, date : due_date },
		success: function (response) {
			callback(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var delete_user_task = function(task_id, callback) {
		var user_id = $('#profile-header').data('user-id');
		$.ajax({ cache: false,
			url: "/user/"+user_id+"/delete_user_task",
			type:"DELETE",
			dataType: 'json',
			data: { task_id : task_id },
			success: function (response) {
				callback(response);
				
			},
			error: function (error) {
				//console.log(error);
			}
		});	
};

var get_user_task = function(callback) {
		$.ajax({ cache: false,
			url: "/user/overduetasks",
			dataType: 'json',
			success: function (response) {
				callback(response);
				
			},
			error: function (error) {
				//console.log(error);
			}
		});	
};

var get_available_user_games = function(callback) {
	$.ajax({ cache: false,
		url: "/games/get-available-games",
		dataType: 'json',
		success: function (response) {
			callback(response);

		},
		error: function (error) {
			//console.log(error);
		}
	});	
};

var start_user_game = function(callback){
	
};

var get_user_notifications = function(callback) {
	$.ajax({ cache: false,
		url: "/user/notifications",
		dataType: 'json',
		success: function (response) {
			callback(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});	
};

var delete_user_notification = function(notification_id, callback) {
	var user_id = $('#profile-header').data('user-id');
	$.ajax({ cache: false,
		url: "/user/"+notification_id+"/delete_user_notification",
		type:"DELETE",
		dataType: 'json',
		data: { notification_id : notification_id },
		success: function (response) {
			callback(response);	
		},
		error: function (error) {
			//console.log(error);
		}
	});	
};


var add_date_picker = function(target){
	$(target).daterangepicker({
		locale: {
		format: 'DD/MM/YYYY h:mm A' },
		"singleDatePicker": true,
		"timePicker24Hour": true,
		"timePicker": true,
		"timePickerIncrement": 10,
		"autoApply": true,
		"showCustomRangeLabel": false	
//		"startDate": "03/08/2017",
//		"endDate": "02/23/2017"
	});
};

$(document).ready(function () {
    
	$('#todo-container').on('click','.add_user_task', function () {
		var parent = $(this).closest('todo-list');
		var user_task = $(parent).find("input[name=task]").val();
		var due_date = $(parent).find("input[name=due-date]").val();
		add_user_task(user_task, due_date, function(response){
			var task_id = response.task_id;
			$('#todo-container').html(response.html);
			add_date_picker('.todo-date');	
			$("#todo-description").val('');
			$(".notification-contact").html(response.task_count);
			if(response.task_count > 0){
				$(".notification-contact").show();
			}
		});
	});
		
});

$(document).ready(function () {
	
	

	$("#show_notifications_icon").on('click',function(e){
		e.preventDefault();
		if($('#file_menu_notifications').is(':visible')){
			$('#file_menu_notifications').hide();
		}
		else {
			get_user_notifications(function(response){
					$('#file_menu_notifications').html(response.html);
					$('#file_menu_notifications').on('click','.delete-user-notification', function (e) {
						var notification_id = $(e.target).data("notification-id");
						delete_user_notification(notification_id, function(response){
							$("#notification-" +notification_id).remove();
							$(".notification-count").html(response.notification_count);
							if(response.notification_count == 0){
								$(".notification-count").remove();
								$("#show_notifications_icon").remove();
							}
						});					
					});
					$('#file_menu_notifications').fadeIn('medium');
				} 
			);
		}
	});

	$('.current-product-type-selction').on('click',function(){
		$('.profile-current-product-type').toggle();
	});

	$('.current-company-selction').on('click',function(){
		$('.profile-current-company-selection').toggle();
	});
	
	
	
    $("#show_tasks_icon").on('click',
		function (e) {
			e.preventDefault();
			if($('#user-task-notification-content').is(':visible')){
				$('#user-task-notification-content').hide();
				return;
			}else{
				get_user_task(function(response){
					$('#user-task-notification-content').show();
					$('#user-task-notification-content').html(response.html);
				} 
			);
		}
	});

	$('.user-task-notification-content').on('click', '.user-task-info', function(){
		$(this).parent().find('.user-task-edit-section').hide();
		$(this).parent().find('.user-task-info-section').toggle();
	});
	
	$('.user-task-notification-content').on('click', '.user-task-edit', function(){
		$(this).parent().find('.user-task-info-section').hide();
		$(this).parent().find('.user-task-edit-section').toggle();
	});

	$('.user-task-notification-content').on('click', '.edit-user-task', function(){
		
		var that = $(this);
		var parent = $(this).closest('.user-task-edit-section');
		var task_id = $(this).data('task-id');
		var task_title = $(parent).find(':input[name="task_title"]').val();
		var task_description = $(parent).find(':input[name="task_description"]').val();
		
		if(task_title.length < 2){
			error_message('Please Enter a descriptive Task Title');
			return false;
		}		
				
		var user_id = $(parent).find(':input[name="task_user_id"]').find(":selected").val();
		
		var due_date = $(parent).find(".todo-date").val();
		
		var data = {
			'task_id' : task_id,
			'task_title' : task_title,
			'task_description' : task_description,
			'user_id' : user_id,
			'due_date' : due_date,
			'view_style': 'user_notification'
		};
		
		$.ajax({
			url: '/user/edit/task',
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					if(checkErrors(response)){
						success_message('Task Updated');
						$('#user-task-notification-content').html(response.html);
					}
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
	});

	$('.user-task-notification-content').on('click', '.user-task-check', function(){
		
		var task_id = $(this).data('task-id');
		
		var that = $(this);
		
		$.ajax({
			url: '/user/toggle/notification-task/' + task_id,
			type:"GET",
			dataType: 'json',
			success: function(response) {
				if(checkErrors(response)){
					success_message('Task Updated');
					$('#user-task-notification-content').html(response.html);
					if(isset(response.task_count)){
						$('#show_tasks_icon .task-count-number').html(response.task_count);
					}
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
	});
	
	$(".show-below").on('click',function () {
			if($(this).find('.show-me-please').is(':visible')){
				$('.show-me-please').hide();
			}
			else{
				$('.show-me-please').hide();
				$(this).find('.show-me-please').show();
			}
//			if($('#file_menu_games').is(':visible')){
//				$('#file_menu_games').hide();
//				return;
//			}
//			else{
//				$('#file_menu_games').show();
//			}
//			get_available_user_games(function(response){
//				if(response.html == ''){
//					$('#file_menu_games').hide();
//					error_message('No incentives running - check again later');
//				}
//				else{
//					$('#file_menu_games').html(response.html);
//					$('#file_menu_games').show();
//					$('#file_menu_games').on('click','.start-game', function (e) {
//						var game_id = $(e.target).data("game-id");
//						start_user_game(game_id, function(response){
//
//						});					
//					});
//					$('#file_menu_tasks').fadeIn('medium');
//				}
//			} 
		//);
	});
	
	var test = true;
    $("#clickme").click(function () {
        if(test){
			$(this).parent().parent().animate({right:'0px'}, {queue: false, duration: 100});
		}
        else{
			$(this).parent().parent().animate({right:'-300px'}, {queue: false, duration: 100});                
        }      
       test= !test; 
	});
	$('#leadid-support').hide();
	$('#support-details').hide();
	$("#support-description").change(function () {
		var selected_option = $('#support-description option:selected').val();
		var text_area = $('#support-details');
		if (selected_option == "crm") {
			$('#leadid-support').show();
			text_area.show();
		}
		else {
			$('#leadid-support').hide();
			text_area.show();
		}
		if (selected_option == "feature") { 
			text_area.attr("placeholder", "Please let us know about your idea!");
		}
		else {
			text_area.attr("placeholder", "Please detail the issue you are having");
		}
	});
	

	var handle_sidebar_state = function(callback){
		
		var hidden = true;
		
		$(".sidebar-items-wrapper").each(function(){
			if($(this).is(':visible')){
				hidden =false;	
			}

		});
		if(hidden){
			$('p.list-group-item').find('#product-logo').click();
		}
		
	}
	$('.list-group.panel').on('click','.sidebar-items-wrapper-parent',function(){
		handle_sidebar_state();

		$(this).parent().children('.sidebar-items-wrapper').toggle();
		$(this).toggleClass('sidebar-menu-item-dark-blue');
		handle_sidebar_state();
	});
	
});
