
//set customer details for accident protection customer (NO API)
var set_additional_customer_details = function(quote_id, data, callback) {
	var that = this;
	$.ajax({
		url: "/order/setAccidentProtectionAdditionalCustomerDetails/"+quote_id,
		data: {
			'details' : data
		},
		method: 'POST',
		success: function(response){
			if(response.errors){
				that.throwError(response.errors);
			}else {
				callback(response);
			}				
		},
		error : function(error) {
			//console.log(error);
		}		
	});
}

//clecked to save additional contact information
$(document).ready(function(){
	var quote_id = $('#lead_script').data("quoteid");
	var customer_validator = new customerValidator();
	$('#lead_script').on('click','#additional_applicant_details',function(e){
		e.preventDefault();
		//console.log('About to post additional applicant details');
		var validation = [	
			//function(next) { customer_validator.validateEmploymentStatus($('#lead_script #contact-details-employment-status').val(),next); },
			//function(next) { customer_validator.validateOccupation($('#lead_script #contact-details-occupation').val(),next); },
			function(next) { customer_validator.validateTimeAtAddress($('#lead_script #contact-time-at-address').val(),next); },
			function(next) { customer_validator.validateEmployer($('#lead_script #contact-details-employer').val(),next); },
			function(next) { customer_validator.validateIndustry($('#lead_script #contact-details-industry').val(),next); },
			function(next) { customer_validator.validateRetirementAge($('#lead_script #contact-details-retirement-age').val(),next); },
			function(next) { customer_validator.validateMaritalStatus($('#lead_script #contact-details-marital-status').val(),next); },
			function(next) { customer_validator.validateNationality($('#lead_script #contact-details-nationality').val(),next); },
			function(next) { customer_validator.validateResidence($('#lead_script #contact-details-residence').val(),next); }
		];
		customer_validator.validateAll(validation,function(errors,data){
			//console.log(errors);
			//console.log(data);
			if(errors !== null){
				//console.log(errors);
				$.each(errors,function(index,error){
					$('#lead_script #'+error.target).html(error.message);
					//console.log(error.message);
				});
				$('#lead_script span.error-message').show();
				setTimeout(function(){ 
					$('#lead_script span.error-message').hide();
				}, 2000);
			}
			else{
				set_additional_customer_details(quote_id,data,function(response) {
					//console.log('about to move forward');
					window.lead_script.handleStep('accident-protection.dependants');
				});
			}
		});
	});

	//has been moved to fact find - may move again
	$("#lead_script").on('click',"#ML_assessed_special_care",function(e){
		var accident_protection_assessed_special_care = $('#ML_assessed_special_care').prop('checked');
		var quoteID = window.lead_script.quoteId;
		$.ajax({
			url: "/orders/updateSCA",
			type:"POST",
			dataType: 'json',
			data: {
					accident_protection_assessed_special_care: accident_protection_assessed_special_care,
					quoteID: quoteID
				  },
			cache: false,
			success: function(response){
				if(response.errors){
					//@TODO replace with modal
					alert('Problems with registering the SCA on the CRM; please notify IT Support');
				}else {
					if (accident_protection_assessed_special_care === true) {
						$('#fact-find-1-next').show();
					} 
					else {
						$('#fact-find-1-next').hide();
					}
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});	
	});
	
	//validate and update customer dob - now separate on introduction page
	$("#lead_script").on('change',".insurance-dob-capture #customer-details-dob-day, .insurance-dob-capture #customer-details-dob-month , .insurance-dob-capture #customer-details-dob-year",function(e){
		e.preventDefault();
		var validation = [
			function(next) { customer_validator.validateDOB(
				$('#lead_script #customer-details-dob-day').val(),
				$('#lead_script #customer-details-dob-month').val(),
				$('#lead_script #customer-details-dob-year').val()
				,next); }
		];
		customer_validator.validateAll(validation,function(errors,data){
			//console.log(errors);
			if(errors !== null){
				$.each(errors,function(index,error){
					$('#lead_script #'+error.target).html(error.message);
					//console.log(error.message);
				});
				$('#introduction-next').hide();
				$('#lead_script span.error-message').show();
				setTimeout(function(){ 
					$('#lead_script span.error-message').hide();
				}, 1000);
			}
			else{
				set_additional_customer_details(quote_id,data,function(response) {
					$('#introduction-next').show();
				});
			}
		});
		
	});
	
	
});
