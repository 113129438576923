
$(function() {

	if($('#insurance-renewals-table').length){
		let url = '/get/insurance/renewals';
		let init_start = $('#insurance-renewals-table').data('start');
		let init_end = $('#insurance-renewals-table').data('end');
		if(isset(init_start) && isset(init_end)){
			url = "/get/insurance/renewals?start="+init_start+"&end="+init_end;
		}
		const datatable = $('#insurance-renewals-table').DataTable({
			ajax: url,
			pageLength: 40,
			ordering: true,
			order: [[ 0, "desc" ]],
			responsive: true,
			bAutoWidth:false,
			autoWidth: false,
			lengthMenu: [25, 40, 100, 500],
			columnDefs: [
				{"type": "date", "targets": 5}
			],
			initComplete: function () {
					this.api().columns([3,4, 7]).every( function () {
					var column = this;
					var title = $(column.header()).html();
					var select = $('<br><select style="text-align: center; margin-right:20px; border-radius:4px; color:black; width:100%;"><option></option></select>')
						.on( 'change', function () {
							var val = $.fn.dataTable.util.escapeRegex(
								$(this).val()
							);
	
							column
								.search( val ? '^'+val+'$' : '', true, false )
								.draw();
						} );
					column.data().unique().sort().each( function ( d, j ) {
					if(column.search() === '^'+d+'$'){
						select.append( '<option value="'+d+'" selected="selected">'+d+'</option>' )
					} 
					else {
						select.append( '<option value="'+d+'">'+d+'</option>' )
					}
					} );
					$(column.header()).html('<span>'+title+'</span>');
					$(column.header()).append(select);
				});
			}
		});
		if($('input[name="insurance-renewals-date-filter"]').length > 0){
			$('input[name="insurance-renewals-date-filter"]').daterangepicker({
	
			"locale": {
				"format": 'DD/MM/YYYY',
				"separator": "-"
			},
			"linkedCalendars": false,
			"showDropdowns": true
			},
			function(start, end, label) {
				url = "/get/insurance/renewals?start="+start+"&end="+end;
				//console.log("Ok, changing date range...");
				$('#insurance-renewals-table tbody').html('');
				const datatable = $('#insurance-renewals-table').DataTable({
					destroy: true,
					dom: 'flrtiBp',
					ajax: url,
					pageLength: 40,
					ordering: true,
					order: [[ 0, "desc" ]],
					responsive: true,
					bAutoWidth:false,
					autoWidth: false,
					lengthMenu: [25, 40, 100, 500],
					columnDefs: [
						{"type": "date", "targets": 5}
					],
					initComplete: function () {
						this.api().columns([3,4, 7]).every( function () {
							var column = this;
							var title = $(column.header()).find('span').html();
							var select = $('<br><select style="text-align: center; margin-right:20px; border-radius:4px; color:black; width:100%;"><option></option></select>')
								.on( 'change', function () {
									var val = $.fn.dataTable.util.escapeRegex(
										$(this).val()
									);

									column
										.search( val ? '^'+val+'$' : '', true, false )
										.draw();
								} );
							column.data().unique().sort().each( function ( d, j ) {
							if(column.search() === '^'+d+'$'){
								select.append( '<option value="'+d+'" selected="selected">'+d+'</option>' )
							} 
							else {
								select.append( '<option value="'+d+'">'+d+'</option>' )
							}
							} );
							$(column.header()).html('<span>'+title+'</span>');
							$(column.header()).append(select);
						});
					}
				});
	
			});
		}
	}

	var insurance_renewals_calendar = false;

	// var loadInsuranceRenewalsCalendar = function(){
	// 	makeRequest('/insurance/renewals/get-renewals-by-month', {month:'January'}, function(response){
	// 		if(isset(response.success) && response.success && isset(response.events)){
	// 			$.each(response.events, function(i, event){
	// 				insurance_renewals_calendar.addEvent(event);
	// 			});
	// 		}
	// 		else{
	// 			var error = 'unable to load renewals';
	// 			if(isset(response.error)){
	// 				error = response.error;
	// 			}
	// 			error_message(error);
	// 		}
	// 	});
	// };

	var clickedRenewalDay = function(info){
		var renewal_date = info.event.extendedProps.renewal_date;

		var data = {
			'renewal_date' : renewal_date
		};

		// lets just take the user to a new page. 
		var url = '/insurance/renewals?renewal_date='+renewal_date;
		window.location.href = url;
		return;
	};

	if($('#insurance-renewals-by-month_calendar').length && isset(FullCalendar)){
		let initial_date = $('#insurance-renewals-by-month_calendar').data('initial_date');
		if(!isset(initial_date)){
			initial_date = moment().format('YYYY-MM-DD');
		}
		insurance_renewals_calendar = new FullCalendar.Calendar($('#insurance-renewals-by-month_calendar')[0], {
			'initialView': 'dayGridMonth',
			 headerToolbar: {
				start: 'dayGridMonth,timeGridWeek,timeGridDay',
				center: 'title',
				end: 'prev,next',
			},
			'eventClick' : clickedRenewalDay,
			'selectable': true,
			events: {
				url: '/insurance/renewals/get-renewals-by-month',
				method: 'POST',
				extraParams: function(){
					return {
						month: 'January',
						}
					}	
				,
				failure: function() {
				alert('there was an error while fetching events!');
				},
				color: 'yellow',   // a non-ajax option
				textColor: 'black' // a non-ajax option
			}
		});
		insurance_renewals_calendar.render();
	}
});