var shareReportUpdate  = function(report_id,items){
	var data = {
		'report_id': report_id,
		'items': items
	};
	makeRequest('/reports/share-report', data, function (response) {
		if (isset(response.success) && response.success) {
			success_message('You have shared a report');
			$('.reports-list-container').html(response.html);
			hideLoader();
		}
		else {
			if (isset(response.error)) {
				hideLoader();
				error_message(response.error);
			}
			else {
				hideLoader();
				error_message('Unable to get reports');
			}
		}
	});
};

var findTeamPosition = function () {
	var pos = 1;
	$.each($('#report-table').find('tr').find('th'), function (i, column) {
		if($(column).text() === 'Team') {
			pos = i;
		}
	});
	return pos;
};

var add_date_picker = function(target){
	$(target).daterangepicker({
		locale: {
			format: 'DD/MM/YYYY h:mm A' },
		"singleDatePicker": true,
		"timePicker24Hour": true,
		"timePicker": true,
		"timePickerIncrement": 10,
		"autoApply": true,
		"showCustomRangeLabel": false
		//		"startDate": "03/08/2017",
		//		"endDate": "02/23/2017"
	},function(start,end,label){
		$(target).val(start.format('D, MMMM, YYYY'));
	});
};

var reportAttributeMethods = {
	'custom-range' : function (wrapper) {
		console.log('here');
		console.log(wrapper);
		var dates = {};
		dates['type'] = 'custom';
		dates['from-date'] = $(wrapper).find('#custom-from-date').val();
		dates['to-date'] = $(wrapper).find('#custom-to-date').val();

		return dates;
	},
	'specific-date' : function (wrapper) {
		var dates = {};
		dates['type'] = 'specific';
		dates['from'] = $(wrapper).find("input[name='specific-from']").val();
		dates['from-metric'] = $(wrapper).find('#specific-from').find("select[name='specific-from-metric'] :selected").text();

		dates['to'] = $(wrapper).find("input[name='specific-to']").val();
		dates['to-metric'] = $(wrapper).find('#specific-to').find("select[name='specific-to-metric'] :selected").text();

		return dates;
	},
	'predefined-date' : function (wrapper) {
		var dates = {};
		dates['type'] = 'predefined';
		dates['from-date'] = $(wrapper).find("input[name='predefined-from-date']").val();
		dates['to-date'] = $(wrapper).find("input[name='predefined-to-date']").val();

		return dates;
	}
};

var report_sortable = function() {
	$( "ul[id^=reportsort_], ul[id^=fieldsort_]" ).sortable({
		connectWith: ".reportSortable",
//		appendTo: "body",
//		helper: "clone",
//		containment: "window",
		update: function (event, ui) {
			if($(event.target).attr('id') === 'reportsort_1' && $(event.target).hasClass('reportSortable')){
				var fields_in_report = [];
				var report_id = $('#report-field-link-container').data('report_id');
				$('#edit-report-field-links ul li').each(function(){
					fields_in_report.push({
						'link_id': $(this).data('field-link'),
						'field_id': $(this).data('field-id')
					});
				});
				update_report(report_id,fields_in_report, $(this));
			}
		}
	}).disableSelection();
};

var consoleLog = function () {
	console.log('here');
};

var report_attributes_sortable = function () {
	$( "ul#attributesort, .add-report-attribute-landing" ).sortable({
		appendTo: "body",
		helper: "clone",
		containment: "window",
		sort: function(e) {
			// $('.add-report-attribute-landing').toggle();
		},
		connectWith: ".reportAttributesSortable",
		update: function (event, ui) {
			// $('.add-report-attribute-landing').hide();
			if($(event.target).hasClass('reportAttributesSortable')){
				var report_id = $('#edit-report-attribute-links').data('report-id');
				var attribute_id = ui.item.attr('id');
				ui.item.remove();
				update_report_attributes(report_id, attribute_id, 'add');
			}
		},
		revert: function(event, ui) {
			// $(event.target).hide();
			// $('.add-report-attribute-landing').hide();
			return true;
		},
		onDrop: function(event, ui) {
			// $(ui.item).hide();
			// $('.add-report-attribute-landing').hide();
		},
	}).disableSelection();
};

var unlink_field = function(report_id, field_id) {
	$.ajax({
		type: "POST",
		dataType: 'json',
		data: {
			field_id : field_id,
			report_id : report_id
		},
		url: '/reports/unlink-field',
		success: function(response) {
			if(!response.success) {
				error_message(response.error);
			}
			else {
				$('#edit-report-field-links').html(response.html);
				$('.update-error').hide(); //this is here just incase the errors are actually visible
				$('.update-success').fadeIn().text('Updated');
				setTimeout(function () {
					$('.update-success').fadeOut();
				}, 5000);
				// $('#edit-report-field-links ul li[data-field-link='+ field_id +']').remove();
				report_sortable();
				report_attributes_sortable();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var update_report = function(report_id,fields_in_report, selector) {
	$.ajax({
		type: "POST",
		dataType: 'json',
		data: {
			fields_in_report : fields_in_report
		},
		url: '/reports/update-report/'+report_id,
		success: function(response) {
			if(!response.success) {
				error_message(response.error);
			}
			else {
				$('#edit-report-field-links').html(response.html);
				$('.update-success').fadeIn().text('Updated');
				setTimeout(function () {
					$('.update-success').fadeOut();
				}, 5000);
				$(selector).attr('data-link-id', response.link_id);
				$(selector).find('.delete-trigger').attr('data-link-id', response.link_id);
				$(selector).find('.edit-trigger').attr('data-link-id', response.link_id);
				report_sortable();
				report_attributes_sortable();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var update_report_attributes = function(report_id, attribute_id, type){
	$('.add-report-attribute-landing').hide();
	showLoader();
	$.ajax({
		type: "POST",
		dataType: 'json',
		data: {
            report_id: report_id,
			type: type,
			attribute_id : attribute_id
		},
		url: '/reports/update-report-attributes',
		success: function(response) {
			hideLoader();
			if(response.success){
				$('#report-field-links').html(response.html);
				report_sortable();
				report_attributes_sortable();
				$('.report-attributes-col').show();
				$('.report-edit-panel').show();
				$('.report-fields-col').hide();
				$('.report-preview-panel').hide();

				add_date_picker('#custom-from-date');
				add_date_picker('#custom-to-date');

				$('.select2.multi').select2({
					'tags': true,
					'tokenSeparators': [',', ' '],
					'width':'100%'
				});
			}
			else {
				if(isset(response.error)) {
					error_message(response.error);
				}
				else {
					error_message();
				}
			}
		},
		error: function (error) {
			error_message();
			hideLoader()
			//console.log(error);
		}
	});
};

var editReport = function (report_id) {
    $.ajax({
        url: '/reports/edit/'+report_id,
        dataType: 'json',
        success: function(response) {
            if(response.success){
				$('.report-edit-panel').html(response.html);
				$('.report-attributes-col').hide();
                $('.report-fields-col').show();
                report_sortable();
				report_attributes_sortable();
                $('.report-edit-panel').show();
                $('.report-preview-panel').hide();
            }
        },
        error: function (error) {
            //console.log(error);
        }
    });
};

var editGlobalReport = function (report_id) {
	showModal('editReportModal');

	$('#copy_report_confirm').data('report-id', report_id);
	$('#edit_global_report_confirm').data('report-id', report_id);
};

var editGlobalAttributes = function (report_id) {
	showModal('editReportAttributesModal');

	$('#copy_report_attributes_confirm').data('report-id', report_id);
	$('#edit_global_report_attributes_confirm').data('report-id', report_id);
};

var editReportAttributes = function(report_id) {
	var data = {
		'report_id': report_id,
	};

	makeRequest('/reports/show-attributes', data, function (response) {
		if(response.success){
			$('#report-field-links').html(response.html);
			$('.report-attributes-col').show();
			report_sortable();
			report_attributes_sortable();
			$('.report-edit-panel').show();
			$('.report-fields-col').hide();
			$('.report-preview-panel').hide();

			add_date_picker('#custom-from-date');
			add_date_picker('#custom-to-date');

			$('.select2.multi').select2({
				'tags': true,
				'tokenSeparators': [',', ' '],
				'width':'100%'
			});
		}
		else {
			if(response.error) {
				error_message(response.error);
			}
			else {
				error_message();
			}
		}
	}, 'GET');
};

var showSchedule = function (report_id) {
	var data = {
		'report_id': report_id
	};
	makeRequest('/reports/show-schedules', data, function (response) {
		if (response.html) {
			$('#report-field-links').html(response.html);
			$('.report-attributes-col').show();
			report_sortable();
			report_attributes_sortable();
			$('.report-edit-panel').show();
			$('.report-fields-col').hide();
			$('.report-preview-panel').hide();
		}
		else {
			if(response.error) {
				error_message(response.error);
			}
			else {
				error_message();
			}
		}
	}, 'GET');
};

var refreshHelpText = function (target = false) {
	$.each($('.parameters-help-text-container').find('.parameters-help-text'), function (i, helper) {
		$(helper).hide();
		if(target) {
			if ($(helper).data('for') == target) {
				$(helper).show();
			}
		}
	});
};

$(document).ready(function() {

	// $('body').on('mousedown', '.attribute-single-container', function () {
	// 	$('.add-report-attribute-landing').show();
	// });

	$(".attribute-single-container")
		.mousedown(function() {
			$('.add-report-attribute-landing').show();
		})
		.mouseup(function() {
			$('.add-report-attribute-landing').hide();
	});

	$('body').on('click', '#edit_global_report_attributes_confirm', function () {
		var report_id = $(this).data('report-id');
		editReportAttributes(report_id);
	});

	$('body').on('click', '#edit_global_report_confirm', function () {
		var report_id = $(this).data('report-id');
		editReport(report_id);
	});

	if($('#reports').length){
		report_sortable();
		report_attributes_sortable();
	}

	$('body').on('click', '.param-button', function () {
		$.each($('.param-button'), function (i, button) {
			$(button).removeClass('active');
		});

		if(!$(this).hasClass('active')) {

			var target = $(this).data('target');
			var container = $(this).closest('.parameters-lead-row');
			var report_id = $(container).data('report-id');

			if(isset(report_id) && target.length) {
				if (target == 'attributes') {
					editReportAttributes(report_id);
					$(this).addClass('active');
				}

				if (target == 'fields') {
					editReport(report_id);
					$(this).addClass('active');

				}
				if(target == 'schedule') {
					showSchedule(report_id);
					$(this).addClass('active');
				}
				refreshHelpText(target);
			}
		}
	});

	$('body').on('keyup', '.search-reports', function () {
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#teams-container ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		}
	});

	$('#reports').on('click', '.edit-trigger', function() {
		var that = $(this);
	    var report_id = $(that).data('report-id');

		refreshHelpText();

		if($(that).hasClass('is-global-report')) {
			editGlobalReport(report_id);
		}
		else {
			editReport(report_id);
        }

		$('.report-edit-panel').on('click', '.delete-trigger', function() {
			var to_remove = $(this).data('field-link');
			var report_id = $('#report-field-link-container').data('report_id');

			unlink_field(report_id,to_remove);
		});

		$('#report-field-links').on('keyup', '.search-field-links', function(){
			var valThis = $(this).val().toLowerCase();
			var target = $(this).parent().parent().find('#report-field-link-container ul');
			if(valThis === ""){
				$(target).find('li').show();
			} 
			else {
				$(target).find('li').each(function(){
					var text = $(this).text();
					var compare = text.toLowerCase();
					(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
				});
			}
		});

		$('.report-edit-panel').on('keyup','#report-fields .search-fields',function(){
			var valThis = $(this).val().toLowerCase();
			var collapseIn = $('#report-field-container').children().children();
			var target = $(this).parent().parent().find('#report-field-container');
			if(valThis === ""){
				collapseIn.removeClass('in');
				$(target).find('li').show();
			} 
			else {
				$(target).find('li').each(function(){
					var text = $(this).text();
					var compare = text.toLowerCase();
					(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
					$(this).parent().parent().addClass('in');
				});
		   }
		});
	});

	$('body').on('click', '#save-report-attributes', function() {
		var that = $(this),
			container = $('.report-attributes-table'),
			error = true;
		// var start = $('input[name="date-schedule-report-timeframe"]').data('daterangepicker').startDate.format('x');
		// var end = $('input[name="date-schedule-report-timeframe"]').data('daterangepicker').endDate.format('x');
		var data = {
			'report_id': $(this).data('report-id'),
		};

		data['attributes'] = {};
		$.each($(container).find('.attribute-item'), function(i, attribute_container) {
			var input_data = {};
			var type = $(attribute_container).data('type'),
				attribute_id = $(attribute_container).data('id');

			if(type != 'window') {
				var input = $(attribute_container).find(':input:visible');
				if($(input).hasClass('select2')) {
					if ($(input).hasClass('multi')) {
						error = false;
						$(input).find(':selected:visible').each(function (i, option) {
							input_data[i] = [];
							if (error == false) {
								if (!$(option).val().length) {
									error = true;
								} 
								else {
									input_data[i] = $(option).val();
								}
							}
						});
					}
					else {
						error = false;
						input_data = $(input).val();
					}
				}
				else {
					error = false;
					if($(input).is(':checkbox')) {
						input_data = $(input).is(':checked') ? 1 : 0;
					}
					else {
						input_data = $(input).val();
					}
				}
			}
			else {
				var sub_type_wrapper = $(attribute_container).find('.time-inputs.active'),
					dates = reportAttributeMethods[$(sub_type_wrapper).attr('id')](sub_type_wrapper);

				if(isset(dates['type'])) {
					if(dates['type'] === 'custom') {
						if(dates['from-date'].length && dates['to-date'].length) {
							error = false;
							input_data = dates;
						}
					}
					if(dates['type'] === 'predefined') {
						if(dates['from-date'].length && dates['to-date'].length) {
							error = false;
							input_data = dates;
						}
					}
					if(dates['type'] === 'specific') {
						if(isset(dates['from']) && isset(dates['to']) && isset(dates['from-metric']) && isset(dates['to-metric'])) {
							if(dates['from'].length && dates['to'].length && dates['from-metric'].length && dates['to-metric'].length) {
								error = false;
								input_data = dates;
							}
						}
					}
				}
			}
			data['attributes'][attribute_id] = input_data;
		});

		if(!error) {

			makeRequest('/reports/save-attributes', data, function(response) {
				if(response.success){
					success_message();
				}
				else {
					if(response.error) {
						error_message(response.error);
					}
					else {
						error_message('An unknown error occurred');
					}
				}
			});

		}
		else {
			error_message('Error with your attribute selection');
		}
	});

	$('body').on('click', '.report-switch', function () {
		if($(this).val() == 1) {
			$(this).attr('value', 0);
		}
		else {
			$(this).attr('value', 1);
		}
	});

	$('#reports').on('click', '.attributes-trigger', function() {
		var that = $(this);
		var report_id = $(this).data('report-id');

		if($(that).hasClass('is-global-report')) {
			editGlobalAttributes(report_id);
		}
		else {
			editReportAttributes(report_id);
		}
	});

	var data_table = false;
	var team_pos = findTeamPosition();
	var buildReport = function(element, url){
		if(data_table){
			data_table.destroy();
		}
		data_table = $(element).DataTable({
			dom: 'flrtiBp',
			ajax: url,
			pageLength: 40,
			ordering: true,
			responsive: true,
			bAutoWidth:true,
			autoWidth: true,
			select: true,
			initComplete : function(settings, json){
				var cols = [];

				for(var row in json['data']){
					for(var col in json['data'][row]){
						if(isNaN(cols[col])){
							cols[col] = 0;
						}
						if(typeof(json['data'][row][col]) == "string") {
							cols[col] =+ 1;
						}
						else {
							cols[col] += parseInt(json['data'][row][col])
						}
					}
				}
				for(var i in cols){
					if (cols[i] == 0){
						try{
							if($("th[data-column='" + i +"']").hasClass('multi')){
								data_table.column(i).visible(false);
							}
						}
						catch(err){

						}
					}
				}

				if(json['data'].length == 0){
					for(var index = 0; index < 50; index++ )
					if($("th[data-column='" + index +"']").hasClass('multi')){
						data_table.column(index).visible(false);
					}
				}

				this.api().columns([team_pos]).every( function () {
					var column = this;
					if($(column.header()).find('span').length){
						var title = $(column.header()).find('span').text();
					}
					else{
						var title = $(column.header()).text();
					}

					var select = $('<br><select class="pdf-hide" style="text-align: center; margin-right:20px; border-radius:4px; color:black; width:100%;"><option></option></select>')
						.on( 'change', function () {
							var val = $.fn.dataTable.util.escapeRegex(
								$(this).val()
							);

							column
								.search( val ? ''+val+'' : '', true, false )
								.draw();
						} );
					column.data().unique().sort().each( function ( d, j ) {
						if (column.search() === '^' + d + '$') {
							select.append('<option value=\'' + $(d).text() + '\' selected="selected">' + $(d).text() + '</option>');
						} 
						else {
							select.append('<option value=\'' + $(d).text() + '\'>' + $(d).text() + '</option>');
						}
					});
					$(column.header()).html('<span>'+title+'</span>');
					$(column.header()).append(select);
				});
			},
			lengthMenu: [25, 40, 100],
			buttons: [
				'copyHtml5',
				'csv',
				{
					extend : 'print',
					title : $(element).data('report-title'),
					exportOptions: {
						columns: ':visible',
						stripHtml: false,
						format: {
							body: function ( data, row, column, node ) {
								var classes = $(node).attr('class');
								if(classes){
									return '<td class="'+classes+'" >' + data + '</td>';
								}
								return  data;
								
							}
						}
					},
					customize: function ( win,a ,b ) {
						win.close();
						showLoader();
						makeRequest('/download/pdf', {'html' : $(win.document.body).html()}, function (response) {
							if (response.success) {
								if (response.file) {
									hideLoader();
									success_message('PDF Generated');
	
									var print_frame = $("<iframe id='secret_iframe_4_printz' >").hide();
									$('body').append(print_frame);
									$('#secret_iframe_4_printz').attr("src", window.location.origin + '/download/raw/' + response.file);
									setTimeout(function(){
										$('#secret_iframe_4_printz')[0].contentWindow.print();
									}, 500);
								}
							} 
							else {
								error_message(response.errors);
								hideLoader();
							}
							hideLoader();
						});
					}
				},
				{
					text : 'Print Landscape',
					extend : 'print',
					title : $(element).data('report-title'),
					exportOptions: {
						columns: ':visible',
						stripHtml: false,
						format: {
							body: function ( data, row, column, node ) {
								var classes = $(node).attr('class');
								if(classes){
									return '<td class="'+classes+'" >' + data + '</td>';
								}
								return  data;
								
							}
						}
					},
					customize: function ( win,a ,b ) {
						win.close();
						showLoader();
						makeRequest('/download/pdf', {'html' : $(win.document.body).html(), 'orientation' : 'landscape'}, function (response) {
							if (response.success) {
								if (response.file) {
									hideLoader();
									success_message('PDF Generated');
	
									var print_frame = $("<iframe id='secret_iframe_4_printz' >").hide();
									$('body').append(print_frame);
									$('#secret_iframe_4_printz').attr("src", window.location.origin + '/download/raw/' + response.file);
									setTimeout(function(){
										$('#secret_iframe_4_printz')[0].contentWindow.print();
									}, 500);
								}
							} 
							else {
								error_message(response.errors);
								hideLoader();
							}
							hideLoader();
						});
					}
				},
			]
		});
	};
	
	if($('.reports-table').length){
		var current_url = window.location.href;
		buildReport($('.reports-table'), current_url);
	}

	if($('input[name="date-filter-reports"]').length > 0){
	//date picker for changing order filter range (separate reload, may want to do more with this)
	$('input[name="date-filter-reports"]').daterangepicker({

		"locale": {
			"format": 'DD/MM/YYYY HH:mm A',
			"separator": " - "
		},
		"timePicker": true,
		"linkedCalendars":false,
		"showDropdowns": true
		},
		function(start, end, label) {

			//console.log("Ok, changing date range...");
			var product_type = $('#product_type_picker').val();
			buildReport($('.reports-table'), current_url + "?start="+start+"&end="+end+'&ptype='+product_type)
		});
	}
	
	//onchange for product type picker
	$('#product_type_picker').change(function() {
		var product_type = $(this).val();
		var start = $('input[name="date-filter-reports"]').data('daterangepicker').startDate.format('x')
		var end = $('input[name="date-filter-reports"]').data('daterangepicker').endDate.format('x')
		buildReport($('.reports-table'), current_url + "?start="+start+"&end="+end+'&ptype='+product_type);
		
	});

	$('.report-edit-panel').on('click', '#report-fields .edit-trigger', function() {
		var field_id = $(this).data('field');

		makeRequest('/views/modal/edit-report-field-attributes-modal', {'report_field_id' : field_id}, function(response){
			if(response.html){
				createModal('edit_report_field_attributes_modal', response.html);
				success_message();
			}
			else{
				error_message('oh no...');
			}
		})
	});

	$('body').on('click', '#edit_report_field_attributes_modal #next', function(){
		var modal_content = $(this).closest('.modal-content');
		var name = $(modal_content).find('.name-input').val();

		if(name.length < 4) { //wrap is only 4 chars
			error_message('please enter a descriptive name');
			return false;
		}

		var type = $(modal_content).find('.type-input').val();
		var attributes = [];
		var editable = [];
		$(modal_content).find("input[name='has_attribute']:checked").each(function() {
			attributes.push($(this).val());
		});
		$(modal_content).find("input[name='is_editable']:checked").each(function() {
			editable.push($(this).val());
		});

		var id = $(this).data('id');
		var data = {
			'id' : id,
			'name': name,
			'type': type,
			'attributes': attributes,
			'ediable_attributes': editable
		};
		var that = this;

		makeRequest('reports/field_attributes/edit', data, function(response){
			if(response.success && response.html) {
				modal_content.find('.values-section').html(response.html).collapse('show');
				modal_content.find('.config-section').collapse('hide');
				$(that).text('update');
				$(that).attr('id', 'save_values');
				$(that).data('input-id', response.input_id);
				$('.select2').select2({
					'width': '100%'
				});
				$('.select2.mutli').select2({
					'tags': true,
					'tokenSeparators': [',', ' '],
					'width':'100%'
				});
				success_message();
			}
			else{
				error_message(response.error);
			}
		});
	});

	var readAttributeValues = function(form){
		var form_data = form.serializeArray();
		var data = {};

		$.each(form_data, function(i, serialized_data) {
			if(isset(data[serialized_data['name']])){
				if(typeof(data[serialized_data['name']]) != 'object'){
					data[serialized_data['name']] = [data[serialized_data['name']]];
				}
				data[serialized_data['name']].push(serialized_data['value']);
			}
			else{
				data[serialized_data['name']] = serialized_data['value'];
			}
		});

		form = $(form).closest('form');
		//unchecked checkboxes do not get returned as part of serializeArray -- because that makes all the sense. So lets do that by hand
		var uncheckedboxes = $(form).find(':input[type="checkbox"]:not(:checked)');
		$.each(uncheckedboxes, function(i, checkbox){
			data[$(checkbox).attr('name')] = 0;
		});

		//handle range attributes
		var modal = $('#edit_report_field_attributes_modal, #report_field_attribute_edit_modal');
		var form = modal.find('form');
		var range_items = form.find('.range-items');
		$.each(range_items, function(i, range_item){
			var range_data = {};
			$.each($(range_item).find('.range-item'), function(x, action_item){
				range_data[x] = {};
				$.each($(action_item).find(':input'), function(k, action_input){
					range_data[x][$(action_input).data('name')] = $(action_input).val();
				});
			});
			data[$(range_item).data('attribute-name')] = range_data;
		});

		if(isset(data['disposition_type'])){

			data['disposition'] = {
				'type' : data['disposition_type'],
				'value' : data['disposition_value']
			}
		}
		return data;
	};

	$('body').on('click', '#add_new_range_item', function(e){
		e.preventDefault();
		var parent = $(this).parent();
		var template = parent.find('.range-item-template');
		var html = template.html();
		parent.find('.range-items').append(html);
	});

	$('body').on('click', '.range-items .delete-trigger', function(e){
		e.preventDefault();
		var parent = $(this).parent();
		parent.remove();
	});

	$('body').on('click', '#edit_report_field_attributes_modal #save_values', function() {
		var field_id = $(this).data('id');
		var modal_content = $(this).closest('.modal-content');
		var form = $(modal_content).find('#attribute-table').find('form :input:visible');
		var data = readAttributeValues(form);
		data.field_id = field_id;
		var that = this;

		makeRequest('reports/field_attributes/update', data, function(response){
			if(checkErrors(response)) {
				var modal = $(that).closest('.modal');
				$(modal).modal('hide');
				$(modal).find('.values-section').html('');
				success_message();
			}
		});
	});

	$('body').on('click', '#report-field-links .edit-trigger', function(){
		var report_id = $('#report-field-link-container').data('report_id');
		var field_id = $(this).data('field-link');
		var data = {
			'report_id' : report_id,
			'field_id' : field_id
		};

		makeRequest('reports/field_attributes/edit_existing_attributes', data, function(response){
			if(response.success){
				if(response.html){
					createModal('report_field_attribute_edit_modal', response.html);
				}
				else{
					error_message('no editable attributes');
				}
			}
			else{
				error_message(response.error);
			}
			hideLoader();
		});
	});

	$('body').on('click', '#report_field_attribute_edit_modal #save_values', function(){
		var link_id = $(this).data('id');
		var report_id = $('#edit-report-field-links').data('report-id');
		var modal_content = $(this).closest('.modal-content');
		var form = $(modal_content).find('#attribute-table').find('form :input:visible');
		var form_data = readAttributeValues(form);
		var data = {
			'link_id' : link_id,
			'form_data' : form_data,
			'report_id' : report_id
		};

		var that = this;
		makeRequest('reports/field_attributes/update_existing_attributes', data, function(response){
			if(response.success){
				success_message();
				$(that).closest('.modal').modal('hide');
				$('#edit-report-field-links').html(response.html);
				report_sortable();
				report_attributes_sortable();
			}
			else{
				error_message(response.error);
			}
			hideLoader();
		});
	});

	$('#reports').on('click', '#add-question', function(e){
		showLoader();
		var container = $(this).closest('.action-group-create');
		var input = $(container).find('input');
		var data = {
			'name' : input.val()
		};

		makeRequest('reports/create', data, function(response){
			if(isset(response.html)){
				$('.reports-list-container').html(response.html);
				editReport(response.new_report_id);
				success_message();
				hideLoader();
			}
			else{
				var error = 'please refresh and try again';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})
	});

	$('body').on('click', '#edit-report-description', function(e){
		showLoader();
		var container = $(this).closest('.trigger-group-update');
		var input = $(container).find('input');
		var data = {
			'description' : input.val(),
			'report_id' : $(this).data('report-id')
		};

		makeRequest('reports/edit_description', data, function(response){
			if(isset(response.html)){
				$('.reports-list-container').html(response.html);
				success_message();
				hideLoader();
			}
			else{
				var error = 'please refresh and try again';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
				hideLoader();
			}
		});
	});

    $('body').on('click', '#edit-report-name', function(e){
		showLoader();

		var container = $(this).closest('.trigger-group-update');
        var input = $(container).find('input');
        var data = {
            'name' : input.val(),
            'report_id' : $(this).data('report-id')
        };

        makeRequest('reports/edit_name', data, function(response){
            if(isset(response.html)){
				$('.reports-list-container').html(response.html);
                success_message();
            }
            else{
                var error = 'please refresh and try again';
                if(isset(response.error)){
                    error = response.error;
                }
                error_message(error);
            }
        });
		hideLoader();
	});

	$('body').on('click', '#edit-report-type', function(e){
		var container = $(this).closest('.trigger-group-update');
		var input = $(container).find('select');
		var data = {
			'type_id' : input.val(),
			'report_id' : $(this).data('report-id')
		};

		makeRequest('reports/edit_type', data, function(response){
			if(isset(response.success) && response.success){
				success_message();
			}
			else{
				var error = 'please refresh and try again';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})
	});

	$('body').on('click', '#edit-report-range', function(e){
		var container = $(this).closest('.trigger-group-update');
		var data = {
			'start_date' : $(container).find('input[name="start_date"]').val(),
			'end_date' : $(container).find('input[name="end_date"]').val(),
			'report_id' : $(this).data('report-id')
		};

		makeRequest('reports/edit-range', data, function(response){
			if(isset(response.success) && response.success){
				success_message();
			}
			else{
				var error = 'please refresh and try again';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})
	});

	$('body').on('change', 'select[name="disposition_type"]', function(){
		var parent = $(this).closest('.rule-group-edit');
		parent.find('select').hide();
		parent.find('select.' + $(this).val()).show();
		$(this).show();
	});
	
	$('body').on('click', '.print-report', function(){

		var report_id = $(this).data('report-id');

		$('.report-edit-panel').hide();
		$('.report-preview-panel').show();

		makeRequest('/reports/build_table', {'report_id' : report_id}, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				success_message();
				$('#report_view').html(response.html);
				buildReport($('.reports-table'), '/reports/' + report_id);
			}
			else{
				var error = 'please refresh and try again';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});

	});

	$('body').on('change', '.report-global-visibility', function () {
		if($(this).val() == 1) {
			var report_id = $(this).data('report-id');

			if (isset(report_id)) {
				var data = {
					'report_id': report_id
				};
				makeRequest('/reports/promote-report', data, function (response) {
					if (isset(response.success) && response.success) {
						if(response.html) {
							$('.reports-list-container').html(response.html);
							success_message();
						}
					} 
					else {
						if (isset(response.error)) {
							error_message(response.error);
						} 
						else {
							error_message('Unable to configure global visibility');
						}
					}
				});
			} 
			else {
				error_message('Report ID not supplied to server');
			}
		}
	});

	$('body').on('click', '.clone-report-trigger', function () {
		showLoader();
		var report_id = $(this).data('report-id');
		var report_name = $(this).data('report-name');

		if (isset(report_id)) {
			var data = {
				'report_id': report_id
			};
			makeRequest('/reports/clone-report', data, function (response) {
				if (isset(response.success) && response.success) {
					if(response.html) {
						$('.reports-list-container').html(response.html);
						success_message('Copied: ' + report_name + ' to your reports');

						if(isset(response.new_report_id)) {
							editReport(response.new_report_id);
						}
						hideLoader();
					}
				}
				else {
					if (isset(response.error)) {
						hideLoader();
						error_message(response.error);
					}
					else {
						hideLoader();
						error_message('Unable to clone report');
					}
				}
			});
		}
		else {
			hideLoader();
			error_message('Report ID not supplied to server');
		}
	});

	$('body').on('click', '.share-report-trigger', function () {
		var report_id = $(this).data('report-id');
		var report_name = $(this).data('report-name');

		var data = {
			'report_id': report_id,
			'all_users': 'firstname'
		};
		buildDynamicModal('dynamic_report_share_modal', data);
		// showModal('shareReportModal');
		// $('#confirm-share-report-modal-title').html(report_name);
		// $('#confirm-share-report').data('report-id', report_id);
	});

	$('body').on('click', '#save_report_shared_users', function () {
		showLoader();
		var report_id = $(this).data('report-id');
		if (isset(report_id)) {
			var rows = $('.shared-users-container-row');
			var items = {};

			$.when(
				$.each(rows, function (index, row) {
					if (!$(row).hasClass('template')) {
						if (!isset(items[index])) {
							items[index] = {};
						}
						var editable_val = 0;
						if ($(row).find('.shared-user-editable-input').is(":checked")) {
							editable_val = 1;
						}
						items[index] = {
							'user_id': $(row).find('.shared-user-id').data('id'),
							'editable': editable_val
						};
					}
				})
			).then(function () {
				shareReportUpdate(report_id,items);
			});
		}
		else {
			hideLoader();
			error_message('Report ID not supplied to server');
		}
	});

	$('body').on('click', '.delete-report-trigger', function () {
		showLoader();
		var report_id = $(this).data('report-id');
		var report_name = $(this).data('report-name');

		if (isset(report_id)) {
			var data = {
				'report_id': report_id
			};
			makeRequest('/reports/delete-report', data, function (response) {
				if (isset(response.success) && response.success) {
					if(response.html) {
						$('.reports-list-container').html(response.html);
						success_message('Deleted Report: ' + report_name);

						if(isset(response.new_report_id)) {
							editReport(response.new_report_id);
						}
						hideLoader();
					}
				}
				else {
					if (isset(response.error)) {
						hideLoader();
						error_message(response.error);
					}
					else {
						hideLoader();
						error_message('Unable to delete report');
					}
				}
			});
		}
		else {
			hideLoader();
			error_message('Report ID not supplied to server');
		}
	});

	$('body').on('click', '.restore-report-trigger', function () {
		showLoader();
		var report_id = $(this).data('report-id');
		var report_name = $(this).data('report-name');

		if (isset(report_id)) {
			var data = {
				'report_id': report_id
			};
			makeRequest('/reports/restore-report', data, function (response) {
				if (isset(response.success) && response.success) {
					if(response.html) {
						$('.reports-list-container').html(response.html);
						$('.deleted-reports-container').show();
						success_message('Restored Report: ' + report_name);

						if(isset(response.new_report_id)) {
							editReport(response.new_report_id);
						}
						hideLoader();
					}
				}
				else {
					if (isset(response.error)) {
						hideLoader();
						error_message(response.error);
					}
					else {
						hideLoader();
						error_message('Unable to restore report');
					}
				}
			});
		}
		else {
			hideLoader();
			error_message('Report ID not supplied to server');
		}
	});

	$('body').on('click', '.show-reports-container', function () {
		var that = $(this),
			container = $(this).next();

		if($(container).hasClass('hidden-reports-container')) {
			$(container).toggle();
			$(that).toggleClass('report-container-margin-bottom');
		}
	});

	$('body').on('click', '.remove-report-attribute', function () {
		showLoader();
		var attribute_id = $(this).data('attribute-id');

		var data = {
			'attribute_id': attribute_id,
			'report_id': $('#edit-report-attribute-links').data('report-id')
		};
		$.ajax({
			url: '/reports/remove-attributes',
			data: data,
			type: 'POST',
			dataType: 'json',
			success: function(response) {
				if(response.success){
					$('#report-field-links').html(response.html);
					$('.report-attributes-col').show();
					report_sortable();
					report_attributes_sortable();
					$('.report-edit-panel').show();
					$('.report-fields-col').hide();
					$('.report-preview-panel').hide();

					add_date_picker('#custom-from-date');
					add_date_picker('#custom-to-date');

					$('.select2.multi').select2({
						'tags': true,
						'tokenSeparators': [',', ' '],
						'width':'100%'
					});
				}
				else {
					if(isset(response.error)) {
						error_message(response.error);
					}
					else {
						error_message();
					}
				}
				hideLoader()
			},
			error: function (error) {
				error_message();
				hideLoader()
				//console.log(error);
			}
		});
	});

	$('body').on('click', '.edit-schedule', function () {
		var report_id = $(this).data('report-id');
		showSchedule(report_id);
	});

	$('body').on('click','.delete_report_schedule',function(){
		var schedule_id = $(this).parent().data('schedule_item_id');
		var parent = $(this).parent();
		// perform ajax to get data and html
		$.ajax({
			type: 'POST',
			url: '/reports/schedule/delete-item',
			data: { schedule_id : schedule_id },
			dataType: 'json',
			success: function(response) {
				if(response.success){
					parent.remove();
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});

	$('body').on('click', '.add_report_schedule', function() {
		var schedule_id =  $(this).parent().data('schedule_id');
		$.ajax({
			data: {
				'schedule_id' :schedule_id
			},
			type: 'POST',
			url: '/reports/schedule/add-item',
			dataType: 'json',
			success: function(response) {
				if(response.success){$('#reports-schedule-container').find('.schedule-group').append(response.html);
				}
			},
			error: function (error) {

			}
		});
	});

	$('body').on('click', '.save_report_schedule', function() {
		var schedule_id = $(this).parent().data('schedule_id');
		var container = $(this).parent().parent();
		var data = [];
		container.find('.scheduler-container').each(function(){
			var item_id = $(this).data('schedule_item_id');
			var obj = {item_id:item_id};
			$(this).find(':input').each(function(){
				var key = $(this).attr('name');
				obj[key] = $(this).val();
			});
			data.push(obj);
		});
		$.ajax({
			data: {schedule_id:schedule_id,data : data},
			type: 'POST',
			url: '/reports/schedule/save',
			dataType: 'json',
			success: function(response) {
				if(response.success){
					$('#rule-value-edit').html(response.html);
					$('.update-error').hide();
					$('.update-success').fadeIn().text('Schedule saved');
					setTimeout(function(){
						$('.update-success').fadeOut();
					},5000);
					$('#actions-schedule-container').hide();
				}
			},
			error: function (error) {
//				console.log(error);
			}
		});
	});

	$('body').on('select2:select', function(e) {
		var item = e.params.data;
		var new_container = $('.shared-users-container-row.template').clone().appendTo('.shareable-records');

		$(new_container).find('.shared-user-id').attr('data-id', item.id);
		$(new_container).find('.shared-user-id').text(item.id);
		$(new_container).find('.shared-user-name').text(item.text);
		$(new_container).removeClass('template').show();
	});

	$('body').on('select2:unselect', function(e) {
		var item = e.params.data;

		$('.shared-users-container').find("div[data-id='"+item.id+"']").parent().remove();
	});

	$('.report-product-type-picker').select2({'width':'300px'});

	var trigger_report_upload = function(report_id){
		makeRequest('static-reports/upload-panel-content/'+report_id,{},function(response){
			if(isset(response.success) && response.success){
				$('#report_upload_panel').html(response.html);
				$('.static-report-upload-panel').show();
				$("div#static_report_file_upload_dropzone").dropzone({
					 url: "/static-reports/files/upload-data/"+report_id,
					 'autoProcessQueue':false,
					 'uploadMultiple':true,
					 'parallelUploads':10,
					 'addRemoveLinks':true,
					 headers: {
     				   'X-CSRF-TOKEN': $('body').data("token")
    				},
					init: function() {
						var static_report_upload = this;
						// First change the button to actually tell Dropzone to process the queue.

						$('#upload_static_report_file').on('click',function(e){
							showLoader();
						// Make sure that the form isn't actually being sent.
							e.preventDefault();
							e.stopPropagation();
							static_report_upload.processQueue();
						});
						static_report_upload.on("successmultiple", function(multiple,response) {
							if(isset(response.message)){
								success_message(response.message);
							}
							$('.static-report-upload-panel').hide();
							hideLoader();
							$('#report_upload_panel').html('');
							// rebuild uploads list 
							trigger_report_upload(report_id);
						});
					},
					'accept' : function(file, done) {
						$('.instruction-to-drop').hide();
						$('.dz-success-mark').hide();
						$('.dz-error-mark').hide();
						let html_preview = '';
						if(file.type == 'application/pdf'){
							html_preview = '<div class="file-icon-container"><i class="fa-solid fa-file-pdf large-icons"></i></div>';
						}
						else if (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
							html_preview = '<div class="file-icon-container"><i class="fa-solid fa-file-spreadsheet large-icons"></i></div>';
						}
						else if(file.type == 'text/csv'){
							html_preview = '<div class="file-icon-container"><i class="fa-solid fa-file-csv large-icons"></i></div>';
						}
						else if(file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
							html_preview = '<div class="file-icon-container"><i class="fa-solid fa-file-word large-icons"></i></div>';
						}
						if(html_preview.length){
							$(file.previewElement).find('.dz-image').html(html_preview);
						}
						return done();
					}				
					
				});
				$('.static-report-upload-panel').show();
			}
			else{
				if(isset(response.errors)){
					error_message(response.errors.join(','));
				}
				else{
					error_message('upload failed');
				}
			}
		},'GET');
	}

	$('body').on('click', '.upload-static-report-data-icon', function(){
		var form = $(this).closest('form');
		var report_id = $(this).data('report_id');
		trigger_report_upload(report_id);
	});

	


});