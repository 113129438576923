/*
* //// api specific functions 
*/
function orderInterface () {
	var that = this;
	this.order_id = null,
	this.capture_mprn = function(order_id,callback){
		this.order_id = order_id;
		var mprn = prompt('enter mprn');
		if(mprn){
			if(mprn.length > 7){
				this.set_mprn(mprn,function(response){
					callback(response)
				});
			}
			else{
				error_message('please enter a valid mpan');
			}
		}
	}
	this.set_mprn = function(mprn,callback){
		var data = {
			order_id: this.order_id,
			action:'set_mprn',
			value:mprn,
		};
		this.perform_action(data,function(response){
			callback(response)
		});
		
	},
	this.capture_mpan = function(order_id,callback){
		this.order_id = order_id;
		var mpan = prompt('enter mpan');
		if(mpan){
			if(mpan.length > 7){
				this.set_mpan(mpan,function(response){
					callback(response)
				});
			}
			else{
				error_message('please enter a valid mpan');
			}
		}
	}
	this.set_mpan = function(mpan,callback){
		var data = {
			order_id: this.order_id,
			action:'set_mpan',
			value:mpan,
		};
		this.perform_action(data,function(response){
			callback(response)
		});
		
	},
	
	this.capture_edf_account_number = function(order_id,callback){
		this.order_id = order_id;
		var current_account_number = $('#capture_edf_account_number').data('current-account-number');
		var new_account_number = prompt('enter account number', current_account_number);
		if(new_account_number){
			if(new_account_number.length > 7){
				this.set_edf_account_number(new_account_number,function(response){
					callback(response);
					if(response.success){
						$('#capture_edf_account_number').data('current-account-number', new_account_number);
					}
				});
			}
			else{
				error_message('please enter a valid account number')
			}
		}
	}
	this.capture_utility_warehouse_account_number = function(order_id,callback){
		this.order_id = order_id;
		var current_account_number = $('#capture_utility_warehouse_account_number').data('current-account-number');
		var new_account_number = prompt('enter account number', current_account_number);
		if(new_account_number){
			if(new_account_number.length > 6){
				this.set_utility_warehouse_account_number(new_account_number,function(response){
					callback(response);
					if(response.success){
						$('#capture_utility_warehouse_account_number').data('current-account-number', new_account_number);
					}
				});
			}
			else{
				error_message('please enter a valid account number')
			}
		}
	}


	this.add_external_order_number = function(order_id,callback){
		this.order_id = order_id;
		var current_account_number = $('#add_external_order_number').data('current-account-number');
		var new_account_number = prompt('enter external order number', current_account_number);
		if(new_account_number){
			if(new_account_number.length > 7){
				this.set_external_order_number(new_account_number,function(response){
					callback(response);
					if(response.success){
						$('#add_external_order_number').data('current-account-number', new_account_number);
						window.location.reload();
					}
				});
			}
			else{
				error_message('please enter a valid account number')
			}
		}
	}

	this.showManageDDModal = function(order_id, callback = function(){}){
		var data = {
			'order_id' : order_id
		}
		showLoader();		
		makeRequest('/order/manage/dd', data, function(response){
			if(isset(response.html)){
				createModal('manage_dd_modal', response.html);
				callback();
			}
			else{
				var error = 'unable to load available dd details';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});
	}

	this.manage_dd = function(order_id,callback){
		this.order_id = order_id;
		this.showManageDDModal(order_id, function(){

		});
	}

	this.showOrderConfirmationPreviewModal = function(order_id, callback = function(){}){
		var data = {
			'order_id' : order_id
		}
		showLoader();		
		makeRequest('/order/confirmation/preview', data, function(response){
			if(isset(response.html)){
				createModal('order_confirmation_preview_modal', response.html);
				callback();
			}
			else{
				var error = 'unable to load confirmation'; 
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});
	}
	
	this.show_confirmation_preview = function(order_id,callback){
		this.order_id = order_id;
		this.showOrderConfirmationPreviewModal(order_id, function(){

		});
	}

	this.force_auto_switch = function(order_id, callback = function(){}){
		var data = {
			'sales_order_id' : order_id
		}
		buildDynamicModal('force_auto_switch_modal', data, function(){});	
	}


	this.set_edf_account_number = function(account_number,callback){
		var data = {
			order_id: this.order_id,
			action:'set_edf_account_number',
			value:account_number,
		};
		this.perform_action(data,function(response){
			callback(response)
		});
		
	},

	this.set_utility_warehouse_account_number = function(account_number,callback){
		var data = {
			order_id: this.order_id,
			action:'set_utility_warehouse_account_number',
			value:account_number,
		};
		this.perform_action(data,function(response){
			callback(response)
		});
		
	},

	this.set_external_order_number = function(account_number,callback){
		var data = {
			order_id: this.order_id,
			action:'set_external_order_number',
			value:account_number,
		};
		this.perform_action(data,function(response){
			callback(response)
		});
	},

	this.unlink_transaction_id = function(order_id, callback){
		if(confirm('are you sure you want to remove the call recording?')){
			var data = {
				'order_id' : order_id,
				'action' : 'unlink_transaction_id'
			};
			this.perform_action(data, function(response){
				callback(response);
			});
		}
	}
	this.set_transaction_id = function(transaction_id,callback){
		var data = {
			order_id: this.order_id,
			action:'set_transaction_id',
			value:transaction_id,
		};
		this.perform_action(data,function(response){
			callback(response)
		});
		
	},
	this.reprocess_order = function(order_id,callback){
		this.order_id = order_id;
		var jedi = confirm('Are you really sure you want to do this and know what you are doing?');
		if(true == jedi){
			var data = {
				order_id: this.order_id,
				action:'reprocess_order',
			};
			this.perform_action(data,function(response){
				console.log(response);
				callback(response)
			});
		}
	},
			
	this.request_payment_card = function(order_id,callback){
		this.order_id = order_id;
		var data = {
			order_id: this.order_id,
			action:'request_payment_card'
		};
		this.perform_action(data,function(response){
			callback(response)
		});
		
	},
	
	this.request_dd_mandate = function(order_id,callback){
		this.order_id = order_id;
		var data = {
			order_id: this.order_id,
			action:'request_dd_mandate'
		};
		this.perform_action(data,function(response){
			callback(response)
		});
		
	},
	
	this.resend_ehl_order_confirmation_email = function(order_id,callback){
		this.order_id = order_id;
		var data = {
			order_id: this.order_id,
			action:'resend_ehl_order_confirmation_email'
		};
		this.perform_action(data,function(response){
			callback(response)
		});
		
	},
		
	this.perform_action = function(data,callback){
		showLoader(['getting ready to perform action','performing request to '+data.action,'completing action','ok we lied, we\'re still busy performing '+data.action,' please hold your call is important to us','well this is awkward...','maybe something broke, wait 5 more seconds just in case' ]);
		makeRequest('/order/fix/quick-actions', data, function(response){
			hideLoader();
			if(response.errors){
				error_message('something went wrong, please check and try again');
				that.throwError(response.errors);
			}else{
				success_message();
				callback(response);
			}
		});
	},
	this.throwError = function(error){
		var message = error;
		var message = new MessageBox('errorDialog', message);
		message.setData({});
		message.run();
		$('.modal-footer').hide();
	}
}
module.exports = orderInterface;
