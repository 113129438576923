
var processQuestion = function(that){
		var found_next = false;
        var parent = $(that).closest('.will-question');
        var question_id = parent.data('question_id');
        do {
            question_id++;
            var next_question = $('#will-question-'+question_id);
			if(next_question.length){
				var dependencies = next_question.data('dependencies');
				if(isset(dependencies)){
					found_next = true;
					$.each(dependencies, function(index,value) {
						var selected_answer = $('.will-questionair-section').find("[data-type='" + index + "'].active");
						if(selected_answer.length){
							if(Array.isArray(value)){
								if(value.indexOf(selected_answer.data('value')) == -1){
									$(next_question).hide();
									found_next = false;
								}
							}
							else{
								if(selected_answer.data('value') != value){
									$(next_question).hide();
									found_next = false;
								}
							}
							
						}
					});
				}
				else{
					// no dependencies so return
					found_next = true;
					// hide any unanswered questions ahead of the next question;

				}
			}
			else{
				$('.script-footer .next-button').show();
				break;
			}
        }
        while (found_next == false);
		var total_questions = $('.will-question').length;
		var next_question_id = question_id+1;
		while($('#will-question-'+next_question_id).length){
			$('#will-question-'+next_question_id).hide();
			next_question_id++;
		}
        setTimeout(function(){
            // parent.fadeOut('fast',function(){
                $('#will-question-'+question_id).fadeIn();
            // });
        },500);
}

$(function () {

	$('#lead_script').on('click','.get-bundle-information-button',function(e){
		e.preventDefault();
		var bundle_id = $(this).data('bundle_id');
		var quote_id = $('#lead_script').data('quoteid');
		makeRequest('/quote/get-bundle-detail/'+quote_id+'/'+bundle_id, {}, function(response){
			if(isset(response.success) && response.success){
				$('.fact-find-suggested-bundle').html(response.html);
				success_message('info updated');
			}
			else{
				error_message(response.error);
			}
			// script.handleStep($(that).data('success-step'));
		},'GET');
	});

	$('#lead_script').on('click','.select-bundle-button',function(e){
		e.preventDefault();
		var bundle_id = $(this).data('bundle_id');
		var quote_id = $('#lead_script').data('quoteid');
		var data = {
			'bundle_id':bundle_id
		};
		makeRequest('/quote/add-product-bundle/'+quote_id, data, function(response){
			if(isset(response.success) && response.success){
				var next_step = $('.script-footer').find('.next-button button').data('success-step');
				window.lead_script.handleStep(next_step)	
			}
			else{
				error_message(response.error);
			}
			// script.handleStep($(that).data('success-step'));
		});
	});

	$('#lead_script').on('click', '.new-rll-lead-form .rll-number-update-button', function(e){
		$(this).addClass('active');
		var parent_wrapper = $(this).closest('.will-questionair-section');
		var contact_id = $(parent_wrapper).data('contact_id');
		var input_container = $(this).closest('.rll-number-container');
		var type = $(this).data('type');
		var quote_id = $('#lead_script').data('quoteid');
		var value = $(input_container).find(':input').val();
		makeRequest('/contact/update/profile/'+contact_id, {
				'key' : type, 
				'value' : value,
				'quote_id' : quote_id
		}, function(response){
			// script.handleStep($(that).data('success-step'));
		});
		var that = this;
        processQuestion(that);
	});

	$('#lead_script').on('click', '.new-rll-lead-form .rll-toggle', function(e){
        if(!$(this).hasClass('active')){
            var container = $(this).closest('.rll-toggle-container');
            $(container).find('.rll-toggle').removeClass('active');
            $(this).addClass('active');
            var type = $(this).data('type');
			var value = $(this).data('value');
			var quote_id = $('#lead_script').data('quoteid');
			var parent_wrapper = $(this).closest('.will-questionair-section');
			var contact_id = $(parent_wrapper).data('contact_id');
			makeRequest('/contact/update/profile/'+contact_id, {
				'key' : type, 
				'value' : value,
				'quote_id' : quote_id
			}, function(response){
				// script.handleStep($(that).data('success-step'));
			});
        }
        var that = this;
        processQuestion(that);
    });


    $('#lead_script').on('click','.product-group-products-tab',function(){
		$('.product-group-product-section').hide();
		var target = $(this).data('target');
		$('#'+target).show();
	});


	$('#lead_script').on('click','.energy-upsell-product-group-products-content .add-product-to-order',function(e){
		e.preventDefault();
		var quote_id = $('#lead_script').data('quoteid');
		var data = {};
		data.product_id = $(this).data('product_id');
		var parent = $(this).closest('.product-table-item-row');
		data.quantity = parent.find(':input[type="number"]').val();
		// take the product id, qty
		makeRequest('/quote/add-energy-upsell-product-to-basket/' + quote_id, data, function(response){
				if(response.errors == null) {		
					if(response.html != null){
						$('.basket-products-content').html(response.html);
						if(isset(response.continue) && response.continue){
							$('.script-footer .next-button').show();
						}
						else{
							$('.script-footer .next-button').hide();
						}
					}
				}
			}, 'POST');

	});
	$('#lead_script').on('click','.product-group-products-content .add-product-to-order',function(e){
		e.preventDefault();
		var quote_id = $('#lead_script').data('quoteid');
		var data = {};
		data.product_id = $(this).data('product_id');
		var parent = $(this).closest('.product-table-item-row');
		data.quantity = parent.find(':input[type="number"]').val();
		// take the product id, qty
		makeRequest('/quote/add-will-product-to-basket/' + quote_id, data, function(response){
				if(response.errors == null) {		
					if(response.html != null){
						$('.basket-products-content').html(response.html);
						if(isset(response.continue) && response.continue){
							$('.script-footer .next-button').show();
						}
						else{
							$('.script-footer .next-button').hide();
						}
					}
				}
			}, 'POST');

	});

	$('#lead_script').on('change','.basket-product-section .basket-product-table-item-row :input[type="number"]',function(e){
		e.preventDefault();
		var that = this;
		$(that).attr('disabled','disabled');
		var quote_id = $('#lead_script').data('quoteid');
		var data = {};
		var parent = $(this).closest('.basket-product-table-item-row');
		data.quantity = $(this).val();
		var bundle_id = $(this).data('bundle_id');
		if(isset(bundle_id)){
			data.bundle_id  = bundle_id;
		}
		data.item_id = $(parent).data('item_id');
		console.log(data);
		// take the product id, qty
		makeRequest('/quote/update-will-product-basket/' + quote_id, data, function(response){
				$(that).removeAttr('disabled');
				if(response.errors == null) {		
					if(response.html != null){
						$('.basket-products-content').html(response.html);
						if(isset(response.continue) && response.continue){
							$('.script-footer .next-button').show();
						}
						else{
							$('.script-footer .next-button').hide();
						}
					}
				}
			}, 'POST');

	});
	
	$('#lead_script').on('click','.basket-product-section .basket-product-table-item-row .remove-product-from-order',function(e){
		e.preventDefault();
		var quote_id = $('#lead_script').data('quoteid');
		var data = {};
		var parent = $(this).closest('.basket-product-table-item-row');
		data.quantity = 0;
		data.item_id = $(parent).data('item_id');
		// take the product id, qty
		makeRequest('/quote/update-will-product-basket/' + quote_id, data, function(response){
				if(response.errors == null) {		
					if(response.html != null){
						$('.basket-products-content').html(response.html);
						if(isset(response.continue) && response.continue){
							$('.script-footer .next-button').show();
						}
						else{
							$('.script-footer .next-button').hide();
						}
					}
				}
			}, 'POST');

	});

	$('#lead_script').on('click','.energy-basket-product-section .basket-product-table-item-row .remove-product-from-order',function(e){
		e.preventDefault();
		var quote_id = $('#lead_script').data('quoteid');
		var data = {};
		var parent = $(this).closest('.basket-product-table-item-row');
		data.quantity = 0;
		data.item_id = $(parent).data('item_id');
		// take the product id, qty
		makeRequest('/quote/update-energy-product-basket/' + quote_id, data, function(response){
				if(response.errors == null) {		
					if(response.html != null){
						$('.basket-products-content').html(response.html);
						if(isset(response.continue) && response.continue){
							$('.script-footer .next-button').show();
						}
						else{
							$('.script-footer .next-button').hide();
						}
					}
				}
			}, 'POST');

	});

	$('#lead_script').on('click','.add-manager-discount-button',function(e){
		e.preventDefault();
		var quote_id = $('#lead_script').data('quoteid');
		makeRequest('/quote/get-manager-dicount-modal-content/' + quote_id, {}, function(response){
			if(response.errors == null) {		
				if(response.html != null){
					$('#manager-discount-modal-content').html(response.html);
					showModal('managerDiscount');
				}
			}
		}, 'GET');
	});

	$('.quick-order-actions').on('click','.add-manager-discount-button',function(e){
		e.preventDefault();
		var order_id = $(this).data('order_id');
		makeRequest('/quote/get-manager-dicount-modal-content/' + order_id, {}, function(response){
			if(response.errors == null) {		
				if(response.html != null){
					$('#manager-discount-modal-content').html(response.html);
					showModal('managerDiscount');
				}
			}
		}, 'GET');

	});

	$('#managerDiscount').on('click','#add_manager_discount',function(e){
		e.preventDefault();
		var form = $(this).closest('form');
		var form_data = $(form).serializeArray();
		var data = {};
		$.each(form_data,function(index,input){
			var key = input.name;
			data[key] = input.value;
		});

		if($('#order-details-'+data.order_id).length){
			data.client_page = true;
		}
		
		if(!isset(data.discount_reason) || data.discount_reason.length < 5){
			$('.discount-reason-section').find('label').addClass('error shake');
			setTimeout(function(){
				$('#manager-discount-modal-content').find('label').removeClass('error shake');
			},1500)
			return;
		}

		if(!isset(data.discount_value) || data.discount_value == 0){
			$('.discount-value-section').find('label').addClass('error shake');
			setTimeout(function(){
				$('#manager-discount-modal-content').find('label').removeClass('error shake');
			},1500)
			return;
		}

		makeRequest('/quote/apply-manager-dicount/' + data.order_id, data, function(response){
			if(response.errors == null) {		
				if(response.html != null){
					
					if($('#lead_script').length){
						$('.basket-products-content').html(response.html);
						if(isset(response.continue) && response.continue){
							$('.script-footer .next-button').show();
						}
						else{
							$('.script-footer .next-button').hide();
						}
					}
					// work out if we're in a script or not 
					if($('#order-details-'+data.order_id).length){
						$('#order-details-'+data.order_id).find('.basket-products-content').html(response.html)
					}
					
					hideModal('managerDiscount');
					successMessage('Discount Applied')
				}
			}
		}, 'POST');

	});

	$('.quick-order-actions').on('click','.add-split-commission-button',function(e){
		e.preventDefault();
		var order_id = $(this).data('order_id');
		makeRequest('/quote/get-split-commission-modal-content/' + order_id, {}, function(response){
			if(response.error == null) {		
				if(response.html != null){
					$('#split-commission-modal-content').html(response.html);
					showModal('splitCommission');
				}
			}
			else{
				error_message(response.error);
			}
		}, 'GET');
	});

	$('#splitCommission').on('click','.add-agent-commission-split-to-order',function(e){
		e.preventDefault();
		// this has the row to add, go add it to the view but don't add it to the order until the agent clicks save
		let parent = $(this).closest('.commission-split-add-row');
		let agent_id = $(parent).find('#commission_split_agent_add option').filter(":selected").val();
		let value = $(parent).find(':input[name="agent_commission_percentage"]').val();
		// lets get the current commission % being paid to the order owner and take away the new value added
		let order_id = $('#splitCommission').find(':input[name="order_id"]').val();
		makeRequest('/quote/add-commission-split/' + order_id, {
			'agent_id':agent_id,
			'split_percentage':value
		}, function(response){
			if(response.error == null) {		
				if(response.html != null){
					$('#split-commission-modal-content').html(response.html);
				}
			}
			else{
				error_message(response.error);
			}
		}, 'POST');
	});

	$('#splitCommission').on('click','.remove-agent-commission-split-button',function(e){
		e.preventDefault();
		// this has the row to add, go add it to the view but don't add it to the order until the agent clicks save
		let parent = $(this).closest('.commission-split-table-row');
		let agent_id = $(parent).find('.agent-name').data('value');
		let value = $(parent).find('.split-value').data('value');
		// lets get the current commission % being paid to the order owner and take away the new value added
		let order_id = $('#splitCommission').find(':input[name="order_id"]').val();
		makeRequest('/quote/remove-commission-split/' + order_id, {
			'agent_id':agent_id,
			'split_percentage':value
		}, function(response){
			if(response.error == null) {		
				if(response.html != null){
					$('#split-commission-modal-content').html(response.html);
				}
			}
			else{
				error_message(response.error);
			}
		}, 'POST');
	});

	$('#lead_script').on('change','.payment-plan-payments-section :input[name="number_of_payments"]',function(){
		var parent = $(this).closest('.payment-plan-payments-section');
		var total = $(parent).find('.total-balance').data('value');
		var payments = $(this).val()
		if(payments >0){
			var monthly_amount = parseFloat((total/100)/payments).toFixed(2);
			$(parent).find('.monthly-payment-amount').html('£'+monthly_amount);
			$(parent).find('.monthly-payment-amount').data('monthly_payment_amount',monthly_amount);
		}
		else{
			$(this).val(1);
			$(parent).find('.monthly-payment-amount').html('£'+total/100);
			$(parent).find('.monthly-payment-amount').data('monthly_payment_amount',monthly_amount);
		}
	});

	$('#lead_script').on('change','.finance-agreement-payments-section :input[name="number_of_payments"]',function(){
		var parent = $(this).closest('.finance-agreement-payments-section');
		var total = $(parent).find('.total-balance').data('value');
		var payments = $(this).val();
		if(payments >0){
			var monthly_amount = parseFloat((total/100)/payments).toFixed(2);
			$(parent).find('.monthly-payment-amount').html('£'+monthly_amount);
			$(parent).find('.monthly-payment-amount').data('monthly_payment_amount',monthly_amount);
		}
		else{
			$(this).val(1);
			$(parent).find('.monthly-payment-amount').html('£'+total/100);
			$(parent).find('.monthly-payment-amount').data('monthly_payment_amount',monthly_amount);
		}
	});

	$('#lead_script').on('change','.bundle-calculator-container :input[name="bundle-calculator-deposite"]',function(){
		var deposit = $(this).val();
		var container = $(this).closest('.bundle-calculator-container');
		var bundle_total = $(container).find('.bundle-calculator-bundle-total').data('bundle_total');
		var remaining_balance = bundle_total - deposit;
		var monthly_payments = $(container).find('select[name="bundle-calculator-payment-months"]').find(":selected").val();
		$(container).find('.bundle-calculator-bundle-monthly-payment').html('£'+(remaining_balance/monthly_payments).toFixed(2));
		var quote_id = $('#lead_script').data('quoteid');
		var data = {
			'key':'order:preferred_deposit',
			'value':deposit,
		};
		makeRequest('/quote/update/profile/'+quote_id, data, function(response){
				console.log(response);
			},'POST');
	});

	$('#lead_script').on('change','.bundle-calculator-container select[name="bundle-calculator-payment-months"]',function(){
		var container = $(this).closest('.bundle-calculator-container');
		var monthly_payments = $(this).val();
		var deposit = $(container).find(':input[name="bundle-calculator-deposite"]').val();
		var bundle_total = $(container).find('.bundle-calculator-bundle-total').data('bundle_total');
		var remaining_balance = bundle_total - deposit;
		$(container).find('.bundle-calculator-bundle-monthly-payment').html('£'+(remaining_balance/monthly_payments).toFixed(2));
		var quote_id = $('#lead_script').data('quoteid');
		var data = {
			'key':'order:preferred_finance_months',
			'value':monthly_payments,
		};
		makeRequest('/quote/update/profile/'+quote_id, data, function(response){
				console.log(response);
			},'POST');
	});

	$('#aftersale-will-finance').on('click','#finance-agreement-add-completed',function(){
		var finance_reference = $('#finance-reference').val();
		if(finance_reference.length !== 10){
			// add some more sensible checking here
			error_message('Finance Reference MUST be Added!')
			return;
		}
		var quote_id = $('#lead_script').data('quoteid');
		var data = {
			'finance_reference':finance_reference,
		};
		makeRequest('/order/add/finance-agreement/'+quote_id, data, function(response){
			if(isset(response.success) && response.success){
				success_message('Finance Added Successfully');
				window.location.reload();
			}
			else{
				if(isset(response.error)){
					error_message(response.error);
				}
				else{
					error_message('Something went wrong')
				}
			}
		},'POST');

	});
	
});
