var filterByText = function(target,value){
	if(value === ""){
		$(target).find('li').show();
	} 
	else {
		$(target).find('li').each(function(){
			var text = $(this).text();
			var compare = text.toLowerCase();
			(compare.indexOf(value) >=0) ? $(this).show() : $(this).hide();
		});
	};
};

var sort_update = function(li){
	var actions_in_event = [];
	var list = $(li).closest('ul');
	
	var type = $(list).data('type-id');
	$(list).find('li').each(function(){
		if(actions_in_event.indexOf($(this).data('action-id')) == -1){
			actions_in_event.push($(this).data('action-id'));
		}
		else{
			$(this).remove();
		}
	});
	
	var event_id = $('#active_event_actions').data('event-id');
	
	update_actions(event_id, actions_in_event, type);
	
}


var reloadEventsList = function(){
	var icon = $('.all-events-list').closest('.module-inner').find('.statistic-heading i');
	makeLoadingIcon(icon);
	makeRequest('/admin/events/reload/events', {}, function(response){
		if(isset(response.success) && response.success && isset(response.html)){
			$('.all-events-list').html(response.html);
			$( "ul.all-events-list" ).sortable({
				connectWith: ".event-action-group-group-events-list",
				appendTo: 'body',
			});
			restoreIcon(icon);
		}
	});
}

var reloadRulesList = function(){
	var icon = $('.all-rules-list').closest('.module-inner').find('.statistic-heading i');
	makeLoadingIcon(icon);
	makeRequest('/admin/events/reload/rules', {}, function(response){
		if(isset(response.success) && response.success && isset(response.html)){
			$('.all-rules-list').html(response.html);
			$( "ul#all_rules_sort" ).sortable({
				connectWith: ".ruleSortable",
				appendTo: "body",
			});
			restoreIcon(icon);
		}
	});
}

var reloadActionsList = function(){
	var icon = $('.all-action-list').closest('.module-inner').find('.statistic-heading i');
	makeLoadingIcon(icon);
	makeRequest('/admin/events/reload/actions', {}, function(response){
		if(isset(response.success) && response.success && isset(response.html)){
			$('.all-action-list').html(response.html);
			$( "ul#all_actions_sort" ).sortable({
				connectWith: ".actions-sortable",
				appendTo: 'body',
			});
			restoreIcon(icon);
		}
	});
}

var event_actions_preview_sortable = function(){
	$(".active_action_group_group_sort").sortable({
		'handle' : '.event-action-group-group-drag-handle',
		'update': function (e, ui) {
			var data = {
				'event_id' : $(this).data('event-id'),
				'type_id' : $(this).data('type-id'),
				'items' : []
			};
			var items = $(this).children();
			$.each(items, function(i, item){
				data.items.push($(item).data('action-group-group-id'));
			})
			makeRequest('/admin/events/reorder/action-group-groups', data, function(response){
				if(isset(response.success) && response.success){
					success_message();
				}
				else{
					var error = 'unable to re-order event action group groups';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			})
		}
	});
}

var event_actions_sortable = function() {
	$( "ul.actions-sortable" ).sortable({
		update: function (e, ui) {
			var group_id = $(this).data('group-id');
			var data = {
				'event_action_group_id' : group_id,
				'items' : []
			};
			var element = ui.item[0];
			makeLoadingIcon($(element).find('.action-icon-small i'))
			var items = $(this).children();
			$.each(items, function(i, item){
				var item_obj = {};
				var link_id = $(item).data('action-group-link-id');
				if(link_id){
					item_obj = {'type' : 'existing', 'id' : link_id};
				}
				else{
					item_obj = {'type' : 'new', 'id' : $(item).data('action-id')};
				}
				data.items.push(item_obj);
			})
			var that = $(this);
			makeRequest('/admin/events/reorder/actions', data, function(response){
				if(isset(response.success) && response.success){
					if(isset(response.modal)){
						createModal('edit_action_editable_attributes_modal', response.modal);
						$('#edit_action_editable_attributes_modal').find('.select2:not(.multi)').select2({'width':'100%'});
						$('#edit_action_editable_attributes_modal').find('.select2.multi').select2({'width':'100%', 'tags': true});
						$('#edit_action_editable_attributes_modal input.date-filter').daterangepicker({
							"locale": {
								"format": 'DD/MM/YYYY HH:mm'		
							},
							"timePicker": true,
							"singleDatePicker": true,
							"showDropdowns": true
						});
					}
					if(isset(response.html)){
						$(that.closest('.event-action-group-container').replaceWith(response.html));
						event_actions_sortable();
						success_message();
					}
				}
				else{
					var error = 'unable to event action group rules';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
				reloadActionsList();
			});
		}
	});
	
	$( "ul.ruleSortable" ).sortable({
		update: function (e, ui) {
			var verdict = $(this).data('verdict');
			var group_id = $(this).data('group-id');
			var data = {
				'verdict' : verdict,
				'event_action_group_id' : group_id,
				'items' : []
			};
			var element = ui.item[0];
			makeLoadingIcon($(element).find('.rule-icon-small i'))
			var items = $(this).children();
			$.each(items, function(i, item){
				var item_obj = {};
				var link_id = $(item).data('trigger-rule');
				if(link_id){
					item_obj = {'type' : 'existing', 'id' : link_id};
				}
				else{
					item_obj = {'type' : 'new', 'id' : $(item).data('rule-id')};
				}
				data.items.push(item_obj);
			})
			var that = $(this);
			makeRequest('/admin/events/reorder/rules', data, function(response){
				if(isset(response.success) && response.success){
					if(isset(response.modal)){
						createModal('edit_rule_editable_attributes_modal', response.modal);
					}
					if(isset(response.html)){
						$(that.closest('.event-action-group-container').replaceWith(response.html));
						event_actions_sortable();
						success_message();
					}
					//reload the available rules list
					makeRequest('/admin/events/reload/rules', {}, function(response){
						if(isset(response.success) && response.success && isset(response.html)){
							$('#all_rules_list').replaceWith(response.html);
						}
					})
				}
				else{
					var error = 'unable to event action group rules';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
					$(that).remove();
				}
				//reload the available rules list
				reloadRulesList();
			});
			
		}
	});
	$( "ul.event-action-group-group-events-list" ).sortable({
		update: function (e, ui) {
			var group_group_id = $(this).data('event-action-group-group-id');
			var entity_id = $(this).data('entity-id')
			var item = ui.item[0];
			if($(item).data('pivot-id')){
				//it is already linked - someone is rearanging items - that does nothing here
				return false;
			}
			var event_id = $(item).data('event-id');
			var data = {
				'event_action_group_group_id' : group_group_id,
				'entity_id' : entity_id,
				'event_id' : event_id
			};
			var that = $(this);
			showLoader();
			makeRequest('/admim/events/action-groups/group/event/link', data, function(response){
				if(isset(response.success) && response.success){
					if(isset(response.modal)){
						createModal('new_event_link_order_check_modal', response.modal);
						$('ul.event-link-conflict-list').sortable()
						$( "ul.event-link-new-conflict-list" ).sortable({
							connectWith: ".event-link-conflict-list"
						});
						$('#new_event_link_order_check_modal #cancel_group_arrangement').on('click', function(){
							$(item).remove();
							reloadEventsList();
						});
						$('#new_event_link_order_check_modal #save_group_arrangement').on('click', function(){
							if($('.event-link-new-conflict-list li').length){
								error_message('there are still unresolved items');
								return false;
							}
							var data = {
								'event_id' : event_id,
								'entity_id' : entity_id,
								'active_group_id' : group_group_id,
								'order' : []
							};
							$.each($('#new_event_link_order_check_modal .event-link-conflict-list li'), function(i, item){
								data.order.push($(item).data('event-action-group-group-id'));
							});
							makeRequest('/admim/events/action-groups/group/event/reorder', data, function(response){
								if(isset(response.success) && response.success && isset(response.updated)){
									success_message('updated ' + response.updated + ' records');
									$(that).html(response.html);
									event_actions_sortable();
								}
								else{
									var error = 'unable to arrange groups';
									if(isset(response.error)){
										error = response.error;
									}
									error_message(error);
									$(item).remove();
									reloadEventsList();
								}
								$('#new_event_link_order_check_modal').modal('hide');
							})
						});
					}
					else{
						success_message();
						if(isset(response.html)){
							$(that).html(response.html);
							event_actions_sortable();
						}
					}
					//reload the available rules list
					reloadEventsList();
				}
				else{
					var error = 'unable to event action group rules';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
					$(item).remove();
					reloadEventsList();
				}
				hideLoader();
			});
		}
	});
	$(".event-action-group-list").sortable({
		'handle' : '.event-action-group-drag-handle',
		'update': function (e, ui) {
			var data = {
				'event_id' : $(this).data('event-id'),
				'type_id' : $(this).data('type-id'),
				'items' : []
			};
			var items = $(this).children();
			$.each(items, function(i, item){
				data.items.push($(item).data('action-group-id'));
			})
			makeRequest('/admin/events/reorder/action-groups', data, function(response){
				if(isset(response.success) && response.success){
					success_message();
				}
				else{
					var error = 'unable to re-order event action groups';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			})
		}
	});
};

var update_actions = function(event_id, actions, type_id){
	$.ajax({
		type: "POST",
		dataType: 'json',
		data: {
			'type_id' : type_id,
			'actions' : actions
		},
		url: '/event/actions/update-linked-actions/'+event_id,
		success: function(response) {
			if(checkErrors(response)){
				success_message();
				$('.active_actions_sort  div.delete-trigger').show();
			}
		},
		error: function (error) {
			alert(error);
		}
	});
};

var success_message = function (text) {
	if (!text) {
		text = 'Updated';
	}
	$('.update-success').fadeIn().text(text);
	setTimeout(function () {
		$('.update-success').fadeOut();
	}, 5000);
};

var error_message = function (text) {
	$('.update-error').fadeIn().text(text);
	setTimeout(function () {
		$('.update-error').fadeOut();
	}, 5000);
};

var checkErrors = function (response) {
	if (response.success != true) {
		if (response.error) {
			if (response.error.length > 1) {
				error_message(response.error);
				return false;
			}
		}
		error_message('an unknown error occurred');
		return false;
	}
	return true;

}


$( document ).ready(function() {


	$('body').on('click','.stream-call-recording',function(e){
        e.preventDefault();
        var data = {
            'external_id' : $(this).data('external-id'),
            'dialler_id' : $(this).data('dialler-id')
        };
		var that = this;
		var loader =$(this).parent().find('.call-loader'); 
		loader.show();
		$(this).hide();
        makeRequest('/get/call-recording', data, function(response){
            if(response.success){
                $(that).hide();
				var container = $(that).closest('.player-container');

                $(container).find('#mp3_source').attr('src', '/stream/call-recording/'+response.cdr_record_hash);
                var player = new Plyr($(container).find('audio'), {});

                // Expose player so it can be used from the console
                // window.player = player;
			}
            else {
            	var error = 'Unknown Error With Playback';
            	if(response.error) {
            		error = response.error
				}
				$(that).closest('.player-container').find('.audio-playback-error').html(error).show();
			}
			$(loader).hide();
        });
    });


	$('#saved-events-wrapper').on('keyup','.search-events',function(){
		var target = $(this).parent().parent().find('#saved-events ul');
		filterByText(target,$(this).val().toLowerCase());
	});	
	
	$('#saved-events-wrapper').on('click', '#create-new-event',function() {
		var input = $('#event-name').val();
		$.ajax({
			data: {name : input},
			type: 'POST',
			url: '/admin/events/create',
			dataType: 'json',
			success: function(response) {
				if(response.success){
					$('.update-error').hide();
					$('.update-success').fadeIn().text('Created '+input);
					setTimeout(function(){
						$('.update-success').fadeOut();
					},5000);
					$('#event-value-edit').show();
					$('#event-value-edit').html(response.html);
				}
				else{
					var error = 'whaa';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
					// it must be an error so show something helpful
				}
			},
			error: function (error) {

			}
		});
	});
	
	$('#event-wrapper').on('click', '#save_event',function() {
		var form = $('#event-edit-table').find('form').serialize();
		var event_id = $('#event-edit-table').find('form').data('event-id');
		$.ajax({
			data: form,
			type: 'POST',
			url: '/admin/events/update/'+event_id,
			dataType: 'json',
			success: function(response) {
				if(response.success){
					var existing = $('#saved-events ul').find("[data-event-id='" + event_id + "']");
					$('#event-value-edit').hide();
					$('.update-error').hide();
					$('.update-success').fadeIn().text('Event Saved');
					setTimeout(function(){
						$('.update-success').fadeOut();
					},5000);
					if(existing.length == 0) {
						$(response.html).appendTo('#saved-events ul');
					} 
					else {
						$(existing).html(response.html);
					}
					// we are all good, 
					// show the edit event div
					// fill the div with the html returned from your controller
					// eg $('#thing').show();
					// eg $('#thing').html(response.html);
				}
				else{
					var error = 'whaa';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
					// it must be an error so show something helpful
				}
			},
			error: function (error) {

			}
		});
	});
	
	$('#event-wrapper').on('click', '#edit-event',function() {
		var event_id = $(this).parent().data('event-id');
		makeRequest('/admin/events/edit/'+event_id, {}, function(response){
			if(response.success && response.html){
				$('#event-value-edit').show();
				$('#event-value-edit').html(response.html);
				event_actions_sortable();
				$('.active_actions_sort  div.delete-trigger').show();
			}
			else{
				var error = 'failed to get event actions';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})
	});

	$('#event-wrapper').on('click', '#edit-event-actions',function() {
		var event_id = $(this).parent().data('event-id');
		makeRequest('/admin/events/edit/actions/'+event_id, {}, function(response){
			if(response.success && response.html){
				$('#event-value-edit').show();
				$('#event-value-edit').html(response.html);
				// event_actions_sortable();
				// $('.active_actions_sort  div.delete-trigger').show();
				event_actions_preview_sortable();
			}
			else{
				var error = 'failed to get event actions';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})
	});
	
	$('#event-wrapper').on('click', '#edit-event-categories',function() {
		var event_id = $(this).parent().data('event-id');
		makeRequest('/admin/events/edit/categories/'+event_id, {}, function(response){
			if(response.success && response.html){
				$('#event-value-edit').show();
				$('#event-value-edit').html(response.html);
				event_actions_sortable();
				$('.active_actions_sort  div.delete-trigger').show();
			}
			else{
				var error = 'failed to get event actions';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})
	});
	
	$('#event-wrapper').on('click', '#create_event_action_group',function() {
		var group_id = $(this).data('event-action-group-group-id');
		var entity_id = $(this).data('entity-id');
		var name = $(this).parent().find('input[name="new-event-action-group-name"]').val();
		if(name.length < 4){
			error_message('please enter a name longer than 3 characters for your action group');
			return;
		}
		var data = {
			'group_id' : group_id,
			'entity_id' : entity_id,
			'name' : name,
		}
		var that = $(this);
		makeRequest('/admin/events/create/action-group', data, function(response){
			if(response.success && response.html){
				success_message();
				var container = $('.event-action-group-list');
				$(container).append(response.html);
				event_actions_sortable();
				$(that).parent().find('input[name="new-event-action-group-name"]').val('');
				$(that).parent().find('input[name="new-event-action-group-name"]').trigger('keyup');
			}
			else{
				var error = 'failed to create event action group';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})
	});
	
	$('#event-wrapper').on('click','.category-state-toggle .active-event-category-switch',function(){
		var data = {
			state : $(this).is(':checked')? 'on' : 'off',
			event_id : $('#event_category_container').data('event-id'),
			category_id : $(this).closest('li').data('category-id')
		}	
		$.ajax({
			url: '/admin/events/toggle/category',
			type:'POST',
			dataType: 'json',
			data: data,
			success: function(response) {
				if(response.success){
					if(response.state == 'on'){
						success_message(response.message);
					}
					if(response.state == 'off'){
						error_message(response.message);
					}
				}
				else{
					// it must be an error so show something helpful
					error_message(response.message);
				}
			},
			error: function (error) {

			}
		});
	});
	
	$('#event-wrapper').on('click', '#add-action',function() {
		
		var parent = $(this).parent();
		var input = $(parent).find('input[name="new-action-name"]');
		var action_name = $(input).val();
		
		if(action_name.length < 5){
			error_message('please enter a descriptive action name');
			return false;
		}
		
		$('#createEventAction').find('#action_name').val(action_name);
		$('#create-ticket-action-config').collapse('show');
		$('#createEventAction input').attr('selected', false);
		$('#createEventAction #save_rule_defaults').attr('id', 'create_action_next');
		$('#createEventAction').modal();
		
	});
	
	$('#event-wrapper').on('click', '#add-rule',function() {
		
		var parent = $(this).parent();
		var input = $(parent).find('input[name="new-rule-name"]');
		var rule_name = $(input).val();
		
		if(rule_name.length < 5){
			error_message('please enter a descriptive rule name');
			return false;
		}
		
		$('#createEventRule').find('#rule_name').val(rule_name);
		$('#createEventRule').find('#save_event_action_rule_defaults').attr('id', 'create_event_rule_next');
		$('#create-rule-config').collapse('show');
		$('#create-rule-attributes').collapse('hide');
		$('#createEventRule input').attr('selected', false);
		$('#createEventRule').modal();
		
	});
	
	
	$('#createEventAction').on('click', '#create_action_next', function(){
		
		var that = $(this);
		var modal_content = $(this).closest('.modal-content');
		var action_name = $(modal_content).find('#action_name').val();
		
		if(action_name.length < 5){
			error_message('please enter a descriptive action name');
			return false;
		}
		
		var action_type = $(modal_content).find('#action_type').val();
		var entity_type = $(modal_content).find('#entity_type').val();
		var attributes = [];
		var editable = [];
		var runtime_editable = [];

		$(modal_content).find("input[name='has_attribute']:checked").each( function () {
			attributes.push($(this).val());
		});
		$(modal_content).find("input[name='is_editable']:checked").each(function () {
			editable.push($(this).val());
		});
		$(modal_content).find("input[name='is_runtime_editable']:checked").each(function () {
			runtime_editable.push($(this).val());
		});

		var data = {
			'action_name': action_name,
			'action_type': action_type,
			'entity_type': entity_type,
			'attributes': attributes,
			'ediable_attributes': editable,
			'runtime_ediable_attributes': runtime_editable
		};

		makeRequest('/event/action/create', data, function(response){
			if (checkErrors(response)) {
				if(response.html){
					modal_content.find('#create-ticket-action-attributes').html(response.html).collapse('show');
					modal_content.find('#create-ticket-action-config').collapse('hide');
					$(that).text('update');
					$(that).attr('id', 'save_rule_defaults');
					$(that).data('action-id', response.action_id);
				}
				success_message();
			}
		});
	});
	
	$('#createEventRule').on('click', '#create_event_rule_next', function(){
		
		var that = $(this);
		
		var modal_content = $(this).closest('.modal-content');
		
		var rule_name = $(modal_content).find('#rule_name').val();
		
		if(rule_name.length < 5){
			error_message('please enter a descriptive rule name');
			return false;
		}
		
		var rule_type = $(modal_content).find('#rule_type').val();
		var entity_type = $(modal_content).find('#target_entity').val();
		var attributes = [];
		var editable = [];
		$(modal_content).find("input[name='has_attribute']:checked").each( function () {
			attributes.push($(this).val());
		});
		$(modal_content).find("input[name='is_editable']:checked").each(function () {
			editable.push($(this).val());
		});

		var data = {
			'rule_name': rule_name,
			'rule_type': rule_type,
			'target_linkable_entity_id': entity_type,
			'attributes': attributes,
			'ediable_attributes': editable
		};

		makeRequest('/event/rule/create', data, function(response){
			if(isset(response.success) && response.success && response.html){
				modal_content.find('#create-rule-attributes').html(response.html).collapse('show');
				modal_content.find('#create-rule-config').collapse('hide');
				$(that).text('update');
				$(that).attr('id', 'save_event_action_rule_defaults');
				$(that).data('rule-id', response.rule_id);
				modal_content.find('.select2').select2({'width' : '100%'});
			}
			else{
				var error = 'unable to create rule';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
	
	$('body').on('click', '#editEventActionRule #edit_event_rule_next', function(){
		var that = $(this);
		
		var modal_content = $(this).closest('.modal-content');
		
		var rule_name = $(modal_content).find('#rule_name').val();
		
		if(rule_name.length < 5){
			error_message('please enter a descriptive rule name');
			return false;
		}
		
		var rule_type = $(modal_content).find('#rule_type').val();
		var entity_type = $(modal_content).find('#target_entity').val();
		var attributes = [];
		var editable = [];
		$(modal_content).find("input[name='has_attribute']:checked").each( function () {
			attributes.push($(this).val());
		});
		$(modal_content).find("input[name='is_editable']:checked").each(function () {
			editable.push($(this).val());
		});

		var data = {
			'rule_name': rule_name,
			'rule_type': rule_type,
			'target_linkable_entity_id': entity_type,
			'attributes': attributes,
			'ediable_attributes': editable,
			'rule_id' : $(that).data('rule-id')
		};

		makeRequest('/event/rule/edit', data, function(response){
			if(isset(response.success) && response.success && response.html){
				modal_content.find('#edit-rule-attributes').html(response.html).collapse('show');
				modal_content.find('#edit-rule-config').collapse('hide');
				$(that).text('update');
				$(that).attr('id', 'save_event_action_rule_values_edit');
				$(that).data('rule-id', response.rule_id);
				modal_content.find('.select2').select2({'width' : '100%'});
			}
			else{
				var error = 'unable to edit rule';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
	
	$('body').on('click', '#editEventActionRule #save_event_action_rule_values_edit', function(){
		var rule_id = $(this).data('rule-id');
		var form = $(this).closest('.modal-dialog').find('#attribute-table').find('form');
		handle_special_fields(form.parent());
		var form = $(this).closest('.modal-dialog').find('#attribute-table').find('form');
		var form_data = form.serializeArray();
		
		var data = {};
		
		$.each(form_data, function(i, serialized_data){
			data[serialized_data['name']] = serialized_data['value'];
		});
		

		makeRequest('/event/rule/update-attributes/'+rule_id, data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('#editEventActionRule').modal('hide');
				$('#editEventActionRule').modal('hide');
				$('#editEventActionRule #edit-rule-attributes').html('');
				success_message();
			}
			else{
				var error = 'unable to save rule attributes';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
	
	
	$('#createEventAction').on('click', '.event-attribute-time-button', function(){
		
		var radio = $(this).find('input');
		var value = $(radio).val();
		
		if(value == 'custom'){
			$('#event-attribute-time-input').show();
			return;
		}
		
		$('#event-attribute-time-input').hide();
		
		$('#event-attribute-time-input input').val(value);

	});

	$('#modal_container').on('click', '.add-new-event-action-task', function(e){
		e.preventDefault();
		var container = $(this).closest('.tasks-argh');
		var clonable = $(container).find('.task-definition.clonable');

		$(clonable.clone().removeClass('clonable').show()).insertBefore($(clonable));

	})

	var handleMagicLosseyTaskAttribute = function(){
		var container = $('.tasks-argh');

		var tasks = container.find('.task-definition');

		var result = [];
		$.each(tasks, function(i, task_container){

			var task_result = {};

			task_result.title = $(task_container).find(':input[name="task-title"]').val();
			task_result.description = $(task_container).find(':input[name="task-description"]').val();
			if(task_result.title.length){
				task_result.due = {};
				task_result.due.unit = $(task_container).find(':input[name="task-due-unit"]').val();
				task_result.due.amount = $(task_container).find(':input[name="task-due-amount"]').val();
				task_result.user_id = $(task_container).find(':input[name="task-user_id"]').val();

				result.push(task_result);
			}
		})

		return result;

	}

	var handleMagicWorkingDaysAttribute = function(section){
		var selected = $(section).find('.event-attribute-working-days-button.active');
		var selected_input = $(selected).find(':input').val();
		var value = selected_input;
		if(selected_input == 'custom'){
			value = $(section).find('#event-attribute-working-days-input').find(':input').val();
		}
		return value;
	}
	
	$('#modal_container').on('click', '#save_rule_defaults', function(){
		var action_id = $(this).data('action-id');
		var form = $('#attribute-table').find('form');
		var form_data = form.serializeArray();
		
		var data = {};
		
		$.each(form_data, function(i, serialized_data){
			if(serialized_data['name'] == 'task-text'){
				data['tasks'] = handleMagicLosseyTaskAttribute();
			}
			else{
				data[serialized_data['name']] = serialized_data['value'];
			}
		});

		$.each($(form).find('.select2.multi'), function(i, multi_select){
			data[$(multi_select).attr('name')] = $(multi_select).val();
		});
		

		makeRequest('/event/action/update-attributes/'+action_id, data, function(response){
			if (checkErrors(response)) {
				if(response.html){
					if($('li#action-'+action_id).length == 0){
						$('ul#all_actions_sort').append(response.html);
					}
				}
				$('#createEventAction').modal('hide');
				$('#editEventAction').modal('hide');
				$('#createEventAction #create-ticket-action-attributes').html('');
				success_message();
				
			}
		});
	});
	
	$('#modal_container').on('click', '#save_event_action_rule_defaults', function(){
		var rule_id = $(this).data('rule-id');
		var form = $('#attribute-table').find('form');
		var form_data = form.serializeArray();
		
		var data = {};
		
		$.each(form_data, function(i, serialized_data){
			data[serialized_data['name']] = serialized_data['value'];
		});
		

		makeRequest('/event/rule/update-attributes/'+rule_id, data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('ul#all_rules_sort').append(response.html);
				$('#createEventRule').modal('hide');
				$('#editEventRule').modal('hide');
				$('#createEventRule #create-rule-attributes').html('');
				success_message();
			}
			else{
				var error = 'unable to save rule attributes';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
	
	$('#event-wrapper').on('keyup','.search-actions',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#all_actions_sort');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});
	
	
	$('#event-wrapper').on('click', '.all-action-list .edit-action',function(){
		var action_id = $(this).data('action-id');
		$.ajax({
			type: 'GET',
			url: '/event/action/update-action/'+action_id,
			dataType: 'json',
			success: function (response) {
				if (checkErrors(response)) {
					if(response.html){
						createModal('editEventAction', response.html);
						$('#edit_action_editable_attributes_modal').find('.select2:not(.multi)').select2({'width':'100%'});
						$('#edit_action_editable_attributes_modal').find('.select2.multi').select2({'width':'100%', 'tags': true});
						$('#edit_action_editable_attributes_modal input.date-filter').daterangepicker({
							"locale": {
								"format": 'DD/MM/YYYY HH:mm'		
							},
							"timePicker": true,
							"singleDatePicker": true,
							"showDropdowns": true
						});
					}
				}
			},
			error: function (error) {
				alert(error);
			}
		});
	});
	
	$('#event-wrapper').on('click', '.actions-section .edit-action',function(){
		var action_link_id = $(this).closest('.action-container').data('action-group-link-id');

		makeRequest('/event/action/update-existing-action/'+action_link_id, {'action_group_link_id' : action_link_id}, function(response){
			if (checkErrors(response)) {
				if(response.html){
					createModal('edit_action_editable_attributes_modal', response.html);
					$('#edit_action_editable_attributes_modal').find('.select2:not(.multi)').select2({'width':'100%'});
					$('#edit_action_editable_attributes_modal').find('.select2.multi').select2({'width':'100%', 'tags': true});
					$('#edit_action_editable_attributes_modal input.date-filter').daterangepicker({
						"locale": {
							"format": 'DD/MM/YYYY HH:mm'		
						},
						"timePicker": true,
						"singleDatePicker": true,
						"showDropdowns": true
					});
				}
			}
		})
	});
	
	$('#event-wrapper').on('click', '.delete-event-action-group',function(e){		
		e.stopPropagation();
		var event_action_group_id = $(this).data('action-group-id');

		var data = {
			'event_action_group_id' : event_action_group_id
		};

		var that = $(this);
		if(confirm('are you sure you want to delete this event action group?')){
			makeLoadingIcon($(this).find('i'));
			makeRequest('/admin/events/delete/action-group', data, function(response){
				if(isset(response.success)){
					$(that).closest('.action-group-container').remove();
					success_message('deleted');
				}
				else{
					var error = 'unable to delete event action group';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
				restoreIcon($(that).find('i'));
			});
		}

	});
	
	$('#modal_container').on('click', '#editEventAction #edit_action_next', function(){
		
		var that = $(this);
		
		var action_id = $(this).data('action-id');
		
		var modal_content = $(this).closest('.modal-content');
		
		var action_name = $(modal_content).find('#action_name').val();
		
		if(action_name.length < 5){
			error_message('please enter a descriptive action name');
			return false;
		}
		
		var action_type = $(modal_content).find('#action_type').val();
		var entity_type = $(modal_content).find('#entity_type').val();
		var attributes = [];
		var editable = [];
		var runtime_editable = [];
		$(modal_content).find("input[name='has_attribute']:checked").each( function () {
			attributes.push($(this).val());
		});
		$(modal_content).find("input[name='is_editable']:checked").each(function () {
			editable.push($(this).val());
		});
		$(modal_content).find("input[name='is_runtime_editable']:checked").each(function () {
			runtime_editable.push($(this).val());
		});
		
		var data = {
			'action_name': action_name,
			'action_type': action_type,
			'entity_type': entity_type,
			'attributes': attributes,
			'ediable_attributes': editable,
			'runtime_ediable_attributes': runtime_editable
		};

		makeRequest('/event/action/edit/' + action_id, data, function(response){
			if (checkErrors(response)) {
				if(response.html){
					modal_content.find('#create-ticket-action-attributes').html(response.html).collapse('show');
					modal_content.find('#create-ticket-action-config').collapse('hide');
					$(that).text('update');
					$(that).attr('id', 'save_rule_defaults');
					$(that).data('action-id', response.action_id);
					$(modal_content).find('.select2:not(.multi)').select2({'width':'100%'});
					$(modal_content).find('.select2.multi').select2({'width':'100%', 'tags': true});
					reloadActionsList();
				}
				success_message();
				$('#editEventAction input.date-filter').daterangepicker({
					"locale": {
						"format": 'DD/MM/YYYY HH:mm'		
					},
					"timePicker": true,
					"singleDatePicker": true,
					"showDropdowns": true
				});
			}
		});
	});
		
	
	$('#modal_container').on('click', '.event-attribute-toggles', function(){
		var radio = $(this).find('input');
		var value = $(radio).val();
		
		var input = $(this).closest('div.toggle-parent').find('input.form-control');
		
		if(value == 'custom'){
			$(input).parent().show();
			return;
		}
		
		$(input).parent().hide();
		
		$(input).val(value);

	});
	

	$('#event-wrapper').on('click', '.rules-section .delete-rule', function(){
		var link_id = $(this).data('trigger-rule');
		var data = {
			'link_id' : link_id
		};
		var that = $(this);
		customConfirm('are you sure you want to delete this rule?', function(){
			makeLoadingIcon($(that).find('i'));
			makeRequest('/admin/events/remove/rule', data, function(response){
				if(isset(response.success) && response.success && isset(response.html)){
					success_message();
					$(that).closest('.event-action-group-container').replaceWith(response.html);
					event_actions_sortable();
				}
				else{
					var error = 'unable to delete rule';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
				restoreIcon($(that).find('i'));
			});
		});
	});
	
	$('#event-wrapper').on('click', '.actions-section .delete-trigger', function(){
		var link_id = $(this).data('action-group');
		var data = {
			'link_id' : link_id
		};
		var that = $(this);
		customConfirm('are you sure you want to remove this action?', function(){
			makeLoadingIcon($(that).find('i'));
			makeRequest('/admin/events/remove/action', data, function(response){
				if(isset(response.success) && response.success && isset(response.html)){
					success_message();
					$(that).closest('.event-action-group-container').replaceWith(response.html);
					event_actions_sortable();
				}
				else{
					var error = 'unable to delete rule';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
				restoreIcon($(that).find('i'));
			});
		});
	});
	
	$('#event-wrapper').on('click', '.event-action-group-group-events-list .delete-trigger', function(){
		var link_id = $(this).data('pivot-id');
		var data = {
			'link_id' : link_id
		};
		var that = $(this);
		customConfirm('are you sure you want to remove this event?', function(){
			makeLoadingIcon($(that).find('i'));
			makeRequest('/admim/events/action-groups/group/remove', data, function(response){
				if(isset(response.success) && response.success){
					success_message();
					$(that).closest('.three-column-item').remove();
				}
				else{
					var error = 'unable to delete link';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
				restoreIcon($(that).find('i'));
			});
		});
	});
	
	$('#event-wrapper').on('click', '#all_rules_sort .edit-rule', function(){
		var rule_id = $(this).data('rule-id');
		var data = {
			'rule_id' : rule_id
		};
		makeRequest('/admin/events/edit/get/rule-edit', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				success_message();
				createModal('editEventActionRule', response.html);
			}
			else{
				var error = 'unable to edit rule';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});

	$('#modal_container').on('click', '#edit_rule_editable_attributes_modal #save_editable_attribute_values', function(){

		var form = $(this).closest('.modal').find('.rule-attribute-form');
		handle_special_fields(form.parent());
		var form = $(this).closest('.modal').find('.rule-attribute-form');
		var data = $(form).serializeArray();

		var sanitised_data = {};
		$.each(data, function(i, input_data){
			var link_id = $(form).find(':input[name="'+input_data.name+'"]').closest('.editable_attribute_container').data('link-id');
			if(!isset(sanitised_data[link_id])){
				sanitised_data[link_id] = {};
			}
			sanitised_data[link_id][input_data.name] = input_data.value;
		});

		makeRequest('/admin/events/edit/rule/editable-attributes', sanitised_data, function(response){
			if(isset(response.success) && response.success){
				success_message();
				$('#edit_rule_editable_attributes_modal').modal('hide');
				if(isset(response.html) && response.html && isset(response.action_group_id)){
					$('.event-action-group-container[data-action-group-id="' + response.action_group_id + '"]').replaceWith(response.html);
					event_actions_sortable();
				}
			}
			else{
				var error = 'failed to update rule values';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});


	});
	
	$('#modal_container').on('click', '#edit_action_editable_attributes_modal #save_editable_action_attribute_values', function(){

		var form = $(this).closest('.modal').find('.action-attribute-form');
		var sanitised_data = {};
		$.each($(form).find('.editable_attribute_container'), function(i,section){
			// a section could be any type of input
			var link_id = $(section).data('link-id');
			if($(section).find('.select2.multi').length){
				$.each($(section).find('.select2.multi'),function(i,multi_select){
					sanitised_data[link_id] = $(multi_select).val();
				});
			}
			else{
				var inputs = $(section).find(':input');
				if(inputs.length == 1){
					sanitised_data[link_id] = inputs[0].value;
				}
				else{
					if($(section).find('.tasks-argh').length){
						sanitised_data[link_id] = handleMagicLosseyTaskAttribute();
					}
					else if($(section).find('.event-attribute-working-days-button').length){
						sanitised_data[link_id] = handleMagicWorkingDaysAttribute(section);
					}

				}
			}
		});

		// $.each($(form).find('.select2.multi'), function(i, multi_select){
		// 	var link_id = $(multi_select).closest('.editable_attribute_container').data('link-id');
		// 	sanitised_data[link_id] = $(multi_select).val();
		// });

		makeRequest('/admin/events/edit/action/editable-attributes', sanitised_data, function(response){
			if(isset(response.success) && response.success){
				success_message();
				$('#edit_action_editable_attributes_modal').modal('hide');
				if(isset(response.html) && response.html && isset(response.action_group_id)){
					$('.event-action-group-container[data-action-group-id="' + response.action_group_id + '"]').replaceWith(response.html);
					event_actions_sortable();
				}
			}
			else{
				var error = 'failed to update action values';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});


	});


	$('body').on('submit', '#action-attribute-form', function(e){
		e.preventDefault();
		$(this).closest('.modal').find('#submit_attributes').trigger('click');
	});
	
	$('body').on('submit', '.rule-attribute-form', function(e){
		e.preventDefault();
		$(this).closest('.modal').find('#save_editable_attribute_values').trigger('click');
	});
	
	$('body').on('submit', '.rule-attribute-form', function(e){
		e.preventDefault();
		$(this).closest('.modal').find('#save_editable_action_attribute_values').trigger('click');
	});


	$('#event-wrapper').on('click', '.event-action-group-container .edit-rule', function(){

		var link_id = $(this).data('trigger-rule');

		var data = {
			'link_id' : link_id
		};
		
		makeRequest('/admin/events/get/rule/editable-attributes', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				createModal('edit_rule_editable_attributes_modal', response.html);
			}
			else{
				var message = 'unable to load editable attributes';
				if(isset(response.error)){
					message = response.error;
				}
				error_message(message);
			}
		})

	});

	$('#modal_container').on('click', '#editEventActionAttributes #submit_attributes', function(){
		
		var entity_id = $(this).data('entity-id');
		var event_id = $(this).data('event-id');
		var context = $(this).data('context');
		var form = $('#editEventActionAttributes form.action-attribute-form');

		var all_form_data = {};
	
		var data = {};

		var inputs = $(form).find(':input');
		$.each(inputs, function(i, input){
			var action_group_id = $(input).closest('.action-group-container').data('action-group-id');
			var action_id = $(input).closest('.action-group-action-container').data('action-id');
			var action_link_id = $(input).closest('.action-group-action-container').data('action-group-action-link-id');
			var attribute_id = $(input).closest('.action-group-action-attribute-container').data('attribute-id');
			var link_id = $(input).closest('.action-group-action-attribute-container').data('action-group-action-link-attribute-link-id');
			if(!isset(data[action_group_id])){
				data[action_group_id] = {
					'action_group_id' : action_group_id,
					'actions' : {}
				};
			}

			if(!isset(data[action_group_id]['actions'][action_link_id])){
				data[action_group_id]['actions'][action_link_id] = {
					'action_id' : action_id,
					'action_group_action_link_id' : action_link_id,
					'attributes' : {}
				}
			}
			
			if(!isset(data[action_group_id]['actions'][action_link_id]['attributes'][link_id])){
				data[action_group_id]['actions'][action_link_id]['attributes'][link_id] = {
					'attribute_id' : attribute_id,
					'value' : $(input).val(),
					'action_group_action_link_attribute_link_id' : link_id
				}
			}
		});
		
		all_form_data['action_groups'] = data;
		
			
		var post_data = {
			'event_id' : event_id,
			'form_data' : all_form_data,
			'context' : context
		};
		
		makeRequest('/entity/new/event/'+entity_id, post_data, function(response){
			if (checkErrors(response)) {
				$('#editEventActionAttributes').modal('hide');
				success_message();
				if(response.redirect){
					window.location = response.url;
				}
			}
			else{
				var error = 'milk eye';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});

	$('#event-wrapper').on('click', '#simulate_event_action_group', function(){
		var group_id = $(this).data('event-action-group-group-id');
		var entity_type_id = $(this).data('entity-type-id');

		var data = {
			'event_action_group_group_id' : group_id,
			'linkable_entity_type_id' : entity_type_id
		};

		var that = $(this);
		makeRequest('/simulate/event-action-group-group/event-actions', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$(that).closest('.simulate-container').find('.result').html(response.html);
				success_message();
			}
			else{
				var error = isset(response.error) ? response.error : 'unable to simulate ation groups';
				error_message(error);
			}
		})
	});
	
	$('#event-wrapper').on('click', '.linked-events-simulate-event-action-groups', function(){
		var event_id = $(this).data('event-id');
		var entity_type_id = $(this).data('entity-type-id');

		var data = {
			'event_id' : event_id,
			'linkable_entity_type_id' : entity_type_id
		};

		var that = $(this);
		makeLoadingIcon($(that).find('i'));
		makeRequest('/simulate/event/event-actions', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				var results = $(that).closest('.tab-pane').find('.simulate-container .result');
				if(!$(results).length){
					results = $('.simulate-container .result');
				}
				$(results).html(response.html);
				$(results)[0].scrollIntoView({
					behavior: "smooth", 
					block: "start"
				});
			}
			else{
				var error = isset(response.error) ? response.error : 'unable to simulate ation groups';
				error_message(error);
			}
			restoreIcon($(that).find('i'));
		});
	});

	$('#event-wrapper').on('click', '#get_logs_event_action_group', function(){
		var event_action_group_group_id = $(this).data('event-action-group-group-id');
		var entity_type_id = $(this).data('entity-type-id');

		var data = {
			'event_action_group_group_id' : event_action_group_group_id,
			'linkable_entity_type_id' : entity_type_id
		};

		$(this).closest('.simulate-container').find('.result').html("<div class='row text-center pad-me-please-20'><i class=\"fa-regular fa-circle-notch fa-spin\" aria-hidden=\"true\"></i></div>");

		var that = $(this);
		makeRequest('/getlog/event-action-group-group/event-actions', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$(that).closest('.simulate-container').find('.result').html(response.html);
				success_message();
			}
			else{
				var error = isset(response.error) ? response.error : 'unable to get event action group logs';
				$(this).closest('.simulate-container').find('.result').html("<div class='row text-center pad-me-please-20'><i class=\"fa-solid fa-exclamation-triangle\" aria-hidden=\"true\"></i></div>")
				error_message(error);
			}
		})
	});
	
	$('#event-wrapper').on('click', '#get_event_action_group_logs_for_event, .linked-events-view-log', function(){
		var event_id = $(this).data('event-id');
		var entity_type_id = $(this).data('entity-type-id');

		var data = {
			'event_id' : event_id,
			'linkable_entity_type_id' : entity_type_id
		};

		var that = $(this);
		makeLoadingIcon($(that).find('i'));
		makeRequest('/getlog/event/event-actions', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				var results = $(that).closest('.tab-pane').find('.simulate-container .result');
				if(!$(results).length){
					results = $('.simulate-container .result');
				}
				$(results).html(response.html);
				$(results)[0].scrollIntoView({
					behavior: "smooth", 
					block: "start"
				});
				success_message();
			}
			else{
				var error = isset(response.error) ? response.error : 'unable to get event action group logs';
				error_message(error);
			}
			restoreIcon($(that).find('i'));
		})
	});

	$('body').on('click', '.event-passed-entity-collapsed', function () {
		$(this).next().toggle();
	});

	$('#create_new_event_action_group_group').on('click', function(e){
		var name = $('#event_action_group_group_name').val();
		if(name.length < 4){
			error_message('please enter a group name longer than 3 characters');
		}
		var data = {
			'name' : name
		};

		makeRequest('/admim/events/action-groups/group/new', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('.event-action-group-group-list').append(response.html);
				success_message();
			}
			else{
				var error = isset(response.error) ? response.error : 'unable to create event action group group';
				error_message(error);
			}
		});
	});
	
	$('.event-action-group-groups-page').on('change', '.active-event-action-group-group-switch', function(e){
		var active = $(this).is(':checked');
		var group_id = $(this).data('group-id');
		var data = {
			'group_id' : group_id,
			'active' : active
		};

		makeRequest('/admim/events/action-groups/group/toggle', data, function(response){
			if(isset(response.success) && response.success){
				success_message();
			}
			else{
				var error = isset(response.error) ? response.error : 'unable to ' + active ? 'enable' : 'disable';
				error_message(error);
			}
		});
	});
	
	$('.event-action-group-groups-page').on('click', '.event-action-group-group-container .delete-trigger', function(e){
		var that = this;
		customConfirm('Are you sure you want to delete this group?', function(){
			var group_id = $(that).data('event-action-group-group-id');
			var data = {
				'group_id' : group_id
			};
			makeRequest('/admim/events/action-groups/group/delete', data, function(response){
				if(isset(response.success) && response.success){
					$(that).closest('.event-action-group-group-container').remove();
					success_message();
				}
				else{
					var error = isset(response.error) ? response.error : 'unable to delete';
					error_message(error);
				}
			});
		});
	});
	
	$('.event-action-group-groups-page').on('click', '.event-action-group-group-container .edit-trigger:not(.waiting)', function(e){
		$(this).addClass('waiting');
		var icon = $(this).find('i');
		makeLoadingIcon(icon)
		var group_id = $(this).data('event-action-group-group-id');
		var data = {
			'group_id' : group_id
		};
		var that = this;
		makeRequest('/admim/events/action-groups/group/edit', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('#event_action_group_group_details').html(response.html);
				event_actions_sortable();
			}
			else{
				var error = isset(response.error) ? response.error : 'unable to edit';
				error_message(error);
			}
			$(that).removeClass('waiting');
			restoreIcon(icon);
		});
	});


	$('.event-action-group-groups-page').on('keyup', '.event-action-group-search', function(){
		var to_search = $(this).val();
		if(to_search === ""){
			$('.event-action-group-list').find('li.action-group-container').show();
		} 
		else {
			$('.event-action-group-list').find('li.action-group-container').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(to_search) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});
	
	$('.event-action-group-groups-page').on('keyup', '.event-action-group-group-linked-events-search', function(){
		var to_search = $(this).val();
		if(to_search === ""){
			$('.event-action-group-group-events-list').find('li').show();
		} 
		else {
			$('.event-action-group-group-events-list').find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(to_search) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});
	
	$('.event-action-group-groups-page').on('click', '.edit-event-action-group-name', function(e){
		e.stopPropagation();
		var container = $(this).closest('.action-group-container');
		var header = $(container).find('.event-action-group-heading span.header-text');
		$(header).data('original-text', $(header).text());
		$(header).attr('contenteditable', 'true');
		$(header).focus();
		// https://stackoverflow.com/a/3866442 -- the below sets the cursor at the end of the text
		var range = document.createRange();//Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(header[0]);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        var selection = window.getSelection();//get the selection object (allows you to change selection)
        selection.removeAllRanges();//remove any selections already made
        selection.addRange(range);//make the range you have just created the visible selection
	});



	var editEventActionGroupName = function(element){
		var new_name = $(element).text();
		$(element).attr('contenteditable', 'false');

		var original_text = $(element).data('original-text');

		if(original_text == new_name){
			return false;
		}
		if(new_name.length < 4){
			error_message('please enter a group name more than 3 characters long');
			$(element).text(original_text);
			return false;
		}

		var header = $(element).closest('.event-action-group-heading');
		var edit_button = $(header).find('.edit-event-action-group-name');
		var group_id = $(edit_button).data('action-group-id');
		var data = {
			'group_id' : group_id,
			'name' : new_name
		};
		makeLoadingIcon(edit_button.find('i'));
		makeRequest('/admim/events/action-groups/group/rename', data, function(response){
			
			if(isset(response.success) && response.success){
				success_message();
			}
			else{
				var error = isset(response.error) ? response.error : 'unable to edit';
				error_message(error);
				$(element).text($(element).data('original-text'));
			}
			restoreIcon(edit_button.find('i'));
		});
	}

	$('.event-action-group-groups-page').on('blur', '.event-action-group-heading span.header-text', function(e){
		editEventActionGroupName($(this));
	});
	
	$('.event-action-group-groups-page').on('keydown', '.event-action-group-heading span.header-text', function(e){
		//apparently keyCode is being depreciated - .key is the new hotness 
		if (e.key == 'Enter') {
			e.preventDefault();
			$(this).attr('contenteditable', 'false');
			//let the blur do the update
			return false;
		}
		else if(e.key =='Escape'){
			e.preventDefault();
			$(this).text($(this).data('original-text'));
			$(this).attr('contenteditable', 'false');
		}
	});

	$('#event-wrapper').on('click', '.preview-rule', function(){
		var link_id = $(this).data('trigger-rule-id');
		var data = {
			'link_id' : link_id
		};
		makeRequest('/admim/events/action-groups/group/rule/preview', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				createModal('preview_event_action_group_group_rule_modal', response.html);
			}
			else{
				var error = 'unable to get rule data';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})
	});
	
	$('#event-wrapper').on('click', '.preview-action', function(){
		var link_id = $(this).data('action-group-link-id');
		var data = {
			'link_id' : link_id
		};
		makeRequest('/admim/events/action-groups/group/action/preview', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				createModal('preview_event_action_group_group_action_modal', response.html);
			}
			else{
				var error = 'unable to get rule data';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})
	});

	if($('.event-action-group-groups-page').length){
		reloadActionsList();
		reloadEventsList();
		reloadRulesList();
	}

	$('#event-wrapper').on('change', '.select-all-roles',function() {
		$(this).closest('.role-items').find('.role-input').prop('checked', $(this).is(':checked'));
	});

});

