var getDraftReport = function(){
	var data = localStorage.getItem('coaching_draft');
	if(data){
		data = JSON.parse(data);
	}
	if(typeof(data) == 'object'){
		return data;
	}
	return false;
};

var clearDraftReport = function(){
	localStorage.removeItem('coaching_draft');
};

var isDraftRelevant = function(container, draft){
	var relevant = false;
	if(draft !== false){
		$.each(draft, function(id, value){
			if($(container + ' #'+id).length){
				relevant = true;
			}
		})
	}
	return relevant;
};

window.openCoachingForm = function(report_id) {
	showLoader();
	makeRequest('/coaching/open', {'report_id' : report_id}, function(response){

		hideLoader();
		if(isset(response.success) && response.success && isset(response.html)){
			createModal('coaching_form_modal', response.html);
			$('.date-filter.coaching').daterangepicker({
				timePicker: true,
				"singleDatePicker": true,
				"showDropdowns": true,
				"locale": {
					"format": 'DD-MM-YYYY H:mm',
					"separator": "-"
				}
			});
			$('#coaching_form_modal .select2:not(#type_id)').select2({'width' : '100%', 'height' : '20px'});
			$('#coaching_form_modal #type_id.select2').select2({'width' : '100%',  'tags': true, 'height' : '20px'});
			$('.coaching-details-item.save-button').hide();
			var draft = getDraftReport();
			if(draft !== false && isDraftRelevant('#coaching_form_modal', draft)){
				$('.restore-coaching-inputs-alert').show();
			}
		}
		else{
			var error = 'whoops';
			if(isset(response.error)){
				error = response.error;
			}
			error_message(error);
		}

	});
};

$(document).ready(function(){

	$('div#coaching_script[name="coaching-energy"], div#lead_script[name="coaching-energy"]').on('click','#coaching-start',function(){

		var order_id = $(this).closest('div[name="coaching-energy"]').data("quoteid");
		var ident = $(this).closest('div[name="coaching-energy"]').data("scriptid");
	
		//grab user id connected to the script this may be overridden by a manager
		//var user_id = $('#script-tabs').find("[data-truename='coaching-energy']").data('scriptuserid');
		// update the order to compliance started.  //
		$.ajax({ 
			cache: false,
			url: "/coaching/start/"+order_id,//+"/"+user_id,
			dataType: 'json',
			success: function (response) {
				if(response.success === true){
					//console.log(ident+'.introduction');
					window.coaching_script.handleStep(ident+'.introduction');
				}
				else{
					alert(response.message);
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		//		'/coaching/start/'+order_id;
	});
	
	$('div#coaching_script[name="coaching-energy"], div#lead_script[name="coaching-energy"]').on('click','#coaching-complete',function(){

		var order_id = $(this).closest('div[name="coaching-energy"]').data("quoteid");
		var ident = $(this).closest('div[name="coaching-energy"]').data("scriptid");
		
		var notes = $('#coaching_additional_notes').val();
		// update the order to coaching started.  //
		$.ajax({ cache: false,
			url: "/coaching/completed/"+order_id,
			type:"POST",
			dataType: 'json',
			data : {additional_notes: notes },
			success: function (response) {
				if(response.success === true){
					window.coaching_script.handleStep(ident+'.show_report');
				}
				else{
					alert(response.message);
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		//		'/compliance/start/'+order_id;
	});
	
	$('div[name="coaching-energy"][id="coaching_script"]').on('click','#coaching-form-continue',function(){
		$('.coaching-form-group').submit();
	});
	
	
	$('div[name="coaching-energy"][id="coaching_script"]').on('submit','.coaching-form-group', function(e){
		e.preventDefault();
		
		var order_id = $('div[name="coaching-energy"][id="coaching_script"]').data("quoteid");
		
		$('.error').html('');
		var form_name = this.id;
		var data = $(this).serialize();
		var action = $(this).attr('action');
		$.ajax({ cache: false,
			url: "/coaching/validate/"+order_id,
			type:"POST",
			dataType: 'json',
			data: { form_name : form_name, data : data, action:action },
			success: function (response) {
				if(response.success === true){
					window.coaching_script.handleStep(action);
				}
				else{
					if(response.errors){
						$.each(response.errors, function(target,message){
							$('#'+target).html(message.error);
						});
					
					}
					else{
						alert(response.message);
					}
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});
	
	//quick report print, without resorting to plugins.
	$('div#coaching_script[name="coaching-energy"], div#lead_script[name="coaching-energy"]').on('click','#coaching-print-report', function(e) {
		var mywindow = window.open('', 'PRINT', 'height=800,width=800');
		mywindow.document.write('<html><head><title>' + document.title +' | Coaching Report </title>');
		mywindow.document.write('</head><body >');
		mywindow.document.write($('div#coaching_script[name="coaching-energy"], div#lead_script[name="coaching-energy"]').find('#print-report').html());
		mywindow.document.write('</body></html>');

		mywindow.document.close(); // necessary for IE >= 10
		mywindow.focus(); // necessary for IE >= 10*/

		mywindow.print();
		mywindow.close();

		return true;
	});
	
	var fixed = $('.affix');
	fixed.on('touchmove', function(e) {
        e.preventDefault();
	}, false);


	$('body').on('click', '.coaching-report-open', function(){
		var report_id = $(this).data('report-id');
		window.openCoachingForm(report_id);
	});

	$('body').on('change', '.coaching-details :input:not([type="range"])', function(){
		$('.coaching-details-item.save-button').show();
	});


	$('body').on('click', '#save_coaching_report_details', function(e){
		e.preventDefault();
		var data = {};
		data.created_at = $('#coaching_created_at').val();
		data.coach_id = $('#coach_id').val();
		data.transaction_id = $('#transaction_id').val();
		data.type_id = $('#type_id').val();
		data.report_id = $(this).data('report-id');
		showLoader();
		makeRequest('/coaching/update_details', data, function(response){
			hideLoader();
			if(isset(response.success) && response.success){
				$('.coaching-details-item.save-button').hide();
				success_message();
			}
			else{
				var error = 'whoops';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});

	$('body').on('click', '#update_coaching_report', function(e){
		e.preventDefault();
		var data = {};
		data.positive_feedback = $('#coaching_form_modal #positive_feedback').val();
		data.negative_feedback = $('#coaching_form_modal #negative_feedback').val();
		data.improvement_feedback = $('#coaching_form_modal #improvement_feedback').val();
		data.agent_feedback = $('#coaching_form_modal #agent_feedback').val();
		data.type_id = $('#coaching_form_modal #type_id').val();
		data.report_id = $(this).data('report-id');
		data.status_id = $('#coaching_form_modal #status_id').val();
		data.agent_rating = $('#coaching_form_modal i.og-star').length;
		if($('.twinkle-twinkle').length && data.agent_rating == 0){
			if(!confirm('are you sure you want to give your coaching 0 stars?')){
				return;
			}
		}
		$('#coaching_form_modal').modal('hide');
		showLoader();
		makeRequest('/coaching/update_details', data, function(response){
			hideLoader();
			if(isset(response.success) && response.success){
				$('.coaching-details-item.save-button').hide();
				success_message();
				clearDraftReport();
			}
			else{
				var error = 'whoops';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});

	$('body').on('mouseenter', '.twinkle-twinkle i', function(e){
		$(this).addClass('fa-star');
		$(this).prevAll('i').addClass('fa-star');
		$(this).removeClass('fa-star-o');
		$(this).prevAll('i').removeClass('fa-star-o');
	});
	$('body').on('mouseleave', '.twinkle-twinkle i', function(e){
		if(!$(this).hasClass('og-star')){
			$(this).addClass('fa-star-o');
			$(this).prevAll('i:not(.og-star)').addClass('fa-star-o');
			$(this).removeClass('fa-star');
			$(this).prevAll('i:not(.og-star)').removeClass('fa-star');
		}
	});
	$('body').on('click', '.twinkle-twinkle i', function(e){
		$(this).addClass('fa-star');
		$(this).prevAll('i').addClass('fa-star');
		$(this).removeClass('fa-star-o');
		$(this).prevAll('i').removeClass('fa-star-o');
		$(this).addClass('og-star');
		$(this).prevAll('i').addClass('og-star');
		$(this).nextAll('i').removeClass('og-star');
		$(this).nextAll('i').removeClass('fa-star');
		$(this).nextAll('i').addClass('fa-star-o');
	});

	$('body').on('click', '.coaching-report-delete', function(e){
		if(confirm('Are you sure you want to delete this coaching report?')){
			var report_id = $(this).data('report-id');
			
			var data = {
				'report_id' : report_id
			};

			var that = $(this);

			makeRequest('/coaching/delete_report', data, function(response){
				hideLoader();
				if(isset(response.success) && response.success){
					$(that).closest('tr').remove();
					success_message('deleted');
				}
				else{
					var error = 'whoops';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			});

		}
	});



	$('body').on('blur', '#coaching_form_modal textarea', function(){
		var current_data = getDraftReport();
		if(!current_data){
			current_data = {};
		}
		current_data[$(this).attr('id')] = $(this).val();
		localStorage.setItem('coaching_draft', JSON.stringify(current_data));
	});
	
	$('body').on('click', '#coaching_form_modal .restore-button-outline', function(){
		var draft = getDraftReport();
		if(!draft){
			draft = {};
		}
		$.each(draft, function(id, value){
			$('#coaching_form_modal #'+id).val(value);
		})
		$(this).closest('.restore-coaching-inputs-alert').hide();
	});

});
