window.load_state = false;

window.showChatLoader = function(){
	$('.chat-loader-overlay').show();
}

window.hideChatLoader = function(){
	$('.chat-loader-overlay').hide();
}

window.chatConfirmation = function(text, callback = function(){}){
	$('.chat-confirmation-overlay #chat-confirm-confirm').off();
	$('.chat-confirmation-overlay').find('.chat-confirmation-text').html(text);
	$('.chat-confirmation-overlay').show();
	$('.chat-confirmation-overlay #chat-confirm-confirm').on('click', function(){ $('.chat-confirmation-overlay').hide(); callback()});
}


var who_is_typing = {
	//chat_id => {'name' : time}
};

var last_typing_sent = false;

var startTyping = function(){
	var tpying_sensitivity = 500; //milliseconds
	var milliseconds_since_last_update = moment().diff(last_typing_sent);
	if(last_typing_sent === false || milliseconds_since_last_update > tpying_sensitivity){
		last_typing_sent = moment();
		var active_window = $('.active-chat-window-container');
		var chat_id = $(active_window).data('chat-id');

		chatInterface.sendTypingNotification(chat_id);
	}
}


var buidTypingString = function(people){
	if(people.length){
		if(people.length > 2) {
			return people.length + ' people are typing';
		}
		else if(people.length == 2){
			return people.join(' and ') + 'are typing';
		}
		else{
			return people[0] + ' is typing';
		}
	}
	return '';
}

var renderTypingIndicator = function(){
	var active_window = $('.active-chat-window-container');
	var chat_id = $(active_window).data('chat-id');
	var stale_timeout = 750; //milliseconds
	var typing_people = [];
	if(isset(who_is_typing[chat_id])){
		$.each(who_is_typing[chat_id], function(name, time){
			var milliseconds_since_update = moment().diff(time);
			if(milliseconds_since_update > stale_timeout){
				delete who_is_typing[chat_id][name];
			}
			else{
				typing_people.push(name);
			}
		});
	}
	$('div.typing-indicator').text(buidTypingString(typing_people));
	if(typing_people.length){
		setTimeout(function(){renderTypingIndicator()}, 500);
	}
}


window.receiveTypingNotification = function(chat_id, name){
	if(!isset(who_is_typing[chat_id])){
		who_is_typing[chat_id] = {};
	}
	who_is_typing[chat_id][name] = moment();
	renderTypingIndicator();
}

var receivedAMessageSoTheyCantStillBeTpying = function(chat_id, name){
	if(isset(who_is_typing[chat_id]) && isset(who_is_typing[chat_id][name])){
		delete who_is_typing[chat_id][name];
		renderTypingIndicator();
	}
}



var setLastChatId = function(chat_id){
	localStorage.setItem('last_chat_id', chat_id);
}

var getLastChatId = function(){
	return localStorage.getItem('last_chat_id');
}

var getChatBubbleClass = function(sender_name, type){
	if(type == 'system'){
		return 'system';
	}
	return sender_name ? 'recipient-message' : 'sent-message';
};

var truncateMessage = function(message){
	if(message.length > 80){
		var message = message.substr(0, 80);
		message += '...';
	}
	return message;
}

var openMessagePaneWithChat = function (chat_id, callback =function(){}) {
    var data = {
		'chat_id' : chat_id
	};
    makeRequest('/chat/chat-pane', data, function(response){
        if(isset(response.success) && response.success && response.html){
			$('.open-message-pane-container').html(response.html);
			callback();
			if(isset(response.unread)){
				setNotificationCount(response.unread);
			}
        }
        else {
			var error = 'unable to open chat';
			if(isset(response.error)){
				error = response.error;
			}
            error_message(error);
        }
    });
};

var addAutoResize = function(){
	$('#live_chat_content').autoResize()
}

var openSpecificChat = function(chat_id){
	setLastChatId(chat_id);
	var pane_control = $('.show-message-pane');
	if($(pane_control).data('state') == 'closed') {
		$(pane_control).data('state', 'open');
		//lets got get the pane with the specific chat loaded

		openMessagePaneWithChat(chat_id, function(){
			$('#message-pane-container').show();
			startNewChatEventListener();
			$($('.chat-window-message-container')).scrollTop($($('.chat-window-message-container'))[0].scrollHeight);
			$('.new-chat-window').hide();
			$('.empty-chat-window').hide();
			addAutoResize();
			$('#live_chat_content').focus();
			if(!chatInterface.isConnected()){
				showChatLoader();
			}

		});
	}
	else {
		if($(pane_control).data('state') == 'minimised') {
			$('.page-message-pane').removeClass('minimise-message-pane');
			$(pane_control).data('state', 'open');
			resetMinimiseButton($('.restore-message-pane'));
			$('.open-message-pane-container').show();
			$('.collapsed-message-pane').hide();
		}
		//the chat window is open -- lets go get our chat
		var active_chat = $('.active-chat-window-container').data('chat-id');
		if(active_chat != chat_id){
			openChat(chat_id, function(){
				anchorMessageWindow();
				$('.directory-item').removeClass('active-directory-item');
				$('.directory-item[data-chat-id="' +chat_id+'"]').addClass('active-directory-item');
			});
			$('#live_chat_content').focus();
		}
	}
}

var addNotificationCount = function(){
	if($('.chat-notification-count').hasClass('hide')){
		$('.chat-notification-count').removeClass('hide');
	}
	$('.chat-notification-count').text(parseInt($('.chat-notification-count').text()) +1);
	$('.chat-notification-count').removeClass('heartbeat');
	setTimeout(function(){$('.chat-notification-count').addClass('heartbeat');}, 10);
};

var setNotificationCount = function(value){
	if(value == 0){
		$('.chat-notification-count').addClass('hide');
	}
	else{
		$('.chat-notification-count').removeClass('heartbeat');
		setTimeout(function(){$('.chat-notification-count').addClass('heartbeat');}, 10);
	}
	$('.chat-notification-count').text(value);
};

var parseMessageBody = function (content) {
    var temp_body_pre = content.replace('@((https?://)?([-\w]+\.[-\w\.]+)+\w(:\d+)?(/([-\w/_\.]*(\?\S+)?)?)*)@', '<a href="$1" target="_blank">$1</a>');
    return temp_body_pre.replace('/<a\s[^>]*href\s*=\s*"((?!https?:\/\/)[^"]*)"[^>]*>/i', '<a href="http://$1" target="_blank">');
};

// function replace_content(content) {
//     var exp_match = /(\b(https?|):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
//     var element_content = content.replace(exp_match, "<a href='$1'>$1</a>");
//     var new_exp_match = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
//     var new_content = element_content.replace(new_exp_match, '$1<a target="_blank" href="http://$2">$2</a>');
//     console.log(new_content);
//     return new_content;
// };



function parseContent(content) {
    var parsed_content, replace_pattern_1, replace_pattern_2, replace_pattern_3;

    replace_pattern_1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    parsed_content = content.replace(replace_pattern_1, '<a href="$1" target="_blank">$1</a>');

    replace_pattern_2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    parsed_content = parsed_content.replace(replace_pattern_2, '$1<a href="http://$2" target="_blank">$2</a>');

    replace_pattern_3 = /((crm\.discount)[\S]+(\b|$))$/gim;
    parsed_content = parsed_content.replace(replace_pattern_3, '<a href="https://$1" target="_blank">$1</a>');

    return parsed_content;
};

window.renderChatMessage = function (chat_id, message_id, content, timestamp, sender_name = false, type = 'message', color = null) {
    var body = parseContent(content);

	var container = $('.chat-container');

	var active_window = $(container).find('.active-chat-window-container');

    active_window.removeClass('unread-directory-item');

	if(sender_name){
		receivedAMessageSoTheyCantStillBeTpying(chat_id, sender_name);
	}
	var received = sender_name !== false;

	var top_class = getChatBubbleClass(sender_name, type);
	if(!sender_name){
		sender_name = $(container).find('.templates .chat-bubble-template').find('.message-bubble-timestamp').data('current-user-name');
	}

	var read = false;
    $('.collapsed-message-pane').find('.count-notification-col').addClass('notification-window');
	var count_container = $('.collapsed-message-pane').find('.count-notification-col span');
	var current_count = $(count_container).html();
	var count = parseInt(current_count) + 1;
	$(count_container).html(count);

	$('.collapsed-message-pane').find('.message-content-col').html(body);

	$('.collapsed-message-pane').find('.timestamp-col').html(timestamp);

	if(active_window.data('chat-id') == chat_id){

		//our chat is open -- just show it

		var template = $(container).find('.templates .chat-bubble-template').first().clone();
		$(template).find('.message-bubble').addClass(top_class);
		$(template).find('.message-bubble').data('message-id', message_id);
		$(template).find('.message-bubble-body').html(body);
        $(template).find('.message-bubble-header').css('color', color).html(sender_name);
        $(template).find('.message-bubble-timestamp').html(timestamp);
		$(template).appendTo($('.chat-window-message-container'));
		$($('.chat-window-message-container')).scrollTop($($('.chat-window-message-container'))[0].scrollHeight);

		if(received){
			// lets tell the ws that we have read that message :)
			chatInterface.markMessageRead(message_id);
			read = true;
		}

	}
	//need to update the left panel
	var directory_chat_container =  $(container).find('.directory-item[data-chat-id="' +chat_id+'"]');
	if(directory_chat_container.length){
	    if(active_window.data('chat-id') != chat_id) {
            $(directory_chat_container).addClass('unread-directory-item');
        }
        $(directory_chat_container).find('.chat-sender').html(sender_name + ' - ' + truncateMessage(body));
		$(directory_chat_container).find('.chat-timestamp').html(timestamp);
	}

	if(received){
        $('.chat-pop-audio')[0].play();
		//it is not from us
		minimisedChatNotification(body, timestamp);
		showNotification(sender_name + ' said :', truncateMessage(body), function(){
			//need to open the chat to the correct view
			openSpecificChat(chat_id);
		});
		if(!read){
			addNotificationCount();
		}
	}
};


var minimisedChatNotification = function (content, timestamp) {
    var body = parseContent(content);
    var container = $('.collapsed-message-pane').show();

    $(container).find('.count-notification-col').addClass('notification-window').find('span').html(1);
    $(container).find('.message-content-col').html(body);
    $(container).find('.timestamp-col').html(timestamp);

    // $('.page-message-pane').addClass('notification-window');
};


var openMessagePane = function (callback) {
	var data = {};

	makeRequest('/chat/message-pane', data, function(response){
		if(isset(response.success) && response.success && response.html){
			$('.open-message-pane-container').html(response.html);
			addAutoResize();
			callback();
			if(!chatInterface.isConnected()){
				showChatLoader();
			}
		}
		else {
			//error handle
		}
	});
	
};

var startNewChat = function (callback) {
    var users = $('.new-chat-window').find('#contact_selection').val();
    var teams = $('.new-chat-window').find('#team_selection').val();
    var name = $('.new-chat-window').find('#chat_name').val();
    var content = $('.new-chat-window').find('#new_chat_content').val();
    var data = {
        'users': users,
        'name': name,
        'teams': teams,
        'content': content
    };

    makeRequest('/chat/new', data, function(response){
        if(isset(response.success) && response.success && response.html){
            $('.open-message-pane-container').html(response.html);
            callback();
            //move to active chat window and reload the directory
        }
        else {
            //error handle
        }
    });
};

var startNewDirectChat = function (callback) {
    var user = $('.new-direct-chat-window').find('#direct_contact_selection').val();
    var content = $('.new-direct-chat-window').find('#new_direct_chat_content').val();

    var data = {
        'user': user,
        'content': content
    };

    makeRequest('/chat/new-direct', data, function(response){
        if(isset(response.success) && response.success && response.html){
            $('.open-message-pane-container').html(response.html);
            callback();
            //move to active chat window and reload the directory
        }
        else {
            //error handle
        }
    });
};

var addToChat = function(chat_id, callback) {
    var users = $('.add-to-chat-window').find('#add_contact_selection').val();
    var teams = $('.add-to-chat-window').find('#add_team_selection').val();

    var data = {
        'users': users,
        'teams': teams,
        'chat_id': chat_id
    };

    makeRequest('/chat/add-to-chat', data, function(response){
        if(isset(response.success) && response.success){
            // $('.open-message-pane-container').html(response.html);
            $('#add_team_selection').val('', '');
            $('#add_contact_selection').val('', '');
            callback(chat_id);
            //move to active chat window and reload the directory
        }
        else {
            //error handle
        }
    });
};

window.openChat = function (chat_id, callback) {
	setLastChatId(chat_id);
    var data = {
        'chat_id': chat_id
    };

    makeRequest('/chat/open', data, function(response){
        if(isset(response.success) && response.success && response.html){
            $('.new-direct-chat-window').hide();
            $('.new-chat-window').hide();
            $('.add-to-chat-window').hide();
            $('.active-chat-window').html(response.html).show();

            $('body').on('click', '.load-more-messages', function () {
                var message_id = $(this).data('message-id');
                var chat_id = $('.active-chat-window-container').data('chat-id');
                loadMoreMessages(message_id, chat_id, function () {
                    var message_id_update = $('.sub-chat-window-message-container').find('.message-bubble').first().data('message-id');
                    $('.load-more-messages').data('message-id', message_id_update);
                    resetMessageWindow(1);
                    load_state = false;
                });
			});
			addAutoResize();
			$('#live_chat_content').focus();
			callback();
			if(isset(response.unread)){
				setNotificationCount(response.unread);
			}
        }
        else {
            //error handle
        }
    });
};

var sendMessage = function (content, chat_id, color = null) {

	chatInterface.sendMessage(chat_id, content, color);
	renderChatMessage(chat_id, null, content, 'now', false, null, color);
    // makeRequest('/chat/send-message', data, function(response){
    //     if(isset(response.success) && response.success && response.html){
    //         $('.chat-window-message-container').append(response.html);
    //         callback();
    //     }
    //     else {
    //         //error handle
    //     }
    // });
};

var removeDuplicateDateNotices = function(){
	var date_notices = $('.date-content');
	var found = [];
	$.each(date_notices, function(i, item){
		var class_string = $(item).attr('class');
		var date = class_string.split(' ')[1];
		if(found.indexOf(date) === -1){
			found.push(date);
		}
		else{
			$(item).hide();
		}
	});
}

var loadMoreMessages = function (message_id, chat_id, callback) {
    if(!load_state) {
        var data = {
            'message_id': message_id,
            'chat_id': chat_id
        };
        load_state = true;
        makeRequest('/chat/load-messages', data, function (response) {
            if (isset(response.success) && response.success) {
                if (response.html) {
                    $('.chat-window-message-container').find('.sub-chat-window-message-container').prepend(response.html);
					callback();
					removeDuplicateDateNotices();
                }
                if (response.message) {
                    success_message(response.message);
                    $('.load-more-messages').remove();
                    load_state = false;
                }
            } 
			else {
                if (response.error) {
                    error_message(response.error);
                    load_state = false;
                } 
				else {
                    error_message('Unknown Error');
                    load_state = false;
                }
            }
        });
    }
};

window.anchorMessageWindow = function () {
    var container = $('.chat-window-message-container');
    $(container).scrollTop($(container)[0].scrollHeight);
};

var resetMessageWindow = function (position = 0) {
    var container = $('.chat-window-message-container');
    $(container).scrollTop(position);
};

var resetRestoreButton = function (that) {
    $(that).removeClass('hide-message-pane').addClass('restore-message-pane');
    $('.restore-message-pane-icon').show();
    $('.hide-message-pane-icon').hide();
};

var resetMinimiseButton = function (that) {
    $(that).addClass('hide-message-pane').removeClass('restore-message-pane');
    $('.restore-message-pane-icon').hide();
    $('.hide-message-pane-icon').show();
};

var searchEventListener = function () {
    $('body').on('keyup', '#search_chats', function () {
        var value = $('#search_chats').val().toLowerCase();
        $.each($('.directory-item'), function (i, selector) {
            var search_text = $(selector).data('original-title');
            var chat_name = $(selector).find('.chat-name').html();
            var chat_message = $(selector).find('.chat-sender').html();
            if(value != '') {
                if (isset(search_text) && search_text.length && search_text.toLowerCase().indexOf(value) >= 0) {

                }
                else {
                    if(isset(chat_name) && chat_name.length && chat_name.toLowerCase().indexOf(value) >= 0) {

                    }
                    else {
                        if(isset(chat_message) && chat_message.length && chat_message.toLowerCase().indexOf(value) >= 0) {

                        }
                        else {
                            $(selector).hide();
                        }
                    }
                }
            }
            else {
                $(selector).show();
            }
        });
    });
};

var startNewChatEventListener = function () {
    $('body').on('click', '.start-new-chat', function () {
        $('.new-direct-chat-window').hide();
        $('.new-chat-window').show();
        $('.add-to-chat-window').hide();
        $('.empty-chat-window').hide();
        $('.active-chat-window').hide();
        $('#contact_selection').select2({'width':'100%'});
        $('#team_selection').select2({'width':'100%'});
    });

    $('body').on('click', '#begin_new_chat', function () {
        startNewChat(function () {

            var chat_id = $('#active_chat_window').find('.bind_chat_trigger').data('chat-id');
            openChat(chat_id, function () {
                $('#contact_message').val('');
                anchorMessageWindow();
                $('.users-tooltip').tooltip({html:true});
            });
        });
    });

    $('body').on('click', '#begin_new_direct_chat', function () {
        startNewDirectChat(function () {

            var chat_id = $('#active_chat_window').find('.bind_chat_trigger').data('chat-id');
            openChat(chat_id, function () {
                $('#contact_message').val('');
                anchorMessageWindow();
                $('.users-tooltip').tooltip({html:true});
            });
        });

    });

    $('body').on('click', '.start-new-direct-chat', function () {
        $('.new-direct-chat-window').show();
        $('.new-chat-window').hide();
        $('.add-to-chat-window').hide();
        $('.empty-chat-window').hide();
        $('.active-chat-window').hide();
        $('#direct_contact_selection').select2({'width':'100%'});
    });
    searchEventListener();
};

var createChatPopElement = function () {
    var audioElement = '<audio class="chat-pop-audio" hidden="hidden" style="display:none;" src="/sounds/chat/chat_pop.mp3">';
    $('body').append(audioElement);
};

$(document).ready(function() {
    createChatPopElement();

    // $(window).on('beforeunload', function(){
    //     if($('#show_messages_icon').length && chatInterface){
    //         chatInterface.closeConnection();
    //     }
    // });

    $('body').on('click', '.directory-item', function () {
        var that = $(this);
        var chat_id = $(this).data('chat-id');
        openChat(chat_id, function () {
            $(that).removeClass('unread-directory-item');
            $('.directory-item').removeClass('active-directory-item');
            $(that).addClass('active-directory-item');
            anchorMessageWindow();
            $('.users-tooltip').tooltip({html:true});

            $('.chat-window-message-container').scroll(function() {
                var that = $(this);
                if($('.load-more-messages').length) {
                    var position = $(that).scrollTop();

                    if(position == 0) {
                        $('.load-more-messages').trigger('click');
                    }
                }
            });
        });
    });

    $('body').on('click', '#send-message', function () {
        var chat_id = $(this).closest('.active-chat-window-container').data('chat-id');
        var content = $('#live_chat_content').val();
        var color = $('#live_user_color').val();
        // var attachments = $('');

        if(content.length) {
            sendMessage(content, chat_id, color);
			$('#live_chat_content').val('');
			anchorMessageWindow();
        }
        else {
            //error enter message
        }
    });

    $('body').on('click', '.show-message-pane', function () {
		var last_chat_id = getLastChatId();
		if(last_chat_id){
			openSpecificChat(last_chat_id);
		}
		else{
			if($(this).data('state') == 'closed') {
				$(this).data('state', 'open');
				openMessagePane(function () {
					$('#message-pane-container').show();
					startNewChatEventListener();
					$('.users-tooltip').tooltip({html:true});
				});
			}
			else {
				if($(this).data('state') == 'minimised') {
					$('.page-message-pane').removeClass('minimise-message-pane');

					$(this).data('state', 'open');


					resetMinimiseButton($('.restore-message-pane'));

					$('.open-message-pane-container').show();
					$('.collapsed-message-pane').hide();
				}
				// else {
				//     console.log($(this).data('state'));
				// }
			}
		}
    });

    // $('body').on('click', '.close-message-pane', function () {
    //     $('.show-message-pane').data('state', 'closed');
    //     $('.open-message-pane-container').html().show();
    //     $('#message-pane-container').hide();
    //     $('.page-message-pane').removeClass('minimise-message-pane');
    // });

    $('body').on('click', '.hide-message-pane', function () {
        var that = $(this);
        resetRestoreButton(that);
        $('.show-message-pane').data('state', 'minimised');
        $('.page-message-pane').addClass('minimise-message-pane');
        $('.collapsed-message-pane').show();
        $('.open-message-pane-container').hide();
    });

    $('body').on('click', '.restore-message-pane', function () {
        var that = $(this);
        resetMinimiseButton(that);
        $('.show-message-pane').trigger('click');
    });

    $('body').on('click', '.chat-add', function () {
        var chat_id = $(this).data('chat-id');
        $('.new-direct-chat-window').hide();
        $('.new-chat-window').hide();
        $('.add-to-chat-window').show();
        $('.empty-chat-window').hide();
        $('.active-chat-window').hide();
        $('#add_contact_selection').select2({'width':'100%'});
        $('#add_team_selection').select2({'width':'100%'});
        $('#add_to_chat').data('chat-id', chat_id);
    });
	
	$('body').on('click', '.chat-close', function () {
		var chat_id = $(this).data('chat-id');
		chatConfirmation('Are you sure you want to close this chat?', function(){
			chatInterface.closeChat(chat_id);
			openChat(chat_id);
		});
    });
	
	$('body').on('click', '.chat-delete', function () {
		var chat_id = $(this).data('chat-id');
		chatConfirmation('Are you sure you want to delete this chat? <br> (this cannot be undone)', function(){
			chatInterface.deleteChat(chat_id);
			$('.active-directory-item').hide();
			$('.active-chat-window ').hide();
			$('.new-direct-chat-window').show();
			setLastChatId('');
		});
    });
	
    $('body').on('click', '#add_to_chat', function () {
        var chat_id = $('#add_to_chat').data('chat-id');
        addToChat(chat_id, function (chat_id) {
            openChat(chat_id, function () {
                $('#contact_message').val('');
                anchorMessageWindow();
                $('.users-tooltip').tooltip({html:true});
            });
        });
    });

    // $('body').mouseup(function (e) {
    //     if($('.open-message-pane-container').length && $('.show-message-pane').data('state') == 'open') {
    //         var container = $('.open-message-pane-container');
    //         if (!container.is(e.target) && container.has(e.target).length === 0) {
    //             $('.show-message-pane').data('state', 'minimised');
    //             $('.page-message-pane').addClass('minimise-message-pane');
    //             resetRestoreButton($('.hide-message-pane'));
    //             container.hide();
    //         }
    //     }
    // });

    $('body').on('keypress', '#live_chat_content', function (e) {
        if (e.keyCode == 10 || e.keyCode == 13) {
			$('#send-message').trigger('click');
			e.preventDefault();
		}
		else{
			//need to post typing indicator
			startTyping();
		}
    });
	
	$('body').on('click', '.chat-nudge', function (e) {
		var type_id = 2;
		var container = $(this).closest('.active-chat-window-container');
		var chat_id = $(container).data('chat-id');
		var user_name =  $('.templates .chat-bubble-template').find('.message-bubble-timestamp').data('current-user-name');
		var content = ' - ' + user_name + ' sent a nudge - ';
		chatInterface.sendMessage(chat_id, content, null, type_id);
		renderChatMessage(chat_id, null, content , 'now', false, 'system');
	});

    searchEventListener();

	//default to the page load so the user doesn't land and get bombarded 
	var last_nudge_time = moment();

	window.nudgeUser = function ( callback = function(){}) {
		
		var nudge_limit = 5; //seconds
		var seconds_since_last_nudge = moment().diff(last_nudge_time, 'seconds');
		if(seconds_since_last_nudge > nudge_limit){
			$('#page-content-wrapper').removeClass('nudge-animation');
			setTimeout(function(){$('#page-content-wrapper').addClass('nudge-animation');}, 10);
			last_nudge_time = moment();
			callback();
		}
	};

	$('body').on('click', '.load-more-messages', function () {
		var message_id = $(this).data('message-id');
		var chat_id = $('.active-chat-window-container').data('chat-id');
		loadMoreMessages(message_id, chat_id, function () {
			var message_id_update = $('.sub-chat-window-message-container').find('.message-bubble').first().data('message-id');
			$('.load-more-messages').data('message-id', message_id_update);
			resetMessageWindow(1);
			load_state = false;
		});
	});

	$('body').on('scroll', '.chat-window-message-container', function() {
		var that = $(this);
		if($('.load-more-messages').length) {
			var position = $(that).scrollTop();

			if(position == 0) {
				$('.load-more-messages').trigger('click');
			}
		}
	});
	
	$('body').on('click', '#chat-confirm-cancel', function() {
		$(this).closest('.chat-confirmation-overlay').hide();
	});
	
});
