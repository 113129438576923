class ScriptInput {
	
	constructor(){ 
		var container = false;
	}

	renderTemplate(template_class, data = {}){
		var template = $(this.container).find('.templates .' + template_class + '.template');
		var html = $(template).html();
		$.each(data, function(key, value){
			var regex = new RegExp('{{'+key+'}}', 'g');
			html = html.replace(regex, value);
		})
		return html;
	}

}


window.ScriptInput = ScriptInput;
