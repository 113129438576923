$(document).ready(function() {
	$.fn.dataTable.ext.errMode = 'throw';
	
    const datatable = $('#coaching-table').DataTable({
        dom: 'flrtiBp',
		ajax: "/get/coaching",
        pageLength: 40,
        ordering: true,
		responsive: true,
		bAutoWidth:false,
		autoWidth: false,
        lengthMenu: [25, 40, 100],
		buttons: [
                'copyHtml5',
                'print',
                {
                    extend: 'collection',
                    text: 'Export',
                    buttons: [
                        'csvHtml5',
                        'pdfHtml5'   
                    ]
                }
		],
		initComplete : function(settings, json){

			if(urlParam('id')){
				if($('.coaching-report-open[data-report-id="'+urlParam('id')+'"]').length){
					$('.coaching-report-open[data-report-id="'+urlParam('id')+'"]').trigger('click');
				}
			}
            this.api().columns([0,1,2,3,4]).every( function () {
                var column = this;
				if($(column.header()).find('span').length){
					var title = $(column.header()).find('span').text();
				}
				else{
					var title = $(column.header()).text();
				}

				var select = $('<br><select class="pdf-hide" style="text-align: center; margin-right:20px; border-radius:4px; color:black; width:100%;"><option></option></select>')
					.on( 'change', function (e) {
						e.stopPropagation();
						var val = $.fn.dataTable.util.escapeRegex(
							$(this).val()
						);

						column
							.search( val ? ''+val+'' : '', true, false )
							.draw();
					} );
				column.data().unique().sort().each( function ( d, j ) {
					if(column.search() === '^'+d+'$'){
						select.append( '<option value=\''+$(d).text()+'\' selected="selected">'+$(d).text()+'</option>' )
					} 
					else {
						if($(d).length){
							if($(d).filter('i.fa-star-o').length || $(d).filter('i.fa-star').length){
								select.append( '<option value=\''+$(d).filter('i.fa-star').length+'\'>'+$(d).filter('i.fa-star').length+'</option>' )
								select.unbind();
								select.on( 'change', function () {			
									column
										.search( 'fa-star-o', true, false )
										.draw();
								} );
							}
							else{
								select.append( '<option value=\''+$(d).text()+'\'>'+$(d).text()+'</option>' )
							}
						}
						else{
							select.append( '<option value=\''+d+'\'>'+d+'</option>' )
						}
					}
				} );
				$(column.header()).html('<span>'+title+'</span>');
				$(column.header()).append(select);
            });
        }
    });
	
	if($('input[name="coaching-date-filter"]').length > 0){
		$(function() {
		//date picker for changing compliance report filter range (separate reload, may want to do more with this)
		$('input[name="coaching-date-filter"]').daterangepicker({

		"locale": {
			"format": 'DD/MM/YYYY',
			"separator": "-"
		},
		"linkedCalendars": false,
		"showDropdowns": true
		},
		function(start, end, label) {

			const datatable = $('#coaching-table').DataTable({
				destroy: true,
				dom: 'flrtiBp',
				ajax: "/get/coaching?all=true&start="+start+"&end="+end,
				pageLength: 40,
				ordering: true,
				responsive: true,
				bAutoWidth:false,
				autoWidth: false,
				lengthMenu: [25, 40, 100],
				select: true,
				buttons: [
					'copyHtml5',
					'print',
					{
						extend: 'collection',
						text: 'Export',
						buttons: [
							'csvHtml5',
							'pdfHtml5'   
						]
					}
                ],
                initComplete: function () {
					this.api().columns([0,1,2,3,4]).every( function () {
						var column = this;
						if($(column.header()).find('span').length){
							var title = $(column.header()).find('span').text();
						}
						else{
							var title = $(column.header()).text();
						}
		
						var select = $('<br><select class="pdf-hide" style="text-align: center; margin-right:20px; border-radius:4px; color:black; width:100%;"><option></option></select>')
							.on( 'change', function (e) {
								e.stopPropagation();
								var val = $.fn.dataTable.util.escapeRegex(
									$(this).val()
								);
		
								column
									.search( val ? ''+val+'' : '', true, false )
									.draw();
							} );
						column.data().unique().sort().each( function ( d, j ) {
							if(column.search() === '^'+d+'$'){
								select.append( '<option value=\''+$(d).text()+'\' selected="selected">'+$(d).text()+'</option>' )
							} 
							else {
								if($(d).length){
									if($(d).filter('i.fa-star-o').length || $(d).filter('i.fa-star').length){
										select.append( '<option value=\''+$(d).filter('i.fa-star').length+'\'>'+$(d).filter('i.fa-star').length+'</option>' )
										select.unbind();
										select.on( 'change', function () {			
											column
												.search( 'fa-star-o', true, false )
												.draw();
										} );
									}
									else{
										select.append( '<option value=\''+$(d).text()+'\'>'+$(d).text()+'</option>' )
									}
								}
								else{
									select.append( '<option value=\''+d+'\'>'+d+'</option>' )
								}
							}
						} );
						$(column.header()).html('<span>'+title+'</span>');
						$(column.header()).append(select);
					});
                }
			});
		});
		});
	}
	    
    //if($("#lead_script").length) {
	// if($('div[name="coaching-energy"][id="lead_script"]').length) {
    //     window.lead_script = new ScriptFlow($("#lead_script"),'coaching-energy');
	// } 
    
    $('#leads-table').on('click', '.delete-lead', function(e) {
        e.preventDefault();
        const id = $(this).data('id');
        const message = new MessageBox('deletelead', 'Delete lead id ' + id);
        message.setData({
            ajax: { url: '/leads/' + id },
            success: function(data) {
                datatable.ajax.reload();
            }
        });
        message.run();
    });
    
    //because yeah, lets just add 3 event listeners to this button... 
    // $("#edit-script").click(function(e) {
    //     e.preventDefault();
    //     window.lead_script.showEditBox();
    // });
    
        
    //delete coaching report
    $('#coaching-table').on('click','.delete-coaching',function(e) {
        e.preventDefault();
        console.log("Ok, delete button pressed, going to send...");
        var coaching_report_id = $(this).data('id');
     
        var message = 'Delete selected coaching report #'+coaching_report_id+'?';
        var message = new MessageBox('deletecoaching', message);
        message.setData({
            ajax: { 
                cache: false,
                url: '/coaching/delete',
                type: 'POST',
                dataType: 'json',
                data: {coachingReportID: coaching_report_id},
                success: function(response) {
                    datatable.ajax.reload();
                },
                error: function (error) {
                    //console.log(error);
                }
            }
        });
        message.run();
    });	
    
    
    //unlock coaching report (revert to status 8 for further editing)
    //and redirect us there
    $('#coaching-table').on('click','.unlock-coaching',function(e) {
        
        console.log("Ok, unlock button pressed, going to send...");
        var coaching_report_id = $(this).data('id');
        var message = 'Unlock selected coaching report #'+coaching_report_id+'?';
        var message = new MessageBox('unlockcoaching', message);
        message.setData({
            ajax: { 
                cache: false,
                url: '/coaching/unlock',
                type: 'POST',
                dataType: 'json',
                data: {coachingReportID: coaching_report_id},
                success: function(response) {
                    //console.log(response);
                    window.location = '/order/'+response.order_id;
                },
                error: function (error) {
                    //console.log(error);
                }
            }
        });
        message.run();
    });	
      
    
});
