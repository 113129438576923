$(function() {
	var quote_id = $('#lead_script').data('quoteid');
    $('#lead_script').on('click', '#select_business_script_contact', function () {
		var selected_contact_id = $('#lead_script').find('#business_quote_contact_select').val();
		var not_allowed = ['', false, 'add_new'];
		if(not_allowed.indexOf(selected_contact_id) !== -1 || isNaN(parseInt(selected_contact_id)) ){
			error_message('please select a contact');
			return false;
		}
		
		var data = {
			'quote_id' : quote_id,
			'contact_id' : selected_contact_id
		};
		var that = this;
		makeRequest('/business/quote/apply-contact', data, function(response){
			if(isset(response.success) && response.success){
				lead_script.handleStep($(that).data('success-step'));
			}
			else{
				var error = 'unable to set contact - please refresh and try again';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})
	});
	
    $('#lead_script').on('click', '.toggle-address-sections', function () {
		$('#lead_script').find('.address-section').toggle();
    });
	
	
	$('#lead_script').on('click', '#select_business_script_address', function () {
		var selectbox = $('#lead_script').find('#business_quote_address_select');
		var data = {
			'quote_id' : quote_id	
		};
		if($(selectbox).is(':visible')){
			var address_id = $(selectbox).val();
			if(isNaN(parseInt(address_id))){
				error_message('please select an address');
				return false;
			}
			data.type = 'crm';
			data.address_id = address_id;
		}
		else{

			//are we gbg?
			if($('div.address-results-container').is(':visible')){
				//we are gbg
				var address_id = $('#lead_script .address-result-item.active').data('id');
				if(!address_id || !address_id.length){
					error_message('please select an address');
					return false;
				}
				data.type = 'gbg';
				data.address_id = address_id;
			}
			else{
				//we are manual 

				var form = $('#lead_script').find('#gbg_section :input');

				var address_data = {};
				var serialized = $(form).serializeArray();
				$.each(serialized, function(i, input){
					address_data[input.name] = input.value;
				});

				data.type = 'manual';
				data.address_data = address_data;
			}

		}
		var that = this;
		makeRequest('/business/quote/apply-address', data, function(response){
			if(isset(response.success) && response.success){
				lead_script.handleStep($(that).data('success-step'));
				$('.business-addresses-section').replaceWith(response.html);
			}
			else{
				var error = 'unable to set address - please refresh and try again';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})
	});
	
	$('#lead_script').on('change', '.fuel-toggle input', function () {
		var state = $(this).is(':checked');
		var fuel = $(this).val();
		$('#lead_script').find('.show-' + fuel).toggle(state);
		if(state){
			var install_state = $('.install-toggle input[value="' +fuel +'"]').is(':checked')
			$('#lead_script').find('.show-' + fuel + '-install').toggle(install_state);
		}
	});
	
	$('#lead_script').on('change', '.install-toggle input', function () {
		var state = $(this).is(':checked');
		var fuel = $(this).val();
		$('#lead_script').find('.show-' + fuel + '-install').toggle(state);
	});

	$('#lead_script').on('click', '#void_meter_current_supply_submit', function () {
		var data = {
			'quote_id' : quote_id	
		};

		var form_data = $('#lead_script :input').serializeArray();
		var supply_data = {};
		$.each(form_data, function(i, item){
			supply_data[item.name] = item.value;
		});

		if(!isset(supply_data["electricity:supply_type"]) && !isset(supply_data["gas:supply_type"])){
			error_message('please select at least one fuel');
			return false;
		}
		
		var fuels_to_remove = ['gas', 'electricity'];
		var install_keys_to_remove = [
			'electricity:meter_above_6ft', 
			'electricity:half_hourly_reads',
			'gas:meter_above_6ft', 
			'gas:half_hourly_reads'
		];
		$.each(supply_data, function(name, value){
			if(name.indexOf('supply_type') !== -1){
				fuels_to_remove.splice(fuels_to_remove.indexOf(value), 1);
			}
			if(name.indexOf('meter_installation_required') !== -1){
				$.each(install_keys_to_remove, function(i, install_key){
					if(install_key.indexOf(value) != -1){
						delete install_keys_to_remove[i];
					}
				});
				// reindex
				var tmp_install_keys_to_remove = [];
				$.each(install_keys_to_remove, function(i, value){
					if(value){
						tmp_install_keys_to_remove.push(value);
					}
				});
				install_keys_to_remove = tmp_install_keys_to_remove;

			}
		});

		//remove any items for a fuel that we are not selling
		var fuel_regex = new RegExp('(' + fuels_to_remove.join(')|(') + ')');
		var install_regex = new RegExp('(' + install_keys_to_remove.join(')|(') + ')');
		$.each(supply_data, function(name, value){
			if(name.replace(fuel_regex, '') != name){
				delete supply_data[name];
			}
			if(name.replace(install_regex, '') != name){
				delete supply_data[name];
			}
		});
		data.supply_data = supply_data;

		var that = this;
		makeRequest('/void_meter/current_supply/set', data, function(response){
			if(isset(response.success) && response.success){
				lead_script.handleStep($(that).data('success-step'));
			}
			else{
				var error = 'unable to set current supply - please refresh and try again';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
	
	$('#lead_script').on('click', '#submit_access_instructions', function () {
		var data = {
			'quote_id' : quote_id,
			'access_instructions' : $('#lead_script #address_access_instructions').val()
		};
		
		var that = this;
		makeRequest('/void_meter/access_instructions/set', data, function(response){
			if(isset(response.success) && response.success){
				lead_script.handleStep($(that).data('success-step'));
			}
			else{
				var error = 'unable to set access instructions - please refresh and try again';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
});