$(function() {

	registerCallbackOnAllScripts('boiler-care-online.introduction', function(){
		$('.script .select2').select2({
			'width': '100%'
		});
	});
	
	registerCallbackOnAllScripts('boiler-care-online.fact_find', function(){
		$('.script .select2').select2({
			'width': '100%'
		});
	});
	
	registerCallbackOnAllScripts('boiler-care-online.pitch', function(){
		$('.script [data-toggle="tooltip"]').tooltip({
			'container':"body"
		});
	});
	
	registerCallbackOnAllScripts('boiler-care-online.correspondence_address', function(){
		$('.script .select2').select2({
			'width': '100%'
		});
	});
});