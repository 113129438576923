var basic_validate = function(data,callback){
	var errors = [];
	$.each(data,function(index,value){	
		$.each(value.inputs,function(index,input){
			//console.log(input.must_complete);
			if (!Array.isArray(input.value)) {
				if(input.regex != null && input.value != null && input.value != '' ){
					var pattern = input.regex;
					var regex = new RegExp(pattern);
					if(!regex.test(input.value)){
						//html id's don't like pipe like html name 
						input.name = input.name.replace('|','-');
						errors.push({target:'error-'+input.name,message:'The value you entered is not valid'});
					}
					// regex the value! 
				}
				else{
					if(input.must_complete == 1 && (input.value == 'undefined' || input.value == 'please-select' || input.value == '')){
						//html id's don't like pipe like html name 
						input.name = input.name.replace('|','-');
						errors.push({target:'error-'+input.name,message:'Please enter a value'});
					}
				}
			}
		});
	});
	
	callback(errors,data);
};
var displayErrors = function(errors,parent_element){
	var error_bag = [];
	$.each(errors,function(index,error){
		$('#'+error.target).html(error.message);
		error_bag.push({'message':error.message});
		error_message(error.message);
	});
	$(parent_element+' span.errors').show();
	
};

var full_validate = function(data, button, callback){
	var quote_id = $('#lead_script').data("quoteid");
	var source = '';
	if($(button).data('source')){
		source = '/' + $(button).data('source');
	}
	$.ajax({ cache: false,
			url: source + "/validate-additional-questions/"+quote_id,
			type:"POST",
			dataType: 'json',
			data: { payload : data },
			success: function (response) {
				//console.log(response);
				if(response.errors){
					//console.log(response);
					$('#additional-questions-content').html(response.html);
					//window.refreshScripts();
					displayErrors(response.errors,'#additional-questions-content');
					//that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
}


$(document).ready(function() {
	
   $('#lead_script').on('click','#additional-questions-continue',function(e){
	e.preventDefault();
	$('.errors').html('');
	var groups = [];

	var bail = false;
	 $('.additional-question-group').each(function () {
		var group_name = $(this).attr('id');
		var group = [];
		var arrayFlag = false;
		var questionName ='';
		//groups[group_name] = [];
		$(this).find(':input').each(function () {
			if($(this).parent().is(':visible')){
				questionName='';
				var input_name = $(this).attr('name');
				
				//work out which bits of form data to send.
				if ($(this).prop('type')==='checkbox') {
					questionName = input_name;
					arrayFlag = true;
					if ($(this).is(":checked")) {	
						var input_value = $(this).data('value');
						group.push({ name : input_name, value : input_value });		
					}
					else{
						// i know horrible - sorry
						var container = $(this).parent().parent().parent();
						var must_complete = $(container).data('must_complete');
						if($(container).find(':input:checked').length == 0){
							var friendly_label = $(container).find('.header-labels').text();
							error_message('please check an option for ' + friendly_label);
							bail = true;
							return false;
						}


					} 
				}
				else{
					if($(this).prop('type')==='radio'){
						if ($(this).is(":checked")) {
							var input_value = $(this).val();
							group.push({ name : input_name, value : input_value });		
						}
					} 
					else {
						var input_value = $(this).val();
						var input_regex = $(this).parent().data('regex');
						var must_complete = $(this).parent().data('must_complete');
						group.push({ name : input_name, value : input_value, regex : input_regex, must_complete : must_complete });		
						//groups[group_name].push({ name : input_name, value : input_value });
					}
				}
					
			}
		});
		if (arrayFlag) {
			var tempGroup = [];
			for (var index in group) {
				tempGroup.push(group[index].value);
			}
			group =[];
			group.push({name: questionName, value: tempGroup});
		}
		groups.push({group_name:group_name,inputs:group});
	 });
	 if(bail){
		 return false;
	 }
	 var that = $(this);
	 basic_validate(groups,function(errors,data){
		 if(errors.length){
				 $.each(errors,function(index,error){
					$('#'+error.target).html(error.message);
					if(index === 0){
						$(window).scrollTop(0);
					}
					$('.errors').show();
					
				 });
		 }
		 else{
			 full_validate(data, that, function(response){
				 //console.log(response);
				 if(response.success){
					 window.lead_script.handleStep($('#additional-questions-continue').data('success-step'));
				 }
			 });
		 }
	 });
   });
   
   // co-op hack
   $('#additional-questions-wrapper').on('change','#coopMembership #question-isMember',function(){
	   if($(this).find('select[name$="isMember"]').val() == 'Yes'){
			$(this).parent().find('#question-membershipNumber').show();
			$(this).parent().find('#question-join').hide();
	   }
	   else{
		   if($(this).find('select[name$="isMember"]').val() == 'No'){
			   $(this).parent().find('#question-membershipNumber').hide();
			   $(this).parent().find('#question-join').show();
		   }
		   else{
			   $(this).parent().find('#question-membershipNumber').hide();
			   $(this).parent().find('#question-join').hide();
		   }
	   }
	  
   });
   
});

