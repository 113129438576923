
var getAddressValues = function(data = {}){
		
	if($('#insurance_address_not_shown').is(':visible')){
		//we have selected from a button so the value should be in our hidden input 
		
		data.address_id = $('#insurance_selected_address').val().trim();
		
	}
	else if($('#insurance_postcode_lookup_go').is(':visible')){
		//we used the postcode lookup to find the address
		var selected_option = $('#insurance_postcode_addresses option:selected');
		var type = selected_option.data('type');
		var id = selected_option.attr('value');
		if(!type || !id){
			error_message('please select an address');
			return false;
		}
		data.address_type = type;
		data.address_external_id = id;
		
	}
	else if($('#manual-house-number').is(':visible')){
		
		//we are on manual addess screen
		var manual_address = {};
		$('#insurance_manual_address input').each(function(){
			manual_address[$(this).attr('name')] = $(this).val();
		});
		data.manual_address = manual_address;
	}
	
	return data;
	
	
}

$(function() {
	$('.script').on('click', '#submit_broadband_data_capture', function () {
		
		showLoader();
		var required_values = [
			'reference_number',
			// 'monthly_cost'
		];

		var data = sanitiseSerialedArray($('.broadband-data-capture-form :input:visible').serializeArray());


		var data = getAddressValues(data);

		if(!data){
			hideLoader();
			return false;
		}

		var bail = false;
		$.each(required_values, function(i, required){
			if(!isset(data[required]) || !data[required].length){
				error_message('please enter a valid ' + ucwords(required));
				bail = true;
				return false;
			}
		});

		if(bail){
			hideLoader();
			return false;
		}
		
		var quote_id = $(this).closest('.script').data('quoteid');
		var to_send = {
			'quote_id' : quote_id,
			'data' : data
		};
		
		makeRequest('/broadband/details/capture', to_send, function(response){
			if(isset(response.contact_id)){
				success_message('confirmed');
				window.location.href = '/contact/' + response.contact_id;
			}
			else{
				var error = 'unable to store details';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});
		

	});
	
	$('.script').on('click', '#submit_broadband_current_supply', function () {
		
		showLoader();

		var raw_data = $('.broadband-current-supply-page :input').serializeArray();
		var data = sanitiseSerialedArray(raw_data);
		$.each($('.broadband-current-supply-page :input[type="checkbox"]:not(:checked)'), function(i, unchecked_box){
			data[$(unchecked_box).attr('name')] = 'off';
		});

		if(isset(data['has_tv_package']) && data['has_tv_package'] == 'on' && !isset(data['tv_provider'])){
			error_message('please select a TV package provider');
			hideLoader();
			return false;
		}

		var script_element = $(this).closest('.script');
		var quote_id = script_element.data('quoteid');
		var to_send = {
			'quote_id' : quote_id,
			'data' : data
		};
		
		var that = this;
		makeRequest('/broadband/current_supply/capture', to_send, function(response){
			if(isset(response.success) && response.success){
				window[$(script_element).data('script-name') + '_script'].handleStep($(that).data('success-step'));
			}
			else{
				var error = 'unable to store details';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});
		

	});
	
	$('.script').on('change', '.broadband-dd-picker', function () {
		

		var value = $(this).val();
		if(!value){
			return false;
		}
		
		var contact_id = $(this).data('contact-id');
		if(!contact_id){
			error_message('contact id not found - please refresh and try again');
			return false;
		}

		var container = $(this).closest('.customer-data-to-enter-into-external-portal');
		var loader = $(container).find('.broadband-dd-data-loader');
		loader.show();

		var data_container = $(container).find('.broadband-dd-data-holder');
		$(data_container).hide();

		var data = {
			'account_id' : value,
			'contact_id' : contact_id
		}

		makeRequest('/contact/view/account', data, function(response){
			if(isset(response.success) && response.success){
				$(data_container).html(response.html);
				$(data_container).show();
			}
			else{
				var error = 'unable to load details';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			loader.hide();
		});
		

	});
});