var validateNewLog = function (data) {
    var verified = true;
    if(data['title'].length === 0 && data['title'].length > 50) {
        verified = false;
    }

    if(data['description'].length === 0 && data['description'].length > 250) {
        verified = false;
    }

    if(!data['type'].length) {
        verified = false;
    }

    return verified;
}

var saveNewLog = function () {
    var data = {
        'title': $('#release_log_title').val(),
        'type': $('#release_log_type').val(),
        'description': $('#release_log_description').val()
    };

    if(validateNewLog(data)) {
        makeRequest('/admin/releases/new', data, function (response){
            if(isset(response.success) && response.success && isset(response.html)) {
                success_message();
                $('#log_new_release_modal').modal('hide');
                $('.release-log-binding-container').html(response.html);
            }
            else {
                error_message();
            }
        });
    }
    else {
        error_message('Please Fill out all fields');
    }
}

var getQAReport = function (data, callback) {
    makeRequest('/admin/quality-assurance/view', data, function (response) {
        if(isset(response.success) && response.success) {
            callback(response.html);
            success_message();
        }
        else {
            error_message();
        }
    });
};

var newQAReport = function (data, callback) {
    makeRequest('/admin/quality-assurance/new', data, function (response) {
        if(isset(response.success) && response.success) {
            callback(response.html);
            success_message();
        }
        else {
            error_message();
        }
    });
};

var saveQAReport = function (data, callback) {
    makeRequest('/admin/quality-assurance/save', data, function (response) {
        if(isset(response.success) && response.success) {
            callback(response.html);
            success_message();
        }
        else {
            error_message();
        }
    });
};
var viewQAReport = function (data, callback) {
    makeRequest('/admin/quality-assurance/view', data, function (response) {
        if(isset(response.success) && response.success) {
            callback(response.html);
            success_message();
        }
        else {
            error_message();
        }
    });
};

$(document).ready(function() {
    $('.release-log-stat-container').on('keyup', '#search_release_logs', function () {
        var term = $(this).val();

        if(term != '') {
            $('.release-log-container').each(function (i, row) {
                var text = $(row).text();
                if (text.indexOf(term) !== -1) {
                    $(row).show();
                }
                else {
                    $(row).hide();
                }
            });
        }
        else {
            $('.release-log-container').show();
        }
    });

    $('body').on('click', '#log_new_release', function () {
        var data = {
            'all_release_log_types': 'name'
        };
        buildDynamicModal('log_new_release_modal', data);
    });

    $('body').on('click', '#save_log_release', function () {
        saveNewLog();
    });

    $('.qa-directory-container').on('click', '.qa-directory-item-group.open-group', function (e) {
        if(e.target !== e.currentTarget) return;

        $(this).removeClass('open-group');
        $(this).addClass('opened-group');

        $(this).find('.closed-sub-directory').first().hide();
        $(this).find('.open-sub-directory').first().show();

        var elem = $(this).children('.qa-directory-item-group.collapsed-group');

        // var elem = $(this).find('.qa-directory-item-group.collapsed-group').first();

        $(elem).removeClass('collapsed-group').addClass('open-group');

        var child = $(this).children('.qa-directory-items');

        if($(child).hasClass('qa-directory-items')) {
           $(child).find('.qa-directory-item').removeClass('collapsed-item');
        }
    });

    $('.qa-directory-container').on('click', '.qa-directory-item-group.opened-group', function (e) {
        if(e.target !== e.currentTarget) return;

        $(this).addClass('open-group');
        $(this).removeClass('opened-group');
        $(this).find('.closed-sub-directory').show();
        $(this).find('.open-sub-directory').hide();

        var elem = $(this).find('.qa-directory-item-group');

        $(elem).addClass('collapsed-group');
        $(elem).removeClass('opened-group');

        $(this).find('.qa-directory-item').addClass('collapsed-item');
    });

    $('.qa-directory-container').on('click', '.qa-directory-item', function () {
        getQAReport(data, function (html) {

        });
    });

    $('body').on('click', '.new-health-report', function () {
        var data = {
            'type_id': $(this).data('type-id')
        };

        newQAReport(data, function (html) {
            $('.health-check-binding-container').html(html);
        });
    });

    $('body').on('click', '.qa-report-switch', function () {
        var that = $(this),
            parent = $(that).closest('.qa-report-field-item');

        if($(that).hasClass('disabled-report-item')) {
            return;
        }

        $(that).removeClass('qa-report-switch-off');
        $(that).addClass('selected-field');

        if($(that).hasClass('qa-report-switch-pass')) {
            $(parent).find('.qa-report-switch-fail').addClass('qa-report-switch-off').removeClass('selected-field');
        } 
		else {
            $(parent).find('.qa-report-switch-pass').addClass('qa-report-switch-off').removeClass('selected-field');
        }
    });

    $('body').on('click', '.save-qa-report-progress', function () {
        var that = $(this),
            table_container = $('.qa-report-body .qa-report-table'),
            table_fields = $(table_container).find('.qa-report-field-item'),
            uncategorized_container = $('.qa-report-body .uncategorized-table'),
            uncategorized_fields = $(uncategorized_container).find('.qa-report-field-item'),
            data = {
                'type' : $(that).data('type'),
                'report_id' : $('.qa-report-container').data('report-id'),
                'field_values' : {}
            };

        if(!isset(data['field_values']['categorized'])) {
            data['field_values']['categorized'] = {};
        }

        $.each(table_fields, function (i, categorized_field) {
            if($(categorized_field).find(':input').length) {
                var input = $(categorized_field).find(':input');
                if($(input).val().length) {
                    console.log(input);
                    if(!isset(data['field_values']['categorized'][$(input).data('category-id')])) {
                        data['field_values']['categorized'][$(input).data('category-id')] = {};
                    }

                    if(!isset(data['field_values']['categorized'][$(input).data('category-id')][$(input).data('field-id')])) {
                        data['field_values']['categorized'][$(input).data('category-id')][$(input).data('field-id')] = {};
                    }

                    data['field_values']['categorized'][$(input).data('category-id')][$(input).data('field-id')] = $(input).val();
                }
            } 
			else {
                var selected_field = $(categorized_field).find('.selected-field');
                if(isset(selected_field) && $(selected_field).hasClass('selected-field') && $(selected_field).data('val').length) {
                    if(!isset(data['field_values']['categorized'][$(selected_field).data('category-id')])) {
                        data['field_values']['categorized'][$(selected_field).data('category-id')] = {};
                    }

                    if(!isset(data['field_values']['categorized'][$(selected_field).data('category-id')][$(selected_field).data('field-id')])) {
                        data['field_values']['categorized'][$(selected_field).data('category-id')][$(selected_field).data('field-id')] = {};
                    }

                    data['field_values']['categorized'][$(selected_field).data('category-id')][$(selected_field).data('field-id')] = $(selected_field).data('val');
                }
            }
        });

        if(!isset(data['field_values']['uncategorized'])) {
            data['field_values']['uncategorized'] = {};
        }

        $.each(uncategorized_fields, function (i, uncategorized_field) {
            if($(uncategorized_field).find(':input').length) {
                var input = $(uncategorized_field).find(':input');
                if($(input).val().length) {
                    if(!isset(data['field_values']['uncategorized'][$(input).data('category-id')])) {
                        data['field_values']['uncategorized'][$(input).data('category-id')] = {};
                    }

                    data['field_values']['uncategorized'][$(input).data('field-id')] = $(input).val();
                }
            } 
			else {
                var selected_field = $(uncategorized_field).find('.selected-field');
                if(isset(selected_field) && $(selected_field).hasClass('selected-field') && $(selected_field).data('val').length) {
                    if(!isset(data['field_values']['uncategorized'][$(selected_field).data('category-id')])) {
                        data['field_values']['uncategorized'][$(selected_field).data('category-id')] = {};
                    }

                    data['field_values']['uncategorized'][$(selected_field).data('field-id')] = $(selected_field).data('val');
                }
            }
        });
        saveQAReport(data, function (html) {
            $('.health-check-binding-container').html(html);
            success_message('Saved');
        });
    });

    $('body').on('click', '.qa-directory-item', function () {
        var data = {
            'report_id' : $(this).data('report-id')
        };

        viewQAReport(data, function (html) {
            $('.health-check-binding-container').html(html);
            success_message();
        });
    });
});