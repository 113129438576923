
$( document ).ready(function() {

	var loader_template = $('#dialler-admin-component-templates').find('.dialler-admin-loader');

	$('#refresh_active_calls').on('click', function(){

		$('.active-calls').html(loader_template);

		makeRequest('/dialler/get/active-calls', {}, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('.active-calls').html(response.html);
				success_message('updated');
			}
			else{
				var error = 'unable to refresh';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});

	});
	
	$('#refresh_active_conferences').on('click', function(){

		$('.active-conferences').html(loader_template);

		makeRequest('/dialler/get/active-conferences', {}, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('.active-conferences').html(response.html);
				success_message('updated');
			}
			else{
				var error = 'unable to refresh';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});

	});

	$.each($('.dialler-admin-loader'), function(i, loader){
		var container = $(loader).closest('.dialler-admin-section');
		var refresh_button = $(container).find('.refresh-button');
		$(refresh_button).trigger('click');
	});


	setInterval(function(){
		$.each($('.call-timer'), function(i, timer_element){
			var seconds = $(timer_element).data('seconds');
			seconds++;
			$(timer_element).data('seconds', seconds);
			var time_string = (seconds > 3600 ? Math.floor(seconds/ 3600) + ':' : '')+Math.floor(seconds/ 60 % 60) +':' + (seconds% 60).toString().padStart(2, '0');
			$(timer_element).text(time_string);
		});
	}, 1000);



	$('.dailler-admin-container').on('click', '.call-action.end-call', function(){

		var external_id = $(this).data('external-id');
		customConfirm('Are you sure you want to end this call?', function(){
			//lets do this
			var data = {
				'external_id' : external_id
			};
			makeRequest('/dialler/end/call-by-external-id', data, function(response){
				if(isset(response.success) && response.success){
					//looks like the call was ended - lets force a refresh
					success_message('ended');
					$('#refresh_active_calls').trigger('click');
				}
				else{
					var error = 'unable to end call';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			})
		});
	});
	
	$('.dailler-admin-container').on('click', '.conference-call-action.remove-conference-participant', function(){

		var external_id = $(this).data('external-id');
		var conference_id = $(this).data('conference-external-id');
		customConfirm('Are you sure you want to remove this call from the conference?', function(){
			//lets do this
			var data = {
				'external_id' : external_id,
				'conference_external_id' : conference_id
			};
			makeRequest('/dialler/end/conference-participant', data, function(response){
				if(isset(response.success) && response.success){
					//looks like the call was ended - lets force a refresh
					success_message('ended');
					$('#refresh_active_conferences').trigger('click');
				}
				else{
					var error = 'unable to end call';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			})
		});
	});

	$('.dailler-admin-container').on('click', '.conference-action.end-conference', function(){

		var external_id = $(this).data('external-id');
		customConfirm('Are you sure you want to end this call?', function(){
			//lets do this
			var data = {
				'external_id' : external_id
			};
			makeRequest('/dialler/end/conference-by-external-id', data, function(response){
				if(isset(response.success) && response.success){
					//looks like the call was ended - lets force a refresh
					success_message('ended');
					$('#refresh_active_conferences').trigger('click');
				}
				else{
					var error = 'unable to end conference';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			})
		});
	});
	
	$('.dailler-admin-container').on('click', '.conference-action.listen', function(){

		var external_id = $(this).data('external-id');
		softphoneInterface.listenIn(external_id);
	});
	
	$('.dailler-admin-container').on('click', '.call-action.listen', function(){

		var external_id = $(this).data('external-id');
		softphoneInterface.listenIn(external_id);
	});
	
	$('.dailler-admin-container').on('click', '.call-action.barge', function(){
		var that = this;
		customConfirm('Are you sure you want to barge into this call?', function(){
			var external_id = $(that).data('external-id');
			softphoneInterface.bargeIn(external_id);
		});
	});
	
	$('.dailler-admin-container').on('click', '.conference-action.join', function(){
		var that = this;
		customConfirm('Are you sure you want to join this conference?', function(){
			var external_id = $(that).data('external-id');
			softphoneInterface.joinConference(external_id);
		});
	});


	$('#suppress-raw-row-ids').on('click', function(){
		customConfirm('Are you sure you want to suppress raw row ids? - We will do no validation that you are not doing a stupid - that is up to you', function(){
			var row_ids = $('#row-ids').val();

			var data_type = row_ids.indexOf(',') > -1 ? 'csv' : 'rows';
			if(data_type == 'csv'){
				row_ids = row_ids.split(',');
			}
			else{
				row_ids = row_ids.split('\n');
			}
			row_ids = row_ids.map(function(row_id){
				row_id = row_id.trim();
				//remove non-numerical characters
				row_id = row_id.replace(/[^0-9]/g, '');
				if(row_id.length > 0){
					return row_id;
				}
				return false;
			}).filter(Boolean);
			
			if(!row_ids.length){
				error_message('no row ids found');
			}
			
			makeRequest('/dialler/suppress-raw-row-ids', {'row_ids' : row_ids}, function(response){
				if(isset(response.success) && response.success){
					success_message('suppressed');
				}
				else{
					var error = 'unable to suppress raw row ids';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			});

		});
	});

});