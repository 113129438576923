class InputInterface {
	constructor(){
		this.inputs = {};
	}

	reset(){
		this.inputs = {};
	}

	registerInput(input){

		if(isset(input.container_id)){
			input.container = $('div.script-form #' + input.container_id);
		}

		let parent = input.container.closest('.script'),
			index = parent.data('scriptid');

		if(index.length) {
			if(!isset(this.inputs[index])) {
				this.inputs[index] = {};
			}
			this.inputs[index][input.id] = input;

			if (isset(input.load) && typeof (input.load) == 'function') {
				input.load();
			}
		} 
		else {
			error_message('tell IT.')
		}
	}

	submit(script_step, quote_id, callback = function () {}, anchor) {
		var data = {};
		var bail = false;
		var that = this;
		var inputs_to_loop_over = this.inputs[anchor.data('scriptid')];

		$.each(inputs_to_loop_over, function (input_id, input) {
			if (isset(input.get) && typeof (input.get) == 'function') {
				var value = input.get();
				var valid = true;

				if (isset(input.validate) && typeof (input.validate) == 'function') {
					var validation_result = input.validate(value);
					if (!isset(validation_result.success) || !validation_result.success) {
						valid = false;
					}
					if (isset(validation_result.error)) {
						error_message(validation_result.error);
					}
				}
				if (valid) {
					if (input.container.closest('div.repeatable').length) {
						if (!isset(data['repeatable'])) {
							data['repeatable'] = {};
						}

						if (!isset(data['repeatable'][input.container.closest('div.repeatable').data('key')])) {
							data['repeatable'][input.container.closest('div.repeatable').data('key')] = {};
						}

						if (!isset(data['repeatable'][input.container.closest('div.repeatable').data('key')][input.container.closest('div.repeatable').data('group-name')])) {
							data['repeatable'][input.container.closest('div.repeatable').data('key')][input.container.closest('div.repeatable').data('group-name')] = {};
						}

						var container = $(input.container).closest('.repeatable-sub-container');
						var sub_inputs = container.find('[data-input-id="' + input_id + '"]');

						$.each(sub_inputs, function (i, sub_input) {
							if (!$(sub_input).closest('.repeatable-table-row').hasClass('hide')) {
								var row_count = $(sub_input).closest('.repeatable-table-row').prevAll().not('.hide').length - 1;

								if (!isset(data['repeatable'][input.container.closest('div.repeatable').data('key')][input.container.closest('div.repeatable').data('group-name')][row_count])) {
									data['repeatable'][input.container.closest('div.repeatable').data('key')][input.container.closest('div.repeatable').data('group-name')][row_count] = {};
								}

								// if ($(sub_input).hasClass('boolean-switch')) {
								// 	data['repeatable'][input.container.closest('div.repeatable').data('key')][input.container.closest('div.repeatable').data('group-name')][row_count][input_id] = $(sub_input).find(':input').val();
								//
								// 	var on_value = $(sub_input).find(':input').data('on-value');
								// 	var off_value = $(sub_input).find(':input').data('off-value');
								//
								// 	data['repeatable'][input.container.closest('div.repeatable').data('key')][input.container.closest('div.repeatable').data('group-name')][row_count][input_id][$(sub_input).find(':input').val()][additional] = {};
								// 	if (data['repeatable'][input.container.closest('div.repeatable').data('key')][input.container.closest('div.repeatable').data('group-name')][row_count][input_id][$(sub_input).find(':input').val()][additional]) {
								//
								// 		data['repeatable'][input.container.closest('div.repeatable').data('key')][input.container.closest('div.repeatable').data('group-name')][row_count][input_id][$(sub_input).find(':input').val()][additional] = on_value;
								// 		data['repeatable'][input.container.closest('div.repeatable').data('key')][input.container.closest('div.repeatable').data('group-name')][row_count][input_id][$(sub_input).find(':input').val()][additional] = off_value;
								// 	}
								//
								// } else {
								data['repeatable'][input.container.closest('div.repeatable').data('key')][input.container.closest('div.repeatable').data('group-name')][row_count][input_id] = $(sub_input).find(':input').val();
								// }
							}
						});
					} 
					else {
						data[input_id] = value;
					}
				} 
				else {
					bail = true;
					return false;
				}
			}
		});

		if(!bail){
			if(Object.keys(data).length){
				//if we get here then we have a good set of data

				var post_data = {
					'script_step' : script_step,
					'quote_id' : quote_id,
					'form_data' : data
				};

				makeRequest('/script/post/form', post_data, function(response){
					if(isset(response.success) && response.success){
						callback(true);
						that.reset();
						return;
					}
					var error = 'issue posting form data - taking local snapshot of data - if you need this data then please let Will know - most attractive person in IT';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
					localStorage.setItem('script_form_error_state_backup', JSON.stringify(data));
				});
			}
			else{
				this.reset();
				callback(false);
			}
		}
		hideLoader();
	}
}

$(document).ready(function(){

	var buffer = [];
	var last_key_time = 0;
	$(document).on('keyup', function(e){
		var chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
		if(isset(e.key)){
			var key = e.key.toLowerCase();

			// we are only interested in alphanumeric keys
			if (chars.indexOf(key) === -1) return;

			var now = Date.now();

			if (now - last_key_time > 1000) {
					buffer = [];
			}

			buffer.push(key);
			last_key_time = now;

			if(buffer.join('') == 'specialwill'){
				var data = localStorage.getItem('script_form_error_state_backup');
				console.table(JSON.parse(data));
				copyToClipboard(data);
				alert(data);
			}
		}
	});
});

//maybe take this top guy off window later 
window.input_interface = new InputInterface();

window.registerInput = function(input){
	input_interface.registerInput(input);
};

window.submitForm = function(script_step, quote_id, callback = function(){}, anchor){
	input_interface.submit(script_step, quote_id, callback, anchor);
};

