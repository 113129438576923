$(function() {
	if($('#lead_script').length && lead_script && isset(lead_script.registerStepCallback)){
		lead_script.registerStepCallback('void-meter-energy.introduction', function(){
			lead_script.anchor.find('.select2').select2({
				'wdith' : '100%',
				'matcher': function(params, data) {
					if (data.id === "add_new") { // <-- option value of "add_new", always appears in results
						return data;
					} 
					else {
						return $.fn.select2.defaults.defaults.matcher.apply(this, arguments);
					}
				},
			});
			lead_script.anchor.find('.select2').on('select2:select', function(e){
				var value = $(this).val();
				if(value == 'add_new'){
					var that = this;
					showNewBusinessContactModal(function(contact){
						var new_option = new Option(contact.first_name + ' ' +  contact.last_name, contact.id, false, false);
						$(that).append(new_option).val(contact.id).trigger('change');
					})
				}
			})
		});
		
		lead_script.registerStepCallback('void-meter-energy.address_selection', function(){
			lead_script.anchor.find('.select2').select2({
				'wdith' : '100%'
			});
		});
		
		lead_script.registerStepCallback('void-meter-energy.current_supply', function(){
			var value = $('#property_vacated_at_date_picker').val();
			if(!value){
				value = moment();
			}
			lead_script.anchor.find('#property_vacated_at_date_picker').daterangepicker({
				"locale": {
					"format": 'DD-MM-YYYY',
					"separator": "-" 
				},
				"startDate": value,
				"singleDatePicker": true,
				"autoApply": true,
			}, function(start, end, label) {
			console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')');
			});;
		});
	}
});