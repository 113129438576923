//only used for accident protection - in API now as anticipating eventually we'll scrape open works

var detectTermsCompletion = function(callback, data) {
	var missing = null;
	var data = [];
	var duty = $('input[name="customer-declaration-option"]:checked').val();
	var data_protection = $('input[name="customer-data-protection-option"]:checked').val();
	var third_party_consent = $('input[name="ML_third_party"]').prop('checked');
	var policy_consent = $('input[name="ML_policy_consent"]').prop('checked'); 
	
	//check mandatory sections
	if (duty == undefined) {
		missing = 1;
	} 
	else {
		if (data_protection == undefined) {
			missing = 2;
		}
	}
	//console.log(data);
	//push everything...
	data.push({field : 'missing',value : missing});
	data.push({field : 'ml_customer_declaration',value : duty});
	data.push({field : 'ml_customer_data_protection',value : data_protection});
	data.push({field : 'ml_customer_third_party',value : third_party_consent});
	data.push({field : 'ml_customer_policy_documents',value : policy_consent});
		
	callback(data);
};

$(document).ready(function() {
	$('#lead_script').on('click','#terms-check',function(e) {
		//console.log('clicked next for terms1');
		e.preventDefault();
		var quoteID = window.lead_script.quoteId;
		detectTermsCompletion(function(data) {
			if(data[0].value===null) {
				$.ajax({
					url: '/order/updateAccidentProtectionTerms',
					data: {
						'quoteID' : quoteID,
						'data': data
					},
					dataType: 'json',
					method: 'post',
					success: function(response) {
						if(response.success){
							//move on if all ok
							window.lead_script.handleStep('accident-protection.terms_2');
						}
					},
					error: function (error) {
						//console.log(error);
					}
				});
			} 
			else {
				//pop open the accordion blade indicated (unless already open) and scroll to it
				//console.log('we would be popping accordion '+data[0].value);
				if ($('#blade'+data[0].value).attr('aria-expanded')==='false') {
					$('#blade'+data[0].value).click();
				}
				$("html, body").scrollTop($('#blade'+data[0].value).position().top);
			}
		});
	});
	
	$('#lead_script').on('click','#fact-find-employment-status',function(e) {
		e.preventDefault();
		var employment_status = $('#lead_script #fact-find-employment-status').val();
		//console.log(employment_status);
		if (employment_status=='Unemployed') {
			$('#additional_employment_questions').hide();
		} 
		else {
			$('#additional_employment_questions').show();
		}
	});
	
	
	
});


