$(document).ready(function() {
    //console.log("trying to load compliance sampling report data");
    $('#fixedreport-complianceSamplingByAgent-table').DataTable( {
        ajax: "/fixedreport/get/complianceByAgent",
		dom: 'flrtiBp',
        pageLength: 60,
        ordering: true,
        responsive: true,
        bAutoWidth:false,
        autoWidth: false,
        lengthMenu: [25, 60, 100],
        select: true,
        buttons: [
            'copyHtml5',
            'print',
            {
                extend: 'collection',
                text: 'Export',
                buttons: [
                    'csvHtml5',
                    'pdfHtml5'   
                ]
            }
        ],
        footerCallback: function ( row, data, start, end, display ) {
            var api = this.api(), data;

            // Remove the formatting to get integer data for summation
            var intVal = function ( i ) {
            return typeof i === 'string' ?
                i.replace(/[\%]/g, '')*1 :
                typeof i === 'number' ?
                    i : 0;
            };

            var summaryColumns = [1,2,3,4,5,6,7,8,9];
            var averageColumns = [3,4,5,8];
            var total;
            var pageTotal;
            var entry;
            var colLoop;
            var eachColumn;
            
            for (colLoop=0; colLoop < summaryColumns.length; colLoop++) {
                // Total over all pages
                eachColumn = summaryColumns[colLoop];
                if(api.column(eachColumn).data().length) {
                total = api
                .column( eachColumn )
                .data()
                .reduce( function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0 );
                } 
				else {
                    total = 0;
                }
                // Total over this page
                if(api.column(eachColumn).data().length) {
                pageTotal = api
                .column( eachColumn, { page: 'current'} )
                .data()
                .reduce( function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0 );
                } 
				else {
                    pageTotal = 0;
                }
                // sum or average with NAN protection?    
                if (averageColumns.indexOf(eachColumn) === -1) {
                    entry = pageTotal +' ('+ total + ')';
                } 
				else {
                    entry = (total / this.fnGetData().length).toFixed(1);
                    entry = isNaN(entry)?'n/a':entry+"%";
                }
                        
                // Update tfoot in blade
                $( api.column( eachColumn).footer() ).html(entry);    
            }
        }   
    });
    
if($('input[name="date-filter"]').length > 0){
//date picker for changing order filter range (separate reload, may want to do more with this)
$('input[name="date-filter"]').daterangepicker({

    "locale": {
        "format": 'DD/MM/YYYY',
        "separator": "-"
    },
    "linkedCalendars": false,
    "showDropdowns": true
    },
    function(start, end, label, brands) {

        //console.log("Ok, changing date range...");

		//grab the filters
		var brands="";
		brands = $('#brand-filter').val();

        const datatable = $('#fixedreport-complianceSamplingByAgent-table').DataTable({
            destroy: true,
            dom: 'flrtiBp',
            ajax: "/fixedreport/get/complianceByAgent?start="+start+"&end="+end+"&brands="+brands,
            pageLength: 60,
            ordering: true,
            responsive: true,
            bAutoWidth:false,
            autoWidth: false,
            lengthMenu: [25, 60, 100],
            select: true,
            buttons: [
                'copyHtml5',
                'print',
                {
                    extend: 'collection',
                    text: 'Export',
                    buttons: [
                        'csvHtml5',
                        'pdfHtml5'   
                    ]
                }
            ],
            footerCallback: function ( row, data, start, end, display ) {
                var api = this.api(), data;

                // Remove the formatting to get integer data for summation
                var intVal = function ( i ) {
                return typeof i === 'string' ?
                    i.replace(/[\%]/g, '')*1 :
                    typeof i === 'number' ?
                        i : 0;
                };

                var totalColumns = [1,2,3,4,5,6,7,8,9];
                var averageColumns = [3,4,5,8];
                var total;
                var pageTotal;
                var entry;
                var colLoop;
                var eachColumn;

                for (colLoop=0; colLoop < totalColumns.length; colLoop++) {
                    // Total over all pages
                    eachColumn = totalColumns[colLoop];
                    if(api.column(eachColumn).data().length) {
                        total = api
                        .column( eachColumn )
                        .data()
                        .reduce( function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0 );
                    } 
					else {
                        total = 0;
                    }
                    // Total over this page
                    if(api.column(eachColumn).data().length) {
                        pageTotal = api
                        .column( eachColumn, { page: 'current'} )
                        .data()
                        .reduce( function (a, b) {
                            return intVal(a) + intVal(b);
                        }, 0 );
                    } 
					else {
                        var pageTotal = 0;
                    }

                    // sum or average with NAN protection?    
                    if (averageColumns.indexOf(eachColumn) === -1) {
                        entry = pageTotal +' ('+ total + ')';
                    } 
					else {
                        entry = (total / this.fnGetData().length).toFixed(1);
                        entry = isNaN(entry)?'n/a':entry+"%";
                    }

                    // Update tfoot in blade
                    $( api.column( eachColumn).footer() ).html(entry);      
                }
            }
        });

    });
}
    //console.log("trying to load compliance sampling report data");
    $('#fixedreport-AgentSummary-table').DataTable( {
        ajax: "/fixedreport/get/agentSummaryReport",
		dom: 'flrtiBp',
        pageLength: 60,
        ordering: true,
        responsive: true,
        bAutoWidth:false,
        autoWidth: false,
        lengthMenu: [25, 60, 100],
        select: true,
        buttons: [
            'copyHtml5',
            'print',
            {
                extend: 'collection',
                text: 'Export',
                buttons: [
                    'csvHtml5',
                    'pdfHtml5'
                ]
            }
        ],
        footerCallback: function ( row, data, start, end, display ) {
            var api = this.api(), data;
            // Remove the formatting to get integer data for summation
            var intVal = function ( i ) {
            return typeof i === 'string' ?
                i.replace(/[\%]/g, '')*1 :
                typeof i === 'number' ?
                    i : 0;
            };
            //@todo need to dynamically pick these as product count will undoubtedly change
            var summaryColumns = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];
            var averageColumns = [5,6,8,23];
            var total;
            var pageTotal;
            var entry;
            var colLoop;
            var eachColumn;

            for (colLoop=0; colLoop < summaryColumns.length; colLoop++) {
                // Total over all pages
                eachColumn = summaryColumns[colLoop];
                if(api.column(eachColumn).data().length) {
                total = api
                .column( eachColumn )
                .data()
                .reduce( function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0 );
                } 
				else {
                    total = 0;
                }
                // Total over this page
                if(api.column(eachColumn).data().length) {
                pageTotal = api
                .column( eachColumn, { page: 'current'} )
                .data()
                .reduce( function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0 );
                } 
				else {
                    pageTotal = 0;
                }
                // sum or average with NAN protection?
                if (averageColumns.indexOf(eachColumn) === -1) {
                    entry = pageTotal +' ('+ total + ')';
                } 
				else {
                    entry = (total / this.fnGetData().length).toFixed(1);
                    entry = isNaN(entry)?'n/a':entry;
                }

                // Update tfoot in blade
                $( api.column( eachColumn).footer() ).html(entry);
            }
        }
    });

    //date picker for changing order filter range (separate reload, may want to do more with this)
//	$('input[name="date-filter"]').daterangepicker({
//
//		"locale": {
//      "format": 'DD/MM/YYYY',
//        "separator": "-"
//		},
//		"linkedCalendars": false,
//		"showDropdowns": true
//    },
//    function(start, end, label) {
//
        //console.log("Ok, changing date range...");

//        const datatable = $('#fixedreport-AgentSummary-table').DataTable({
//            destroy: true,
//           dom: 'flrtiBp',
//            ajax: "/fixedreport/get/agentSummaryReport?start="+start+"&end="+end,
//            pageLength: 60,
//            ordering: true,
//            responsive: true,
//            bAutoWidth:false,
//            autoWidth: false,
//            lengthMenu: [25, 60, 100],
//            select: true,
//            buttons: [
//                'copyHtml5',
//                'print',
//                {
//                    extend: 'collection',
//                    text: 'Export',
//                    buttons: [
//                        'csvHtml5',
//                        'pdfHtml5'
//                    ]
//                }
//            ],
//            footerCallback: function ( row, data, start, end, display ) {
//                var api = this.api(), data;

                // Remove the formatting to get integer data for summation
//                var intVal = function ( i ) {
//                return typeof i === 'string' ?
//                   i.replace(/[\%]/g, '')*1 :
//                    typeof i === 'number' ?
//                        i : 0;
//                };
//
//	            //@todo need to dynamically pick these as product count will undoubtedly change
//                var summaryColumns = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];
//                var averageColumns = [5,6,8,23];
//                var total;
//                var pageTotal;
//                var entry;
//                var colLoop;
//                var eachColumn;
//
//               for (colLoop=0; colLoop < summaryColumns.length; colLoop++) {
//                    // Total over all pages
//                    eachColumn = summaryColumns[colLoop];
//                    if(api.column(eachColumn).data().length) {
//                        total = api
//                        .column( eachColumn )
//                        .data()
//                        .reduce( function (a, b) {
//                            return intVal(a) + intVal(b);
//                        }, 0 );
//                    } else {
//                        total = 0;
//                    }
//                    // Total over this page
//                    if(api.column(eachColumn).data().length) {
//                        pageTotal = api
//                        .column( eachColumn, { page: 'current'} )
//                        .data()
//                        .reduce( function (a, b) {
//                            return intVal(a) + intVal(b);
//                        }, 0 );
//                    } else {
//                        var pageTotal = 0;
//                    }

                    // sum or average with NAN protection?
//                    if (averageColumns.indexOf(eachColumn) === -1) {
//                        entry = pageTotal +' ('+ total + ')';
//                    } else {
//                        entry = (total / this.fnGetData().length).toFixed(1);
//                        entry = isNaN(entry)?'n/a':entry;
//                    }

//                    // Update tfoot in blade
//                    $( api.column( eachColumn).footer() ).html(entry);
//                }
//            }
//        });

//    });


	$('#fixedreport-customer-reports-by-tariff-table').DataTable( {
	
		ajax: "/fixedreport/get/customerByTariff",
		dom: 'flrtiBp',
		pageLength: 20,
		ordering: true,
		responsive: true,
		bAutoWidth:false,
		autoWidth: false,
		lengthMenu: [20, 40, 60, 100],
		select: true,
		
		buttons: [
			{
				extend: 'csv',
				text: 'Export Data (CSV)',
				filename: "CRM_Energy_Export_"+$('#supplier-filter option:selected').text()+"_"+$('#tariff-filter option:selected').text(),
			}
		]   
	});

	$("body").on('click',"#supplier-filter",function(e){
		//console.log('supplier filter pressed');
		
		var supplierID = $('#supplier-filter').val();
		$.ajax({
			url: "/fixedReports/updateTariffs",
			type:"POST",
			data: {supplierID: supplierID},
			cache: false,
			success: function(response){
				$('#tariff-filter').html(response);
				var tariffCount = (response.match(/<option/g)||[]).length;
				$('#tariff-count').html(tariffCount+' tariff(s) found for selected supplier.');		
			},
			error : function(error) {
				//console.log(error);
			}		
		});	
		
		//on success update filter block
	});
	
	$("body").on('click',"#btn-contact-tariff-search",function(e){
		//console.log('search button pressed');
		var supplierID = $('#supplier-filter').val();
		var tariffID = $('#tariff-filter').val();
		
		//ajax and update contacts datatable
		const datatable = $('#fixedreport-customer-reports-by-tariff-table').DataTable({
            destroy: true,
			ajax: { 
				type:'POST',
				data: {
					'supplierID' : supplierID,
					'tariffID' : tariffID,
				},
				url: "/fixedReports/updateCustomerByTariff",
				datatype : 'JSON',
			},
			dom: 'flrtiBp',
			pageLength: 20,
			ordering: true,
			responsive: true,
			bAutoWidth:false,
			autoWidth: false,
			lengthMenu: [20, 40, 60, 100],
			select: true,
		
			buttons: [
				{
					extend: 'csv',
					text: 'Export Data (CSV)',
					filename: "CRM_Energy_Export_"+$('#supplier-filter option:selected').text()+"_"+$('#tariff-filter option:selected').text(),
				}
			]    
		});
	});
	
	
	
	
});



