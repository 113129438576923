$( document ).ready(function() {
	//editing an assets directory
	var editAssetDirectory = function(assetDirectory,callback) {
        $('#hidden-asset-div-' + assetDirectory + ' #hidden-asset-body').hide();
		var real_asset_dir = assetDirectory.replace(" ","_");
		$.ajax({
			url: '/assets/edit/'+real_asset_dir,
				success: function(response) {
					if(response.success){
						$('#hidden-asset-div-' + assetDirectory + ' #hidden-asset-body').html(response.html);
						$('body,html').animate({}, 800);
						$('#hidden-asset-div-' + assetDirectory + ' #hidden-asset-body').fadeIn(1000);
				}
					//console.log(response);
			},
			error: function (error) {
				//console.log(error);
			}
		});
	};

	var deleteAsset = function(src,dir) {
		$.ajax({
            data: {
				'asset-src' : src,
				'asset-dir' : dir
			},
			type: 'POST',
            url: '/assets/delete/assetSrc',
            dataType: 'json',
			success: function(response) {
                editAssetDirectory(dir,function() {});
				if(response.error) {
					alert(response.error);
				}
				else{
					//console.log("removing element");
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
	}

    var createNewAssetDir = function(name,target) {
        var parent = target.parent().parent().parent();
		var input = $('.search-directory').val();
		parent.find('div.update-error').html('');
		//console.log('creating new asset directory');
		$.ajax({
			data: {
				'asset-directory-name' : name
			},
			type: 'POST',
			url: '/assets/createDir',
			dataType: 'json',
			success: function(response) {
				parent.find('input[name="asset-directory-name"]').val('');
				$('#saved-assets-list').append(response.html);
				$('div.update-error').hide();
				$('.update-success').fadeIn().text('Created Directory with name "'+input+'"');
				setTimeout(function(){
					$('.update-success').fadeOut();
				},5000);
				if(response.error) {
					$('.update-success').hide();
                    $('div.update-error').hide();
					$('div.asset-directory-list div.module-error').html(response.error).show();
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
    }

	//edit trigger for an assets directory
//	$('#saved-assets-list').on('click', '.edit-trigger', function() {
//		//console.log('editing an assets directory:)');
//		var elem = $(this).parent().find('#asset-directory-name');
//		var assetDirectoryName = $(elem).data('value').replace('images/Assets/','');
//		editAssetDirectory(assetDirectoryName,function() {
//			$('#assets-directory-edit-container').show();
//		});
//	});
    
    //delete an asset img
	$('#assets-wrapper').on('click', '.delete-asset-trigger', function() {
		if( ! confirm("are you sure?")) {
			alert("cancelled");
		}
		else {
            var assetImg = $(this).parent().find('#asset-img');
            var assetFullSrc = $(assetImg).attr('src');
            //var assetSrc = assetFullSrc.substr(assetFullSrc.lastIndexOf('/') - 1);
            //var assetSrc = assetFullSrc.substr(0,assetFullSrc.lastIndexOf("/",assetFullSrc.lastIndexOf('/') - 1));
            var assetFullSrcSplit = assetFullSrc.split('/');
            var assetDirectoryName = assetFullSrcSplit[assetFullSrcSplit.length - 2];
            var assetFileName = assetFullSrcSplit[assetFullSrcSplit.length - 1];
            //var assetSrc = assetDirectoryName + "/" + assetFileName;
			//console.log("deleting "+ assetFileName);
			//console.log("deleting "+ assetDirectoryName);
            deleteAsset(assetFileName,assetDirectoryName);
        }
	});
    
    //create a new info page template
	$('#assets-wrapper').on('click','#create-asset-directory',function(){
		var name = $(this).parent().find('input[name="asset-directory-name"]').val();
		var target = $(this).parent();
		if(name.length < 3) {
			target.parent().find('.module-error').fadeIn('fast').text("Please enter a longer name (3 or more characters)");
		} 
		else {
			$('.module-error').hide();
            createNewAssetDir(name, target);
		}
	});
    
    //assets directory search
	$('#assets-wrapper').on('keyup','.search-directory',function(){
        $(this).parent().parent().find('.module-error').hide();
        //console.log("searching");
        var searchVal = $(this).val().toLowerCase();
        var searchList = $(this).parent().parent().find('#saved-assets-list');
        if(searchVal === "") {
            //console.log("search is empty");
            $(searchList).find('.saved-asset-list-names').show();
        }
        else {
            //console.log(searchVal);
            $(searchList).find('.saved-asset-list-names').each(function(){
               var buttonVal = $(this).find('.asset-list-buttons').data('value');
               var compareButtonVal = buttonVal.toLowerCase();
               (compareButtonVal.indexOf(searchVal) >= 0) ? $(this).show() : $(this).hide();
            });
        }
	});	
      
      
    //UPLOAD ASSET  
    $('#assets-wrapper').on('submit','#asset-editor-upload-form',function(e) {
       e.preventDefault();
       var assetFormData = new FormData($('#asset-editor-upload-form')[0]);    
       // If you want to add an extra field for the FormData 
       //assetFormData.append('assetInput', $('input[type=file]'));
       var assetDirectoryName = $('#asset-directory').val();

       $.ajax({
          data: assetFormData,
          type: 'POST',
          url: '/assets/upload/assetSrc',
          enctype: 'multipart/form-data',// multipart
          contentType: false,       // The content type used when sending data to the server.
          cache: false,             // To unable request pages to be cached
          processData:false,        // To send DOMDocument or non processed data file it is set to false
          success: function(response) {
              //console.log("SUCCESS : ", assetFormData);
              editAssetDirectory(assetDirectoryName,function() {});
              if(response.error) {

              }
          },
          error: function (error) {
              //console.log(error);
          }
        });
    });
    
    $('#assets-wrapper').on('click','.edit-assets-trigger',function() {
        var assetDirName = $(this).parent().data('value');
        //console.log("you clicked an asset directory"); 
        //console.log(assetDirName);
        editAssetDirectory(assetDirName,function() {});
        $('.collapse.in').each(function(){
            $(this).collapse('hide');
        });
    });
    
    $('#assets-wrapper').on('click','.add-asset-trigger', function() {
        
        //console.log("add img");
        
        var assetImgSrc = $(this).parent().find('img')[0].src;
        //console.log(assetImgSrc.attr('src'));
        
        var createImgHtmlNode = document.createElement("IMG");
        createImgHtmlNode.setAttribute('src',assetImgSrc);
        createImgHtmlNode.setAttribute('class','asset-img-summer');
        //console.log(createImgHtmlNode);
        
        $('.summernote').summernote('insertNode', createImgHtmlNode);
    });
	
	$('#assets-wrapper').on('click','.add-asset-thumb-trigger', function(){
		var assetImgSrc = $(this).parent().find('img')[0].src;
		var assetOriginalImg = assetImgSrc.substr(assetImgSrc.lastIndexOf('/')+1);
		var assetThumbImg = 'thumb_' + assetOriginalImg;
		var assetThumbSrc = assetImgSrc.replace(assetOriginalImg,assetThumbImg);
		
		var createThumbImgHtmlNode = document.createElement("IMG");
        createThumbImgHtmlNode.setAttribute('src',assetThumbSrc);
		$('#thumbnail_holder').html(createThumbImgHtmlNode);
		
		$('input[name=thumbnail]').val(assetThumbSrc);
		
		
	});
	
	$('#assets-wrapper').on('click','.add-asset-rss-thumb-trigger', function(){
		var assetImgSrc = $(this).parent().find('img')[0].src;
		var assetOriginalImg = assetImgSrc.substr(assetImgSrc.lastIndexOf('/')+1);
		var assetThumbImg = 'rss_thumb_' + assetOriginalImg;
		var assetThumbSrc = assetImgSrc.replace(assetOriginalImg,assetThumbImg);
		
		var createThumbImgHtmlNode = document.createElement("IMG");
        createThumbImgHtmlNode.setAttribute('src',assetThumbSrc);
		$('#thumbnail_holder').html(createThumbImgHtmlNode);
		
		$('input[name=thumbnail]').val(assetThumbSrc);		
	});
    
});