window.isset = function(value){
	if(value != undefined && value != null){
		return true;
	}
	else{
		return false;
	}
}

var isIE9OrBelow = function()
{
   return /MSIE\s/.test(navigator.userAgent) && parseFloat(navigator.appVersion.split("MSIE")[1]) < 10;
}


var loaderInterval;
/* pass through an array(list) of loading messages
 * also handles animation of the dots, and animating the messages to iterate
 */
window.loaderText = function(list, time = 5000) {
	$('.horizontal-dots--dot').removeClass('loaderAnimationOnce');
	$('.horizontal-dots--dot').addClass('loaderAnimationInfinite');
	$("#loader-container").show();
	//setting the counter to the first element in array
	if(!isset(list) || !list.length){
		list = [''];
	}
	var count = 0;
	//setting the intitial message to this count
	$("#rotateLoaderRefineText").text(list[count]);
	loaderInterval = setInterval(function () {
		count++;
		$("#rotateLoaderRefineText").fadeOut(400, function () {
			$(this).text(list[count % list.length]).fadeIn(400);
		});
		// checking to see if the count has reached the max of the array
		// max of array - 2 :: because we set the set the default element to 0, 
		// then iterate over the full list again, so to avoid duplicate message minus 2
		if(count == (list.length - 1)){
			clearInterval(loaderInterval);
		}
	}, time); //5 seconds per message
}

window.loaderClear = function(){
	clearInterval(loaderInterval);
	$("#loader-container").hide();
	$('.horizontal-dots--dot').addClass('loaderAnimationOnce');
	$('.horizontal-dots--dot').removeClass('loaderAnimationInfinite');
}


window.showLoader = function(list,time = 5000) {
	window.loader_control = true;
	 if(isIE9OrBelow() == true) {
		 $('#loader-container').show();
		// animateRotateIE9('#loader',360);
	 }
	 else {
		 loaderText(list,time);
		 //$('#loader-container').show();
	 }
 }
 
window.hideLoader = function (){
	
	if(isIE9OrBelow() == true) {
		$('#loader-container').hide();

		window.loader_control = false;	
		$('#loader').animate({deg: 0}, {
			duration: 1,
			step: function(now) {
				$('#loader').css({
					transform: 'rotate(' + now + 'deg)'
				});
			},
			easing : 'linear'
		});
	}
	else{
		loaderClear();
	}
	
}

window.loader_control = true;