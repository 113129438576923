//only used for accident protection - in API now as anticipating eventually we'll scrape open works

var hideAllColumn = function(callback) {
	for(var counter=1; counter<=5; counter++) {
		$('#pi-total-'+counter).css('visibility','collapse');
	} 
	callback();
};

var showActiveColumn = function (activeColumn) {
	$('#pi-total-'+activeColumn).css('visibility','visible');
};

//clear all the buttons
var deselectAllUnits = function (column, callback) {
	$('#protection-plan').find('.btn').each(function() {  
		if ($(this).parent().data('col')!== column) {
			$(this).removeClass( "active" );
		}
	});
	callback();
};

//calculate the column total value
var calculateColumnTotal = function (column, callback) {
	var total = 0;
	$('#protection-plan').find('.btn').each(function() {  
		//console.log('hello');
		if ($(this).parent().data('col')== column && $(this).hasClass('active') || $(this).hasClass('focus')) {
			//console.log($(this).parent().data('sale_value'));
			total = total + parseInt($(this).parent().data('sale_value'));
		}
	});
	callback(total);
};

//update accident protection sales order
var updateSalesOrder = function(quoteID, column){
	
	var data = [];
	
	//only post the product id and qty data for selected buttons in our correct column
	$('#protection-plan').find('.btn').each(function() { 
		if ($(this).parent().data('col')== column) {
			if ($(this).hasClass('active') || $(this).hasClass('focus')) {
				var product_id = $(this).parent().data('product_id');
				var unit_qty = $(this).parent().data('col');
				data.push([product_id, unit_qty]);
			}
		}
	});
	
	$.ajax({
		url: '/order/updateAccidentProtectionOrder',
		data: {
			'quoteID' : quoteID,
			'data': data
		},
		dataType: 'json',
		method: 'post',
		success: function(response) {
			if(response.success){
				//things to do if we're ok
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

$(document).ready(function() {
	$('#lead_script').on('click','#protection-plan-grid .btn',function(e) {
		
		var quoteID = window.lead_script.quoteId;
		var activeButton = $(this).parent(); 
		var activeColumn = activeButton.data('col');
		var activeRow = activeButton.data('row');
		
		//@todocheck deselection
		
		
		deselectAllUnits(activeColumn, function() {
			
			$(this).toggleClass('active');
			$(this).click();
			var coreButton = "#pi-product-row-1-col-"+activeColumn;
			$(coreButton).find('.btn').addClass("active");
			$(coreButton).click();
			calculateColumnTotal(activeColumn, function(total) {
				total = "<h4>£"+total.toFixed(2)+"</h4>";
				$('#pi-total-'+activeColumn).html(total);
				hideAllColumn( function() {
					showActiveColumn(activeColumn);
					updateSalesOrder(quoteID, activeColumn);
					$('#units-selected-ok').show();
				});
			});
			
		});
		
		
		
	});
});


