$(document).ready(function() {
    var refreshChart = function (scope) {
        var render_container = $(scope).closest('.recent-tests').find('.render-chart');
        var data = {};
        var date_range = $(scope).val();

        data.start = date_range.split('-')[0];
        data.end = date_range.split('-')[1];
        data.site_name = $(render_container).find('.site_name');

        makeRequest('/admin/testsuite/refresh-chart', data, function (response) {
            if (isset(response.success)) {
                $(render_container).html(response.html);
                // success_message('Success');
                $(render_container).find('.chart-loader-mask').hide();
            } 
			else {
                alert('something went wrong');
            }
        });
    };

    var refreshRecent = function () {
        $('.recent-tests-container').hide();
        $('.recent-loader-mask').show();
        var data = {};
        makeRequest('/admin/testsuite/refresh-recent', data, function (response) {
            if (isset(response.success)) {
                $('.render-recent-test').html(response.html);
                // success_message('Success');
                $('.recent-tests-container').show();
                $('.recent-loader-mask').hide();
            } 
			else {
                alert('something went wrong');
            }
        });
    };

    var filterFailedTests = function () {
        var data = {};

        var date_range = $('.recent-test-failures-actions .date-filter').val();

        data.start = date_range.split('-')[0];
        data.end = date_range.split('-')[1];

        $('.recent-test-failures-actions :input').each(function (i, input) {
            if(!$(input).hasClass('date-filter') || $(input).hasClass('btn')) {
                data[$(input).attr('name')] = $(input).val();
            }
        });

        makeRequest('/admin/testsuite/filter-failures', data, function (response) {
            if (isset(response.success)) {
                if(response.success) {
                    $('.failed-tests-container').html(response.html);
                    success_message('Success');
                }
                else {
                    error_message(response.error);
                }
            } 
			else {
                error_message('something went wrong');
            }
        });
    };

    var chartLoad = function () {
        $('body').on('change', 'input[name="chart_date_range"]', function () {
            var scope = $(this);
            var container = $(scope).closest('.recent-tests');
            $(container).find('.test-chart-container').hide();
            $(container).find('.chart-loader-mask').show();
            refreshChart(scope);
        });
    };

    // var refreshData = function () {
    //     refreshChart();
    //     refreshRecent();
    //
    //     setTimeout(function(){
    //         refreshData();
    //     },20000);
    // };
    //
    // setTimeout(refreshData, 20000);
    setTimeout(chartLoad, 1000);

    $('input[name="chart_date_range"]').daterangepicker({
        "locale": {
            "format": 'DD/MM/YYYY HH:mm',
            "separator": "-"
        },
        "timePicker": true,
        "linkedCalendars": false,
        "showDropdowns": true
    });

    $('input[name="failures_date_range"]').daterangepicker({
        "locale": {
            "format": 'DD/MM/YYYY HH:mm',
            "separator": "-"
        },
        "timePicker": true,
        "linkedCalendars": false,
        "showDropdowns": true
    });

    $('body').on('click', '.drill-down-icon', function () {
        // var id = $(this).data('id');
        $(this).closest('.single-test').find('.drill-down-container').toggle();
    });

    $('body').on('click', '#refresh-recent-data', function () {
        refreshRecent();
    });

    $('body').on('click', '.recent-test-failures-actions .filter-action', function () {
        filterFailedTests();
    });

	$('.system-status-page').on('click', '.system-test', function(){
		showLoader();
		$(this).siblings().removeClass('active');
		$(this).addClass('active');
		var hash = $(this).data('test-hash');

		var data = {
			'hash' : hash
		};

		makeRequest('/system/test/results', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('.test-results-table').replaceWith(response.html);
				$('.current-hash').text(hash);
				success_message('updated');
			}
			else{
				var error = 'unable to load test results';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		})

	});
	
	$('.system-status-page').on('click', '.refresh-test-list', function(){
		var i = $(this).find('i')
		$(i).addClass('fa-spin');

		var active_hash = $('.system-test-list .system-test.active').data('test-hash');

		var data ={
			'active_hash' : active_hash
		}

		makeRequest('/system/test/list', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('.system-test-list').replaceWith(response.html);
				$(i).removeClass('fa-spin');
				success_message('updated');
			}
			else{
				var error = 'unable to load tests';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});

	});

	if($('#toggle_passive_mode').length){
		setInterval(function(){
			if($('#toggle_passive_mode').is(':checked')){
				//indicate we are refreshing
				$('.refresh-test-list i').addClass('fa-spin');
				var data = {
					'active_hash' : 'most_recent'
				};
				makeRequest('/system/test/list', data, function(response){
					if(isset(response.success) && response.success && isset(response.html)){
						$('.system-test-list').replaceWith(response.html);
						
						var hash = $('.system-test-list .system-test.active').data('test-hash');

						var data = {
							'hash' : hash
						};

						makeRequest('/system/test/results', data, function(response){
							if(isset(response.success) && response.success && isset(response.html)){
								$('.test-results-table').replaceWith(response.html);
								$('.current-hash').text(hash);
								$('.refresh-test-list i').removeClass('fa-spin');
							}
							else{
								var error = 'unable to load test results';
								if(isset(response.error)){
									error = response.error;
								}
								error_message(error);
							}
						})

					}
					else{
						var error = 'unable to load tests';
						if(isset(response.error)){
							error = response.error;
						}
						error_message(error);
					}
				});
			}
		}, 60000); // every minute
	}
});