$(document).ready(function() {
	var api = new apiInterface();
    const datatable = $('#rejected-orders-table').DataTable({
        dom: 'flrtiBp',
        ajax: "/get/rejected-orders",
        ordering: true,
        responsive: true,
        bAutoWidth:false,
        autoWidth: false,
        lengthMenu: [25, 40, 100],
        select: true,
        buttons: [
                'copyHtml5',
                'print',
                {
                    extend: 'collection',
                    text: 'Export',
                    buttons: [
                        'csvHtml5',
                        'pdfHtml5'   
                    ]
                }
        ],
        initComplete: function () {
            this.api().columns([3,4]).every( function () {
                var column = this;
                var title = $(column.header()).html();
                var select = $('<select style="float:right; margin-right:20px; border-radius:4px; color:black;"><option></option></select>')
                    .on( 'change', function () {
                        var val = $.fn.dataTable.util.escapeRegex(
                            $(this).val()
                        );

                        column
                            .search( val ? '^'+val+'$' : '', true, false )
                            .draw();
                    } );
                column.data().unique().sort().each( function ( d, j ) {
                if(column.search() === '^'+d+'$'){
                    select.append( '<option value="'+d+'" selected="selected">'+d+'</option>' )
                } 
				else {
                    select.append( '<option value="'+d+'">'+d+'</option>' )
                }
                } );
                $(column.header()).html('<span>'+title+'</span>');
                $(column.header()).append(select);
            } );
        }   
    });
	
	$('#rejected-orders-table_wrapper').on('click','#mark_resolved',function(e){
		var that = this;
		api.resolve_order($(this).data('order_id'),[],function(response){
			$(that).parent().parent().fadeOut();
		});
	});
	
	$('#rejected-orders-table_wrapper').on('click','#mark_cant_help',function(e){
		var that = this;
		api.cant_help_order($(this).data('order_id'),[],function(response){
			$(that).parent().parent().fadeOut();
		});
	});
	
	$('#rejected-orders-table_wrapper').on('click','#mark_confirmed',function(e){
		var that = this;
		api.mark_confirmed($(this).data('order_id'),[],function(response){
			$(that).parent().parent().fadeOut();
		});
	});
	
	
	if($('input[name="rejected-date-filter"]').length > 0){
	$('input[name="rejected-date-filter"]').daterangepicker({

    "locale": {
        "format": 'DD/MM/YYYY',
        "separator": "-"
    },
    "linkedCalendars": false,
    "showDropdowns": true
    },
    function(start, end, label) {

        //console.log("Ok, changing date range...");

        const datatable = $('#rejected-orders-table').DataTable({
            destroy: true,
            dom: 'flrtiBp',
            ajax: "/get/rejected-orders?all=true&start="+start+"&end="+end,
            pageLength: 40,
            ordering: true,
            responsive: true,
            bAutoWidth:false,
            autoWidth: false,
            lengthMenu: [25, 40, 100],
            select: true,
            buttons: [
                'copyHtml5',
                'print',
                {
                    extend: 'collection',
                    text: 'Export',
                    buttons: [
                        'csvHtml5',
                        'pdfHtml5'   
                    ]
                }
            ],
            
            initComplete: function () {
                this.api().columns([3,4]).every( function () {
                var column = this;
                var title = $(column.header()).find('span').html();
                var select = $('<select style="float:right; margin-right:20px; border-radius:4px; color:black;"><option></option></select>')
                    .on( 'change', function () {
                        var val = $.fn.dataTable.util.escapeRegex(
                            $(this).val()
                        );

                        column
                            .search( val ? '^'+val+'$' : '', true, false )
                            .draw();
                    } );
                column.data().unique().sort().each( function ( d, j ) {
                if(column.search() === '^'+d+'$'){
                    select.append( '<option value="'+d+'" selected="selected">'+d+'</option>' )
                } 
				else {
                    select.append( '<option value="'+d+'">'+d+'</option>' )
                }
                } );
                $(column.header()).html('<span>'+title+'</span>');
                $(column.header()).append(select);
            });
            } 
        });
		
		$('#rejected-orders-table_wrapper').on('click','#mark_resolved',function(e){
			var that = this;
			api.resolve_order($(this).data('order_id'),[],function(response){
				$(that).parent().parent().fadeOut();
			});
		});

		$('#rejected-orders-table_wrapper').on('click','#mark_cant_help',function(e){
			var that = this;
			api.cant_help_order($(this).data('order_id'),[],function(response){
				$(that).parent().parent().fadeOut();
			});
		});
		
		$('#rejected-orders-table_wrapper').on('click','#mark_confirmed',function(e){
			var that = this;
			api.mark_confirmed($(this).data('order_id'),[],function(response){
				$(that).parent().parent().fadeOut();
			});
		});
		
    });
}
	
});    



