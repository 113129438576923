
//set customer details for accident protection customer (NO API)
var set_customer_details = function(quote_id,data, callback) {
	var that = this;
	$.ajax({
		url: "/order/setAccidentProtectionCustomerDetails/"+quote_id,
		data: {
			'details' : data
		},
		type:"POST",
		dataType: 'json',
		success: function(response){
			if(response.errors){
				that.throwError(response.errors);
			}else{
				callback(response);
			}
		},
		error : function(error) {
			//console.log(error);
		}		
	});
};

$(document).ready(function(){
	var quote_id = $('#lead_script').data("quoteid");
	var customer_validator = new customerValidator();
	$('#lead_script').on('click','#pi-customer-details-continue',function(e) {
		e.preventDefault();
		var validation = [
			function(next) { customer_validator.validateTitle($('#lead_script #customer-details-title').val(),next); },	
			function(next) { customer_validator.validateFirstName($('#lead_script #customer-details-firstname').val(),next); },
			function(next) { customer_validator.validateLastName($('#lead_script #customer-details-lastname').val(),next); },
//			function(next) { customer_validator.validateDOB(
//				$('#lead_script #customer-details-dob-day').val(),
//				$('#lead_script #customer-details-dob-month').val(),
//				$('#lead_script #customer-details-dob-year').val()
//				,next); },
			function(next) { customer_validator.validatePhone($('#lead_script #customer-details-phone').val(),next); },
			function(next) { customer_validator.validateEmail($('#lead_script #customer-details-email').val(),next); },
		];
		customer_validator.validateAll(validation,function(errors,data){
			//console.log(errors);
			if(errors !== null){
				$.each(errors,function(index,error){
					$('#lead_script #'+error.target).html(error.message);
					//console.log(error.message);
				});
//				$('#lead_script span.error-message').show();
//				setTimeout(function(){ 
//					$('#lead_script span.error-message').hide();
//				}, 1000);
			}
			else{
				set_customer_details(quote_id,data,function(response) {
					window.lead_script.handleStep('accident-protection.address_details');
				});
			}
		});
	});
	
});

