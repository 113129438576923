$(function() {

	registerCallbackOnAllScripts('energy.customer_details', function(){
		$('#contract_start_date').daterangepicker({
			"locale": {
				"format": 'DD-MM-YYYY',
				"separator": "-" 
			},
			"singleDatePicker": true,
			"showDropdowns": true,
			'minDate' : moment().add(22, 'days').format("DD-MM-YYYY"),
			'maxDate' : moment().add(90, 'days').format("DD-MM-YYYY"),
		});
	});
	registerCallbackOnAllScripts('auto-switch.basket', function(){
		$('.custom-renewal-date-picker').daterangepicker({
			locale: {
				format: 'YYYY-MM-DD'
			},
			singleDatePicker: true
		});
	});
	registerCallbackOnAllScripts('aftersale-auto-renewal-energy.thank_you', function(){
		$('.custom-renewal-date-picker').daterangepicker({
			locale: {
				format: 'YYYY-MM-DD'
			},
			singleDatePicker: true
		});
	});
	registerCallbackOnAllScripts('energy.basket', function(){
		$('.custom-renewal-date-picker').daterangepicker({
			locale: {
				format: 'YYYY-MM-DD'
			},
			singleDatePicker: true
		});
	});

	registerCallbackOnAllScripts('energy.broadband-details', function(){
		$('.script .select2').select2({
			'width': '100%'
		});

		$('.date-picker').daterangepicker({
			"locale": {
				"format": 'DD-MM-YYYY',
				"separator": "-" 
			},
			"singleDatePicker": true,
			"showDropdowns": true
		});
	});
	
	registerCallbackOnAllScripts('energy.set_current_supply', function(){
		refeshContactDetails();
	});
	
	registerCallbackOnAllScripts('energy.get_postcode', function(){
		$('.script .select2').select2({
			'width': '100%'
		});
	});
});
