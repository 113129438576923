window.handleWelcomeStepAddress = function(quote_id, callback = function(address_data){}){
	if($('#preselected_section').length && $('#preselected_section').is(':visible')){
		//we are in the preselected section which means the address already exists on the CRM and just needs to be applied
		
		var data = {
			'type' : 'crm',
			'external_address_id' : $('#preselected_section').data('address-id'), // this is not actually external -- just that is what address interface is expeccting
			'postcode' : $('#preselected_section').data('postcode'),
			'quote_id' : quote_id
		};	
	}
	else if($('#ehl_section').length && $('#ehl_section').is(':visible')){
		//we are dealling with an ehl address

		if(!$('#ehl_addresses').val() || $('#ehl_addresses').val() == 'PLEASE SELECT AN ADDRESS HERE'){
			error_message('you MUST select an address here');
			return false;
		} 

		var data = {};
		data.type = 'ehl';
		data.external_address_id = $('#ehl_addresses').val();
		data.postcode = $('#ehl_addresses option:selected').data('postcode');
		data.quote_id = quote_id;
	}

	else if($('#gbg_section') && $('#gbg_section').is(':visible')){

		if(!$('#gbg_addresses').val() || $('#gbg_addresses').val() == 'PLEASE SELECT AN ADDRESS HERE'){
			error_message('you MUST select an address here');
			return false;
		} 


		var selected_option = $('#gbg_addresses').find('option:selected');

		var data = {};
		data.type = $(selected_option).data('type');
		data.external_address_id = $('#gbg_addresses').val();
		data.address_text = $(selected_option).text();
		data.postcode = $(selected_option).data('postcode');
		data.quote_id = quote_id;

		if($(selected_option).data('full-address')){
			data.full_address = $(selected_option).data('full-address');
		}

		// var selected = $('ul.address-results li.address-result-item.active');
		// if(!selected.length){
		// 	error_message('please enter and select an address');
		// 	return false;
		// }

		// var data = {};
		// data.type = 'gbg';
		// data.external_address_id = $(selected).data('id');
		// data.address_text = $(selected).text();
		// data.postcode = $(selected).data('postcode');
		// data.quote_id = quote_id;
	}

	showLoader();
	if(data){
		makeRequest('/address/send_dynamic_address', data, function(response){
			if(response.success) {
				if(response.html){
					$('#address-list-display').html(response.html);
				}
				callback(data);
			}
			else{
				hideLoader();
				error_message(response.error);
			}
		});
	}
	else{
		var postcode = $('#api_postcode').val();
		var data = {};
		data.postcode = postcode;
		data.quote_id = quote_id;
		makeRequest('/address/update_postcode', data, function(response){
			if(response.success) {
				if(response.html){
					$('#address-list-display').html(response.html);
				}
				callback(data);
			}
			else{
				hideLoader();
				error_message(response.error);
			}
		});
	}
}


$(document).ready(function(){
	var quote_id = $('#lead_script').data("quoteid");
	var address_validator = new addressValidator();
	var api = new apiInterface();
	
	$('#postcode-search').on('click',function(){
		var postcode=$('#postcode').val();
		$.ajax({ cache: false,
			url: "/address/address-lookup?postcode="+postcode,
			dataType: 'json',
			success: function (response) {
				$('#address-box').hide();
				$('#address-select-display').show();
				$('#address-select-display').html(response.html);
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});

	$('#lead_script').on('click','#continue_with_manual_address',function(e){
		e.preventDefault();
		$('.fancy-section-loading-placeholder').removeClass('hide');
		var quote_id = $('#lead_script').data("quoteid");
		makeRequest("/address/get-manual-address-content/"+quote_id, {}, function(response){
			$('.fancy-section-loading-placeholder').addClass('hide');
			if(isset(response.confirm_address_html)){
				// show a secondary address confirmation section. 
				$('.rebel-address-wrapper').addClass('hide');
				$('.secondary-address-selection-needed').html(response.confirm_address_html).removeClass('hide');
				$('.fancy-section-loading-placeholder').addClass('hide');
			}
			else{
				error_message('unable to search addresses');
			}
		})
	});



	$('#lead_script').on('click','#rebel_postcode_search_section .rebel-postcode-search-button',function(){
		$('.fancy-section-loading-placeholder').removeClass('hide');
		var postcode = $('#rebel_postcode').val();
		var quote_id = $('#lead_script').data("quoteid");
		if(postcode.length < 6){
			error_message('please enter a valid postcode');
			return;
		}
		var data = {
			quote_id:quote_id,
			postcode:postcode,
		};
		makeRequest("/address/get-rebel-addresses", data, function(response){
			$('.fancy-section-loading-placeholder').addClass('hide');
			if(isset(response.html)){
				$('.rebel-address-search-results-container').html(response.html).removeClass('hide');
				$('#rebel_postcode_search_section').addClass('hide');
			}
			else{
				error_message('unable to search addresses');
			}
		})
		
	});

	$('#lead_script').on('change','.address-selection-section .home-owner-status-section input[name="home_owner"]',function(){
		var quote_id = $('#lead_script').data("quoteid");
		var home_owner = $(this).val();
		var data = {
			home_owner:home_owner
		};
		makeRequest("/address/set-home-owner/"+quote_id, data, function(response){
			if(isset(response.success) && response.success){
				var external_address_id = $('#rebel_addresses').val();
				if(isset(external_address_id) && external_address_id.length > 0){
					$('#rebel_addresses').trigger("change");
				}
				else {
					var address_id = $('#lead_script .uw-address-select-section #address_select :selected').val();
					if(isset(address_id) && address_id.length){
						$('#lead_script .uw-address-select-section #address_select').trigger('change');
					}
				}
			}
			else{
				if(isset(response.error)){
					error_message(response.error);
				}
				else{
					error_message('unable to set home owner');
				}
			}
		});
	});

	$('#lead_script').on('click','#change_rebel_postcode',function(){
		$('.rebel-address-search-results-container').addClass('hide');
		$('#rebel_postcode_search_section').removeClass('hide');
		$('.tariff-information-section').addClass('hide');
		$('.fancy-section-loading-placeholder').removeClass('hide');
		$('.tariff-information-dynamic-text').html('');
		$('.customer-information-dynamic-text').html('');
		$('.customer-information-section').addClass('hide');
		$('.address-confirmation-section').addClass('hide');
		$('.direct-debit-information-dynamic-text').html('');
		$('.direct-debit-information-section').addClass('hide');
		$('.go-to-checkout-section').addClass('hide');
		$('.secondary-address-selection-needed').html("").addClass('hide');
		$('.simple-current-usage-section').addClass('hide');

	});


	$('#lead_script').on('change','#rebel_addresses',function(){
		// take the external ID and go get some quote data
		var external_address_id = $(this).val();
		if(external_address_id.length == 0){
			$('.tariff-information-section').addClass('hide');
			error_message('please select an address');
			return false;
		}
		var quote_id = $('#lead_script').data("quoteid");
		var data = {
			external_address_id:external_address_id
		};
		
		$('.fancy-section-loading-placeholder').removeClass('hide');
		$('.tariff-information-dynamic-text').html('');
		$('.tariff-information-section').removeClass('hide');
		$('.customer-information-dynamic-text').html('');
		$('.customer-information-section').addClass('hide');
		$('.address-confirmation-section').addClass('hide');
		$('.direct-debit-information-dynamic-text').html('');
		$('.direct-debit-information-section').addClass('hide');
		$('.go-to-checkout-section').addClass('hide');
		$('.simple-current-usage-section').addClass('hide');
		// $('.tariff-pitch-dynamic-text-section').addClass('hide');
		makeRequest("/address/get-rebel-quote/"+quote_id, data, function(response){
			if(isset(response.tariff_html) && isset(response.contact_html) && isset(response.direct_debit_html)){
				$('.fancy-section-loading-placeholder').addClass('hide');
				$('.tariff-information-dynamic-text').html(response.tariff_html);
				$('.tariff-pitch-dynamic-text-section').removeClass('hide');
				$('.customer-information-dynamic-text').html(response.contact_html);
				$('.customer-information-section').removeClass('hide');
				$('.address-confirmation-dynamic-text').html(response.address_html);
				$('.address-confirmation-section').removeClass('hide');
				$('.direct-debit-information-dynamic-text').html(response.direct_debit_html);
				$('.direct-debit-information-section').removeClass('hide');
				$('.go-to-checkout-section').removeClass('hide');
			}
			else{
				if(isset(response.confirm_address_html)){
					// show a secondary address confirmation section. 
					$('.rebel-address-wrapper').addClass('hide');
					$('.secondary-address-selection-needed').html(response.confirm_address_html).removeClass('hide');
					$('.fancy-section-loading-placeholder').addClass('hide');
				}
				if(isset(response.error)){
					error_message(response.error);
					return;
					// lead_script.handleStep('pure-energy.simple_current_usage');
				}
				error_message('unable to set address');
			}
		})
	});

	$('#lead_script').on('click','.inline-change-usage-button',function(){
		$('.simple-current-usage-section').removeClass('hide');
		$('.simple-current-supply-section').addClass('hide');
		$('.tariff-information-section').addClass('hide');
		$('.tariff-information-dynamic-text').html('');
		$('.customer-information-dynamic-text').html('');
		$('.customer-information-section').addClass('hide');
		$('.direct-debit-information-dynamic-text').html('');
		$('.direct-debit-information-section').addClass('hide');
		$('.address-confirmation-section').addClass('hide');
		$('.go-to-checkout-section').addClass('hide');
	});

	$('#lead_script').on('click','.inline-change-supply-button',function(){
		$('.simple-current-usage-section').addClass('hide');
		$('.simple-current-supply-section').removeClass('hide');
		$('.energy-usage-page').removeClass('hide');
		$('.tariff-information-section').addClass('hide');
		$('.tariff-information-dynamic-text').html('');
		$('.customer-information-dynamic-text').html('');
		$('.customer-information-section').addClass('hide');
		$('.direct-debit-information-dynamic-text').html('');
		$('.direct-debit-information-section').addClass('hide');
		$('.address-confirmation-section').addClass('hide');
		$('.go-to-checkout-section').addClass('hide');
	});

	$('#lead_script').on('click','.update-simple-current-usage-button',function(e){
		e.preventDefault();
		var quote_id = $('#lead_script').data('quoteid');
		var usageDetails = {};

		// Get selected radio options (for simple usage and others)
		$('.usage-type-input-section:not(.hidden) .option-selected input[type="radio"]').each(function() {
			var name = $(this).attr('name');
			var value = $(this).val();
			usageDetails[name] = value;
		});

		// If the actual usage section is visible, collect text inputs (e.g., electricity, gas usage)
		if ($('.usage-type-input-section').is(':visible')) {
			$('.actual-energy-usage-wrapper:not(.hidden)').find('.usage-input-wrapper:not(.hidden) input[type="text"]').each(function() {
				usageDetails['advanced_usage_type'] = 'actual';
				var name = $(this).attr('name');
				var value = $(this).val();
				usageDetails[name] = value;
			});
			if($('.estimated-energy-usage-wrapper').hasClass('hidden') == false){
				usageDetails['advanced_usage_type'] = 'estimated';
			}
		}

		// If the user selects "Enter Spend", capture the spend input value
		if ($('.spend-energy-usage-wrapper').is(':visible')) {
			var spendValue = $('input[name="current_energy_spend"]').val(); // Get the spend value
			if (spendValue) {
				usageDetails['current_energy_spend'] = spendValue; // Add spend to usageDetails
			}
		}

		var data = {
			usageDetails: usageDetails,
			quote_id: quote_id
		};
		
		$('.fancy-section-loading-placeholder').removeClass('hide');
		$('.tariff-information-dynamic-text').html("");
		$('.tariff-pitch-dynamic-text-section').addClass('hide');
		$('.customer-information-dynamic-text').html("");
		$('.customer-information-section').addClass('hide');
		$('.address-confirmation-dynamic-text').html("");
		$('.address-confirmation-section').addClass('hide');
		$('.direct-debit-information-dynamic-text').html("");
		$('.direct-debit-information-section').addClass('hide');
		$('.go-to-checkout-section').addClass('hide');
		$('.simple-current-usage-section').addClass('hide');
		$('.simple-current-supply-section').addClass('hide');
		
		makeRequest('/quote/womp/set-current-usage', data, function(response) {
			if (response.success) {
				makeRequest("/address/get-rebel-quote-bypass/"+quote_id,data, function(response){
					if(isset(response.tariff_html) && isset(response.contact_html) && isset(response.direct_debit_html)){
						$('.fancy-section-loading-placeholder').addClass('hide');
						$('.tariff-information-section').removeClass('hide');
						$('.tariff-information-dynamic-text').html(response.tariff_html);
						$('.tariff-pitch-dynamic-text-section').removeClass('hide');
						$('.customer-information-dynamic-text').html(response.contact_html);
						$('.customer-information-section').removeClass('hide');
						$('.address-confirmation-dynamic-text').html(response.address_html);
						$('.address-confirmation-section').removeClass('hide');
						$('.direct-debit-information-dynamic-text').html(response.direct_debit_html);
						$('.direct-debit-information-section').removeClass('hide');
						$('.go-to-checkout-section').removeClass('hide');
						$('.simple-current-usage-section').addClass('hide');
						$('.simple-current-supply-section').addClass('hide');
						$('#secondary_address_confirmation_button').removeClass('disabled');
					}
				});
			} else {
				error_message(response.error);
			}
		});
	});

	$('#lead_script').on('click','.update-simple-current-supply-button',function(e){
		e.preventDefault();
		$('.fancy-section-loading-placeholder').removeClass('hide');
		$('.tariff-information-dynamic-text').html("");
		$('.tariff-pitch-dynamic-text-section').addClass('hide');
		$('.customer-information-dynamic-text').html("");
		$('.customer-information-section').addClass('hide');
		$('.address-confirmation-dynamic-text').html("");
		$('.address-confirmation-section').addClass('hide');
		$('.direct-debit-information-dynamic-text').html("");
		$('.direct-debit-information-section').addClass('hide');
		$('.go-to-checkout-section').addClass('hide');
		$('.simple-current-usage-section').addClass('hide');
		$('.simple-current-supply-section').addClass('hide');
		
		var quote_id = $('#lead_script').data('quoteid');

		var supplyDetails = {};
		// Get selected options from radio buttons
		$('.simple-current-supply-section .option-selected input[type="radio"]').each(function() {
			var name = $(this).attr('name');
			var value = $(this).val();
			supplyDetails[name] = value;
		});

		if ($('.simple-current-supply-section .usage-type-input-section').is(':visible')) {
			$('.simple-current-supply-section .actual-energy-usage-wrapper:not(.hidden)').find('.usage-input-wrapper input[type="text"]').each(function() {
				var name = $(this).attr('name');
				var value = $(this).val();
				supplyDetails[name] = value;
			});
		}
		
		var data = {
			supplyDetails: supplyDetails,
			quote_id :quote_id
		};

		makeRequest('/quote/inline/set-current-supply', data, function(response) {
			if (response.success) {
				makeRequest("/address/get-rebel-quote-bypass/"+quote_id,data, function(response){
					if(isset(response.tariff_html) && isset(response.contact_html) && isset(response.direct_debit_html)){
						$('.fancy-section-loading-placeholder').addClass('hide');
						$('.tariff-information-section').removeClass('hide');
						$('.tariff-information-dynamic-text').html(response.tariff_html);
						$('.tariff-pitch-dynamic-text-section').removeClass('hide');
						$('.customer-information-dynamic-text').html(response.contact_html);
						$('.customer-information-section').removeClass('hide');
						$('.address-confirmation-dynamic-text').html(response.address_html);
						$('.address-confirmation-section').removeClass('hide');
						$('.direct-debit-information-dynamic-text').html(response.direct_debit_html);
						$('.direct-debit-information-section').removeClass('hide');
						$('.go-to-checkout-section').removeClass('hide');
						$('.simple-current-usage-section').addClass('hide');
						$('.simple-current-supply-section').addClass('hide');
						$('#secondary_address_confirmation_button').removeClass('disabled');
					}
				});
			} else {
				error_message(response.error);
			}
		});
		
	});


	$('#lead_script').on('click','#secondary_address_confirmation_button',function(e){
		var button = $(this);
		if(button.hasClass('disabled')){
			return false;
		}
		var address_id = $('#lead_script .uw-address-select-section #address_select :selected').val();
		// if(address_id == "null"){
		// 	return false;
		// }

		var postcode = $('#lead_script .uw-address-select-section .hidden-postcode-input').val();

		// var home_owner = $('.home-owner-status-section input[name="home_owner"]:checked').val();
		// if(!isset(home_owner)){
		// 	hideLoader();
		// 	error_message('please select home owner status');
		// 	return false;
		// }
		
		$(button).addClass('disabled');
		$('.fancy-section-loading-placeholder').removeClass('hide');
		$('.inline-checkout-go-to-backet-button').addClass('hide');
		$('.tariff-information-dynamic-text').html("");
		$('.tariff-pitch-dynamic-text-section').addClass('hide');
		$('.customer-information-dynamic-text').html("");
		$('.customer-information-section').addClass('hide');
		$('.address-confirmation-dynamic-text').html("");
		$('.address-confirmation-section').addClass('hide');
		$('.direct-debit-information-dynamic-text').html("");
		$('.direct-debit-information-section').addClass('hide');
		$('.go-to-checkout-section').addClass('hide');
		$('.simple-current-usage-section').addClass('hide');
		$('.simple-current-supply-section').addClass('hide');
		// you are here and you need to set the manual address stuff 
		var quote_id = $('#lead_script').data('quoteid');
		
		
		var data = {
			quote_id : quote_id,
			address_id : address_id,
			postcode : postcode,
			// home_owner :home_owner,
		};
		// If the select address section is not visible, it's a manual address.
		if($('.uw-address-select-section').hasClass('hide') && !$('.uw-manual-address-section').hasClass('hide')) {
			var isValid = true;
			var formData = {
				"flatNumber": $('#flatNumber').val().trim(),
				"buildingName": $('#buildingName').val().trim(),
				"street": $('#street').val().trim(),
				"town": $('#cityTown').val().trim(),
				"buildingNumber": $('#buildingNumber').val().trim(),
				"postcode": $('#manual_postcode').val().trim(),
				"addressNotListed": true,
			};

			// Check each required field and display an error if it's empty.
			$('.uw-manual-address-section .row').find('input[required]').each(function() {
				var $input = $(this);
				if ($input.val().trim() === '') {
					$('#error_' + $input.attr('id')).removeClass('hide').addClass('shake'); // Show error message
					isValid = false;
				} else {
					$('#error_' + $input.attr('id')).addClass('hide').removeClass('shake'); // Hide error message
				}
			});

			// If not all required fields are valid, stop and show error.
			if (!isValid) {
				error_message('All required fields must be filled out.');
				return false;
			}

			// Extend the existing data object with the form data.
			$.extend(data, formData);
		} else if(address_id.length < 5) {
			error_message('Invalid address selected');
			return false;
		}
		

		
		// $('.secondary-address-selection-needed').addClass('hide');
		$('.fancy-section-loading-placeholder').removeClass('hide');

		// makeRequest('/quote/utility-warehouse/start-quote',data, function(response){
		// 	if(isset(response.success) && response.success){
				makeRequest("/address/get-rebel-quote-bypass/"+quote_id,data, function(response){
					if(isset(response.tariff_html) && isset(response.contact_html) && isset(response.direct_debit_html)){
						$('.fancy-section-loading-placeholder').addClass('hide');
						$('.tariff-information-section').removeClass('hide');
						$('.tariff-information-dynamic-text').html(response.tariff_html);
						$('.tariff-pitch-dynamic-text-section').removeClass('hide');
						$('.customer-information-dynamic-text').html(response.contact_html);
						$('.customer-information-section').removeClass('hide');
						$('.address-confirmation-dynamic-text').html(response.address_html);
						$('.address-confirmation-section').removeClass('hide');
						$('.direct-debit-information-dynamic-text').html(response.direct_debit_html);
						$('.direct-debit-information-section').removeClass('hide');
						$('.go-to-checkout-section').removeClass('hide');
						$('.simple-current-usage-section').addClass('hide');
						$('.simple-current-supply-section').addClass('hide');
						$(button).removeClass('disabled');
						if(!isset(response.offered_brand) || response.offered_brand == 'Utility Warehouse'){ 
							makeRequest('/quote/utility-warehouse/start-quote',data, function(create_quote_response){
								if(isset(create_quote_response.success) && create_quote_response.success){
									// @TODO deal with continue button
									if($('.bank-details-show-new').is(':hidden')){
										$('.inline-checkout-go-to-backet-button').removeClass('hide');
									}
								}
								else{
									if(isset(create_quote_response.error)){
										if(true || home_owner.toLowerCase() == 'yes'){
											$('.view-all-tariffs-button').removeClass('hide');
											$('.inline-checkout-go-to-backet-button').addClass('hide');
											$('.customer-already-registered-warning').removeClass('hide');
											$('.inline-checkout-go-to-backet-button').addClass('hide');
											$('.tariff-information-dynamic-text').html("");
											$('.tariff-pitch-dynamic-text-section').addClass('hide');
											$('.customer-information-dynamic-text').html("");
											$('.customer-information-section').addClass('hide');
											$('.address-confirmation-dynamic-text').html("");
											$('.address-confirmation-section').addClass('hide');
											$('.direct-debit-information-dynamic-text').html("");
											$('.direct-debit-information-section').addClass('hide');
											// $('.go-to-checkout-section').addClass('hide');
											$('.simple-current-usage-section').addClass('hide');
											$('.simple-current-supply-section').addClass('hide');
											if(isset(create_quote_response.message)){
												error_message(create_quote_response.message);
												$('.customer-already-registered-warning').find('h3').html(create_quote_response.message);
												$('.customer-already-registered-warning').removeClass('hide');
												$('.view-all-tariffs-button').removeClass('hide');
												setTimeout(function(){
													if(confirm(create_quote_response.message)){
														return;
													}
												},500);
											}
											else{
												if(isset(create_quote_response.error)){
													error_message(create_quote_response.error);
													$('.customer-already-registered-warning').find('h3').html(create_quote_response.message);
													$('.customer-already-registered-warning').removeClass('hide');
													$('.view-all-tariffs-button').removeClass('hide');
													setTimeout(function(){
														if(confirm(create_quote_response.error)){
															return;
														}
													},500);
												}
											}
										}
									}
									else{
										error_message("Unable to set create UW Quote");
									}
								}
							});
						}
						else{
							// $('#secondary_address_confirmation_button').trigger('click');
							$('.inline-checkout-go-to-backet-button').removeClass('hide');
						}
					}
					else{
						if(isset(response.action)){
							// redirect the user to usage
							if(response.action == 'simple_usage'){
								$('.tariff-information-dynamic-text').html("");
								$('.tariff-pitch-dynamic-text-section').addClass('hide');
								$('.customer-information-dynamic-text').html("");
								$('.customer-information-section').addClass('hide');
								$('.direct-debit-information-dynamic-text').html("");
								$('.direct-debit-information-section').addClass('hide');
								$('.go-to-checkout-section').addClass('hide');
								$('.simple-current-usage-section').removeClass('hide');
								$('.address-confirmation-section').addClass('hide');
								$('.simple-current-supply-section').addClass('hide');
							}
							else if(response.action == 'simple_supply'){
								$('.tariff-information-dynamic-text').html("");
								$('.tariff-pitch-dynamic-text-section').addClass('hide');
								$('.customer-information-dynamic-text').html("");
								$('.customer-information-section').addClass('hide');
								$('.direct-debit-information-dynamic-text').html("");
								$('.direct-debit-information-section').addClass('hide');
								$('.go-to-checkout-section').addClass('hide');
								$('.simple-current-usage-section').addClass('hide');
								$('.address-confirmation-section').addClass('hide');
								$('.simple-current-supply-section').removeClass('hide');
							}
							// lead_script.handleStep(response.redirect);
							return;
						}
						$(button).removeClass('disabled');
						$('.secondary-address-selection-needed').removeClass('hide');
						$('.fancy-section-loading-placeholder').addClass('hide');
						if(isset(response.error)){
							error_message(response.error);
						}
						else{
							error_message("Unable to set address");
						}
					}
					// script.handleStep($(that).data('success-step'));
				});
		// 	}
		// 	else{
		// 		$(button).removeClass('disabled');
		// 		if(isset(response.error)){
		// 			error_message(response.error);
		// 		}
		// 		else{
		// 			error_message("Unable to set address");
		// 		}
		// 	}
		});
	// });

	$('#lead_script').on('click','.inline-checkout-go-to-backet-button',function(){
		// check we have everything we need at this point and validate bank details are set
		showLoader();
		if($('.rebel-address-search-results-container').hasClass('hide')){
			hideLoader();
			error_message('You must select an address to use this button');
			return;
		}

		var home_owner = $('.home-owner-status-section input[name="home_owner"]:checked').val();
		if(!isset(home_owner)){
			hideLoader();
			alert('please select home owner status');
			error_message('please select home owner status');
			return false;
		}
		
		// REMOVED REBEL ADDRESS CHECK WHILE NOT USING REBEL 
		// YOU SHOULD NOT BE ABLE TO GET HERE WITHOUT AN ADDRESS ANYWAY ! 
		// var address_id = $('#rebel_addresses').find('option:selected').val();
		// if(address_id.length < 5){
		// 	hideLoader();
		// 	error_message('You must select an address to use this button');
		// 	return;
		// }
		// lets make sure bank details are set properly on the quote. 
		if($('#new-bank-section').is(':visible')){
			hideLoader();
			error_message('You must save bank details before continuing');
			return;
		}
		if(!$('.customer-details-dont-ask-if-possible').hasClass('hide')){
			hideLoader();
			error_message('Save customer details before continuing');
			return;
		}
		var quote_id = $('#lead_script').data("quoteid");
		var skip_dd = $('.direct-debit-details-dynamic-text-section').find('input[name="skip_dd_flag"]').val();
		
		if(skip_dd > 0){
			var skip_dd_data = {
				quote_id:quote_id,
				skip_dd:skip_dd
			};
			// grab the address id 
			makeRequest('/quote/validate-inline-checkout',skip_dd_data,function(response){
				if(isset(response.success) && response.success){
					if(isset(response.next_step)){
						lead_script.handleStep(response.next_step);
					}
					else{
						lead_script.handleStep('pure-energy.meter_number_search');
					}
				}
				else{
					hideLoader();
					if(isset(response.error)){
						error_message(response.error)
					}
					else{
						error_message('something went wrong, please check and try again');
					}
				}
			});
		}
		else {
			if($('#using-stored-details').val()){
				var that = $(this);
				var update = false;
				var script_element = $(this).closest('.script');
				var script_name = $(script_element).data('script-name');
				var script_ident = $(script_element).find('#active_step').data('script-ident');

				var selected_option = $('#account-history option:selected');
				if($(selected_option).data('account-name-error') && !$('.account-name-error-div').is(':visible')){
					error_message('The current account name is too long, please select a new one.');
					$('.account-name-error-div').show();
					return false;
				}

				var data = {
					home_owner:home_owner
				};
				data['account_id'] = $('#lead_script').find('#account-history').val();
				if($('#payment-date').length && $('#payment-date').is(':visible')) {
					data['payment-date'] = $('#lead_script').find('#payment-date').val();
					if (!(data['payment-date'] >= 1) && !(data['payment-date'] <= 31)) {
						error_message('You must provide a payment day')
						return;
					}
				}

				if($('#account-name-new').length && $('#account-name-new').is(':visible')) {
					var account_name = $('#lead_script').find('#account-name-new').val();
					if(account_name.length > 25 || account_name.length < 2) {
						error_message('Account Name MUST be less than or equal to 18 characters and more than 1!')
						return;
					}
					else {
						data['account_name'] = account_name;
					}
				}
				data['update'] = update;
				data['script_step'] = script_ident;
				
				api.use_stored_bank_details(quote_id, data, function(response){
					if(response.success === true){
						// data.address_id = address_id;
						data.quote_id = quote_id;
						// grab the address id 
						makeRequest('/quote/validate-inline-checkout',data,function(response){
							if(isset(response.success) && response.success){
								if(isset(response.next_step)){
									lead_script.handleStep(response.next_step);
								}
								else{
									lead_script.handleStep('pure-energy.meter_number_search');
								}
							}
							else{
								hideLoader();
								if(isset(response.error)){
									error_message(response.error)
								}
								else{
									error_message('something went wrong, please check and try again');
								}
							}
						});
					}
					else{
						alert(response.message);
					}
				});
			}
			else{
				hideLoader();
				error_message('save bank details before continuing');
			}
		}
	});

	$('#lead_script').on('click','.concertina-heading',function(){
		var clicked_heading = $(this).closest('.concertina-section-wrapper');
		var all_sections = $('.concertina-section-wrapper');
		
		// Hide all sections after the clicked one
		var clicked_index = all_sections.index(clicked_heading);
		all_sections.each(function (index) {
			if (index > clicked_index) {
				$(this).find('.concertina-body-section').addClass('hide');
			}
		});
		
		// Toggle the clicked section
		var body_section = clicked_heading.find('.concertina-body-section');
		if (body_section.hasClass('hide')) {
			body_section.removeClass('hide');
		} else {
			body_section.addClass('hide');
		}
	});
	
	$('#address-select-display').on('change', '#contact-address-select', function(){
		var address_id = $('#contact-address-select :selected').val();
		var contact_id = $('#contact-details').data('contact-id');
		var data = {
			'address_id' : address_id
		}
		makeRequest("/address/set-address/"+contact_id, data, function(response){
			if(isset(response.html)){
				$('#address-list-display').html(response.html);
				$('#address-select-display').hide();
			}
			else{
				error_message('unable to set address');
			}
		})
	});

	$('#lead_script').on('click','.customer-details-we-need-more',function(e){
		e.preventDefault();
		$('.customer-details-dont-ask-if-possible').removeClass('hide');
	});
	
	
	$('.business-tab-content').on('click', '#business-postcode-search',function(){
		var postcode=$('#postcode').val();
		makeRequest('/address/business-address-lookup', {'postcode' : postcode}, function(response){
			$('#address-box').hide();
			$('#address-select-display').show();
			$('#address-select-display').html(response.html);
		}, 'GET');
	});
	
	$('.business-tab-content').on('change', '#address-select-display', function(){
		var address_id = $('#business-address-select :selected').val();
		var business_id = $('#business-details').data('business-id');
		var data = {
			'address_id' : address_id,
			'business_id' : business_id
		}
		makeRequest("/business/link-address", data, function(response){
			if(isset(response.html)){
				$('.business-addresses-section').replaceWith(response.html);
				success_message();
			}
			else{
				error_message('unable to set address');
			}
		})
	});
	
	$('#address-list-display').on('click', '.set-previous-address',function(){
		var address_id = $(this).data('set-previous-address');
		var contact_id = $('#contact-details').data('contact-id');
		$.ajax({ cache: false,
			url: "/address/set-as-previous/"+contact_id,
			type:"POST",
			dataType: 'json',
			data: { address_id : address_id },
			success: function(response){
				$('#address-list-display').html(response.html);
				$('#address-select-display').hide();
				//console.log(response);
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});

	$('#previous-addresses').on('click', '.delete-address',function(e){
            //console.log("Ok, delete button pressed, going to send...");
            var address_id = $(this).data('delete-address');
            var contact_id = $('#contact-details').data('contact-id');
            var first_name = $('#contact-details-edit input[name="first_name"]').val();
            var last_name = $('#contact-details-edit input[name="last_name"]').val();
            var message = 'Delete Address for '+first_name+' '+last_name+'?';
            e.preventDefault();
            var message = new MessageBox('deleteaddress', message);
            message.setData({
            ajax: { 
                //cache: false,
                url: '/address/' +contact_id + '|' + address_id,
                type: 'DELETE',
                success: function(response) {
                  $('#previous-address').html("Address successfully deleted");
                },
                error: function (error) {
                    //console.log(error);
                }
            }
            });
            message.run();
        });	
                 
//            //original handler
//            $.ajax({ cache: false,
//			url: "/address/"+contact_id,
//			type: "DELETE",
//			dataType: 'json',
//			//data: { address_id : address_id },
//			success: function(response){
//				$('#previous-address').html(response.html);
//			},
//			error: function (error) {
//				console.log(error);
//			}
//		});
//	});

	
	$('.previous-addresses-footer').on('click', '#show-previous-addresses', function(){
		var contact_id = $('#contact-details').data('contact-id');
		//console.log('hi');
		$.ajax({ cache: false,
			url: "/address/get-previous/"+contact_id,
			success: function(response){
				$('#previous-address').html(response.html);
				//console.log(response);
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});
	
	$('#address-list-display').on('click', '.update-address',function(e){
		e.preventDefault();
		var address_id = $(this).data('update-address');
		var contact_id = $('#contact-details').data('contact-id');
		var form = $(this).closest('form');
		var formdata = form.serialize();
		var primary = $(this).closest('form').find('input[name="primary"]').is(':checked');
		var data = { address_id : address_id, data : formdata, primary : primary};
		makeRequest("/address/update/"+contact_id, data, function(response){
			if(isset(response.html)){
				$('#address-list-display').html(response.html);
				$('#address-select-display').hide();
			}
			else{
				error_message('unable to update address');
			}
		});
	});

	$('#address-list-display').on('click', '.copy-address-text',function(){
		var address_text = $(this).data('address-text');
		copyToClipboard(address_text);
		success_message('address coppied');
	});
	
	
	$('#address-list-display').on('click', '.unlock-address',function(){
		var address_id = $(this).data('unlock-address');
		var contact_id = $('#contact-details').data('contact-id');
		$.ajax({ cache: false,
			url: "/address/unlock/"+contact_id,
			type:"POST",
			dataType: 'json',
			data: { address_id : address_id },
			success: function(response){
				$('#address-list-display').html(response.html);
				$('#address-select-display').hide();
				//console.log(response);
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});
	
	$('#slidecontent').on('click', '#send-support', function(e){
		e.preventDefault();
		var formData = {
			'issue': $('#support-description option:selected').text(),
			'lead-id': $('#leadid-support').val(),
			'details': $('#support-details').val()
        };
		$.ajax({ cache: false,
			url: "/support",
			type:"POST",
			dataType: 'json',
			data: formData,
			success: function(response){
				$('.support-module-content').hide();
				$('#support-success').show();
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});
	
	var showAddressLoader = function(){
        $('#address_loader').show();
    }

    var hideAddressLoader = function(){
		$('#address_loader').hide();
	}
	
	window.addressSearch = function(search_term, element, callback = function(){}){

		var url = "https://api.addressy.com/Capture/Interactive/Find/v1.00/json3.ws?";
        showAddressLoader();
        $.ajax({ 
            cache: false,
            url: url,
            dataType: 'json',
            type:"POST",
            data : {text:search_term,Countries:'GBR',Key:'ED93-YG38-ZH88-GJ99',Origin:'GBR'}, //,system:system},
            success: function (response) {
                hideAddressLoader();
                if(search_term == $(element).val()){
                    callback(response);
                }
            },
            error: function (error) {
                hideAddressLoader();
            }
        });

	}
	
    window.containerSearch = function(search_term, id, element, callback =function(){}){
        var url = "https://api.addressy.com/Capture/Interactive/Find/v1.00/json3.ws?";
        showAddressLoader();
        $.ajax({ 
            cache: false,
            url: url,
            dataType: 'json',
            type:"POST",
            data : {text:search_term,Countries:'GBR',Key:'ED93-YG38-ZH88-GJ99',Origin:'GBR', Container: id }, //,system:system},
            success: function (response) {
                hideAddressLoader();
                if(search_term == $(element).val()){
                    callback(response);
                }
            },
            error: function (error) {
                hideAddressLoader();
            }
        });
	}
	
	window.highlightText = function(text, hightlight_parts){

		var text_parts = hightlight_parts.split(',');
		var new_text = '';
		var last_end = 0;
		$.each(text_parts, function(i, hightlight_points){
	
			var hightlight_point_parts = hightlight_points.split('-');
	
			var start = parseInt(hightlight_point_parts.shift());
	
			var end = parseInt(hightlight_point_parts.pop()) + 1;
	
			new_text += text.substring(last_end, start) + '<span class="highlight">' + text.substring(start, end) + '</span>';
	
			last_end = end;
		});
	
		new_text += text.substring(last_end, text.length);
	
		return new_text;
	}

	window.getAddressText = function(addres_item){

		var text = '';
		if(isset(addres_item.Text)){
			text = addres_item.Text;
		}
	
		var description = '';
		if(isset(addres_item.Description)){
			description = addres_item.Description;
		}
	
		if(isset(addres_item.Highlight)){
	
			var highlight_parts = addres_item.Highlight.split(';');
	
			var text_part = highlight_parts.shift();
	
			text = highlightText(text, text_part);
	
			if(highlight_parts.length){
	
				var description_part = highlight_parts.pop();
	
				description = highlightText(description, description_part);
			}
		}
	
		return text + ', ' + description;
	
	}


    $('div#lead_script').on('keyup', '#gbg_section input#address', function(){
		var that = this;
        addressSearch($(this).val(), $(this), function(response){
            if(isset(response.Items)){
				$(that).closest('#gbg_section').find('div.address-results-container ul.address-results li').each(function(){
                    $(this).remove();
                });
                $.each(response.Items, function(index, item){                
                    var element = document.createElement('li');
					$(element).addClass('address-result-item');
                    $(that).closest('#gbg_section').find('div.address-results-container ul.address-results').append(element)
                    $(element).data('id', item.Id);
                    $(element).attr('data-id', item.Id);
                    $(element).data('type', item.Type);
                    $(element).attr('data-type', item.Type);
                    $(element).data('text', item.Text);
                    $(element).attr('data-text', item.Text);
                    $(element).data('description', item.Description);
                    $(element).attr('data-description', item.Description);
                    var postcode = item.Description.split(', ').pop();
                    $(element).data('postcode', postcode);
                    $(element).attr('data-postcode', postcode);
                    $(element).text(item.Text + ', ' + item.Description);
                    
				})
				if(response.Items.length == 1){
					$(that).closest('#gbg_section').find('div.address-results-container ul.address-results li').trigger('click');
				}
            }
        })
    });

    $('div#lead_script').on('click', 'div.address-results-container ul.address-results li', function(){

        $('div.address-results-container ul.address-results li.active').removeClass('active');
        var that = this;
        if($(this).data('type') != 'Address'){
            $('div.address-results-container ul.address-results li.sub-item').remove();
            var id = $(this).data('id');
			var search_term = $('#address').val();
			$(this).hide();
            containerSearch(search_term, id, $('#address'), function(response){
                if(isset(response.Items)){
                    $.each(response.Items, function(index, item){                
                        var element = document.createElement('li');
                        $(element).addClass('address-result-item');
                        $(element).addClass('sub-item');
                        $(that).after(element);
                        $(element).data('id', item.Id);
                        $(element).data('type', item.Type);
                        $(element).data('text', item.Text);
                        $(element).data('description', item.Description);
                        var postcode = item.Description.split(', ').pop();
                        $(element).data('postcode', postcode);
                        $(element).attr('data-postcode', postcode);
                        $(element).text(item.Text + ', ' + item.Description);
                        
                    })
                }
            });
            return;

        }
		$(this).addClass('active');
		if($('.script-meter-number-search').length == 0){
			$(this).siblings().hide();
		}
		if($('#meter_search_results').length){
			getAddressDetails($(this).data('id'));
		}
	})
	
	$('#lead_script').on('click', '#send_gbg_address', function(){

		$('#olaf').hide();
		if($('.homeowner-type:not(:checked)').length ==2){
			error_message('Please answer home owner question');
			return false;
		}
		else{
			handleWelcomeStepAddress(quote_id, handleGoodAddress);
		}
	})


	$('.script').on('click', '#no_shown_address, .no-shown-address', function(e){
		e.preventDefault();
		var parent = $(this).parent();
		parent.hide();
		parent.next().show();
	})	

	$('.script').on('keyup', '.get-postcode-page-dynamic-postcode', function(e){
		var postcode = $(this).val();
		$('#gbg_addresses option:not(.placeholder)').remove();
		var regex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
		if(postcode.match(regex)){
			// it looks like a postcode we should go get the addresses for the postcode

			var data = {
				'postcode': postcode
			};

			$('.address-select-loading-icon').show();
			makeRequest('/addresses/get', data, function(response){
				if(isset(response.success) && response.success){
					var addresses = response.addresses;
					$.each(addresses, function(index, address){
						var option = $('<option>');
						$(option).attr('value', address.id);
						$(option).data('postcode', address.postcode);
						$(option).data('full-address', safe_json_decode(address.full_address));
						$(option).data('type', address.type);
						$(option).text(address.address);
						$('#gbg_addresses').append(option);
					});
				}
				$('.address-select-loading-icon').hide();
			});
			

		}
	});


	$('#lead_script').on('submit', '#address_search_form', function(e){
		e.preventDefault();
	})
	
	$('#lead_script').on('click', '.change-meter-number-button', function(e){
		var container = $(this).closest('.meter-number-container');

		var input = $(container).find('.meter-number-input');

		$(input).removeAttr('disabled');
		$(this).hide();

	})


	var handleGoodAddress = function(data){

//		if (!document.getElementById('homeowner-yes').checked && !document.getElementById('homeowner-no').checked) {
//			$('#homeownerError').html('please select homeowner status first');
//			setTimeout(function(){
//				$('#homeownerError').html('');
//			},2000);
//		} 
//		else {
//			var home_owner = $("[name='homeowner-type']:checked").val();
//			data.home_owner = home_owner;

			if(api.is_valid_postcode(data.postcode)){
				api.set_postcode(quote_id,data,function(response){
					if(response.error){
						$('#postcodeError').html(response.message);
						setTimeout(function(){
							$('#postcodeError').html('');
						},2000);	
					}
					else{
						//console.log(response);
						if(response.next_step == 'region'){
							window.lead_script.handleStep(window.lead_script.magicPlease('set_region'));
						}
						else{
							if($('#send_gbg_address').data('success-step')){
								window.lead_script.checkStep($('#active_step').data('script-ident'), function(response){
									if(response.step){
										window.lead_script.handleStep(response.step)
									}
									else{
										window.lead_script.handleStep($('#send_gbg_address').data('success-step'));
									}
								});
							}
							else{
								window.lead_script.handleStep(window.lead_script.magicPlease('set_current_supply'));
							}
						}
					}
				});
			}
			else{
				error_message('invalid postcode');
			}
		//}	
	};


	$('#client-details').on('click', '.create-new-contact-address', function(e){
		e.preventDefault();

		var form = $(this).closest('#address-form');

		var address_data = {};
		var serialized = $(form).serializeArray();
		$.each(serialized, function(i, input){
			address_data[input.name] = input.value;
		})
		var contact_id = $('#contact-details').data('contact-id');
		var data =  {
			'contact_id' : contact_id,
			'address_data' : address_data
		};
		showLoader();
		makeRequest('/contact/addresses/create-manual', data, function(response){
			if(isset(response.success) && response.success && response.html){
				$('.contact-adress-section').replaceWith(response.html);
				success_message();
			}
			else{
				var error = 'Unable to create address';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});		

	});
	
});
