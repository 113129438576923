

//parent to everything -- if you are inclined to copy pastey this file changing this variable might be helpful to you
var scope = 'product_type_multi_script_link';

var column_1 = 'product_types';
var column_2 = 'product_type_scripts';
var column_3 = 'all_scripts';

var edit_panel = 'edit_panel';

var base_url = 'multi_script';


var makeSortable = function () {
	$(".sort-list").sortable().sortable('destroy');
	$(".sort-list").sortable({
		connectWith: ".drop-zone",
		update: sortUpdate,
	}).disableSelection();
};

var sortUpdate = function(e, ui){
	if (this === ui.item.parent()[0]) {
		if(!$(ui.item).find('.start_status').length){
			ui.item.replaceWith(makeLinkElement(ui.item));
		}
	}
}

var makeLinkElement = function(element){
	var existing_start_element = $(element).find('.start_status');
	var start = 0;
	if(existing_start_element.length){
		start = $(existing_start_element).val();
	}
	var existing_end_element = $(element).find('.end_status');
	var end = 0;
	if(existing_end_element.length){
		end = $(existing_end_element).val();
	}
	var inner_html = $(element).html();
	$(element).html(`
	<div class='fixed-height-40'>` + inner_html +` </div>
	<div class='fixed-height-40 white-bg pad-me-please-7'>
		<div class='half-width'>
			<label>Start Status</label>
			<input type='number' class='start_status half-width change_status' value='` + start + `' />
		</div>
		<div class='half-width'>
			<label>End Status</label>
			<input type='number' class='end_status half-width change_status' value='` + end + `' />
		</div>

	</div>`)
	$(element).addClass('fixed-height-80');
	return element[0].outerHTML;
}


var buildPostData = function(){

	var data = { 'items' : [] };

	var product_type_id = $('#' + column_2).find('.module-inner').data('id');

	data['product_type_id'] = product_type_id;

	var items = $('#' + column_2).find('li.sortable-handle');

	$.each(items, function(i, item){

		//put some data into data['items']
		var item_data = {};

		item_data['product_type_id'] = product_type_id;
		item_data['script_id'] = $(item).data('script-id');
		item_data['label'] = $(item).find('.name').text();
		item_data['min_status'] = $(item).find('.start_status').val();
		item_data['max_status'] = $(item).find('.end_status').val();

		data['items'].push(item_data);

	})

	return data;

}


var global_data = false;

var updateSever = function(data = {}){
	global_data = data;
	setTimeout(
		function(){
			if(global_data == data){
				makeRequest(base_url + '/' + column_1 + '/update', data, function(response){
					if(response.success){
						success_message();
					}
					else{
						if(response.errors){
							error_message(response.errors);
						}
						else{
							error_message('doh! something went wrong');
						}
					}
				});
			}
		}, 
		1000
	)

}


$(document).ready(function() {
	
	$('#' + scope).on('click', '#' + column_1 + ' .edit-trigger', function(){

		var id = $(this).data('id');

		var data = {
			'id' : id
		};

		makeRequest(base_url + '/' + column_1 + '/edit', data, function(response){
			if(response.success && response.html){
				$('#' + column_2).html(response.html);
				$('#' + edit_panel).show();
				makeSortable();
			}
		})
	});

	$('#' + scope).on('keyup', '.change_status', function(){

		var data = buildPostData();
		updateSever(data);

	})
	
	$('#' + scope).on('click', '#' + column_2 + ' .delete-trigger', function() {

		$(this).closest('li.sortable-handle').remove();

		var data = buildPostData();
		updateSever(data);
		
	});
	
	
	$('#' + scope).on('click', '#' + column_3 + ' #create_script', function() {

		var input = $(this).parent().find('input');

		var new_name = input.val();

		if(new_name.match(/.{5,}/)){


			$('#edit_script_modal #ident').val(new_name);

			showModal('edit_script_modal');

			$('#edit_script_modal #edit_script').data('id', 0);

			return;
		}
		error_message('please enter at least 5 characters for the script name');
		
	});
	
	$('#' + scope).on('click', '#' + column_3 + ' .edit-trigger', function() {

		var script_id = $(this).data('script-id');

		var data = {
			'script_id' : script_id
		};

		showLoader();

		makeRequest(base_url + '/script/get_details', data, function(response){
			hideLoader();
			if(response.success && response.data){
				
				$.each(response.data, function(name, value){
					$('#edit_script_modal .edit-form #'+name).val(value);
					$('#edit_script_modal .edit-form #' + name).trigger('change');
				});

				$('#edit_script_modal #edit_script').data('id', script_id);

				showModal('edit_script_modal');
				return;
			}
			var error = 'looks broken to me...';
			if(response.error){
				error = response.error;
			}
			error_message(error);
		})

		
	});

	$('#edit_script_modal').on('click', '#edit_script', function(){

		var form = $('#edit_script_modal').find('.edit-form');

		var inputs = form.find(':input:not(button)');

		var data = {};

		var id = $(this).data('id');

		data['id'] = id;

		$.each(inputs, function(i, input){
			data[$(input).attr('name')] = $(input).val();
		});

		makeRequest(base_url +'/script/update', data, function(response){
			if(response.success && response.html){

				$('#' + column_3).html(response.html);
				makeSortable();
			}
		})

	});

	$('body').on('click', '.back-btn', function () {
		var back_step = $(this).data('step');
		var active_step = $('.script-minheight').find('div.script').find('#active_step').data('script-ident');
		if(back_step && active_step){
			if(back_step == active_step) {
				window.location.reload();
			}
		}
	});


	$('#modal_container').on('click', '#adhoc_new_script_group', function(){
		customPrompt('name of script group?', 'group name', function(group_name){
			if(!group_name.length){
				error_message('please enter a valid name, I\'m doing you a favour by giving you this option, please don\'t abuse me');
				return false;
			}
			makeRequest('/script-groups/create-group', {'script-group-name' : group_name}, function(response){
				if(response.success && isset(response.script_group)){
					var new_option = new Option(group_name, response.script_group.id, false, false);
					$('#master_group_id').append(new_option).trigger('change');	
				}			
			})
		});
	});

	
});