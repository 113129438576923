window.$ = window.jQuery = require('jquery');
//require('bootstrap-sass'); -- do not uncomment these lines unless you are js fucking guru and you know what you are doing
//window.Handlebars = require('handlebars/runtime');
require('datatables.net-bs')(window, $);

window.paceOptions = {
    elements: false,
    restartOnRequestAfter: 300,
    easeFactor: 0.2,
    initialRate: .005,
	maxProgressPerFrame: 5,
	trackWebSockets: false,
	ajax:{
		ignoreURLs : [/wss:/, /ws:/]
	}
};

require('pace-progress');
window.displayHelper = require('./api/displayHelper');
window.usageValidator = require('./api/usageValidator');
window.customerValidator = require('./api/customerValidator');
window.addressValidator = require('./api/addressValidator');
window.bankValidator = require('./api/bankValidator');
window.apiInterface = require('./api/api_interface');
window.orderInterface = require('./orders/order_interface');
window.ScriptFlow = require('./components/script_system');

window.MessageBox = require('./components/message_box');
