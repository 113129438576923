
	



$(function () {

	$('body').on('click','.create-appointment',function(e){
        e.preventDefault();
		let contact_id = $(this).data('contact_id');
		// go get all the bits we need for the modal
		makeRequest('/get/create-appointment-modal-content',{contact_id:contact_id},function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				// clear the modal content and repopulate with updated content. 
				$('#createAppointment').find('.modal-content').html(response.html);

				showModal('createAppointment');
				appointments_calendar = createAppointmentCalendar('createAppointment','timeGridWeek',null,[]);
			}
			else{
				let error = 'Unable to create appointment';
                if (isset(response.error)) {
                    error = response.error;
                }
                error_message(error);
			}
			
		});
    });

	$('#createAppointment').on('click','#create_appointment_form_submit',function(e){
		e.preventDefault();
		var form_data = $('#create_appointment_modal_form').serializeArray();
		makeRequest('/appointment/create',form_data,function(response){
			if(isset(response.success) && response.success){
				hideModal('createAppointment');
				success_message('appointment created');
			}
			else{
				if(isset(response.error)){
					error_message(response.error);	
				}
				else{
					error_message('something is wrong');
				}
			}
		});
	});

	$('#createAppointment').on('change','.appointment-filters select',function(){
		appointments_calendar.refetchEvents();
	});

	$('#editAppointment').on('click','#edit_appointment_form_submit',function(e){
		e.preventDefault();
		var form_data = $('#edit_appointment_modal_form').serializeArray();
		var appointment_id = $('#edit_appointment_modal_form').data('appointment_id');
		makeRequest('/appointment/update/'+appointment_id,form_data,function(response){
			if(isset(response.success) && response.success){
				hideModal('editAppointment');
				success_message('appointment updated');
				appointments_calendar.refetchEvents();
			}
			else{
				if(isset(response.error)){
					error_message(response.error);	
				}
				else{
					error_message('something is wrong');
				}
			}
		});
	});

	$('#createAppointment').on('change','select[name="appointment_agent_select"]',function(e){
		appointments_calendar.refetchEvents();
	});

	let appointments_calendar = false;
	$('#createAppointment').on('click','#appointment-settings-wrapper .back-to-calendar-view',function(){
		$('#appointments-calendar-wrapper').show();
		$('#appointment-settings-wrapper').hide();
		let default_view = $('#appointments-calendar-wrapper').data('default_view');
		if(!isset(default_view)){
			default_view = 'timeGridWeek';
		}
		appointments_calendar.changeView(default_view);
	});


	let getSelectedUserValue = function(){
		return $('#appointments-calendar-wrapper select[name="appointment_agent_select"]').find(":selected").val();
	}

	let getFilteredUserValue = function(){
		return $('.appointment-filters select[name="agent_appointments_filter"]').find(":selected").val();
	}

	let getFilteredStatesValue = function(){
		return $('.appointment-filters select[name="status_appointments_filter"]').find(":selected").val();
	}

	let getFilteredTypesValue = function(){
		return $('.appointment-filters select[name="type_appointments_filter"]').find(":selected").val();
	}

	let showEditAppointmentModal = function(appointment_id){
		makeRequest('/get/edit-appointment-modal-content',{appointment_id:appointment_id},function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				// clear the modal content and repopulate with updated content. 
				$('#editAppointment').find('.modal-content').html(response.html);

				showModal('editAppointment');
				$('#editAppointment input[name="appointment_date"]').daterangepicker({
					"locale": {
						"format": 'YYYY-MM-DD H:mm',
						"separator": "-" 
					},
					"singleDatePicker": true,
					"showDropdowns": true,
					"timePicker24Hour": true,
					"timePicker": true,
					"timePickerIncrement": 15,
					"autoApply": true,
				});
			}
			else{
				let error = 'Unable to edit appointment';
                if (isset(response.error)) {
                    error = response.error;
                }
                error_message(error);
			}
			
		});
	}

	let rescheduleEvent = function(appointment_id,data,info,callback){
		makeRequest('/appointment/reschedule/'+appointment_id,data,function(response){
			callback(response);
		});
	}

	let showAppointmentCalendar = function(target,default_view,data){
		var tooltip = false;
		var calendar_element = document.getElementById('calendar');
        var calendar = new FullCalendar.Calendar(calendar_element, {
    		initialView: default_view,
			 headerToolbar: {
				start: 'dayGridMonth,timeGridWeek,timeGridDay',
				center: 'title',
				end: 'prev,next',
			},
			weekends: false,
			slotMinTime: '08:00',
			slotMaxTime: '18:00',
			slotDuration: '00:15:00',
			dateClick : function(info) {
				calendar.changeView('timeGridDay', info.dateStr);
			},
			eventRender: function(info) {
				if (!info.isMirror) {
					var tooltip = new Tooltip(info.el, {
						title: info.event.extendedProps.description,
						placement: 'top',
						trigger: 'hover',
						container: 'body'
					})
				}
			},
			eventMouseLeave:  function(info) {
				if (tooltip) {
					tooltip.dispose();
				}
			},
			selectable:true,
			// editable:true,
			// eventStartEditable:true,
			// eventResizableFromStart:true,
			// eventDurationEditable:true,
		
			eventClick: function(info) {
				// show the edit event modal?
				showEditAppointmentModal(info.event.id);

				// change the border color just for fun
				info.el.style.borderColor = 'red';
			},
			eventDidMount: function(info) {
				$(info.el).tooltip({
					title: info.event.extendedProps.description,
					container: 'body',
					delay: { "show": 50, "hide": 50 }
				});
			},
			// eventChange: function(info){
			// 	var data = {
			// 		appointment_start : info.event.startStr,
			// 		appointment_end : info.event.endStr
			// 	}
			// 	var appointment_id = info.event.id;
			// 	rescheduleEvent(appointment_id,data,info,function(response){
			// 			if(isset(response.success) && response.success){
			// 				appointments_calendar.refetchEvents();
			// 			}
			// 			else{
			// 				info.revert();
			// 				if(isset(response.error)){
			// 					error_message(response.error);	
			// 				}
			// 				else{
			// 					error_message('something is wrong');
			// 				}
			// 			}
			// 	});

				
			// },
			events: {
				url: '/get/appointments',
				method: 'POST',
				extraParams: function(){
					return {
						mode: 'review',
						user_id: getFilteredUserValue(),
						status_id: getFilteredStatesValue(),
						type_id: getFilteredTypesValue(),
						}
					}	
				,
				failure: function() {
				alert('there was an error while fetching events!');
				},
				color: 'yellow',   // a non-ajax option
				textColor: 'black' // a non-ajax option
			}
		  
        });
		calendar.render();
		setTimeout(function(){
			calendar.updateSize();
		}, 100);
		return calendar;
	}


	let createAppointmentCalendar = function(target,default_view,data){
		var calendar_element = document.getElementById('calendar');
        var calendar = new FullCalendar.Calendar(calendar_element, {
    		initialView: default_view,
			 headerToolbar: {
				start: 'dayGridMonth,timeGridWeek,timeGridDay',
				center: 'title',
				end: 'prev,next',
			},
			weekends: false,
			slotMinTime: '08:00',
			slotMaxTime: '18:00',
			slotDuration: '00:15:00',
			dateClick : function(info) {
				calendar.changeView('timeGridDay', info.dateStr);
			},
			selectable:true,
			select: function(info) {
				// work out which view we're in.. 
				if(info.view.type == "timeGridDay" || info.view.type == "timeGridWeek"){
					$('#appointments-calendar-wrapper').hide();
					$('#appointment-settings-wrapper').show();
					var start = moment(info.startStr);
					var end = moment(info.endStr);
					var length = end.diff(start,'minutes');
					var snapped = Math.round(length / 15) * 15;
					$('#appointment_date').find('.appointment-date-label').html(start.format('MMMM Do YYYY, h:mm a'));
					$('#appointment_date').find("input[name='appointment_date']").val(start);
					$('#appointment_length').find("select[name='appointment_length'] option[value="+snapped+"]").attr('selected','selected');
					$('#appointment_agent').find('.appointment-agent-label').html($('#appointments-calendar-wrapper select[name="appointment_agent_select"]').find(":selected").text());
					$('#appointment_agent').find("input[name='appointment_agent_id']").val($('#appointments-calendar-wrapper select[name="appointment_agent_select"]').find(":selected").val());
				}
			},
			eventDidMount: function(info) {
				$(info.el).tooltip({
					title: info.event.extendedProps.description,
					container: 'body',
					delay: { "show": 50, "hide": 50 }
				});
			},
			events: {
				url: '/get/appointments',
				method: 'POST',
				extraParams: function(){
					return {
						user_id: getSelectedUserValue(),
						mode: 'create'
						}
					}	
				,
				failure: function() {
					alert('there was an error while fetching events!');
				},
				color: 'yellow',   // a non-ajax option
				textColor: 'black' // a non-ajax option
			}
		  
        });
		setTimeout(function(){
			calendar.render();
			calendar.updateSize();
		}, 200);
		return calendar;
	}
	
	if($('#appointments-calendar-wrapper').length){
		let default_view = $('#appointments-calendar-wrapper').data('default_view');
		if(!isset(default_view)){
			default_view = 'timeGridWeek';
		}
		appointments_calendar = showAppointmentCalendar('#appointments-calendar-wrapper',default_view,null,[]);
	}
	
});
    