var handleAutoRenewalSelection = function (data, callback) {
	makeRequest('/script/auto-renewal-policy', data, function(response) {
		if(response.success) {
			callback();
		}
		else {
			if(response.error) {
				error_message(response.error);
			}
			else {
				error_message('error, turn it off and on again');
			}
		}
	});
}


window.getBoilerCareData = function (data, container) {
	var flat_data = sanitiseSerialedArray(data, 'field');

	if(isset(flat_data['home_owner']) && flat_data['home_owner'] != 'No'){

		var form_data = $(container).find('.boiler-care-follow-up-questions :input:visible').serializeArray();
		
		$.each(form_data, function(i, field){
			data.push({
				'field' : field['name'],
				'value' : field['value']
			});
		});
	}
	return data;
}

$(function() {
	$('.script').on('click', '#confirm_auto_renewal_policy_button', function () {
		let that = $(this),
			data = {
				policy_id: $('.renewal-policy-selection.active').data('policy-id'),
				type_id: $('.renewal-policy-selection.active').data('type-id'),
				sales_order_id: $(this).data('sales-order-id'),
				state: true,
				policies_to_remove: []
			},
			$container = $('.renewal-policy-selection.active');

		if(!$container.length) {
			$('.selection-error').show();
			return;
		}

		if($('.policy-switch-row').length) {
			$.each($('.policy-switch-row'), function (i, $row) {
				if (!$($row).find('.switch-input-checkbox-select').is(':checked')) {
					data.policies_to_remove.push({
						policy_id: $($row).data('policy-id'),
						sales_order_id: $($row).data('sales-order-id')
					});
				}
			});
		}
		else if($('.hidden-policies-to-remove').length){
			$.each($('.hidden-policies-to-remove :input'),function(i, row){
				data.policies_to_remove.push({
					policy_id: $(row).data('policy-id'),
					sales_order_id: $(row).data('sales-order-id')
				});
			});
		}

		handleAutoRenewalSelection(data, function () {
				let script = false;
				if(typeof window.lead_script.checkStep === "function") {
					script = window.lead_script;
				}
				else {
					script = window.aftersale_auto_renew_script;
				}


				var current_step = $(that).data('step');
				if(!current_step) {
					current_step = $(script.anchor).find('#active_step').data('script-ident');
				}

				script.checkStep(current_step, function (response) {
					if (response.step) {
						script.handleStep(response.step)
					}
					else {
						script.handleStep($(that).data('success-step'))
					}
				});
			}
		);
	});

    $('#lead_script').on('click', '.accept_recap', function () {
        window.lead_script.handleStep(window.lead_script.magicPlease($(this).data('magic-step')));
	});
	
	$('.script').on('click', '#submit_after_sale_meter_numbers', function () {
		var script = $(this).closest('#lead_script');
		var inputs = $(script).find('.meter_number_search_meter_input');
		var data = $(inputs).serializeArray();
		var post_data = {
			'quote_id' : $(script).data('quoteid'),
			'data' : {}
		};
		var validation = {
			'gas_meter' : /^(?=.*[1-9])[0-9]{10}$/,
			'electricity_meter' : /^[0-9]{13}$/
		}
		var bail = false;
		$.each(data, function(i, item){
			item.value = item.value.trim();
			if(item.value.length){
				if(isset(validation[item.name]) && item.value.match(validation[item.name])){	
					post_data['data'][item.name] = item.value;
				}
				else{
					error_message('please enter a valid ' + item.name.replace('_', ' ') + ' number') ;
					bail = true;
				}
			}
		});
		if(!bail){
			var that = this;
			makeRequest('/after-sales/store/meter-numbers', post_data, function(response){
				if(isset(response.success) && response.success){
					aftersale_script.handleStep($(that).data('success-step'));
				}
				else{
					var error = 'unable to set meter numbers - pelase refresh and try again';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			})
		}
	});

	var getDob = function(script){
		var dob_day = $(script).find('#customer-details-dob-day').val();
		var dob_month = $(script).find('#customer-details-dob-month').val();
		var dob_year = $(script).find('#customer-details-dob-year').val();

		var dob = moment().date(dob_day).month(dob_month -1).year(dob_year);
		if(dob.isValid()){
			if(moment().diff(dob, 'years') > 17){
				//we have a good dob
				return dob.format('D-M-YYYY'); 
			}
			else{
				error_message('the customer must be at least 18 years old');
				return false;
			}
		}
		else{
			error_message('invalid dob');
			return false;
		}
	}

	var getEmail = function(script){
		var email = $(script).find('#customer-details-email').val();
		if(email == '' || !email.match(/\S+@\S+\.\S+/)){
			error_message('please enter a valid email address');
			return false;
		}
		return email;
	}
	
	var getAccountNumber = function(script){
		var number = $(script).find('#account_number').val();
		if(number == '' || (!number.match(/^67\d{10}$/) && !number.match(/\d{6,9}$/))){
			error_message('please enter a valid supplier account number');
			return false;
		}
		return number;
	}
	
	$('.script').on('click', '#submit_after_sale_additional_items', function () {
		var script = $(this).closest('#lead_script');
		var data = {
			'quote_id' : $(script).data('quoteid'),
			'data' : {}
		};
		//dob
		if($(script).find('#dob_container').length){
			var dob = getDob(script);
			if(dob === false){
				return false;
			}
			data.data.dob = dob;
		}
		// email
		if($(script).find('#email_container').length){
			var email = getEmail(script);
			if(email === false){
				return false;
			}
			data.data.email = email;
		}

		// account number
		if($(script).find('#supplier_account_number_container').length){
			var account_number = getAccountNumber(script);
			if(account_number === false){
				return false;
			}
			data.data.account_number = account_number;
		}

		var that = this;
		makeRequest('/after-sales/store/additional-items', data, function(response){
			if(isset(response.success) && response.success){
				aftersale_script.handleStep($(that).data('success-step'));
			}
			else{
				var error = 'unable to store data - pelase refresh and try again (maybe make a note of the values here first)';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})

	});
	
	$('.script').on('click', '#complete-aftersales', function () {
		showLoader();
		var script = $(this).closest('#lead_script');
		var quotes_to_confirm = [];
		var quote_id = $(script).data('quoteid');
		quotes_to_confirm.push(quote_id);
		
		var keys_to_update  = {};
		var data= {
			'quotes' : quotes_to_confirm,
			'checkout_actions' : keys_to_update
		};

		// data['reset_confirmed_date'] = $(this).data('reset-confirmed-date');
		
		var api = new apiInterface();
		api.confirmOrders(data, function(response){
			if(response.success){
				window.location.href =  response.redirect_url;
			}
			else{
				hideLoader();
				if(isset(response.errrors)){
					response.errors.each(function(i,e){
						error_message(e);
					});
				}
				else{
					error_message('something went wrong');
				}
			}
		});
		
		return;
	});
		// var script = $(this).closest('#lead_script');
		// var data = {
		// 	'order_id' : $(script).data('quoteid'),
		// 	'data' : {}
		// };
		// makeRequest('/after-sales/complete/order', data, function(response){
		// 	if(isset(response.success) && response.success){
		// 		window.location.reload();
		// 	}
		// 	else{
		// 		var error = 'unable to confirm order - pelase refresh and try again (maybe make a note of the values here first)';
		// 		if(isset(response.error)){
		// 			error = response.error;
		// 		}
		// 		error_message(error);
		// 	}
		// })
		// window.location.reload();
	// });

	$('.script').on('change','.monthly-spend-fact-find-container input[name="current_energy_spend"]',function(){
		var energy_spend = $(this).val();
		$('.energy-bundle-pricing-calculator input[name="current_energy_monthly_spend"').val(energy_spend);
		$('.energy-bundle-pricing-calculator input').trigger('change');
	});

	$('.script').on('change','.monthly-spend-fact-find-container input[name="current_broadband_spend"]',function(){
		var broadband_spend = $(this).val();
		$('.energy-bundle-pricing-calculator input[name="current_broadband_monthly_spend"').val(broadband_spend);
		$('.energy-bundle-pricing-calculator input').trigger('change');
	});

	$('.script').on('click', '#monthly-spend-fact-find', function () {
		var next_step = $(this).data('success-step');
		var script = $(this).closest('#lead_script');
		var form_data = $('#monthly_spend_fact_find_form').serialize();
		// reset all the errors
		$('#monthly_spend_fact_find_form .error').removeClass('shake').addClass('hide').text('');
		var data = {
			'quote_id' : $(script).data('quoteid'),
			'form_data' : form_data
		};
		makeRequest('/quote/store/monthly-spend-data', data, function(response){
			if(isset(response.success) && response.success){
				window.lead_script.handleStep(next_step);
			}
			else{
				var error = 'error storing monthly spend data';
				if(isset(response.errors)){
					$.each(response.errors,function(key,error){
						console.log(error);
						console.log('.'+error.target+'_error');
						$('.'+error.target+'_error').text(error.message);
						$('.'+error.target+'_error').removeClass('hide');
						$('.'+error.target+'_error').addClass('shake');
						// setTimeout(function(){
						// 	$('.'+error.target+'_error').addClass('hide');
						// 	$('.'+error.target+'_error').removeClass('shake');
						// },5000)
					});
				}
				else{
					error_message(error);
				}
			}
		})
	});
	
	
	
	$('.script').on('click', '#start_save_loss_script', function () {
		//we need to check dpa has been completed 
		if($('.dpa_checkbox:checked').length < 3){
			error_message('please check at least 3 items of dpa');
			return false;
		}
		//need to start switch here 
		var script = $(this).closest('#lead_script');
		var that = this;
		var data = {
			'quote_id' :  $(script).data('quoteid')
		};

		var feedback_input = $(script).find('#customer_feedback');
		if(feedback_input.length){
			var feedback = feedback_input.val();
			if(feedback.length){
				data.feedback = feedback;
			}
		}

		makeRequest('/api/start/swtich', data, function(response){
			if(isset(response.success) && response.success){
				window.lead_script.handleStep($(that).data('success-step'));
			}
			else{
				var error = 'unable to start switch';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})
	});
	
	$('.script').on('click', '.save-loss-tariff-details-button', function () {
		var script = $(this).closest('#lead_script');
		var tariff_id = $(this).data('tariffid');
		var quote_id = $(script).data('quoteid');
		var json = $(this).data('json')
		var data = {
			'quote_id' :  quote_id,
			'tariff_id' : tariff_id,
			'json' : json

		}
		makeRequest('/api/get/offline-details', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('#tariff-detail-parent-'+tariff_id).find('.tariff-info-box').html(response.html);
			}
			else{
				var error = 'unable to get details';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})
	});
	
	$('.script').on('click', '.save-loss-tariff-details-button', function () {
		var script = $(this).closest('#lead_script');
		var tariff_id = $(this).data('tariffid');
		var quote_id = $(script).data('quoteid');
		var json = $(this).data('json')
		var data = {
			'quote_id' :  quote_id,
			'tariff_id' : tariff_id,
			'json' : json

		}
		makeRequest('/api/get/offline-details', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('#tariff-detail-parent-'+tariff_id).find('.tariff-info-box').html(response.html);
			}
			else{
				var error = 'unable to get details';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})
	});
	
	$('.script').on('click', '#submit_save_loss_email', function () {
		var script = $(this).closest('#lead_script');
		var email = getEmail(script);
		if(email){
			var quote_id = $(script).data('quoteid');
			var data = {
				'quote_id' :  quote_id,
				'email' : email
			}
			var that = this;
			makeRequest('/api/set/email', data, function(response){
				if(isset(response.success) && response.success){
					window.lead_script.handleStep($(that).data('success-step'));
					refeshContactDetails();
				}
				else{
					var error = 'unable to get details';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			});
		}
	});
	
	$('.script').on('click', '#submit_carbon_offset_email', function () {
		var script = $(this).closest('#lead_script');
		var email = getEmail(script);
		if(email){
			var quote_id = $(script).data('quoteid');
			var data = {
				'quote_id' :  quote_id,
				'email' : email
			}
			var that = this;
			makeRequest('/api/set/email', data, function(response){
				if(isset(response.success) && response.success){
					let script = false;
					if(typeof window.lead_script.checkStep === "function") {
						script = window.lead_script;
					}
					else {
						script = window.aftersale_auto_renew_script;
					}

					script.handleStep($(that).data('success-step'));
					refeshContactDetails();
				}
				else{
					var error = 'unable to get details';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			});
		}
	});

	$('.script').on('click', '#submit_save_loss_feedback', function () {
		var script = $(this).closest('#lead_script');
		var feedback = $('#customer_feedback').val();
		
		var quote_id = $(script).data('quoteid');
		var data = {
			'quote_id' :  quote_id,
			'feedback' : feedback
		}
		var that = this;
		makeRequest('/script/set/leaving-customer-feedback', data, function(response){
			if(isset(response.success) && response.success){
				window.lead_script.handleStep($(that).data('success-step'));
			}
			else{
				var error = 'unable to save feedback';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
	
	$('.script').on('change', '#show_contract_start_date', function () {
		var state = $(this).is(':checked');
		$('.contract_start_date_container').toggle(state);
	});

	$('.script').on('click', '.renewal-policy-selection', function () {
		let data_target = $(this).data('policy-id');

		$('.selection-error').hide();
		$(".renewal-policy-content").hide();
		$('.renewal-policy-selection').removeClass('active');

		$(this).addClass('active');

		$('.renewal-policy-content-container').find("[data-policy-id='"+data_target+"']").show();
	});
	
	$('.script').on('click', '.select-auto-switch-from-tariffs', function () {
		var script = $(this).closest('#lead_script');
		var quote_id = $(script).data('quoteid');
		var data = {
			'quote_id' :  quote_id
		}
		showLoader();
		makeRequest('/api/set-up-switching-service-quote', data, function(response){
			if(isset(response.success) && response.success && isset(response.quote_id)){
				window.location.href = '/quote/' + response.quote_id;
			}
			else{
				var error = 'unable to prepare switching service quote';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
				hideLoader();
			}
		});
	});
	
	$('.script').on('click', '#bail_aftersales_auto_switch_to_current_supply', function () {
		var script = $(this).closest('#lead_script');
		var quote_id = $(script).data('quoteid');
		var data = {
			'quote_id' :  quote_id
		}
		showLoader();
		makeRequest('/api/set-up-switching-service-quote-current-supply', data, function(response){
			if(isset(response.success) && response.success && isset(response.quote_id)){
				window.location.href = '/quote/' + response.quote_id;
			}
			else{
				var error = 'unable to prepare switching service quote';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
				hideLoader();
			}
		});
	});

	$('.script').on('click', '#auto_switch_dpa_confirm', function () {
		if($('.dpa_checkbox:checked').length < 1){
			error_message('please check at least 1 items of dpa');
			return false;
		}

		var script_element = $(this).closest('.script');
		var quote_id = $(script_element).data('quoteid');

		let script = false;
		if(typeof window.lead_script.checkStep === "function") {
			script = window.lead_script;
		}
		else {
			script = window.aftersale_auto_renew_script;
		}
		var that = this;
		makeRequest('/api/optionchanged/'+quote_id, {
			'key' : 'customer:has_warm_home_discount', 
			'value' : $('.has_warm_home_discount').is(':checked') ? 'Yes' : 'No'
		}, function(response){
			script.handleStep($(that).data('success-step'));
		})
	});

	var handleGoodAddress = function(data, next_step, quote_id){
		var api = new apiInterface();
		if(api.is_valid_postcode(data.postcode)){
			api.set_postcode(quote_id,data,function(response){
				if(response.error){
					$('#postcodeError').html(response.message);
					setTimeout(function(){
						$('#postcodeError').html('');
					},2000);	
				}
				else{
					//console.log(response);
					if(response.next_step == 'region'){
						window.lead_script.handleStep(window.lead_script.magicPlease('set_region'));
					}
					else{
						window.lead_script.handleStep(next_step);
					}
				}
				hideLoader();
			});
		}
		else{
			error_message('invalid postcode');
			hideLoader();
		}
	}
	
	$('.script').on('click', '#auto_switch_dpa_stand_alone_confirm', function () {
		showLoader();
		var script_element = $(this).closest('.script');
		var quote_id = $(script_element).data('quoteid');

		var that = this;
		//okay actually first first lets set warm home discount - that should be easy 
		makeRequest('/api/optionchanged/'+quote_id, {
			'key' : 'customer:has_warm_home_discount', 
			'value' : $('.has_warm_home_discount').is(':checked') ? 'Yes' : 'No'
		}, function(response){

			//okay first - have we chosen to give a new address/postcode? 

			if($('#ar_change_postcode_section').is(':visible')){

				//we are using a new postcode 

				if($('#gbg_section') && $('#gbg_section').is(':visible')){
					if(!$('#gbg_addresses').val() || $('#gbg_addresses').val() == 'PLEASE SELECT AN ADDRESS HERE'){
						error_message('you MUST select an address here');
						return false;
					} 
					var selected_option = $('#gbg_addresses').find('option:selected');
					var data = {};
					data.type = $(selected_option).data('type');
					data.external_address_id = $('#gbg_addresses').val();
					data.address_text = $(selected_option).text();
					data.postcode = $(selected_option).data('postcode');
					data.quote_id = quote_id;
					if($(selected_option).data('full-address')){
						data.full_address = $(selected_option).data('full-address');
					}
					makeRequest('/address/send_dynamic_address', data, function(response){
						if(response.success) {
							if(response.html){
								$('#address-list-display').html(response.html);
							}
							handleGoodAddress(data, $(that).data('success-step'), quote_id)
						}
						else{
							hideLoader();
							error_message(response.error);
						}
					});
				}
				else{
					var data = {
						'postcode': $('#api_postcode').val()
					};
					handleGoodAddress(data, $(that).data('success-step'), quote_id);
				}

			}
			else{
				if($('.dpa_checkbox:checked').length < 1){
					error_message('please check at least 1 item of dpa');
					hideLoader();
					return false;
				}
				var data = {
					'postcode': $(that).data('postcode')
				};
				handleGoodAddress(data, $(that).data('success-step'), quote_id);
			}
		});
	});
	
	$('.script').on('click', '#warm_home_discount_continue', function () {
		var script_element = $(this).closest('.script');
		var quote_id = $(script_element).data('quoteid');

		let script = false;
		if(typeof window.lead_script.checkStep === "function") {
			script = window.lead_script;
		}
		else {
			script = window.aftersale_auto_renew_script;
		}
		var that = this;
		makeRequest('/api/optionchanged/'+quote_id, {
			'key' : 'customer:has_warm_home_discount', 
			'value' : $('.has_warm_home_discount').is(':checked') ? 'Yes' : 'No'
		}, function(response){
			script.handleStep($(that).data('success-step'));
		})


	});

	$('.script').on('click','#confirm-aftersale-auto-switch-address',function(){
		var that = this;
		var data = [];
		$('.error-message').html('');

		var api = new apiInterface();
		var address_validator = new addressValidator();
		var quote_id = $(this).data('quote-id');

		var script  = $(this).closest('.script');

		if($('#energy-address-select').is(':visible')){
			
			if(!$('#selected-energy-address-id').val()){
				var message = new MessageBox('errorDialog', 'Please select an address');
				message.setData({});
				message.run();
				return false;
			}
			//var address_id = $('#selected-energy-address-id').val();
			data.push({field : 'address_id',value : $('#selected-energy-address-id').val()});
			data.push({field : 'address_type',value : $('#selected-energy-address-type').val()});
			if (!$("[name='boiler-care-follow-up-questions boiler-care-follow-up-questions ']:checked").length) {
				$('#homeownerError').html('please select homeowner status first');
				setTimeout(function(){
					$('#homeownerError').html('');
				},2000);
				return;
			} 
			var home_owner = $("[name='homeowner-type']:checked").val();
			data.push({field:'home_owner',value:home_owner});
			var container  = $(this).closest('.script');

			data = getBoilerCareData(data, container);

			api.confirm_address_selection(quote_id,data,function(response){
				if(response.success === true){
					if($(that).data('success-step')){
						
						window.aftersale_auto_renew_script.checkStep($(aftersale_auto_renew_script.anchor).find('#active_step').data('script-ident'), function(response){
							if(response.step){
								window.aftersale_auto_renew_script.handleStep(response.step)
							}
							else{
								window.aftersale_auto_renew_script.handleStep($(that).data('success-step'))
							}
						});
						
					}
					else{
						window.aftersale_auto_renew_script.handleStep(window.aftersale_auto_renew_script.magicPlease('smart_meter'));
					}
				}
				else{
					if(response.errors.length){
						$.each(response.errors,function(index,error){
							if($('#'+error.target).length){
								$('#'+error.target).html(error.message);
							}
							else{
								$('#error-other').html(error.message.text);
							}
						});
					}
				}
			});
		}else if($('#energy-address-manual').is(':visible')){
			address_validator.validateManualAddress(function(errors,data){
				if(errors !== null){
					$.each(errors,function(index,error){
						if($('#'+error.target).length){
							$('#'+error.target).html(error.message);
						}
						else{
							$('#error-other').html(error.message.text);
						}
					});
				}
				else{
					// we're all good send the address to energy helpline
					if($(this).data('boiler-serviceable')){
						data.push({field : 'boiler_serviceable', value : $(this).data('boiler-serviceable')});
					}
					if (!$("[name='homeowner-type']:checked").length) {
						$('#homeownerError').html('please select homeowner status first');
						setTimeout(function(){
							$('#homeownerError').html('');
						},2000);
						return;
					} 
					var home_owner = $("[name='homeowner-type']:checked").val();
					data.push({field:'home_owner',value:home_owner});
					data.push({field : 'script_step', value: $('#active_step').data('script-ident')});
					
					data = getBoilerCareData(data, script);
					api.confirm_manual_address(quote_id,data,function(response){
						//console.log(response);
						if(response.success === true){
							if($(that).data('success-step')){
								window.aftersale_auto_renew_script.checkStep($(aftersale_auto_renew_script.anchor).find('#active_step').data('script-ident'), function(response){
									if(response.step){
										window.aftersale_auto_renew_script.handleStep(response.step)
									}
									else{
										window.aftersale_auto_renew_script.handleStep($(that).data('success-step'))
									}
								})
							}
							else{
								window.aftersale_auto_renew_script.handleStep(window.aftersale_auto_renew_script.magicPlease('smart_meter'));
							}
						}
						else{
							if(isset(response.errors) && response.errors.length){
								$.each(response.errors,function(index,error){
									if($('#'+error.target).length){
										$('#'+error.target).html(error.message);
									}
									else{
										$('#error-other').append(error.message.text+'<br/>');
									}
								});
							}
							if(isset(response.error)){
								$('#error-other').append(response.error+'<br/>');
								error_message(response.error);
							}
						}
					});
				}
			}, script);
		}
	});
	
	$('.script').on('click','#submit_broadband_details',function(){
		var that = this;
		var script = $(this).closest('.script');
		var quote_id = $(script).data('quoteid');


		var raw_values = $(script).find('.broadband-section :input').serializeArray();
		var values = sanitiseSerialedArray(raw_values);

		var to_send = {};
		var cascade = ['tv', 'broadband', 'phone'];

		var bail = false;
		$.each(cascade, function(i, type){
			if(isset(values['has_' + type + '_package'])){
				if(isset(values[type + '_provider']) && values[type + '_provider'].length){
					to_send[type] = values[type + '_provider'];
					if(isset(values[type + '_end_date'])){
						//check if the end date is visible
						if($(script).find('input[name="' + type + '_end_date"]').is(':visible')){
							//only send it it was not hidden 
							to_send[type + '_end_date'] = values[type + '_end_date'];
						}
					}
					// the or broardband below is a hack to make us get some OK-ish data for phone without asking for it
					if(isset(values[type + '_does_' + cascade[i+1]]) || type == 'broadband'){ 
						$.each(cascade, function(sub_i, sub_type){
							if(sub_i > i){
								to_send[sub_type] = values[type + '_provider'];
								//check if the end date is visible
								if(isset(to_send[type + '_end_date'])){
									//only send it it was not hidden 
									to_send[sub_type + '_end_date'] = to_send[type + '_end_date'];
								}
							}
						});
						return false;
					}
				}
				else{
					error_message('please select a ' + type + ' provider');
					bail = true;
					return false;
				}
			}
		});
		
		if(bail){
			return false;
		}

		var data = {
			'quote_id' : quote_id,
			'data' : to_send
		};

		makeRequest('/api/store/broadband-details', data, function(response){
			if(isset(response.success) && response.success){
				window[$(script).data('script-name') + '_script'].handleStep($(that).data('success-step'));
			}
			else{
				var error = 'unable to set details - pelase refresh and try again';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})	

	});
	
	$('.script').on('click','#submit_broadband_referral',function(){
		var that = this;
		var script = $(this).closest('.script');

		var data = {
			'link_id': $('#contact-details').data('contact-id'),
			'event_type_name': 'contact',
			'product_type': 'broadband',
			'add_to_basket': 'false',
			'event_name' : 'broadband_referral'
		}

		var input_values = sanitiseSerialedArray($(script).find(':input').serializeArray());
		$.each(input_values, function(i, v){
			data[i] = v;
		});

		showLoader();
		makeRequest('/event/referral', data, function(response){
			if(isset(response.success) && response.success){
				success_message('referred contact');
				$(that).html('<i class="fa-solid fa-check" aria-hidden="true"></i>')
			}
			else{
				var error = 'unable refer contact';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		})	

	});

	$('.script').on('change','.broadband-section .toggle-broadband-type',function(){
		if(!$(this).is(':checked')){
			//we have been turned off - this turned off any child types - then lets shwo the sections again
			var type = $(this).data('type');
			var children_control = $("input[name^='" + type +"_does']");
			if($(children_control).is(':checked')){
				$(children_control).trigger('click');
			}
		}
	});

	$('.script, #main_modal').on('change','.broadband-section .toggle-provider',function(){
		var provider = $(this).val();
		var container = $(this).closest('.broadband-section');
		var end_date_section = $(container).find('.end-date-section');
		var show_providers = $(end_date_section).data('show-providers');
		if(show_providers){
			if(show_providers.indexOf(provider) !== -1){
				$(end_date_section).collapse('show');
			}
			else{
				$(end_date_section).collapse('hide');
			}
		}
	});
	
	$('.script').on('click','#send-bbt-hotlink-sms',function(){
		if($(this).data('state') == 'loading'){
			return false;
		}
		$(this).data('state', 'loading');
		var parent = $(this).parent();
		var data = {};
		if($(parent).find('.contact-mobile').length){
			//we need a mobile number 
			var mobile = $(parent).find('.contact-mobile').val();
			if(!mobile.length){
				error_message('please enter a mobile number');
				$(this).data('state', 'ready');
				return false;
			}
			var mobile_regex = /^07[0-9]{8,10}$/;
			if(!mobile_regex.test(mobile)){
				error_message('please enter a valid mobile number');
				$(this).data('state', 'ready');
				return false;
			}
			data.mobile = mobile;
		}

		data.contact_id = $('#contact-details').data('contact-id');
		makeLoadingIconFromText(this);
		var that = this;
		makeRequest('/broadband/send-bbt-hotlink-sms', data, function(response){
			if(isset(response.success) && response.success){
				success_message('sms sent');
			}
			else{
				var error = 'unable to send sms';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			restoreTextFromLoadingIcon(that);
			$(that).data('state', 'ready');
		});
	});
	
	$('.script').on('click','#send-bbt-hotlink-email',function(){
		if($(this).data('state') == 'loading'){
			return false;
		}
		$(this).data('state', 'loading');
		var parent = $(this).parent();
		var data = {};
		if($(parent).find('.contact-email').length){
			//we need an email 
			var email = $(parent).find('.contact-email').val();
			if(!email.length){
				error_message('please enter a email address');
				$(this).data('state', 'ready');
				return false;
			}
			var email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
			if(!email_regex.test(email)){
				error_message('please enter a valid email address');
				$(this).data('state', 'ready');
				return false;
			}
			data.email = email;
		}

		data.contact_id = $('#contact-details').data('contact-id');
		makeLoadingIconFromText(this);
		var that = this;
		makeRequest('/broadband/send-bbt-hotlink-email', data, function(response){
			if(isset(response.success) && response.success){
				success_message('email sent');
			}
			else{
				var error = 'unable to send email';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			restoreTextFromLoadingIcon(that);
			$(that).data('state', 'ready');
		});
	});

});
