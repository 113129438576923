$(document).ready(function(){
	var api = new apiInterface();
	var quote_id = $('#lead_script').data("quoteid");
	var view = new displayHelper();
	var validator = new usageValidator();
	$("#lead_script").on('click',"[id$='-tariff_end_date']",function(e){
		$(e.target).first('input').daterangepicker({
			"locale": {
				"format": 'DD-MM-YYYY',
				"separator": "-" 
			},
			"singleDatePicker": true,
			"showDropdowns": true
		});
		$(e.target).focus();
	});

	$('#lead_script').on('submit','#postcodeform',function(e){
		e.stopPropagation();
		e.preventDefault();
        if (!document.getElementById('homeowner-yes').checked && !document.getElementById('homeowner-no').checked) {
			$('#homeownerError').html('please select homeowner status first');
			setTimeout(function(){
				$('#homeownerError').html('');
            },2000);
        } 
		else {
            
			var postcode = $('#api_postcode').val();
			var home_owner = $("[name='homeowner-type']:checked").val();
			var data = {
				'postcode' : postcode,
				'home_owner' : home_owner
			}
		if(api.is_valid_postcode(postcode)){
			api.set_postcode(quote_id,data,function(response){
				if(response.error){
					$('#postcodeError').html(response.message);
					setTimeout(function(){
						$('#postcodeError').html('');
					},2000);	
				}
				else{
					//console.log(response);
					if(response.next_step == 'region'){
						window.lead_script.handleStep(window.lead_script.magicPlease('set_region'));
					}
					else{
						window.lead_script.handleStep(window.lead_script.magicPlease('set_current_supply'));
					}
				}
			});
		}
		else {
			$('#postcodeError').html('please enter a valid postcode');
			setTimeout(function(){
				$('#postcodeError').html('');
			},2000);
		}
		// check is valid postcode,
            }
	});


	$('#lead_script').on('click', '#reject_estimated_details', function(){

		$('.address-data-estimation-overlay').hide();
		$('#compareType').show();

	});

	$('#lead_script').on('click', '#change_supply_summary_details', function(){
		$('.energy-usage-page').removeClass('hide');
		$('.current-supply-simple-summary').hide();
	});

	$('#lead_script').on('click', '#womp_energy_current_supply_next',function(e){
		e.preventDefault();
		var next_step = $(this).data('success-step');

		var quote_id = $('#lead_script').data('quoteid');

		var supplyDetails = {};
		// Get selected options from radio buttons
		$('.option-selected input[type="radio"]').each(function() {
			var name = $(this).attr('name');
			var value = $(this).val();
			supplyDetails[name] = value;
		});

		if ($('.usage-type-input-section').is(':visible')) {
			$('.actual-energy-usage-wrapper:not(.hidden)').find('.usage-input-wrapper input[type="text"]').each(function() {
				var name = $(this).attr('name');
				var value = $(this).val();
				supplyDetails[name] = value;
			});
		}

		var energy_supplier = $('.energy-supplier-and-spend-section select[name="energy_supplier"]').val();
		var energy_spend = $('.energy-supplier-and-spend-section input[name="current_energy_spend"]').val();
		supplyDetails['energy_supplier'] = energy_supplier;
		supplyDetails['energy_spend'] = energy_spend;
		
		var data = {
			supplyDetails: supplyDetails,
			quote_id :quote_id
		};

		makeRequest('/quote/womp/set-current-supply', data, function(response) {
			if (response.success) {
				lead_script.handleStep(next_step);
			} else {
				error_message(response.error);
			}
		});
		
	});

	$('#lead_script').on('click', '#womp_energy_simple_current_usage_next', function(e) {
		e.preventDefault();
		var next_step = $(this).data('success-step');
		var quote_id = $('#lead_script').data('quoteid');

		var usageDetails = {};

		// Get selected radio options (for simple usage and others)
		$('.usage-type-input-section:not(.hidden) .option-selected input[type="radio"]').each(function() {
			var name = $(this).attr('name');
			var value = $(this).val();
			usageDetails[name] = value;
		});

		// If the actual usage section is visible, collect text inputs (e.g., electricity, gas usage)
		if ($('.usage-type-input-section').is(':visible')) {
			$('.actual-energy-usage-wrapper:not(.hidden)').find('.usage-input-wrapper:not(.hidden) input[type="text"]').each(function() {
				usageDetails['advanced_usage_type'] = 'actual';
				var name = $(this).attr('name');
				var value = $(this).val();
				usageDetails[name] = value;
			});
		}

		// If the user selects "Enter Spend", capture the spend input value
		if ($('.spend-energy-usage-wrapper').is(':visible')) {
			var spendValue = $('input[name="current_energy_spend"]').val(); // Get the spend value
			if (spendValue) {
				usageDetails['current_energy_spend'] = spendValue; // Add spend to usageDetails
			}
		}

		var data = {
			usageDetails: usageDetails,
			quote_id: quote_id
		};

		// Send the data via your existing request function
		makeRequest('/quote/womp/set-current-usage', data, function(response) {
			if (response.success) {
				lead_script.handleStep(next_step);
			} else {
				error_message(response.error);
			}
		});
	});


	$('#lead_script').on('click','.finish-womp-script',function(){
		var contact_id = $(this).data('contact_id');
		showLoader();
		window.location.href = '/contact/'+contact_id;		
	});

	$('#lead_script').on('click', '#send_sms_link', function() {
		var quote_id = $('#lead_script').data('quoteid');
		var data = {
			quote_id: quote_id
		};

		// Change button text immediately
		$('#send_sms_link').text('Resend SMS');
		
		// Disable the button and show the countdown
		$('#send_sms_link').prop('disabled', true);
		$('#countdown').removeClass('hide').text(60);

		// Countdown function
		var countdown = 60;
		var countdownInterval = setInterval(function() {
			countdown--;
			$('#countdown').text(countdown);
			if (countdown <= 0) {
				clearInterval(countdownInterval);
				$('#send_sms_link').prop('disabled', false);
				$('#countdown').addClass('hide');
			}
		}, 1000);

		// Make the request to send SMS
		makeRequest('/quote/womp/send-invite-sms', data, function(response) {
			if (response.success) {
				$('.sms-link-sent-text').removeClass('hide');
				success_message('sms sent');
			} else {
				error_message(response.error);
			}
		});
	});

	$('#lead_script').on('click', '#send_email_link', function() {
		var quote_id = $('#lead_script').data('quoteid');
		var data = {
			quote_id: quote_id
		};

		// Change button text immediately
		$('#send_email_link').text('Resend Email');
		
		// Disable the button and show the countdown
		$('#send_email_link').prop('disabled', true);
		$('#email_countdown').removeClass('hide').text(60);

		// Countdown function
		var countdown = 60;
		var countdownInterval = setInterval(function() {
			countdown--;
			$('#email-countdown').text(countdown);
			if (countdown <= 0) {
				clearInterval(countdownInterval);
				$('#send_email_link').prop('disabled', false);
				$('#email_countdown').addClass('hide');
			}
		}, 1000);

		// Make the request to send SMS
		makeRequest('/quote/womp/send-invite-email', data, function(response) {
			if (response.success) {
				$('.email-link-sent-text').removeClass('hide');
				success_message('email sent');
			} else {
				error_message(response.error);
			}
		});
	});
	
	


	$('#lead_script').on('click', '#accept_estimated_details', function(){


		var assumed_data = {};
		$.each($('.address-estimation-row'), function(){
			if($(this).data('profile-key') && $(this).data('profile-value')){
				assumed_data[$(this).data('profile-key')] = $(this).data('profile-value');
			}
		});
		makeRequest('/quotes/set_assumed_profile/' + quote_id, assumed_data, function(response){
			if(isset(response.success) && response.success){
				if($('#currentUsageContinue').data('success-step')){
					window.lead_script.handleStep($('#currentUsageContinue').data('success-step'));
				}
				else{
					window.lead_script.handleStep(window.lead_script.magicPlease('set_tariff_preferences'));
				}
			}
			else{
				var error = 'oops, failed to set profile';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});

	});

	// click continue on section 1
	$('#lead_script').on('click','#compareTypeContinue',function(){
		// first hide the current section.
		view.updateCompareType();
		var compare_type = $("input[name=compare-type]:checked").val();
		var supply_type = $("input[name=supply-type]:checked").val();
		api.updateOrder(quote_id,{compare_type:compare_type,supply_type:supply_type},function(response){
			// make sure we update any content as required 
		});
		$('.select2.tariff-selector').select2({
			'templateResult' : style_expired_tariffs
		});
	});
	$('#lead_script').on('click','#compareType h3',function(){
		var comparetype = $("input[name=compare-type]:checked").val();
		$('#compareType h3').siblings().show('slow');
		if(comparetype != 'dual') {
			$('#mutliSupplier').hide();
		}
		$('#currentSupplier-electricity h3').siblings().show();
		$('#compareType').siblings().hide();
	});
	
	$('#lead_script').on('click', "input[name=compare-type]:radio", function () {
//		if (this.value == 'dual') {
//			$('#mutliSupplier').show();
//		} else {
//			$('#mutliSupplier').hide();
//		}
		
		//view.updateCompareType();
		var compare_type = $("input[name=compare-type]:checked").val();
		var supply_type = $("input[name=supply-type]:checked").val();
		view.updateUsageInputs(this.value);
		api.updateOrder(quote_id,{compare_type:compare_type,supply_type:supply_type},function(response){
			// make sure we update any content as required 
		});
		var to_show =[];
		if(compare_type == 'dual' || compare_type == 'electricity'){
			to_show.push('electricity')
		}
		if(compare_type == 'dual' || compare_type == 'gas'){
			to_show.push('gas')
		}
		if(compare_type == 'dual'){
			to_show.push('dual')
		}

		$('.show-electricity').hide();
		$('.show-gas').hide();
		$('.show-dual').hide();

		$.each(to_show, function(i, fuel){
			$('.show-' + fuel).show();
		})

	});
	$('#lead_script').on('click', "input[name=supply-type]:radio", function () {
		//view.updateCompareType();
		var compare_type = $("input[name=compare-type]:checked").val();
		var supply_type = $("input[name=supply-type]:checked").val();
		api.updateOrder(quote_id,{compare_type:compare_type,supply_type:supply_type},function(response){
			// make sure we update any content as required 
		});
	});
	
	$('#lead_script').on('click', 'input.supply-smart-meter:radio', function(){
		if($(this).val() == 'yes'){
			$(this).closest('.formInputs').parent().find('.smart_meter_warning').show();
		}
		else{
			$(this).closest('.formInputs').parent().find('.smart_meter_warning').hide();
		}
		var fuel = $(this).data('fuel');
		
		api.optionChanged(quote_id,fuel+':smart_meter',this.value,function(response){});
		
	})
	
	
	
	
	$('#lead_script').on('click',"input[name ^='currentSupplierContinue-']",function(){
		var supply_type = this.name.replace("currentSupplierContinue-","");
		validator.updateCurrentSupplyDetails(quote_id, supply_type, function(response){
			if(isset(response.success) && response.success){
				view.updateCurrentSupplier(supply_type,function(){});
			}
		})
	});

	$('#lead_script').on('click', "[id^='currentSupplier-'] h3",function(){
		var parent = $(this).parent();
		var sectionType = parent.attr('id').replace("currentSupplier-","");
		$('#smartMeterSection').hide();
		if(sectionType == 'gas'){
			$('#currentSupplier-electricity').hide();
			$('#currentSupplier-electricity h3').siblings().show();
		}
		$(this).siblings().show('slow');
	});

	window.style_expired_tariffs = function(tariff_option){

		var result = $('<span>' + tariff_option.text + '</span>');
		var element = $(tariff_option.element);
		if($(element).data('expired')){
			$(result).addClass('expired-tariff-option');
			$(result).text($(result).text() + ' -  expired on ' + $(element).data('expired'));
		}
		return result;
	}

	
	// big 6 changed, post the selection to the server
	$('#lead_script').on('click',"input[name $='supplier-big6']:radio",function(){
		var select_source = this.name.replace("-supplier-big6","");
		var selected_value = this.value;
		api.updateCurrentSupplier(quote_id,{'supplier_id': this.value},select_source,function(response){
			$("#"+select_source+"-supplierSelect option[value='"+selected_value+"']").prop('selected',true);
			$('#currentTariffs-'+select_source).html(response.html.tariffs);
			$('.select2.tariff-selector').select2({
				'templateResult' : style_expired_tariffs
			});
			$('#currentPayment-'+select_source).html(response.html.payments);
			view.updateSupplySummary(select_source);
		});
	});

	// supplier selected from drop down, post selection to server
	$('#lead_script').on('change',"select[name $='supplier-list']",function(){
		var select_source = this.name.replace("-supplier-list","");
		var selected_value = this.value;
		api.updateCurrentSupplier(quote_id,{'supplier_id': this.value},select_source,function(response){
			$("input[name = "+select_source+"-supplier-big6]:checked").prop('checked',false);
			if($('#'+select_source+'-supplier-'+selected_value) != 'undefined'){
				$('#'+select_source+'-supplier-'+selected_value).prop('checked',true);
			}
			$('#currentTariffs-'+select_source).html(response.html.tariffs);
			$('.select2.tariff-selector').select2({
				'templateResult' : style_expired_tariffs
			});
			$('#currentPayment-'+select_source).html(response.html.payments);
			view.updateSupplySummary(select_source);
		});
	});
        
        // cover arranged selected from drop down, post selection to server
	$('#lead_script').on('change',"select[name $='boilercover-list']",function(){
		var selected_value = null;
		var gas_selected_value = null;
		var electricity_selected_value = null;
		var selectedCompareType = $("input[name=compare-type]:checked").val();
		//console.log(selectedCompareType);
		var selectedSource = this.name.replace("-boilercover-list","");
		//console.log(selectedSource);
		var counter;
		if (selectedCompareType === 'dual') {
			
			//easy decision so we can commit the answer
			if (selectedSource === 'dual') {
				selected_value = document.getElementById('dual-boilercoverSelect').value;
			} 
			else {
				//dual single comparison (different suppliers for each supply type)
				gas_selected_value = document.getElementById('gas-boilercoverSelect').value;
				electricity_selected_value = document.getElementById('electricity-boilercoverSelect').value;
				if (gas_selected_value !== 'No' || electricity_selected_value !== 'No') {
					//somewhat arbitarily pick one as we're only storing one profile key per contact
					if (gas_selected_value !== 'No') {
						selected_value = gas_selected_value;
					} 
					else {
						selected_value = electricity_selected_value;
					}
				}
			}
		} 
		else {
			
		    //single comparison
			if (selectedCompareType === 'electricity') {
				selected_value = document.getElementById('electricity-boilercoverSelect').value;  
			}
			if (selectedCompareType === 'gas') {
				selected_value = document.getElementById('gas-boilercoverSelect').value;
			}	
		}
        
		//we've made a decision commit it
		if (selected_value !== null) {
			//var selected_value = this.value;
			//console.log("Hello, you're changing your cover to "+selected_value);
			api.updateBoilerCover(quote_id,selected_value,false,function(response) { 
				//console.log("Updated boiler cover status.");
			});
		}
	});

	// a tariff selected from drop down, post selection to server
	$('#lead_script').on('change',"select[name $='tariff-list']",function(){
		var select_source = this.name.replace("-tariff-list","");
		var selected_value = this.value;
		api.updateCurrentTariff(quote_id,{'tariff_id': this.value},select_source,function(response){
			$('#currentPayment-'+select_source).html(response.html);
			view.updateSupplySummary(select_source);
		});
	});

	// a payment selected from drop down, post selection to server
	$('#lead_script').on('change',"select[name $='payment-option']",function(){
		var smart_prepay_meter = ($('select[id="dual-payment-option"] option:selected').html().trim() == 'Smart Prepay') ? 'true' : 'false';
		var select_source = this.name.replace("-payment-option","");
		var current_supplier = $("select[name='"+select_source+"-supplier-list']").find(":selected").val();
		var data = {
			'payment_id': this.value,
			'supplier_id':current_supplier,
			'smart_prepay_meter': smart_prepay_meter
		};
		api.updateCurrentPaymentOption(quote_id, data ,select_source,function(response){
			$('#currentTariffs-'+select_source).html(response.html.tariffs);
			view.updateSupplySummary(select_source);
			$('.select2.tariff-selector').select2({
				'templateResult' : style_expired_tariffs
			});
		});
	});
	// eco 7 option changed
	$('#lead_script').on('click',"input[name $='eco7']:radio",function(){
		if(this.value == 'no'){
			$('.eco7').hide();
			$('#electricity-usage-standard').show();
		}
		else{
			$('.eco7').show();
			$('#electricity-usage-standard').hide();
		}
		api.optionChanged(quote_id,'electricity:eco7',this.value,function(response){
				// make sure we update any content as required 
				//console.log(response);
		});
	});
	
	
		//////////////// CALCULATOR JS /////////////////
	

	$('#lead_script').on('click',"input[name=do-you-know]:radio",function(){
		if(this.value == "no"){
			$('#usageActual').hide('slow');
			$('#usageEstimatedSimple').hide('slow');
			$('#usageEstimated').show('slow');
			$('#usageEstimatedNotSoSimple').hide('slow');
		}
		else if(this.value == 'really_no'){
			$('#usageActual').hide('slow');
			$('#usageEstimatedSimple').show('slow');
			$('#usageEstimated').hide('slow');
			$('#usageEstimatedNotSoSimple').hide('slow');
		}
		else if(this.value == 'really_no_but_maybe_yes') {
			$('#usageActual').hide('slow');
			$('#usageEstimated').hide('slow');
			$('#usageEstimatedSimple').hide('slow');
			$('#usageEstimatedNotSoSimple').show('slow');
		}
		else{
			$('#usageActual').show('slow');
			$('#usageEstimated').hide('slow');
			$('#usageEstimatedSimple').hide('slow');
			$('#usageEstimatedNotSoSimple').hide('slow');
		}
		//console.log(this.value);
	});
	$('#lead_script').on('click','input[name=simple-vs-detail]:radio',function(){
		if(this.value == 'simple'){
			$('#usage-detailed').hide('slow');
			$('#usage-simple').show('slow');
			// show the simple estimator
		}
		else{

			$('#usage-detailed').show('slow');
			$('#usage-simple').hide('slow');
			// show the detailed one
		}
	});



	$('#lead_script').on('click','input[class $="-i-use"]:radio',function(){
		$(this).each(function(){
			var type = $(this).attr('class').replace('-i-use','');
			$('.spend-'+type).prop("disabled","disabled");
			$('.usage-'+type).prop("disabled",false);
		});
	});

	$('#lead_script').on('click','input[class $="-i-spend"]:radio',function(){
		$(this).each(function(){
			var type = $(this).attr('class').replace('-i-spend','');
			$('.spend-'+type).prop("disabled",false);
			$('.usage-'+type).prop("disabled","disabled");
		});

	});

	$('#lead_script').on('click','#currentUsageContinue',function(){
		var compare_type = $(this).data('compare-type');
		validator.validateUsage(compare_type);
	});
	
	
	$('#lead_script').on('change','#PriorityRegister',function(){
		var selection = $(this).val();
		api.setPriorityRegister(quote_id,selection,function(response){
			console.log(selection);
		});
	});
	
	$('#lead_script').on('click','.smart-meter-choice',function(){
		window.lead_script.handleStep($(this).data('success-step'));
		return;
		var selection = $(this).html();
		var that = $(this);
		api.setSmartMeter(quote_id,selection,function(response){
			if(response.success === true){
				window.lead_script.handleStep($(that).data('success-step'));
			}
		});
	});
	
	$('#lead_script').on('click','#smart_meter_continue',function(){
		var total_radios = $('input[name$="smart"]:visible').length;
		var selected_radios = $('input[name$="smart"]:visible:checked').length;
		if(selected_radios < total_radios/2){
			error_message('Please select an option for each smart meter question');
		}
		else{
			window.lead_script.handleStep($(this).data('success-step'));
		}
	});

	$('#lead_script').on('click','#confirm-switch',function(){
		$("#confirm-switch").hide();
		var data = {};
		data.payment_card = $('#confirm_payment_card').is(':checked');
		data.direct_debit = $('#confirm_direct_debit_mandate').is(':checked');
		data.paper_billing = $('#confirm_paper_billing').is(':checked');
		
		api.confirmSwitch(quote_id, data, function(response){
			if(response.success === true){
				if(response.unlimited){
					window.lead_script.handleStep(window.lead_script.magicPlease('switch_confirmed'));
				}
				else{
					window.lead_script.handleStep(window.lead_script.magicPlease('switch_confirmed'));
				}
			}
			else{
				location.reload();
			}

		});
	});
	
	$('#lead_script').on('click','input[name$="usage-info"]',function(){
		var checked = $('input[name$="usage-info"][value="spend"]:checked').length;
		if(checked){
			$('span.show-estimated-spend').show();
		}
		else{
			$('span.show-estimated-spend').hide();
		}
	});
	
	
	
});
