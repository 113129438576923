class MessageBox {
    constructor(template, title, root = 'main_modal') {
        this.root = {
            box: $('#' + root),
            title: $('#' + root + '_title'),
            body: $('#' + root + '_body'),
            form: $('#' + root + '_form')
        };
        
        this.title = title;
        const source = $('#' + template + '-template').html();
        this.template = Handlebars.compile(source);
        this.permissionBox = false;
    }
    
    setData(data) {
        this.data = data;
    }
    
    run() {
        this.permissionBox = false;
        const before = this.data.before || function(cb) { cb(); };
        before(this.showBox.bind(this));
    }
    
    showBox(data = {}) {
        const that = this;
        const templateData = $.extend({}, this.data.template || {}, data);
        this.root.title.text(this.title);
        this.root.body.html(this.template(templateData));
        this.root.form.off();
        this.root.form.on('submit', function(e) {
            e.preventDefault();
            const form = $(this).serializeArray();
            if(typeof that.data.extra === 'function') {
                that.data.extra(form);
            }
            
            that.formSubmit.bind(that)(form);
        });

        this.show();
        if(typeof this.data.after === 'function') {
            this.data.after();
        }
    }
    
    formSubmit(form) {
        if (this.data.ajax.data) {
            var postData = this.data.ajax.data;
            for (var key in postData) {
                if (postData.hasOwnProperty(key)) {
                    var newobj = {name: key, value: postData[key]};        
                    //console.log(key + " -> " + postData[key]);
                    form.push(newobj);
                  
                }
            }
		} 
		if(isset(this.data.prerequest) && typeof(this.data.prerequest) == 'function'){
			this.data.prerequest();
		}
        const settings = $.extend({ method: 'post', dataType: 'json' }, this.data.ajax, { data: form });
        const request = $.ajax(settings);
        request.done(this.requestSuccess.bind(this));
        request.fail(this.requestFailure.bind(this));
    }
    
    requestSuccess(ret) {
        if(typeof this.data.success === 'function') {
            this.data.success(ret);
        }

        this.hide();
    }
    
    requestFailure(jqXHR) {
        if(typeof jqXHR.responseJSON === 'object') {
            const json = jqXHR.responseJSON;
            const rand = Math.round(Math.random() * 1e8);
            const text = typeof json.error === 'undefined' ? this.formatValidationResponse(json) : json.error;
            this.root.body.prepend('<p class="bg-danger" id="error' + rand + '">' + text + '</p>');
            
            if(jqXHR.status === 403) {
                setTimeout(function() {
                    $("#error" + rand).fadeOut(500, function() { $(this).remove(); });
                }, 2500);
            }
            
            if(this.permissionBox === false && json.html) {
                this.root.body.append(json.html);
                this.permissionBox = true;
            }
        }
    }
    
    formatValidationResponse(data) {
        let ret = '';
        for(let k in data) {
            ret += data[k][0];
        }
        
        return ret;
    }
    
    show() {
        this.root.box.modal('show');
    }
    
    hide() {
        this.root.box.modal('hide');
    }
}

module.exports = MessageBox;
