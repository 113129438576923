$(function() {
    $('.sales-order-agreed-policies-list-item').on('click', '.view-trigger', function () {
        let policy_id = $(this).data('policy-id');
        makeRequest('/get-order-policy/'+policy_id, {},function (response) {
            if(isset(response.success) && response.success && isset(response.policy.content)) {
                $('#policies_body').html(response.policy.content);
                $('#policies_modal').modal();
            } 
			else {
                error_message();
            }
        }, 'GET');
    });

    $('.sales-order-agreed-policies-list-item').on('click', '.contact-page-sales-order-optin-trigger', function () {
        let policy_id = $(this).data('policy-id'),
            data = {
                sales_order_id: $(this).data('sales-order-id'),
                policy_id: policy_id,
                state: $(this).find('.switch-input-checkbox-select').is(':checked')
            }

        $(this).find('.switch-input-checkbox-select').is(':checked') == true
            ? $(this).find('.switch-input-checkbox-select').attr('checked', 'checked')
            : $(this).find('.switch-input-checkbox-select').removeAttr('checked');

        makeRequest('/order/toggle-opt-in', data, function (response) {
            if(isset(response.success) && response.success) {
                success_message();
            } 
			else {
                error_message();
            }
        }, 'POST');
    });

    if($('#direct-debit-payment-history').length) {
        $('#direct-debit-payment-history').DataTable();
    }

    $('body').on('click', '.view-event-details', function () {
        var dom = $(this).closest('tr');
        var data = {
			'context':$(this).data('context'),
			'entity_id':$(this).data('entity_id'),
			'event_name':$(this).data('event_name'),
			'event_link_id':$(this).data('event_link_id')
		};
        if (!$(dom).next().hasClass('temp-data')) {
            getEventDetails(data, dom);
        } 
		else {
            $(dom).next().remove();
        }
    });

	var getEventDetails = function (data, dom) {
        $.ajax({
            cache: false,
            url: '/get/contact/event/details',
            type:"POST",
            data : data,
            dataType: 'json',
            success: function (response) {
                if(response.errors){
                    console.log(response.errors)
                }else{
                    var html = '<tr class="temp-data"></tr>';
                    $(dom).after(html);
                    if ($(dom).next().hasClass('temp-data')) {
                        $(dom).next().html('<p class="pad-me-please-left-7">' + response.details + '</p>');
                    }
                }
            },
            error: function (error) {
                console.log('oh no');
            }
        });
    };

    $('body').on('click', '.view-dialler-instance-events', function () {
        var dom = $(this).closest('tr');
        var data = {
            'context':$(this).data('context'),
            'entity_id':$(this).data('entity_id'),
			'dialler_instance_id':$(this).data('dialler_instance_id'),
		};
        if (!$(dom).next().hasClass('temp-data')) {
            getDiallerInstanceEvents(data, dom);
        } 
		else {
            $(dom).next().remove();
        }
    });

    var getDiallerInstanceEvents = function (data, dom) {
        $.ajax({
            cache: false,
            url: '/get/contact/dialler-instance/events',
            type:"POST",
            data : data,
            dataType: 'json',
            success: function (response) {
                if(response.errors){
                    console.log(response.errors)
                }else{
                    var html = '<tr class="temp-data"></tr>';
                    $(dom).after(html);
                    if ($(dom).next().hasClass('temp-data')) {
                        $(dom).next().html(response.details);
                    }
                }
            },
            error: function (error) {
                console.log('oh no');
            }
        });
    };

	window.success_message = function(text){
		if(!text){
			text = 'Updated';
		}
		$('.update-success').fadeIn().text(text);
		setTimeout(function(){
			$('.update-success').fadeOut();
		},5000);
	};
	
	var error_message = function(text){
		$('.update-error').fadeIn().text(text);
		setTimeout(function(){
			$('.update-error').fadeOut();
		},5000);
	};
	
	var checkErrors = function(response){
		if(response.success != true){
			if(response.error){
				if(response.error.length > 1){
					error_message(response.error);
					return false;
				}
			}
			error_message('an unknown error occurred');
			return false;
		}
		return true;
		
	};
	
	var contact_id = $('#contact-details').data('contact-id');
	var event_types = $('.contact-events-sub-table');
	const tables = {};

	$.each(event_types, function(i, selector) {
		var event_type_id = $(selector).data('event-type');
		//load events
		tables[event_type_id] = $('.event-binding-table-'+event_type_id).DataTable({
			ajax: "/get/contact/events/" + contact_id + "/" + event_type_id,
			pageLength: 7,
			ordering: true,
			responsive: true,
			searching: false,
			paging: true,
			lengthChange: false,
			autoWidth: false,
			bFilter: false,
			order: [[2, "desc"]],
			"columnDefs": [
				{"orderable": false, "targets": [0, 1, 2, 3, 4]}
			],
			initComplete : function(settings, json){
				if(isset(json.data) && json.data.length) {
					var event_count = json.data.length;
					$('.count-' + event_type_id).html(event_count);
				}
			}


			//lengthMenu: false
		});
	});
	
	var updateContactEventTables = function(){
		$.each(tables, function(index, data){
			tables[index].ajax.reload();
		});
    }
    
    //load contact dialler instances
    const diallerInstancesTable = $('#contact-dialler-instances-table').DataTable({
        ajax: "/get/contact/dialler-instances/"+contact_id,
        pageLength: 4,
		ordering: true,
		order: [[ 5, "desc" ]],
		searching: false,
		paging: true,
		lengthChange: false,	
		autoWidth: false,
		bFilter: false,
		"columnDefs": [
			{ "orderable": false, "targets": [0, 1, 2, 3, 4 ] }
		  ]
			//lengthMenu: false
	});

    
    //load notes
    const notestable = $('#contact-notes-table').DataTable({
        ajax: "/get/contact/notes/"+contact_id,
        pageLength: 4,
		ordering: true,
		order: [[ 3, "desc" ]],
		searching: false,
		paging: true,
		lengthChange: false,	
		autoWidth: false,
		bFilter: false,
		"columnDefs": [
			{ "orderable": false, "targets": [0, 1, 2, 3, 4 ] }
		  ]
			//lengthMenu: false
		});
    
	//load leads
    const leadstable = $('#contact-leads-table').DataTable({
        ajax: "/get/contact/leads/"+contact_id,
        pageLength: 4,
        ordering: false,
		responsive: true,
		searching: false,
		paging: true,
		lengthChange: false,	
		autoWidth: false,
		bFilter: false
    });
	
	//load correspondence
    const correspondencetable = $('#contact-correspondence-table').DataTable({
        ajax: "/get/contact/correspondence/"+contact_id,
        pageLength: 4,
        ordering: false,
		responsive: true,
		searching: false,
		paging: true,
		lengthChange: false,	
		autoWidth: false,
		bFilter: false
    });
    
    //toggle active an info page
	var toggleOptin = function(contact_id,action_id,state,callback) {
	    let data = {
            action_id:action_id,
            state:state?'ON':'OFF'
        }
	    makeRequest('/contacts/'+contact_id+'/toggle-optin', data, function (response) {
            if(isset(response.success) && response.success) {
                callback();
            } 
			else {
                error_message();
            }
        }, 'POST');
    };
    
    //toggling a marketing opt in (on/off)
	$('#contact-policies-edit').on('click', '.contact-page-optin-trigger input', function() {	
        var marketing_action_id = $(this).parent().parent().attr('id').replace('contact-marketing-optins-','');
        var is_checked = $(this).is(':checked');
        var contact_id = $('#contact-details').data('contact-id');
        //var target = $(this).parent();
        toggleOptin(contact_id,marketing_action_id,is_checked,function(){
            $('.update-error').hide();
            $('.update-success').fadeIn().text('Marketing Permissions Updated');
            setTimeout(function(){
                $('.update-success').fadeOut();
            },5000);
        });
	});
    
    $('.contact-agreed-policies-list-item').on('click', '.view-trigger', function() {
        var policy_id = $(this).data('policy_id');
        //alert(policy_id);
        // make an ajax request from here to /get-policy/{policy_id}
        // if you get success back, insert the content into your model
        // show model

        makeRequest('/get-policy/'+policy_id, {}, function (response) {
             if(isset(response.success) && response.success && isset(response.policy.content)) {
                 $('#policies_body').html(response.policy.content);
                 $('#policies_modal').modal();
             } 
			 else {
                 error_message();
             }
        }, 'GET');
    });
    
    //delete event
    $('#contact-events-table, #contact-notes-table').on('click','.delete-event',function(e) {
        
        //console.log("Ok, delete button pressed, going to send...");
        var data = {
			'context':$(this).data('context'),
			'entity_id':$(this).data('entity_id'),
			'event_name':$(this).data('event_name'),
			'event_link_id':$(this).data('event_link_id')
		};
        
        //console.log("Event entity link is " + event_entity_link_id);
        var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var message = 'Delete selected event for '+first_name+' '+last_name+'?  (Contact #'+data.entity_id+')';
        e.preventDefault();
        var message = new MessageBox('deleteevent', message);
        message.setData({
            ajax: { 
                cache: false,
                url: '/event/destroy',
				type : 'POST',
                dataType: 'json',
                data: data,
                success: function(response) {
                    $('#event-message').html("Event successfully deleted");
                    $.each(tables, function(index, data){
                        tables[index].ajax.reload();
                    });
                    notestable.ajax.reload();
                    var eventsCount = parseInt($('#events-count').html()) - 1;
                    $('#events-count').html(eventsCount);
                },
                error: function (error) {
                    //console.log(error);
                }
            }
            });
        message.run();
        });	
	
	//withdraw event
    $('#contact-events-table, #contact-notes-table').on('click','.withdraw-event',function(e) {
        var data = {
			'context':$(this).data('context'),
			'entity_id':$(this).data('entity_id'),
			'event_name':$(this).data('event_name'),
			'event_link_id':$(this).data('event_link_id')
		};

		customPrompt('Please provide a reason why you are withdrawing this event', 'Reason', function(reason){
			if(!reason.length){
				error_message('please provide a reason');
				return false;
			}
			data.reason = reason;
			showLoader();
			makeRequest('/event/withdraw', data, function(response){
				if(isset(response.success) && response.success){
					success_message('withdrawn');
				}
				else{
					var error = 'unable to withdraw'
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
				hideLoader();
			})
		})
	});


    //delete note
    $('#contact-notes-table').on('click','.delete-note',function(e) {
        e.preventDefault();
        
        //console.log("Ok, delete button pressed on note, going to send...");
        var contact_id = $('#contact-details').data('contact-id');
        var event_entity_link_id = $(this).data('event-id');
        //console.log("Event entity link is " + event_entity_link_id);
        var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var message = 'Delete selected note for '+first_name+' '+last_name+'? (Contact #'+contact_id+')';

        var message = new MessageBox('deletenote', message);
        message.setData({
            ajax: { 
                cache: false,
                url: '/event/'+event_entity_link_id,
                type: 'DELETE',
                dataType: 'json',
                success: function(response) {
                    $('#event-message').html("Note successfully deleted");
                                        $.each(tables, function(index, data){
                        tables[index].ajax.reload();
                    });
                    notestable.ajax.reload();
                    var noteCount = parseInt($('#notes-count').html()) - 1;
                    $('#notes-count').html(noteCount);
                },
                error: function (error) {
                   // console.log(error);
                }
            }
            });
        message.run();
    });
    
    $('.forum-header-buttons').on('click','#create-contact-note',function(e) {
        e.preventDefault();
		e.stopPropagation();
        //console.log("Ok, add button pressed, going to send...");
        var contact_id = $('#contact-details').data('contact-id');
        var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var message = 'Add new note for '+first_name+' '+last_name+'?  (Contact #'+contact_id+')';

        var message = new MessageBox('addnote', message);
        
        message.setData({
            ajax: { 
                cache: false,
                url: '/event', 
                type:"POST",
		dataType: 'json',
                data: { 
                        link_id: contact_id,
                        event_name : 'new_contact_note_event', 
                        event_type_name : 'contact',
                },
                success: function(response) {
//                    $('#event-message').html("Event successfully Added");
                                        $.each(tables, function(index, data){
                        tables[index].ajax.reload();
                    });
                    notestable.ajax.reload();
                    var noteCount = parseInt($('#notes-count').html()) + 1;
                    $('#notes-count').html(noteCount);
                },
                error: function (error) {
                    //console.log(error);
                }
            }
        });
        message.run();
    });
    
    $('#newnote').keyup(function() {
        //console.log('here');
        var characterCount = $(this).val().length;
        var current = $('#currentcount');
        
        current.text(characterCount);
 
        if (characterCount < 100) {
          current.css('color', '#00ff');
        }
 
        if (characterCount > 250) {
          current.css('color', '#ff');
        }
    });
    
    $('.forum-header-buttons').on('click','#create-event',function(e) {
		e.preventDefault();
		e.stopPropagation();
       // console.log("Ok, add button pressed, popping preferred event list not sausage buttons...");
        var contact_id = $('#contact-details').data('contact-id');
        var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var message = 'Add new manual event for '+first_name+' '+last_name+'?  (Contact #'+contact_id+')';

        var message = new MessageBox('addevent', message);
        var event_name = $('#manualEventSelection').value;
        
        message.setData({
            ajax: { 
                //cache: false,
                url: '/event', 
                type: 'POST',
                dataType: 'json',
                data: { 
                    link_id: contact_id,
                    event_type_name : 'contact'
                },
                success: function(response) {
					if(isset(response.success) && response.success){
						var callback = function(){
							$.each(tables, function(index, data){
								tables[index].ajax.reload();
							});
							var eventsCount = parseInt($('#events-count').html()) + 1;
							$('#events-count').html(eventsCount);
						}
						if(isset(response.html) && isset(response.target)){
							createModal(response.target, response.html);
							$('#' + response.target).find('.select2').select2({'width' : '100%'});
							$('#' + response.target).find('#submit_attributes').on('click', function(){
								callback();
							});
						}
						else{
							callback();
						}
					}
					else{
						var error = isset(response.error) ? response.error : 'unable to create event';
						error_message(error);
					}
                },
                error: function (error) {
                   // console.log(error);
                }
			}
        });
        message.run();
    });
    
    $('.forum-header-buttons').on('click','#compliance-event',function(e) {
        e.preventDefault();
        //console.log("Ok, add button pressed, popping preferred event list not sausage buttons...");
        var contact_id = $('#contact-details').data('contact-id');
        var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var message = 'Add new compliance event for '+first_name+' '+last_name+'?  (Contact #'+contact_id+')';
        var message = new MessageBox('addcompevent', message);
        //var event_name = $('#complianceEventSelection').value;

        message.setData({
            ajax: {
                //cache: false,
                url: '/event',
                type: 'POST',
                dataType: 'json',
                data: {
                    link_id: contact_id,
                    event_type_name : 'contact'
                },
                success: function(response) {
                   // console.log("Event successfully Added");
                                        $.each(tables, function(index, data){
                        tables[index].ajax.reload();
                    });
                    var eventsCount = parseInt($('#events-count').html()) + 1;
                    $('#events-count').html(eventsCount);
                },
                error: function (error) {
                   // console.log(error);
                }
            }
        });
        message.run();
    });

//delete quote
$('.delete-quote').click(function(e) {
    e.preventDefault();
    //console.log("Ok, quote delete button pressed, going to send...");
    var contact_id = $('#contact-details').data('contact-id');
    var order_id = $(this).data('order-id');
    var first_name = $('#contact-details-edit input[name="first_name"]').val();
    var last_name = $('#contact-details-edit input[name="last_name"]').val();
    var message = 'Delete selected quote # '+order_id+' for '+first_name+' '+last_name+'?  (Contact #'+contact_id+')';
    var messagebox = new MessageBox('deletequote', message);
    messagebox.setData({
        ajax: { 
            cache: false,
            url: '/quotes/sdelete',
            type: 'POST',
            dataType: 'json',
            data: {quoteID : order_id},
            success: function(response) {
                //@TODO do this nicer when I have 5 mins 
                location.reload(); 
            },
            error: function (error) {
                //console.log(error);
            }
        }
        });
    messagebox.run();
});	

$('.coaching-quote').click(function(e) {
    e.preventDefault();
	
	//grab quoteID from the order list
	var quote_id = $(this).data('order-id');
	
	//patch the correct quote into the script header (memory and DOM)
    $('#coaching_script').data('quoteid',quote_id);
	$('#coaching_script').attr('data-quoteid',quote_id);
	
    setTimeout(function() {
		//initialise the script flow for coaching
		window.coaching_script = new ScriptFlow($("#coaching_script"));
	
		//show the populated modal
		$('#script_modal').modal();
	}, 500);
	
});	



//switch agent assigned to a sale
$('select#switch_agent').on('change', function(e) {
    e.preventDefault();
    //console.log("Ok, agent change made, going to send...");
    var contact_id = $('#contact-details').data('contact-id');
    var choice = $(this).val();
    //console.log(choice);
    var choices = choice.split(":");
    var order_id = choices[0];
    var agent_id = choices[1];
    var old_agent = $('#old-agent-'+order_id).val();
    var new_agent =  $(this).find(":selected").text();
    var new_agent_split = new_agent.split(" ");
    new_agent = new_agent_split[1].trim()+' '+new_agent_split[0].trim();
    var first_name = $('#contact-details-edit input[name="first_name"]').val();
    var last_name = $('#contact-details-edit input[name="last_name"]').val();
    //console.log('old: '+old_agent+' new:'+new_agent);
    var new_agentID = agent_id;
    var message = 'Changing Sales Order #'+order_id+' for '+first_name+' '+last_name+' from '+old_agent+' to '+new_agent;
    var messagebox = new MessageBox('reassignsale', message);
    messagebox.setData({
        ajax: { 
            cache: false,
            url: '/quotes/updateAgent',
            type: 'POST',
            dataType: 'json',
            data: { orderID : order_id,
                    newUserID: agent_id
                  },
            success: function(response) {
                //@TODO do this nicer when I have 5 mins 
                location.reload(); 
            },
            error: function (error) {
                //console.log(error);
                location.reload(); 
            }
        }
        });
   
    messagebox.run();
});	

//switch status assigned to a sale
$('select#switch_status').on('change', function(e) {
    e.preventDefault();
    //console.log("Ok, sales status change made, going to send...");
    var contact_id = $('#status-details').data('status-id');
    var choice = $(this).val();
    var choices = choice.split(":");
    var order_id = choices[0];
    var new_status_id = choices[1];
    var old_status = $('#old-status-' + order_id).val();
    var new_status = $(this).find(":selected").text();
    var first_name = $('#contact-details-edit input[name="first_name"]').val();
    var last_name = $('#contact-details-edit input[name="last_name"]').val();
    var message = 'Changing Sales order #' + order_id + ' for ' + first_name + ' ' + last_name + ' from "' + old_status + '" to "' + new_status + '"';
    var messagebox = new MessageBox('changestatus', message);
    messagebox.setData({
        ajax: {
            cache: false,
            url: '/quotes/updateStatus',
            type: 'POST',
            dataType: 'json',
            data: {
                orderID: order_id,
                newStatusID: new_status_id
            },
            success: function (response) {
				if(isset(response.success) && response.success){
					success_message('status updated');
                	location.reload();
				}
				else{
					if(isset(response.error)){
						error_message(response.error);
					}
					else {
						error_message('status was not updated');
					}
				}
                //@TODO do this nicer when I have 5 mins
            },
            error: function (error) {
                //console.log(error);
                location.reload();
            }
        }
    });
    messagebox.run();
});	


	if($('input[name="confirm_date"]').length > 0){
		//date picker for changing confirm date on order
	   $('input[name="confirm_date"]').daterangepicker({

		   "locale": {
			   "format": 'DD/MM/YYYY h:mm A',
			   "separator": "-"
		   },
		   "singleDatePicker": true,
		   "timePicker": true,
		   "showDropdowns": true
		   },
		   function(start, end, label) {

			   //console.log("Ok, changing confirm date...");
			   var dp = $(this);
			   var dptag = dp[0].element[0].id;
			   var order_id = dptag.replace('confirm_date-','');
			   //var order = $('input[name="confirm_date"]').attr('id');
			   //var order_parts = order.split("-");
			   //var order_id = order_parts[1];
			   var old_confirm_date = $('#original-confirm-date-'+order_id).val();
			   var first_name = $('#contact-details-edit input[name="first_name"]').val();
			   var last_name = $('#contact-details-edit input[name="last_name"]').val();
			   var new_confirm_date = start.format('DD-MM-YYYY h:mm A');
			   var message = 'Changing Sales order #'+order_id+'\'s confirm date for '+first_name+' '+last_name+' from "'+old_confirm_date+'" to "'+new_confirm_date+'"';
			   var messagebox = new MessageBox('changeconfirmdate', message);
			   messagebox.setData({
				   ajax: {
					   cache: false,
					   url: '/quotes/updateConfirmDate',
					   type: 'POST',
					   dataType: 'json',
					   data: { orderID : order_id,
							   newConfirmDate : new_confirm_date
							 },
					   success: function(response) {
						   //@TODO do this nicer when I have 5 mins
						   location.reload();
					   },
					   error: function (error) {
						   //console.log(error);
						   location.reload();
					   }
				   }
				   });
			   messagebox.run();
		   });
	 }

	$('#lead_script').on('click','#new-contact-create-button',function(e){
		e.preventDefault();
		$('.error-message').html('');
		if(!$('#agreed-to-terms').is(':checked')){
			alert('The customer must acknoledge and agree to our terms of service');
			return;
		}
        var form = $('#new-contact-form').serialize();
		if($('#first_name').val().length < 3){
			$('#firstname-error').html('Please add a valid firstname');
			return;
		}
		if($('#last_name').val().length < 3){
			$('#lastname-error').html('Please add a valid firstname');
			return;
		}
        form += "&current_supplier=";
		$.ajax({ cache: false,
			url: "/contacts/create",
			type:"POST",
			dataType: 'json',
			data: form,
			success: function (response) {
				if(response.errors === true){
					if(response.message.message.match(/phone/)){
						$('#telephone-error').html('Please enter a valid UK phone number');
					}
					if(response.message.message.match(/postcode/)){
						$('#postcode-error').html('Please enter a valid UK postcode');
					}
					//$('#create-contact-errors').html(response.message.message);
				}
				if(response.success === true){
					window.location = '/quote/'+response.quote_id;
				}
				else {
				    if(isset(response.error)) {
				        error_message(response.error);
                    } 
					else {
				        error_message();
                    }
                }
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});
  
    //log complaint    
    $('.complaint-event').on('click', function(e) {
        e.preventDefault();
        //console.log("Ok, log complaint button pressed, going to send...");
        var contact_id = $('#contact-details').data('contact-id');
        var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var lc = $(this);
        var lctag = lc[0].id;
        var order_id = lctag.replace('complaint-event-','');
		
		$('#createTicket #ctm_ticket_title_edit').val('complaint for order ' + order_id);
		
		//set the selected option from the queue modal to customer care
		$('#createTicket #ctm_ticket-queue option').each(function(){
			if($(this).text().indexOf('Customer Care') !== -1){
				$('#createTicket #ctm_ticket-queue').val($(this).val()).trigger('change');
			}
		});
		
		showModal('createTicket');
		
       /* var message = 'Log new complaint for '+first_name+' '+last_name+' (Contact #'+contact_id+') and Sales Order #'+order_id+" ?";
        var message = new MessageBox('addcomplaint', message);
        
        message.setData({
            ajax: { 
                cache: false,
                url: '/event/complaint', 
                type:"POST",
				dataType: 'json',
                data: { 
                        link_id: contact_id,
                        sales_order: order_id,
                        event_name : 'new_salesorder_complaint_event', 
                        event_type_name : 'Contact'
                },
                success: function(response) {
//                    $('#event-message').html("Event successfully Added");
                                        $.each(tables, function(index, data){
                        tables[index].ajax.reload();
                    });
                    var eventCount = parseInt($('#events-count').html()) + 1;
                    $('#events-count').html(eventCount);
                },
                error: function (error) {
                    //console.log(error);
                }
            }
        });
        message.run();*/
    });
	
	$('.compliance-complaint-event').on('click', function(e) {
        e.preventDefault();
        //console.log("Ok, log complaint button pressed, going to send...");
        var contact_id = $('#contact-details').data('contact-id');
        var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var lc = $(this);
        var lctag = lc[0].id;
        var order_id = lctag.replace('complaint-event-','');

        var message = 'Log new complaint for '+first_name+' '+last_name+' (Contact #'+contact_id+') and Sales Order #'+order_id+" ?";
        var message = new MessageBox('addcomplaint', message);
        
        message.setData({
            ajax: { 
                cache: false,
                url: '/event/complaint', 
                type:"POST",
				dataType: 'json',
                data: { 
                        link_id: contact_id,
                        sales_order: order_id,
                        event_name : 'new_salesorder_complaint_event', 
                        event_type_name : 'Contact'
                },
                success: function(response) {
//                    $('#event-message').html("Event successfully Added");
                                        $.each(tables, function(index, data){
                        tables[index].ajax.reload();
                    });
                    var eventCount = parseInt($('#events-count').html()) + 1;
                    $('#events-count').html(eventCount);
                },
                error: function (error) {
                    //console.log(error);
                }
            }
        });
        message.run();
    });

    var showReferralMessagebox = function(contact_id, message, product_type, data = {}, onShow = function(){}, onComplete = function(){}){
        var message = new MessageBox('addreferralevent', message);
        message.setData({
            after: function() {
                makeRequest('/views/modal/'+product_type+'-referal', {'contact_id' : contact_id, 'list_ident':data.list_ident }, function(response) {
                    if(response.error) {
                        error_message(response.error);
                    }
                    if(response.html) {
                        $('#main_modal_body').html(response.html);
                        onShow(response);
                    }
                });
            },
            ajax: {
                //cache: false,
                url: '/event/referral',
                type: 'POST',
                dataType: 'json',
                data: data,
                success: function(response) {
                    onComplete();
                },
                error: function (error) {
                    // console.log(error);
                }
            }
        });
        message.run();
        $('.modal-footer').show();


    };
    
    $('body').on('change',':radio.time_slot_radio',function(e){
	
	$('.modal-footer').show();
	if($(this).is(':checked')){
	    if($(this).val() == 'timed_call_back'){
		$('#c-date-filter').show();
	    }
	    else{
		$('#c-date-filter').hide();
	    }
	} 
    });
    
    $('body').on('change','select#event_name',function(){
	if($(this).is(':checked')){
	    if($(this).val() == 'vmrefer_and_add_to_ultra_for_automatic_callback'){
		$('.time_slot_show').show();
	    }
	    else{
		$('.time_slot_show').hide();
	    }
	} 
    });

    $('body').on('click','.log-coaching',function(e){
        e.preventDefault();
        showModal('createCoaching');
    });

    $('body').on('click','#create_coaching_form',function(){
        var form = $(this).parent().parent().serializeArray();
        makeRequest('/coaching/create',form, function(response) {
            if(response.error) {
                error_message(response.error);
            }
            if(response.success) {
                success_message(response.message);
                hideModal('createCoaching');
            }
        });
    });

    $('body').on('click','.raise-erroneous-transfer',function(e){
        e.preventDefault();
        showModal('raise_erroneous_transfer_modal');
    });

    $('body').on('click','#raise_erroneous_transfer',function(){
        let $comment = $('#raise_erroneous_transfer_modal').find('#erroneous_transfer_comment');
        let comment = $comment.val();
        let contact_id = $('#raise_erroneous_transfer_modal').find('#erroneous_transfer_id').val();

        let data = {
            comment,
            contact_id
        }

        makeRequest('/contact/raise/erroneous_transfer', data, function(response) {
            if(response.success) {
                success_message(response.message);
                hideModal('raise_erroneous_transfer_modal');
                $comment.val('');
            }
            else {create_coaching_form
                let error = 'Unable to raise erroneous transfer';
                if (isset(response.error)) {
                    error = response.error;
                }

                error_message(error);
            }
        });
    });

    $('body').on('click', '#MortgageReferEvent', function(e){
        e.preventDefault();
        var contact_id = $('#contact-details').data('contact-id');
        var basket = $(this).data('add-to-basket') ? true : false;
        var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var product_type = $(this).data('product-type').toLowerCase();
        if(!product_type){
            product_type = 'mortgage';
        }
        var onShow = function(response){

            $('#main_modal_body input[name="callback_preference"]').daterangepicker({
                "singleDatePicker": true,
                "timePicker": true,
                "locale": {
                    "format": 'DD/MM/YYYY HH:mm',
                    "separator": "-"
                },
                "showDropdowns": true
            });
            $('#mortgage_type_section input').on('change', function() {
                $('#mortgage_extra_questions').toggle();
            });
            $('#main_modal_body .select2').select2({
                width: '50%',
                dropdownParent: $('#main_modal_body')
                //containerCssClass : 'col-xs-6'
            });
        }
        var onComplete = function(){
            // console.log("Event successfully Added");
                                $.each(tables, function(index, data){
                        tables[index].ajax.reload();
                    });
            var eventsCount = parseInt($('#events-count').html()) + 1;
            $('#events-count').html(eventsCount);
        }

        var message = 'Add new Mortgage referral event for ' + first_name + ' ' + last_name + '?  (Contact #' + contact_id + ')';

        var post_data = {
            'link_id': contact_id,
            'event_type_name' : 'contact',
            'product_type' : product_type,
            'add_to_basket' : basket
        };

        if(basket){
            post_data.quote_id = lead_script.quoteId;
        }

        showReferralMessagebox(contact_id, message, product_type, post_data,  onShow, onComplete);
    });


    $('body').on('click', '#WillReferEvent', function(e){
        e.preventDefault();
        var contact_id = $('#contact-details').data('contact-id');
        var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var product_type = $(this).data('product-type').toLowerCase();
        if(!product_type){
            product_type = 'will';
        }
		var list_ident = $(this).data('list-ident');
        var onShow = function(response){

			$('#main_modal_body input[name="insurance_time_to_call"]').daterangepicker({
                "singleDatePicker": true,
                "locale": {
                    "format": 'DD/MM/YYYY HH:mm',
                    "separator": "-"
                },
                "showDropdowns": true,
				"timePicker": true,
            });
			$('select[name="callback_preference"]').on('change', function () {
                if($(this).val() == 'custom_time'){
					$('.insurance-call-time-selector').show();
				}
				else{
					$('.insurance-call-time-selector').hide();
				}
            });
            $('#mortgage_type_section input').on('change', function() {
                $('#mortgage_extra_questions').toggle();
            });
            $('#main_modal_body .select2').select2({
                width: '50%',
                dropdownParent: $('#main_modal_body')
                //containerCssClass : 'col-xs-6'Select your Referral event to Homecover.Discount:
            });
        }
        var onComplete = function(){
            // console.log("Event successfully Added");
            updateContactEventTables();

            var eventsCount = parseInt($('#events-count').html()) + 1;
            $('#events-count').html(eventsCount);
        }

        var message = 'Add new Will referral event for ' + first_name + ' ' + last_name + '?  (Contact #' + contact_id + ')';

        var post_data = {
            'link_id': contact_id,
            'event_type_name' : 'contact',
            'product_type' : product_type,
			'list_ident':list_ident
        };

        showReferralMessagebox(contact_id, message, product_type, post_data,  onShow, onComplete);
    });


	$('body').on('click','#HCDReferEvent, #InsuranceReferEvent',function(e) {
        e.preventDefault();
        var contact_id = $('#contact-details').data('contact-id');
        var basket = $(this).data('add-to-basket') ? true : false;
        var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var product_type = $(this).data('product-type').toLowerCase();
        if (!product_type) {
            product_type = 'insurance';
        }
        var onShow = function (response) {
            $('#main_modal_body input[name="insurance_renewal_date"]').daterangepicker({
                "singleDatePicker": true,
                "locale": {
                    "format": 'DD/MM/YYYY',
                    "separator": "-"
                },
                "showDropdowns": true
            });
            $('#main_modal_body input[name="insurance_time_to_call"]').daterangepicker({
                "singleDatePicker": true,
                "locale": {
                    "format": 'DD/MM/YYYY HH:mm',
                    "separator": "-"
                },
                "showDropdowns": true,
				"timePicker": true,
            });
            $('#has_insurance_question input').on('change', function () {
                $('.insurance-extra-questions').toggle();
				if($(this).val() == 'No'){
					$('select[name="callback_preference"] option[value="at_renewal_date"]').attr('disabled', 'disabled');
					$('select[name="callback_preference"]').val('');
					$('.insurance-call-time-selector').hide();
				}
				else{
					$('select[name="callback_preference"] option[value="at_renewal_date"]').removeAttr('disabled');
				}
            });
            $('select[name="callback_preference"]').on('change', function () {
                if($(this).val() == 'custom_time'){
					$('.insurance-call-time-selector').show();
				}
				else{
					$('.insurance-call-time-selector').hide();
				}
            });
        }

        var onComplete = function () {
            // console.log("Event successfully Added");
                                $.each(tables, function(index, data){
                        tables[index].ajax.reload();
                    });
            var eventsCount = parseInt($('#events-count').html()) + 1;
            $('#events-count').html(eventsCount);
        }

        var message = 'Add new Homecover.Discount referral event for ' + first_name + ' ' + last_name + '?  (Contact #' + contact_id + ')';

        var post_data = {
            'link_id': contact_id,
            'event_type_name' : 'contact',
            'product_type' : product_type,
            'add_to_basket' : basket
        };

        if(basket){
            post_data.quote_id = lead_script.quoteId;
        }

        showReferralMessagebox(contact_id, message, product_type, post_data,  onShow, onComplete);
    });
	
	$('body').on('click','#MLReferEvent, #AccidentProtectionReferEvent',function(e) {
        e.preventDefault();
        var basket = $(this).data('add-to-basket') ? true : false;
        var contact_id = $('#contact-details').data('contact-id');
        var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var product_type = $(this).data('product-type').toLowerCase();
        if (!product_type) {
            product_type = 'AccidentProtection';
        }
        var onShow = function(response) {
	    $('.modal-footer').hide();
            $('#main_modal_body input[name="callback_preference"]').daterangepicker({
                "singleDatePicker": true,
                "timePicker": true,
                "locale": {
                    "format": 'DD/MM/YYYY HH:mm',
                    "separator": "-"
                },
                "showDropdowns": true
            });

        }
        var onComplete = function(){
            // console.log("Event successfully Added");
                                $.each(tables, function(index, data){
                        tables[index].ajax.reload();
                    });
            var eventsCount = parseInt($('#events-count').html()) + 1;
            $('#events-count').html(eventsCount);
        }

        var message = 'Add new Met Life referral event for '+first_name+' '+last_name+'?  (Contact #'+contact_id+')';

        var post_data = {
            'link_id': contact_id,
            'event_type_name' : 'contact',
            'product_type' : product_type,
            'add_to_basket' : basket
        };

        if(basket){
            post_data.quote_id = lead_script.quoteId;
        }

        showReferralMessagebox(contact_id, message, product_type, post_data,  onShow, onComplete);
    });

    $('body').on('click','#BroadbandReferEvent',function(e) {
        e.preventDefault();
        var basket = $(this).data('add-to-basket') ? true : false;
        var contact_id = $('#contact-details').data('contact-id');
        var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var product_type = $(this).data('product-type').toLowerCase();
        if (!product_type) {
            product_type = 'Broadband';
        }
        var onShow = function(response) {
			$('.broadband-referral-content .select2').select2({
				'width': '100%'
			});
	
			$('.broadband-referral-content .date-picker').daterangepicker({
				"locale": {
					"format": 'DD-MM-YYYY',
					"separator": "-" 
				},
				"singleDatePicker": true,
				"showDropdowns": true
			});
        }
        var onComplete = function(){
            // console.log("Event successfully Added");
            $.each(tables, function(index, data){
                tables[index].ajax.reload();
            });
            var eventsCount = parseInt($('#events-count').html()) + 1;
            $('#events-count').html(eventsCount);
        }

        var message = 'Add new Broadband referral event for '+first_name+' '+last_name+'?  (Contact #'+contact_id+')';

        var post_data = {
            'link_id': contact_id,
            'event_type_name' : 'contact',
            'product_type' : product_type,
            'add_to_basket' : basket
        };

        if(basket){
            post_data.quote_id = lead_script.quoteId;
        }

        showReferralMessagebox(contact_id, message, product_type, post_data,  onShow, onComplete);
    });

    $('body').on('click','#BoilerCareReferEvent',function(e) {
        e.preventDefault();
        var basket = $(this).data('add-to-basket') ? true : false;
        var contact_id = $('#contact-details').data('contact-id');
        var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var product_type = $(this).data('product-type').toLowerCase();
        if (!product_type) {
            product_type = 'Broadband';
        }
        var onShow = function(response) {
			$('#main_modal_body input[name="insurance_time_to_call"]').daterangepicker({
                "singleDatePicker": true,
                "locale": {
                    "format": 'DD/MM/YYYY HH:mm',
                    "separator": "-"
                },
                "showDropdowns": true,
				"timePicker": true,
            });
			$('select[name="callback_preference"]').on('change', function () {
                if($(this).val() == 'custom_time'){
					$('.insurance-call-time-selector').show();
				}
				else{
					$('.insurance-call-time-selector').hide();
				}
            });
        }
        var onComplete = function(){
            // console.log("Event successfully Added");
            $.each(tables, function(index, data){
                tables[index].ajax.reload();
            });
            var eventsCount = parseInt($('#events-count').html()) + 1;
            $('#events-count').html(eventsCount);
        }

        var message = 'Add new Boiler Care referral event for '+first_name+' '+last_name+'?  (Contact #'+contact_id+')';

        var post_data = {
            'link_id': contact_id,
            'event_type_name' : 'contact',
            'product_type' : product_type,
            'add_to_basket' : basket
        };

        if(basket){
            post_data.quote_id = lead_script.quoteId;
        }

        showReferralMessagebox(contact_id, message, product_type, post_data,  onShow, onComplete);
    });

	//manual upload to ultra via contact leads panel	
	$('#contact-leads-table').on('click','.ultra-upload', function(e) {
		e.preventDefault();
        var contact_id = $('#contact-details').data('contact-id');
		var lead_id = $(this).data('id');
		var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var message = 'Manual Ultra upload for '+first_name+' '+last_name+'?  (Contact #'+contact_id+', original Lead #'+lead_id+')';
        var messagebox = new MessageBox('uploadultra', message);

		messagebox.setData({
		
		//component has to be added *after* handlebars compiles and DOM updates, so just like this:	
		after: function() {
			var currentDate = moment();
			$('#l-date-filter').daterangepicker({
			"locale": {
				"format": 'DD/MM/YYYY hh:mm A',
				"separator": "-",
				"applyLabel": "Schedule date"
			},
			"singleDatePicker": true,
			"timePicker": true,
			"opens":'right',
			"showDropdowns:": true,
			"startDate" : currentDate.add(moment.duration(5,'minutes')).format("DD/MM/YYYY hh:mm A"),
			"minDate" : currentDate.format("DD/MM/YYYY hh:mm A"),
			"showDropdowns" : true
			});
		},
        ajax: { 
			//use the lead-less contact upload
            cache: false,
            url: '/ultra/manual-upload',
            type: 'POST',
            dataType: 'json',
            data: {
				contact_id : contact_id,
				lead_id : lead_id
			},
            success: function(response) {
				//pop a relaxing confirmation dialog showing what we've done...
				var upload_details = 'Ultra list: '+response['ultra_list']+'<br/>'+response['timestamp']+'<br/>Agent: '+response['agent']; 
			    $('#upload_details').html(upload_details);
			   	$("#ultra-upload-confirmation-dialog").dialog({
					resizable: false,
					height: "auto",
					width: 400,
					modal: true,
					dialogClass: 'modalHeadingRestful',
					buttons: {
					  Ok: function() {
							$( this ).dialog( "close" );
					  }
					}
				});
            },
            error: function (error) {
                //console.log(error);
            }
        }
        });
		messagebox.run();
	});
	
	//toggle dnc status	
	$('#client-profile').on('click','#dnc-toggle', function(e) {
		e.preventDefault();
		//console.log("Ok, toggling DNC...");
		var contact_id = $('#contact-details').data('contact-id');
		
		$.ajax ({ 
            cache: false,
            url: '/contact/toggle-dnc',
            type: 'POST',
            dataType: 'json',
            data: {
				'contact_id' : contact_id,
				'source' : 'RealCRM2'
			},
            success: function(response) {
               //confirmation dialog
			   location.reload();
		    },
            error: function (error) {
                //console.log(error);
            }
        });
    });    
	
	//forget a contact (GDPR)
	$('#contact-policies-edit').on('click','#forget-me', function(e) {
		e.preventDefault();
		//console.log("Ok, working to forget...");
		var contact_id = $('#contact-details').data('contact-id');
		var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var message = 'Right to be forgotten/to erasure for '+first_name+' '+last_name+'?  (Contact #'+contact_id+')';
		var messagebox = new MessageBox('forgetcontact', message);
		messagebox.setData({
			'prerequest' : function(){
				showLoader();
			},
			'ajax': { 
				cache: false,
				url: '/contact/forget-me',
				type: 'POST',
				dataType: 'json',
				data: {
					contact_id : contact_id
				},
				success: function(response) {
				//confirmation dialog to explain what has happened (useful summary I think for info/debug/confirm action)
					hideLoader();
					response.summary = response.summary;
					$('#forget-outcome').html(response.summary);
					$("#right-to-forget-confirmation-dialog").dialog({
						resizable: false,
						height: "auto",
						width: 400,
						modal: true,
						dialogClass: 'modalHeadingRed',
						buttons: {
						Ok: function() {
								//move away from contact
								window.location.href = '/';
								//$( this ).dialog( "close" );
						}
						}
					});
				},
				error: function (error) {
					//console.log(error);
					hideLoader();
					error_message(error);
				}
			}
        });
		messagebox.run();
	});
	
	//contact data access request (GDPR)
	$('#contact-policies-edit').on('click','#right-of-access', function(e) {
		e.preventDefault();
		//console.log("Ok, working to get contact's crm data...");
		var contact_id = $('#contact-details').data('contact-id');
		var first_name = $('#contact-details-edit input[name="first_name"]').val();
        var last_name = $('#contact-details-edit input[name="last_name"]').val();
        var message = 'Right to access for '+first_name+' '+last_name+'?  (Contact #'+contact_id+')';
        var messagebox = new MessageBox('accesscontact', message);
		messagebox.setData({
        ajax: { 
            cache: false,
            url: '/contact/access',
            type: 'POST',
            dataType: 'json',
            data: {
				contact_id : contact_id
			},
            success: function(response) {
				//force a download (AJAX shouldn't do this, so in cross-browser friendly way...)
				window.location = '/contact/downloadSAR?zippath='+response['pathname'];
				
				//the old dialog when email/printing were FTPd
				//and then Kerridge-printed.  Leave for now.
				$("#right-to-access-confirmation-dialog").dialog({
					resizable: false,
					height: "auto",
					width: 400,
					modal: true,
					dialogClass: 'modalHeadingRed',
					buttons: {
						Ok: function() {
							$( this ).dialog( "close" );
						}
					}
				});
            },
            error: function (error) {
                //console.log(error);
            }
        }
        });
		messagebox.run();
	});
	
	//check to see if a contact has been forgotten in the CRM
	$('#contact-forgotten-check').on('click','#check-forget-button', function(e) {
		//console.log('pressed to check if forgotten');
		e.preventDefault();	
		
		var first_name = $('#forgotten_check_form input[name="first_name"]').val();
        var last_name = $('#forgotten_check_form input[name="last_name"]').val();
        var postcode = $('#forgotten_check_form input[name="postcode"]').val();

		$.ajax ({ 
            cache: false,
            url: '/contact/forgotten',
            type: 'POST',
            dataType: 'json',
            data: {
				'first_name' : first_name,
				'last_name' : last_name,
				'postcode' : postcode
			},
            success: function(response) {
                //show the response
				//console.log(response);
				var message = "<hr/><h4>Search outcome:</h4><p>"+response.details.message+"</p>";
				if (response.success) {
					message = message + "<p><b>Erasure date:</b> " + response.details.erasure_date + "</p>";
					message = message + "<p><b>Acting CRM user:</b> " + response.details.user + "</p>";
				}
				$('#forgotten_outcome').html(message);
				$('#forgotten_outcome').show();
		    },
            error: function (error) {
                //console.log(error);
            }
        });
		
	});	
		
		
	//email accident protection documents, post open work
	$('.send-documents-button').on('click', function(e) {
		e.preventDefault();
		//console.log("Ok, sending an email...");
		var order_id = $(this).data('order_id');
		var contact_id = $('#contact-details').data('contact-id');
		var first_name = $('#contact-details-edit input[name="first_name"]').val();
		var last_name = $('#contact-details-edit input[name="last_name"]').val();
		var message = 'Email Accident Protection documents for '+first_name+' '+last_name+' (Contact #'+contact_id+', Order #'+order_id+')?';
		var messagebox = new MessageBox('emailconfirmed', message);
		
		messagebox.setData({
			ajax : { 
				cache: false,
				url: '/upload/accident_protection_email',
				type: 'POST',
				dataType: 'json',
				data: {
					'order_id' : order_id
				},
				success: function(response) {
					//confirmation dialog
					if (response.success) {
						//console.log('here in success');	
					}
				},
				error: function (error) {
					//console.log(error);
				}
			}
		});
		messagebox.run();
	});

	// $('#script-tabs').on('click', function(event) {
	
	// 	event.preventDefault();
		
  	// 	//get the current script tab
	// 	var tab_clicked = event.target;
	// 	var tab_label = $(tab_clicked).data('truename');
		
	// 	//get locked state (red padlock)
	// 	var lock_state = $('#script-tabs').find("[data-truename='"+tab_label+"']").html().indexOf('red');
			
	// 	//are we locked?  if so, needs a pin auth to open	
	// 	if (lock_state>0) {
	// 		var contact_id = $('#contact-details').data('contact-id');
	// 		var order_id = $(tab_clicked).data('orderid');
	// 		var message = 'Alias another user to modify '+tab_label+' script for quote #'+order_id+'?';
	// 		var messagebox = new MessageBox('aliasscript', message);
	// 		setTimeout(function(){
	// 			$('#'+tab_label).removeClass('active');
	// 		},300);

	// 		messagebox.setData({
	// 			ajax: { 
	// 			cache: false,
	// 			url: '/script/alias',
	// 			type: 'POST',
	// 			dataType: 'json',
	// 			data: {
	// 				'quoteID' : order_id,
	// 				'scriptID' : tab_label
	// 			},
	// 			success: function(response) {
	// 				//check if no user id (not aliased)
	// 				if (response.user_id < 0) {
	// 					//any action  if they get pin wrong...
	// 					$('#'+tab_label).removeClass('active');
	// 				} else {
	// 					//update script tab with an override userID
	// 					var userID = $(tab_clicked).data('scriptuserid',response.user_id);
	// 					//let aliasing user see it
	// 					$('#'+tab_label).addClass('active');
	// 				}
	// 			},
	// 			error: function (error) {
	// 				//console.log(error);
	// 			}
	// 		}
	// 	});
	// 	messagebox.run();
	// 	}
	// });
		
	
	$('#openworks').on('click', '#open-work-show-account', function(e){
		e.preventDefault();
		$('p.open-work-hide-account').each(function(){
			if($(this).text()[0] != '*'){
				$(this).text($(this).text().replace(/.(?=.{3,}$)/g, '*'));
			}
		});
		$(this).siblings().text($(this).siblings().data('full')) 
	});
    

    $('body').on('click', '#check_web_user_link', function(e){
        e.preventDefault();
        var contact_id = $('#contact-details').data('contact-id');

        var data = {
            'contact_id' : contact_id
        };

        var that = this;
        $.ajax({ 
            cache: false,
            url: '/contacts/check_web_link',
            type:"POST",
            dataType: 'json',
            data: data,
            success: function (response) {
                
                if(response.success){
                    if(response.html){
                        if($('#web_user_details').length){
                            $('#web_user_details').replaceWith(response.html);
                        }
                        else{
                            $('#contact-details-edit').append(response.html);
                        }
                        success_message('updated');
                        $(that).remove();
                        return;
                        
                    }
                    error_message('no link');
                    return 
                }
                if(response.error){
                    error_message(response.error)
                }
                else{
                    error_message('failed : ' + response);
                }

            },
            error: function (error) {
                error_message(error)
            }
        });
    })

    $('body').on('click', '#unlink_web_user', function(e){
        e.preventDefault();
        var contact_id = $('#contact-details').data('contact-id');
        var data = {
            'contact_id' : contact_id
        };
        if(confirm('are you sure you want to unlink this?')){
            $.ajax({ 
                cache: false,
                url: '/contacts/delete_web_link',
                type:"POST",
                dataType: 'json',
                data: data,
                success: function (response) {
                    
                    if(response.success){
                        if($('#web_user_details').length){
                            if(response.html){
                                $('#web_user_details').replaceWith(response.html);    
                            }
                            else{
                                $('#web_user_details').remove();
                            }
                            
                        }
                        success_message('unlinked');
                        return;
                    }
                    if(response.error){
                        error_message(response.error)
                    }
                    else{
                        error_message('failed : ' + response);
                    }

                },
                error: function (error) {
                    error_message(error)
                }
            });
        }
    })

    $('body').on('click', '#send_password_reset', function(e){
        e.preventDefault();
        var contact_id = $('#contact-details').data('contact-id');
        var data = {
            'contact_id' : contact_id
        };
        if(confirm('are you sure?')){
            $.ajax({ 
                cache: false,
                url: '/contacts/send_pw_reset',
                type:"POST",
                dataType: 'json',
                data: data,
                success: function (response) {
                    
                    if(response.success){
                        success_message('sent');
                        return;
                    }
                    if(response.error){
                        error_message(response.error)
                    }
                    else{
                        error_message('failed : ' + response);
                    }

                },
                error: function (error) {
                    error_message(error)
                }
            });
        }
    })

    $('body').on('click', '#find_user_from_email', function(e){
        e.preventDefault();
        var contact_id = $('#contact-details').data('contact-id');
        var data = {
            'contact_id' : contact_id
        };
        // if(confirm('are you sure you want to link this user?')){
            $.ajax({ 
                cache: false,
                url: '/contacts/attempt_steal',
                type:"POST",
                dataType: 'json',
                data: data,
                success: function (response) {

                    if(response.state && response.state == 'linked'){
                        if(response.html){
                            if($('#web_user_details').length){
                                $('#web_user_details').replaceWith(response.html);
                            }
                            else{
                                $('#contact-details-edit').append(response.html);
                            }
                            success_message('updated');
                            return;
                            
                        }
                    }

                    if(response.html){
                        $('#stealWebUserWarning-template').html(response.html)
                        var messagebox = new MessageBox('stealWebUserWarning', 'Warning You are about to steal a webuser from another contact ');
                        messagebox.setData({
                        ajax: { 
                            cache: false,
                            url: '/contacts/steal_web_user',
                            type: 'POST',
                            dataType: 'json',
                            data: {
                                'contact_id' : contact_id,
                            },
                            success: function(response) {
                            //confirmation dialog to explain what has happened (useful summary I think for info/debug/confirm action)
                                if(response.success){      
                                    success_message('complete');
                                    window.location.reload();
                                }
                               
                            },
                            error: function (error) {
                                //console.log(error);
                            }
                        }
                        });
                        messagebox.run();
                    }
                    else{
                        if(response.error){
                            error_message(response.error);
                        }
                    }

                },
                error: function (error) {
                    error_message(error)
                }
            });
        //}
    })

    $('body').on('click', '#web_user_link_warning button#steal_web_user', function(e){
        e.preventDefault();
        var contact_id = $('#contact-details').data('contact-id');
        var data = {
            'contact_id' : contact_id
        };
        if(confirm('are you sure you want to link this user?')){
            $.ajax({ 
                cache: false,
                url: '/contacts/attempt_steal',
                type:"POST",
                dataType: 'json',
                data: data,
                success: function (response) {
                    
                    if(response.html){
                        $('#stealWebUserWarning-template').html(response.html)
                        var messagebox = new MessageBox('stealWebUserWarning', message);
                        messagebox.setData({
                        ajax: { 
                            cache: false,
                            url: '/contact/steal_web_user',
                            type: 'POST',
                            dataType: 'json',
                            data: {
                                contact_id : contact_id
                            },
                            success: function(response) {
                            //confirmation dialog to explain what has happened (useful summary I think for info/debug/confirm action)
                                response.summary = response.summary;
                                $('#forget-outcome').html(response.summary);
                                $("#right-to-forget-confirmation-dialog").dialog({
                                    resizable: false,
                                    height: "auto",
                                    width: 400,
                                    modal: true,
                                    dialogClass: 'modalHeadingRed',
                                    buttons: {
                                    Ok: function() {
                                            //move away from contact
                                            window.location.href = '/';
                                            //$( this ).dialog( "close" );
                                    }
                                    }
                                });
                            },
                            error: function (error) {
                                //console.log(error);
                            }
                        }
                        });
                        messagebox.run();
                    }


                    if(response.success){
                        //we can steal - but lets show the user what they are doing 
                        if(response.html){
                            createModal('web_user_link_warning', response.html);
                        }
                        $('#steal_web_user').data('stealable', true);
                    }
                    if(response.html){
                        createModal('web_user_link_warning', response.html);
                        $('#steal_web_user').data('stealable', false);
                    }

                },
                error: function (error) {
                    error_message(error)
                }
            });
        }
    })

    $('body').on('click', '#resend_hot_link', function(e){
        e.preventDefault();
        var contact_id = $('#contact-details').data('contact-id');
        var data = {
            'contact_id' : contact_id
        };
        if(confirm('are you sure you want send this sms?')){
            $.ajax({ 
                cache: false,
                url: '/contacts/send_hot_link',
                type:"POST",
                dataType: 'json',
                data: data,
                success: function (response) {
                    
                    if(response.success){
                        success_message('sent')
                    }
                    else{
                        if(response.error){
                            error_message(response.error);
                        }
                        else{
                            error_message('unknown error');
                        }
                    }

                },
                error: function (error) {
                    error_message(error)
                }
            });
        }
    })

    var old = '';
    var screen_refresh_timeout = false;
    var screenRefresh = function(data){
        screen_refresh_timeout = setTimeout(function(){             
            $.ajax({ 
                cache: false,
                url: '/contacts/support_html',
                type:"POST",
                dataType: 'json',
                data: data,
                success: function (response) {
                    if(response.success && response.body) {

                        if(data.full === true){
                            data.full =false;
                            var context = $('#support_screen')[0].contentWindow.document;
                            var head = $('head', context);
                            if(response.head){
                                head.html(response.head);
                            }
                            var body = $('body', context);
                            body.html(response.body);
                            $('div.screen-header').hide();
                        }
                        else{
                            if(old != response.body){
                                var context = $('#support_screen')[0].contentWindow.document;
                                var body = $('body', context);
                                body.html(response.body);
                            }
                        }
                        old = response.body;
                        screenRefresh(data);
                        return;
                    }
                    if(response.error){
                        error_message(response.error);
                        if(response.success){
                            screenRefresh(data);
                        }
                        return;
                    }
                    
                },
                error:function(){screenRefresh(data)}
            });

        }, 5000)
    }


    $('body').on('click', '#offer_support', function(e){
        e.preventDefault();
        var contact_id = $('#contact-details').data('contact-id');
        var command_id = 1; // this is the command_id for offer help
        var data = {
            'contact_id' : contact_id,
            'command_id' : command_id,
        };
        if(confirm('are you sure you want send this to the user, it will ask them to share their screen with you?')){
            $.ajax({ 
                cache: false,
                url: '/contacts/run_web_user_command',
                type:"POST",
                dataType: 'json',
                data: data,
                success: function (response) {
                    
                    if(response.success){
                        success_message('sent')
                        $('#support_tab').show().find('a').trigger('click');

                        data.full = true;

                        screenRefresh(data);
                        

                    }
                    else{
                        if(response.error){
                            error_message(response.error);
                        }
                        else{
                            error_message('unknown error');
                        }
                    }

                },
                error: function (error) {
                    error_message(error)
                }
            });
        }
    })

    $('body').on('click', '#send_web_user_command', function(e){
        e.preventDefault();
        var contact_id = $('#contact-details').data('contact-id');
        var command_id = $(this).parent().find('#web_user_command_selector').val(); // this is the command_id for offer help
        var data = {
            'contact_id' : contact_id,
            'command_id' : command_id,
        };
        if(confirm('are you sure you want send this to the user, this will change the users experience?')){
            $.ajax({ 
                cache: false,
                url: '/contacts/run_web_user_command',
                type:"POST",
                dataType: 'json',
                data: data,
                success: function (response) {
                    
                    if(response.success){
                        success_message('sent')

                        switch (command_id) {
                            case "3":
                                //disconnect 
                                clearTimeout(screen_refresh_timeout);
                                $('#support_tab').hide();
                                $('a[href="#client-details"]').trigger('click');
                                break;
                        
                            default:
                                break;
                        }

                    }
                    else{
                        if(response.error){
                            error_message(response.error);
                        }
                        else{
                            error_message('unknown error');
                        }
                    }

                },
                error: function (error) {
                    error_message(error)
                }
            });
        }
    })
	
	//@TODO to complete

	
	//create email modals..
	$('body').on('click',"#create-contact-email",function(e) {
        e.preventDefault();
//		var first_name = $('#contact-details-edit input[name="first_name"]').val();
//		var last_name = $('#contact-details-edit input[name="last_name"]').val();
		var email = $('#contact-details-edit input[name="email"]').val();
		var contact_id = $('#contact-details').data('contact-id');
				
//		$('#create_email_modal_title').html('Send email to ' + first_name + ' ' + last_name + ' ('+email+') ?');  
//		showModal('create_email_modal');
		email_obj.clear();
		email_obj.setTos([{'contact_id' : contact_id, 'email' : email}]);
		email_obj.setRenderMode('rendered');
		email_obj.setEntityId(contact_id);
		email_obj.setType('contact');
		email_obj.startFlow();
    });

    $('body').on('click', '.referral-tab-btn', function() {
        var step = $(this).data('name');

        $('.referral-tab-item').each(function () {
            $(this).slideUp("fast", function () {
                $(this).removeClass('show').addClass('hide');
            });
        });
        setTimeout(expandReferral(step), 750);
    });

    var expandReferral = function (step) {
        $('.'+step+'-tab').removeClass('hide').addClass('show');
    };

	
	$('body').on('click', 'a.show-email', function(e){
		e.preventDefault();
		$(this).closest('tr').find('.email-content').toggle();
	});

    $('body').on('click', '.event-type-selector', function() {
        var that = $(this),
            event_type_id = $(this).data('event-type');

        if(!$(that).hasClass('active-event-type')) {
            $('.active-event-type').removeClass('active-event-type');
            $(that).addClass('active-event-type');
            $('.contact-events-sub-table').each(function(i, selector) {
                $(selector).hide();
            });
            $('.event-table-for-' + event_type_id).show();
        }
    });

    $('body').on('click', '.call-coaching-event', function() {
        var data = {
            'contact_id': $(this).data('contact-id'),
            'transaction_id': $(this).data('transaction-id'),
            'user_id': $(this).data('user-id')
        };
        buildDynamicModal('dynamic_coaching_modal', data);
    });

    $('body').on('click','#create_dynamic_coaching_form',function(){
        var form = $(this).parent().parent().serializeArray();
        makeRequest('/coaching/create',form, function(response) {
            if(response.error) {
                error_message(response.error);
            }
            if(response.success) {
                success_message(response.message);
                hideModal('dynamic_coaching_modal');

                if(isset(response.report)) {
                    window.openCoachingForm(response.report);
                }
            }
        });
    });

    $('body').on('click', '.call-recording-event', function () {
        var data = {
            'external_id': $(this).data('external-id'),
            'dialler_id': $(this).data('dialler-id'),
            'contact_id': $(this).data('contact-id'),
            'user_id': $(this).data('user-id')
        };
        buildDynamicModal('dynamic_call_recording_modal', data);
    });
    $('body').on('hidden.bs.modal', '#dynamic_call_recording_modal', function (e) {
        $('#dynamic_call_recording_modal').find('#player').remove();
    });

	$('#modal_container').on('click','#confirm_suppression_select_lists_modal .confirm-suppression',function(){
		var contact_id = $(this).data('contact-id');
		var lists = false;
		if($('#confirm_suppression_select_lists_modal .list-selection').length){
			lists = $('#confirm_suppression_select_lists_modal .list-selection').val();
		}
		var data = {
			'contact_id' : contact_id,
			'lists' : lists,
		}
		showLoader();
		makeRequest('/contact/suppress/lists', data, function(response){
			if(isset(response.success) && response.success){
				$('#confirm_suppression_select_lists_modal').modal('hide');
				success_message('Suppressed');
				updateContactEventTables();
			}
			else{
				var error = 'an unknown error occurred';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		})

	});

	$('#suppress_contact').on('click', function(){
	
		var form = $('#contact-details-edit');
		
		var contact_id_finder = $(form).find('#contact-details');
		
		if(contact_id_finder.length){
			var contact_id = $(contact_id_finder).data('contact-id');
			var data = {
				'contact_id' : contact_id,
			};
			buildDynamicModal('confirm_suppression_select_lists_modal', data);
		}
		else{
			error_message('contact not found');
		}

	});


	$('#contact-dialler-instances-table').on('click', '.show-dialler-instance-profile', function(e){
		e.preventDefault();
		var icon = $(this).find('i')
		makeLoadingIcon(icon);
		var cdi_id = $(this).data('cdi-id');

		var data = {
			'contact_dialler_instance_id' : cdi_id,
		};

		buildDynamicModal('dialler_instance_profile_modal', data, function(){}, true, function(){
			restoreIcon(icon);	
		});


	});
});	
	
