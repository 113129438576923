var makeBusinessesDataTable = function (start = false, end = false, reload = false) {
	var base = '/get/business-orders';
	var params = '';
	if(start != false && end != false && reload) {
		params = "?start=" + start + "&end=" + end;
	}

	var url = base.concat(params);

	if(reload) {
		$('#business-orders-table').find('thead select').remove();
	}

	return $('#business-orders-table').DataTable({
		destroy: true,
		dom: 'flrtiBp',
		ajax: url,
		"columns": [
			{
				"data": 'sales_order_id',
				"render": function (data) {
					return '<a target="_blank" href="/order/'+data+'">'+data+'</a>'
				}
			},
			{ "data": "external_order_number" },
			{
				"data": "business_id",
				"business_name": "business_name",
				"render": function (data, type, row) {
					return '<a target="_blank" href="/business/'+data+'">'+row['business_name']+'</a>'
				}
			},
			{
				"data": "line_1",
				"line_1": "line_1",
				"line_2": "line_2",
				"post_code": "post_code",
				"render": function (data, type, row) {
					var address = '';
					if(isset(row['line_1'])) {
						address.concat(row['line_1'])
					}
					if(isset(row['line_2'])) {
						address.concat(' ', row['line_2'])
					}
					if(isset(row['post_code'])) {
						address.concat(' ', row['post_code'])
					}
					return row['line_1'] + ' ' + row['line_2'] + ' ' + row['post_code']
				}
			},
			{ "data": "product_name" },
			{ "data": "status" },
			{ "data": "lead_url" },
			{ "data": "confirmed_at" },
			{
				"data": 'sales_order_id',
				"render": function (data) {
					return '<a target="_blank" href="/order/'+data+'">View</a>'
				}
			}
		],
		pageLength: 25,
		ordering: true,
		responsive: true,
		bAutoWidth: false,
		autoWidth: false,
		lengthMenu: [25, 50, 100],
		select: true,
		buttons: [
			'copyHtml5',
			'print',
			{
				extend: 'collection',
				text: 'Export',
				buttons: [
					'csvHtml5',
					'pdfHtml5'
				]
			}
		],
		initComplete: function () {
			this.api().columns([3, 5]).every(function () {
				var column = this;
				var title = $(column.header()).html();
				var select = $('<select style="float:left; width:85%; border-radius:4px; color:black;"><option></option></select>')
					.on('change', function () {
						var val = $.fn.dataTable.util.escapeRegex(
							$(this).val()
						);

						column
							.search(val ? '^' + val + '$' : '', true, false)
							.draw();
					});
				column.data().unique().sort().each(function (d, j) {
					if (column.search() === '^' + d + '$') {
						select.append('<option value="' + d + '" selected="selected">' + d + '</option>')
					} 
					else {
						select.append('<option value="' + d + '">' + d + '</option>')
					}
				});
				$(column.header()).html('<span>' + title + '</span>');
				$(column.header()).append(select);
			});
		}
	});
}

var makeRebelDataTable = function (start = false, end = false, reload = false) {
	var base = '/get/rebel-orders';
	var params = '';
	if(start != false && end != false && reload) {
		params = "?start=" + start + "&end=" + end;
	}

	var url = base.concat(params);

	if(reload) {
		$('#rebel-orders-table').find('thead select').remove();
	}

	return $('#rebel-orders-table').DataTable({
		destroy: true,
		dom: 'flrtiBp',
		ajax: url,
		"columns": [
			{
				"data": "contact_id",
				"contact_name": "contact_name",
				"render": function (data, type, row) {
					return '<a target="_blank" href="/contacts/'+data+'">'+row['contact_name']+'</a>'
				}
			},
			{
				"data": 'sales_order_id',
				"render": function (data) {
					return '<a target="_blank" href="/order/'+data+'">'+data+'</a>'
				}
			},
			{ "data": "external_order_number" },
			{ "data": "customer_state" },
			{ "data": "internal_status" },
			{ "data": "confirmed_at" },
			{ "data": "all_meters_on_supply" },
			{ "data": "electricity_status" },
			{ "data": "electricity_meter_number" },
			{ "data": "electricity_supply_start_date" },
			{ "data": "electricity_is_on_supply" },
			{ "data": "electricity_meter_status" },
			{ "data": "gas_status" },
			{ "data": "gas_meter_number" },
			{ "data": "gas_supply_start_date" },
			{ "data": "gas_is_on_supply" },
			{ "data": "gas_meter_status" },
		],
		pageLength: 25,
		ordering: true,
		responsive: true,
		bAutoWidth: false,
		autoWidth: false,
		lengthMenu: [25, 50, 100],
		select: true,
		buttons: [
			'copyHtml5',
			'print',
			{
				extend: 'collection',
				text: 'Export',
				buttons: [
					'csvHtml5',
					'pdfHtml5'
				]
			}
		],
		initComplete: function () {
			this.api().columns([3,4,6,7,11,12,16]).every(function () {
				var column = this;
				var title = $(column.header()).html();
				var select = $('<select style="float:left; width:85%; border-radius:4px; color:black;"><option></option></select>')
					.on('change', function () {
						var val = $.fn.dataTable.util.escapeRegex(
							$(this).val()
						);

						column
							.search(val ? '^' + val + '$' : '', true, false)
							.draw();
					});
				column.data().unique().sort().each(function (d, j) {
					if (column.search() === '^' + d + '$') {
						select.append('<option value="' + d + '" selected="selected">' + d + '</option>')
					} 
					else {
						select.append('<option value="' + d + '">' + d + '</option>')
					}
				});
				$(column.header()).html('<span>' + title + '</span>');
				$(column.header()).append(select);
			});
		}
	});
}


var makeDataTable = function (start = false, end = false, reload = false) {
	var base = '/get/orders';
	var params = '';
	if(start != false && end != false && reload) {
		params = "?start=" + start + "&end=" + end;
	}

	var url = base.concat(params);

	if(reload) {
		$('#orders-table').find('thead select').remove();
	}

	return $('#orders-table').DataTable({
		destroy: true,
		dom: 'flrtiBp',
		ajax: url,
		"columns": [
			{
				"data": 'sales_order_id',
				"render": function (data) {
					return '<a target="_blank" href="/order/'+data+'">'+data+'</a>'
				}
			},
			{ "data": "external_order_number" },
			{
				"data": "contact_id",
				"contact_name": "contact_name",
				"render": function (data, type, row) {
					return '<a target="_blank" href="/contacts/'+data+'">'+row['contact_name']+'</a>'
				}
			},
			{ "data": "email" },
			{ "data": "user_name" },
			{ "data": "product_name" },
			{ "data": "status" },
			{ "data": "lead_url" },
			{ "data": "online_order" },
			{ "data": "lead_age" },
			{ "data": "order_total" },
			{ "data": "confirmed_at" },
			{
				"data": 'sales_order_id',
				"render": function (data) {
					return '<a target="_blank" href="/order/'+data+'">View</a>'
				}
			}
		],
		pageLength: 25,
		ordering: true,
		responsive: true,
		bAutoWidth: false,
		autoWidth: false,
		lengthMenu: [25, 50, 100],
		select: true,
		buttons: [
			'copyHtml5',
			'print',
			{
				extend: 'collection',
				text: 'Export',
				buttons: [
					'csvHtml5',
					'pdfHtml5'
				]
			}
		],
		initComplete: function () {
			this.api().columns([3, 5]).every(function () {
				var column = this;
				var title = $(column.header()).html();
				var select = $('<select style="float:left; width:85%; border-radius:4px; color:black;"><option></option></select>')
					.on('change', function () {
						var val = $.fn.dataTable.util.escapeRegex(
							$(this).val()
						);

						column
							.search(val ? '^' + val + '$' : '', true, false)
							.draw();
					});
				column.data().unique().sort().each(function (d, j) {
					if (column.search() === '^' + d + '$') {
						select.append('<option value="' + d + '" selected="selected">' + d + '</option>')
					} 
					else {
						select.append('<option value="' + d + '">' + d + '</option>')
					}
				});
				$(column.header()).html('<span>' + title + '</span>');
				$(column.header()).append(select);
			});
		}
	});
}

var makeOrdersOnHoldDataTable = function (start = false, end = false, reload = false) {
	var base = '/get/on-hold-orders';
	var params = '';
	if(start != false && end != false && reload) {
		params = "?start=" + start + "&end=" + end;
	}

	var url = base.concat(params);

	if(reload) {
	}
		$('#orders-on-hold-table').find('thead select').remove();

	return $('#orders-on-hold-table').DataTable({
		destroy: true,
		dom: 'flrtiBp',
		ajax: url,
		pageLength: 25,
		ordering: true,
		responsive: true,
		bAutoWidth: false,
		autoWidth: false,
		lengthMenu: [25, 50, 100],
		select: true,
		buttons: [
			'copyHtml5',
			'print',
			{
				extend: 'collection',
				text: 'Export',
				buttons: [
					'csvHtml5',
					'pdfHtml5'
				]
			}
		]
	});
}

var makeFinanceOrdersOnHoldDataTable = function (start = false, end = false, reload = false) {
	var base = '/get/on-hold-orders-finance';
	var params = '';
	if(start != false && end != false && reload) {
		params = "?start=" + start + "&end=" + end;
	}

	var url = base.concat(params);

	if(reload) {
		$('#finance-orders-on-hold-table').find('thead select').remove();
	}

	return $('#finance-orders-on-hold-table').DataTable({
		destroy: true,
		dom: 'flrtiBp',
		ajax: url,
		pageLength: 25,
		ordering: true,
		responsive: true,
		bAutoWidth: false,
		autoWidth: false,
		lengthMenu: [25, 50, 100],
		select: true,
		buttons: [
			'copyHtml5',
			'print',
			{
				extend: 'collection',
				text: 'Export',
				buttons: [
					'csvHtml5',
					'pdfHtml5'
				]
			}
		]
	});
}

$(function () {

	if($('.rebel-orders-date-filter').length){
		const finance_orders_on_hold_datatable = makeRebelDataTable();
		$('.rebel-orders-date-filter').daterangepicker(
			{
				"locale": {
					"format": 'DD/MM/YYYY HH:mm',
					"separator": "-"
				},
				"linkedCalendars": false,
				"showDropdowns": true
			},
			function(start, end, label) {
				const rebel_orders_datatable = makeRebelDataTable(moment(start).format("DD-MM-YYYY HH:mm"), moment(end).format("DD-MM-YYYY HH:mm"), true);
			}
		);
	}

	if($('.finance-orders-on-hold-date-filter').length){
		const finance_orders_on_hold_datatable = makeFinanceOrdersOnHoldDataTable();
		$('.finance-orders-on-hold-date-filter').daterangepicker(
			{
				"locale": {
					"format": 'DD/MM/YYYY HH:mm',
					"separator": "-"
				},
				"linkedCalendars": false,
				"showDropdowns": true
			},
			function(start, end, label) {
				const finance_orders_on_hold_datatable = makeFinanceOrdersOnHoldDataTable(moment(start).format("DD-MM-YYYY HH:mm"), moment(end).format("DD-MM-YYYY HH:mm"), true);
			}
		);
	}

	if($('.orders-on-hold-date-filter').length){
		const orders_on_hold_datatable = makeOrdersOnHoldDataTable();
		$('.orders-on-hold-date-filter').daterangepicker(
			{
				"locale": {
					"format": 'DD/MM/YYYY HH:mm',
					"separator": "-"
				},
				"linkedCalendars": false,
				"showDropdowns": true
			},
			function(start, end, label) {
				const orders_on_hold_datatable = makeOrdersOnHoldDataTable(moment(start).format("DD-MM-YYYY HH:mm"), moment(end).format("DD-MM-YYYY HH:mm"), true);
			}
		);
	}
	const datatable = makeDataTable();
	const businesses_datatable = makeBusinessesDataTable();

	$('.business-orders-date-filter').daterangepicker(
		{
			"locale": {
				"format": 'DD/MM/YYYY HH:mm',
				"separator": "-"
			},
			"linkedCalendars": false,
			"showDropdowns": true
		},
		function(start, end, label) {
			const businesses_datatable = makeBusinessesDataTable(moment(start).format("DD-MM-YYYY HH:mm"), moment(end).format("DD-MM-YYYY HH:mm"), true);
		}
	);

	$('.rebel-orders-date-filter').daterangepicker(
		{
			"locale": {
				"format": 'DD/MM/YYYY HH:mm',
				"separator": "-"
			},
			"linkedCalendars": false,
			"showDropdowns": true
		},
		function(start, end, label) {
			const datatable = makeRebelDataTable(moment(start).format("DD-MM-YYYY HH:mm"), moment(end).format("DD-MM-YYYY HH:mm"), true);
		}
	);



	$('.orders-date-filter').daterangepicker(
		{
			"locale": {
				"format": 'DD/MM/YYYY HH:mm',
				"separator": "-"
			},
			"linkedCalendars": false,
			"showDropdowns": true
		},
		function(start, end, label) {
			const datatable = makeDataTable(moment(start).format("DD-MM-YYYY HH:mm"), moment(end).format("DD-MM-YYYY HH:mm"), true);
		}
	);

	window.cancelOrder = function (order_id) {
		buildDynamicModal('cancel_order_modal', {'sales_order_id' : order_id}, function(){
			$('#cancel_order_modal input#order_cancelation_date').daterangepicker({
				"locale": {
					"format": 'DD/MM/YYYY HH:mm',
					"separator": "-"
				},
				"singleDatePicker": true,
				"timePicker": true,
				"linkedCalendars": false,
				"showDropdowns": true
			});
			$('#cancel_order_modal #order_cancelation_description').val('');
			$('#cancel_order_modal').attr('data-order-id', order_id);
		});
	};

	$('body').on('click', '#cancel_order_modal button#cancel_order_go', function(){
		
		var message = $('#cancel_order_modal #order_cancelation_description').val();
		var date = $('#order_cancelation_date').val();

		//data is being a dick - attr works so going to use that for now!
		var order_id = $('#cancel_order_modal').attr('data-order-id');

		var associated_order_tickboxes = $('#cancel_order_modal #cancel_associated_orders input');

		var associated_orders_to_cancel = [];
		$.each(associated_order_tickboxes, function(i, checkbox){
			if($(checkbox).is(':checked')){
				associated_orders_to_cancel.push($(checkbox).data('order-id'));
			}
		})
		
		var data =  {
			'message' : message,
			'date' : date,
			'associated_orders_to_cancel' : associated_orders_to_cancel
		};

		makeRequest('/order/quick-cancel/'+order_id, data, function(response){
			if(response.success) {
				success_message();
				$('#cancel_order_modal').modal('hide');
				window.location.reload();
			}
			else {
				error_message(response.error);
				$('#cancel_order_modal').modal('hide');
			}
		});
	});

	var order_id = 0;
	if (window.location.href.indexOf('/order/')) {
		order_id = window.location.href.split('/').pop();
	}
	//load notes
	const ordernotestable = $('#order-notes-table').DataTable({
		ajax: "/get/order/notes/" + order_id,
		pageLength: 4,
		ordering: false,
		searching: false,
		paging: true,
		lengthChange: false,
		autoWidth: false,
		bFilter: false,
		//lengthMenu: false
	});

	$('.forum-header-buttons').on('click','#create-order-note, #create-quote-note',function(e) {
		e.preventDefault();
		e.stopPropagation();
		//console.log("Ok, add button pressed, going to send...");
		var order_id = $(this).data('order-id');
		var message = 'Add new note for order? (Order #' + order_id + ')';
		if (this.id === 'create-quote-note') {
			message = 'Add new note for quote? (Quote #' + order_id + ')';
		}

		var message = new MessageBox('addnote', message);

		message.setData({
			ajax: {
				cache: false,
				url: '/event',
				type:"POST",
				dataType: 'json',
				data: {
					link_id: order_id,
					event_name : 'new_contact_note_event',
					event_type_name : 'SalesOrder',
				},
				success: function(response) {
					ordernotestable.ajax.reload();
					var noteCount = parseInt($('#order-notes-count').html()) + 1;
					$('#order-notes-count').html(noteCount);
				},
				error: function (error) {
					//console.log(error);
				}
			}
		});
		message.run();
	});

	var runOrderAction = function(button){
		var action = $(button).attr('id');
		var order_id = $(button).closest('div.quick-order-actions').data('order_id');
		if(action == 'cancel_order'){
			cancelOrder(order_id);
			return;
		}
		var order_interface = new orderInterface();
		if(isset(order_interface[action])){
			order_interface[action](order_id, function (response) {
				if (response.redirect) {
					window.location = response.url;
				} 
				else {
					success_message();
				}
			});
		}
	}

	$('.quick-order-actions').on('click', 'button', function (e) {
		e.preventDefault();
		runOrderAction(this);
	});
	

	//the order actions in the table below are lazy loaded
	$('#business_order_table').on('click', '.quick-order-actions button', function (e) {
		e.preventDefault();
		runOrderAction(this);
	});

	$('input#order_cancelation_date').daterangepicker({
		"locale": {
			"format": 'DD/MM/YYYY HH:mm',
			"separator": "-"
		},
		"singleDatePicker": true,
		"timePicker": true,
		"linkedCalendars": false,
		"showDropdowns": true
	});

	$('body').on('click','.stream-sales-call-recording',function(e){
        e.preventDefault();
        var data = {
            'short_code' : $(this).data('short-code'),
            // 'transaction_id' : $(this).data('transaction-id'),
        }
		var that = this;
		var loader =$(this).parent().find('.call-loader'); 

		var container = $(this).closest('.player-container');
		loader.show();
		$(this).hide();
        makeRequest('/get/sales-call-recording', data, function(response){
            if(response.success){
                $(that).hide();

                $(container).find('.mp3-source').attr('src', '/stream/sales-call-recording/'+data.short_code); //'+data.transaction_id+'/
				var plyr_element = $(container).find('.plyr');
                const player = new Plyr(plyr_element, {});

                // Expose player so it can be used from the console
				window.player = player;
				$(container).find('.link-differient-call-button').show();
				$(container).find('.remove-call-recording-button').show();
			}
			else {
				var error = 'Unknown Error With Playback';
				if(response.error) {
					error = response.error
				}
				$(container).find('.audio-playback-error').html(error).show();
			}
			$(loader).hide();
        });
	});
	
	$('body').on('click','.download-call-recording',function(e){
		var current_html = $(this).html();
		$(this).html('Loading Recording <i class="fa-regular fa-circle-notch fa-spin"></i>');
		var that = this;
		window.addEventListener('blur', function(){
			$(that).html(current_html);
		}, false);
	});
	
	$('.aftersale-script-tab-link').on('click', function(e){
		if($(this).closest('li').hasClass('active')){
			//lets hide the script
			// I know the setimeout below is a little bobbins - but it saves fighting with bootstrap or removing the 
			// funcationality they give us and this kinda works 
			var that = this;
			setTimeout(function(){
				$($(that).attr('href')).removeClass('active');
				$(that).closest('li').removeClass('active');
			}, 50);
		}
	})

	$('#modal_container').on('click', '#submit-dd-account-details', function(){
		var modal = $(this).closest('.manage-dd-modal');
		var order_id = $(modal).data('order-id');
		if($(modal).find('#using-stored-details').val()){

			var data = {};
			data['order_id'] = order_id;
			data['account_id'] = $(modal).find('#account-history').val();

			if($(modal).find('#account-name-new').length && $(modal).find('#account-name-new').is(':visible')) {
				var account_name = $(modal).find('#account-name-new').val();
				if(account_name.length > 25 || account_name.length < 2) {
					error_message('Account Name MUST be less than or equal to 18 characters and more than 1!')
					return;
				}
				else {
					data['account_name'] = account_name;
				}
			}

			//disable button and make a spinny
			$(this).prop('disabled', true);
			$(this).attr('data-state', 'loading');
			var that = this;
			makeRequest('/order/update/dd-account', data, function(response){
				if(isset(response.success) && response.success){
					// stop spinny and tell user we love life
					$(that).attr('data-state', 'confirmed');
					if(isset(response.html)){
						$(modal).find('.existing-payment-details-section').html(response.html)
					}
				}
				else{
					var error = 'unable to update dd details';
					if(isset(response.error)){
						error = response.error;
					}
					$(that).attr('data-state', 'errored')
				}
				$(that).prop('disabled', false);
				setTimeout(function(){
					$(that).attr('data-state', 'waiting');
				}, 1000);
			});
		}
		else{
			var bank_validator = new bankValidator();

			$(modal).find('.error-notice').html('');
			var raw_bank_data = $(modal).find('#new-bank-section input').serializeArray();
			var bank_data = {
				'sort-code' : []
			};
			$.each(raw_bank_data, function(i, item){
				if(item.name.indexOf('sort') !== -1){
					bank_data['sort-code'].push(item.value);
				}
				else{
					bank_data[item.name] = item.value;
				}
			})
			var that = this;
			bank_validator.validateData(bank_data, function(errors,data){
				if(errors !== null){
					$.each(errors,function(index,error){
						$('#'+error.target).html(error.message);
						error_message(error.message);
					});
					$(that).attr('data-state', 'errored');
					setTimeout(function(){
						$(that).attr('data-state', 'waiting');
					}, 1000);
				}
				else{
					var to_send = {};
					var dd_data = {};
					$.each(data, function(i, item){
						dd_data[item.field] = item.value;
					});
					to_send['dd_data'] = dd_data;
					to_send['order_id'] = order_id;
					//disable button and make a spinny
					$(that).prop('disabled', true);
					$(that).attr('data-state', 'loading');
					makeRequest('/order/new/dd-account', to_send, function(response){
						if(isset(response.success) && response.success){
							// stop spinny and tell user we love life
							$(that).attr('data-state', 'confirmed');
							if(isset(response.html)){
								$(modal).find('.existing-payment-details-section').html(response.html)
							}
						}
						else{
							var error = 'unable to update dd details';
							if(isset(response.error)){
								error = response.error;
							}
							if(isset(response.errors)){
								$.each(response.errors, function(element, server_error){
									if($('#'+element).length){
										$('#'+element).text(server_error);
									}
									error = server_error;
								});
							}
							$(that).attr('data-state', 'errored')
						}
						$(that).prop('disabled', false);
						setTimeout(function(){
							$(that).attr('data-state', 'waiting');
						}, 1000);
					});
				}
			});
		}
	});

	$('#modal_container').on('click', '#submit-payment-day', function(){
		var modal = $(this).closest('.manage-dd-modal');
		var order_id = $(modal).data('order-id');

		var data = {};
		data['order_id'] = order_id;

		var payment_day = $(modal).find('#payment-date').val();


		if(payment_day > 0 && payment_day < 29){
			//loving life
			data['payment_day'] = payment_day;
			$(this).prop('disabled', true);
			$(this).attr('data-state', 'loading');
			var that = this;
			makeRequest('/order/update/dd-payment-day', data, function(response){
				if(isset(response.success) && response.success){
					// stop spinny and tell user we love life
					$(that).attr('data-state', 'confirmed');
					if(isset(response.html)){
						$(modal).find('.existing-payment-details-section').html(response.html)
					}
				}
				else{
					var error = 'unable to update payment day';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
					$(that).attr('data-state', 'errored')
				}
				$(that).prop('disabled', false);
				setTimeout(function(){
					$(that).attr('data-state', 'waiting');
				}, 1000);
				// hideLoader();
			});

		}
		else{
			error_message('please enter a valid payment date');
		}
	});
	
	$('#modal_container').on('click', '.resend-email-confirmation', function(){
		var modal = $(this).closest('.confirmation-preview-modal');
		var order_id = $(this).data('order-id');

		var data = {
			'order_id' : order_id
		};
		showLoader();
		
		makeRequest('/order/confirmation/resend', data, function(response){
			if(isset(response.success) && response.success){
				success_message();
				$(modal).modal('hide');
			}
			else{
				var error = 'unable to resend confirmation';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});
	});

	$('body').on('click', '.remove-order-item', function () {
		let sales_order_item_id = $(this).data('order-item-id')
		buildDynamicModal('remove_order_item_modal', {
			sales_order_item_id: sales_order_item_id
		}, function() {
			removeOrderItem();
		});
	});

	$('#modal_container').on('click', '#force_auto_switch_confirm', function(){
		var modal = $(this).closest('.force-auto-switch-modal');
		var order_id = $(modal).data('order-id');
		var textarea = $(modal).find('#force_notes');
		$(textarea).removeClass('red-border-1');
		var force_notes = $(textarea).val();
		if(!force_notes.length){
			$(textarea).addClass('red-border-1');
			error_message('please add a reason');
			return false;
		}
		var data = {
			'order_id' : order_id,
			'force_notes' : force_notes
		};
		showLoader();
		
		makeRequest('/order/auto-renewal/force', data, function(response){
			if(isset(response.success) && response.success){
				success_message();
				$(modal).modal('hide');
			}
			else{
				var error = 'unable to create force event';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});
	});

	$('.payment-details-tabs').on('click','.payment-details-tab',function(){
		var target = $(this).data('target');
		var parent = $(this).closest('.order-information-summary');
		$(parent).find('.payment-details-tabs .payment-details-tab').removeClass('active');
		$(parent).find('.payment-details-tab-content').hide();
		$(parent).find('#'+target).show();
		$(this).addClass('active');
	});

	var trigger_order_documents_upload = function(container,order_id){
		makeRequest('/files/order/get-file-upload-modal-content',{},function(response){
			if(isset(response.success) && response.success){
				$('#salesOrderDocumentUploadModal').find('.modal-content').html(response.html);
				$("div#sales-order-documents-dropzone").dropzone({
					 url: "/files/order/upload-documents/"+order_id,
					 'autoProcessQueue':false,
					 'uploadMultiple':true,
					 'parallelUploads':10,
					 'addRemoveLinks':true,
					 headers: {
     				   'X-CSRF-TOKEN': $('body').data("token")
    				},
					init: function() {
						var sales_order_upload = this;
						// First change the button to actually tell Dropzone to process the queue.

						$('#upload_sales_order_documents').on('click',function(e){
							showLoader();
						// Make sure that the form isn't actually being sent.
							e.preventDefault();
							e.stopPropagation();
							sales_order_upload.processQueue();
						});
						sales_order_upload.on("successmultiple", function(multiple) {
							hideModal('salesOrderDocumentUploadModal');
							hideLoader();
							$('#salesOrderDocumentUploadModal').find('.modal-content').html('');
							// rebuild order details documents panel
							makeRequest('/files/order/get-order-documents-content/'+order_id,{},function(response){
								if(isset(response.success) && response.success){
									$(container).closest('.order-documents-section').find('.order-documents-content').html(response.html);
									success_message('documents uploaded');
								}
								else{
									if(isset(response.error)){
										error_message(response.error);
									}
									else{
										error_message('something went wrong, please speak to IT');
									}
								}
							},'GET');
						});
					},
					'accept' : function(file, done) {
						$('.instruction-to-drop').hide();
						$('.dz-success-mark').hide();
						$('.dz-error-mark').hide();
						let html_preview = '';
						if(file.type == 'application/pdf'){
							html_preview = '<div class="file-icon-container"><i class="fa-solid fa-file-pdf large-icons"></i></div>';
						}
						else if (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
							html_preview = '<div class="file-icon-container"><i class="fa-solid fa-file-spreadsheet large-icons"></i></div>';
						}
						else if(file.type == 'text/csv'){
							html_preview = '<div class="file-icon-container"><i class="fa-solid fa-file-csv large-icons"></i></div>';
						}
						else if(file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
							html_preview = '<div class="file-icon-container"><i class="fa-solid fa-file-word large-icons"></i></div>';
						}
						if(html_preview.length){
							$(file.previewElement).find('.dz-image').html(html_preview);
						}
						return done();
					}				
					
				});
				showModal('salesOrderDocumentUploadModal');
			}
		},'GET');
	}

	$('.order-information-summary').on('click','.upload-sales-order-documents',function(){
		let container = this;
		let order_id = $(this).data('order-id');
		trigger_order_documents_upload(container,order_id);
	});

	$('#lead_script').on('click','.upload-sales-order-documents',function(){
		let container = this;
		let order_id = $(this).data('order-id');
		trigger_order_documents_upload(container,order_id);
	});

	$('.order-documents-section').on('click','.file-edit-container',function(){
		let order_id = $(this).data('order_id');
		let file_id = $(this).data('file_id');
		// do a quick round trip for the contents
		makeRequest('/files/order/get-file-update-modal-content/'+order_id+'/'+file_id,{},function(response){
			if(isset(response.success) && response.success){
				$('#salesOrderDocumentEditModal').find('.modal-content').html(response.html);
				showModal('salesOrderDocumentEditModal');
			}
			
		},'GET');
	});

	$('#lead_script').on('click','.file-edit-container',function(){
		let order_id = $(this).data('order_id');
		let file_id = $(this).data('file_id');
		// do a quick round trip for the contents
		makeRequest('/files/order/get-file-update-modal-content/'+order_id+'/'+file_id,{},function(response){
			if(isset(response.success) && response.success){
				$('#salesOrderDocumentEditModal').find('.modal-content').html(response.html);
				showModal('salesOrderDocumentEditModal');
			}
			
		},'GET');
	});

	$('#salesOrderDocumentEditModal').on('click','#delete_sales_order_document',function(e){
		e.preventDefault();
		let form = $(this).closest('form').serializeArray();
		let order_id = $(this).closest('form').find('input[name="order_id"]').val();
		if(confirm('Are you REALLY sure you want to do this?')){
			makeRequest('/files/order/delete-existing-file',form,function(response){
				if(isset(response.success) && response.success){
					success_message('file deleted');
					hideModal('salesOrderDocumentEditModal');
					// find the original section to replace the content
					makeRequest('/files/order/get-order-documents-content/'+order_id,{},function(response){
						if(isset(response.success) && response.success){
							$('.order-documents-section[data-order_id="'+order_id+'"]').find('.order-documents-content').html(response.html);
						}
					},'GET');
				}
			});
		}
	});

	$('#salesOrderDocumentEditModal').on('click','#update_sales_order_documents',function(e){
		e.preventDefault();
		let form = $(this).closest('form').serializeArray();
		let order_id = $(this).closest('form').find('input[name="order_id"]').val();
		makeRequest('/files/order/update-existing-file',form,function(response){
			if(isset(response.success) && response.success){
				success_message('file updated');
				hideModal('salesOrderDocumentEditModal');
				// find the original section to replace the content
				makeRequest('/files/order/get-order-documents-content/'+order_id,{},function(response){
					if(isset(response.success) && response.success){
						$('.order-documents-section[data-order_id="'+order_id+'"]').find('.order-documents-content').html(response.html);
					}
				},'GET');
			}
		});
	});
	

});



var removeOrderItem = function () {
	$('body').on('click', '#remove-order-item-confirm', function () {
		let sales_order_item_id = $(this).data('order-item-id')
		makeRequest('/order/remove-item', {
			sales_order_item_id: sales_order_item_id
		}, function (response) {
			if(isset(response.success) && response.success) {
				success_message();
				window.location.replace(window.location.href);
				return;
			}
			if(isset(response.error)) {
				error_message();
				return;
			}
			error_message('Unknown error occurred');
		});
	});
}
    
