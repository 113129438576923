/*
* //// api specific functions 
*/
function apiInterface () {
	var that = this;
	this.set_postcode = function(quote_id,data,callback){
		$.ajax({ cache: false,
			url: "/api/setpostcode/"+quote_id,
			type:"POST",
			dataType: 'json',
			data: data,
			success: function (response) {
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
	},
	this.postcode_lookup = function(postcode,callback){
		$.ajax({ 
			cache: false,
			url: "/address/neutral-address-lookup/"+postcode,
			type:"GET",
			dataType: 'json',
			success: function (response) {
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
	},				
	this.is_valid_postcode = function(postcode){
		return postcode.length > 4 ? true : false; // disabled due to central london postcodes
		postcode = postcode.replace(/\s/g, "");
		var regex = /^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i;
		return regex.test(postcode);
	},
	this.update_region = function(quote_id,region_id,callback){
		$.ajax({ cache: false,
			url: "/api/updateregion/"+quote_id,
			type:"POST",
			dataType: 'json',
			data: { region_id : region_id},
			success: function (response) {
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
	},
	this.loadview = function(step,callback){
		$.ajax({
			url: "/api/loadview/"+step,
			success : function(response){
				callback(JSON.parse(response));
			},
			error : function(error) {
				//console.log(error);
			}

		});
	},
        this.updateHomeowner = function(quote_id,value,source,callback){
		var that = this;
		$.ajax({
			url: "/api/updatehomeowner/"+quote_id,
			data: {value : value,source:source},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				that.throwError(error);
			}		
		});
	},
	this.updateCurrentSupplier = function(quote_id,value,source,callback){
		var that = this;
		$.ajax({
			url: "/api/updatecurrentsupplier/"+quote_id,
			data: {value : value,source:source},
 			type:"POST",
 			dataType: 'json',
 			success: function(response){

 				if(response.errors){
 					that.throwError(response.errors);
 				}else{
 					callback(response);
 				}
 			},
 			error : function(error) {
 				that.throwError(error);
 			}
		});
	},          
    this.updateHomeowner = function(quote_id,value,source,callback){
		var that = this;
		$.ajax({
			url: "/api/updatehomeowner/"+quote_id,
			data: {value : value,source:source},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				that.throwError(error);
			}		
		});
	},          
	this.updateBoilerCover = function(quote_id,value,source,callback){
		var that = this;
		$.ajax({
			url: "/api/updateboilercover/"+quote_id,
			data: {value : value,source:source},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				that.throwError(error);
			}		
		});
	},
	this.throwError = function(error){
		if(typeof(error) == 'object' && error.length){
			if(isset(error[0]) && isset(error[0].group) && isset(error[0].message) && isset(error[0].message.text)){
				error = error[0].group + ' ' + error[0].message.text;
			}
			else{
				var message = new MessageBox('errorDialog', 'An unknown error occured : ' + JSON.stringify(error) );
				message.setData({});
				message.run();
				$('.modal-footer').hide();
				return;
			}
		}
		var message = error;
		var message = new MessageBox('errorDialog', message);
		message.setData({});
		message.run();
		$('.modal-footer').hide();
	},
	this.updateCurrentTariff = function(quote_id,value,source,callback){
		var that = this;
		$.ajax({
			url: "/api/updatecurrenttariff/"+quote_id,
			data: {value : value,source:source},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					if(response.variable_end_date == 1){
						$('#tariff_end_date_block-'+source).show();
					}
					else{
						$('#tariff_end_date_block-'+source).hide();
					}
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.updateCurrentPaymentOption = function(quote_id,value,source,callback){
		var that = this;
		$.ajax({
			url: "/api/updatecurrentpaymentoption/"+quote_id,
			data: {value : value,source:source},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.updateCurrentUsage = function(quote_id,values,source, callback = function(){}){
		var that = this;
		$.ajax({
			url: "/api/updatecurrentsupply/"+quote_id,
			data: {value : values,source:source},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
					var error_string = '';
					if(typeof(response.errors) == 'object' && response.errors.length){

						$.each(response.errors, function(i, error){
							if(i > 0){
								error_string += '<br>';
							}
							error_string += error;
						})
					}
					else{
						error_string = response.errors
					}
					$('.current-supply-errors').html(error_string).show();
				}else{
					callback(response);
					$('.current-supply-errors').hide();
				}
				
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.optionChanged = function(quote_id,key,value,callback){
		var that = this;
		$.ajax({
			url: "/api/optionchanged/"+quote_id,
			data: {key : key, value : value},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.updateOrder = function(quote_id,data,callback){
		var that = this;
		$.ajax({
			url: "/api/updateorder/"+quote_id,
			data: {values:data},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.updateUsage = function(quote_id,values,callback){
		var that = this;
		$.ajax({
			url: "/api/updateusage/"+quote_id,
			data: {data:values},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.sendCurrentSupplyData = function(quote_id){
		var that = this;
		$.ajax({
			url: "/api/current_supply/"+quote_id,
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					return true;
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.sendCurrentUsageData = function(quote_id,callback){
		var that = this;
		$.ajax({
			url: "/api/current_usage/"+quote_id,
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback();
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.sendDetailedEstimate = function(quote_id,values,callback){
		var that = this;
		$.ajax({
			url: "/api/detailed-usage-estimate/"+quote_id,
			data: {data:values},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback({success:true,errors:0,response:response});
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.sendSimpleUsage = function(quote_id,values,callback){
		var data = {
			'data' : values
		}
		makeRequest("/api/set-simple-usage/"+quote_id, data, callback)
	},
	this.sendNotSoSimpleUsage  = function(quote_id,values,callback){
		var data = {
			'data' : values
		}
		makeRequest("/api/set-no-so-simple-usage/"+quote_id, data, callback)
	},
	this.get_available_tarrifs = function(quote_id,callback){
		var that = this;
		$.ajax({
			url: "/api/available_tariffs/"+quote_id,
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.get_tariff_details = function(quote_id,tariff_id,json,callback){
		var that = this;
		$.ajax({
			url: "/api/tariff/details/"+tariff_id,
			dataType: 'json',
			type:"POST",
			data: {data:json,quote_id:quote_id},
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
			
	this.getTariffPitchText = function(data, callback){
		var that = this;
		$.ajax({
			url: "/api/tariff/pitch-text",
			dataType: 'json',
			type:"POST",
			data: data,
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
		
	},

	this.getPriceAlertPitchText = function(data, callback){
		var that = this;
		$.ajax({
			url: "/api/tariff/pitch-text-price-alert",
			dataType: 'json',
			type:"POST",
			data: data,
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
		
	},
			
	this.getUnlimitedTariffPitchText = function(data, callback){
		var that = this;
		$.ajax({
			url: "/api/tariff/pitch-text-unlimited",
			dataType: 'json',
			type:"POST",
			data: data,
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
		
	},
	
	this.sms_tariff_details = function(quote_id,short_code,number,callback){
		var that = this;
		$.ajax({
			url: "/sms/tariff/details/"+quote_id,
			dataType: 'json',
			type:"POST",
			data: {number:number,short_code:short_code},
			success: function(response){
				//console.log(response);
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.email_tariff_details = function(quote_id,short_code,email,callback){
		var that = this;
		$.ajax({
			url: "/email/tariff/details/"+quote_id,
			dataType: 'json',
			type:"POST",
			data: {email:email,short_code:short_code},
			success: function(response){
				//console.log(response);
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.set_supply_preferences = function(quote_id,values,callback){
		var that = this;
		$.ajax({
			url: "/api/supply_preference/"+quote_id,
			data: {data:values},
			type:"POST",
			dataType: 'json',
			success: function(response){
				//console.log(response);
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.set_tsm_payment_override = function(quote_id,values,callback){
		var that = this;
		$.ajax({
			url: "/api/set_tsm_payment_override/"+quote_id,
			data: {data:values},
			type:"POST",
			dataType: 'json',
			success: function(response){
				//console.log(response);
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},

	this.set_tariff_selection = function(quote_id,tariff_id,brand_id,json,callback){
		var that = this;
		$.ajax({
			url: "/api/set_tariff_selection/"+quote_id,
			data: {tariff_id:tariff_id,brand_id:brand_id,json:json},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},


	this.add_price_alert = function(quote_id, callback){
		var that = this;
		$.ajax({
			url: "/api/add_price_alert/" + quote_id,
			dataType: 'json',
			type:"POST",
			data: {},
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
		
	},

	this.set_customer_details = function(quote_id,data,callback){
		var that = this;
		makeRequest("/api/set_customer_details/"+quote_id, {details:data}, function(response){
			if(response.errors){
				that.throwError(response.errors);
			}else{
				callback(response);
			}
		});
	},
	
	this.get_address_by_id = function(quote_id,data,callback){
		var that = this;
		makeRequest("/api/get_address_by_id/"+quote_id, data, function(response){
			if(response.errors){
				that.throwError(response.errors);
			}
			else{
				callback(response);
			}
		});
	},
	this.confirm_address_selection = function(quote_id,data,callback){
		var that = this;
		makeRequest("/api/confirm_address_selection/"+quote_id, {'data':data}, function(response){
			if(response.errors){
				var error_message = 'An unknown error occurred';
				if(response.errors[0].message){
					error_message =response.errors[0].message;
				}
				else if(response.errors[0]){
					error_message = response.errors[0];
				}
				that.throwError(error_message);
			}
			else if(response.error){
				that.throwError(response.error);
			}
			else{
				callback(response);
			}
		});
	},
	this.confirm_manual_address = function(quote_id,data,callback){
		var that = this;
		$.ajax({
			url: "/api/confirm_manual_address/"+quote_id,
			data: {data:data},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors[0].message);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.setSmartMeter = function(quote_id,selection,callback){
		var that = this;
		$.ajax({
			url: "/api/set_smart_meter/"+quote_id,
			data: {selection:selection},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.setPriorityRegister = function(quote_id,selection,callback){
		var that = this;
		$.ajax({
			url: "/api/set_priority_register/"+quote_id,
			data: {selection:selection},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.send_bank_details = function(quote_id,data,callback){
		var that = this;
		$.ajax({
			url: "/api/set_bank_details/"+quote_id,
			data: {data:data},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					hideLoader();
					if(typeof(response.errors) == 'object'){
						$.each(response.errors, function(element, error){
							if($('#'+element).length){
								$('#'+element).text(error);
							}
						});
						error_message('please check the information provided and try again');
					}
					else{
						that.throwError(response.errors);
					}
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
			
	this.use_stored_bank_details = function(quote_id,data,callback){
		var that = this;
		$.ajax({
			url: "/api/use_stored_bank_details/"+quote_id,
			data: {data:data},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					hideLoader();
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.create_will_payment_plan = function(quote_id,data,callback){
		var that = this;
		$.ajax({
			url: "/quote/set_bank_details-for_payment_plan/"+quote_id,
			data: {data:data},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					if(typeof(response.errors) == 'object'){
						$.each(response.errors, function(element, error){
							if($('#'+element).length){
								$('#'+element).text(error);
							}
						});
						error_message('please check the information provided and try again');
					}
					else{
						that.throwError(response.errors);
					}
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.create_will_direct_debit_deposit = function(quote_id,data,callback){
		var that = this;
		$.ajax({
			url: "/quote/set_bank_details_for_dd_deposit/"+quote_id,
			data: {data:data},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					if(typeof(response.errors) == 'object'){
						$.each(response.errors, function(element, error){
							if($('#'+element).length){
								$('#'+element).text(error);
							}
						});
						error_message('please check the information provided and try again');
					}
					else{
						that.throwError(response.errors);
					}
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.create_will_finance_agreement = function(quote_id,data,callback){
		var that = this;
		$.ajax({
			url: "/quote/set_bank_details-for_finance_agreement/"+quote_id,
			data: {data:data},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					if(typeof(response.errors) == 'object'){
						$.each(response.errors, function(element, error){
							if($('#'+element).length){
								$('#'+element).text(error);
							}
						});
						error_message('please check the information provided and try again');
					}
					else{
						that.throwError(response.errors);
					}
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	},
	this.create_will_payment_plan_with_existing_details = function(quote_id,data,callback){
		var that = this;
		$.ajax({
			url: "/quote/use_stored_bank_details_for_payment_plan/"+quote_id,
			data: {data:data},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	};
	this.create_will_direct_debit_deposit_with_existing_details = function(quote_id,data,callback){
		var that = this;
		$.ajax({
			url: "/quote/use_stored_bank_details_for_dd_deposit/"+quote_id,
			data: {data:data},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	};
	this.create_will_finance_agreement_with_existing_details = function(quote_id,data,callback){
		var that = this;
		$.ajax({
			url: "/quote/use_stored_bank_details_for_finance_agreement/"+quote_id,
			data: {data:data},
			type:"POST",
			dataType: 'json',
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	};
	this.confirmSwitch = function(quote_id, data, callback){
		var that = this;
		$.ajax({
			url: "/api/confirm_switch/"+quote_id,
			type:"POST",
			dataType: 'json',
			data: data ,
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	};
	
	this.reprocess_order = function(quote_id, data, callback){
		var that = this;
		$.ajax({
			url: "/api/reprocess_order/"+quote_id,
			type:"POST",
			dataType: 'json',
			data: data ,
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	};
	this.resolve_order = function(quote_id, data, callback){
		var that = this;
		$.ajax({
			url: "/api/resolve_order/"+quote_id,
			type:"POST",
			dataType: 'json',
			data: data ,
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	};
	
	this.cant_help_order = function(quote_id, data, callback){
		var that = this;
		$.ajax({
			url: "/api/cant_help_order/"+quote_id,
			type:"POST",
			dataType: 'json',
			data: data ,
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	};
	
	this.mark_confirmed = function(quote_id, data, callback){
		var that = this;
		$.ajax({
			url: "/api/mark_cofirmed/"+quote_id,
			type:"POST",
			dataType: 'json',
			data: data ,
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	};
	
	this.boiler_cover_fact_find = function(quote_id, data, callback){
		var that = this;
		$.ajax({
			url: "/api/boiler-cover-fact-find/"+quote_id,
			type:"POST",
			dataType: 'json',
			data: data ,
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	};
	
	this.confirmOrders = function(data, callback){
		var that = this;
		$.ajax({
			url: "/api/confirm-orders",
			type:"POST",
			dataType: 'json',
			data: data ,
			success: function(response){
				if(isset(response.success) && response.success){
					callback(response);
				}else{
					if(isset(response.errors)) {
						that.throwError(response.errors);
						hideLoader();
						callback(response);
						return;
					}
					that.throwError('Please Contact IT');
					hideLoader();
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	};
	
	this.confirmApprovedOrders = function(data, callback){
		var that = this;
		makeRequest('/api/confirm-approved-orders', data, function(response){
			if(response.errors){
				that.throwError(response.errors);
			}
			else{
				callback(response);
			}
		})
	};
	
	this.boiler_cover_skip = function(quote_id, data, callback){
		var that = this;
		$.ajax({
			url: "/api/boiler-cover-skip/"+quote_id,
			type:"POST",
			dataType: 'json',
			data: data ,
			success: function(response){
				if(response.errors){
					that.throwError(response.errors);
				}else{
					callback(response);
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});
	};
	
	
	
}
module.exports = apiInterface;
