
var keys_to_display_in_script = {
	'MPRN1' : 'gas',
	'MPAN1': 'electricity'
};

var values_to_submit = {};

var tryToSetMeterNumbers = function(meter_numbers){
	var result_string = '';
	$.each(meter_numbers, function(fuel, number){
		if($('#' +fuel + '_meter_number').length){
			$('#' +fuel + '_meter_number').val(number);
			result_string += fuel + ' meter number found';
		}
	});
	if(result_string.length){
		success_message(result_string);
	}
	else{
		error_message('missing meter numbers not found');
	}
}

window.getAddressDetails = function(id){

	var data = {
		'address_id' : id 
	};
	$('#address_detail_results').html('');
	$('li.address-result-item.active').children().remove();
	makeRequest('/admin/meter-search/address-details', data, function(response){
		if(isset(response.Id)){
			$('#address_detail_results').html('');
			var meter_numbers_to_set = {};
			$.each(response, function(key, value){
				if(value.length){
					if($('.script-meter-number-search').length){
						//we are in a script -- with a swamp donkey -- need to hide the scary shit
						if(isset(keys_to_display_in_script[key])){
							$('li.address-result-item.active').append('<label class="bold color-white display-block" data-key="' + key + '" data-value="' + value + '">' + key + '</label><div>' + value+ '</div>' );
							meter_numbers_to_set[keys_to_display_in_script[key]] = value;
						}
					}
					else{
						$('#address_detail_results').append('<label class="bold">' + key + '</label><div>' + value+ '</div>' );
					}
				}
			});
			if(Object.keys(meter_numbers_to_set).length){
				tryToSetMeterNumbers(meter_numbers_to_set);
			}
		}
	});
}

$(function () {
	$('#meter_search_container').on('click','.rebel-energy-postcode-search-button',function(){
		$('.rebel-energy-address-results').html('');
		$('.rebel-energy-address-results').addClass('hide');
		var container = $(this).closest('.rebel-energy-postcode-search');
		var postcode = $(container).find('input[name="rebel_postcode"]').val();
		var data = {
			postcode : postcode
		};
		if(postcode.length){
			makeRequest('/admin/meter-search/rebel-postcode-search', data, function(response){
				if(isset(response.success) && response.success){
					$('.rebel-energy-address-results').html(response.html);
					$('.rebel-energy-address-results').removeClass('hide');
				}
			});
		}
	});
	$('#meter_search_container').on('change','.rebel-energy-address-results select',function(){
		var address_id = $(this).val();
		if(address_id.length){
			var data = {
				address_id : address_id
			};
			makeRequest('/admin/meter-search/rebel-address-meter-search', data, function(response){
				if(isset(response.success) && response.success){
					$('#address_detail_results').html(response.html);
				}
			});
		}
	});

});

$(document).on('ready', function(){

	$('#lead_script').on('click', '#submit_script_meter_numbers', function(){
		
		var inputs = $('.meter_number_search_meter_input');

		var data = {
			'quote_id' : lead_script.quoteId,
			'meter_numbers' : {}
		};

		var warnings = [];
		$.each(inputs, function(i, input){
			var input_value = $(input).val();
			if(input_value){
				data['meter_numbers'][$(input).attr('name')] = input_value;
			}
			else{
				warnings.push('no ' + $(input).attr('name').replace(/_/g, ' ') + ' given');
			}	

		});


		var confirmation_message = '';
		if(Object.keys(data['meter_numbers']).length){
			confirmation_message = 'You are about to manually set meter numbers for this customer, please be sure they are correct, getting this wrong would result in an <span style="color:red;font-weight:bold"> Erroneous Transfer </span> and a fine for both us and the supplier.<br/>';
			confirmation_message += 'You are about to submit : <br/>';
			$.each(data['meter_numbers'], function(name, value){
				confirmation_message += name.replace(/_/g, ' ') + ' : <b>' + value + '</b><br/>';
			});
		}
		else{
			confirmation_message = '<span style="color:red" >Please be advised that your switch will be put on hold until we are able to confirm these meter details. Someone from our customer care team may get in touch with you for further information.</span><br/>';
		}


		$.each(warnings, function(i, warning){
			confirmation_message += warning + ' <br/>';		
		});


		var that = $(this);
		customConfirm(confirmation_message, function(){
			
			makeRequest('/meter-search/script-submit', data, function(response){
				if(isset(response.success) && response.success){
					window.lead_script.handleStep($(that).data('success-step'));
				}
				else{
					var error = isset(response.error) ? response.error : 'unable to set meter numbers';
					error_message(error);
				}
			})
		})
	});
	
	$('#lead_script').on('change', '#meter_search_address_select', function(){

		var address_id = $(this).val();

		var data = {
			'address_id' : address_id
		};
		showLoader();
		makeRequest('/meter-search/match-address', data, function(response){
			if(isset(response.success) && response.success){
				if(isset(response.meter_numbers) && Object.keys(response.meter_numbers).length){
					tryToSetMeterNumbers(response.meter_numbers);
				}
				else{
					error_message('no meter numbers found');
				}
			}
			else{
				var error = isset(response.error) ? response.error : 'unable to retrieve data for address';
				error_message(error);
			}
			hideLoader();
		});
	});

	$('body').on('submit', 'form.meter-form', function(e){
		e.preventDefault();
	})

	$('body').on('click', '#meter_number_search', function(e){
		e.preventDefault();
		var form = $(this).closest('form');
		var data = form.serializeArray();

		var usable_data = {};
		$.each(data, function(i, sub_data){
			if(sub_data.value.length){
				usable_data[sub_data.name] = sub_data.value;
			}
		});

		if(Object.keys(usable_data).length){
			showLoader()
			makeRequest('/admin/meter-search/meter-details', usable_data, function(response){
				hideLoader();
				$('form.meter-form div.address-results-container ul.address-results li').remove();
				$('.script-meter-number-search div.address-results-container ul.address-results li').remove();
                
                $.each(response, function(index, item){                
                    var element = document.createElement('li');
					$(element).addClass('address-result-item');
					if($('.script-meter-number-search').length){
						$('.script-meter-number-search div.address-results-container ul.address-results').append(element);
					}
					else{
						$('form.meter-form div.address-results-container ul.address-results').append(element);
					}
                    $(element).data('id', item.Id);
                    $(element).attr('data-id', item.Id);
                    $(element).data('type', item.Type);
                    $(element).attr('data-type', item.Type);
                    $(element).data('text', item.Text);
                    $(element).attr('data-text', item.Text);
                    $(element).data('description', item.Description);
                    $(element).attr('data-description', item.Description);
                    var postcode = item.Description.split(', ').pop();
                    $(element).data('postcode', postcode);
                    $(element).attr('data-postcode', postcode);
                    $(element).text(item.Text + ', ' + item.Description);
                    
				});
				$('.address-results-container').renmoveClass('hide');
				if(response.length == 1){
					if($('.script-meter-number-search').length){
						$('.script-meter-number-search div.address-results-container ul.address-results li').trigger('click');
					}
					else{
						$('form.meter-form div.address-results-container ul.address-results li').trigger('click');
					}
				}
			})
		}
		else{
			error_message('no search criterea given');
		}
	});

});