$(document).ready(function () {

    var quote_id = $('#lead_script').data("quoteid");


    var make_request = function (url, data, button, next_step) {
        $.ajax({
            cache: false,
            url: url,
            type: "POST",
            dataType: 'json',
            data: data,
            success: function (response) {
                if (response.errors == null) {
                    window.lead_script.dynamicStep(button, next_step);
                }
                else {
                    //error	
                    if (typeof (response.errors) == 'string') {
                        error_message(response.errors);
                    }
                    else {
                        $.each(response.errors, function (key, error) {
                            if ($('#' + error.target).length) {
                                $('#' + error.target).html(error.message);
                            }
                            else {
                                $('#error-other').append(error.message.text + '<br/>');
                            }
                        });
                    }
                }
            },
            error: function (error) {
                loaderClear();
                error_message('an unknown error occurred');
            }
        });
    }

    var recalculateTarget = function (target, percent_or_pound) {

        target = parseFloat(target);

        var include_exit_fees = $('input[name="exit_fees_included"]:checked').val();

        include_exit_fees = include_exit_fees == 'true' ? true : false;

        var total_exit_fees = 0;
        if (include_exit_fees) {
            total_exit_fees = parseFloat($('#target_saving_pound').data('total-exit-fees'));
        }

        var personal_projection = parseFloat($('#target_saving_pound').data('personal-projection').replace(/,/g, ''));

        var new_target_percent = 10;
        var new_target_pound = 10;
        if (percent_or_pound == 'percent') {
            new_target_percent = target;
            new_target_pound = parseFloat(((target / 100) * personal_projection).toFixed(2));
        }
        else {
            new_target_pound = target;
            new_target_percent = (new_target_pound / personal_projection) * 100;
        }

        new_target_percent = Math.round(new_target_percent);

        $('#target_saving_pound').val(new_target_pound);
        $('#target_saving_pound_input').val(new_target_pound);
        $('#target_saving_percent').val(new_target_percent);

        var total_saving = new_target_pound + total_exit_fees;

        $('#total_saving').text(total_saving.toFixed(2));


    }

    $('#lead_script').on('input', '#target_saving_pound', function () {

        recalculateTarget($(this).val(), 'pound');

    });

    $('#lead_script').on('click', 'input[name="exit_fees_included"]', function () {

        recalculateTarget($('#target_saving_pound_input').val(), 'pound');

        if($(this).val() == 'true'){
            $('#exit_fees_text').css('opacity', 1);
            $('#exit_fees_text').css('text-decoration', 'none');
        }
        else{
            $('#exit_fees_text').css('opacity', 0.5);
            $('#exit_fees_text').css('text-decoration', 'line-through');
        }

    });

    $('#lead_script').on('input keyup', '#target_saving_percent', function () {

        recalculateTarget($(this).val(), 'percent');

    });

    $('#lead_script').on('input keyup', '#target_saving_pound_input', function () {

        recalculateTarget($(this).val(), 'pounds');

    });





    $('#lead_script').on('click', '#make_new_price_alert', function () {

        var percentage = parseInt($('#target_saving_pound_input').val());

        if (percentage < 1 || percentage > 1000) {

            error_message('please enter a sensible target saving, > 1 and < 1000');
            return;
        }

        var data = {};

        var include_exit_fees = $('input[name="exit_fees_included"]:checked').val();

        include_exit_fees = include_exit_fees == 'true' ? true : false;

        var target_saving = parseFloat($('#target_saving_pound_input').val());

        data.include_exit_fees = include_exit_fees;
        data.target_saving = target_saving;
        data.quote_id = quote_id;
        var that = $(this);

        make_request('/price-alerts/create', data, that, 'price-alert-energy.customer_details');
    });


    $('#lead_script').on('click', '#price-alert-customer-details-continue', function () {
        var customer_validator = new customerValidator();

        var that = $(this);

        var validation = [
            function (next) { customer_validator.validateTitle($('#lead_script #customer-details-title').val(), next); },
            function (next) { customer_validator.validateFirstName($('#lead_script #customer-details-firstname').val(), next); },
            function (next) { customer_validator.validateLastName($('#lead_script #customer-details-lastname').val(), next); },
            function (next) {
                customer_validator.validateDOB(
                    $('#lead_script #customer-details-dob-day').val(),
                    $('#lead_script #customer-details-dob-month').val(),
                    $('#lead_script #customer-details-dob-year').val()
                    , next);
            },
            function (next) { customer_validator.validatePhone($('#lead_script #customer-details-phone').val(), next); },
            function (next) { customer_validator.validateEmail($('#lead_script #customer-details-email').val(), next); },
        ];
        customer_validator.validateAll(validation, function (errors, data) {
            if (errors !== null) {
                $.each(errors, function (index, error) {
                    $('#lead_script #' + error.target).html(error.message);
                });
            }
            else {

                var usable_data = {};
                $.each(data, function (key, value) {
                    usable_data[value.field] = value.value;
                });
                usable_data.quote_id = quote_id;

                make_request('/price-alerts/set-customer-details', usable_data, that, 'price-alert-energy.address');
                refeshContactDetails();
            }
        });
    });

    $('#lead_script').on('click', '#price-alert-confirm-address-continue', function () {
        var address_validator = new addressValidator();
        var that = this;
        var data = {};
        $('.error-message').html('');

        if ($('#energy-address-select').is(':visible')) {

			if (!$('#selected-energy-address-id').val()) {
				error_message('please select an addresss');
            }

            data.address_id = $('#selected-energy-address-id').val();
            data.quote_id = quote_id;

            if ($('#meter_numbers').is(":visible")) {
                // we have a need to capture meter numbers 
                if ($('#meter_numbers #gas_meter').length) {
                    data.gas_meter = $('#meter_numbers #gas_meter').val();
                }
                if ($('#meter_numbers #electricity_meter').length) {
                    data.electricity_meter = $('#meter_numbers #electricity_meter').val();
                }
            }
            if ($('#hidden_meter_numbers').is(":visible")) {
                if ($('#hidden_meter_numbers #gas_meter').length) {
                    data.gas_meter = $('#hidden_meter_numbers #gas_meter').val();
                }
                if ($('#hidden_meter_numbers #electricity_meter').length) {
                    data.electricity_meter = $('#hidden_meter_numbers #electricity_meter').val();
                }
            }
            make_request('/price-alerts/select-address', data, that, 'price-alert-energy.dd');
        }
        else if ($('#energy-address-manual').is(':visible')) {
            address_validator.validateManualAddress(function (errors, data) {
                if (errors !== null) {
                    $.each(errors, function (index, error) {
                        if ($('#' + error.target).length) {
                            $('#' + error.target).html(error.message);
                        }
                        else {
                            $('#error-other').html(error.message.text);
                        }
                    });
                }
                else {

                    var usable_data = {};
                    $.each(data, function (key, value) {
                        usable_data[value.field] = value.value;
                    });
                    usable_data.quote_id = quote_id;

                    make_request('/price-alerts/manual-address', usable_data, that, 'price-alert-energy.dd');
                }
            });
        }

    });

    $('#lead_script').on('click', '#price-alert-energy-direct-debit-completed', function () {
        var bank_validator = new bankValidator();
        $(".error-notice").html('');
        var that = $(this);

        if ($('#using-stored-details').val()) {

            var data = {};
            data['account_id'] = $('#account-history').val();
            data['payment-date'] = $('#payment-date').val();
            data['quote_id'] = quote_id;

            make_request('/price-alerts/used-stored-dd', data, that, 'price-alert-energy.additional_questions');
        }
        else {
            bank_validator.validateAll(function (errors, data) {
                if (errors !== null) {
                    $.each(errors, function (index, error) {
                        $('#' + error.target).html(error.message);
                    });
                }
                else {

                    var usable_data = {};
                    $.each(data, function (key, value) {
                        usable_data[value.field] = value.value;
                    });
                    usable_data.quote_id = quote_id;

                    make_request('/price-alerts/store-dd', usable_data, that, 'price-alert-energy.additional_questions');
                }
            });
        }
    });

    $('#lead_script').on('click', '#confirm_price_alert_for_realz', function(){

        var data = {};
        data.quote_id = quote_id;

        $.ajax({ cache: false,
            url: '/price-alerts/confirm',
            type:"POST",
            dataType: 'json',
            data: data,
            success: function (response) {
                if(response.errors == null) {
                    if(response.contact_id){
                        window.location.href = "/contacts/"+response.contact_id;
                    }
                }
                else{
                    //error	
                    error_message(response.errors);
                }
            },
            error: function (error) {
                loaderClear();
                error_message('an unknown error happened');
            }
        });

    });

    $('.toggle-price-alert').on('change', function(){

        var data = {};
        data.state = $(this).is(':checked');
        data.price_alert_id = $(this).data('price-alert-id');
        
        var that = $(this);
        $.ajax({ cache: false,
            url: '/price-alerts/change-state',
            type:"POST",
            dataType: 'json',
            data: data,
            success: function (response) {
                if(response.errors == null) {
                    success_message();
                }
                else{
                    //error	
                    error_message(response.errors);
                    $(that).attr('checked', !data.state);
                }
            },
            error: function (error) {
                loaderClear();
                error_message('unknown error');
            }
        });
        
        
    });

	
	$('#lead_script').on('click', '#send-acttivation-link', function(){

        var data = {};
        data.quote_id = quote_id;

        $.ajax({ cache: false,
            url: '/price-alerts/send-activation-link',
            type:"POST",
            dataType: 'json',
            data: data,
            success: function (response) {
                if(response.errors == null) {
                    success_message('SMS has been sent to the customer');
                    $('#confirm_price_alert').show();
                }
                else{
                    //error	
                    error_message(response.errors);
                }
            },
            error: function (error) {
                loaderClear();
                error_message('an unknown error happened');
            }
        });

    });
	
	$('#lead_script').on('click', '#send-epf-activation-email', function(){

        var data = {};
        data.quote_id = quote_id;

        $.ajax({ cache: false,
            url: '/price-alerts/send-activation-email',
            type:"POST",
            dataType: 'json',
            data: data,
            success: function (response) {
                if(response.errors == null) {
                    success_message('Email has been sent to the customer');
                    $('#confirm_price_alert').show();
                }
                else{
                    //error	
                    error_message(response.errors);
                }
            },
            error: function (error) {
                loaderClear();
                error_message('an unknown error happened');
            }
        });

    });
});