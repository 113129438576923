$(document).ready(function(){
	var search_object = {
		search_rules : {},
		search_term : '',
		get_search_rules : function(callback){
			var that = this;
			makeRequest("/search/get-rules", {}, function(response){
				if(response.success === true && Object.keys(response.rules).length > 0){
					that.search_rules = response.rules;
					callback();
				}
			}, 'GET');
		},
		search : function(class_name,field_name,term,callback){
			makeRequest("/search", {
				'class_name' : class_name,
				'field_name' : field_name,
				'term' : term
			}, function(response){
				if(response.success === true && Object.keys(response.results).length > 0){
					callback(response.results);
				}
				else{
					callback([]);
				}
			});
		},
		handleReplacments : function(term,rules,callback){
			if(rules.replace && Object.keys(rules.replace).length){
				$.each(rules.replace,function(index,replacements){
					term = term.replace(replacements.old,replacements.new);
				});
			}
			callback(term);
		},
		searchMatchesPatterns : function(term,rules,callback){
			var passes_tests = true;
			if(rules.patterns && Object.keys(rules.patterns).length){
				$.each(rules.patterns,function(index,pattern){
					if(!term.match(pattern)){
						passes_tests = false;
						return false;
					}
				});
			}
			if(passes_tests){
				callback();
			}
		},
		showResults : function(results, results_container){
			console.log(results_container)
			$.each(results,function(key,value){
				$(results_container).append(value);
			});
			$(results_container).parent().show();
		},
		searchWrapper : function(term, results_container = '#mini-search-results'){
			var that = this;
			if(term === this.search_term){
				return;
			}
			if(!Object.keys(this.search_rules).length){
				this.get_search_rules(function(){
					that.searchWrapper(term, results_container);
				});
			}
			else{
				// update the object to store term so we dont keep re - searching the same thing 
				that.search_term = term;
				$.each(this.search_rules,function(class_name,fields){
					$.each(fields,function(field_name,rules){
						that.handleReplacments(term,rules,function(clean_term){
							that.searchMatchesPatterns(clean_term,rules,function(){
								that.search(class_name,field_name,clean_term,function(results){
									that.showResults(results, results_container);
								});
							});
						});
					});
				});
			}
		},
		getLastSearchTerm : function(){
			return this.search_term;
		}
		
	};
	$('#mini-search-wrapper').on('keyup','#search-input',function(e){
		if (e.keyCode == 27) {
			$('#mini-search-results-wrapper').hide();
			$('#mini-search-results').html('');
			return;
		}
		if(e.keyCode == 13){
			$('#mini-search-wrapper .btn-search').trigger('click');
		}
	});
	$('#mini-search-wrapper').on('click','.btn-search',function(e){
		
		var  term = $('#search-input').val();
		if(search_object.getLastSearchTerm() !== term){
			$('#mini-search-results').html('');
			search_object.searchWrapper(term);
		}
	});
	$('#mini-search-results-wrapper').on('click','#close-search',function(e){
		$('#mini-search-results-wrapper').hide();
		$('#mini-search-results').html('');
	});
	
	$('#mini-search-results').on('click','.search-results-pagination',function(){
		$($(this).data('class')).hide();
		$('.'+$(this).attr('class')).removeClass('active');
		$(this).addClass('active');
		$($(this).data('target')).show();
		$($(this).data('target')).toggleClass('active');
	});

	$('#lead_script').on('click', '.btn-search', function(){
		var  term = $('#contact_search_input').val();
		if(search_object.getLastSearchTerm() !== term){
			$('#contact_search_results').html('');
			search_object.searchWrapper(term, '#contact_search_results');
		}
	});
	
});
