/*Edit a list Item*/
var editList = function(list_id,callback) {
	makeRequest('/admin/dialler/list/edit/'+list_id, {}, function (response) {
		if(isset(response.success) && response.success && isset(response.html)) {
			$('#edit-list-container').html(response.html).show();
			$('body,html').animate({
				scrollTop: 0
			}, 800);
			if(isset(response.advanced_html) && response.advanced_html && response.advanced_html.length) {
				$('#advanced-list-options-container').html(response.advanced_html).show();
				$('#advanced-list-options-container').find('.option-date-range-input .date-range-picker').daterangepicker({
					"locale": {
						"format": 'DD/MM/YYYY HH:mm A',
						"separator": " - "
					},
					"ranges": {
						'Today': [moment(), moment()],
						'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
						'This Week': [moment().startOf('week'), moment().endOf('week')],
						'This Month': [moment().startOf('month'), moment().endOf('month')],
					}
				});
			}
			callback();
		}
		else {
			error_message();
		}
	}, 'GET');
};

var showUploadHistory = function () {
	makeRequest('/dialler/history', {}, function (response) {
		if(isset(response.success) && response.success && isset(response.html)) {
			$('#edit-list-container').hide();
			$('#additional-list-container').hide();
			$('#upload-history-container').html(response.html).show();
		}
		else {
			if(isset(response.error)) {
				error_message(response.error);
			}
			else {
				error_message('Unknown Error - Failed to get summary');
			}
		}
	});
};

var getListSummary = function(list_id,callback) {
	var data = {
		'list_id' : list_id
	};
	makeRequest('/dialler/list/summary' , data, function (response) {
		if(isset(response.success) && response.success && isset(response.html)) {
			$('#edit-list-container').html(response.html);
			$('#upload-history-container').show().html(response.additional_html);
			success_message();
			callback();
		}
		else {
			if(isset(response.error)) {
				error_message(response.error);
			}
			else {
				error_message('Unknown Error - Failed to get summary');
			}
		}
	});
}
		
/*Save a list Item*/
var saveList = function(list_data, callback) {
	makeRequest('/admin/dialler/list/save', list_data, function (response) {
		if(response.success){
			$('#edit-list-container').hide();
			$('#advanced-list-options-container').hide();
			$('body,html').animate({
				scrollTop: 0
			}, 800);
			success_message('Saved List');
			callback();
		}
		else {
			var error = 'unable to save list';
			if(isset(response.errors)) {
				error = response.errors;
			}
			error_message(error);
		}
	});
};

var beginDownload = function (list_id = '', callback) {
	var data = {};
	if(isset(list_id) && typeof(list_id) !== 'string') {
		data['list_id'] = list_id
	}

	makeRequest('/dialler/begin-download', data, function (response) {
		if(isset(response.success) && response.success && isset(response.html)) {
			$('#upload-history-container').hide();
			$('#edit-list-container').html(response.html);
			success_message();
			callback();
		}
		else {
			if(isset(response.error)) {
				error_message(response.error);
			}
			else {
				error_message('Unknown Error - Failed to get upload view');
			}
		}
	});
}

var beginUpload = function (list_id = '', callback) {
	var data = {};
	if(isset(list_id) && typeof(list_id) !== 'string') {
		data['list_id'] = list_id
	}

	makeRequest('/dialler/begin-upload', data, function (response) {
		if(isset(response.success) && response.success && isset(response.html)) {
			$('#upload-history-container').hide();
			$('#edit-list-container').html(response.html);
			success_message();
			callback();
		}
		else {
			if(isset(response.error)) {
				error_message(response.error);
			}
			else {
				error_message('Unknown Error - Failed to get upload view');
			}
		}
	});
}

var preValidateUpload = function (file_form_data, callback) {
	$.ajax({
		url: '/dialler/pre-validate-upload',
		type: "POST",
		data: file_form_data,
		enctype: 'multipart/form-data',// multipart
		contentType: false,       // The content type used when sending data to the server.
		cache: false,             // To unable request pages to be cached
		processData:false,        // To send DOMDocument or non processed data file it is set to false
		success: function(response) {
			callback(response);
		},
		error: function (error) {
			callback({'error' : 'Failed to pre validate upload'});
		}
	});
}

/*Create a list Item*/
var createList = function(name){
	var data = {
		'name' : name
	};

	makeRequest('/admin/dialler/list/create', data, function (response) {
		if(isset(response.success) && response.success && isset(response.html)) {
			$('#edit-list-container').html(response.html);
			$('#edit-list-container').show();
			success_message('Created List');
		}
		else {
			if(response.error){
				error_message(response.error);
			}
			else {
				error_message();
			}
		}
	});
};
	
/*Toggle a list Item*/
var toggleList = function(id,state,callback){
	var data = {
		'list_id': id,
		'state': state
	};

	makeRequest('/admin/dialler/list/toggle-active', data, function (response) {
		if(isset(response.success) && response.success) {
			var list_target = '#data-list-id-'+id;
			var new_enable = $('#list_name').val();
			$(list_target).children().eq(1).html(new_enable);
			success_message('Changed State');
			callback();
		}
		else {
			if(response.error){
				error_message(response.error);
			}
			else {
				error_message();
			}
		}
	});
};

var validation_rules = {
	'list_name' : '.{4, 30}',
	'list_ident' : '\d{3,4}',
	'product_type_id' : '\d{1,2}'
};
var validate_data = function(data){
	$.each(data, function(key, value){
		if(validation_rules[key]){
			if(!value.match(validation_rules[key])){
				return false;
			}
		}
	});
	return true;
};

var instantiate_droppable_list = function () {
	$('#uploaded_fields_list li').droppable({
		accept: 'li',
		drop: function(event, ui) {
			var target = $(event.target);
			var bail = false;

			$.each($('.interpreted-system-field'), function (i, element) {
				if ($(element).data('field-id') == $(ui.draggable).data('field-id')) {
					bail = true;
				}
			});

			if (target.is('li') && !bail) {
				if (ui.draggable.closest('#uploaded_fields_list').length == 0) {
					if (!$(event.target).hasClass('required-wizard-field')) {
						var clone = ui.draggable.clone();
						if ($(clone).find('.remove-wizard-field').length) {
							$(clone).find('.remove-wizard-field').show();
						}
						$(clone).find('.required-field-span').remove();
						target.replaceWith($(clone).removeClass('supported-field-draggable').addClass('interpreted-system-field'));
					}
					else {
						error_message('You cannot override a required field, please move it first');
					}
				}
			}

			if(bail && $(ui.draggable).hasClass('supported-field-draggable')) {
				error_message('Field is already in the configuration wizard!')
			}

			$("#uploaded_fields_list").sortable({
				revert: true,
				update: function () {}
			});
			$( "#uploaded_fields_list" ).sortable( "enable" );
			instantiate_droppable_list();
		}
	});
}

var submitUploadConfiguration = function (data, callback) {
	makeRequest('/dialler/confirm-upload-configuration', data, function (response) {
		if(isset(response.success) && response.success && response.html) {
			callback(response);
		}
		else {
			if(isset(response.error) && response.error) {
				error_message(response.error);
			}
			else {
				error_message('Unable to submit configuration');
			}
		}
	});
};

var submitDownloadOptions = function (data, callback) {
	makeRequest('/dialler/confirm-download-options', data, function (response) {
		if(isset(response.success) && response.success) {
			callback(response);
		}
		else {
			if(isset(response.error) && response.error) {
				error_message(response.error)
			}
			else {
				error_message('Unable to submit configuration');
			}
		}
	});
};

var submitOptionsConfiguration = function (data, callback) {
	makeRequest('/dialler/confirm-options-configuration', data, function (response) {
		if(isset(response.success) && response.success) {
			callback(response);
		}
		else {
			if(isset(response.error) && response.error) {
				error_message(response.error)
			}
			else {
				error_message('Unable to submit configuration');
			}
		}
	});
};

var clearDiallerList = function (list_id , callback) {
	var data = {
		'list_id': list_id
	};
	makeRequest('/admin/dialler/list/clear', data, function (response) {
		callback(response);
	});
};

var refreshAbandons = function (list_id , date_range, callback) {
	var data = {
		'list_id': list_id,
		'date_range': date_range
	};
	makeRequest('/admin/dialler/list/abandons/refresh', data, function (response) {
		callback(response);
	});
};


var make_sortable_lists = function () {
	$("#uploaded_fields_list").sortable({
		revert: true,
		update: function () {}
	});
	$(".supported-field-draggable").draggable({
		//connectToSortable: '.sortableList',
		cursor: 'pointer',
		helper: 'clone',
		revert: true,
		stop: function () {
			$( "#uploaded_fields_list" ).sortable( "enable" );
		},
		drag: function () {
			$( "#uploaded_fields_list" ).sortable( "disable" );
		}
	});
	instantiate_droppable_list();
}

$( document ).ready(function() {
	//checking for list messages
	//editing a list item
	$('#saved-lists').on('click', '.edit-event', function() {
		var list_id = $(this).parent().data('list-id');
		$('#upload-history-container').hide();
		editList(list_id,function() {
			$('.module-error').hide();
			$('#edit-list-container').show();
		});
	});
	
	//saving a list item
	$('#edit-list-container').on('click', '#save-list', function() {
		var form = $('#edit-list-form-data');
		var data = {
			list_id : $(form).data('list-id'),
			list_name : $(form).find('#list_name').val(),
			product_type_id : $(form).find('#product_type_id').val(),
			dialler_id : $(form).find('#dialler_id').val(),
			type_id : $(form).find('#type_id').val(),
			list_ident : $(form).find('#list_ident').val(),
			hopper_size : $(form).find('#list_size').val(),
			active : $(form).find('#active').val(),
			auto_decay : $(form).find('#auto_decay').val(),
			api_leads : $(form).find('#api_leads').val()

		}
		if(!validate_data(data)) {
			error_message('validation failed');
			return;
		}
		else {
			saveList(data, function() {
				$('#edit-list-container').hide();
				$('#saved-lists').load(location.href + " #saved-lists");
			});
		}
	});
	
	//creating a list item
	$('#lists-wrapper').on('click','#create-list', function() {
		var name = $(this).parent().find('input[name="new-list-name"]').val();

		if(name.length < 3) {
			error_message("Please enter a longer name");
		} 
		else {
			createList(name);
		}
	});
	
	//toggling a list item on and off
	$('#saved-lists').on('click', '#toggle-list input', function() {
		var list_id = $(this).parent().parent().data('list-id');
		var state = $(this).is(':checked');
		toggleList(list_id,state,function(){
			$('.update-error').hide();
			$('.update-success').fadeIn().text('list toggled');
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		});
	});
	
	$('#lists-wrapper').on('keyup', '#new-list-name', function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-lists');
		if(valThis === ""){
			$(target).find('li').show();
		}
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		}
	});

	$('#saved-lists').on('click', '.information-trigger', function() {
		var list_id = $(this).parent().data('list-id');
		$('#upload-history-container').hide();
		getListSummary(list_id, function() {
			$('.module-error').hide();
			$('#edit-list-container').show();
		});
	});

	$('#saved-lists, #toolbar-options').on('click', '.begin-upload-trigger', function() {
		var list_id = $(this).closest('.event-container').data('list-id');
		beginUpload(list_id, function() {
			if($('#list_id_select').length) {
				$('#list_id_select').select2({'width':'100%'});
			}
			$('.module-error').hide();
			$('#edit-list-container').show();
		});
	});

	$('#saved-lists').on('click', '.begin-download-trigger', function () {
		$('.additional-list-container').hide();

		var list_id = $(this).closest('.event-container').data('list-id');
		beginDownload(list_id, function () {
			$.each($('.mapping-input'), function (i, element) {
				if($(element).hasClass('mapping-datetimerange')) {
					$(element).daterangepicker({
						"locale": {
							"format": 'DD/MM/YYYY HH:mm',
							"separator": "-"
						},
						"timePicker": true,
						"linkedCalendars": false,
						"showDropdowns": true
					});
				}
				if($(element).hasClass('mapping-datetime')) {
					$(element).daterangepicker({
						"locale": {
							"format": 'DD/MM/YYYY HH:mm',
							"separator": "-"
						},
						"singleDatePicker": true,
						"timePicker": true,
						"linkedCalendars": false,
						"showDropdowns": true
					});
				}
				if($(element).hasClass('mapping-select')) {
					$(element).select2({'width':'100%', 'height':'35px'});
				}
			});
			$('.module-error').hide();
			$('#edit-list-container').show();
		})
	});

	$('#edit-list-container').on('click', '.upload-list-data-button', function () {
		var file_form_data = new FormData($('#list-data-upload-form')[0]);
		$('.pre-validate-upload-wizard').hide();
		$('.list-file-upload-loader').show();

		if($('#list_id_select').length) {
			$('.event-edit.list-upload').data('list-id', $('#list_id_select').val());
		}

		preValidateUpload(file_form_data, function (response) {
			if(isset(response.success) && response.success && isset(response.html) && isset(response.additional_html)) {
				$('#field_list_container').html(response.html);
				$('#additional-list-container').html(response.additional_html);
				$('#additional-list-container').show();
				make_sortable_lists();
				$('.list-file-upload-loader').hide();
				$('.post-validate-upload-wizard').show();
				$('.confirm-list-data-button').show();
			}
			else {
				if(isset(response.error)) {
					error_message(response.error);
				}
				else {
					error_message();
				}
				$('.pre-validate-upload-wizard').show();
				$('.list-file-upload-loader').hide();
			}
		});
	});

	$('#edit-list-container').on('click', '.confirm-list-data-button', function () {
		var required_items = $('#available_fields_list').find('.required-wizard-field');
		var required_ids = {};

		$.each(required_items, function (i, value) {
			required_ids[i] = $(value).data('field-id');
		});

		var fields = $('#uploaded_fields_list li'),
			data = {};

		$.each(fields, function (i, item) {
			if(!isset(data['items'])) {
				data['items'] = {};
			}
			data['items'][i] = '';
			if(isset($(item).data('field-id')) && $(item).data('field-id') != '') {
				var field_id = $(item).data('field-id');
				data['items'][i] = field_id;
				$.each(required_ids, function (required_index, required_id) {
					if(field_id == required_id) {
						delete required_ids[required_index];
					}
				});
			}
		});

		if(!$.isEmptyObject(required_ids)) {
			error_message('You have to map all the required fields before you can proceed');
			return;
		}

		data['list_id'] = $('.event-edit.list-upload').data('list-id');
		data['file_id'] = $('#uploaded_fields_list').data('file-id');
		submitUploadConfiguration(data, function (response) {
			$('.options-upload-wizard').find('#options_mapping_confirmation').html(response.additional_html);
			$('.options-upload-wizard').find('#options_list_container').html(response.html);

			$.each($('.mapping-input'), function (i, element) {
				if($(element).hasClass('mapping-datetime')) {
					$(element).daterangepicker({
						"locale": {
							"format": 'DD/MM/YYYY HH:mm',
							"separator": "-"
						},
						"singleDatePicker": true,
						"timePicker": true,
						"linkedCalendars": false,
						"showDropdowns": true
					});
				}
				// if($(element).hasClass('mapping-select')) {
				// 	$(element).select2({'width':'100%', 'height':'35px'});
				// }
			});
			$('.options-upload-wizard').find('.select2').select2({'width':'100%', 'height':'35px'});

			$('.post-validate-upload-wizard').hide();
			$('.options-upload-wizard').show();
			$('.confirm-options-data-button').show();
		});
	});

	$('#edit-list-container').on('click', '.remove-wizard-field', function () {
		var item = $(this).closest('li');
		var disabled_field = "<li class='ui-state-default ui-state-disabled disabled-field ui-droppable'>None</li>";

		if($(item).hasClass('required-wizard-field')) {
			error_message($(item).text() + ' is a required field.')
		}
		else {
			$(item).replaceWith(disabled_field);
			success_message('Removed Field');
			instantiate_droppable_list();
		}
	});

	$('#edit-list-container').on('click', '.confirm-options-data-button', function () {
		var data = {};
		data['file_id'] = $('.options-upload-data-row').data('file-id');
		var inputs = $('#options_list_container').find(':input');
		$.each(inputs, function (i, input) {
			if(!isset(data['options'])) {
				data['options'] = {};
			}
			data['options'][$(input).attr('name')] = $(input).val();
		});

		submitOptionsConfiguration(data, function (response) {
			$('#edit-list-container').hide();
			$('#additional-list-container').hide();
			$('#upload-history-container').html(response.html).show();

			success_message('Your file will now be processed and imported');
		});
	});

	$('#toolbar-options').on('click', '.show-history-trigger', function () {
		showUploadHistory();
	});

	$('#edit-list-container').on('click', '.download-options-data-button', function () {
		var data = {};
		data['list_id'] = $('.options-upload-data-row').data('list-id');
		var inputs = $('.download-options').find(':input');
		$.each(inputs, function (i, input) {
			if(!isset(data['options'])) {
				data['options'] = {};
			}
			data['options'][$(input).attr('name')] = $(input).val();
		});

		submitDownloadOptions(data, function (response) {
			window.open('/dialler/download-export/file/' + response.file_name,'_blank');
			success_message('Your file will now be downloaded');
		});
	});
	
	$('#advanced-list-options-container').on('click', '.clear-list-in-crm', function () {

		var list_id = $(this).data('list-id');

		var that = this;
		customConfirm('Are you sure you want to clear the list in CRM? - this is not easy to undo', function () {
			clearDiallerList(list_id, function (response) {
				if(isset(response.success) && response.success) {
					success_message('The list has been cleared - please make sure you empty the ist in the dialler');
					if(isset(response.additional_info)){
						var parent_container = $(that).closest('.advanced-option');
						var info_box = $(parent_container).find('.info-box');
						$(info_box).find('.info-box-content').html(response.additional_info);
						$(info_box).show();
					}
				}
				else {
					var error = 'There was an error clearing the list';
					if(isset(response.error)) {
						error =  response.error;
					}
					error_message(error);
				}
			});
		});
	});
	
	$('#advanced-list-options-container').on('click', '.refresh-abandons', function () {

		var list_id = $(this).data('list-id');

		//we need to get the date range too
		var date_range = $(this).closest('.advanced-option').find('.date-range-picker').val();

		var that = this;
		customConfirm('Are you sure you want to refresh abandons? - this is not easy to undo', function () {
			refreshAbandons(list_id, date_range, function (response) {
				if(isset(response.success) && response.success) {
					success_message('Refreshed');
					if(isset(response.additional_info)){
						var parent_container = $(that).closest('.advanced-option');
						var info_box = $(parent_container).find('.info-box');
						$(info_box).find('.info-box-content').html(response.additional_info);
						$(info_box).show();
					}
				}
				else {
					var error = 'There was an error refreshing - please check you are logged in - if so please talk to IT';
					if(isset(response.error)) {
						error =  response.error;
					}
					error_message(error);
				}
			});
		});
	});
	
	$('#advanced-list-options-container').on('click', '.save-list-variables', function () {

		var list_id = $(this).data('list-id');

		var parent = $(this).closest('.advanced-option');

		var serial_data = $(parent).find('.list-variable-item :input').serializeArray();
		var variable_data = sanitiseSerialedArray(serial_data);
		
		var to_send = {
			'list_id': list_id,
			'variable_data': variable_data
		};

		makeRequest('/admin/dialler/list/variables/update', to_send, function(response){
			if(isset(response.success) && response.success){
				success_message('The list variables have been updated');
			}
			else{
				var error = 'There was an error updating the list variables';
				if(isset(response.error)) {
					error =  response.error;
				}
				error_message(error);
			}
		})
	});


});