
var business_contact_creation_callback = function(){};
window.showNewBusinessContactModal = function(callback = function(){}){
	$('#link_new_business_contact_modal .duplicate-resolution-section').hide();
	$('#link_new_business_contact_modal .contact-details-section').show();
	$('#link_new_business_contact_modal .contact-details-section input').val('');
	$('#link_new_business_contact_modal .contact-details-section select').val(null).trigger('change');
	$('#link_new_business_contact_modal .modal-footer').show();
	showModal('link_new_business_contact_modal');
	business_contact_creation_callback = callback;
}

$(function() {
	var business_calendar = false;

	// var loadMeterInstallEventsCalendar = function(){
	// 	var business_id = $('#business-install-events').data('business-id');
	// 	var data = {
	// 		'business_id' : business_id
	// 	};
	// 	makeRequest('/business/install/events', data, function(response){
	// 		if(isset(response.success) && response.success && isset(response.events)){
	// 			$.each(response.events, function(i, event){
	// 				business_calendar.addEvent(event);
	// 			});
	// 		}
	// 		else{
	// 			var error = 'unable to load install events';
	// 			if(isset(response.error)){
	// 				error = response.error;
	// 			}
	// 			error_message(error);
	// 		}
	// 	});
	// };

	var clickedMeterInstallEventEvent = function(info){
		var event_id = info.event.extendedProps.event_id;

		var data = {
			'event_id' : event_id
		};

		makeRequest('/business/install/event', data, function(response){
			if(isset(response.success) && response.success){
				createModal('viewMeterInstallEvent', response.html, function(){});
			}
			else{
				var error = 'unable to load meter install event';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	};

	if($('#install_events_calendar').length && isset(FullCalendar)){
		var business_id = $('#business-install-events').data('business-id');
		business_calendar = new FullCalendar.Calendar($('#install_events_calendar')[0], {
			'initialView': 'dayGridMonth',
			 headerToolbar: {
				start: 'dayGridMonth,timeGridWeek,timeGridDay',
				center: 'title',
				end: 'prev,next',
			},
			'eventClick' : clickedMeterInstallEventEvent,
			'selectable': true,
			events: {
				url: '/business/install/events',
				method: 'POST',
				extraParams: function(){
					return {
						business_id: 'business_id',
						}
					}	
				,
				failure: function() {
				alert('there was an error while fetching events!');
				},
				color: 'yellow',   // a non-ajax option
				textColor: 'black' // a non-ajax option
			}
		});
		business_calendar.render();
	}

	$('.view-business-install-events').on('click', function(){
		setTimeout(function(){
			business_calendar.updateSize();
		}, 50);
	})

	if($('#lead_script').length && lead_script && isset(lead_script.registerStepCallback)) {
		lead_script.registerStepCallback('business.primary_contact', function () {
			lead_script.anchor.find('.select2').select2();
		});
	}

	var new_business_id = false
    $('#lead_script').on('click','#business-create-button',function(e){
		e.preventDefault();
		var data  = {};

		var business_name = $('#business-create #business_name').val();
		if(!business_name){
			error_message('please provide a business name');
			return false;
		}
		var business_invoice_name = $('#business-create #business_invoice_name').val();
		if(!business_invoice_name){
			error_message('please provide a business invoice name');
			return false;
		}
		var business_telephone = $('#business-create #business_telephone').val();
		if(!business_telephone){
			error_message('please provide a business telephone');
			return false;
		}

		//are we gbg?
		if($('div.address-results-container').is(':visible')){
			//we are gbg
			if($('#business-create .address-result-item.active').length == 0){
				error_message('please select an address');
				return false;
			}
			data.gbg_address_id = $('#business-create .address-result-item.active').data('id');
		}
		else{
			var form = $('#lead_script').find('#gbg_section :input');;

			var address_data = {};
			var serialized = $(form).serializeArray();
			$.each(serialized, function(i, input){
				address_data[input.name] = input.value;
			})
			data.address_data = address_data;
		}

		data.business_name = business_name;
		data.business_invoice_name = business_invoice_name;
		data.business_telephone = business_telephone;

		var that = this;
		makeRequest('/business/create', data, function(response){
			if(isset(response.success) && response.success && isset(response.business_id)){
				new_business_id = response.business_id;
				lead_script.handleStep($(that).data('success-step'));
			}
			else{
				var error = 'Unable to create business';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
	
	$('#lead_script').on('click','#create-business-primary-contact-button',function(e){
		e.preventDefault();

		var data = $('#create-business-primary-contact').serializeArray();

		var bail = false;
		var to_send = [];
		$.each(data, function(i, input_data){
			if(!input_data.value.length){
				//lets check if this item is optional
				var optional = $($('#create-business-primary-contact').find('[name="' + input_data.name + '"]')).data('optional');
				if(!optional){
					bail = true;
					error_message('please enter a valid ' + input_data.name);
					return false; 
				}
			}
			else{
				to_send.push(input_data);
			}

		});
		if(bail){
			return false;
		}
		to_send.push({
			name: "business_id",
			value: new_business_id
		});

		makeRequest('/business/primary-contact/create', to_send, function(response){
			if(isset(response.success) && response.success && isset(response.redirect_to)){
				window.location.href = response.redirect_to;
			}
			else{
				var error = 'Unable to create contact';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});

	$('#business-details-save').on('click', function(e){
		e.preventDefault();
		var business_id = $('#business-details').data('business-id');
		var form = $(this).closest('form');
		var form_data = form.serializeArray();
		var data = {
			'business_id' : business_id
		};
		var bail = false;
		$.each(form_data, function(i, input_data){
			if(!input_data.value.length){
				bail = true;
				error_message('please enter a valid ' + input_data.name);
				return false; 
			}
			data[input_data.name] = input_data.value
		});
		if(bail){
			return false;
		}

		makeRequest('/business/update-details', data, function(response){
			if(isset(response.success) && response.success){
				$('.details-saved').fadeIn();
				setTimeout(function(){ $('.details-saved').fadeOut(); }, 3000);
			}
			else{
				var error = 'Unable to update business details';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});		
	});

	$('.business-tab-content').on('click', '#link_new_business_contact', function(){
		showNewBusinessContactModal();
	});
	
	$('#link_new_business_contact_modal').on('click', '#confirm_link_new_business_contact', function(){
		var business_id = $('#business-details').data('business-id');
		var data  = {
			'business_id' : business_id
		};

		var form_data = $('#link_new_business_contact_modal :input').serializeArray()

		var bail = false;
		$.each(form_data, function(i, input_data){
			if(!input_data.value.length){
				//lets check if this item is optional
				var optional = $($('#link_new_business_contact_modal').find('[name="' + input_data.name + '"]')).data('optional');
				if(!optional){
					bail = true;
					error_message('please enter a valid ' + input_data.name);
					return false; 
				}
			}
			else{
				data[input_data.name] = input_data.value
			}

		});
		if(bail){
			return false;
		}

		makeRequest('/business/contacts/link-new', data, function(response){
			if(isset(response.success) && response.success && isset(response.html) && isset(response.action)){
				if(response.action == 'confirm_from_list'){
					$('#link_new_business_contact_modal .duplicate-resolution-section').html(response.html);
					$('#link_new_business_contact_modal .duplicate-resolution-section').show();
					$('#link_new_business_contact_modal .contact-details-section').hide();
					$('#link_new_business_contact_modal .modal-footer').hide();

				}
				else{
					$('.business-contact-list').replaceWith(response.html);
					$('.business-contact-list .select2').select2({ width: '100%' });
					hideModal('link_new_business_contact_modal');
					business_contact_creation_callback(response.contact);
					success_message();
				}
			}
			else{
				var error = 'Unable to create contact';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});
	
	$('#link_new_business_contact_modal').on('click', '.link-existing', function(){
		var business_id = $('#business-details').data('business-id');
		var contact_id = $(this).data('contact-id');
		
		var data  = {
			'business_id' : business_id,
			'contact_id' : contact_id
		};
		showLoader();
		makeRequest('/business/contacts/link-existing', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('.business-contact-list').replaceWith(response.html);
				hideModal('link_new_business_contact_modal');
				business_contact_creation_callback(response.contact);
				success_message();
			}
			else{
				var error = 'Unable to link contact';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader()
		});
	});
	
	$('#link_new_business_contact_modal').on('click', '.link-new-contact', function(){
		var business_id = $('#business-details').data('business-id');
		var contact_data = $(this).data('contact-data');
		
		var data  = {
			'business_id' : business_id,
			'contact_data' : contact_data
		};
		showLoader();
		makeRequest('/business/contacts/force-new-link', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('.business-contact-list').replaceWith(response.html);
				hideModal('link_new_business_contact_modal');
				business_contact_creation_callback(response.contact);
				success_message();
			}
			else{
				var error = 'Unable to create contact';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader()
		});
	});

	$('.business-tab-content').on('click', '.unlink-business-contact', function(){
		var business_id = $('#business-details').data('business-id');
		var contact_id = $(this).parent().parent().parent().find('form').data('contact-id');
		customConfirm('Are you sure you want to unlink this contact?', function(){
			var data  = {
				'business_id' : business_id,
				'contact_id' : contact_id
			};
			showLoader();
			makeRequest('/business/contacts/unlink', data, function(response){
				if(isset(response.success) && response.success && isset(response.html)){
					$('.business-contact-list').replaceWith(response.html);
					hideModal('link_new_business_contact_modal');
					success_message();
				}
				else{
					var error = 'Unable to remove link';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
				hideLoader()
			});
		});
	});

	$('.business-tab-content').on('click', '.edit-business-contact', function(e) {
		e.preventDefault();
		let $this = $(this);
		let $parent = $this.parent().parent().parent();
		$parent.find('.view-business-contact-field').hide();
		$parent.find('.edit-business-contact-field').show();
		$this.hide();
	});

	$('.business-tab-content').on('click', '.business-contact-details-save', function(e) {
		e.preventDefault();
		var $form = $(this).parent().parent();
		var contact_id = $form.data('contact-id');
		var formdata = $form.serialize();
		var customer_validator = new customerValidator();
		var validation = [
			function(next) { customer_validator.validateTitle($form.find('.customer-details-title').val(),next); },
			function(next) { customer_validator.validateFirstName($form.find('.customer-details-first_name').val(),next); },
			function(next) { customer_validator.validateLastName($form.find('.customer-details-last_name').val(),next); },
			function(next) { customer_validator.validatePhone($form.find('.customer-details-telephone').val(),next); },
			function(next) { customer_validator.validateEmail($form.find('.customer-details-email').val(),next); },
		];
		customer_validator.validateAll(validation,function(errors,data){
			//console.log(results);
			if(errors !== null){
				$.each(errors,function(index,error){
					$form.find('.' + error.target).html(error.message).show();
					//console.log(error.message);
				});
				setTimeout(function(){
					$form.find('span.error-message').hide();
				}, 5000);
			}
			else{
				makeRequest("/contact/update/" + contact_id, formdata, function(response){
					if(isset(response.success) && response.success){
						$('.details-saved').fadeIn();
						setTimeout(function(){ $('.details-saved').fadeOut(); }, 3000);
					}
					else{
						var error = 'Unable to save contact';
						if(isset(response.error)){
							error = response.error;
						}
						error_message(error);
					}
					hideLoader();
				});

			}
		});
		//console.log(formdata);

	});

	$('.business-tab-content').on('click', '#demote_primary_address', function(){
		customConfirm('Are you sure you want to demote the primary address?', function(){
			var business_id = $('#business-details').data('business-id');
			var data =  {
				'business_id' : business_id
			};
			showLoader();
			makeRequest('/business/primary-address/demote', data, function(response){
				if(isset(response.success) && response.success && response.html){
					$('.business-addresses-section').replaceWith(response.html);
					success_message();
				}
				else{
					var error = 'Unable to demote address';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
				hideLoader();
			});		
		});
	});
	
	$('.business-tab-content').on('click', '.promote-to-primary-address', function(){
		
		var business_id = $('#business-details').data('business-id');
		var address_id = $(this).data('address-id');
		var data =  {
			'business_id' : business_id,
			'address_id' : address_id
		};
		showLoader();
		makeRequest('/business/primary-address/promote', data, function(response){
			if(isset(response.success) && response.success && response.html){
				$('.business-addresses-section').replaceWith(response.html);
				success_message();
			}
			else{
				var error = 'Unable to promote address';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});		
		
	});
	
	$('.business-tab-content').on('click', '.unlink-business-address', function(){
		
		var business_id = $('#business-details').data('business-id');
		var address_id = $(this).data('address-id');
		customConfirm('Are you sure you want to unlink this address?', function(){
			var data =  {
				'business_id' : business_id,
				'address_id' : address_id
			};
			showLoader();
			makeRequest('/business/addresses/unlink', data, function(response){
				if(isset(response.success) && response.success && response.html){
					$('.business-addresses-section').replaceWith(response.html);
					success_message();
				}
				else{
					var error = 'Unable to unlink address';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
				hideLoader();
			});		
		});
		
	});

	$('.business-tab-content, #client-details').on('click', '#show_manual_address_entry_display', function(e){
		$('#manual_address_entry_display').show();
	});
	
	$('.business-tab-content').on('click', '.create-new-business-address', function(e){
		e.preventDefault();

		var form = $(this).closest('#address-form');

		var address_data = {};
		var serialized = $(form).serializeArray();
		$.each(serialized, function(i, input){
			address_data[input.name] = input.value;
		})
		var business_id = $('#business-details').data('business-id');
		var data =  {
			'business_id' : business_id,
			'address_data' : address_data
		};
		showLoader();
		makeRequest('/business/addresses/create-manual', data, function(response){
			if(isset(response.success) && response.success && response.html){
				$('.business-addresses-section').replaceWith(response.html);
				success_message();
			}
			else{
				var error = 'Unable to create address';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});		

	});

	$('#businesses_table').DataTable({
        ajax: "/get/businesses",
        pageLength: 40,
        ordering: true,
		order: [[ 0, "desc" ]],
		responsive: true,
		bAutoWidth:false,
		autoWidth: false,
		lengthMenu: [25, 40, 100, 500],
		columnDefs: [
			{"type": "date", "targets": 7}
		],
	});

	var business_order_table = $('#business_order_table').DataTable();

	$('#business_order_table').on( 'click', 'tr td .tariff-details-button', function () {
        var tr = $(this).closest('tr');
        var row = business_order_table.row( tr );

        if ( row.child.isShown() ) {
            row.child.hide();
         }
        else {
			if($(row.child()).find('.order-details').length){
				row.child.show();
			}
			else{
				var loader = '<div class="business-order-details-loader-container"><i class="fa-regular fa-circle-notch fa-spin" aria-hidden="true"></i></div>';
				row.child(loader).show();

				//now actually go get the html
				makeRequest('/order/details', {'order_id' : $(this).data('order-id')}, function(response){
					if(isset(response.success) && response.success && isset(response.html)){
						row.child(response.html);
					}
					else{
						var error = 'unable to load order details';
						if(isset(response.error)){
							error = response.error;
						}
						error_message(error);
						var error_content = '<div class="business-order-details-error-container">Error : ' + error + '</div>';
						row.child(error_content);
					}
				});
			}
         }
    } );

});	
	
