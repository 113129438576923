$(document).ready(function() {
    $('#agent_performance_table').DataTable({
        dom: 'flrtiBp',
        ajax: "/get/agent-performance",
        ordering: true,
        responsive: true,
        order : [[ 9, "asc" ]],
        bAutoWidth:false,
        autoWidth: false,
        lengthMenu: [25, 40, 100],
        select: true,
        buttons: [
                'copyHtml5',
                'print',
                {
                    extend: 'collection',
                    text: 'Export',
                    buttons: [
                        'csvHtml5',
                        'pdfHtml5'   
                    ]
                }
        ],
    });

    $('input[name="agent-performance-date-filter"]').daterangepicker({

        "locale": {
            "format": 'DD/MM/YYYY',
            "separator": "-"
        },
        "linkedCalendars": false,
        "showDropdowns": true
        },
        function(start, end, label) {
            $('#agent_performance_table').DataTable({
                destroy: true,
                dom: 'flrtiBp',
                ajax: "/get/agent-performance?start="+start+"&end="+end,
                ordering: true,
                order : [[ 9, "asc" ]],
                responsive: true,
                bAutoWidth:false,
                autoWidth: false,
                lengthMenu: [25, 40, 100],
                select: true,
                buttons: [
                        'copyHtml5',
                        'print',
                        {
                            extend: 'collection',
                            text: 'Export',
                            buttons: [
                                'csvHtml5',
                                'pdfHtml5'   
                            ]
                        }
                ],
            });
        }
    );
    
});