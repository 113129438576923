//only used for accident protection - in API now as anticipating eventually we'll scrape open works
var detectFactFindCompletion = function(callback, data) {
	var missing = null;
	var data = [];
	
	//grab transient data from input tabs (hospital stays) 
	var customer_hospital_stay_option = $('input[name="customer-hospital-stay-option"]:checked').val();
	var customer_medical_conditions = $('input[name="customer-medical-conditions"]').val();
	var customer_hospital_days = $('input[name="customer-hospital-days"]').val();
	var customer_hospital_repeat_option = $('input[name="customer-hospital-repeat-option"]:checked').val();
	
	var customer_broken_bones_option = $('input[name="customer-broken-bones-option"]:checked').val();
	var customer_minor_bones_broken = $('input[name="customer-minor-bones-broken"]').val();
	var customer_major_bones_broken = $('input[name="customer-major-bones-broken"]').val();

	var customer_refused_insurance_option = $('input[name="customer-refused-insurance-option"]:checked').val();

	//grab transient data from input tabs (employment overview)
	var employment_status = $('input[name="employment_status"]').val();
	var occupation = $('input[name="occupation"]').val();
	var customer_hospital_time_off_option = $('input[name="customer-hospital-time-off-option"]:checked').val();

	//grab transient data from input tabs (dependant overview)
	var customer_has_dependants = $('input[name="customer-has-dependants"]:checked').val();
	var customer_children_qty = $('input[name="customer-children-qty"]').val();
	var customer_dependant_medical_conditions = $('input[name="customer-dependant-medical-conditions"]').val();
	var customer_dependant_hospital_nights = $('input[name="customer-dependant-hospital-nights"]').val();
	var customer_dependant_broken_bones_option = $('input[name="customer-dependant-broken-bones-option"]:checked').val();
	var customer_dependant_minor_bones_broken = $('input[name="customer-dependant-minor-bones-broken"]').val();
	var customer_dependant_major_bones_broken = $('input[name="customer-dependant-major-bones-broken"]').val();
	
	var customer_refused_insurance_option = $('input[name="customer-refused-insurance-option"]:checked').val();
	var customer_employment_status = $('#lead_script #fact-find-employment-status').val();
	var customer_occupation = $('#lead_script #fact-find-occupation').val();
	var customer_hospital_time_off_option = $('input[name="customer-hospital-time-off-option"]:checked').val();
	
	//validation
	
	//force success for now (@TODO needs separate validator)
	data.push({field:'success', value: true});
	
	//push everything for hospital stay metadata...
	data.push({field : 'customer:ml_meta_hospital_stay',value : 
		{'chs': customer_hospital_stay_option,
		 'cmc': customer_medical_conditions,
		 'chd': customer_hospital_days,
		 'chr': customer_hospital_repeat_option,
		 'cbb': customer_broken_bones_option,
		 'cmibb': customer_minor_bones_broken,
		 'cmabb': customer_major_bones_broken
		}
	});
	
	if (customer_has_dependants == 'Yes') {
		//push everything for depedant_meta_hospital_stay - if they exist
		data.push({field : 'customer:ml_meta_children_hospital_stay',value : 
			{'cdmc': customer_dependant_medical_conditions,
			 'cdhd': customer_dependant_hospital_nights,
			 'cdbb': customer_dependant_broken_bones_option,
			 'cdmibb': customer_dependant_minor_bones_broken,
			 'cdmabb': customer_dependant_major_bones_broken
			}
		});
		//optional
		data.push({field : 'customer:number_of_children', value: customer_children_qty});
	}
	
	//push everything deemed contact profile related
	data.push({field : 'customer:ml_customer_refused_insurance_option', value: customer_refused_insurance_option});
	data.push({field : 'customer:occupation', value: customer_occupation});
	data.push({field : 'customer:employment_status', value: customer_employment_status});
	data.push({field : 'customer:hospital_time_off_option', value: customer_hospital_time_off_option});
	data.push({field : 'customer:has_dependants', value: customer_has_dependants});

	callback(data);
};

$(document).ready(function() {
	$('#lead_script').on('click','#fact-find-check',function(e) {
		//console.log('clicked next for fact find 1');
		e.preventDefault();
		var quoteID = window.lead_script.quoteId;
		detectFactFindCompletion(function(data) {
			//console.log(data);
			if(data[0].value === true) {
				$.ajax({
					url: '/order/updateAccidentProtectionFactFind',
					data: {
						'quoteID' : quoteID,
						'data': data
					},
					dataType: 'json',
					method: 'post',
					success: function(response) {
						if(response.success){
							//move on if all ok
							window.lead_script.handleStep('accident-protection.fact_find_1');
						}
					},
					error: function (error) {
						//console.log(error);
					}
				});
			} 
			else {
				//pop open the accordion blade indicated (unless already open) and scroll to it
				//console.log('we would be popping accordion '+data[0].value);
//				if ($('#blade'+data[0].value).attr('aria-expanded')==='false') {
//					$('#blade'+data[0].value).click();
//				}
//				$("html, body").scrollTop($('#blade'+data[0].value).position().top);
			}
		});
	});
	
	//various script page pops for optional inputs 
	$('#lead_script').on('click','input[name="customer-hospital-stay-option"]',function(e) {
		var customer_hospital_stay_option = $('input[name="customer-hospital-stay-option"]:checked').val();
		//console.log(customer_hospital_stay_option);
		if (customer_hospital_stay_option=="Yes") {
			$('#customer-hospital-stay-questions').show();
		} 
		else {
			$('#customer-hospital-stay-questions').hide();
		}
	});
	
	$('#lead_script').on('click','input[name="customer-broken-bones-option"]',function(e) {
		var customer_broken_bones_option = $('input[name="customer-broken-bones-option"]:checked').val();
		//console.log(customer_broken_bones_option);
		if (customer_broken_bones_option=="Yes") {
			$('#broken-bones-questions').show();
		} 
		else {
			$('#broken-bones-questions').hide();
		}
	});
	
	$('#lead_script').on('click','#lead_script #fact-find-employment-status',function(e) {
		var customer_employment_status = $('#lead_script #fact-find-employment-status').val();
		//console.log(customer_employment_status);
		if (customer_employment_status=="Unemployed") {
			$('#additional_employment_questions').hide();
		} 
		else {
			$('#additional_employment_questions').show();
		}
	});
	
	$('#lead_script').on('click','input[name="customer-has-dependants"]',function(e) {
		var customer_has_dependants_option = $('input[name="customer-has-dependants"]:checked').val();
		//console.log(customer_has_dependants_option);
		if (customer_has_dependants_option=="Yes") {
			$('#dependants-questions').show();
		} 
		else {
			$('#dependants-questions').hide();
		}
	});
	
	$('#lead_script').on('click','input[name="customer-dependant-broken-bones-option"]',function(e) {
			var customer_dependant_broken_bones_option = $('input[name="customer-dependant-broken-bones-option"]:checked').val();
		//console.log(customer_dependant_broken_bones_option);
		if (customer_dependant_broken_bones_option=="Yes") {
			$('#dependants-broken-bones-questions').show();
		} 
		else {
			$('#dependants-broken-bones-questions').hide();
		}
	});
	
});


