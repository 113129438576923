require('./bootstrap');
$(document).ready(function(){
    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': $('body').data("token")
        }
	});
});

require('./api/current_supply_events');
require('./api/map');
require('./api/tariff_selection');
require('./api/customer_details');
require('./api/customer_address');
require('./api/direct_debit');

require('./email/email-sender');

require('./components/address');
require('./components/search');
require('./components/action-groups');
require('./components/incentives');
require('./pages/incentive-log');
require('./components/games/slot');
require('./components/games/scratch');
require('./components/actions');
require('./components/admin/suppliers/suppliers');
require('./components/admin/dialler/admin-dialler');
require('./components/admin/products/admin-products');
require('./components/admin/remoteconfigs/admin-remoteconfigs');
require('./components/admin/tickers/admin-tickers');
require('./components/admin/ultra/admin-lists');
require('./components/admin/profiles/admin-profiles');
require('./components/admin/switch-site-config/switch-site-config');
require('./components/admin/dialler/dialler');
require('./components/triggers');
require('./components/rules');
require('./components/customer-details');
require('./components/pretty-stuff');
require('./components/compliance');
require('./components/admin-teams');
require('./components/compliance/admin-compliance');
require('./components/scheduler');
require('./components/templating');
require('./components/events');
require('./components/action-group-dashboard');
require('./components/coaching');
require('./components/assets');
//require('./components/insurance');
require('./components/modals');
require('./components/loader');
require('./components/rss');
require('./components/price-alerts-script');
require('./components/ultra-callbar.js');
require('./components/script_system.js');
require('./components/softphone/softphone.js');

require('./pages/appointments');
require('./pages/chat');
require('./pages/leads');
require('./pages/insurance-renewals');
require('./pages/orders');
require('./pages/missed-appointment-events');
require('./pages/payments');
require('./pages/will-products');
require('./pages/insurance_products');
require('./pages/failed-orders');
require('./pages/orders-awaiting-export');
require('./pages/renewal-orders');
require('./pages/cancelled-orders');
require('./pages/rejected-orders');
require('./pages/dashboard');
require('./pages/quotes');
require('./pages/dispositions');
require('./pages/reports');
require('./pages/users');
require('./pages/contact');
require('./pages/business');
require('./pages/compliance');
require('./pages/coaching');
require('./pages/management-review');
require('./pages/fixedreports');
require('./pages/script-groups');
require('./pages/multi_script');
require('./pages/script_inputs');
require('./pages/script_summary');
require('./pages/database-compare');
require('./pages/ticket');
require('./pages/ticket-queues');
require('./pages/ticket-list');
require('./pages/web-users');
require('./pages/test-suite');
require('./pages/agent-performance');
require('./pages/switch-site-config');
require('./pages/partner-portal-admin');
require('./pages/affiliate-portal-admin');
require('./pages/meter_search');
require('./pages/staff-events');
require('./pages/system');
require('./pages/invoices');
require('./pages/consent');
require('./pages/callrecording');
require('./pages/auto-renewal');
require('./pages/region');
require('./pages/product-bundles');
require('./pages/utility-warehouse');
require('./pages/eagle');

require('./api/confirm_product');
require('./api/additional_questions');
require('./api/confirm_product_virgin');
require('./api/accident_protection_presentation');
require('./api/accident_protection_customer_details');
require('./api/accident_protection_address_details');
require('./api/accident_protection_additional_customer_details');
require('./api/dependants_details');
require('./api/accident_protection_bank_details');
require('./api/accident_protection_terms_1');
require('./api/accident_protection_fact-find-check');
require('./api/boiler_cover_fact_find');
require('./api/script_basket');

require('./components/script/generic_script');
require('./components/script/script_inputs/ScriptInput');
require('./components/script/script_inputs/InputInterface');
require('./components/script/carbonoffset/button_events');
require('./components/script/mortgage/button_events');
require('./components/script/funeralplan/button_events');
require('./components/script/will/button_events');
require('./components/script/Insurance/button_events');
require('./components/script/Insurance/script_control');
require('./components/script/energy/reactive_events');
require('./components/script/energy/button_events');
require('./components/script/energy/script_control');
require('./components/script/voidmeterenergy/script_control');
require('./components/script/voidmeterenergy/button_events');
require('./components/script/broadband/script_control');
require('./components/script/broadband/reactive_events');
require('./components/script/broadband/button_events');
require('./components/script/boilercare/button_events');
require('./components/script/boilercare/script_control');
require('./components/script/boilercareonline/script_control');

require('./components/utils/utils');
require('./components/utils/autoresize');
