//only used for virgin - in API now as anticipating eventually we'll scrape something external, e.g. vSpot?
$(document).ready(function() {
	
	$('#lead_script').on('click','#virginOrderUpdate',function(e){

		var quoteID = window.lead_script.quoteId;
		var productID = document.getElementById('virgin-product').value;
		var externalOrderNumber = document.getElementById('virgin-reference').value;
		var externalID = document.getElementById('virgin-payment-reference').value;
		
		//regex for external Virgin order numbers when we want tighter validation (TBC)
		//var virginRefRegEx = /(PSL-INT-)([0-9]{7})/;
		//var virginPayRefRegEx = /(PSL-INT-)([0-9]{7})/;
	
		//our error message timeout in milliseconds
		var timeoutSeconds = 3000;
		
		//validation chain, top to bottom
		//if (!externalOrderNumber.match(crmRefRegEx)){
		if (productID==='select') {
				//console.log('here in select error');
				$('#virginProductError').html('Please select a Virgin Media product from those listed.');
				setTimeout(function() {
					$('#virginProductError').html('');
				},timeoutSeconds);
		} 
		else {
			if (externalOrderNumber.length<1) {
			$('#virginRefError').html('Please enter Virgin Transaction ID');
				setTimeout(function() {
					$('#virginRefError').html('');
				},timeoutSeconds);
			} 
			else {
				if (externalID.length<1) {
					$('#virginPaymentRefError').html('Please enter Third Party Payment Reference');
						setTimeout(function() {
							$('#virginPaymentRefError').html('');
					},timeoutSeconds);
				} 
				else {
					//all good! let's send it!
					$.ajax({
							url: "/orders/updateVirginOrder",
							method:"POST",
						data: {
							quoteID: quoteID,
							productID: productID,
							externalOrderNumber: externalOrderNumber,
							externalID: externalID
						},
						success: function(response){
							if(response.errors){
								$('#virginOrderSubmitError').html('RealCRM2 has encountered an error.');
								setTimeout(function() {
									$('#virginOrderSubmitError').html('');
								},timeoutSeconds);
								return false
							}else {
								$('#virginOrderSubmitError').html('RealCRM2 has updated Virgin Media sale.');
								setTimeout(function() {
									$('#virginOrderSubmitError').html('');
									$('#virginOrderUpdate').hide();
									$('#virginOrderNext').show();
								},timeoutSeconds);
							}
						},
						error : function(error) {
							//console.log(error);
						}		
					});
				}
			}
		}		
	});
	
	//tailor bundle script based on selection
	$('#lead_script').on('change','#virgin-package-select',function(e){
		
		//console.log("bundle changed, updating script on this page...");
		
		var productID = document.getElementById('virgin-package-select').value;
		var bundleData = [
				['£35','100 Meg','70 tv channels','Weekend free calls and 0870','Tivo box'],
				['£45','100 Meg','150 tv channels','Weekend free calls and 0870','V6 box (6 channels recording + 1 to watch)'],
				['£55','200 Meg','230 tv channels','Weekend free calls and 0870','V6 box (6 channels recording + 1 to watch)'],
				['£62','200 Meg','250 tv channels and all Sky Cinema channels','Weekend free calls and 0870','V6 box (6 channels recording + 1 to watch)'],
				['£71','200 Meg','240 tv channels including all Sky Sports channels (e.g. F1) and BT Sports','Weekend free calls and 0870','V6 box (6 channels recording + 1 to watch)'],
				['£81','200 Meg','245 tv channels including all Sky Cinema channels, all Sky Sports channels (e.g. F1) and BT Sports','Weekend free calls and 0870','V6 box (6 channels recording + 1 to watch)'],
				['£85','300 Meg','245 tv channels including all Sky Cinema channels, all Sky Sports channels (e.g. F1) and BT Sports','Talk anytime','2 x V6 boxes (12 channels recording + 2 to watch)']
		];
		
		var selectedPackage = bundleData[productID];
		$('#ppm').html(selectedPackage[0]);
		$('#bandwidth').html(selectedPackage[1]);
		$('#channels').html(selectedPackage[2]);
		$('#benefits').html(selectedPackage[3]);
		$('#cablebox').html(selectedPackage[4]);
	});
	
	
	
});


