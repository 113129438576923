/**
 * @author inigo.villalba
 */

var api = new apiInterface();

var regionalMap = {};

regionalMap = {
    init: function () {
        if ($('#regionSelect')) {
            $('#lead_script').on('click','#RegionLabelsLeft .RegionLabel input, #RegionLabelsRight .RegionLabel input',function () {
                regionalMap.radioBtnClick(this);
            });
            $('#lead_script').on('.MapLabel', function () {
                regionalMap.labelClick(this);
            });
        }
    },
    radioBtnClick: function (el) {
        el.blur();
        $('input').parent().removeClass('selected');
        $('.MapLabel').removeClass('selected');
        $(el).parent().parent().next().addClass('selected');
        $(el).parent().addClass('selected');
    },
    labelClick: function (el) {
		//console.log(el);
        $('input').parent().removeClass('selected');
        $('input').removeAttr('checked');
        $(el).prev().find('input').prop("checked", true);
        $('.MapLabel').removeClass('selected');
        $(el).addClass('selected');
        
        $(el).prev().find('input').parent().addClass('selected');
    }
}


$(document).ready(function () {
    regionalMap.init();

	$('#lead_script').on('click',"#submitregiondata",function () {
		var quote_id = $('#lead_script').data("quoteid");
        var selectedRegion = $("input[type='radio'][name='RegionCode']:checked").val();
		if(selectedRegion > 0 ){
			api.update_region(quote_id,selectedRegion,function(response){
				if(response.success){
					window.lead_script.handleStep(window.lead_script.magicPlease('set_current_supply'));
				}
			});
		}
        return false;
    });
	$('#lead_script').on('click',"#regionSelect input[type='radio']",function () {
		var quote_id = $('#lead_script').data("quoteid");
        var selectedRegion = $("#regionSelect input[type='radio'][name='RegionCode']:checked").val();
		if(selectedRegion > 0 ){
			var next_step = $('.next-button button').data('step');
			api.update_region(quote_id,selectedRegion,function(response){
				if(response.success){
					window.lead_script.handleStep(next_step);
				}
			});
		}
        return false;
    });
	
	
});