$(document).ready(function() {
    const datatable = $('#incentive-token-table').DataTable({
        dom: 'flrtiBp',
        ajax: "/get/incentive/log",
        ordering: true,
        responsive: true,
        bAutoWidth:false,
        order: [[ 4, "desc" ]],
        autoWidth: false,
        lengthMenu: [25, 40, 100],
        select: true,
        buttons: [
                'copyHtml5',
                'print',
                {
                    extend: 'collection',
                    text: 'Export',
                    buttons: [
                        'csvHtml5',
                        'pdfHtml5'   
                    ]
                }
        ],
        initComplete: function () {}
    });
	
	
	
	if($('input[name="incentive-tokens-date-filter"]').length > 0){
        $('input[name="incentive-tokens-date-filter"]').daterangepicker({

            "locale": {
                "format": 'DD/MM/YYYY',
                "separator": "-"
            },
            "linkedCalendars": false,
            "showDropdowns": true
        },
        function(start, end, label) {

            const datatable = $('#incentive-token-table').DataTable({
                destroy: true,
                dom: 'flrtiBp',
                ajax: "/get/incentive/log?all=true&start="+start+"&end="+end,
                pageLength: 40,
                ordering: true,
                responsive: true,
                order: [[ 4, "desc" ]],
                bAutoWidth:false,
                autoWidth: false,
                lengthMenu: [25, 40, 100],
                select: true,
                buttons: [
                    'copyHtml5',
                    'print',
                    {
                        extend: 'collection',
                        text: 'Export',
                        buttons: [
                            'csvHtml5',
                            'pdfHtml5'   
                        ]
                    }
                ],
                
                initComplete: function () {} 
            });		
        });
    }
	
	
	$('#incentive-token-table').on('click', '#issue_prize', function(){
			
		var log_item = $(this).data('log-id');
		var that = this;
		$.ajax({
			data: {
				log_item_id:log_item
			},
			type: 'POST',
			url: '/incentives/issue_prize',
			dataType: 'json',
			success: function(response) {
				
				if(response.success){
					$(that).hide();
					if(isset(response.new_text)){
						that.replaceWith(response.new_text);
					}
					success_message('updated');
				}
				else{
					error_message(response.error);
				}

			},
			error: function (error) {
				error_message('something went wrong');
			}
		});
		
	})
	
});    



