//only used for insurance - in API now as anticipating eventually we'll scrape paymentshield?
$(document).ready(function() {
  
	//$('#lead_script').on('click','#insuranceSendEmailSubmit',function(e){
	$('#lead_script').on('submit','form#insurance-form',function(e){
		e.preventDefault();
		
		//console.log('sending the email');
		var quoteID = window.lead_script.quoteId;
		var revisedEmail = document.getElementById('revised-email').value;
		var filelist = document.getElementById('uploadFiles').files;
		var ourFormData = new FormData($(this)[0]);
	
		var file = null;
		var that = this;
		var canSend = -1;
		var validation_reasons = ['Please check email address','Please select as least one PDF','Please select only PDF files'];
		//console.log(filelist);
		
		//@TODO replace with email check
		if (revisedEmail.length < 1) {
			canSend = 0;	
		}
		
		if (filelist.length  < 1) {
			canSend = 1;
		}
		
		for (var counter = 0; counter < filelist.length; counter++) {
			//console.log('processing...'+filelist[counter].name);
			if (filelist[counter].name.indexOf('.pdf')===-1) {
				canSend = 2;
				continue;
			}
		}
		
		//is there a problem?
		if(canSend>=0) {
			//@TODO replace with modal and possibly set focus to correct first issue accordion div
			alert(validation_reasons[canSend]);
		} 
		else {
			//console.log('trying a post');
			//all good!
			$.ajax({
				url: "/upload/insurancefiles",
				type:"POST",
				data: ourFormData,
				cache: false,
				processData: false,
				contentType: false,
				success: function(response){
					if(response.errors){
						//@TODO replace with modal
						alert('Problems with automated email send; please send manually');
					}else {
						//@TODO replace with modal
						alert("Email successfully sent");
						window.lead_script.handleStep($(that).data('success-step'));
					}
				},
				error : function(error) {
					//console.log(error);
				}		
			});	
		}
	});
	
	$("#lead_script").on('click',"#insurance-policy-startdate",function(e){
		$(e.target).first('input').daterangepicker({
			"locale": {
				"format": 'DD-MM-YYYY',
				"separator": "-" 
			},
			"singleDatePicker": true,
			"showDropdowns": true
		});
		$(e.target).focus();
	});

	$("#lead_script").on('click',"#insurance_policy_start_date",function(){
		$(this).daterangepicker({
			"locale": {
				"format": 'DD-MM-YYYY',
				"separator": "-" 
			},
			"singleDatePicker": true,
			"showDropdowns": true
		});
		$(this).trigger('focus');
	});
	
	$("#lead_script").on('click',"#insurance-cb-priority-services-register",function(e){
		var insurance_priority_services_register = $('#insurance-cb-priority-services-register').prop('checked');
		var quoteID = window.lead_script.quoteId;
		$.ajax({
			url: "/orders/updatePSR",
			type:"POST",
			dataType: 'json',
			data: {insurance_priority_services_register: insurance_priority_services_register,
				   quoteID: quoteID
				  },
			cache: false,
			success: function(response){
				if(response.errors){
					//@TODO replace with modal
					alert('Problems with registering the PSR on the CRM; please notify IT Support');
				}else {
					//@TODO replace with modal
				}
			},
			error : function(error) {
				//console.log(error);
			}		
		});	
	});
	
	
	var getInsuranceAddressValue = function(){
		
		var data = {};
		if($('#insurance_address_not_shown').is(':visible')){
			//we have selected from a button so the value should be in our hidden input 
			
			data.address_id = $('#insurance_selected_address').val().trim();
			
		}
		else if($('#insurance_postcode_lookup_go').is(':visible')){
			//we used the postcode lookup to find the address
			var selected_option = $('#insurance_postcode_addresses option:selected');
			if(selected_option.data('full-address') == undefined){
				return window.insuranceAddressError();
			}
			data.full_address = selected_option.data('full-address');
			
		}
		else if($('#manual-house-number').is(':visible')){
			
			//we are on manual addess screen
			var manual_address = {};
			$('#insurance_manual_address input').each(function(){
				manual_address[$(this).attr('name')] = $(this).val();
			});
			data.manual_address = manual_address;
		}
		
		return data;
		
		
	}

	$('#lead_script').on('click','#complete_insurance_order',function(e){
			showLoader();
			//
			var quote_id = window.lead_script.quoteId;
			makeRequest('/orders/complete-insurance-order/'+quote_id,{},function(response){
				hideLoader();
				if(isset(response.success) && response.success){
					success_message(response.message);
					window.location.reload();
				}
				else{
					if(isset(response.error)){
						error_message(response.error);
					}
					else{
						error_message('error confirming order')
					}
				}
			});
		});
	
	$('#lead_script').on('click','#insuranceOrderSubmit',function(e){
		
		//console.log('Clicked to save insurance order data to CRM');
		var quoteID = window.lead_script.quoteId;
		var externalID = document.getElementById('insurance-external-reference').value;
		var productID = document.getElementById('insurance-product').value;
		//console.log(productID);
		var productValue = document.getElementById('insurance-product-value').value;
		var policyReferenceID = document.getElementById('insurance-policy-reference').value;
		var policyPaymentPreferenceID = document.getElementById('insurance-payment-method').value;
		var policyStartDate = document.getElementById('insurance-policy-startdate').value;
		
		//address section - addition 
		var data = getInsuranceAddressValue();
		
		data.quoteID =quoteID;
		data.productID = productID;
		data.productValue = productValue;
		data.externalID = externalID;
		data.externalOrderNumber = policyReferenceID;
		data.policyPaymentPreference = policyPaymentPreferenceID;
		data.policyStartDate = policyStartDate;

		var quotes_to_confirm = [];
		$('button.btn-remove-quote:visible').each(function(){
			quotes_to_confirm.push($(this).data('quote-id'));
		});
		data.additional_orders = quotes_to_confirm;
		
		//regex pair for external order numbers 
		var crmRefRegEx = /(PSL-INT-)([0-9]{7})|([0-9]{13})/;
		// var crmRefRegEx2 = /(UINH)([0-9]{7})|([0-9]{13})/;
		var crmRefRegEx2 = /^[A-Za-z]{3,4}[0-9]{7,13}$/;
		var crmPolicyRefRegEx = /(BPNL)([0-9]{8})/;
		
		//our error message timeout in milliseconds
		var timeoutSeconds = 3000;
		
		//validation chain, top to bottom
		if (!externalID.match(crmRefRegEx) && !externalID.match(crmRefRegEx2)){
			$('#externalReferenceError').html('Policy\'s external reference must be in format PSL-INT-0123456 or UINH0123456');
					setTimeout(function() {
						$('#externalReferenceError').html('');
					},timeoutSeconds);
		} 
		else {
			if (productID==='select') {
				$('#insuranceProductError').html('Please select a policy type');
				setTimeout(function() {
					$('#insuranceProductError').html('');
				},timeoutSeconds);
			} 
			else {
//				if (!policyReferenceID.match(crmPolicyRefRegEx)) {
//					$('#externalPolicyRefError').html('Policy\'s reference must be in format BPNL01234567');
//						setTimeout(function() {
//							$('#externalPolicyRefError').html('');
//						},timeoutSeconds);
//				} else {
					if (productValue < 1) { 
						$('#insuranceValueError').html('Please enter a policy value');
						setTimeout(function() {
							$('#insuranceValueError').html('');
						},timeoutSeconds);
					} 
					else {
						if (policyPaymentPreferenceID==='select') {
							$('#insurancePaymentMethodError').html('Please select a policy payment preference');
							setTimeout(function() {
								$('#insurancePaymentMethodError').html('');
							},timeoutSeconds);
						} 
						else {
							
							//all good! let's send it!
							$.ajax({
								url: "/orders/updateInsuranceOrder",
								method:"POST",
								data: data,
								success: function(response){
									if(response.errors){
										$('#insuranceOrderSubmitError').html('RealCRM2 has encountered an error.');
										setTimeout(function() {
											$('#insuranceOrderSubmitError').html('');
										},timeoutSeconds);
									}else {
										$('#insuranceOrderSubmitError').html('RealCRM2 has updated insurance sale.');
										setTimeout(function() {
											$('#insuranceOrderSubmitError').html('');
											$('#insuranceOrderSubmit').hide();
											$('#insuranceOrderFinish').show();
										},timeoutSeconds);
									}
								},
								error : function(error) {
									//console.log(error);
								}		
							});
						}
					}						
//				}
			}
		}
	});
	
	$('#lead_script').on('click','#accidentProtectionOrderSubmit',function(e){
		
		//console.log('Clicked to save accident protection order data to CRM');
		var quoteID = window.lead_script.quoteId;
		//our error message timeout in milliseconds
		var timeoutSeconds = 1000;					
		//all good! let's send it!
		$.ajax({
			url: "/orders/completeAccidentProtectionOrder",
			method:"POST",
		data: {
			quoteID: quoteID
		},
		success: function(response){
			if(response.errors){
				$('#accidentProtectionOrderSubmitError').html('RealCRM2 has encountered an error.');
				setTimeout(function() {
					$('#accidentProtectionOrderSubmitError').html('');
				},timeoutSeconds);
			}else {
				$('#accidentProtectionOrderSubmitError').html('RealCRM2 has updated insurance sale.');
				setTimeout(function() {
					$('#accidentProtectionOrderSubmitError').html('');
					$('#accidentProtectionOrderSubmit').hide();
					$('#accidentProtectionOrderFinish').show();
					$('#script-referall-block').show();
				},timeoutSeconds);
			}
		},
		error : function(error) {
			//console.log(error);
		}		
		});	
	});
	
});
