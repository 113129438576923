function customerValidator(){
	this.api = new apiInterface();
	var that = this;
	this.validateAll = function(validation,callback){
		this.processSeries(validation,function(results){
			var error_count = 0;
			var errors = [];
			var data = [];
			for(var i = 0; i<results.length;i++){
				if(results[i][0].status === 'error'){
					errors.push(results[i][0].error);
					error_count++;
				}
				else{
					data.push(results[i][0].data);
				}
			}
			if(error_count===0){
				callback(null,data);	
			}
			else{
				callback(errors,data);
			}
		});
	},
	this.validateTitle = function(title,callback){
		var titles = ['Please Select','Mr','Mrs','Miss','Ms','Dr'];
		if(title !== 'undefined' && title !== 'Please Select' && titles.indexOf(title)>0){
			callback({
				status:'ok',
				data : {
					field : 'title',
					value : title
				}
			});
		}else{
			callback({
				status:'error',
				error: { 
					message:'Please enter a valid title',
					target:'title-error'
				}
			});
		}
//		if(document.getElementById("pleaseSelect").value == "select-title")
//		{
//			alert("Please select title");
//			document.getElementById("pleaseSelect").focus();
//		return false;
//		}
	},
	this.validateFirstName = function(name,callback){
		name = name.replace(/[^-a-zA-Z0-9 .'&ñçàáâãäèéêëìíîïòóôõöùúûü]/g, '');
		if(name != 'undefined' && 
				name.length >= 3 && 
				name.match(/^[-a-zA-Z0-9 .'&ñçàáâãäèéêëìíîïòóôõöùúûü]{2,50}$/)
			){
			callback({
				status:'ok',
				data : {
					field : 'first_name',
					value : name
				}
			});
		}else{
			callback({
				status:'error',
				error: {
					message:'Please enter a valid first name',
					target:'firstname-error'
				}
			});
		}
	},
	this.validateLastName = function(name,callback){
		name = name.replace(/[^-a-zA-Z0-9 .'&ñçàáâãäèéêëìíîïòóôõöùúûü]/g, '');
		if(name != 'undefined' && 
				name.length >= 3 &&
				name.match(/^[-a-zA-Z0-9 .'&ñçàáâãäèéêëìíîïòóôõöùúûü]{2,50}$/)
			){
			callback({
				status:'ok',
				data: {
					field : 'last_name',
					value : name
				}
			});
		}else{
			callback({
				status:'error',
				error: {
					message:'Please enter a valid last name',
					target:'lastname-error'
				}
			});
		}
	},
	this.validateDay = function(day,callback){
		if(day !== 'undefined' && day >= 1 && day <= 31){
			callback({status:'ok'});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Please enter a valid DOB',
					target:'dob-error'
				}
			});
		}
	},
	this.validateMonth = function(month,callback){
		if(month !== 'undefined' && month >= 1 && month <= 12){
			callback({status:'ok'});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Please enter a valid DOB',
					target:'dob-error'
				}
			});
		}
	},
	this.validateYear = function(year,callback){
		var min_year = new moment().subtract(120, 'years');
		var max_year = new moment().subtract(17, 'years');
		if(year !== 'undefined' && year > min_year.year() && year < max_year.year()){
			callback({status:'ok'});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Please enter a valid DOB',
					target:'dob-error'
				}
			});
		}
	},
	this.validateYearGeneric = function(year,callback){
		if(year !== 'undefined' && year > 1900){
			callback({status:'ok'});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Please enter a valid DOB',
					target:'dob-error'
				}
			});
		}
	},				
	this.validateDOB = function(day,month,year,callback){
		var callback = callback;
		that.validateDay(day,function(response){
			if(response.status === 'ok'){
				that.validateMonth(month,function(response){
					if(response.status === 'ok'){
						that.validateYear(year,function(response){
							if(response.status === 'ok'){
								var res = callback({
									status: 'ok',
									data: {
										field : 'dob',
										value : day+'-'+month+'-'+year
									}
								});
								//DO NOT REMOVE
								console.log(res);
							}
							else{
								var res = callback(response);
								//DO NOT REMOVE
								console.log(res);
							}
						});
					}
					else {
						var res = callback(response);
						//DO NOT REMOVE
						console.log(res);
					}
				});
			}
			else {
				var res = callback(response);
				//DO NOT REMOVE
				console.log(res);
			}
		});
	},
	this.validateGenericDOB = function(day,month,year,callback){
		var callback = callback;
		that.validateDay(day,function(response){
			if(response.status === 'ok'){
				that.validateMonth(month,function(response){
					if(response.status === 'ok'){
						that.validateYearGeneric(year,function(response){
							if(response.status === 'ok'){
								var res = callback({
									status: 'ok',
									data: {
										field : 'dob',
										value : day+'-'+month+'-'+year
									}
								});
								//DO NOT REMOVE
								console.log(res);
							}
							else{
								var res = callback(response);
								//DO NOT REMOVE
								console.log(res);
							}
						});
					}
					else {
						var res = callback(response);
						//DO NOT REMOVE
						console.log(res);
					}
				});
			}
			else {
				var res = callback(response);
				//DO NOT REMOVE
				console.log(res);
			}
		});
	},		
	this.validatePhone = function(phone,callback){
		if(phone !== 'undefined' &&	phone.match(/^0[0-9 ]{9,24}$/)){
			callback({
				status:'ok',
				data : {
					field : 'telephone',
					value : phone
				}
			});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Please enter a valid phone number',
					target:'phone-error'
				}
			});
		}	
	},
	this.validateEmploymentStatus = function(employment_status,callback){
		if( employment_status !== 'Please Select') {
			callback({
				status:'ok',
				data: {
					field : 'employment_status',
					value : employment_status
				}
			});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Please select a valid employment status',
					target:'employment-status-error'
				}
			});
		}
	},
	this.validateOccupation = function(occupation,callback){
		if(occupation !== '' && occupation !== 'undefined') {
			callback({
				status:'ok',
				data: {
					field : 'occupation',
					value : occupation
				}
			});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Please enter a valid occupation',
					target:'occupation-error'
				}
			});
		}
	},
	this.validateEmployer = function(employer,callback){
		if(employer !== '' && employer !== 'undefined') {
			callback({
				status:'ok',
				data: {
					field : 'employer',
					value : employer
				}
			});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Please enter a valid employer',
					target:'employer-error'
				}
			});
		}
	},
	this.validateIndustry = function(industry,callback){
		if(industry !== '' && industry !== 'undefined') {
			callback({
				status:'ok',
				data: {
					field : 'industry',
					value : industry
				}
			});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Please enter a valid industry',
					target:'industry-error'
				}
			});
		}
	},
	this.validateRetirementAge = function(retirement_age,callback){
		if(retirement_age >= 55 && retirement_age <= 75) {
			callback({
				status:'ok',
				data: {
					field : 'intended_retirement_age',
					value : retirement_age
				}
			});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Retirement age must be between 55 and 75',
					target:'retirement-age-error'
				}
			});
		}
	},		
	this.validateTimeAtAddress = function(time_at_address,callback){
		if(time_at_address >0) {
			callback({
				status:'ok',
				data: {
					field : 'time_at_address',
					value : time_at_address
				}
			});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Please enter 1 or more months',
					target:'time-at-address-error'
				}
			});
		}
	},				
	this.validateMaritalStatus = function(marital_status,callback){
		if(marital_status !== 'Please Select') {
			callback({
				status:'ok',
				data: {
					field : 'marital_status',
					value : marital_status
				}
			});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Please select a marital status',
					target:'marital-status-error'
				}
			});
		}
	},		
	this.validateNationality = function(nationality,callback){
		if(nationality !== 'Please Select') {
			callback({
				status:'ok',
				data: {
					field : 'nationality',
					value : nationality
				}
			});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Please select a nationality',
					target:'nationality-error'
				}
			});
		}
	},		
	this.validateResidence = function(residence,callback){
		if(residence !== 'Please Select') {
			callback({
				status:'ok',
				data: {
					field : 'residence',
					value : residence
				}
			});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Please select a residence',
					target:'residence-error'
				}
			});
		}
	},					
	this.validateEmail = function(email,callback){
		var beefy_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if(!isset(email) || email == '' || email.match(beefy_regex)) {
			callback({
				status:'ok',
				data: {
					field : 'email',
					value : email
				}
			});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Please enter a valid email address',
					target:'email-error'
				}
			});
		}
	},		
			
	this.processSeries = function(callbacks, last) {
		var results = [];
		function next() {
			var callback = callbacks.shift();

			if(callback) {
				callback(function() {
					results.push(Array.prototype.slice.call(arguments));
					next();
				});
			} 
			else {
				last(results);
			}
		}
		next();
	};
}


module.exports = customerValidator;