/* info page, category and group functions below.. */
//editing an info page
var editInfo = function(info_id,callback) {
	$.ajax({
		url: '/templating/info/edit/'+info_id,
			success: function(response) {
				if(response.success){
					$('#info-template-edit-container').html(response.html);
					$('body,html').animate({
						scrollTop: 0
					}, 800);
					$('.summernote').summernote();                        
					callback();
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

//update an info template from posted values and 
var saveInfo = function(info_id, info_template_data,checked_page_groups, callback) {
	$.ajax({
		url: '/templating/info/save',
		data: {
			'info-template-id' : info_id,
			'info-template-data' : info_template_data,
			'info-page-groups' : checked_page_groups
		},
		type: 'POST',
		dataType: 'json',
		success: function(response) {
			if(response.success){
				//rename title in info list if changed
				var info_target = '#info-template-'+info_id;
				var new_title = $('#info-title').val();
				$(info_target).children().eq(1).html(new_title);
				callback();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});	
};

//creates new placeholder info template 
var createNewInfoTemplate = function(name,target) {
	var parent = target.parent();
	var input = $('.search-templates').val();
	parent.find('.errors-container').hide();
	parent.find('span.errors').html('');
	//console.log('creating new sms');
	$.ajax({
		data: {
			'info-template-name' : name
		},
		type: 'POST',
		url: '/templating/info/create-info',
		dataType: 'json',
		success: function(response) {
			parent.find('input[name="info-template-name"]').val('');
			$('#saved-info-templates ul').append(response.html);
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Created '+input);
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
			if(response.error) {
				$('.update-success').hide();
				parent.find('span.errors').html(response.error);
				parent.find('.errors-container').show();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var deleteInfoTemplate = function(id,target) {
	$.ajax({
		data: {
			'info-template-id' : id
		},
		type: 'POST',
		url: '/templating/info/delete',
		dataType: 'json',
		success: function(response) {
			if(response.error) {
				alert(response.error);
			}
			else{
				//console.log("removing element");
				$(target).remove();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
}

//toggle active an info page
var toggleActiveInfo = function(info_id,state,callback) {
	$.ajax({
		url: '/templating/info/toggle-active',
		data: {
			info_id:info_id,
			state:state
		},
		type: 'POST',
		dataType: 'json',
			success: function(response) {
				if(response.success){
					var info_target = '#info-template-'+info_id;
					var new_enable = $('#info-title').val();
					$(info_target).children().eq(1).html(new_enable);
					callback();
					//console.log("success woop woo");
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

//editing an info category
var editInfoCategory = function(info_category_id,callback) {
	$.ajax({
		url: '/templating/info-category/edit/'+info_category_id,
			success: function(response) {
				if(response.success){
					$('#info-category-template-edit-container').html(response.html);
					$('body,html').animate({
						scrollTop: 0
					}, 800);
					callback();
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

//update an info template from posted values and 
var saveInfoCategory = function(info_category_id, info_category_template_data, callback) {
	$.ajax({
		url: '/templating/info-category/save',
		data: {
			'info-category-template-id' : info_category_id,
			'info-category-template-data' : info_category_template_data
		},
		type: 'POST',
		dataType: 'json',
		success: function(response) {
			if(response.success){
				//rename title in info list if changed
				var info_category_target = '#info-category-template-'+info_category_id;
				var new_title = $('#info-category-title').val();
				$(info_category_target).children().eq(1).html(new_title);
				callback();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});	
};

var createNewInfoCategoryTemplate = function(name,target) {
	var parent = target.parent();
	var input = $('.search-templates').val();
	parent.find('.errors-container').hide();
	parent.find('span.errors').html('');
	//console.log('creating new sms');
	$.ajax({
		data: {
			'info-category-template-name' : name
		},
		type: 'POST',
		url: '/templating/info-category/create-info-category',
		dataType: 'json',
		success: function(response) {
			parent.find('input[name="info-category-template-name"]').val('');
			$('#saved-info-category-templates ul').append(response.html);
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Created '+input);
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
			if(response.error) {
				$('.update-success').hide();
				parent.find('span.errors').html(response.error);
				parent.find('.errors-container').show();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var deleteInfoCategoryTemplate = function(id,target) {
	$.ajax({
		data: {
			'info-category-template-id' : id
		},
		type: 'POST',
		url: '/templating/info-category/delete',
		dataType: 'json',
		success: function(response) {
			if(response.error) {
				alert(response.error);
			}
			else{
				//console.log("removing element");
				$(target).remove();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
}
	
//toggle active an info page
var toggleActiveInfoCategory = function(info_category_id,state,callback) {
	$.ajax({
		url: '/templating/info-category/toggle-active',
		data: {
			info_category_id:info_category_id,
			state:state
		},
		type: 'POST',
		dataType: 'json',
			success: function(response) {
				if(response.success){
					var info_category_target = '#info-category-template-'+info_category_id;
					var new_enable = $('#info-category-title').val();
					$(info_category_target).children().eq(1).html(new_enable);
					callback();
					//console.log("success woop woo");
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

//editing an info group
var editInfoGroup = function(info_group_id,callback) {
	$.ajax({
		url: '/templating/info-group/edit/'+info_group_id,
			success: function(response) {
				if(response.success){
					$('#info-group-edit-container').html(response.html);
					$('body,html').animate({
						scrollTop: 0
					}, 800);                      
					callback();
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

//update an info template from posted values and 
var saveInfoGroup = function(info_group_id, info_group_form_data, callback) {
	$.ajax({
		url: '/templating/info-group/save',
		data: {
			'info-group-id' : info_group_id,
			'info-group-form-data' : info_group_form_data
		},
		type: 'POST',
		dataType: 'json',
		success: function(response) {
			if(response.success){
				//rename group name in info group list if changed
				var info_group_target = '#info-group-'+info_group_id;
				var new_title = $('#info-group-title').val();
				$(info_group_target).children().eq(1).html(new_title);
				callback();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});	
};

var createNewInfoGroup = function(name,target) {
	var parent = target.parent();
	var input = $('.search-templates').val();
	parent.find('.errors-container').hide();
	parent.find('span.errors').html('');
	//console.log('creating new info group');
	$.ajax({
		data: {
			'info-group-name' : name
		},
		type: 'POST',
		url: '/templating/info-group/create-info-group',
		dataType: 'json',
		success: function(response) {
			parent.find('input[name="info-group-name"]').val('');
			$('#saved-info-groups ul').append(response.html);
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Created '+input);
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
			if(response.error) {
				$('.update-success').hide();
				parent.find('span.errors').html(response.error);
				parent.find('.errors-container').show();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var deleteInfoGroup = function(id,target) {
	$.ajax({
		data: {
			'info-group-id' : id
		},
		type: 'POST',
		url: '/templating/info-group/delete',
		dataType: 'json',
		success: function(response) {
			if(response.error) {
				alert(response.error);
			}
			else{
				//console.log("removing element");
				$(target).remove();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
}

//toggle active an info group
var toggleActiveInfoGroup = function(info_group_id,state,callback) {
	$.ajax({
		url: '/templating/info-group/toggle-active',
		data: {
			info_group_id:info_group_id,
			state:state
		},
		type: 'POST',
		dataType: 'json',
			success: function(response) {
				if(response.success){
					var info_group_target = '#info-group-'+info_group_id;
					var new_enable = $('#info-group-name').val();
					$(info_group_target).children().eq(1).html(new_enable);
					callback();
					//console.log("success woop woo");
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

/* 
 * 
 * email and sms function below.. 
 * 
 */

//creates new placeholder email template 
var createNewEmailTemplate = function(name,target) {
	var parent = target.parent();
	parent.find('.errors-container').hide();
	parent.find('span.errors').html('');

	var data = {
		'email-template-name' : name
	}; 
	makeRequest('/templating/emails/create', data, function(response){
		parent.find('input[name="email-template-name"]').val('');
		$('#saved-email-templates ul').append(response.html);
		if(response.error) {
			parent.find('span.errors').html(response.error);
			parent.find('.errors-container').show();
		}
	});
};

var editEmail = function(email_id,callback) {
		$.ajax({
			url: '/templating/emails/edit/'+email_id,
				success: function(response) {
					if(response.success){
						$('#email-template-edit-container').html(response.html);
						$('body,html').animate({
							scrollTop: 0
						}, 800);
						callback();
				}
					//console.log(response);
			},
			error: function (error) {
				//console.log(error);
			}
		});
	};
	
//editing an sms
var editSms = function(sms_id,callback) {
	$.ajax({
		url: '/templating/sms/edit/'+sms_id,
			success: function(response) {
				if(response.success){
					$('#sms-template-edit-container').html(response.html);
					$('body,html').animate({
						scrollTop: 0
					}, 800);
					callback();
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

//update an sms template from posted values and 
var saveSms = function(sms_id, sms_template_data, callback) {
	$.ajax({
		url: '/templating/sms/save',
		data: {
			'sms-template-id' : sms_id,
			'sms-template-data' : sms_template_data
		},
		type: 'POST',
		dataType: 'json',
		success: function(response) {
			if(response.success){
				//rename ident in sms list if changed
				var sms_target = '#sms-template-'+sms_id;
				var new_ident = $('#sms-ident').val();
				$(sms_target).children().eq(1).html(new_ident);
				callback();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});	
};

//update an email template from posted values
var saveEmail = function(email_id, email_template_data, callback) {
	var data = {
		'email-template-id' : email_id,
		'email-template-data' : email_template_data
	}
	makeRequest('/templating/emails/save', data, function(response){	
		if(response.success){
			//rename ident in sms list if changed
			var email_target = '#email-template-'+email_id;
			var new_ident = $('#email-ident').val();
			$(email_target).children().eq(1).html(new_ident);
			callback();
		}
		else{
			var error = 'unable to save template';
			if(isset(response.error)){
				error = response.error;
			}
			error_message(error);
		}
	});
};

//preview an sms template from posted values
var previewSms = function(sms_id, sms_template_data, callback) {
	$.ajax({
		url: '/templating/sms/preview',
		data: {
			'sms-template-id' : sms_id,
			'sms-template-data' : sms_template_data
		},
		type: 'POST',
		dataType: 'json',
		success: function(response) {
			//console.log(response);
			if(response.success){	
				$('#screen-preview').html(response.html);
				$('#screen-preview').show();
				callback();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});	
};

//creates new placeholder sms template 
var createNewSMSTemplate = function(name,target) {
	var parent = target.parent();
	var input = $('.search-templates').val();
	parent.find('.errors-container').hide();
	parent.find('span.errors').html('');
	//console.log('creating new sms');
	$.ajax({
		data: {
			'sms-template-name' : name
		},
		type: 'POST',
		url: '/templating/sms/create-sms',
		dataType: 'json',
		success: function(response) {
			parent.find('input[name="sms-template-name"]').val('');
			$('#saved-sms-templates ul').append(response.html);
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Created '+input);
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
			if(response.error) {
				$('.update-success').hide();
				parent.find('span.errors').html(response.error);
				parent.find('.errors-container').show();
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

//editing an email
var editEmail = function(email_id,callback) {
	$.ajax({
		url: '/templating/emails/edit/'+email_id,
			success: function(response) {
				if(response.success){
					$('#email-template-edit-container').html(response.html);
					$('body,html').animate({
						scrollTop: 0
					}, 800);
					callback();
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var filterByText = function(target_div,value){
	var target = $(this).parent().parent().find(target_div+' ul');
	if(value === ""){
		$(target).find('li').show();
	} 
	else {
		$(target).find('li').each(function(){
			var text = $(this).text();
			var compare = text.toLowerCase();
			(compare.indexOf(value) >=0) ? $(this).show() : $(this).hide();
		});
	};
};


var sort_email_teams_update = function(){
	var active_teams = [];
	
	$('#active_team_sort li').each(function(){
		if(active_teams.indexOf($(this).data('team-id')) == -1){
			active_teams.push($(this).data('team-id'));
		}
		else{
			$(this).remove();
		}
	});
	
	var template_id = $('#email-template-id').data('email-template-id');
	
	updateTeamLinks(template_id, active_teams);
	
}

var email_teams_sortable = function() {
	$( "ul#all_teams_sort" ).sortable({
		connectWith: "#active_team_sort",
//		appendTo: "body",
//		helper: "clone",
//		containment: "window",
		update: function (e, ui) {
			sort_email_teams_update();
		}
	});
	$( "ul#active_team_sort" ).sortable({
		connectWith: "#all_teams_sort",
//		appendTo: "body",
//		helper: "clone",
//		containment: "window",
		update: function (e, ui) {
			sort_email_teams_update();
		}
	});
	$(".reportSortable").sortable();
};

var success_message = function(){
	$('.update-success').fadeIn().text('Updated');
	setTimeout(function(){
		$('.update-success').fadeOut();
	},5000);
};

var updateTeamLinks = function(template_id, teams){
	$.ajax({
		type: "POST",
		dataType: 'json',
		data: {
			'teams' : teams,
		},
		url: '/templating/emails/update_teams/'+template_id,
		success: function(response) {
			if(checkErrors(response)){
				success_message();
				$('#active_team_sort  div.delete-trigger').show();
				$('#all_teams_sort  div.delete-trigger').hide();
			}
		},
		error: function (error) {
			alert(error);
		}
	});
};

var success_message = function (text) {
	if (!text) {
		text = 'Updated';
	}
	$('.update-success').fadeIn().text(text);
	setTimeout(function () {
		$('.update-success').fadeOut();
	}, 5000);
};

var error_message = function (text) {
	$('.update-error').fadeIn().text(text);
	setTimeout(function () {
		$('.update-error').fadeOut();
	}, 5000);
};

var checkErrors = function (response) {
	if (response.success != true) {
		if (response.error) {
			if (response.error.length > 1) {
				error_message(response.error);
				return false;
			}
		}
		error_message('an unknown error occurred');
		return false;
	}
	return true;

}



$( document ).ready(function() {

	if($('.summernote').length > 0){
		$('.summernote').summernote();   
	}

	// this code hides and shows bcc and cc for the email templating page
	$('#template-wrapper').on('click', '#add-cc', function() {
		$('#carbon-copy').slideToggle("fast");
	});
	
	$('#template-wrapper').on('click', '#add-bcc', function() {
		$('#blank-carbon-copy').slideToggle("fast");
	});
	
	// search for email templates
	$('#template-wrapper').on('keyup','.search-templates',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-email-templates ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});
	
	// search for email template variables collapses the panel for results
	$('#template-wrapper').on('keyup','.search-variables',function(){
		var valThis = $(this).val().toLowerCase();
		var collapseIn = $('.all-the-variables').children().children();
		var target = $(this).parent().parent().find('.all-the-variables');
		if(valThis === ""){
			collapseIn.removeClass('in');
			$(target).find('a').show();
		} 
		else {
			$(target).find('a').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
				$(this).parent().parent().addClass('in');
			});
	   };
	});
		
	//sms templating search
	$('#template-wrapper').on('keyup','.search-templates',function(){
	//filterByText('#saved-sms-templates',$(this).val().toLowerCase());
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-sms-templates ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});	
	
	// this is related to the sms text messaging live preview on mobile device
	$('#sms-template-edit-container').on('keyup','#sms-screen',function(){
		var screen = $("#sms-screen").val().length;
		$("#screen-preview").show().html($("#sms-screen").val());
		updateCount(screen);
		if(screen === 0) {
			$("#screen-preview").hide();
		}
	});

	function updateCount(cs) {
		$('#char-count').text(cs);
		if(cs >= 160) {
			$('.two-sms').show().css("color", "red");
			$('#char-count').css("color", "red");
		} 
		else {
			$('.two-sms').hide();
			$('#char-count').css("color", "green");
		}
	}
	
	//create a new email template
	$('#saved-email-templates-wrapper').on('click','#create-email-template',function(){
		var name = $(this).parent().find('input[name="email-template-name"]').val();
		var target = $(this).parent();
		if (name < 4) {
			target.parent().find('.module-error').fadeIn('fast').text("Please enter a longer name");
		} 
		else {
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Created - '+name);
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		}
		createNewEmailTemplate(name, target);
	});
	
	//create a new sms template
	$('#template-wrapper').on('click','#create-sms-template',function(){
		var name = $(this).parent().find('input[name="sms-template-name"]').val();
		var target = $(this).parent();
		if (name < 4) {
			target.parent().find('.module-error').fadeIn('fast').text("Please enter a longer name");
		} 
		else {
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Created - '+name);
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		}
		createNewSMSTemplate(name, target);
	});
	
	//editing an email
	$('#saved-email-templates').on('click', '.edit-trigger', function() {
	//	console.log('editing an email:)');
		var target = $(this).parent().attr('id').replace('email-template-','');
		editEmail(target,function() {
			$('#email-template-edit-container').show();
			email_teams_sortable();
			$('#all_teams_sort  div.delete-trigger').hide();
		});
	});
	
	//saving an email
	$('#email-template-edit-container').on('click','#email-template-editor-save', function(e) {
	//	console.log('saving an email');
		e.preventDefault();
		var email_template_data = $('#form-email-template').serializeArray();
		var email_id = $('#email-template-id').data('email-template-id');
		saveEmail(email_id,email_template_data,function() {
			$('#email-template-edit-container').hide();
		});
		$('.update-error').hide();
		$('.update-success').fadeIn().text('Template saved');
		setTimeout(function(){
			$('.update-success').fadeOut();
		},5000);
	});

	//click on template variables and insert into document at last caret position
	$('#email-template-edit-container').on('click', '.template-variable-list a', function(e) {
		var caret = $('.template-editor-container').find('textarea').prop('selectionStart');
		e.preventDefault();
		$('.template-editor-container').find('textarea').focus();
		var text = "{{ $s('" + $(this).data('value') + "') }}";
		var text_area = $('.template-editor-container').find('textarea');
		var current_text = text_area.val();
		text_area.val(current_text.substr(0, caret) + text + current_text.substr(caret));
	});
	
	//removing an email team link
	$('#email-template-edit-container').on('click','#active_team_sort .delete-trigger', function(e) {
		$(this).parent().remove();
		sort_email_teams_update();
	});
	
	//editing an sms
	$('#saved-sms-templates').on('click', '.edit-trigger', function() {
	// console.log('editing an sms :)');
		var target = $(this).parent().attr('id').replace('sms-template-','');
		editSms(target,function() {
			$('#sms-template-edit-container').show();
		});
	});

	//saving an sms
	$('#sms-template-edit-container').on('click','#sms-template-editor-save', function(e) {
	//	console.log('saving an sms');
		e.preventDefault();
		var sms_template_data = $('#form-sms-template').serializeArray();
		var sms_id = $('#sms-template-id').data('sms-template-id');
		saveSms(sms_id,sms_template_data,function() {
			$('#sms-template-edit-container').hide();
		});
		$('.update-error').hide();
		$('.update-success').fadeIn().text('Template saved');
		setTimeout(function(){
			$('.update-success').fadeOut();
		},5000);
	});
	
	//updating an sms ident
	$('#sms-template-edit-container').on('click','#sms-template-sms-ident-save', function(e) {
	//	console.log('updating an sms');
		e.preventDefault();
		var sms_template_data = $('#form-sms-template').serializeArray();
		var sms_id = $('#sms-template-id').data('sms-template-id');
		saveSms(sms_id,sms_template_data,function() {
			$('#sms-template-edit-container').hide();
		});
	});
	
	//live preview
	$('#sms-template-edit-container').on('click','#sms-template-editor-preview', function(e) {
		//console.log('live preview sms');
		e.preventDefault();
		var sms_template_data = $('#form-sms-template').serializeArray();
		var sms_id = $('#sms-template-id').data('sms-template-id');
		previewSms(sms_id,sms_template_data,function() {
			//things to do on return
		});
		
	});
	
	//this class needs renaming at some point Si!
	$('#saved-sms-templates').on('click', '.delete-trigger', function() {
		//console.log('delete sms');
	});
	
	//click on template variables and insert into document at last caret position
	$('#sms-template-edit-container').on('click', 'a', function(e) {
		var caret = $('.template-editor-container').find('textarea').prop('selectionStart');
		e.preventDefault();
		$('.template-editor-container').find('textarea').focus();
		var text = "{{ $s('" + $(this).data('value') + "') }}";
		var text_area = $('.template-editor-container').find('textarea');
		var current_text = text_area.val();
		text_area.val(current_text.substr(0, caret) + text + current_text.substr(caret));
	});
	
	
	
/* INFO PAGES */
	
	//editing an info page
	$('#saved-info-templates').on('click', '.edit-trigger', function() {
		//console.log('editing an info template:)');
		var target = $(this).parent().attr('id').replace('info-template-','');
		editInfo(target,function() {            
			$('#info-template-edit-container').show();
			$('.select2-multi').select2({'width':'100%'});
		});
	});
	
	//saving an info template
	$('#info-template-edit-container').on('click','#info-template-editor-save', function(e) {
		//console.log('saving an info page');
		e.preventDefault();
		var group_inputs  = $(this).parent().parent().find('div#info-page-groups input[type=checkbox]');
		var checked_page_groups = {};
		
		if($(group_inputs).length > 0) {
			$(group_inputs).each(function(){
				checked_page_groups[$(this).data('info-page-group-id')] = $(this).is(':checked');
			});		
			var info_template_data = $('#form-info-template').serializeArray();
			var info_id = $('#info-template-id').data('info-template-id');		
			saveInfo(info_id,info_template_data,checked_page_groups,function() {
				$('#info-template-edit-container').hide();
			});
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Info Template saved');
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		}
		else {
			//if no checkbox selected
			alert("please assign the info page to at least one group");
		}
	});
	
	//create a new info page 
	$('#template-wrapper').on('click','#create-info-template',function(){
		var name = $(this).parent().find('input[name="info-template-name"]').val();
		var target = $(this).parent();
		if (name.length < 4) {
			target.parent().find('.module-error').fadeIn('fast').text("Please enter a longer name");
			return;
		} 
		else {
			createNewInfoTemplate(name, target);
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Created - '+name);
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		}
	});
	
	//delete an info page 
	$('#saved-info-templates').on('click', '.delete-trigger', function() {
		//console.log('delete info');
		if( ! confirm("are you sure?")) {
			alert("cancelled delete");
		}
		else {
			var info_page_id = $(this).parent().attr('id').replace('info-template-','');
			//console.log(name);
			var target = $(this).parent();
			deleteInfoTemplate(info_page_id,target);
		}
	});
	
	//info page search
	$('#template-wrapper').on('keyup','.search-templates',function(){
	//filterByText('#saved-sms-templates',$(this).val().toLowerCase());
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-info-templates ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});	
	
	//enabling an info page
	$('#saved-info-templates').on('click', '.info-page-enable-trigger input', function() {
		if( ! confirm("are you sure?")) {
			alert("cancelled page enable");
		}
		else {
			var info_page_id = $(this).parent().parent().attr('id').replace('info-template-','');
			var state = $(this).is(':checked');
			//var target = $(this).parent();
			toggleActiveInfo(info_page_id,state,function(){
				$('.update-error').hide();
				$('.update-success').fadeIn().text('Info Template enabled');
				setTimeout(function(){
					$('.update-success').fadeOut();
				},5000);
			});
			
		}
	});
	
	
/* INFO CATEGORIES */	
	
	//editing an info category 
	$('#saved-info-category-templates').on('click', '.edit-trigger', function() {
		//console.log('editing an info template:)');
		var target = $(this).parent().attr('id').replace('info-category-template-','');
		editInfoCategory(target,function() {
            $('#info-category-template-edit-container').show();
        });
	});
	
	//saving an info category 
	$('#info-category-template-edit-container').on('click','#info-category-template-editor-save', function(e) {
		//console.log('saving an info category');
		e.preventDefault();
		var info_category_template_data = $('#form-info-category-template').serializeArray();
		var info_category_id = $('#info-category-template-id').data('info-category-template-id');
		saveInfoCategory(info_category_id,info_category_template_data,function() {
			$('#info-category-template-edit-container').hide();
		});
		$('.update-error').hide();
		$('.update-success').fadeIn().text('Info Template saved');
		setTimeout(function(){
			$('.update-success').fadeOut();
		},5000);
	});
	
	//create a new info category 
	$('#template-wrapper').on('click','#create-info-category-template',function(){
		var name = $(this).parent().find('input[name="info-category-template-name"]').val();
		var target = $(this).parent();
		if (name.length < 4) {
			target.parent().find('.module-error').fadeIn('fast').text("Please enter a longer name");
			return;
		} 
		else {
			createNewInfoCategoryTemplate(name, target);
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Created - '+name);
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);		}

	});
	
	//delete an info category 
	$('#saved-info-category-templates').on('click', '.delete-trigger', function() {
		//console.log('delete info');
		if( ! confirm("are you sure?")) {
			alert("cancelled delete");
		}
		else {
			var info_category_page_id = $(this).parent().attr('id').replace('info-category-template-','');
			//console.log(name);
			var target = $(this).parent();
			deleteInfoCategoryTemplate(info_category_page_id,target);
		}
	});
	
	//info category search
	$('#template-wrapper').on('keyup','.search-templates',function(){
	//filterByText('#saved-sms-templates',$(this).val().toLowerCase());
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-info-category-templates ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});	
	
	//enabling an info category
	$('#saved-info-category-templates').on('click', '.info-page-enable-trigger input', function() {
		if( ! confirm("are you sure?")) {
			alert("cancelled page enable");
		}
		else {
			var info_category_id = $(this).parent().parent().attr('id').replace('info-category-template-','');
			var state = $(this).is(':checked');
			//var target = $(this).parent();
			toggleActiveInfoCategory(info_category_id,state,function(){
				$('.update-error').hide();
				$('.update-success').fadeIn().text('Info Category toggled');
				setTimeout(function(){
					$('.update-success').fadeOut();
				},5000);
			});
			
		}
	});
	
	
/* INFO GROUPS */

	//editing an info group
	$('#saved-info-groups').on('click', '.edit-trigger', function() {
		//console.log('editing an info template:)');
		var target = $(this).parent().attr('id').replace('info-group-','');
		editInfoGroup(target,function() {            
			$('#info-group-edit-container').show();
		});
	});
	
	//saving an info group
	$('#info-group-edit-container').on('click','#info-group-editor-save', function(e) {
	//console.log('saving an info group');
		e.preventDefault();
		var info_group_form_data = $('#form-info-group').serializeArray();
		var info_group_id = $('#info-group-id').data('info-group-id');
		saveInfoGroup(info_group_id,info_group_form_data,function() {
			$('#info-group-edit-container').hide();
		});
		$('.update-error').hide();
		$('.update-success').fadeIn().text('Info Group saved');
		setTimeout(function(){
			$('.update-success').fadeOut();
		},5000);
	});
	
	//create a new info group
	$('#template-wrapper').on('click','#create-info-group',function(){
		var name = $(this).parent().find('input[name="info-group-name"]').val();
		var target = $(this).parent();
		if (name.length < 4) {
			target.parent().find('.module-error').fadeIn('fast').text("Please enter a longer name");
			return;
		} 
		else {
			createNewInfoGroup(name, target);
			$('.update-error').hide();
			$('.update-success').fadeIn().text('Created - '+name);
			setTimeout(function(){
				$('.update-success').fadeOut();
			},5000);
		}		
	}); 
	
	//delete an info category 
	$('#saved-info-groups').on('click', '.delete-trigger', function() {
		//console.log('delete info');
		if( ! confirm("are you sure?")) {
			alert("cancelled delete");
		}
		else {
			var info_group_id = $(this).parent().attr('id').replace('info-group-','');
			//console.log(name);
			var target = $(this).parent();
			deleteInfoGroup(info_group_id,target);
		}
	});
	
	//info group search
	$('#template-wrapper').on('keyup','.search-templates',function(){
	//filterByText('#saved-sms-templates',$(this).val().toLowerCase());
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-info-groups ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});	
	
	//enabling an info category
	$('#saved-info-groups').on('click', '.info-page-enable-trigger input', function() {
		if( ! confirm("are you sure?")) {
			alert("cancelled page enable");
		}
		else {
			var info_group_id = $(this).parent().parent().attr('id').replace('info-group-','');
			var state = $(this).is(':checked');
			//var target = $(this).parent();
			toggleActiveInfoGroup(info_group_id,state,function(){
				$('.update-error').hide();
				$('.update-success').fadeIn().text('Info Category toggled');
				setTimeout(function(){
					$('.update-success').fadeOut();
				},5000);
			});
			
		}
	});

	$('body').on('click', '#email-template-editor-preview', function () {
		showModal('sendEmailPreviewModal');
	});

	$('body').on('click', '#send-template-email', function () {
		var data = {};
		data['template_id'] = $('#email-template-edit-container').find('#email-template-id').data('email-template-id');
		data['email_body'] = $('#email-template-edit-container').find('.email-body').find('textarea').val();

		$('#send_email_preview_modal_body').find(':input').each(function(i, input) {
			data[$(input).attr('name')] = $(input).val();
		});

		makeRequest('/templating/send-preview', data, function (response) {
			if (isset(response.success) && response.success) {
				success_message('Email preview has been sent.');
				hideLoader();
			}
			else {
				if (isset(response.error)) {
					hideLoader();
					error_message(response.error);
				}
				else {
					hideLoader();
					error_message('Unable to send email');
				}
			}
		});
	})

	$('select.select2contactsearchmodal').select2({
		dropdownParent: $('#sendEmailPreviewModal'),
		ajax: {
			url: function (params) {
				return '/raw_search?search=' + params.term;
			},
			dataType: 'json',
			processResults: function (data) {

				var usable_data = [];

				//loop over our data to get it in a format usable by select2
				if(data.success == true){
					$.each(data.results.contact, function(match_type, matches){
						$.each(matches, function(i, contact_data){
							usable_data.push({
								'id' : contact_data.id,
								'text' : contact_data.first_name + ' ' + contact_data.last_name + ' - ' + contact_data.telephone
							});
						});

					});
				}
				return {
					results: usable_data
				};
			}
		},
		width: '100%'
	});
});