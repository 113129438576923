const websocket_client = require("./websocket_client");

class chat_websocket extends websocket_client {
    constructor(){
		super();
		this.type = 'chat';
		this.port = 8090;
		this.magic_url = '/wss2/'; // this is the magic part of th url that apache will pick up and redirect to the websocket server -- 
							 // for chat it would be /wss2/
	}


	buildMessagePayload(chat_id, message, type_id, color){
		return JSON.stringify({
			'action' : 'message',
			'payload' : {
				'chat_id' : chat_id,
				'message' : message,
				'type_id' : type_id,
				'color': color
			}
		});
	}

	sendMessage(chat_id, message, color, type_id = 1){
		if(this.isConnected()){
			var payload = this.buildMessagePayload(chat_id, message, type_id, color);
			this.connection.send(payload);
		}
	}

	handleMessageMessage(payload){
		renderChatMessage(payload.chat_id, payload.message_id, payload.body, payload.timestamp, payload.sender, null, payload.color);
	}

	handleNudgeMessage(payload){
		//need to nudge the user and write a system mesage into the chat log
		nudgeUser(function(){
			renderChatMessage(payload.chat_id, payload.message_id, payload.body , 'now', payload.sender, 'system');
		});
	}
	
	
	handleClosedMessage(payload){
		$('.directory-item[data-chat-id="' +payload.chat_id+'"]').addClass('non-active');
		var active_window = $('.active-chat-window-container');
		if($(active_window).data('chat-id') == payload.chat_id){
			//lets go reload it from the server
			openChat(payload.chat_id, function(){
				anchorMessageWindow();
			});	
		}
	}

	handleDeletedMessage(payload){
		$('.directory-item[data-chat-id="' +payload.chat_id+'"]').hide();
		var active_window = $('.active-chat-window-container');
		if($(active_window).data('chat-id') == payload.chat_id){
			$('.active-directory-item').hide();
			$('.active-chat-window ').hide();
			$('.new-direct-chat-window').show();
			setLastChatId('');
		}
	}
	
	handleTyping_notificationMessage(payload){
		if(isset(payload.chat_id) && isset(payload.user)){
			receiveTypingNotification(payload.chat_id, payload.user);
		}
	}
	


	markMessageRead(message_id){
		var payload = JSON.stringify({
			'action' : 'read_message',
			'payload' : {
				'message_id' : message_id,
			}
		});
		this.connection.send(payload);
	}

	closeChat(chat_id){
		var payload = JSON.stringify({
			'action' : 'close_chat',
			'payload' : {
				'chat_id' : chat_id,
			}
		});
		this.connection.send(payload);
	}
	
	deleteChat(chat_id){
		var payload = JSON.stringify({
			'action' : 'delete_chat',
			'payload' : {
				'chat_id' : chat_id,
			}
		});
		this.connection.send(payload);
	}
	
	sendTypingNotification(chat_id){
		var payload = JSON.stringify({
			'action' : 'typing_notification',
			'payload' : {
				'chat_id' : chat_id,
			}
		});
		this.connection.send(payload);
	}

	closedActions(){
		showChatLoader();
	}
	
	connectedActions(){
		hideChatLoader();
	}

}

module.exports  = new chat_websocket();
