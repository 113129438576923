$(document).ready(function() {
	
	//clicked to look up a postcode
	$('#lead_script').on('click','#postcode-lookup',function(e){
		e.stopPropagation();
		e.preventDefault();
		//console.log('search pressed');
		var api = new apiInterface();
		var quote_id = $('#lead_script').data("quoteid");
		var postcode = $('#api_postcode').val();
		//console.log(postcode);
		if(api.is_valid_postcode(postcode)){
			//console.log('postcode is ok');
			api.postcode_lookup(postcode,function(response) {
				if(response.error){
					//console.log('postcode error');	
					$('#postcodeError').html(response.message);
					setTimeout(function(){
						$('#postcodeError').html('');
					},2000);	
				}
				else{
					//console.log('postcode was ok');
					$('#energy-address').html(response.html);
					$('#address-count').html(response.count+" addresses found:");
				}	
			});
		} 
		else {
			$('#postcodeError').html('please enter a valid postcode');
			setTimeout(function(){
				$('#postcodeError').html('');
			},2000);
		}

	});

	//attempt to set address (manual or selected)
	$('#lead_script').on('click','#accident-protection-set-address',function(e){
		e.stopPropagation();
		e.preventDefault();
		
		var quote_id = $('#lead_script').data("quoteid");
		var address_validator = new addressValidator();
		var ok_to_save = true;
		if($('#energy-address-manual').is(':visible')===false){

			var selected_option = $('#lead_script #energy-address option:selected')
			var data = {};
			data.type = $(selected_option).data('type');
			data.external_address_id = $('#lead_script #energy-address').val();
			data.address_text = $(selected_option).text();
			data.postcode = data.address_text.split(',').pop();
			data.quote_id = quote_id;
			showLoader();
			makeRequest('/address/send_dynamic_address', data, function(response){
				if(response.success) {
					window.lead_script.handleStep('accident-protection.occupation');
				}
				else{
					hideLoader();
					error_message(response.error);
				}
			});
		} 
		//then run the validator, either way
		address_validator.validateManualAddress(function(errors,data){
			if(errors !== null) {
				ok_to_save = false;
				$.each(errors,function(index,error){
					if($('#'+error.target).length){
						$('#'+error.target).html(error.message);
					}
					else{
						$('#error-other').html(error.message.text);
					}
				});
			}
			if (ok_to_save===true) {
				$.ajax({
					url: "/order/setAddressDetails/"+quote_id,
					data: {details:data},
					type:"POST",
					dataType: 'json',
					success: function(response){
						if (!response.success) {
							//console.log('oops');
						} 
						else {
							//console.log('about to move forward');
							window.lead_script.handleStep('accident-protection.occupation');
						}
					},
					error : function(error) {
						//console.log(error);
					}		
				});
			}
		});
	});
	
	/* ignote the above for now - don't know what it is in relation to */
	$('#lead_script').on('click', '.contact-address-button', function(e){
		e.preventDefault();
		
		var address_id = $(this).data('address-id');
		
		$('#insurance_selected_address').val(address_id);
		
		$('.contact-address-button.active').removeClass('active');
		
		$(this).addClass('active');
		
		
	});
	
	
	$('#lead_script').on('click', '#insurance_address_not_shown', function(e){
		e.preventDefault();
		
		$('.insurance-address-lookup').show();
		$('.insurance-address-button-section').hide();
		
	});
	
	$('#lead_script').on('click', '#insurance_postcode_lookup_go', function(e){
		e.preventDefault();
		
		//need to go a lookup
		
		var postcode = $('#insurance_postcode_lookup').val().trim();
		var api = new apiInterface();
		if(api.is_valid_postcode(postcode)){
			api.postcode_lookup(postcode,function(response) {
				if(response.error){
					console.log('postcode error');	
				}
				else{
					$('#insurance_found_addresses select').html(response.html);
					$('#insurance_found_addresses').show();
				}	
			});
		} 
		else {
			console.log('please enter a valid postcode')
		}
		
	});
	
	
	$('#lead_script').on('click', '#insurance_manual_address_button', function(e){
		e.preventDefault();
		
		$('.insurance-address-lookup').hide();
		$('#insurance_manual_address').show();
	});
	
	$('#lead_script').on('click', '#insurance_address_undo', function(e){
		e.preventDefault();
		
		if($('#insurance_postcode_lookup_go').is(':visible')){
			//we are at step 2 of the process
			$('.insurance-address-lookup').hide();
			$('.insurance-address-button-section').show();
			
		}
		else if($('#manual-house-number').is(':visible')){
			//we are on a manual address section 
			$('.insurance-address-lookup').show();
			$('#insurance_manual_address').hide();
		}
		
	})
	
	
	
	
});
