function usageValidator(){
	this.api = new apiInterface();
	var that = this;
	this.validateUsage = function(compare_type = false){
		var actualUsage = $('input[name=do-you-know]:checked').val();
		if(actualUsage === 'yes'){
			// validate the actual usage inputs
			this.validateActualUsage(compare_type, function(errors){
				setTimeout(function(){
					$('.usage-error').html('');
				},2000);
			});
		}
		else if(actualUsage == 'no'){
			// is it a detailed estimate ? 
			var quote_id = $('#lead_script').data("quoteid");
			var details = $('#detailed-usage-form').serialize();
			that.api.sendDetailedEstimate(quote_id,details,function(response){
				if(response.errors == 0){
					if($('#currentUsageContinue').data('success-step')){
						window.lead_script.handleStep($('#currentUsageContinue').data('success-step'));
					}
					else{
						window.lead_script.handleStep(window.lead_script.magicPlease('set_tariff_preferences'));
					}
				}
			});
		}
		else if(actualUsage == 'really_no'){
			// is it a detailed estimate ? 
			var quote_id = $('#lead_script').data("quoteid");
			var details =$('#usage-simple :input').serializeArray();
			if(!details.length){
				error_message('please select a usage level');
				return false;
			}

			var to_send = sanitiseSerialedArray(details);

			that.api.sendSimpleUsage(quote_id,to_send,function(response){
				if(response.success){
					if($('#currentUsageContinue').data('success-step')){
						window.lead_script.handleStep($('#currentUsageContinue').data('success-step'));
					}
					else{
						window.lead_script.handleStep(window.lead_script.magicPlease('set_tariff_preferences'));
					}
				}
				else{
					var error = 'unable to set usage';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			});
		}
		else if(actualUsage == 'really_no_but_maybe_yes'){
			// is it a detailed estimate ?
			var quote_id = $('#lead_script').data("quoteid");
			var details =$('#usage-not-simple :input').serializeArray();

			if(!details.length) {
				error_message('please select a usage level');
				return false;
			}
			var to_send = sanitiseSerialedArray(details);

			that.api.sendNotSoSimpleUsage(quote_id,to_send,function(response){
				if(response.success){
					if($('#currentUsageContinue').data('success-step')){
						window.lead_script.handleStep($('#currentUsageContinue').data('success-step'));
					}
					else{
						window.lead_script.handleStep(window.lead_script.magicPlease('set_tariff_preferences'));
					}
				}
				else{
					var error = 'unable to set usage';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			});
		}
	},
	this.validateActualUsage = function(compare_type = false, callback = function(){}){
		// check what actually needs validating

		if(!compare_type){
			var compare_type = $('input[name=compare-type]:checked').val();
		}
		var quote_id = $('#lead_script').data("quoteid");
		var errors = 0;
		if(compare_type == 'dual'){
			// validate both
			this.checkUsageFields('gas',quote_id,function(response){
				if(response.errors == 0){
					var fields = response.data;
					that.checkUsageFields('electricity',quote_id,function(response){
						if(response.errors == 0){
							for (var attrname in response.data) { fields[attrname] = response.data[attrname]; }
							that.api.updateUsage(quote_id,fields,function(response){
								if(response.error){
									alert(response.error);
								}
								else{
									if($('#currentUsageContinue').data('success-step')){
										window.lead_script.handleStep($('#currentUsageContinue').data('success-step'));
									}
									else{
										window.lead_script.handleStep(window.lead_script.magicPlease('set_tariff_preferences'));
									}
								}
								//that.api.sendCurrentUsageData(quote_id,window.lead_script.handleStep('energy.set_tariff_preferences'));
							});
						}
						else{
							//console.log('something went wrong with elec');
						}
					});
				}
				else{
					//console.log('something went wrong, gas');
				}
			});
		}
		else{
			this.checkUsageFields(compare_type,quote_id,function(response){
				if(response.errors == 0){
					//console.log('do we get here with no errors?');
					that.api.updateUsage(quote_id,response.data,function(){
						if($('#currentUsageContinue').data('success-step')){
							window.lead_script.handleStep($('#currentUsageContinue').data('success-step'));
						}
						else{
							window.lead_script.handleStep(window.lead_script.magicPlease('set_tariff_preferences'));
						}
						//that.api.sendCurrentUsageData(quote_id,window.lead_script.handleStep('energy.set_tariff_preferences'));
					});
				}
				else{
					//console.log(response);
				}
			});
		}
		
	},
	this.checkUsageFields = function(type,quote_id,callback){
		var errors = 0;
		var data = {};
		if((type == 'electricity') && $('#eco7-usage-night').is(':visible')){
			// validate eco 7 instead
			if($('input[name=electricity-usage-info]:checked').val() == 'usage'){
				var value_day = $('#eco7-usage-day').val();
				if(value_day == 'undefined' || value_day <= 0){
					$('#eco7-usage-error-day').html('Please enter a value for eco 7 day usage');
					errors++;
				}
				else{
					var field = 'electricity:eco7_usage_day';
					data[field] = value_day;
				}
				var value_night = $('#eco7-usage-night').val();
				var errors = [];
				if(value_night == 'undefined' || value_night <= 0){
					$('#eco7-usage-error-night').html('Please enter a value for eco 7 night usage');
					errors++;
				}
				else{
					var field = 'electricity:eco7_usage_night';
					data[field] = value_night;
				}
			}
			else{
				var value = $('#eco7-usage-percent').val();
				if(value == 'undefined' || value <= 0){
					$('#eco7-percent-error').html('Please enter a value for eco 7 usage percent'); 
					errors++;
				}
				else{
					var field = 'electricity:eco7_usage_percentage';
					data[field] = value;
					this.api.optionChanged(quote_id,'electricity:eco7_usage_percentage',value,function(response){
						
					});
				}
				var usageMetric = $('input[name='+type+'-usage-info]:checked').val();
				// check the spend box has a value
				var value = $('#'+type+'-'+usageMetric).val();
				var frequency = $('#'+type+'-'+usageMetric+'-frequency').val();
				if(value == 'undefined' || value <= 0){
					$('#'+type+'-'+usageMetric+'-error').html('Please enter a value for '+type+' '+usageMetric);
					errors++;
				}
				else{
					data[type+':consumption'] = usageMetric;
					data[type+':frequency'] = frequency;
					data[type+':value'] = value;
				}
			}

		}else{
			var usageMetric = $('input[name='+type+'-usage-info]:checked').val();
			// check the spend box has a value
			var value = $('#'+type+'-'+usageMetric).val();
			var frequency = $('#'+type+'-'+usageMetric+'-frequency').val();
			if(value == 'undefined' || value <= 0){
				$('#'+type+'-'+usageMetric+'-error').html('Please enter a value for '+type+' '+usageMetric);
				errors++;
			}
			else{
				data[type+':consumption'] = usageMetric;
				data[type+':frequency'] = frequency;
				data[type+':value'] = value;
				}
		}
		callback({errors:errors,data:data});
		//return errors;
	},
	this.updateCurrentSupplyDetails = function(quote_id,source,callback =function(){}){
		var supplier_id = $("select[name='"+source+"-supplier-list']").find(":selected").val();
		var tariff_id = $("select[name='"+source+"-tariff-list']").find(":selected").val();
		var payment_id = $("select[name='"+source+"-payment-option']").find(":selected").val();
		var tariff_end_date = false;
		if($('#'+source+'-tariff_end_date').is(":visible")){
			tariff_end_date = $("input[name='"+source+"-tariff_end_date']").val();
		}
		
		if(source == 'electricity' || source == 'dual'){
			this.api.optionChanged(quote_id,'electricity:eco7',$("input[name='"+source+"-eco7']:checked ").val(),function(response){
				
			});
		}
		var values = {
			supplier_id:supplier_id,
			tariff_id:tariff_id,
			payment_id:payment_id,
			tariff_end_date:tariff_end_date
		};
		this.api.updateCurrentUsage(quote_id,values,source,callback);
	};
	
}
module.exports = usageValidator;
