$(document).ready(function() {
    $('body').on('change', '.bought-staff-event', function () {
        var value = 0;
        if($(this).val() == 0) {
            value = 1
        }
        var data = {
            link_id: $(this).data('link-id'),
            value: value
        };
        makeRequest('/staff-events/change-status', data, function (response) {
            if (response.success) {
                success_message();
            }
            else {
                if(response.error) {
                    error_message(response.error);
                }
                else {
                    error_message();
                }
            }
        });
    });

    $('body').on('click', '.add-new-participant', function () {
        var buyer = $('.new-user-participant').find('.buyer').val();
        var recipient =  $('.new-user-participant').find('.recipient').val();

        var data = {
            buyer: buyer,
            recipient: recipient
        };
        makeRequest('/staff-events/add-participant', data, function (response) {
            if (response.success) {
                $('.reload-participants').html(response.html);
                success_message();
            }
            else {
                if(response.error) {
                    error_message(response.error);
                }
                else {
                    error_message();
                }
            }
        });
    });

    $('body').on('click', '.hide-participant-name-button', function () {
        var that = $(this);
        var name = $(that).closest('.participant-row').find('.hide-participant-name').toggle();
        if($(name).hasClass('hidden')) {
            $(name).removeClass('hidden');
            $(that).find('.toggle-participant').html('Hide');
         }
        else {
            $(name).addClass('hidden');
            $(that).find('.toggle-participant').html('Show');
        }
    });

    $('body').on('click', '.remove-participant', function () {
        var data = {
            link_id: $(this).data('link-id')
        };
        makeRequest('/staff-events/remove-participant', data, function (response) {
            if (response.success) {
                $('.reload-participants').html(response.html);
                success_message();
            }
            else {
                if(response.error) {
                    error_message(response.error);
                }
                else {
                    error_message();
                }
            }
        });
    });
});