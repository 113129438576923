var getSearchCriteria = function () {
    var data = {};
    $.each($('.search-criteria'), function (i, $input) {
        let val = $($input).val();
        if(isset(val) && val.length) {
            data[$($input).attr('id')] = val;
        }
    });
    return data;
}

var data_table = false;
var prepareCallRecordingDataTable = function (callback) {

	if(data_table){
		data_table.ajax.reload();
	}
	else{
		data_table = $('#call_recording_results').DataTable({
			autoWidth: true,
			processing: true,
			serverSide: true,
			paging: true,

			pageLength: 10,
			ordering: true,
			responsive: true,
			bAutoWidth: false,
			lengthMenu: [10, 25, 50, 100, 200],
			pagingType: 'full_numbers',
			bInfo: false,
			searching: false,

			'ajax': {
				'headers': {
					'X-CSRF-TOKEN': $('[data-token]').data('token')
				},
				'url': '/call/recording/table',
				'type': "POST",
				'data': function (data) {
					data.criteria = getSearchCriteria()
					return data;
				}
			},
			'columns': [
				{data: 'contact_link'},
				{data: 'source'},
				{data: 'destination'},
				{data: 'direction'},
				{data: 'from'},
				{data: 'duration'},
				{data: 'disposition'},
				{data: 'user_full_name'},
				{data: 'team'},
				{data: 'list'},
				{data: 'service'},
				{data: 'external_id'},
				{data: 'listen_link'},
			]
		});
		callback();
	}
}

var prepareContactCallRecordingTable = function (callback) {
	var first_load = true;
    var call_recording_data_table = $('#call_recording_results_contact').DataTable({
        autoWidth: true,
        processing: true,
        serverSide: true,
        paging: true,

        pageLength: 10,
        ordering: true,
        responsive: true,
        bAutoWidth: false,
        lengthMenu: [10, 25, 50, 100, 200],
        pagingType: 'full_numbers',
        bInfo: false,
        searching: false,

        'ajax': function(data, callback, settings) {
			if(first_load){
				first_load = false;
				callback({data: []}); // don't fire ajax, just return empty set
				return;
			}

			data.criteria = getSearchCriteria();
			return makeRequest('/call/recording/table', data, callback);

            // 'headers': {	
            //     'X-CSRF-TOKEN': $('[data-token]').data('token')
            // },
            // 'url': '/call/recording/table',
            // 'type': "POST",
            // 'data': function (data) {
            //     data.criteria = getSearchCriteria()
            //     return data;
            // }
        },
        'columns': [
            {data: 'source'},
            {data: 'destination'},
            {data: 'direction'},
            {data: 'from'},
            {data: 'duration'},
            {data: 'disposition'},
            {data: 'user_full_name'},
            {render: function (data, type, row) {
                return row.listen_link + ' ' + row.add_link;
            }},
        ]
    });

    $('body').on('click', '.reload-datatable-criteria', function () {
        call_recording_data_table.ajax.reload();
    });

    callback();
}

$(function () {
    // prepareCallRecordingDataTable(function () {
        $('.select2-multi').select2({'width':'100%', 'tags':true});
    // });

    $('body').on('click', '.reload-datatable-criteria', function () {
        prepareCallRecordingDataTable(function () {
            $('.select2-multi').select2({'width':'100%', 'tags':true});
        });
    });

    $('body').on('click', '.link-call-recording', function () {
        let data = {
            'order_id' : $(this).data('order-id'),
            'contact_id' : $(this).data('contact-id')
        }

		var preloaded_recording_search_parameters = $(this).data('preloaded-recording-search-parameters');
		if(preloaded_recording_search_parameters){
			var parameters = {};
			$.each(preloaded_recording_search_parameters, function(key, value){
				parameters[key] = value;
			});
			data['parameters'] = parameters;
		}

        buildDynamicModal('link_different_call_modal', data, function () {
            prepareContactCallRecordingTable(function () {
                $('.select2-multi').select2({'width':'100%', 'tags':true});
            });
        });
    });

    $('body').on('click', '.listen-table-recording', function () {
        let data = {
            'external_id' : $(this).data('external-id'),
            'dialler_id' : $(this).data('dialler-id')
        };

        makeRequest('/call/recording/table-listen', data, function (response) {
            if(isset(response.html)) {
                $('.call-recording-table-player-container').html(response.html);
            } 
			else {
                error_message('Cannot play recording!');
            }
        });
    });

    $('body').on('click', '.link-table-recording', function () {
        let data = {
            key : 'cdr:hash',
            value : $(this).data('hash')
        };
        var quote_id = $('.link-order-to-call-recording-container').data('order-id');
        makeRequest('/api/optionchanged/' + quote_id, data, function (response) {
            if(response.errors){
                that.throwError(response.errors);
            } 
			else {
                document.location.reload();
                success_message('Linked call recording to order: ' + quote_id);
            }
        });
    });
    
	$('body').on('click', '.select2-clear', function (e) {
		e.preventDefault();
        var container = $(this).closest('.form-group');
		var select = $(container).find('select');
		$(select).val(null).trigger('change');
    });
	
	$('body').on('click', '.input-clear', function (e) {
		e.preventDefault();
        var container = $(this).closest('.form-group');
		var input = $(container).find('input');
		$(input).val(null);
    });
});