var makeDataTable = function (start = false, end = false, reload = false) {
	var base = '/get/renewal-orders';
	var params = '';
	if(start != false && end != false && reload) {
		params = "?start=" + start + "&end=" + end;
	}

	var url = base.concat(params);

	if(reload) {
		$('#renewal-orders-table').find('thead select').remove();
	}

	return $('#renewal-orders-table').DataTable({
		destroy: true,
		dom: 'flrtiBp',
		ajax: url,
		"columns": [
			{
				"data": 'sales_order_id',
				"render": function (data) {
					return '<a target="_blank" href="/order/'+data+'">'+data+'</a>'
				}
			},
			{ "data": "external_order_number" },
			{
				"data": "contact_id",
				"contact_name": "contact_name",
				"render": function (data, type, row) {
					return '<a target="_blank" href="/contacts/'+data+'">'+row['contact_name']+'</a>'
				}
			},
			{ "data": "user_name" },
			{ "data": "product_name" },
			{ "data": "status" },
			{ "data": "lead_url" },
			{ "data": "confirmed_at" },
			{
				"data": 'sales_order_id',
				"render": function (data) {
					return '<a target="_blank" href="/order/'+data+'">View</a>'
				}
			}
		],
		pageLength: 25,
		ordering: true,
		responsive: true,
		bAutoWidth: false,
		autoWidth: false,
		lengthMenu: [25, 50, 100],
		select: true,
		buttons: [
			'copyHtml5',
			'print',
			{
				extend: 'collection',
				text: 'Export',
				buttons: [
					'csvHtml5',
					'pdfHtml5'
				]
			}
		],
		initComplete: function () {
			this.api().columns([3, 5]).every(function () {
				var column = this;
				var title = $(column.header()).html();
				var select = $('<select style="float:left; width:85%; border-radius:4px; color:black;"><option></option></select>')
					.on('change', function () {
						var val = $.fn.dataTable.util.escapeRegex(
							$(this).val()
						);

						column
							.search(val ? '^' + val + '$' : '', true, false)
							.draw();
					});
				column.data().unique().sort().each(function (d, j) {
					if (column.search() === '^' + d + '$') {
						select.append('<option value="' + d + '" selected="selected">' + d + '</option>')
					} 
					else {
						select.append('<option value="' + d + '">' + d + '</option>')
					}
				});
				$(column.header()).html('<span>' + title + '</span>');
				$(column.header()).append(select);
			});
		}
	});
}

$(document).ready(function () {
	const datatable = makeDataTable();

	$('.renewal-date-filter').daterangepicker(
		{
			"locale": {
				"format": 'DD/MM/YYYY HH:mm',
				"separator": "-"
			},
			"linkedCalendars": false,
			"showDropdowns": true
		},
		function(start, end, label) {
			const datatable = makeDataTable(moment(start).format("DD-MM-YYYY HH:mm"), moment(end).format("DD-MM-YYYY HH:mm"), true);
		}
	);
});