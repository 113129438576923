

var makeReceivedDirectDebitPaymentsDataTable = function (start = false, end = false, reload = false) {
	var base = '/get/received-payments/direct-debit';
	var params = '';
	if(start != false && end != false && reload) {
		params = "?start=" + start + "&end=" + end;
	}

	var url = base.concat(params);

	if(reload) {
	}
		$('#received_direct_debit_payments-table').find('thead select').remove();

	return $('#received_direct_debit_payments-table').DataTable({
		destroy: true,
		dom: 'flrtiBp',
		ajax: url,
		pageLength: 25,
		ordering: true,
		responsive: true,
		bAutoWidth: false,
		autoWidth: false,
		lengthMenu: [25, 50, 100],
		select: true,
		buttons: [
			'copyHtml5',
			'print',
			{
				extend: 'collection',
				text: 'Export',
				buttons: [
					'csvHtml5',
					'pdfHtml5'
				]
			}
		],
		initComplete: function () {
			this.api().columns([7]).every(function () {
				var column = this;
				var title = $(column.header()).html();
				var select = $('<select style="float:left; width:85%; border-radius:4px; color:black;"><option></option></select>')
					.on('change', function () {
						var val = $.fn.dataTable.util.escapeRegex(
							$(this).val()
						);

						column
							.search(val ? '^' + val + '$' : '', true, false)
							.draw();
					});
				column.data().unique().sort().each(function (d, j) {
					if (column.search() === '^' + d + '$') {
						select.append('<option value="' + d + '" selected="selected">' + d + '</option>')
					} 
					else {
						select.append('<option value="' + d + '">' + d + '</option>')
					}
				});
				$(column.header()).html('<span>' + title + '</span>');
				$(column.header()).append(select);
			});
		}
	});
}

var makeFirstDirectDebitPaymentsDataTable = function (start = false, end = false, reload = false) {
	var base = '/get/first-payments/direct-debit';
	var params = '';
	if(start != false && end != false && reload) {
		params = "?start=" + start + "&end=" + end;
	}

	var url = base.concat(params);

	if(reload) {
	}
		$('#first_direct_debit_payments-table').find('thead select').remove();

	return $('#first_direct_debit_payments-table').DataTable({
		destroy: true,
		dom: 'flrtiBp',
		ajax: url,
		pageLength: 25,
		ordering: true,
		responsive: true,
		bAutoWidth: false,
		autoWidth: false,
		lengthMenu: [25, 50, 100],
		select: true,
		buttons: [
			'copyHtml5',
			'print',
			{
				extend: 'collection',
				text: 'Export',
				buttons: [
					'csvHtml5',
					'pdfHtml5'
				]
			}
		],
		initComplete: function () {
			this.api().columns([7]).every(function () {
				var column = this;
				var title = $(column.header()).html();
				var select = $('<select style="float:left; width:85%; border-radius:4px; color:black;"><option></option></select>')
					.on('change', function () {
						var val = $.fn.dataTable.util.escapeRegex(
							$(this).val()
						);

						column
							.search(val ? '^' + val + '$' : '', true, false)
							.draw();
					});
				column.data().unique().sort().each(function (d, j) {
					if (column.search() === '^' + d + '$') {
						select.append('<option value="' + d + '" selected="selected">' + d + '</option>')
					} 
					else {
						select.append('<option value="' + d + '">' + d + '</option>')
					}
				});
				$(column.header()).html('<span>' + title + '</span>');
				$(column.header()).append(select);
			});
		}
	});
}

var makeReceivedCardPaymentsDataTable = function (start = false, end = false, reload = false) {
	var base = '/get/received-payments/card';
	var params = '';
	if(start != false && end != false && reload) {
		params = "?start=" + start + "&end=" + end;
	}

	var url = base.concat(params);

	if(reload) {
	}
		$('#received_card_payments-table').find('thead select').remove();

	return $('#received_card_payments-table').DataTable({
		destroy: true,
		dom: 'flrtiBp',
		ajax: url,
		pageLength: 25,
		ordering: true,
		responsive: true,
		bAutoWidth: false,
		autoWidth: false,
		lengthMenu: [25, 50, 100],
		select: true,
		buttons: [
			'copyHtml5',
			'print',
			{
				extend: 'csv',
				charset: 'utf-8',
				extension: '.csv',
				bom: true
			}
		]
		// buttons: [
		// 	'copyHtml5',
		// 	'print',
		// 	{
		// 		extend: 'collection',
		// 		text: 'Export',
		// 		buttons: [
		// 			'csvHtml5',
		// 			'pdfHtml5'
		// 		]
		// 	}
		// ]
	});
}

var makeOverduePaymentsDataTable = function (start = false, end = false, reload = false) {
	var base = '/admin/get-overdue-payments';
	var params = '';
	if(start != false && end != false && reload) {
		params = "?start=" + start + "&end=" + end;
	}

	var url = base.concat(params);

	if(reload) {
	}
		$('#overdue-payments-table').find('thead select').remove();

	return $('#overdue-payments-table').DataTable({
		destroy: true,
		dom: 'flrtiBp',
		ajax: url,
		pageLength: 25,
		ordering: true,
		responsive: true,
		bAutoWidth: false,
		autoWidth: false,
		lengthMenu: [25, 50, 100],
		select: true,
		buttons: [
			'copyHtml5',
			'print',
			{
				extend: 'collection',
				text: 'Export',
				buttons: [
					'csvHtml5',
					'pdfHtml5'
				]
			}
		]
	});
}


$(function () {

	if($('.overdue-payments-date-filter').length){
		const overdue_payments_datatable = makeOverduePaymentsDataTable();
		$('.overdue-payments-date-filter').daterangepicker(
			{
				"locale": {
					"format": 'DD/MM/YYYY HH:mm',
					"separator": "-"
				},
				"linkedCalendars": false,
				"showDropdowns": true
			},
			function(start, end, label) {
				const overdue_payments_datatable = makeOverduePaymentsDataTable(moment(start).format("DD-MM-YYYY HH:mm"), moment(end).format("DD-MM-YYYY HH:mm"), true);
			}
		);
	}

	if($('.first_direct_debit_payments-date-filter').length){

		const first_direct_debits_datatable = makeFirstDirectDebitPaymentsDataTable();
		$('.first_direct_debit_payments-date-filter').daterangepicker(
			{
				"locale": {
					"format": 'DD/MM/YYYY HH:mm',
					"separator": "-"
				},
				"linkedCalendars": false,
				"showDropdowns": true
			},
			function(start, end, label) {
				const first_direct_debits_datatable = makeFirstDirectDebitPaymentsDataTable(moment(start).format("DD-MM-YYYY HH:mm"), moment(end).format("DD-MM-YYYY HH:mm"), true);
			}
		);
	}

	if($('.received_direct_debit_payments-date-filter').length){

		const received_direct_debits_datatable = makeReceivedDirectDebitPaymentsDataTable();
		$('.received_direct_debit_payments-date-filter').daterangepicker(
			{
				"locale": {
					"format": 'DD/MM/YYYY HH:mm',
					"separator": "-"
				},
				"linkedCalendars": false,
				"showDropdowns": true
			},
			function(start, end, label) {
				const received_direct_debits_datatable = makeReceivedDirectDebitPaymentsDataTable(moment(start).format("DD-MM-YYYY HH:mm"), moment(end).format("DD-MM-YYYY HH:mm"), true);
			}
		);
	}

	if($('.received_card_payments-date-filter').length){

		const received_card_payments_datatable = makeReceivedCardPaymentsDataTable();
		$('.received_card_payments-date-filter').daterangepicker(
			{
				"locale": {
					"format": 'DD/MM/YYYY HH:mm',
					"separator": "-"
				},
				"linkedCalendars": false,
				"showDropdowns": true
			},
			function(start, end, label) {
				const received_card_payments_datatable = makeReceivedCardPaymentsDataTable(moment(start).format("DD-MM-YYYY HH:mm"), moment(end).format("DD-MM-YYYY HH:mm"), true);
			}
		);
	}

	$('#lead_script').on('click','.add-payment-to-order',function(){
		var quote_id = $('#lead_script').data('quoteid');
		if(isset(quote_id)){
			makeRequest('/quote/get-payment-modal-info/' + quote_id, {}, function(response){
				if(response.errors == null) {		
					if(response.html != null){
						$('#createPayment').find('#payment-details-modal-content').html(response.html);
						showModal('createPayment');
						if($('.payment-date-section input[name="payment_date"]').length){
							$('.payment-date-section input[name="payment_date"]').daterangepicker({
								"singleDatePicker": true,
								"timePicker24Hour": true,
								"timePicker": true,
								"autoApply": true,
								"showCustomRangeLabel": false,
								"locale": {
									format: 'DD/MM/YYYY H:mm' 
								},
							});
						}
					}
				}
			}, 'GET');
		}
	});
	$('.payment-details-content').on('click','.add-payment-to-order',function(){
		var quote_id = $(this).data('quote_id');
		if(isset(quote_id)){
			makeRequest('/quote/get-payment-modal-info/' + quote_id, {}, function(response){
				if(response.errors == null) {		
					if(response.html != null){
						$('#createPayment').find('#payment-details-modal-content').html(response.html);
						showModal('createPayment');
						if($('.payment-date-section input[name="payment_date"]').length){
							$('.payment-date-section input[name="payment_date"]').daterangepicker({
								"singleDatePicker": true,
								"timePicker24Hour": true,
								"timePicker": true,
								"autoApply": true,
								"showCustomRangeLabel": false,
								"locale": {
									format: 'DD/MM/YYYY H:mm' 
								},
							});
						}
					}
				}
			}, 'GET');
		}
	});

	$('.payment-details-content, #lead_script').on('click','.delete-existing-card-payment',function(e){
		e.preventDefault();
		var quote_id = $(this).data('order_id');
		var payment_id = $(this).data('payment_id');
		var payment_type = $(this).data('payment_type');
		if(isset(quote_id) && isset(payment_id)){
			if(confirm('Are you sure you want to delete this payment?')){
				makeRequest('/quote/delete-existing-payment/' + quote_id + '/' + payment_id+'?payment_type='+payment_type, {}, function(response){
					if(response.success) {
						if($('#order-details-'+quote_id).find('.existing-payments-table').length){
							// contact details page with multiple potential containers
							$('#order-details-'+quote_id).find('.existing-payments-table').html(response.existing_payments_table_html);
							$('#order-details-'+quote_id).find('.reminaing-balance-title').text('Remaining Balance £'+response.remaining_balance);
						}
						else if($('.payment-details-content').length){
							// order details page, should only be one instance of the table
							$('.existing-payments-table').html(response.existing_payments_table_html);	
							$('.reminaing-balance-title').text('Remaining Balance £'+response.remaining_balance);
						}
						else {
							// we're inside the script 
							$('#existing-payments-table').html(response.existing_payments_table_html);	
							$('#reminaing-balance-title').text('Remaining Balance £'+response.remaining_balance);
							$('#lead_script').find('.next-button').show();
						}
					}
				}, 'GET');
			}
		}
	});
	// edit an existing card payment
	$('.payment-details-content, #lead_script').on('click','.edit-existing-card-payment',function(e){
		 // stop the link going anywhere
		e.preventDefault();
		var quote_id = $(this).data('order_id');
		var payment_id = $(this).data('payment_id');
		var payment_type = $(this).data('payment_type');
		if(isset(quote_id) && isset(payment_id)){
			makeRequest('/quote/get-edit-payment-modal-info/' + quote_id + '/' + payment_id+'?payment_type='+payment_type, {}, function(response){
				if(response.errors == null) {		
					if(response.html != null){
						$('#editPayment').find('#payment-details-modal-content').html(response.html);
						showModal('editPayment');
						if($('.payment-date-section input[name="payment_date"]').length){
							$('.payment-date-section input[name="payment_date"]').daterangepicker({
								"singleDatePicker": true,
								"timePicker24Hour": true,
								"timePicker": true,
								"autoApply": true,
								"showCustomRangeLabel": false,
								"locale": {
									format: 'DD/MM/YYYY H:mm' 
								},
							});
						}
					}
				}
			}, 'GET');
		}
		// post to the server and grab the new payment content
	});

	$('#create-quote-payment-form').on('change', 'select[name="payment_type"]',function(){
		var payment_type = $(this).val();
		if(payment_type == 'card'){
			$('.card-type-section').show();
			$('.bank-payment-type-section').hide();
		}
		else{
			$('.card-type-section').hide();
			$('.bank-payment-type-section').show();
		}
		$('.transaction-type-section').show();
	});

	$('#create-quote-payment-form').on('change', 'select[name="transaction_type"]',function(){
		$('.payment-amount-input-section').children().hide();
		$('.payment-type-section').removeClass('error shake');
		var transaction_type = $(this).val();
		$('.'+transaction_type.toLowerCase()+'-amount-input').show();
		$('.payment-reference-section').show();
		$('.payment-date-section').show();

	});
	

	$('#edit-quote-payment-form').on('click','#edit_existing_payment_form',function(){
		$('.payment-input-container').find('label').removeClass('error shake');
		var form = $(this).closest('form');
		var form_data = $(form).serializeArray();
		var data = {};
		$.each(form_data,function(index,input){
			var key = input.name;
			data[key] = input.value;
		});
		if($('.card-type-section').is(':visible')){
			if(!isset(data.card_type) || data.card_type == "Select"){
				$('.card-type-section').find('label').addClass('error shake');
				return;
			}
		}
		if($('.bank-payment-type-section').is(':visible')){
			if(!isset(data.bank_payment_type) || data.bank_payment_type == "Select"){
				$('.card-type-section').find('label').addClass('error shake');
				return;
			}
		}
		
		if(!isset(data.transaction_type) || data.transaction_type == "Select"){
			$('.transaction-type-section').find('label').addClass('error shake');
			return;
		}
		var transaction_type = data.transaction_type;
		var transaction_amount = data[transaction_type+'_value'];
		if((transaction_amount <= 0 || transaction_amount.length == 0) && transaction_type.toLowerCase() == 'payment'){
			$('.payment-amount-input-section').find('label').addClass('error shake');
			$('.payment-amount-input-error').text('payments must be a positive value');
			$('.payment-amount-input-error').addClass('error shake');
			$('.payment-amount-input-error').show();
			setTimeout(function(){
				$('.payment-amount-input-error').text('');
				$('.payment-amount-input-error').removeClass('error shake');
				$('.payment-amount-input-error').show();
			},1500)
			return;
		}
		if((transaction_amount >= 0 || transaction_amount.length == 0) && transaction_type.toLowerCase() == 'refund'){
			$('.payment-amount-input-section').find('label').addClass('error shake');
			$('.refund-amount-input-error').text('payments must be a positive value');
			$('.refund-amount-input-error').addClass('error shake');
			$('.refund-amount-input-error').show();
			setTimeout(function(){
				$('.refund-amount-input-error').text('');
				$('.refund-amount-input-error').removeClass('error shake');
				$('.refund-amount-input-error').show();
			},1500)
			return
		}
		if(!isset(data.payment_reference) || data.payment_reference.length < 5){
			// $('.payment-reference-section').find('label').addClass('error shake');
			// return;
		}
		// ok we should have everything we need so lets post it
		var quote_id = data.order_id;
		var payment_id = data.payment_id;
		makeRequest('/quote/edit-payment-on-order/'+ quote_id + '/' + payment_id, data, function(response){
			if(response.success) {
				if($('#order-details-'+quote_id).find('.existing-payments-table').length){
					// contact details page with multiple potential containers
					$('#order-details-'+quote_id).find('.existing-payments-table').html(response.existing_payments_table_html);
					$('#order-details-'+quote_id).find('.reminaing-balance-title').text('Remaining Balance £'+response.remaining_balance);
				}
				else if($('.payment-details-content').length){
					// order details page, should only be one instance of the table
					$('.existing-payments-table').html(response.existing_payments_table_html);	
					$('.reminaing-balance-title').text('Remaining Balance £'+response.remaining_balance);
				}
				else {
					// we're inside the script 
					$('#existing-payments-table').html(response.existing_payments_table_html);	
					$('#reminaing-balance-title').text('Remaining Balance £'+response.remaining_balance);
					$('#lead_script').find('.next-button').show();
				}
				success_message('Payment Added');
				hideModal('editPayment');
				return;
				// if a good response update the remaining balance section and the existing payments sections of the page.
			}
			else{
				hideLoader();
				error_message(response.error);
			}
		});
	});

	$('#edit-quote-finance-payment-form').on('click','#edit_existing_finance_payment_form',function(){
		$('.payment-input-container').find('label').removeClass('error shake');
		var form = $(this).closest('form');
		var form_data = $(form).serializeArray();
		var data = {};
		$.each(form_data,function(index,input){
			var key = input.name;
			data[key] = input.value;
		});
		
		if(!isset(data.transaction_type) || data.transaction_type == "Select"){
			$('.transaction-type-section').find('label').addClass('error shake');
			return;
		}
		var transaction_type = data.transaction_type;
		var transaction_amount = data[transaction_type+'_value'];
		if((transaction_amount <= 0 || transaction_amount.length == 0) && transaction_type.toLowerCase() == 'payment'){
			$('.payment-amount-input-section').find('label').addClass('error shake');
			$('.payment-amount-input-error').text('payments must be a positive value');
			$('.payment-amount-input-error').addClass('error shake');
			$('.payment-amount-input-error').show();
			setTimeout(function(){
				$('.payment-amount-input-error').text('');
				$('.payment-amount-input-error').removeClass('error shake');
				$('.payment-amount-input-error').show();
			},1500)
			return;
		}
		if((transaction_amount >= 0 || transaction_amount.length == 0) && transaction_type.toLowerCase() == 'refund'){
			$('.payment-amount-input-section').find('label').addClass('error shake');
			$('.refund-amount-input-error').text('payments must be a positive value');
			$('.refund-amount-input-error').addClass('error shake');
			$('.refund-amount-input-error').show();
			setTimeout(function(){
				$('.refund-amount-input-error').text('');
				$('.refund-amount-input-error').removeClass('error shake');
				$('.refund-amount-input-error').show();
			},1500)
			return
		}
		if(!isset(data.payment_reference) || data.payment_reference.length < 5){
			// $('.payment-reference-section').find('label').addClass('error shake');
			// return;
		}
		// ok we should have everything we need so lets post it
		var quote_id = data.order_id;
		var payment_id = data.payment_id;
		var finance_agreement_id = data.finance_agreement_id
		makeRequest('/quote/edit-finance-payment-on-order/'+ quote_id + '/' + finance_agreement_id + '/' + payment_id, data, function(response){
			if(response.success) {
				if($('#order-details-'+quote_id).find('.finance-payment-history').length){
					// contact details page with multiple potential containers
					$('#order-details-'+quote_id).find('.finance-payment-history').html(response.existing_payments_table_html);
					$('#order-details-'+quote_id).find('.reminaing-finance-balance-title').text('Remaining Finance Balance £'+response.remaining_balance);
				}
				else if($('.payment-details-content').length){
					// order details page, should only be one instance of the table
					$('.finance-payment-history').html(response.existing_payments_table_html);	
					$('.reminaing-finance-balance-title').text('Remaining Finance Balance £'+response.remaining_balance);
				}
				success_message('Payment Added');
				hideModal('editFinancePayment');
				return;
				// if a good response update the remaining balance section and the existing payments sections of the page.
			}
			else{
				hideLoader();
				error_message(response.error);
			}
		});
	});

	$('#create-quote-payment-form').on('click','#create_payment_form',function(){
		$('.payment-input-container').find('label').removeClass('error shake');
		var form = $(this).closest('form');
		var form_data = $(form).serializeArray();
		var data = {};
		$.each(form_data,function(index,input){
			var key = input.name;
			data[key] = input.value;
		});
		if(!isset(data.payment_type) || data.payment_type == "Select"){
			$('.payment-type-section').find('label').addClass('error shake');
			return;
		}
		if(data.payment_type == 'card'){
			if(!isset(data.card_type) || data.card_type == "Select"){
				$('.card-type-section').find('label').addClass('error shake');
				return;
			}
		}
		else{
			if(!isset(data.bank_payment_type) || data.bank_payment_type == "Select"){
				$('.bank-payment-type-section').find('label').addClass('error shake');
				return;
			}
		}
		if(!isset(data.transaction_type) || data.transaction_type == "Select"){
			$('.transaction-type-section').find('label').addClass('error shake');
			return;
		}
		var transaction_type = data.transaction_type;
		var transaction_amount = data[transaction_type+'_value'];
		if((transaction_amount <= 0 || transaction_amount.length == 0) && transaction_type.toLowerCase() == 'payment'){
			$('.payment-amount-input-section').find('label').addClass('error shake');
			$('.payment-amount-input-error').text('payments must be a positive value');
			$('.payment-amount-input-error').addClass('error shake');
			$('.payment-amount-input-error').show();
			setTimeout(function(){
				$('.payment-amount-input-error').text('');
				$('.payment-amount-input-error').removeClass('error shake');
				$('.payment-amount-input-error').show();
			},1500)
			return;
		}
		if((transaction_amount >= 0 || transaction_amount.length == 0) && transaction_type.toLowerCase() == 'refund'){
			$('.payment-amount-input-section').find('label').addClass('error shake');
			$('.refund-amount-input-error').text('payments must be a positive value');
			$('.refund-amount-input-error').addClass('error shake');
			$('.refund-amount-input-error').show();
			setTimeout(function(){
				$('.refund-amount-input-error').text('');
				$('.refund-amount-input-error').removeClass('error shake');
				$('.refund-amount-input-error').show();
			},1500)
			return
		}
		if(!isset(data.payment_reference) || data.payment_reference.length < 5){
			// $('.payment-reference-section').find('label').addClass('error shake');
			// return;
		}
		// ok we should have everything we need so lets post it
		var quote_id = data.order_id;
		makeRequest('/quote/add-payment-to-order/'+ quote_id, data, function(response){
			if(response.success) {
				if($('#order-details-'+quote_id).find('.existing-payments-table').length){
					// contact details page with multiple potential containers
					$('#order-details-'+quote_id).find('.existing-payments-table').html(response.existing_payments_table_html);
					$('#order-details-'+quote_id).find('.reminaing-balance-title').text('Remaining Balance £'+response.remaining_balance);
				}
				else if($('.payment-details-content').length){
					// order details page, should only be one instance of the table
					$('.existing-payments-table').html(response.existing_payments_table_html);	
					$('.reminaing-balance-title').text('Remaining Balance £'+response.remaining_balance);
				}
				else {
					// we're inside the script 
					$('#existing-payments-table').html(response.existing_payments_table_html);	
					$('#reminaing-balance-title').text('Remaining Balance £'+response.remaining_balance);
					$('#lead_script').find('.next-button').show();
				}
				success_message('Payment Added');
				hideModal('createPayment');
				// if we're not inside the script, reload the page
				if($('#lead_script').length == 0){
					window.location.reload()
				}
				return;				
				// if a good response update the remaining balance section and the existing payments sections of the page.
			}
			else{
				hideLoader();
				error_message(response.error);
			}
		});
	});

	$('.payment-details-content').on('click','.add-finance-payment-to-order',function(){
		var quote_id = $(this).data('quote_id');
		var finance_agreement_id = $(this).data('finance_agreement_id');
		if(isset(quote_id)){
			makeRequest('/quote/get-finance-payment-modal-info/' + quote_id + '/'+ finance_agreement_id, {}, function(response){
				if(response.errors == null) {		
					if(response.html != null){
						$('#createFinancePayment').find('#finance-payment-details-modal-content').html(response.html);
						showModal('createFinancePayment');
						if($('.finance-payment-date-section input[name="finance_payment_date"]').length){
							$('.finance-payment-date-section input[name="finance_payment_date"]').daterangepicker({
								"singleDatePicker": true,
								"timePicker24Hour": true,
								"timePicker": true,
								"autoApply": true,
								"showCustomRangeLabel": false,
								"locale": {
									format: 'DD/MM/YYYY H:mm' 
								},
							});
						}
					}
				}
			}, 'GET');
		}
	});

	$('.payment-details-content').on('click','.delete-existing-finance-payment',function(e){
		e.preventDefault();
		var quote_id = $(this).data('order_id');
		var payment_id = $(this).data('payment_id');
		var finance_agreement_id = $(this).data('finance_agreement_id');
		if(isset(quote_id) && isset(payment_id) && isset(finance_agreement_id)){
			if(confirm('Are you sure you want to delete this payment?')){
				makeRequest('/quote/delete-existing-finance-payment/' + quote_id + '/' + finance_agreement_id + '/' + payment_id, {}, function(response){
					if(response.success) {
						if($('#order-details-'+quote_id).find('.finance-payment-history').length){
							// contact details page with multiple potential containers
							$('#order-details-'+quote_id).find('.finance-payment-history').html(response.existing_payments_table_html);
							$('#order-details-'+quote_id).find('.reminaing-finance-balance-title').text('Remaining Finance Balance £'+response.remaining_balance);
						}
						else if($('.payment-details-content').length){
							// order details page, should only be one instance of the table
							$('.finance-payment-history').html(response.existing_payments_table_html);	
							$('.reminaing-finance-balance-title').text('Remaining Finance Balance £'+response.remaining_balance);
						}
					}
				}, 'GET');
			}
		}
	});	

	$('.payment-details-content').on('click','.edit-existing-finance-payment',function(e){
		 // stop the link going anywhere
		e.preventDefault();
		var quote_id = $(this).data('order_id');
		var payment_id = $(this).data('payment_id');
		var finance_agreement_id = $(this).data('finance_agreement_id');
		if(isset(quote_id) && isset(payment_id) && isset(finance_agreement_id)){
			makeRequest('/quote/get-edit-finance-payment-modal-info/' + quote_id + '/' + finance_agreement_id + '/'+ payment_id, {}, function(response){
				if(response.errors == null) {		
					if(response.html != null){
						$('#editFinancePayment').find('#finance-payment-details-modal-content').html(response.html);
						showModal('editFinancePayment');
						if($('.payment-date-section input[name="payment_date"]').length){
							$('.payment-date-section input[name="payment_date"]').daterangepicker({
								"singleDatePicker": true,
								"timePicker24Hour": true,
								"timePicker": true,
								"autoApply": true,
								"showCustomRangeLabel": false,
								"locale": {
									format: 'DD/MM/YYYY H:mm' 
								},
							});
						}
					}
				}
			}, 'GET');
		}
		// post to the server and grab the new payment content
	});

	$('#create-quote-finance-payment-form').on('change', 'select[name="transaction_type"]',function(){
		$('.payment-amount-input-section').children().hide();
		$('.payment-type-section').removeClass('error shake');
		var transaction_type = $(this).val();
		$('.'+transaction_type.toLowerCase()+'-amount-input').show();
		$('.payment-reference-section').show();
		$('.payment-date-section').show();
	});

	$('#create-quote-finance-payment-form').on('click','#create_finance_payment_form',function(){
		$('.payment-input-container').find('label').removeClass('error shake');
		var form = $(this).closest('form');
		var form_data = $(form).serializeArray();
		var data = {};
		$.each(form_data,function(index,input){
			var key = input.name;
			data[key] = input.value;
		});
		if(!isset(data.transaction_type) || data.transaction_type == "Select"){
			$('.transaction-type-section').find('label').addClass('error shake');
			return;
		}
		var transaction_type = data.transaction_type;
		var transaction_amount = data[transaction_type+'_value'];
		if((transaction_amount <= 0 || transaction_amount.length == 0) && transaction_type.toLowerCase() == 'payment'){
			$('.payment-amount-input-section').find('label').addClass('error shake');
			$('.payment-amount-input-error').text('payments must be a positive value');
			$('.payment-amount-input-error').addClass('error shake');
			$('.payment-amount-input-error').show();
			setTimeout(function(){
				$('.payment-amount-input-error').text('');
				$('.payment-amount-input-error').removeClass('error shake');
				$('.payment-amount-input-error').show();
			},1500)
			return;
		}
		if((transaction_amount >= 0 || transaction_amount.length == 0) && transaction_type.toLowerCase() == 'refund'){
			$('.payment-amount-input-section').find('label').addClass('error shake');
			$('.refund-amount-input-error').text('payments must be a positive value');
			$('.refund-amount-input-error').addClass('error shake');
			$('.refund-amount-input-error').show();
			setTimeout(function(){
				$('.refund-amount-input-error').text('');
				$('.refund-amount-input-error').removeClass('error shake');
				$('.refund-amount-input-error').show();
			},1500)
			return
		}
		if(!isset(data.payment_reference) || data.payment_reference.length < 5){
			// $('.payment-reference-section').find('label').addClass('error shake');
			// return;
		}
		// ok we should have everything we need so lets post it
		var quote_id = data.order_id;
		makeRequest('/quote/add-finance-payment-to-order/'+ quote_id, data, function(response){
			if(response.success) {
				if($('#order-details-'+quote_id).find('.finance-payment-history').length){
					// contact details page with multiple potential containers
					$('#order-details-'+quote_id).find('.finance-payment-history').html(response.existing_payments_table_html);
					$('#order-details-'+quote_id).find('.reminaing-finance-balance-title').text('Remaining Finance Balance £'+response.remaining_balance);
				}
				else if($('.payment-details-content').length){
					// order details page, should only be one instance of the table
					$('.finance-payment-history').html(response.existing_payments_table_html);	
					$('.reminaing-finance-balance-title').text('Remaining Finance Balance £'+response.remaining_balance);
				}
				success_message('Payment Added');
				hideModal('createFinancePayment');
				// if we're not inside the script, reload the page
				if($('#lead_script').length == 0){
					window.location.reload()
				}
				return;				
				// if a good response update the remaining balance section and the existing payments sections of the page.
			}
			else{
				hideLoader();
				error_message(response.error);
			}
		});
	});
	
});


    