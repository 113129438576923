$( function() {

	//region Constants

	const $modal_container = $('#modal_container');

	const $product_brands = $('#product-brands');

	const $product_view_flow = $('#product-view-flow');
	const $product_creation_flow = $('#product-creation-flow');
	const $product_creation_variables = $('#product-creation-variables');

	const $products = $('#products');
	const $product_edit = $('#product-edit');

	const $product = $('#product');
	const $product_details = $('#product-details')

	const $ratecard = $('#ratecard');
	const $export_map = $('#export_map');

	const $product_image = $('#product_image');

	var $export_map_upload_data;
	var auto_load_product = '';

	//endregion

	//region Functions

	function productCreation(endpoint, data) {
		showLoader();
		$product_view_flow.hide();
		$product_creation_variables.hide();

		makeRequest(endpoint, data, function(response) {
			if (response.success) {
				$product_creation_flow.show();

				let $html = $(response.html)

				$product_details.html($html);
				$html.find('.select2').select2({'width':'100%', 'tags': true});
			}
			else {
				let error = 'Unable to create new product';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}

			hideLoader();
		});
	}

	function setupSearch(input_id, item_id) {
		// Hide/Display product divs depending on text search input
		$(input_id).on('keyup', function() {
			let search = $(this).val().toLowerCase();
			let $products = $(item_id);

			// Make sure all products are shown if the search input is empty
			if (search.length === 0) {
				$products.show();
			}
			else {
				// Check each div to see if any of the text matches the search input
				$products.each(function() {
					let $this = $(this);

					if ($this.text().toLowerCase().indexOf(search) === -1) {
						$this.hide();
					}
					else {
						$this.show();
					}
				});
			}
		});
	}

	function filterProductsByStatus($html, show_status) {
		show_status = parseInt(show_status);
		let $active = $html.find('.product-single[data-active="1"]');
		let $inactive = $html.find('.product-single[data-active="0"]');

		if (show_status === 0) {
			$active.hide();
			$inactive.show();
		} 
		else if (show_status === 1 || (show_status === -1 && $active.length > 0)) {
			$active.show();
			$inactive.hide();
		} 
		else {
			$html.find('.product-single').show();

			if (show_status === -1) {
				$html.find('#show_status').val(2);
			}
		}
	}

	function loadBrandProducts(brand_id, hide_product_edit, show_status) {
		showLoader();

		let data = {
			brand_id
		};

		if (hide_product_edit) {
			$product.hide();
			$ratecard.hide();
		}
		$export_map.hide();
		$ratecard.hide();
		$products.data('brand-id', brand_id);

		makeRequest('/admin/products/by_brand', data, function(response) {
			if (response.success) {
				let $html = $(response.html);

				filterProductsByStatus($html, show_status);

				$('#product-list').html($html).show();
				$products.show();

				$product_view_flow.show();
				$product_creation_flow.hide();

				setupSearch('#products-search', '.product-single');

				if(auto_load_product.length > 1) {
					$('.product-single .team:contains('+auto_load_product+')').closest('li').css('background-color', '#bcbcbc');
					var select_value = $('#show_status option:contains("All")');
					$('#show_status').val(select_value.val()).change();
					auto_load_product = '';
				}
			}
			else {
				let error = 'Unable to load products for brand';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);

				$product_brands.find('.team-container').removeClass('active');
			}
			hideLoader();
		});
	}

	function loadProducts(product_ids, selected_product_id) {
		let data = {
			'product_ids' : product_ids,
			selected_product_id
		};
		showLoader();

		makeRequest('/admin/products/products', data, function(response) {
			if (response.success) {
				$product_edit.html(response.html);
				$('#product-type-tabs a').click(function (e) {
					e.preventDefault()
					$(this).tab('show')
				});

				$product.data('product_ids', product_ids);
				$product.show();
				$ratecard.hide();
				$('html, body').animate({ scrollTop: 0 }, 'slow');

				$product_view_flow.show();
				$product_creation_flow.hide();
				$('.backdate-variable-date-picker').daterangepicker({
					"singleDatePicker": true,
					"locale": {
						"format": 'DD/MM/YYYY'
					},
				});
			}
			else {
				let error = 'Unable to load product for editing';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
				$products.find('.team-container').removeClass('active');
			}
			hideLoader();
		});
	}

	function enableContinue() {
		let $input = $product_details.find('input:not([type="search"]), select');
		let $continue_button = $product_details.find('#creation_continue');
		let disable = false;

		$input.each(function() {
			let value = $(this).val();
			if (value === null || value.length === 0) {
				disable = true;
			}
		});

		$continue_button.prop('disabled', disable);
		if (disable) {
			$product_creation_variables.hide();
		}
	}

	//endregion

	//region Product Navigation

	setupSearch('#brands-search', '.brand-single');

	// Attempt to load the products when brand edit has been clicked
	$product_brands.on('click', '.edit-products', function() {
		let $parent = $(this).parent();
		let brand_id = $parent.data('brand-id');

		$product_brands.find('.team-container').removeClass('active');
		$parent.addClass('active');

		loadBrandProducts(brand_id, true, -1);
	});

	$products.on('click', '.edit-product', function() {
		let $parent = $(this).parent();
		let product_ids = $parent.data('product-ids');

		showLoader();
		$product.hide();
		$ratecard.hide();
		
		$('#products .team-container').removeClass('active');
		$parent.addClass('active');

		loadProducts(product_ids, 0);
	});

	$products.on('change', '#show_status', function() {
		let show_status = $(this).val();
		let $selected_product = $('#products .product-single .active');

		filterProductsByStatus($products, show_status);

		if (!$selected_product.is(':visible')) {
			$product.hide();
			$selected_product.removeClass('active');
		}
	});

	$product_edit.on('click', '.cancel-edit-product', function() {
		$product.hide();
	});

	//endregion

	//region Product Editing

	$products.on('click', '.retire-product', function () {
		let $parent = $(this).parent();
		let product_ids = $parent.data('product-ids');
		
		let confirmation_message = 'Are you sure you want to retire this product, it will stop the product from appearing in the list on this page?';
		if (confirm(confirmation_message)) {

			let data = {
				'product_ids': product_ids
			};

			makeRequest('/admin/products/retire', data, function(response) {
				if (response.success) {
					$product.hide();
					$parent.hide();
					success_message('Retired');
				}
				else {
					let error = 'Unable to retire product';
					if (isset(response.error)) {
						error = response.error;
					}

					error_message(error);
				}
			});
		}
	});
	
	$products.on('click', '.toggle-all-products', function () {
		let $parent = $(this).parent();
		let product_ids = $parent.data('product-ids');
		let action = $(this).data('action');
		let confirmation_message = 'Are you sure you want to ' + action+ ' this product?';
		if (confirm(confirmation_message)) {

			let data = {
				'product_ids': product_ids,
				'action' : action
			};

			makeRequest('/admin/products/toggle-all', data, function(response) {
				if (response.success) {
					$parent.replaceWith(response.html);
					success_message();
					$product.hide();
				
				}
				else {
					let error = 'Unable to update product';
					if (isset(response.error)) {
						error = response.error;
					}
					error_message(error);
				}
			});
		}
	});

	$product_edit.on('change', '.active-product-switch', function() {
		let $this = $(this);
		let product_id = $this.data('product-id');
		let brand_id = $this.data('brand-id');
		let active = $this.is(":checked");
		let show_status = $(this).val();

		let data = {
			product_id,
			active
		}

		makeRequest('/admin/products/toggle-active', data, function(response) {
			if (response.success === true) {
				if (active) {
					success_message('Activated')
				} 
				else {
					error_message('Deactivated');
				}
				loadBrandProducts(brand_id, false, show_status);
			}
			else {
				let error = 'Unable to toggle product active';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
		});
	});

	$product_edit.on('change', '.product-group-change', function() {
		let $this = $(this);
		let product_id = $this.data('product-id');
		let group_id = $this.val();

		let data = {
			product_id,
			group_id
		}

		makeRequest('/admin/products/change-group', data, function(response) {
			if (response.success === true) {
				success_message('Product group updated')
			}
			else {
				let error = 'Unable to change product group';
				if (isset(response.error)) {
					error = response.error;
				}
				error_message(error);
			}
		});
	});
	
	$product_edit.on('change', '.product-description-change', function() {
		let $this = $(this);
		let product_id = $this.data('product-id');
		let description = $this.val();

		let data = {
			'product_id' : product_id,
			'description' : description
		};

		makeRequest('/admin/products/change-description', data, function(response) {
			if (response.success === true) {
				success_message('Product description updated')
			}
			else {
				let error = 'Unable to change product description';
				if (isset(response.error)) {
					error = response.error;
				}
				error_message(error);
			}
		});
	});


	$product_edit.on('change', '.available-online-switch', function() {
		let $this = $(this);
		let product_id = $this.data('product-id');
		let available_online = $this.is(":checked");

		let data = {
			product_id,
			available_online
		}

		makeRequest('/admin/products/toggle-available-online', data, function(response) {
			if (response.success === true) {
				if (available_online) {
					success_message('Product is now Available Online')
				} 
				else {
					error_message('Product is now Unavailable Online');
				}
			}
			else {
				let error = 'Unable to toggle product available online';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
		});
	});

	$product_edit.on('change', '.available-offline-switch', function() {
		let $this = $(this);
		let product_id = $this.data('product-id');
		let available_offline = $this.is(":checked");

		let data = {
			product_id,
			available_offline
		}

		makeRequest('/admin/products/toggle-available-offline', data, function(response) {
			if (response.success === true) {
				if (available_offline) {
					success_message('Product is now Available Offline')
				} else {
					error_message('Product is now Unavailable Offline');
				}
			}
			else {
				let error = 'Unable to toggle product available offline';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
		});
	});
	
	$product_edit.on('change', '.display-only-switch', function() {
		let $this = $(this);
		let product_id = $this.data('product-id');
		let display_only = $this.is(":checked");

		let data = {
			product_id,
			display_only
		}

		makeRequest('/admin/products/toggle-display-only', data, function(response) {
			if (response.success === true) {
				if (display_only) {
					success_message('Product is now Display Only')
				} else {
					error_message('Product is now Display Only');
				}
			}
			else {
				let error = 'Unable to toggle product Display Only';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
		});
	});

	$product_edit.on('click', '.update-variable', function() {
		if($(this).hasClass('disabled')){
			error_message('This is a dynamic variable please click edit in the advanced options above to enable editing')
			return false;
		}
		let variable_name = $(this).data('variable-name');
		let product_id = $(this).data('product-id');
		let input = $(this).closest('.product-edit-container').find('input');
		let input_value = input.val();

		let container =  $(this).closest('.edit-product-variable-container');
		let date_picker = $(container).find('.backdate-variable-date-picker');

		let data = {
			variable_name,
			product_id,
			'input': input_value,
			'update_group': $(container).find('.apply_to_group').val()
		};

		if($(date_picker).is(':visible')){
			data.from_date = $(date_picker).val();
		}

		makeRequest('/admin/products/product/variable/update', data, function(response) {
			if (response.success) {
				success_message("Updated");
			}
			else {
				let error = 'Unable to update product variable';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
		});
	});
	
	$product_edit.on('click', '.allow-edit', function() {
		var container = $(this).closest('.edit-product-variable-container');
		$(container).find('.update-variable.disabled').removeClass('disabled');
		$(container).find('input').removeAttr('disabled');
		$(this).hide();
	});
	
	$('#product-view-flow, #product-creation-flow').on('keyup change', '.product-variable-change-container .variable-input-section :input', function() {
		var container = $(this).closest('.product-variable-change-container');
		var regex_element = $(container).find('.product-variable-validation-regex');
		var regex = $(regex_element).data('regex');
		if(regex){
			regex = '^' + regex + '$';
			var value = $(this).val();
			if(!value.match(regex)){
				$(container).find('.product-variable-validation-error').show();
				return false;
			}
		}
		$(container).find('.product-variable-validation-error').hide();

	});
	
	$('#product-view-flow, #product-creation-flow').on('click', '.edit-variable-validation-rule-button', function() {
		var product_id = $(this).data('product-id');
		var brand_id = $(this).data('brand-id');
		var variable_id = $(this).data('variable-id');

		var data = {
			'product_id' : product_id,
			'brand_id' : brand_id,
			'product_variable_id' : variable_id,
		};

		buildDynamicModal('edit_product_variable_validation_modal', data, function(){
			$('#modal_container').find('.regex-input').trigger('keyup');
		});
	});

	$product_edit.on('click', '.copy-variable', function() {
		let variable_name = $(this).data('variable-name');
		let product_ids = $product.data('product_ids');
		let input = $(this).parent().parent().parent().find('input')
		let input_value = input.val();

		let data = {
			variable_name,
			product_ids,
			'input': input_value
		};

		makeRequest('/admin/products/product/variable/clone', data, function(response) {
			if (response.success) {
				success_message("Variable Cloned");
				loadProducts(product_ids, 0);
			}
			else {
				let error = 'Unable to clone product variable';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
		});
	});

	$product_edit.on('click', '.update-sort', function() {
		showLoader();
		let product_type_id = $(this).data('product-type-id');
		let product_id = $(this).data('product-id');

		let data = {
			product_type_id,
			product_id
		};

		makeRequest('/admin/products/sorting/by_type', data, function(response) {
			if (response.success) {
				createModal('sort-products-by-type', response.html);

				$("#products-sort").sortable({
					connectWith: ".products-sort",
				}).disableSelection();
			}
			else {
				let error = 'Unable to show sorting';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
			hideLoader();
		});
	});

	$modal_container.on('click', '#confirm-sort-products-by-type', function() {
		showLoader();

		// Loop over the sort ui, grabbing each product id and storing
		let sorted_products = [];
		$("#products-sort").find('li').each(function(){
			let product_id = $(this).data('product-id');
			sorted_products.push(product_id);
		});

		let data = {
			sorted_products
		};
	
		// Update sorting
		makeRequest('/admin/products/sorting/update', data, function(response) {
			if (response.success) {
				success_message('Sorting order updated');
				loadProducts($product.data('product_ids'), $('#sort-select-product-id').val());
			}
			else {
				hideLoader();

				let error = 'Unable to save sorting';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
		});
	});
	
	$modal_container.on('keyup', '#regex_input, #regex_test', function() {
		var modal = $(this).closest('.modal');
		var input_regex = $(modal).find('#regex_input').val();

		if(input_regex.match(/[\/\^\$]/g)){
			error_message('don\'t worry about slashes and ^ $ we do that for you');
			$(icon).removeClass('heartbeat');
			$(icon).removeClass('fa-check-circle');
			$(icon).removeClass('pass');
			$(modal).find('#regex_test').removeClass('pass');
			$(icon).width();
			$(icon).addClass('fa-times-circle');
			$(icon).addClass('fail');
			$(icon).addClass('heartbeat');
			$(modal).find('#regex_test').addClass('fail');
		}

		var regex = '^' + input_regex + '$';

		var to_test = $(modal).find('#regex_test').val();
		var icon = $(modal).find('.regex-test-result i');
		if(to_test.match(regex)){
			$(icon).removeClass('heartbeat');
			$(icon).removeClass('fa-times-circle');
			$(icon).removeClass('fail');
			$(modal).find('#regex_test').removeClass('fail');
			$(icon).width();
			$(icon).addClass('fa-check-circle');
			$(icon).addClass('pass');
			$(icon).addClass('heartbeat');
			$(modal).find('#regex_test').addClass('pass');
		}
		else{
			$(icon).removeClass('heartbeat');
			$(icon).removeClass('fa-check-circle');
			$(icon).removeClass('pass');
			$(modal).find('#regex_test').removeClass('pass');
			$(icon).width();
			$(icon).addClass('fa-times-circle');
			$(icon).addClass('fail');
			$(icon).addClass('heartbeat');
			$(modal).find('#regex_test').addClass('fail');
		}

	});
	
	$modal_container.on('click', '#submit_product_variable_validation_regex', function() {
		var modal = $(this).closest('.modal');
		var regex = $(modal).find('#regex_input').val();
		var brand_only = $(modal).find('#brand_only_toggle').is(':checked');
		var variable_id = $(this).data('variable-id');
		var brand_id = $(this).data('brand-id');
		
		var data = {
			'regex' : regex,
			'variable_id' : variable_id,
			'brand_id' : brand_id,
			'brand_only' : brand_only
		};

		
		if($(modal).find('#regex_test').hasClass('fail')){

			var double_check = confirm('the regex you have entered does not pass on your test string - please confirm you wish to continue with this regex');
			if(!double_check){
				return false;
			}
		}

		showLoader();

		makeRequest('/admin/products/variable/validation/edit', data, function(response) {
			if (response.success) {
				success_message();
				$('.product-variable-validation-regex.variable-id-' + variable_id).data('regex', regex);
				$('.product-variable-validation-regex.variable-id-' + variable_id).each(function(){
					$(this).parent().find(':input').trigger('keyup');
				});
				$('#edit_product_variable_validation_modal').modal('hide');
			}
			else {
				let error = 'Unable to update variable validation';
				if (isset(response.error)) {
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});

	});

	$product_edit.on('click', '.remove-featured', function() {
		let product_id = $(this).data('product-id');

		let data = {
			product_id,
		};

		showLoader();

		makeRequest('/admin/products/featured/remove', data, function(response) {
			if (response.success) {
				success_message('Removed from features');
				loadProducts($product.data('product_ids'), product_id);
			}
			else {
				hideLoader();
				
				let error = 'Unable to update featured products';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
		});
	});

	$product_edit.on('click', '.add-featured', function() {
		let product_id = $(this).data('product-id');

		let data = {
			product_id,
		};

		showLoader();

		makeRequest('/admin/products/featured/add', data, function(response) {
			if (response.success) {
				success_message('Added to featured');
				loadProducts($product.data('product_ids'), product_id);
			}
			else {
				hideLoader();

				let error = 'Unable to update featured products';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
		});
	});

	$product_edit.on('click', '.update-featured', function() {
		let product_type_id = $(this).data('product-type-id');
		let product_id = $(this).data('product-id');

		let data = {
			product_type_id,
			product_id
		};

		showLoader();

		makeRequest('/admin/products/featured/by_type', data, function(response) {
			if (response.success) {
				createModal('sort-products-by-type', response.html);
				$("#products-sort").sortable({
					connectWith: ".products-sort",
				}).disableSelection();
			}
			else {
				
				let error = 'Unable to update featured products';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
			hideLoader();
		});
	});

	$modal_container.on('click', '#confirm-featured-sort-products-by-type', function() {
		// Loop over the sort ui, grabbing each product id and storing
		let sorted_products = [];
		$("#products-sort").find('li').each(function(){
			let product_id = $(this).data('product-id');
			sorted_products.push(product_id);
		});

		let data = {
			sorted_products
		};
		
		// Update sorting
		makeRequest('/admin/products/featured/update', data, function(response) {
			if (response.success) {
				success_message('Featured order updated');
			}
			else {
				let error = 'Unable to save featured order';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
		});
	});

	//endregion

	//region Product Creation/Cloning

	$product_brands.on('click', '.new-product', function () {
		let $parent = $(this).parent();
		let brand_id = $parent.data('brand-id');

		let data = {
			brand_id
		};

		$product_brands.find('.team-container').removeClass('active');
		$parent.addClass('active');

		productCreation('/admin/products/product/new', data);
	});

	$products.on('click', '.clone-product', function () {
		let $parent = $(this).parent();
		let product_ids = $parent.data('product-ids');

		let data = {
			product_ids
		};

		productCreation('/admin/products/product/clone', data);
	});


	$product_details.on('change', '#creation_types', function() {
		let brand_id = $product_details.find('#creation_brand').val();
		let type_ids = $product_details.find('#creation_types').val();

		if (type_ids === null || type_ids.length === 0) {
			return;
		}

		let data = {
			brand_id,
			type_ids
		};

		makeRequest('/admin/products/product/check_renewal', data, function(response) {
			if (response.success) {

				let $renewal_product_wrapper = $('#renewal_product_wrapper');
				if (response.duplicate_renewal_product) {
					$renewal_product_wrapper.show();
				} 
				else {
					$renewal_product_wrapper.hide();
				}

				enableContinue();
			}
			else {
				let error = 'Unable to find variables for selected types';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
		});
	});

	$product_details.on('keyup', 'input', enableContinue);
	$product_details.on('change', 'select', enableContinue);

	$product_details.on('click', '#creation_continue', function() {
		let type_ids = $product_details.find('#creation_types').val();

		let brand_id = $product_details.find('#creation_brand').val();

		let data = {
			type_ids,
			"brand_id" : brand_id
		};

		let $creation_type_to_clone = $('#creation_type_to_clone');
		if ($creation_type_to_clone.length) {
			data['product_to_clone'] = $creation_type_to_clone.val();
		}

		showLoader();

		makeRequest('/admin/products/product/new/variables', data, function(response) {
			if (response.success) {
				let $html = $(response.html)

				$('#product-creation-variables-list').html($html);
				$html.find('.select2').select2({'width':'100%', 'tags': true});

				$product_creation_variables.show();
				$product_creation_flow.show();
			}
			else {
				let error = 'Unable to find variables for selected types';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}

			hideLoader();
		});
	});

	$product_creation_variables.on('click', '#creation_save', function () {
		let name = $product_details.find('#creation_name').val();
		let brand_id = $product_details.find('#creation_brand').val();
		let type_ids = $product_details.find('#creation_types').val();
		let group_name = $product_details.find('#creation_groups').val();
		let renewable = $product_details.find('#creation_renewable').is(":checked");
		let featured_position = $product_creation_variables.find('#featured_position').val();
		let cloned_from = $(this).data('cloned-from');
		let variables = {};

		let variable_list = $product_creation_variables.find(':input:not(:button)');
		variable_list.each(function() {
			let $this = $(this);
			let variable_name = $this.attr('name');
			let value = $this.val();
			if (value.length !== 0) {
				variables[variable_name] = value;
			}
		});

		let data = {
			name,
			brand_id,
			type_ids,
			group_name,
			renewable,
			featured_position,
			variables,
			cloned_from
		}


		if($product_creation_variables.find('.product-variable-validation-error:visible').length){
			if(!confirm('it looks like you have a variable that is failing validation - are you sure you want to proceed?')){
				return false;
			}
		}

		showLoader();

		makeRequest('/admin/products/product/new/save', data, function(response) {
			if (response.success) {
				createModal('product_creation_comparison_modal', response.html);

				$product_creation_flow.hide();

				if (response.group_success) {
					success_message('Products created');

					let $selected = $('.team-container.brand-single.active');
					auto_load_product = $('#creation_name').val();
					$selected.find('.edit-products').trigger('click');
				}
				else {
					error_message('Unable to find group for one or more product, please fix before setting active');
				}
			}
			else {
				let error = 'Unable to save new product';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}

			hideLoader();
		});
	});

	//endregion

	//region Ratecard Importing

	$products.on('click', '.import-ratecard', function () {
		let $parent = $(this).parent();
		let product_ids = $parent.data('product-ids');

		let data = {
			product_ids
		}

		$products.find('.team-container').removeClass('active');
		$parent.addClass('active');

		$product.hide();
		$ratecard.data('product-ids', product_ids);

		$('html, body').animate({ scrollTop: 0 }, 'slow');

		showLoader();

		makeRequest('/admin/products/ratecards/start', data, function(response) {
			if (response.success) {
				let $html = $(response.html);
				$html.find('.select2').select2({'width':'100%', 'tags': true});
				$ratecard.html($html);
				$ratecard.show();
			}
			else {
				let error = 'Unable to start rate card upload';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}

			hideLoader();
		});
	});
	
	$product.on('click', '.upload-new-rate-card-trigger', function () {
				
		var product_ids = $product.data('product_ids');

		var data = {
			product_ids
		}

		$product.hide();
		$ratecard.data('product-ids', product_ids);

		showLoader();

		makeRequest('/admin/products/ratecards/start', data, function(response) {
			if (response.success) {
				let $html = $(response.html);
				$html.find('.select2').select2({'width':'100%', 'tags': true});
				$ratecard.html($html);
				$ratecard.show();
			}
			else {
				let error = 'Unable to start rate card upload';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}

			hideLoader();
		});
	});

	$product.on('click', '.upload-new-image-trigger', function () {
		var product_ids = $product.data('product_ids');

		var data = {
			product_ids
		}

		$product.hide();
		showLoader();

		makeRequest('/admin/products/image/start', data, function(response) {
			if (response.success) {
				let $html = $(response.html);
				$product_image.html($html);
				$product_image.show();
			}
			else {
				let error = 'Unable to start image upload';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}

			hideLoader();
		});
	});

	$product_image.on('change', '#image-file', function() {
		let file_name = $(this).val().split('\\').pop();

		$('#image_upload').prop('disabled', file_name.length === 0);

		if (file_name.length === 0) {
			file_name = 'Select File For Upload';
		}

		$('#image-file-name').html(file_name);
	});

	$ratecard.on('change', '#ratecard-file', function() {
		let file_name = $(this).val().split('\\').pop();

		$('#ratecard_upload').prop('disabled', file_name.length === 0);

		if (file_name.length === 0) {
			file_name = 'Select File For Upload';
		}

		$('#ratecard-file-name').html(file_name);
	});

	$ratecard.on('click', '#ratecard_upload', function() {
		let $ratecard_file = $('#ratecard-file');
		let file = $ratecard_file[0].files[0];
		let product_ids = $ratecard.data('product-ids');
		let $ratecard_payment_method = $('#ratecard-payment-method');
		let payment_method = null;
		if ($ratecard_payment_method.length !== 0) {
			payment_method = $ratecard_payment_method.val()
		}

		let data = new FormData();
		data.append('file', file);
		data.append('payment_method', payment_method);
		product_ids.forEach(id => {
			data.append('product_ids[]', id)
		});

		showLoader();

		makeFileRequest('/admin/products/ratecards/parse', data, function(response) {
			if (response.success) {
				$ratecard.data('table_hash', response.table_hash);

				createModal('ratecard-import-modal', response.html);
			}
			else {
				let error = 'Unable to upload rate card';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
			$ratecard_file.val('');
			$ratecard_file.trigger('change');

			hideLoader();
		});
	});

	$product_image.on('click', '#image_upload', function() {
		let $image_file = $('#image-file');
		let file = $image_file[0].files[0];
		let product_ids = $('#image-import').data('product-ids');

		let data = new FormData();
		data.append('file', file);
		data.append('product_ids', product_ids);

		showLoader();
		makeFileRequest('/admin/products/image/upload', data, function(response) {
			if (response.success) {
				success_message();
				return;
			}
			handleRequestError(response);
			hideLoader();
		});
		hideLoader();
	});

	$ratecard.on('click', '#ratecard_download', function() {
		let product_ids = $ratecard.data('product-ids');
		let $ratecard_payment_method = $('#ratecard-payment-method');
		let payment_method = null;
		if ($ratecard_payment_method.length !== 0) {
			payment_method = $ratecard_payment_method.val()
		}

		let data = new FormData();
		data.append('payment_method', payment_method);
		product_ids.forEach(id => {
			data.append('product_ids[]', id)
		});

		showLoader();

		makeFileRequest('/admin/products/ratecards/download', data, function(response) {
			if (response.success) {
				$ratecard.data('table_hash', response.table_hash);

				createModal('ratecard-import-modal', response.html);
			}
			else {
				let error = 'Unable to upload rate card';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
			hideLoader();
		});
	});

	$modal_container.on('click', '#confirm-ratecard-creation', function() {
		let table_hash = $ratecard.data('table_hash');
		let payment_methods = $('#ratecard_payment_methods').val();
		if(!payment_methods){
			error_message('please select at least one payment method');
			return false;
		}
		let fuels = $('#ratecard_fuels').val();
		if(!fuels){
			error_message('please select at least one fuel method');
			return false
		}
		let product_ids = $ratecard.data('product-ids');

		let data = {
			table_hash,
			payment_methods,
			fuels,
			product_ids
		};

		showLoader();

		let that = this;
		makeRequest('/admin/products/ratecards/save', data, function(response) {
			if (response.success) {
				var success_string = 'Rate cards added';
				if(response.warning){
					success_string += ' ' + response.warning;
				}
				success_message(success_string);
				loadBrandProducts($products.data('brand-id'), true, $('#show_status').val());
				$(that).closest('.modal').modal('hide');
			}
			else {
				let error = 'Unable to save rate cards';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}

			hideLoader();
		});
	});

	//endregion
	
	//region Export Map Importing

	$products.on('click', '.import-export-map', function () {
		let $parent = $(this).parent();
		let product_ids = $parent.data('product-ids');

		let data = {
			product_ids
		}

		$products.find('.team-container').removeClass('active');
		$parent.addClass('active');

		$product.hide();
		$export_map.data('product-ids', product_ids);

		$('html, body').animate({ scrollTop: 0 }, 'slow');

		showLoader();

		makeRequest('/admin/products/export_map/start', data, function(response) {
			if (response.success) {
				let $html = $(response.html);
				$html.find('.select2').select2({'width':'100%', 'tags': true});
				$export_map.html($html);
				$export_map.show();
			}
			else {
				let error = 'Unable to start export map upload';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}

			hideLoader();
		});
	});

	$export_map.on('change', '#export_map_file', function() {
		let file_name = $(this).val().split('\\').pop();

		$('#export_map_upload').prop('disabled', file_name.length === 0);

		if (file_name.length === 0) {
			file_name = 'Select File For Upload';
		}

		$('#export_map_file_name').html(file_name);
	});

	$export_map.on('click', '#export_map_upload', function() {
		let $export_map_file = $('#export_map_file');
		let file = $export_map_file[0].files[0];
		let product_ids = $export_map.data('product-ids');

		$export_map_upload_data = new FormData();
		$export_map_upload_data.append('file', file);
		product_ids.forEach(id => {
			$export_map_upload_data.append('product_ids[]', id)
		});

		showLoader();

		makeFileRequest('/admin/products/export_map/parse', $export_map_upload_data, function(response) {
			if (response.success) {
				createModal('export-map-import-modal', response.html);
			}
			else {
				let error = 'Unable to upload export map';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}
			$export_map_file.val('');
			$export_map_file.trigger('change');

			hideLoader();
		});
	});


	$modal_container.on('click', '#confirm-export-map-creation', function() {
		
		var file_location = $(this).data('file-location');
		var product_ids = $export_map.data('product-ids');
		var mode = $('#export_map_import_mode').val();
		if(!mode){
			error_message('Please select a mode');
			return;
		}
		
		var data = {
			'file_location' : file_location,
			'product_ids' : product_ids,
			'mode' : mode 
		};

		var that = this;
		showLoader();
		makeRequest('/admin/products/export_map/save', data, function(response) {
			if (response.success) {
				success_message('Export Map Added');
				loadBrandProducts($products.data('brand-id'), true, $('#show_status').val());
				$(that).closest('.modal').modal('hide');
			}
			else {
				let error = 'Unable to save export map';
				if (isset(response.error)) {
					error = response.error;
				}

				error_message(error);
			}

			hideLoader();
		});
	});

	if($('#product-terms-editor').length) {
		CKEDITOR.config.allowedContent = true;
		CKEDITOR.replace('product-terms-edit-textarea');
	}

	$('body').on('click', '.save-product-terms', function (e) {
		e.preventDefault();
		showLoader();
		let data = {
			product_ids: $(this).data('product-ids'),
			body: CKEDITOR.instances['product-terms-edit-textarea'].getData()
		}

		makeRequest('/product/terms/update', data, function (response) {
			if (response.success) {
				success_message('Saved');
				hideLoader();
				return;
			}
			handleRequestError(response);
			hideLoader();
		})
	});

	if($('#product-features-editor').length) {
		CKEDITOR.config.allowedContent = true;
		CKEDITOR.replace('product-features-edit-textarea');
	}

	$('body').on('click', '.save-product-features', function (e) {
		e.preventDefault();
		showLoader();

		var body = '';
		if($('.product-features-edit-textarea').is(':visible')) {
			body = $('.product-features-edit-textarea').val();
		}
		else {
			body = CKEDITOR.instances['product-features-edit-textarea'].getData();
		}

		var data = {
			product_ids: $(this).data('product-ids'),
			body: body
		}

		makeRequest('/product/features/update', data, function (response) {
			if (response.success) {
				success_message('Saved');
				//if we are on the create page we need to redirect to the edit page -- this was a silly decision willy
				var url = window.location.href;
				if(url.indexOf('/create') > -1) {
					window.location.href = url.replace('/create', '/edit');
					return;
				}
				hideLoader();
				return;
			}
			handleRequestError(response);
			hideLoader();
		})
	});

	$('.toggle-raw-features').on('click', function(e) {
		$('.product-features-edit-textarea').toggle();
		if($('.product-features-edit-textarea').css('visibility') == 'hidden') {
			$('.product-features-edit-textarea').css('visibility', 'visible');
		}
		else{
			$('.product-features-edit-textarea').css('visibility', 'hidden');
		}
		$('.product-features-edit-textarea').next().toggle();
	});
	
	$product.on('keyup', 'input', function (e) {	
		$product.find('.save-all-section').fadeIn();
	});
	
	
	$product.on('click', '.save-all-product-variables', function (e) {	
		showLoader();

		var variable_data = $(this).closest('.tab-pane').find('.update-variable');
		var variable_values = {};
		$.each(variable_data, function(index, element) {
			var variable_name = $(element).data('variable-name');
			var input = $(element).closest('.product-edit-container').find('input');
			var input_value = input.val();
			if(input_value) {
				variable_values[variable_name] = input_value;
			}
		});
		var data = {
			product_id: $(this).data('product-id'),
			variable_values: variable_values
		}
		

		makeRequest('/admin/products/product/variables/update', data, function (response) {
			if (response.success) {
				success_message('Saved');
				hideLoader();
				return;
			}
			handleRequestError(response);
			hideLoader();
		})
	});

	$('#products').on('click','.add-stock-to-product',function(e){
		var product_ids = $(this).closest('.product-single').data('product-ids');
		if(product_ids.lenght > 1){
			error_message('multiple products linked speak to IT');
			return;
		}
		var product_id = product_ids.shift();


		console.log(product_id);
		// get the add product modal content 
	});

	var makeProductStockDataTable = function (start = false, end = false, reload = false) {
		var product_id = $('#product-stock-table').data('product_id');
		var url = '/product/stock-summary/'+product_id;
		
		return $('#product-stock-table').DataTable({
			destroy: true,
			dom: 'flrtiBp',
			ajax: url,
			pageLength: 25,
			ordering: true,
			responsive: true,
			bAutoWidth: false,
			autoWidth: false,
			lengthMenu: [25, 50, 100],
			select: true,
			buttons: [
				'copyHtml5',
				'print',
				{
					extend: 'collection',
					text: 'Export',
					buttons: [
						'csvHtml5',
						'pdfHtml5'
					]
				}
			]
		});
	}
	if($('#product-stock-table').length){
		const product_stock_datatable = makeProductStockDataTable();
	};

	$('.book-stock-in-button').on('click',function(e){
		e.preventDefault();
		$(this).addClass('hide');
		var product_id = $(this).data('product_id');
		showLoader();
		makeRequest('/admin/products/get-add-product-stock-form/'+product_id, '{}', function (response) {
			hideLoader();
			if (response.success) {
				$('.add-product-stock-container').html(response.html)
				$('.add-product-stock-container').removeClass('hide');
				return;
			}
			if(response.error){
				error_message(response.error);
			}
			else{
				error_message('something has gone wrong');
			}
		},'GET');
	});

	$('.add-product-stock-container').on('click','.cancel-stock-add-button',function(e){
		e.preventDefault();
		$('.add-product-stock-container').addClass('hide');
		$('.book-stock-in-button').removeClass('hide');
	});


	$('.add-product-stock-container').on('click','.post-stock-add-button',function(e){
		// get the stock status and location
		var product_id = $(this).data('product_id');
		var data = {
			variables:[]
		};
		data.stock_location_id = $('.add-product-stock-container select[name="stock_location"]').val();
		data.stock_status_id = $('.add-product-stock-container select[name="stock_status"]').val();
		/// next get any inputs with the class variable-input
		$('.add-product-stock-container input.variable-input').each(function(i,el){
			var variable_id = $(el).data('variable_id');
			var value = $(el).val();
			data.variables.push({ variable_id :variable_id, value : value });
		});
		showLoader();
		makeRequest('/admin/products/add-product-stock/'+product_id,data,function(response){
			hideLoader();
			if(isset(response.success) && response.success){
				$('.add-product-stock-container').addClass('hide');
				$('.book-stock-in-button').removeClass('hide');
				success_message('stock item added');
				makeProductStockDataTable();
			}
			else if(isset(response.error)){
				error_message(response.error);
			}
			else{
				error_message('unable to add stock item');
			}
		});
		
	});

	$('#stock_item_history').on('click','.stock-assignment-actions-wrapper .get-stock-allocation-modal-button',function(){
		var order_id = $(this).data('order_id');
		showLoader();
		makeRequest('/admin/products/get-stock-allocation-modal-content/'+order_id,{},function(response){
			hideLoader();
			if(isset(response.success) && response.success){
				$('#stock_allocation_modal_content').html(response.html);
				showModal('stockAllocation');
			}
			else if(isset(response.error)){
				error_message(response.error);
			}
			else{
				error_message('unable to get stock allocation window');
			}
		},'GET');
	});

	$('#stock_item_history').on('click','.stock-assignment-actions-wrapper .get-stock-dispatch-modal-button',function(){
		var order_id = $(this).data('order_id');
		showLoader();
		makeRequest('/admin/products/get-stock-dispatch-modal-content/'+order_id,{},function(response){
			hideLoader();
			if(isset(response.success) && response.success){
				$('#stock_dispatch_modal_content').html(response.html);
				showModal('stockDispatch');
			}
			else if(isset(response.error)){
				error_message(response.error);
			}
			else{
				error_message('unable to get stock dispatch window');
			}
		},'GET');
	});

	$('#stock_item_history').on('click','.stock-assignment-actions-wrapper .get-stock-return-modal-button',function(){
		var order_id = $(this).data('order_id');
		showLoader();
		makeRequest('/admin/products/get-stock-return-modal-content/'+order_id,{},function(response){
			hideLoader();
			if(isset(response.success) && response.success){
				$('#stock_return_modal_content').html(response.html);
				showModal('stockReturn');
			}
			else if(isset(response.error)){
				error_message(response.error);
			}
			else{
				error_message('unable to get stock return window');
			}
		},'GET');
	});

	$('#stockAllocation').on('click', '.item-to-fulfil .add-stock-item-inputs-button', function() {
		var $item_to_allocate = $(this).closest('.item-to-fulfil');
		var items_required = $item_to_allocate.data('items_required');
		var $container = $item_to_allocate.find('.variable-inputs-container');
		var current_inputs_count = $container.children().length;

		// Check if we can add more inputs
		if (current_inputs_count < items_required) {
			// Clone the template and append it to the container
			var $template = $item_to_allocate.find('.item-input-template').first().clone();
			$template.removeClass('hide');
			
			// Optionally, you might want to reset input values in the template
			$template.find('input').val('');

			$container.append($template);
		} else {
			// Optionally, handle the case where no more inputs are allowed
			$item_to_allocate.find('.error-feedback-section').text("Maximum number of items reached for this product.").removeClass('hide').addClass('shake');
			setTimeout(function(){
				$item_to_allocate.find('.error-feedback-section').text("").addClass('hide').removeClass('shake');
			},2000)
		}
	});
	


	$('#stockAllocation').on('click', '.item-to-fulfil .remove-stock-item-inputs-button, .item-to-dispatch .remove-stock-item-inputs-button', function() {
		$(this).closest('.item-input-template').remove();
	});
	
	$('#stockAllocation').on('blur', '.item-to-fulfil .item-input-template .variable-input', function() {
		var $input = $(this);
		var validation_rules = $input.data('validation_rules');
		var value = $input.val();
		var isValid = true;

		// Check if there are validation rules
		if (validation_rules && validation_rules.length > 0) {
			isValid = validation_rules.some(function(rule) {
				var regex = new RegExp(rule);
				return regex.test(value);
			});
		}

		var $error_container = $input.closest('.variable-input-wrapper').find('.input-validation-error');

		// Show or hide the error message based on validity
		if (!isValid) {
			$error_container.text('Invalid input. Please check and try again.').removeClass('hide').addClass('shake');
			$input.closest('.variable-input-wrapper').find('.validation-passed').addClass('hide');
			$input.closest('.variable-input-wrapper').find('.validation-failed').removeClass('hide');
			setTimeout(function(){
				$error_container.text("").addClass('hide').removeClass('shake');
			},2000)

		} else {
			$input.closest('.variable-input-wrapper').find('.validation-passed').removeClass('hide');
			$input.closest('.variable-input-wrapper').find('.validation-failed').addClass('hide');
			$error_container.text('').addClass('hide');
		}
	});
	
	$('#stockAllocation').on('click', '#allocate_stock', function() {
		var $modalContent = $(this).closest('.modal-content').find('#stock_allocation_modal_content');
		var data = {
			order_id: $modalContent.find('input[name="order_id"]').val(),
			items: [],
			tracking_info: {
				courier : '',
				tracking_number : ''
			}
		};
		var isValid = true;

		$modalContent.find('.item-to-fulfil').each(function() {
			var $item = $(this);
			var itemId = $item.data('item_id');
			var itemsRequired = $item.data('items_required');
			var itemCount = 0;
			var productCount = 0;
			var itemData = {
				item_id: itemId,
				values: [],
			};

			$item.find('.variable-inputs-container .item-input-template').each(function() {
				productCount++;
			});
			if (productCount > itemsRequired) {
				isValid = false;
				error_message('attempting to allocate too many items');
			}
			$item.find('.variable-inputs-container .variable-input-wrapper').each(function() {
				var $inputWrapper = $(this);
				var $input = $inputWrapper.find('.variable-input');
				var isRequired = $input.data('enforce-at-allocation') === true;
				var inputValue = $input.val();

				if (isRequired && !inputValue.trim()) {
					$inputWrapper.find('.input-validation-error').text('This field is required.').removeClass('hide');
					isValid = false;
				} else {
					$inputWrapper.find('.input-validation-error').addClass('hide');
					itemData.values.push({
						variable_id: $input.data('variable_id'),
						value: inputValue
					});
					itemCount++;
				}
			});

			if (itemCount > 0) {
				data.items.push(itemData);
			}
		});

		// var courier = $('.couriers-select-wrapper select[name="courier"]').val();
		// if(courier == null){
		// 	$('.couriers-select-wrapper select[name="courier"]').closest('.variable-input-wrapper').find('.input-validation-error').text('Please select a courier').removeClass('hide');
		// 	isValid = false;
		// }
		// else{
		// 	data.tracking_info.courier = courier;
		// }

		// var tracking_number = $('.couriers-select-wrapper input[name="tracking_number"]').val();
		// if(tracking_number.length < 8 ){
		// 	$('.couriers-select-wrapper input[name="tracking_number"]').closest('.variable-input-wrapper').find('.input-validation-error').text('Please select a courier').removeClass('hide');
		// 	isValid = false;
		// }
		// else{
		// 	data.tracking_info.tracking_number = tracking_number;
		// }

		if (isValid) {
			// Replace this with your AJAX request logic
			makeRequest('/admin/products/allocation-stock-to-order/' + data.order_id, data, function(response) {
				hideLoader();
				if (isset(response.success) && response.success) {
					success_message('Stock Allocated');
					hideModal('stockAllocation');
				} else if (isset(response.error)) {
					error_message(response.error);
				} else {
					error_message('unable to allocate stock');
				}
			});
		} else {
			error_message('Validation failed. Please review the inputs.');
		}
	});

	$('#stockReturn').on('click', '.item-to-return .add-stock-item-inputs-button', function() {
		var $item_to_allocate = $(this).closest('.item-to-return');
		var items_required = $item_to_allocate.data('items_required');
		var $container = $item_to_allocate.find('.variable-inputs-container');
		var current_inputs_count = $container.children().length;

		// Check if we can add more inputs
		if (current_inputs_count < items_required) {
			// Clone the template and append it to the container
			var $template = $item_to_allocate.find('.item-input-template').first().clone();
			$template.removeClass('hide');
			
			// Optionally, you might want to reset input values in the template
			$template.find('input').val('');

			$container.append($template);
		} else {
			// Optionally, handle the case where no more inputs are allowed
			$item_to_allocate.find('.error-feedback-section').text("Maximum number of items reached for this product.").removeClass('hide').addClass('shake');
			setTimeout(function(){
				$item_to_allocate.find('.error-feedback-section').text("").addClass('hide').removeClass('shake');
			},2000)
		}
	});

	$('#stockReturn').on('click', '.item-to-unallocate .add-stock-item-inputs-button', function() {
		var $item_to_allocate = $(this).closest('.item-to-unallocate');
		var items_required = $item_to_allocate.data('items_required');
		var $container = $item_to_allocate.find('.variable-inputs-container');
		var current_inputs_count = $container.children().length;

		// Check if we can add more inputs
		if (current_inputs_count < items_required) {
			// Clone the template and append it to the container
			var $template = $item_to_allocate.find('.item-input-template').first().clone();
			$template.removeClass('hide');
			
			// Optionally, you might want to reset input values in the template
			$template.find('input').val('');

			$container.append($template);
		} else {
			// Optionally, handle the case where no more inputs are allowed
			$item_to_allocate.find('.error-feedback-section').text("Maximum number of items reached for this product.").removeClass('hide').addClass('shake');
			setTimeout(function(){
				$item_to_allocate.find('.error-feedback-section').text("").addClass('hide').removeClass('shake');
			},2000)
		}
	});

	$('#stockReturn').on('click', '.item-to-return .remove-stock-item-inputs-button', function() {
		$(this).closest('.item-input-template').remove();
	});

	$('#stockReturn').on('click', '.item-to-unallocate .remove-stock-item-inputs-button', function() {
		$(this).closest('.item-input-template').remove();
	});

	$('#stockReturn').on('blur', '.item-to-return .item-input-template .variable-input', function() {
		var $input = $(this);
		var validation_rules = $input.data('validation_rules');
		var value = $input.val();
		var isValid = true;

		// Check if there are validation rules
		if (validation_rules && validation_rules.length > 0) {
			isValid = validation_rules.some(function(rule) {
				var regex = new RegExp(rule);
				return regex.test(value);
			});
		}

		var $error_container = $input.closest('.variable-input-wrapper').find('.input-validation-error');

		// Show or hide the error message based on validity
		if (!isValid) {
			$error_container.text('Invalid input. Please check and try again.').removeClass('hide').addClass('shake');
			$input.closest('.variable-input-wrapper').find('.validation-passed').addClass('hide');
			$input.closest('.variable-input-wrapper').find('.validation-failed').removeClass('hide');
			setTimeout(function(){
				$error_container.text("").addClass('hide').removeClass('shake');
			},2000)

		} else {
			$input.closest('.variable-input-wrapper').find('.validation-passed').removeClass('hide');
			$input.closest('.variable-input-wrapper').find('.validation-failed').addClass('hide');
			$error_container.text('').addClass('hide');
		}
	});

	$('#stockReturn').on('blur', '.item-to-unallocate .item-input-template .variable-input', function() {
		var $input = $(this);
		var validation_rules = $input.data('validation_rules');
		var value = $input.val();
		var isValid = true;

		// Check if there are validation rules
		if (validation_rules && validation_rules.length > 0) {
			isValid = validation_rules.some(function(rule) {
				var regex = new RegExp(rule);
				return regex.test(value);
			});
		}

		var $error_container = $input.closest('.variable-input-wrapper').find('.input-validation-error');

		// Show or hide the error message based on validity
		if (!isValid) {
			$error_container.text('Invalid input. Please check and try again.').removeClass('hide').addClass('shake');
			$input.closest('.variable-input-wrapper').find('.validation-passed').addClass('hide');
			$input.closest('.variable-input-wrapper').find('.validation-failed').removeClass('hide');
			setTimeout(function(){
				$error_container.text("").addClass('hide').removeClass('shake');
			},2000)

		} else {
			$input.closest('.variable-input-wrapper').find('.validation-passed').removeClass('hide');
			$input.closest('.variable-input-wrapper').find('.validation-failed').addClass('hide');
			$error_container.text('').addClass('hide');
		}
	});

	$('#stockReturn').on('click', '#return_stock', function() {
		var $modalContent = $(this).closest('.modal-content').find('#stock_return_modal_content');
		var data = {
			order_id: $modalContent.find('input[name="order_id"]').val(),
			allocated_items: [],
			delivered_items: [],
			tracking_info: {
				courier : '',
				tracking_number : ''
			}
		};
		var isValid = true;

		// lets deal with allocated stock first

		$modalContent.find('.item-to-unallocate').each(function() {
			var $item = $(this);
			var itemId = $item.data('item_id');
			var stockItemId = $item.data('stock_item_id');
			var itemsRequired = $item.data('items_required');
			var itemCount = 0;
			var productCount = 0;
			var itemData = {
				item_id: itemId,
				stock_item_id: stockItemId,
				values: [],
				stock_action:null,
			};

			$item.find('.variable-inputs-container .item-input-template').each(function() {
				productCount++;
			});
			if (productCount > itemsRequired) {
				isValid = false;
				error_message('attempting to return too many items');
			}
			$item.find('.variable-inputs-container .variable-input-wrapper').each(function() {
				var $inputWrapper = $(this);
				var $input = $inputWrapper.find('.variable-input');
				var isRequired = $input.data('enforce-at-return') === true;
				var inputValue = $input.val();

				if (isRequired && !inputValue.trim()) {
					$inputWrapper.find('.input-validation-error').text('This field is required.').removeClass('hide');
					isValid = false;
				} else {
					$inputWrapper.find('.input-validation-error').addClass('hide');
					itemData.values.push({
						variable_id: $input.data('variable_id'),
						value: inputValue
					});
					itemCount++;
				}
			});
			var stock_action = $item.find('.variable-inputs-container .stock-action-input-wrapper select[name="stock_action"]').val();
			if(stock_action == null){
				isValid = false;
				error_message('You must enter a stock action');
			}
			else{
				itemData.stock_action = stock_action;
			}

			if (itemCount > 0) {
				data.allocated_items.push(itemData);
			}
		});

		$modalContent.find('.item-to-return').each(function() {
			var $item = $(this);
			var itemId = $item.data('item_id');
			var stockItemId = $item.data('stock_item_id');
			var itemsRequired = $item.data('items_required');
			var itemCount = 0;
			var productCount = 0;
			var itemData = {
				item_id: itemId,
				stock_item_id: stockItemId,
				values: [],
				stock_action:null,
			};

			$item.find('.variable-inputs-container .item-input-template').each(function() {
				productCount++;
			});
			if (productCount > itemsRequired) {
				isValid = false;
				error_message('attempting to return too many items');
			}
			$item.find('.variable-inputs-container .variable-input-wrapper').each(function() {
				var $inputWrapper = $(this);
				var $input = $inputWrapper.find('.variable-input');
				var isRequired = $input.data('enforce-at-return') === true;
				var inputValue = $input.val();

				if (isRequired && !inputValue.trim()) {
					$inputWrapper.find('.input-validation-error').text('This field is required.').removeClass('hide');
					isValid = false;
				} else {
					$inputWrapper.find('.input-validation-error').addClass('hide');
					itemData.values.push({
						variable_id: $input.data('variable_id'),
						value: inputValue
					});
					itemCount++;
				}
			});

			var stock_action = $item.find('.variable-inputs-container .stock-action-input-wrapper select[name="stock_action"]').val();
			if(stock_action == null){
				isValid = false;
				error_message('You must enter a stock action');
			}
			else{
				itemData.stock_action = stock_action;
			}

			if (itemCount > 0) {
				data.delivered_items.push(itemData);
			}
		});

		

		// var courier = $('.couriers-select-wrapper select[name="courier"]').val();
		// if(courier == null){
		// 	$('.couriers-select-wrapper select[name="courier"]').closest('.variable-input-wrapper').find('.input-validation-error').text('Please select a courier').removeClass('hide');
		// 	isValid = false;
		// }
		// else{
		// 	data.tracking_info.courier = courier;
		// }

		// var tracking_number = $('.couriers-select-wrapper input[name="tracking_number"]').val();
		// if(tracking_number.length < 8 ){
		// 	$('.couriers-select-wrapper input[name="tracking_number"]').closest('.variable-input-wrapper').find('.input-validation-error').text('Please select a courier').removeClass('hide');
		// 	isValid = false;
		// }
		// else{
		// 	data.tracking_info.tracking_number = tracking_number;
		// }

		if (isValid) {
			// Replace this with your AJAX request logic
			makeRequest('/admin/products/return-stock-for-order/' + data.order_id, data, function(response) {
				hideLoader();
				if (isset(response.success) && response.success) {
					success_message('Stock Returned');
					hideModal('stockReturn');
					window.location.reload();
				} else if (isset(response.error)) {
					error_message(response.error);
				} else {
					error_message('unable to return stock');
				}
			});
		} else {
			error_message('Validation failed. Please review the inputs.');
		}
	});

	$('#stockDispatch').on('click', '.item-to-dispatch .add-stock-item-inputs-button', function() {
		var $item_to_allocate = $(this).closest('.item-to-dispatch');
		var items_required = $item_to_allocate.data('items_required');
		var $container = $item_to_allocate.find('.variable-inputs-container');
		var current_inputs_count = $container.children().length;

		// Check if we can add more inputs
		if (current_inputs_count < items_required) {
			// Clone the template and append it to the container
			var $template = $item_to_allocate.find('.item-input-template').first().clone();
			$template.removeClass('hide');
			
			// Optionally, you might want to reset input values in the template
			$template.find('input').val('');

			$container.append($template);
		} else {
			// Optionally, handle the case where no more inputs are allowed
			$item_to_allocate.find('.error-feedback-section').text("Maximum number of items reached for this product.").removeClass('hide').addClass('shake');
			setTimeout(function(){
				$item_to_allocate.find('.error-feedback-section').text("").addClass('hide').removeClass('shake');
			},2000)
		}
	});

	$('#stockDispatch').on('click', '.item-to-fulfil .add-stock-item-inputs-button', function() {
		var $item_to_allocate = $(this).closest('.item-to-fulfil');
		var items_required = $item_to_allocate.data('items_required');
		var $container = $item_to_allocate.find('.variable-inputs-container');
		var current_inputs_count = $container.children().length;

		// Check if we can add more inputs
		if (current_inputs_count < items_required) {
			// Clone the template and append it to the container
			var $template = $item_to_allocate.find('.item-input-template').first().clone();
			$template.removeClass('hide');
			
			// Optionally, you might want to reset input values in the template
			$template.find('input').val('');

			$container.append($template);
		} else {
			// Optionally, handle the case where no more inputs are allowed
			$item_to_allocate.find('.error-feedback-section').text("Maximum number of items reached for this product.").removeClass('hide').addClass('shake');
			setTimeout(function(){
				$item_to_allocate.find('.error-feedback-section').text("").addClass('hide').removeClass('shake');
			},2000)
		}
	});

	$('#stockDispatch').on('click', '.item-to-dispatch .remove-stock-item-inputs-button', function() {
		$(this).closest('.item-input-template').remove();
	});

	$('#stockDispatch').on('click', '.item-to-fulfil .remove-stock-item-inputs-button', function() {
		$(this).closest('.item-input-template').remove();
	});
	
	$('#stockDispatch').on('blur', '.item-to-dispatch .item-input-template .variable-input', function() {
		var $input = $(this);
		var validation_rules = $input.data('validation_rules');
		var value = $input.val();
		var isValid = true;

		// Check if there are validation rules
		if (validation_rules && validation_rules.length > 0) {
			isValid = validation_rules.some(function(rule) {
				var regex = new RegExp(rule);
				return regex.test(value);
			});
		}

		var $error_container = $input.closest('.variable-input-wrapper').find('.input-validation-error');

		// Show or hide the error message based on validity
		if (!isValid) {
			$error_container.text('Invalid input. Please check and try again.').removeClass('hide').addClass('shake');
			$input.closest('.variable-input-wrapper').find('.validation-passed').addClass('hide');
			$input.closest('.variable-input-wrapper').find('.validation-failed').removeClass('hide');
			setTimeout(function(){
				$error_container.text("").addClass('hide').removeClass('shake');
			},2000)

		} else {
			$input.closest('.variable-input-wrapper').find('.validation-passed').removeClass('hide');
			$input.closest('.variable-input-wrapper').find('.validation-failed').addClass('hide');
			$error_container.text('').addClass('hide');
		}
	});

	$('#stockDispatch').on('blur', '.item-to-fulfil .item-input-template .variable-input', function() {
		var $input = $(this);
		var validation_rules = $input.data('validation_rules');
		var value = $input.val();
		var isValid = true;

		// Check if there are validation rules
		if (validation_rules && validation_rules.length > 0) {
			isValid = validation_rules.some(function(rule) {
				var regex = new RegExp(rule);
				return regex.test(value);
			});
		}

		var $error_container = $input.closest('.variable-input-wrapper').find('.input-validation-error');

		// Show or hide the error message based on validity
		if (!isValid) {
			$error_container.text('Invalid input. Please check and try again.').removeClass('hide').addClass('shake');
			$input.closest('.variable-input-wrapper').find('.validation-passed').addClass('hide');
			$input.closest('.variable-input-wrapper').find('.validation-failed').removeClass('hide');
			setTimeout(function(){
				$error_container.text("").addClass('hide').removeClass('shake');
			},2000)

		} else {
			$input.closest('.variable-input-wrapper').find('.validation-passed').removeClass('hide');
			$input.closest('.variable-input-wrapper').find('.validation-failed').addClass('hide');
			$error_container.text('').addClass('hide');
		}
	});
	
	
	$('#stockDispatch').on('click', '#dispatch_stock', function() {
		var $modalContent = $(this).closest('.modal-content').find('#stock_dispatch_modal_content');
		var data = {
			order_id: $modalContent.find('input[name="order_id"]').val(),
			unallocated_items: [],
			allocated_items: [],
			tracking_info: {
				courier : '',
				tracking_number : ''
			}
		};
		var isValid = true;

		// lets deal with allocated stock first

		$modalContent.find('.item-to-dispatch').each(function() {
			var $item = $(this);
			var itemId = $item.data('item_id');
			var stockItemId = $item.data('stock_item_id');
			var itemsRequired = $item.data('items_required');
			var itemCount = 0;
			var productCount = 0;
			var itemData = {
				item_id: itemId,
				stock_item_id: stockItemId,
				values: [],
			};

			$item.find('.variable-inputs-container .item-input-template').each(function() {
				productCount++;
			});
			if (productCount > itemsRequired) {
				isValid = false;
				error_message('attempting to allocate too many items');
			}
			$item.find('.variable-inputs-container .variable-input-wrapper').each(function() {
				var $inputWrapper = $(this);
				var $input = $inputWrapper.find('.variable-input');
				var isRequired = $input.data('enforce-at-dispatch') === true;
				var inputValue = $input.val();

				if (isRequired && !inputValue.trim()) {
					$inputWrapper.find('.input-validation-error').text('This field is required.').removeClass('hide');
					isValid = false;
				} else {
					$inputWrapper.find('.input-validation-error').addClass('hide');
					itemData.values.push({
						variable_id: $input.data('variable_id'),
						value: inputValue
					});
					itemCount++;
				}
			});

			if (itemCount > 0) {
				data.allocated_items.push(itemData);
			}
		});

		$modalContent.find('.item-to-fulfil').each(function() {
			var $item = $(this);
			var itemId = $item.data('item_id');
			var itemsRequired = $item.data('items_required');
			var itemCount = 0;
			var productCount = 0;
			var itemData = {
				item_id: itemId,
				values: [],
			};

			$item.find('.variable-inputs-container .item-input-template').each(function() {
				productCount++;
			});
			if (productCount > itemsRequired) {
				isValid = false;
				error_message('attempting to allocate too many items');
			}
			$item.find('.variable-inputs-container .variable-input-wrapper').each(function() {
				var $inputWrapper = $(this);
				var $input = $inputWrapper.find('.variable-input');
				var isRequired = ($input.data('enforce-at-allocation') === true || $input.data('enforce-at-dispatch') === true);
				var inputValue = $input.val();

				if (isRequired && !inputValue.trim()) {
					$inputWrapper.find('.input-validation-error').text('This field is required.').removeClass('hide');
					isValid = false;
				} else {
					$inputWrapper.find('.input-validation-error').addClass('hide');
					itemData.values.push({
						variable_id: $input.data('variable_id'),
						value: inputValue
					});
					itemCount++;
				}
			});
			if (itemCount > 0) {
				data.unallocated_items.push(itemData);
			}
		});

		var courier = $('.couriers-select-wrapper select[name="courier"]').val();
		if(courier == null){
			$('.couriers-select-wrapper select[name="courier"]').closest('.variable-input-wrapper').find('.input-validation-error').text('Please select a courier').removeClass('hide');
			isValid = false;
		}
		else{
			data.tracking_info.courier = courier;
		}

		var tracking_number = $('.couriers-select-wrapper input[name="tracking_number"]').val();
		data.tracking_info.tracking_number = tracking_number;
		// if(tracking_number.length < 8 ){
		// 	$('.couriers-select-wrapper input[name="tracking_number"]').closest('.variable-input-wrapper').find('.input-validation-error').text('Please select a courier').removeClass('hide');
		// 	isValid = false;
		// }
		// else{
		// 		data.tracking_info.tracking_number = tracking_number;
		// }

		if (isValid) {
			// Replace this with your AJAX request logic
			makeRequest('/admin/products/dispatch-stock-for-order/' + data.order_id, data, function(response) {
				hideLoader();
				if (isset(response.success) && response.success) {
					success_message('Stock Dispatched');
					hideModal('stockDispatch');
					window.location.reload();
				} else if (isset(response.error)) {
					error_message(response.error);
				} else {
					error_message('unable to dispatch stock');
				}
			});
		} else {
			error_message('Validation failed. Please review the inputs.');
		}
	});

	$('.stock-item-allocation-summary').on('click','.stock-item-summary-row',function(){
		$(this).find('.stock-item-details').toggleClass('hide');
	});

	$('.stock-item-allocation-summary').on('click','.stock-item-details',function(e){
		e.stopPropagation();
	});


	$('.stock-item-allocation-summary').on('click','.stock-item-details .profile-row .edit-stock-item-profile-item',function(e){
		var parent = $(this).closest('.profile-row');
		var wrapper = $(this).closest('.existing-stock-item-variable-record');
		$(wrapper).addClass('hide');
		$(parent).find('.stock-item-variable-input-wrapper').removeClass('hide');
	});

	$('.stock-item-allocation-summary').on('click','.stock-item-details .profile-row .save-stock-item-profile-item',function(e){
		var profile_container = $(this).closest('.profile-row');
		var parent = $(this).closest('.stock-item-variable-input-wrapper');
		var input = $(parent).find('input');
		var key = $(input).attr('name');
		var value = $(input).val();
		if(value.length == 0){
			error_message('you cannot set a blank value');
			return false;
		}
		var data = {
			'key': key,
			'value': value
		};
		var stock_item_id = $(parent).closest('.stock-item-details').data('stock-id');
		// go save the value and if good update the display
		makeRequest('/admin/products/update-stock-item-profile-value/'+stock_item_id,data,function(response){
			console.log(response)
			if(isset(response.success) && response.success){
				profile_container.find('.stock-item-variable-input-wrapper').addClass('hide');
				profile_container.find('.existing-stock-item-variable-record .profile-row-value').text(data.value);
				profile_container.find('.existing-stock-item-variable-record').removeClass('hide');
			}
			else{
				if(isset(response.error)){
					error_message(response.error);
				}
				else{
					error_message('something went wrong - update failed');
				}
			}
		},'POST');
		
	});

	$('.stock-item-allocation-summary .stock-item-allocation-items .date-picker').daterangepicker({
		"locale": {
			"format": 'DD-MM-YYYY',
			"separator": "-"
		},
		"singleDatePicker": true,
		"showDropdowns": true,
		"autoApply": true // This option automatically applies the date when selected
	}, function(start, end, label) {
		// This callback function is called when a date is selected
		$(this.element).val(start.format('DD-MM-YYYY'));
	});

	$('.stock-item-add-event-table').on('click','.add-stock-event-button',function(){
		$(this).attr('disabled');
		var row = $(this).closest('.event-history-event-row');
		var stock_item_id = $(row).data('stock_id');
		var order_id = $(row).data('order_id');
		var event_id = $(row).find('select[name="add_event_selection"] option:selected').val();
		var event_date = $(row).find('input[name="add_event_date"]').val();
		var event_notes = $(row).find('textarea[name="add_event_notes"]').val();
		var data = {
			order_id:order_id,
			event_id:event_id,
			event_date:event_date,
			event_notes:event_notes
		};
		makeRequest('/admin/products/stock/add-event/'+stock_item_id,data,function(response){
			$(this).removeAttr('disabled');
			if(isset(response.success) && response.success){
				// rebuild the event history
				$('.event-history-container').append(response.html);
				$(row).find('select[name="add_event_selection"]').prop('selectedIndex', 0);
				$(row).find('input[name="add_event_date"]').val(moment().format('DD-MM-YYYY'));
            	$(row).find('textarea[name="add_event_notes"]').val('');
				success_message('Event added');
			}
			else{
				if(isset(response.error)){
					error_message(response.error);
				}
				else{
					error_message('something went wrong - update failed');
				}
			}
		},'POST');
	});


});
