$( function() {

    let $remoteconfigs = $('#remoteconfigs');
    let $remoteconfig = $('#remoteconfig');

    setupSearch('#remoteconfigs-search', '.remoteconfig-single');

    function setupSearch(input_id, item_id) {
        // Hide/Display items divs depending on text search input
        $(input_id).on('keyup', function() {
            let search = $(this).val().toLowerCase();
            let $products = $(item_id);

            // Make sure all items are shown if the search input is empty
            if (search.length === 0) {
                $products.show();
            }
            else {
                // Check each div to see if any of the text matches the search input
                $products.each(function() {
                    let $this = $(this);

                    if ($this.text().toLowerCase().indexOf(search) === -1) {
                        $this.hide();
                    }
                    else {
                        $this.show();
                    }
                });
            }
        });
    }

    $remoteconfigs.on('click', '.edit-remoteconfig', function() {
        let $parent = $(this).parent();
        let remoteconfig_id = $parent.data('remoteconfig-id');

        $remoteconfigs.find('.team-container').removeClass('active');
        $parent.addClass('active');

        showLoader();

        makeRequest('/admin/remoteconfigs/edit', { remoteconfig_id }, function(response) {
            if (response.success) {
                $remoteconfig.html(response.html).show();
                $remoteconfig.data('remoteconfig-id', remoteconfig_id);
            }
            else {
                let error = 'Unable to load remote config';
                if (isset(response.error)) {
                    error = response.error;
                }

                error_message(error);
            }
            hideLoader();
        });
    });

    $remoteconfigs.on('click', '.remoteconfigs-create', function() {
        $remoteconfig.hide();
        $remoteconfigs.find('.team-container').removeClass('active');

        showLoader();

        makeRequest('/admin/remoteconfigs/create', {}, function(response) {
            if (response.success) {
                $remoteconfig.html(response.html).show();
            }
            else {
                let error = 'Unable to create new config';
                if (isset(response.error)) {
                    error = response.error;
                }

                error_message(error);
            }
            hideLoader();
        });
    });

    $remoteconfig.on('click', '.cancel-edit-remoteconfig', function() {
        $remoteconfig.hide();
        $remoteconfigs.find('.team-container').removeClass('active');
    });

    $remoteconfig.on('click', '.save-remoteconfig', function() {
        let data = $('#remoteconfig-details').serializeArray();
        data.push({
           name: 'remoteconfig_id',
           value: $remoteconfig.data('remoteconfig-id')
        });

        showLoader();
        makeRequest('/admin/remoteconfigs/update', data, function(response) {
            if (response.success) {
                success_message('Remote config updated')
            }
            else {
                let error = 'Unable to save remote config';
                if (isset(response.error)) {
                    error = response.error;
                }

                error_message(error);
            }
            hideLoader();
        });
    });

    $remoteconfig.on('click', '.create-remoteconfig', function() {
        let data = $('#remoteconfig-details').serializeArray();

        showLoader();
        makeRequest('/admin/remoteconfigs/store', data, function(response) {
            if (response.success) {
                success_message('Remote config created');
                $remoteconfig.html(response.remoteconfig_html).show();
                $('#remoteconfigs-list').html(response.remoteconfigs_html).show();
            }
            else {
                let error = 'Unable to save remote config';
                if (isset(response.error)) {
                    error = response.error;
                }

                error_message(error);
            }
            hideLoader();
        });
    });

	$('.set_remote_system_auth_token').on('click', function(e) {
		e.preventDefault();
		var remote_system_id = $(this).data('remote-system-id');
		customPrompt('Please provide a static auth token for this remote system', 'token', function(value){
			if(value) {
				showLoader();
				makeRequest('/admin/remoteconfigs/set_remote_system_auth_token', {
					'token': value,
					'remote_system_id': remote_system_id
				}, function(response) {
					if (response.success) {
						success_message('Remote system auth token set');
					}
					else {
						let error = 'Unable to set remote system auth token';
						if (isset(response.error)) {
							error = response.error;
						}

						error_message(error);
					}
					hideLoader();
				});
			}
		});
	});
})