class ScriptFlow {
    constructor(anchor){
	
		this.script_ident =  $(anchor).data('scriptid').replace(/_/g, '-');		
        this.anchor = anchor;
        this.quoteId = anchor.data('quoteid');
	    this.steps = {};
        this.validators = {};
        this.first_step = anchor.data('initialstep');
		this.step = this.first_step;
		this.stepCallbacks = {};
        var that = this;
		window.ultra_setup(function(){
            that.init();
        });
    }
    
    init() {
        const that = this;
        this.anchor.off('click', '*[data-step]');
        this.anchor.on('click', '*[data-step]', function() {
            that.handleStep($(this));
        });
        var url = '/script/init/' + this.quoteId+'/'+this.first_step;
        if(isset(window.ultra) && isset(window.ultra.TransactionId)){
            if(window.ultra.TransactionId.length){
                var transaction_id = window.ultra.TransactionId;
                var url = url+'?transaction_id='+transaction_id;
            }
        }
        
        const request = $.ajax({
            method: 'GET',
            url: url,
            dataType: 'json'
        });
        
        this.handleRequest(request);
    }
	
	
	checkStep(ident, callback){
		var quote_id = this.quoteId;
		$.ajax({
			type: "GET",
			url: '/script/check_step/'+quote_id+'/'+ident,
			success: function(response) {
				callback(response);
			},
			error: function (error) {
				callback(error);
			}
		});
	}
    
    handleStep(obj) {
        var that = this;
        var callback = function(check = false){
            var nextStep = typeof obj === 'string' ? obj : $(obj).data('step');
            if(check){
                that.checkStep(that.step, function(response) {
                    var request = $.ajax({
                        method: 'GET',
                        url: '/script/step/' + that.quoteId + '/' + (isset(response.step) ? response.step : nextStep),
                        dataType: 'json',
                    });
                    that.handleRequest(request);
                });
            }
            else{
                var request = $.ajax({
                    method: 'GET',
                    url: '/script/step/' + that.quoteId + '/' + nextStep,
                    dataType: 'json'
                });
                that.handleRequest(request);
            }
        };
        if($(obj).hasClass('back-btn') || $(obj).parent().hasClass('breadcrumb-item')){
            input_interface.reset();
            callback(false);
        }
        else{
			if(this.checkEnforceVerbatim()){
	            showLoader();
    	        this.handleForm(callback);
			}
        }
    }

    handleForm(callback = function(check = false){}){
        submitForm(this.step, this.quoteId, callback, this.anchor);

    }

    dynamicStep(button, back_up){
        var that = this;
        this.checkStep($('#active_step').data('script-ident'), function(response){
            if(response.step){
                that.handleStep(response.step);
            }
            else{
                if($(button).data('success-step')){
                    that.handleStep($(that).data('success-step'));
                }
                else{
                    that.handleStep(back_up);
                }
            }
        });

    }
    
    handleRequest(request) {
        const that = this;
        request.done(function(data) {
            that.step = data.step.ident;
            that.steps[data.step.ident] = data.step.html;			
			that.anchor.html(data.step.html);
			that.runGenericActions();
			if(isset(that.stepCallbacks[data.step.ident]) && typeof(that.stepCallbacks[data.step.ident]) == 'function'){
				that.stepCallbacks[data.step.ident]();
			}
        });
        hideLoader();
    }
    
    showEditBox() {
        const message = new MessageBox('editscript', 'Edit step ' + this.step);
        message.setData({
            before: this.editBoxBefore.bind(this),
            after: this.editBoxAfter.bind(this, message),
            extra: function(form) { form.push({ name: 'html', value: $('#step-edit-wysiwyg').summernote('code') }); },
            ajax: { 
				url: '/script/update/' + this.step, 
				method: 'POST',
				success: function (response) {
					//console.log(response);	
					if ( response.render_status === 200) {
						alert("Successful update of this script step");
					} 
					else {
						alert("Update of this script step has failed; look for:\n\n"+response.render_error);
					}
				},
				error: function(response) {
					alert("Update of this script step has failed:\n\nLikely cause is 422 - Unprocessable Entity, so check for incorrect objects, methods etc.\n\nFix this error before attempting to refresh.");		
				},
			}
		});	
	
        message.run();
    }
    
    editBoxBefore(cb) {
        const request = $.ajax({
            method: 'get',
            dataType: 'json',
            url: '/script/edit/' + this.quoteId + '/' + this.step 
        });

        request.done(function(data) {
            cb(data);
        });
    }
    
    editBoxAfter(message) {
        $('#step-edit-wysiwyg').summernote({
            height: 400,
            minHeight: 300,
            maxHeight: 600,
            focus: true,
            toolbar: [
                ['style', ['bold', 'italic', 'underline', 'clear']],
                ['font', ['fontsize', 'color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['meta', ['codeview', 'undo', 'redo']]
            ]
        });

        message.root.form.on('click', 'a', function(e) {
            e.preventDefault();
            $('#step-edit-wysiwyg').summernote('focus');
            const text = "{{ $s('" + $(this).data('value') + "') }}";
            $('#step-edit-wysiwyg').summernote('insertText', text);
        });
    }

    magicPlease(target){
        return this.script_ident + '.' + target;
    }


    changeScript(script_id){
        var that = this;
        makeRequest('/script/build/' + script_id, {}, function(response){
            if(response.success && response.ident){
                that.handleStep(response.ident);
            }
            else{
                var error = 'failed to load script';
                if(response.error){
                    error = response.error;
                }
                error_message(error);
            }
        });
	}

	handleEnforceVerbatim(){
		if($(this.anchor).find('.enforce-verbatim').length){

			var that = this;

			$(this.anchor).html('<div class="row"> <div class="col-md-11 verbatim-script-content">' + $(this.anchor).html() + `</div><div class="col-md-1 tick-section">
					<div class='progress-circle'></div>
				</div></div>`);

			var total_checks = $(this.anchor).find('.enforce-verbatim').length

			var progress_bar = new ProgressBar.Circle('.progress-circle', {
				color: '#4774b7',
				strokeWidth: 4,
				trailWidth: 1,
				easing: 'easeInOut',
				duration: 500,
				text: {
				  autoStyleContainer: false
				},
				from: { color: '#aaa', width: 1 },
				to: { color: '#4774b7', width: 4 },
				step: function(state, circle) {
					circle.path.setAttribute('stroke', state.color);
					circle.path.setAttribute('stroke-width', state.width);
				
					//we need to know what number of the total checks have been completed based on the percentage of the progress bar
					var completed = Math.round(circle.value() * total_checks);

					if(completed == total_checks){
						circle.setText('<i class="fa-solid fa-check"></i>');
					}
					else{
						circle.setText(completed + '/' + total_checks);
					}
				}
				
			});
			
			$(that.anchor).find('.enforce-verbatim').each(function(i, element){

				var vertical_position = $(element).position().top;

				var tick_box = $(`<div class='tick-box'> 
					<label>
						<input type="checkbox">
						<div class="check">
							<i class="fa-solid fa-check"></i>
						</div>
					<label>
				</div>`);
				$(that.anchor).find('.tick-section').append(tick_box);
				$(tick_box).css('top', vertical_position);


				$(tick_box).on('change', function(e){
					var total_checked = $(that.anchor).find('.tick-box input:checked').length;
					progress_bar.animate( (total_checked / total_checks).toFixed(2));
				});


				// when you click on the verbatim text, check the tick box
				$(element).on('click', function(e){
					$(tick_box).find('input').trigger('click');
				});


			});

			if($('.check-all-tickbox').length){
				$('.check-all-tickbox input').prop('checked', false);
				$('.check-all-tickbox').show();
			}
		}
		else{
			$('.check-all-tickbox').hide();
		}
	}


	checkEnforceVerbatim(){
		if($(this.anchor).find('.tick-section .tick-box input:not(:checked)').length){
			hideLoader();
			$(this.anchor).find('.tick-section .tick-box input:not(:checked)').each(function(i, element){
				$(element).closest('.tick-box').find('.check').addClass('has-error');
				$(element).closest('.tick-box').find('.check').addClass('shake');
			});
			error_message('Please tick the required items to confirm you have read them.');
			return false;
		}
		return true;
	}

	runGenericActions(){

		this.handleEnforceVerbatim();

		// please do not put $('.select2').select2() here - that was why I made it, but we will get conflicts 
		// if you need a select2 please use : 
		/*
			lead_script.registerStepCallback(step_name, function(){
				lead_script.anchor.find('.select2').select2({});
			});
		*/	
	}

	registerStepCallback(step, callback){
		this.stepCallbacks[step] = callback;
	}

	reloadStep(){
		var request = $.ajax({
			method: 'GET',
			url: '/script/step/' + this.quoteId + '/' + this.step,
			dataType: 'json'
		});
		this.handleRequest(request);		
	}

}

module.exports = ScriptFlow;


window.registerCallbackOnAllScripts = function (step, callback){
	$.each(window.scripts, function(i, script){
		script.registerStepCallback(step, callback);
	})
}