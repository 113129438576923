
$(document).ready(function() {
	var api = new apiInterface();
	$('#lead_script').on('click','#boiler_cover_fact_find',function(e) {
		e.preventDefault();
		var quote_id = $('#lead_script').data('quoteid');
		var data = {};
		if($('#current_cover').val()){
			data['customer:boiler_cover_details'] = $('#current_cover').val();
		}
		if($('#current_spend').val()){
			data['customer:current_boiler_cover_spend'] = $('#current_spend').val();
		}
		
		data['customer:home_owner'] = $("[name='homeowner-type']:checked").val();
		
		var that = this;
		
		if(Object.keys(data).length){
			api.boiler_cover_fact_find(quote_id, data, function(){
				window.lead_script.checkStep($('#active_step').data('script-ident'), function(response){
					if(response.step){
						window.lead_script.handleStep(response.step)
					}
					else{
						window.lead_script.handleStep($(that).data('success-step'))
					}
				});		

			});
		}
		
	});
	
	$('#lead_script').on('click', '#boiler_cover_skip_button', function(){
		var quote_id = $('#lead_script').data('quoteid');
		var that = this;
		api.boiler_cover_skip(quote_id, {'skip' : 'Yes'}, function(){
			window.lead_script.handleStep($(that).data('success-step'));
		});
	});
	
	$('#lead_script').on('click', '#boiler_cover_pitch_next_button', function(){
		var quote_id = $('#lead_script').data('quoteid');
		var that = this;
		api.boiler_cover_skip(quote_id, {'skip' : 'No'}, function(){
			window.lead_script.handleStep($(that).data('success-step'));
		});
	});
});


