function addressValidator(){
	this.api = new apiInterface();
	var that = this;
	this.validateManualAddress = function(callback, script = false){
		
		if(!script){
			script = $('#lead_script');
		}

		var validation = [
			function(next) { that.validateProperty($(script).find('#manual-house-number').val(),next); },
			function(next) { that.validateLine($(script).find('#manual-address-1').val(),'address-1',next); },	
			function(next) { that.validateLine($(script).find('#manual-address-2').val(),'address-2',next); },
			
			function(next) { that.validateLine($(script).find('#manual-town').val(),'town',next); },
			function(next) { that.validateLine($(script).find('#manual-county').val(),'county',next); },
			function(next) { that.validatePostcode($(script).find('#manual-postcode').val(),next); },	
		];
		if($(script).find('#manual-electricity').length){
			if($(script).find('#manual-electricity').is(":visible")){
				validation.push(function(next) { that.validateElectricityMeter($(script).find('#manual-electricity_meter').val(),next); });
			}
			else{
				if($(script).find('#manual_hidden_meter_numbers #manual-electricity_meter').length){
					validation.push(function(next) { that.validateElectricityMeter($(script).find('#manual_hidden_meter_numbers #manual-electricity_meter').val(),next); });
				}
			}
		}
		if($(script).find('#manual-gas').length){
			if($(script).find('#manual-gas').is(":visible")){
				validation.push(function(next) { that.validateGasMeter($(script).find('#manual-gas_meter').val(),next); });
			}
			else{
				if($(script).find('#manual_hidden_meter_numbers #manual-gas_meter').length){
					validation.push(function(next) { that.validateGasMeter($(script).find('#manual_hidden_meter_numbers #manual-gas_meter').val(),next); });
				}
			}
		}
		this.processSeries(validation,function(results){
			var error_count = 0;
			var errors = [];
			var data = [];
			for(var i = 0; i<results.length;i++){
				if(results[i][0].status === 'error'){
					errors.push(results[i][0].error);
					error_count++;
				}
				else{
					data.push(results[i][0].data);
				}
			}
			if(error_count===0){
				callback(null,data);	
			}
			else{
				callback(errors,data);
			}
		});
	},
	this.validateElectricityMeter = function(meterNo,callback){
		if(meterNo.length > 10){
			callback({
				status:'ok',
				data : {
					field : 'electricity-meter',
					value : meterNo
				}
			});
		}else{
			callback({
				status:'error',
				error: { 
					message:'Please enter a valid meter No',
					target:'manual-electricity_meter_error'
				}
			});
		}
	},
	this.validateGasMeter = function(meterNo,callback){
		if(meterNo.length >= 9){
			callback({
				status:'ok',
				data : {
					field : 'gas-meter',
					value : meterNo
				}
			});
		}else{
			callback({
				status:'error',
				error: { 
					message:'Please enter a valid meter No',
					target:'manual-gas_meter_error'
				}
			});
		}
	},
	this.validateProperty = function(property,callback){
		if(property.length > 0){
			callback({
				status:'ok',
				data : {
					field : 'house-number',
					value : property
				}
			});
		}else{
			callback({
				status:'error',
				error: { 
					message:'Please enter a valid house name or No',
					target:'manual-house-number-error'
				}
			});
		}
	},
	this.validateLine = function(line,lineName,callback){
		if(line !== 'undefined' && (line.length > 3 || lineName === 'address-2')){
			callback({
				status:'ok',
				data : {
					field : lineName,
					value : line
				}
			});
		}else{
			callback({
				status:'error',
				error: { 
					message:'Please enter a valid address',
					target:'manual-'+lineName+'-error'
				}
			});
		}
	},
	this.validatePostcode = function(postcode,callback){
		if(postcode !== 'undefined' && postcode.length > 5){
			callback({
				status:'ok',
				data : {
					field : 'postcode',
					value : postcode
				}
			});
		}else{
			callback({
				status:'error',
				error: { 
					message:'Please enter a valid postcode',
					target:'manual-postcode-error'
				}
			});
		}
	},
	this.processSeries = function(callbacks, last) {
		var results = [];
		function next() {
	    var callback = callbacks.shift();
		if(callback) {
			callback(function() {
				results.push(Array.prototype.slice.call(arguments));
				next();
			});
		} 
		else {
			last(results);
		}
	}
	next();
	};
}


module.exports = addressValidator;