var getInvoices = function (callback) {
    makeRequest('/invoices/create', {}, function (response) {
        if(isset(response.success) && response.success && isset(response.html)) {
            callback(response.html);
        }
        else {
            if(isset(response.error)) {
                error_message(response.error);
            }
            else {
                error_message();
            }
        }
    });
}

var createDraftInvoice = function (data, callback) {
    makeRequest('/invoices/create-draft', data, function (response) {
        if(isset(response.success) && response.success && isset(response.html)) {
            callback(response.html);
        }
        else {
            if(isset(response.error)) {
                error_message(response.error);
            }
            else {
                error_message();
            }
        }
    });
}

var showInvoice = function (data, callback) {
    makeRequest('/invoices/show', data, function (response) {
        if(isset(response.success) && response.success && isset(response.html)) {
            callback(response.html);
        }
        else {
            if(isset(response.error)) {
                error_message(response.error);
            }
            else {
                error_message();
            }
        }
    });
};

var updateSelectedEntity = function (data, callback) {
    makeRequest('/invoices/update-entity', data, function (response) {
        if(isset(response.success) && response.success && isset(response.html)) {
            callback(response.html);
        }
        else {
            if(isset(response.error)) {
                error_message(response.error);
            }
            else {
                error_message();
            }
        }
    });
}

var saveInvoiceItem = function (data, callback) {
    makeRequest('/invoices/save-item', data, function (response) {
        if(isset(response.success) && response.success && isset(response.html)) {
            callback(response.html);
        }
        else {
            if(isset(response.error)) {
                error_message(response.error);
            }
            else {
                error_message();
            }
        }
    });
}

var saveInvoiceReference = function (data, callback) {
    makeRequest('/invoices/save-reference', data, function (response) {
        if(isset(response.success) && response.success && isset(response.html)) {
            callback(response.html);
        }
        else {
            if(isset(response.error)) {
                error_message(response.error);
            }
            else {
                error_message();
            }
        }
    });
}

var raiseInvoice = function (data, callback) {
    makeRequest('/invoices/raise-invoice', data, function (response) {
        if(isset(response.success) && response.success && isset(response.html)) {
            callback(response.html);
        }
        else {
            if(isset(response.error)) {
                error_message(response.error);
            }
            else {
                error_message();
            }
        }
    });
}

var fetchInvoiceItems = function (data, callback) {
    makeRequest('/invoices/fetch-items', data, function (response) {
        if(isset(response.success) && response.success && isset(response.html)) {
            callback(response.html);
        }
        else {
            if(isset(response.error)) {
                error_message(response.error);
            }
            else {
                error_message();
            }
        }
    });
}

var showInvoiceImportSettings = function (data, callback) {
    makeRequest('/invoices/get-import-settings', data, function (response) {
        if(isset(response.success) && response.success && isset(response.html)) {
            callback(response.html);
        }
        else {
            if(isset(response.error)) {
                error_message(response.error);
            }
            else {
                error_message();
            }
        }
    });
}

var updateInvoiceImportSetting = function(data, callback) {
    makeRequest('/invoices/update-import-settings', data, function (response) {
        if(isset(response.success) && response.success) {
            callback();
        }
        else {
            if(isset(response.error)) {
                error_message(response.error);
            }
            else {
                error_message();
            }
        }
    });
}

$(function() {
    $('body').on('click', '.create-invoice-button', function () {
        getInvoices(function (html) {
            $('.request-invoice-container').show().html(html);
            $('.new-invoice-form .select2').select2({'width': '100%'});
        });
    });

    $('body').on('click', '#create_invoice_draft', function () {
        var container = $('.new-invoice-form');
        var data = {
            'company_id': $(container).find('#company').val(),
            'tax_type_id': $(container).find('#tax_type').val(),
            'type': $(container).find('#type').val()
        };

        createDraftInvoice(data,function (html) {
            $('.request-invoice-container').show().html(html);
            $('.new-invoice-form .select2').select2({'width': '100%'});
            success_message();
        });
    });

    $('body').on('click', '.invoice-item', function () {
        var data = {
            'invoice_id': $(this).data('invoice-id'),
            'type': $(this).data('type')
        };

        showInvoice(data, function (html) {
            $('.request-invoice-container').show().html(html);
            $('.new-invoice-form .select2').select2({'width': '100%'});
        });
    });

    $('body').on('change', '.entity-selection', function () {
        var data = {
            'entity_id': $(this).val(),
            'entity_type': $(this).closest('.new-invoice-form').data('entity-type'),
            'invoice_id': $(this).closest('.new-invoice-form').data('invoice-id')
        };

        updateSelectedEntity(data, function (html) {
            $('.to-address-section').show().find('.address-items').html(html);
            $('.edit-to-company').show();
            $('.to-address-edit').hide();
            $('.revert-to-company').hide();
            success_message();
        });
    });

    $('body').on('click', '.edit-to-company', function () {
        $('.to-address-section').hide();
        $('.edit-to-company').hide();
        $('.to-address-edit').show();
        $('.revert-to-company').show();
    });

    $('body').on('click', '.revert-to-company', function () {
        $('.to-address-section').show();
        $('.edit-to-company').show();
        $('.to-address-edit').hide();
        $('.revert-to-company').hide();
    });

    $('body').on('click', '.add-invoice-item', function () {
        $('#item_tax_type').select2({'width':'100%'});
        $('.new-entity-item').show();
        $('.add-invoice-item').hide();
    });

    $('body').on('keydown', '.new-entity-item :input', function (e) {
        var type = $(this).attr('type');
        if(type == 'number') {
            if(e.key == 'e' || e.key == '+' || e.key == '-') {
                e.preventDefault();
            }
        }
    });

    $('body').on('click', '.save-invoice-item', function () {
        var data = {
            'invoice_id': $('.new-invoice-form').data('invoice-id'),
            'type': $('.new-invoice-form').data('entity-type'),
        };

        data['item_data'] = {};

        $.each($('.new-entity-item :input:not(button)'), function (i, input) {
            data['item_data'][$(input).attr('id')] = $(input).val();
        });

        saveInvoiceItem(data, function (html) {
            $('.request-invoice-container').show().html(html);
            $('.new-invoice-form .select2').select2({'width': '100%'});
        });
    });

    $('body').on('click', '.save-external-reference', function () {
        var data = {
            'invoice_id': $('.new-invoice-form').data('invoice-id'),
            'type': $('.new-invoice-form').data('entity-type'),
            'reference': $('#external_reference').val()
        };

        saveInvoiceReference(data, function (html) {
            $('.request-invoice-container').show().html(html);
            $('.new-invoice-form .select2').select2({'width': '100%'});
        });
    });

    $('body').on('click', '.edit-external-reference', function () {
        $('.external-reference-edit').show();
        $('.reference-container').hide();
        // $('.revert-reference').show();
    });

    $('body').on('click', '.revert-reference', function () {
        $('.external-reference-edit').hide();
        $('.reference-container').show();
    });

    $('body').on('click', '#raise_invoice', function () {
        var data = {
            'invoice_id': $('.new-invoice-form').data('invoice-id'),
            'type': $('.new-invoice-form').data('entity-type'),
        };

        raiseInvoice(data, function (html) {
            $('.request-invoice-container').show().html(html);
            $('.new-invoice-form .select2').select2({'width': '100%'});
        });
    });


    $('body').on('click', '.fetch-invoice-items', function () {
        var data = {
            'invoice_id': $('.new-invoice-form').data('invoice-id'),
            'type': $('.new-invoice-form').data('entity-type'),
        };

        fetchInvoiceItems(data, function (html) {
            $('.request-invoice-container').show().html(html);
            $('.new-invoice-form .select2').select2({'width': '100%'});
            success_message('Fetched All Possible Items');
        });
    });

    $('body').on('click', '.invoice-import-settings', function () {
        showInvoiceImportSettings({}, function (html) {
            $('.request-invoice-container').show().html(html);
            $('.new-invoice-form .select2').select2({'width': '100%'});
        });
    });

    $('body').on('click', '.save-invoice-import-setting', function () {
        var container = $(this).closest('.invoice-settings-row');

        var data = {
            'partner_id': $(container).data('id'),
            'active': $(container).find('.active-flag-option').val()
        }

        updateInvoiceImportSetting(data, function () {
            success_message();
        });
    });
});