$(document).ready(function(){
	var api = new apiInterface();
	var quote_id = $('#lead_script').data("quoteid");
	var view = new displayHelper();
	var customer_validator = new customerValidator();
	$('#lead_script').on('click','#dependant-details-save',function(e){
		e.preventDefault();
		var validation = [	
			function(next) { customer_validator.validateFirstName($('#lead_script #dependant-details-first_name').val(),next); },
			function(next) { customer_validator.validateLastName($('#lead_script #dependant-details-last_name').val(),next); },
			function(next) { customer_validator.validateGenericDOB(
				$('#lead_script #dependant-details-dob-day').val(),
				$('#lead_script #dependant-details-dob-month').val(),
				$('#lead_script #dependant-details-dob-year').val()
				,next); 
			}
		];
		customer_validator.validateAll(validation,function(errors,data){
			if(errors !== null){
				$.each(errors,function(index,error){
					$('#lead_script #'+error.target).html(error.message);
				});
//				$('#lead_script span.error-message').show();
//				setTimeout(function(){ 
//					$('#lead_script span.error-message').hide();
//				}, 1000);
			}
			else{
				//need to add the dependant data on server
				var dependant_data =[];
				dependant_data.first_name = $('#lead_script #dependant-details-first_name').val();
				dependant_data.last_name = $('#lead_script #dependant-details-last_name').val();
				dependant_data.relationship = $('#lead_script #dependant-details-relationship').val(),
				dependant_data.dob_day = $('#lead_script #dependant-details-dob-day').val();
				dependant_data.dob_month = $('#lead_script #dependant-details-dob-month').val();
				dependant_data.dob_year = $('#lead_script #dependant-details-dob-year').val();
				dependant_data.dependant_on = $('#lead_script #dependant-details-dependant-on').val();
				dependant_data.dependency_term = $('#lead_script #dependant-details-dependency-term').val();
				dependant_data.whole_of_life = $('#lead_script #wol-yes').is(':checked')?'yes':'no';
				$.ajax({
					url: "/dependant/editDependant",
					method:"POST",
					data: {
						'quote_id' : quote_id,
						'dependant_id' : 0,
						'first_name' : dependant_data.first_name,
						'last_name' : dependant_data.last_name,
						'dependant:relationship' : dependant_data.relationship,
 						'dependant:dob' : dependant_data.dob_day+'-'+dependant_data.dob_month+'-'+dependant_data.dob_year,
						'dependant:on' : dependant_data.dependant_on,
						'dependant:term_in_years': dependant_data.dependency_term,
						'dependant:whole_of_life': dependant_data.whole_of_life,
						'mode' : 'update'
					},
					success: function(response){
						if(response.errors){
						} 
						else {
							//update the display and show it
							$('#dependant-inputs').hide();
							//and then on screen (either dynamic list or refresh block)
							var new_dependant = response.html;
							$('#lead_script #dependant-grab').append(new_dependant);						}				
					},
					error : function(error) {
					}		
				});
			}
		});
	});
	
	//clicked to add a new dependant
	$('#lead_script').on('click','#create-new-dependant',function(e){
		e.preventDefault();
		$('#dependant-inputs').show();
	});
	
	//clicked to discard adding of a new dependant
	$('#lead_script').on('click','#dependant-details-discard',function(e){
		e.preventDefault();
		$('#dependant-inputs').hide();
	});

	//edit existing dependant from script
	$('#lead_script').on('click', '.edit-dependant', function() {
		var quote_id = $('#lead_script').data("quoteid");
	    var dependant_id = $(this).parent().attr('id');
		$.ajax({
			url: "/dependant/editDependant",
			method:"POST",
		data: {
			'quote_id' : quote_id,
			'dependant_id' : dependant_id,
			'mode' : 'edit'
		},
		success: function(response){
			if(response.errors){
				
			}else {
				//update the display and show it
				$('#dependant-details-edit').html(response.html['core']+response.html['specific']);
				$('#dependant-inputs').show();
			}
		},
		error : function(error) {
		}		
		});	
	});
	
	//delete existing dependant from script (sales order, but not contact)
	$('#lead_script').on('click', '.delete-dependant', function() {
		var quote_id = $('#lead_script').data("quoteid");
	    var parent_dependant = $(this).parent();
		var dependant_id = parent_dependant.attr('id');
		$.ajax({
			url: "/dependant/editDependant",
			method:"POST",
		data: {
			'quote_id' : quote_id,
			'dependant_id' : dependant_id,
			'mode' : 'delete'
		},
		success: function(response){
			if(response.errors){
				
			}else {
				//update the display and remove it
				parent_dependant.remove();
			}
		},
		error : function(error) {
		}		
		});	
	});
	
});


