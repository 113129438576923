
$(function(){

	$('#todo-container').on('click','.user-task-tab',function(){
		var target = $(this).data('target');
		$(this).closest('.nav-tabs').find('li').removeClass('active');
		$(this).closest('li').addClass('active');
		$('.user-tasks-tab-container .todo-tab-content').hide();
		$(target).show();
		$(target).addClass('active');
	});
    $('#users-table').DataTable( {
        ajax: "/get/users",
		responsive: true,
		bAutoWidth:false,
		autoWidth: false,
        pageLength: 40,
        lengthMenu: [25, 40, 100]
    });

	$('.user-tasks-tab-container').on('click', '.add-user-task', function(){
		
		var that = $(this);
		var active_tab = $(this).closest('.todo-tab-content').attr('id');
		
		var parent = $(this).closest('.add-user-task-container');
		var task_title = $(parent).find(':input[name="task_title"]').val();
		var task_description = $(parent).find(':input[name="task_description"]').val();
		
		if(task_title.length < 2){
			error_message('Please Enter a descriptive Task Title');
			return false;
		}		
				
		var user_id = $(parent).find(':input[name="task_user_id"]').find(":selected").val();
		
		var due_date = $(parent).find(".todo-date").val();
		
		var data = {
			'task_title' : task_title,
			'task_description' : task_description,
			'user_id' : user_id,
			'due_date' : due_date
		};
		
		$.ajax({
			url: '/user/add/task',
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					success_message('Task Created');
					$('#todo-container').find('.user-tasks-tab-container').html(response.html);
					$('.user-tasks-tab-container .todo-tab-content').hide();
					$('#'+active_tab).show();
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
	});

	$('.user-tasks-tab-container').on('click', '.user-task-check', function(){
		
		var task_id = $(this).data('task-id');
		
		var that = $(this);
		var active_tab = $(this).closest('.todo-tab-content').attr('id');
		$.ajax({
			url: '/user/toggle/task/' + task_id,
			type:"GET",
			dataType: 'json',
			success: function(response) {
				if(checkErrors(response)){
					success_message('Task Updated');
					$('#todo-container').find('.user-tasks-tab-container').html(response.html);
					$('.user-tasks-tab-container .todo-tab-content').hide();
					$('#'+active_tab).show();
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
	});

	$('.user-tasks-tab-container').on('click', '.user-task-info', function(){
		$(this).parent().find('.user-task-edit-section').hide();
		$(this).parent().find('.user-task-info-section').toggle();
	});
	
	$('.user-tasks-tab-container').on('click', '.user-task-edit', function(){
		$(this).parent().find('.user-task-info-section').hide();
		$(this).parent().find('.user-task-edit-section').toggle();
	});

	$('.user-tasks-tab-container').on('click', '.edit-user-task', function(){
		var active_tab = $(this).closest('.todo-tab-content').attr('id');
		var that = $(this);
		var parent = $(this).closest('.user-task-edit-section');
		var task_id = $(this).data('task-id');
		var task_title = $(parent).find(':input[name="task_title"]').val();
		var task_description = $(parent).find(':input[name="task_description"]').val();
		
		if(task_title.length < 2){
			error_message('Please Enter a descriptive Task Title');
			return false;
		}		
				
		var user_id = $(parent).find(':input[name="task_user_id"]').find(":selected").val();
		
		var due_date = $(parent).find(".todo-date").val();
		
		var data = {
			'task_id' : task_id,
			'task_title' : task_title,
			'task_description' : task_description,
			'user_id' : user_id,
			'due_date' : due_date
		};
		
		$.ajax({
			url: '/user/edit/task',
			type:"POST",
			dataType: 'json',
			data: data,
			success: function(response) {
				if(checkErrors(response)){
					if(checkErrors(response)){
						success_message('Task Updated');
						$('#todo-container').find('.user-tasks-tab-container').html(response.html);
						$('.user-tasks-tab-container .todo-tab-content').hide();
						$('#'+active_tab).show();
					}
				}
			},
			error: function (error) {
				error_message(error.error);
			}
		});
		
	});
    
    //open ledger entries event
    $('#dashboard-container').on('click','#dashboard-ledger',function(e) {
        
        //console.log('Pop ledger table');
        
        //load ledger entries
        var start = Date.parse($('#reportrange').data('daterangepicker').startDate._d);
	var end = Date.parse($('#reportrange').data('daterangepicker').endDate._d);
        
        const ledgertable = $('#user-ledger-table').DataTable({
            destroy: true,
            ajax: "/get/user/ledger?start=+"+start+"&end="+end,
            pageLength: 5,
            ordering: false,
            responsive: true,
            searching: false,
            paging: true,
            autoWidth: true,
            //lengthMenu: false
        });
	
        $('#user-ledger-pop').show();
//        setTimeout(function(){
//            $('#user-ledger-pop').hide();
//	},10000);
    });
	
	
    $('#users-table_wrapper').on('click', 'div#toggle_user_leads', function(){
		var user_id = $(this).data('user-id');
		
		var icon = $(this).find('i');
		
		var data = {
			'user_id' : user_id
		};
		
		$.ajax({ cache: false,
			url: '/user/toggleleads',
			type:"POST",
			dataType: 'json',
			data: data,
			success: function (response) {
				if(response.error == null) {
					success_message();
					icon.toggleClass('fa-play');
					icon.toggleClass('fa-ban');
				}
				else{
					error_message(response.error);
				}
			},
			error: function (error) {
				loaderClear();
			}
		});
		
	})
	
	if($('input.date-input').length > 0){
	
		$('input.date-input').daterangepicker({
			"singleDatePicker": true,
		});
	}

	var calendar = false;

	// var loadCalendar = function(){
	// 	var user_id = $('#user-absences').data('user_id');
	// 	var data = {
	// 		'user_id' : user_id
	// 	};
	// 	makeRequest('/user/absences/get', data, function(response){
	// 		if(isset(response.success) && response.success && isset(response.events)){
	// 			return response.events
	// 		}
	// 		else{
	// 			var error = 'unable to load past absences';
	// 			if(isset(response.error)){
	// 				error = response.error;
	// 			}
	// 			error_message(error);
	// 		}
	// 	})
	// }

	var start_hour = 9;
	var end_hour = 18;
	var createAbsenceRangePicker = function(element, single =false){


		var start = moment().startOf('day').set('hour', start_hour);
		var end = moment().startOf('day').set('hour', end_hour);


		
		$(element).daterangepicker({
			showCustomRangeLabel : true,
			timePicker: true,
			locale: {
				format: 'D, MMMM, YYYY h:mm a'
			},
			singleDatePicker: single,
			ranges: {
				'Today': [start, end],
				'Yesterday': [moment(start).subtract(1, 'days'), moment(end).subtract(1, 'days')],
				'This Week': [moment(start).startOf('week').set('hour', start_hour), moment(end).endOf('week').startOf('day').set('hour', end_hour)],
				'Last Week': [moment(start).subtract(1, 'week').startOf('week').set('hour', start_hour), moment(end).subtract(1, 'week').endOf('week').startOf('day').set('hour', end_hour)],
				'This Month': [moment(start).startOf('month').set('hour', start_hour), moment(end).endOf('month').startOf('day').set('hour', end_hour)],
				'Last Month': [moment(start).subtract(1, 'month').startOf('month').set('hour', start_hour), moment(end).subtract(1, 'month').endOf('month').startOf('day').set('hour', end_hour)]
			}
		});
	}



	var clickedAbsenceEvent = function(info){		
		var absence_id = info.event.extendedProps.absence_id;
		var user_id = $('#user-absences').data('user_id');

		var data = {
			'absence_id' : absence_id,
			'user_id' : user_id
		};

		makeRequest('/user/absence/get', data, function(response){
			if(isset(response.success) && response.success){
				createModal('editUserAbsence', response.html, function(){
					createAbsenceRangePicker($('#editUserAbsence .absence-date-picker'));
				});
			}
			else{
				var error = 'unable to load absence';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	}
	
	var clickedAbsenceDate = function(info){
		var start_date_selected = moment(info.start);
		var end_date_selected = moment(info.end);
		if(start_date_selected.hour()  < 1){
			start_date_selected.set('hour', start_hour);
			//because the end date will be midnight on the day after we want
			end_date_selected.subtract(1, 'day');
			end_date_selected.set('hour', end_hour);
		}
		$('#createUserAbsence .absence-date-picker').val(start_date_selected.format('D, MMMM, YYYY h:mm a') + ' - ' + end_date_selected.format('D, MMMM, YYYY h:mm a'));
		$('#createUserAbsence .absence-single-date-picker').val(start_date_selected.format('D, MMMM, YYYY h:mm a'));
		createAbsenceRangePicker($('#createUserAbsence .absence-date-picker'));
		createAbsenceRangePicker($('#createUserAbsence .absence-single-date-picker'), true);
		showModal('createUserAbsence');
	}


	window.buildAbsenceCalendar = function(){
		var read_only = $('#absence_calendar').data('read-only');
		var empty = function(){};
		var user_id = $('#user-absences').data('user_id');
		calendar = new FullCalendar.Calendar($('#absence_calendar')[0], {
			
			'initialtView': 'dayGridMonth',
			 headerToolbar: {
				start: 'dayGridMonth,timeGridWeek,timeGridDay',
				center: 'title',
				end: 'prev,next',
			},
			'eventClick' : read_only ? empty : clickedAbsenceEvent,
			'select' : read_only ? empty : clickedAbsenceDate,
			'selectable': true,
			'minTime' : '8:00',
			'maxTime' : '19:00',
			events: {
				url: '/user/absences/get',
				method: 'POST',
				extraParams: function(){
					return {
						user_id: user_id,
						}
					}	
				,
				failure: function() {
				alert('there was an error while fetching events!');
				},
				color: 'yellow',   // a non-ajax option
				textColor: 'black' // a non-ajax option
			}
		});
		calendar.render();

		createAbsenceRangePicker($('#absence_range'));
	}


	var absence_warning_map = {
		1 : [ // 1 = Illness -- we want these to all be < 5 days
			{
				'type' : 'length',
				'units' : 'days',
				'comparison' : '>',
				'limit' : 4,
				'warning' : 'Are you sure you dont want to make this a long term illness?'
			}
		],
		3 : [ // 1 = Long Term Illness -- we want these to all be > 4 days
			{
				'type' : 'length',
				'units' : 'days',
				'comparison' : '<',
				'limit' : 5,
				'warning' : 'Are you sure you dont want to make this a (short term) illness?'
			}
		]
	}

	var warning_methods = {
		'length' : function(data, condition){
			var start = moment(data.start_date);
			if(data.open_ended){
				var end = moment();
			}
			else{
				var end = moment(data.end_date);
			}
			var length = end.diff(start, condition.units);
			if(condition.comparison == '>'){
				if(length > condition.limit){
					return true
				} 
			}
			else if(condition.comparison == '<'){
				if(length < condition.limit){
					return true
				} 
			}
			return false;

		}
	}

	var snakeCheck = function(data){
		var bail = true;
		if(isset(absence_warning_map[data.type_id])){
			$.each(absence_warning_map[data.type_id], function(i, warning_condition){
				if(isset(warning_condition['type']) && isset(warning_methods[warning_condition['type']])){
					if(warning_methods[warning_condition['type']](data, warning_condition)){
						if(!confirm(warning_condition['warning'])){
							bail = false;
						}
					}
				}
			});
		}
		return bail;
	}
	
	if($('#absence_calendar').length && isset(FullCalendar)){
		buildAbsenceCalendar();
	}


	$('#create_new_absence').on('click', function(){
		var range_picker = $('#absence_range').data('daterangepicker');
		var start_date = range_picker.startDate.format('YYYY/MM/DD HH:mm:ss');
		var end_date = range_picker.endDate.format('YYYY/MM/DD HH:mm:ss');
		var type_id = $('#absence_type').val();
		var notes = $('#absence_reason').val();
		var user_id = $('#user-absences').data('user_id');

		var data = {
			'start_date' : start_date,
			'end_date' : end_date,
			'type_id' : type_id,
			'notes' : notes,
			'user_id' : user_id
		};
		if(snakeCheck(data)){
			makeRequest('/user/absence/create', data, function(response){
				if(isset(response.success) && response.success){
					success_message();
					if(isset(response.event)){
						calendar.addEvent(response.event);
					}
				}
				else{
					var error = 'unable to create absence';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			});
		}

	});

	$('body').on('click', '.absence-tab-selector',function(){
		//need to do a sneaky resize so the calendar sizes correctly 
		setTimeout(function(){calendar.updateSize();}, 500);
	})

	$('#modal_container').on('click', '#editUserAbsence button#save', function(){

		var open_ended = $('#editUserAbsence #create_open_ended_absence').is(':checked');
		if(open_ended){
			var range_picker = $('#editUserAbsence .absence-single-date-picker').data('daterangepicker');
			var start_date = range_picker.startDate.format('YYYY/MM/DD HH:mm:ss');
			var end_date = null;
		}
		else{
			var range_picker = $('#editUserAbsence .absence-date-picker').data('daterangepicker');
			var start_date = range_picker.startDate.format('YYYY/MM/DD HH:mm:ss');
			var end_date = range_picker.endDate.format('YYYY/MM/DD HH:mm:ss');
		}
		var type_id = $('#editUserAbsence .absence-type').val();
		var notes = $('#editUserAbsence .absence-reason').val();
		var absence_id = $(this).data('absence-id');

		var data = {
			'start_date' : start_date,
			'end_date' : end_date,
			'type_id' : type_id,
			'notes' : notes,
			'absence_id' : absence_id,
			'open_ended' : open_ended
		};
		if(snakeCheck(data)){
			makeRequest('/user/absence/edit', data, function(response){
				if(isset(response.success) && response.success){
					success_message();
					$('#editUserAbsence').modal('hide');
					var events = calendar.getEvents();
					$.each(events, function(i, event){
						if(event.extendedProps.absence_id == absence_id){
							event.remove();
							return false;
						}
					});
					calendar.addEvent(response.event);

				}
				else{
					var error = 'unable to edit absence';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			});
		}

	});
	
	
	$('#modal_container').on('click', '#editUserAbsence button.delete-absence', function(){
		//some lovely synchronous javascript here 
		if(confirm('Are you sure you want to delete this absence?')){
			var reason = '';
			while(reason.length < 5){
				reason = prompt('Why are you deleting this?');
				if(reason === null){
					return false;
				}
				if(reason.length < 5){
					alert('please enter a descriptive reason');
				}
			}
			var absence_id = $(this).data('absence-id');

			var data = {
				'absence_id' : absence_id,
				'deletion_reason' : reason
			};
			
			makeRequest('/user/absence/delete', data, function(response){
				if(isset(response.success) && response.success){
					error_message('deleted');
					$('#editUserAbsence').modal('hide');
					var events = calendar.getEvents();
					$.each(events, function(i, event){
						if(event.extendedProps.absence_id == absence_id){
							event.remove();
							return false;
						}
					});
				}
				else{
					var error = 'unable to delete absence';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			});
		}

	});
	
	$('#modal_container').on('click', '#createUserAbsence button#save', function(){
		var open_ended = $('#createUserAbsence #create_open_ended_absence').is(':checked');
		if(open_ended){
			var range_picker = $('#createUserAbsence .absence-single-date-picker').data('daterangepicker');
			var start_date = range_picker.startDate.format('YYYY/MM/DD HH:mm:ss');
			var end_date = null;
		}
		else{
			var range_picker = $('#createUserAbsence .absence-date-picker').data('daterangepicker');
			var start_date = range_picker.startDate.format('YYYY/MM/DD HH:mm:ss');
			var end_date = range_picker.endDate.format('YYYY/MM/DD HH:mm:ss');
		}
		var type_id = $('#createUserAbsence .absence-type').val();
		var notes = $('#createUserAbsence .absence-reason').val();
		var user_id = $('#user-absences').data('user_id');

		var data = {
			'start_date' : start_date,
			'end_date' : end_date,
			'type_id' : type_id,
			'notes' : notes,
			'user_id' : user_id,
			'open_ended' : open_ended
		};

		if(snakeCheck(data)){
			makeRequest('/user/absence/create', data, function(response){
				if(isset(response.success) && response.success){
					success_message();
					if(isset(response.event)){
						calendar.addEvent(response.event);
						$('#createUserAbsence').modal('hide');
					}
				}
				else{
					var error = 'unable to create absence';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			});
		}


	});


	$('#modal_container').on('change', '#create_open_ended_absence, #edit_open_ended_absence', function(){
		var container = $(this).closest('.form-group');
		if($(this).is(':checked')){
			//we are open ended
			$(container).find('.non-open-ended-time').hide();
			$(container).find('.open-ended-time').show();
		}
		else{
			//we are fixed ended
			$(container).find('.non-open-ended-time').show();
			$(container).find('.open-ended-time').hide();
		}

	});

	$('#email_signature_section').on('click', '#send_test_email', function(){
		var signature = $('#email_signature_edit').val();
		var user_id = $(this).data('user-id');
		customPrompt('Enter the email to send the test to', 'email', function(email){
			var email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if(!email_regex.test(email)){	
				error_message('invalid email');
				return false;
			}
			var data = {
				'signature' : signature,
				'email' : email,
				'user_id' : user_id
			};
			makeRequest('/user/signature/test', data, function(response){
				if(isset(response.success) && response.success){
					success_message('Email sent');
				}
				else{
					var error = 'unable to send email';
					if(isset(response.error)){
						error = response.error;
					}
					error_message(error);
				}
			});
		});
	});
	
	$('#email_signature_section').on('click', '#update_email_signature', function(){
		var signature = $('#email_signature_edit').val();
		var data = {
			'signature' : signature,
			'user_id' : $(this).data('user-id')
		};
		makeRequest('/user/signature/save', data, function(response){
			if(isset(response.success) && response.success){
				success_message('updated');
			}
			else{
				var error = 'unable to save';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});
	});

	
	$('.user_files_section').on('click','.create-user-folder',function(){
		// see if we're in a folder or at root level
		let data = {};
		let parent_folder_id = $(this).data('current_folder_id');
		if(isset(parent_folder_id) && parent_folder_id > 0){
			data.parent_folder_id = parent_folder_id;
		}
		// lets get the user id 
		let parent_container = $(this).closest('.user_files_section');
		let user_id = parent_container.data('user_id');
		let result_target = parent_container.attr('id');
		if(isset(user_id) && user_id > 0){
			data.user_id = user_id;
			data.result_target = result_target;
		}
		// lets go get some modal content
		makeRequest('/user/folders/get-create-folder-modal-content',data,function(response){
			if(isset(response.success) && response.success){
				$('#createUserFolderModal').find('.modal-content').html(response.html);
				showModal('createUserFolderModal');
				setTimeout(function(){
					$('#createUserFolderModal').find('.modal-content input[name="folder_name"]').focus();
				},200);
				$('#createUserFolderModal').find('.modal-content input[name="folder_name"]').on('keypress',function(e) {
					var keycode = (e.keyCode ? e.keyCode : e.which);
					if(keycode == 13) {
						e.preventDefault();
						$('#createUserFolderModal').find('#create_user_folder').trigger('click');
					}
				});
			}

		},'POST');
	});

	$('#createUserFolderModal').on('click','#create_user_folder',function(e){
		e.preventDefault();
		let form = $(this).closest('form').serializeArray();
		let user_id = $(this).closest('form').find('input[name="user_id"]').val();
		let parent_folder_id = $(this).closest('form').find('input[name="parent_folder_id"]').val();
		let result_target = $(this).closest('form').find('input[name="result_target"]').val();
		let data = {};
		if(isset(parent_folder_id)){
			data.folder_id = parent_folder_id;
		}
		if(isset(result_target)){
			data.result_target = result_target;
		}
		makeRequest('/user/folders/create-user-file',form,function(response){
			if(isset(response.success) && response.success){
				success_message('folder created');
				hideModal('createUserFolderModal');
				// find the original section to replace the content
				makeRequest('/user/folders/get-user-folder-content/'+user_id,data,function(response){
					if(isset(response.success) && response.success){
						$('#'+result_target).find('.user-folders-list').html(response.html);
					}
					else{
						error_message(response.error);
					}
				},'POST');
			}
			else{
				error_message(response.error)
			}
		});

	})

	$('.user_files_section').on('dblclick','.user-folder-container',function(){
		let parent = $(this).closest('.user_files_section');
		let user_id = parent.data('user_id');
		let folder_id = $(this).data('folder_id');
		let result_target = $(parent).attr('id');
		let data = {folder_id:folder_id};
		if(isset(result_target)){
			data.result_target = result_target;
		}
		makeRequest('/user/folders/get-user-folder-content/'+user_id,data,function(response){
			if(isset(response.success) && response.success){
				$(parent).find('.user-folders-list').html(response.html);
			}
			else{
						error_message(response.error);
					}
		},'POST');

	});

	$('.user_files_section').on('dblclick','.file-icon-container',function(){
		let target = $(this).data('target');
		let base_url = window.location.origin;
		let win = window.open(base_url+target, '_blank');
		if (win) {
			//Browser has allowed it to be opened
			win.focus();
		} else {
			//Browser has blocked it
			alert('Please allow popups for this website');
		}
	});

	$('.user_files_section').on('click','.navigate-back-user-folder',function(){
		let parent = $(this).closest('.user_files_section');
		let user_id = parent.data('user_id');
		let folder_id = $(this).data('parent_folder_id');
		let result_target = $(parent).attr('id');
		let data = {folder_id:folder_id};
		if(isset(result_target)){
			data.result_target = result_target;
		}
		makeRequest('/user/folders/get-user-folder-content/'+user_id,data,function(response){
			if(isset(response.success) && response.success){
				$(parent).find('.user-folders-list').html(response.html);
			}
			else{
				error_message(response.error);
			}
		},'POST');

	});

	$('.user_files_section').on('click','.edit-user-folder-menu',function(e){
		var parent = $(this).closest('.user_files_section');
		var target_folder_id = $(this).data('folder_id');
		var parent_folder = $(this).closest('.folder-view-wrapper').data('current_folder_id');
		var parentOffset = $(this).closest('.user_files_section').offset(); 
		var result_target = $(this).closest('.user_files_section').attr('id');
		$(this).closest('.user_files_section').find('.context_menu').data('folder_id',target_folder_id);
		$(this).closest('.user_files_section').find('.context_menu').data('parent_folder_id',parent_folder);
		$(this).closest('.user_files_section').find('.context_menu').data('result_target',result_target);
		var relX = e.pageX - parentOffset.left;
		var relY = e.pageY - parentOffset.top;
		relY = relY + 50;
		relX = relX + 15;

		makeRequest('/user/folders/get-folder-context-menu',{folder_id:target_folder_id},function(response){
			if(isset(response.success) && response.success){
				$(parent).find('.context_menu').css({left:relX, top:relY});
				$(parent).find('.context_menu').html(response.html);
				$(parent).find('.context_menu').show();
			}
			else{
				error_message(response.error);
			}
		},'POST');

		// $('#context_menu').find('.context-menu-option-wrapper').hide();
		// $('#context_menu').find('.folder-menu-options').show();
		
	});

	$('.user_files_section').on('click','.edit-user-file-menu',function(e){
		var target_file_id = $(this).data('file_id');
		var parent = $(this).closest('.user_files_section');
		var parent_folder = $(this).closest('.file-view-wrapper').data('current_folder_id');
		var parentOffset = $(this).closest('.user_files_section').offset();
		var result_target = $(this).closest('.user_files_section').attr('id'); 
		$(parent).find('.context_menu').data('file_id',target_file_id);
		$(parent).find('.context_menu').data('parent_folder_id',parent_folder);
		$(parent).find('.context_menu').data('result_target',result_target)
		var relX = e.pageX - parentOffset.left;
		var relY = e.pageY - parentOffset.top;
		relY = relY + 50;
		relX = relX + 15;

		makeRequest('/user/folders/get-file-context-menu',{file_id:target_file_id},function(response){
			if(isset(response.success) && response.success){
				$(parent).find('.context_menu').css({left:relX, top:relY});
				$(parent).find('.context_menu').html(response.html);
				$(parent).find('.context_menu').show();
			}
			else{
				error_message(response.error);
			}
		},'POST');
	});

	$('.user_files_section').on('click','.context-menu-dismiss',function(){
		$(this).closest('.user_files_section').find('.context_menu').hide();
	});

	var renameUserFolder = function(data){
		makeRequest('/user/folders/rename-user-folder',data,function(response){
			if(isset(response.success) && response.success){
				let sub_data = {folder_id:data.parent_folder_id};
				if(isset(data.target)){
					sub_data.result_target = data.target;
				}
				success_message(response.message);
				makeRequest('/user/folders/get-user-folder-content/'+data.user_id,sub_data,function(response){
					if(isset(response.success) && response.success){
						$('#'+data.target).find('.user-folders-list').html(response.html);
					}
					else{
						error_message(response.error);
					}
				},'POST');
			}
			else{
				error_message(response.error)
			}
		});
	};

	var renameUserFile = function(data){
		makeRequest('/user/folders/rename-user-file',data,function(response){
			if(isset(response.success) && response.success){
				let sub_data = {folder_id:data.parent_folder_id};
				if(isset(data.target)){
					sub_data.result_target = data.target;
				}
				success_message(response.message);
				makeRequest('/user/folders/get-user-folder-content/'+data.user_id,sub_data,function(response){
					if(isset(response.success) && response.success){
						$('#'+data.target).find('.user-folders-list').html(response.html);
					}
					else{
						error_message(response.error);
					}
				},'POST');
			}
			else{
				error_message(response.error)
			}
		});
	};

	$('.user_files_section').on('click','.rename-user-folder',function(e){
		e.preventDefault();
		let folder_id = $(this).closest('.context_menu').data('folder_id');
		let parent_folder_id = $(this).closest('.context_menu').data('parent_folder_id');
		let result_target = $(this).closest('.context_menu').data('result_target');
		let name_container = $('.user_files_section').find('.user-folder-container[data-folder_id="'+folder_id+'"] .folder-name');
		let current_name = name_container.html();
		let parent = $(this).closest('.user_files_section');
		let user_id = parent.data('user_id');
		name_container.html('<input name="folder_name" data-folder_id="'+folder_id+'" value="'+current_name+'">');
		$(name_container).find('input[name="folder_name"]').focus();
		$(name_container).find('input[name="folder_name"]').on('click',function(e){
			e.stopPropagation();
		});
		$(this).closest('.context_menu').hide();
		let data = {folder_id:folder_id,parent_folder_id:parent_folder_id,user_id:user_id,result_target:result_target};
		$(name_container).find('input[name="folder_name"]').on('blur',function(e){
			data.folder_name = $(this).val();
			name_container.html(data.folder_name);
			renameUserFolder(data);
		});
		$(name_container).find('input[name="folder_name"]').on('keypress',function(e) {
			var keycode = (e.keyCode ? e.keyCode : e.which);
			if(keycode == 13) {
				data.folder_name = $(this).val();
				name_container.html(data.folder_name);
				renameUserFolder(data);
			}
		});
	});

	$('.user_files_section').on('click','.rename-user-file',function(e){
		e.preventDefault();
		let file_id = $(this).closest('.context_menu').data('file_id');
		let parent_folder_id = $(this).closest('.context_menu').data('parent_folder_id');
		let result_target = $(this).closest('.context_menu').data('result_target');
		let name_container = $('.user_files_section').find('.file-icon-container[data-file_id="'+file_id+'"] .file-name');
		let current_name = name_container.html();
		let parent = $(this).closest('.user_files_section');
		let user_id = parent.data('user_id');
		name_container.html('<input name="file_name" data-file_id="'+file_id+'" value="'+current_name+'">');
		$(name_container).find('input[name="file_name"]').focus();
		$(name_container).find('input[name="file_name"]').on('click',function(e){
			e.stopPropagation();
		});
		$(this).closest('.context_menu').hide();
		let data = {file_id:file_id,parent_folder_id:parent_folder_id,user_id:user_id,result_target:result_target};
		$(name_container).find('input[name="file_name"]').on('blur',function(e){
			data.file_name = $(this).val();
			name_container.html(data.file_name);
			renameUserFile(data);
		});
		$(name_container).find('input[name="file_name"]').on('keypress',function(e) {
			var keycode = (e.keyCode ? e.keyCode : e.which);
			if(keycode == 13) {
				data.file_name = $(this).val();
				name_container.html(data.file_name);
				renameUserFile(data);
			}
		});
	});

	$('.user_files_section').on('click','.navigation-share-user-folder',function(e){
		e.preventDefault();
		let folder_id = $(this).data('current_folder_id');
		let parent = $(this).closest('.user_files_section');
		let result_target = parent.attr('id');
		let user_id = parent.data('user_id');
		let data = {
			folder_id:folder_id,
			user_id:user_id,
			result_target:result_target
		};
		showLoader();
		makeRequest('/user/folders/get-share-folder-modal-content',data,function(response){
			hideLoader();
			if(isset(response.success) && response.success){
				$(parent).find('.context_menu').hide();
				$('#shareUserFolderModal').find('.modal-content').html(response.html);
				$('.select2-multi').select2({'width' : '100%'})
				showModal('shareUserFolderModal');
			}
			else{
				error_message(response.error);
			}
		},'POST');
	});

	$('.user_files_section').on('click','.share-user-folder',function(e){
		e.preventDefault();
		let folder_id = $(this).closest('.context_menu').data('folder_id');
		let parent = $(this).closest('.user_files_section');
		let result_target = $(parent).attr('id');
		let parent_folder_id = $(this).closest('.context_menu').data('parent_folder_id');
		let user_id = parent.data('user_id');
		let data = {
			folder_id:folder_id,
			user_id:user_id,
			result_target:result_target
		};
		showLoader();
		makeRequest('/user/folders/get-share-folder-modal-content',data,function(response){
			hideLoader();
			if(isset(response.success) && response.success){
				$(parent).find('.context_menu').hide();
				$('#shareUserFolderModal').find('.modal-content').html(response.html);
				$('.select2-multi').select2({'width' : '100%'})
				showModal('shareUserFolderModal');
			}
			else{
				error_message(response.error);
			}
		},'POST');
	});

	$('#shareUserFolderModal').on('click','#share-folder-copy-link',function(e){
		e.preventDefault();
		let web_short_code = $(this).data('folder_code');
		let url = window.location.origin+'/shared/folder/'+web_short_code;
		copyToClipboard(url);
		success_message('link coppied');
	});

	$('#shareUserFolderModal').on('click','#share_user_folder',function(e){
		e.preventDefault();
		let form = $(this).closest('form').serializeArray();
		let user_id = $(this).closest('form').find('input[name="user_id"]').val();
		let folder_id = $(this).closest('form').find('input[name="folder_id"]').val();
		let parent_folder_id = $(this).closest('form').find('input[name="parent_folder_id"]').val();
		let data = {};
		if(isset(folder_id)){
			data.folder_id = folder_id;
		}
		showLoader();
		makeRequest('/user/folders/share-user-folder',form,function(response){
			if(isset(response.success) && response.success){
				success_message('folder shared');
				hideModal('shareUserFolderModal');
				hideLoader();
			}
			else{
				error_message(response.error)
			}
		});
	});

	// delete = show modal, confirm contents, delete everything...show loader 
	$('.user_files_section').on('click','.delete-user-folder',function(e){
		e.preventDefault();
		let folder_id = $(this).closest('.context_menu').data('folder_id');
		let parent = $(this).closest('.user_files_section');
		let parent_folder_id = $(this).closest('.context_menu').data('parent_folder_id');
		let result_target = $(parent).attr('id');
		let user_id = parent.data('user_id');
		let data = {
			folder_id:folder_id,
			user_id:user_id,
			result_target:result_target
		};
		showLoader();
		makeRequest('/user/folders/get-delete-folder-modal-content',data,function(response){
			hideLoader();
			if(isset(response.success) && response.success){
				$(parent).find('.context_menu').hide();
				$('#deleteUserFolderModal').find('.modal-content').html(response.html);
				showModal('deleteUserFolderModal');
			}
			else{
				error_message(response.error);
			}
		},'POST');
	});

	$('#deleteUserFolderModal').on('click','#delete_user_folder',function(e){
		e.preventDefault();
		let form = $(this).closest('form').serializeArray();
		let user_id = $(this).closest('form').find('input[name="user_id"]').val();
		let folder_id = $(this).closest('form').find('input[name="folder_id"]').val();
		let parent_folder_id = $(this).closest('form').find('input[name="parent_folder_id"]').val();
		let result_target = $(this).closest('form').find('input[name="result_target"]').val();
		let data = {};
		if(isset(folder_id)){
			data.folder_id = folder_id;
		}
		showLoader();
		makeRequest('/user/folders/delete-user-folder',form,function(response){
			if(isset(response.success) && response.success){
				success_message('folder deleted');
				hideModal('deleteUserFolderModal');
				hideLoader();
				let sub_data = {folder_id:parent_folder_id,result_target:result_target};
				// find the original section to replace the content
				makeRequest('/user/folders/get-user-folder-content/'+user_id,sub_data,function(response){
					if(isset(response.success) && response.success){
						$('#'+result_target).find('.user-folders-list').html(response.html);
					}
					else{
						error_message(response.error);
					}
				},'POST');
			}
			else{
				error_message(response.error)
			}
		});
	});

	$('.user_files_section').on('click','.delete-user-file',function(e){
		e.preventDefault();
		let file_id = $(this).closest('.context_menu').data('file_id');
		let parent = $(this).closest('.user_files_section');
		let parent_folder_id = $(this).closest('.context_menu').data('parent_folder_id');
		let result_target = $(parent).attr('id');
		let user_id = parent.data('user_id');
		let data = {
			file_id:file_id,
			user_id:user_id,
			folder_id:parent_folder_id,
			result_target:result_target
		};
		$(parent).find('.context_menu').hide();
		if(confirm('Are you sure? This is permanent and cannot be undone')){
			makeRequest('/user/folders/delete-user-file',data,function(response){
				showLoader();
				if(isset(response.success) && response.success){
					success_message('file deleted');
					hideLoader();
					// find the original section to replace the content
					makeRequest('/user/folders/get-user-folder-content/'+user_id,data,function(response){
						if(isset(response.success) && response.success){
							$(parent).find('.user-folders-list').html(response.html);
						}
						else{
							error_message(response.error);
						}
					},'POST');
				}
				else{
					error_message(response.error)
				}
			},'POST');
		}
	});

	let handleUploadModal = function(data){
		makeRequest('/user/folders/get-file-upload-modal-content',data,function(response){
			if(isset(response.success) && response.success){
				$('#userDocumentUploadModal').find('.modal-content').html(response.html);
				$("div#user-documents-dropzone").dropzone({
					 url: "/user/folders/upload-documents/"+data.user_id,
					 'autoProcessQueue':false,
					 'uploadMultiple':true,
					 'parallelUploads':15,
					 'addRemoveLinks':true,
					 headers: {
     				   'X-CSRF-TOKEN': $('body').data("token")
    				},
					init: function() {
						var user_document_upload = this;
						// First change the button to actually tell Dropzone to process the queue.

						$('#upload_user_documents').on('click',function(e){
							showLoader();
						// Make sure that the form isn't actually being sent.
							e.preventDefault();
							e.stopPropagation();
							user_document_upload.processQueue();
						});
						user_document_upload.on("sending", function(file, xhr, formData){
								formData.append("folder_id", data.folder_id);
								formData.append("user_id", data.user_id);
						});
						user_document_upload.on("successmultiple", function(multiple) {
							hideModal('userDocumentUploadModal');
							hideLoader();
							$('#userDocumentUploadModal').find('.modal-content').html('');
							// rebuild order details documents panel
							let sub_data = {
								folder_id:data.display_folder_id,
								result_target:data.result_target
							}
							makeRequest('/user/folders/get-user-folder-content/'+data.user_id,sub_data,function(response){
								if(isset(response.success) && response.success){
										$('#'+data.result_target).find('.user-folders-list').html(response.html);
								}
								else{
									error_message(response.error);
								}
							},'POST');
						});
					},
					'accept' : function(file, done) {
						$('.instruction-to-drop').hide();
						$('.dz-success-mark').hide();
						$('.dz-error-mark').hide();
						let html_preview = '';
						if(file.type == 'application/pdf'){
							html_preview = '<div class="file-icon-container"><i class="fa-solid fa-file-pdf large-icons"></i></div>';
						}
						else if (file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
							html_preview = '<div class="file-icon-container"><i class="fa-solid fa-file-spreadsheet large-icons"></i></div>';
						}
						else if(file.type == 'text/csv'){
							html_preview = '<div class="file-icon-container"><i class="fa-solid fa-file-csv large-icons"></i></div>';
						}
						else if(file.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
							html_preview = '<div class="file-icon-container"><i class="fa-solid fa-file-word large-icons"></i></div>';
						}
						if(html_preview.length){
							$(file.previewElement).find('.dz-image').html(html_preview);
						}
						return done();
					}				
					
				});
				showModal('userDocumentUploadModal');
			}
		},'POST');
	};
	

	$('.user_files_section').on('click','.upload-user-file-to-folder',function(){
		let user_id = $(this).closest('.user_files_section').data('user_id');
		let folder_id = $(this).closest('.context_menu').data('folder_id');
		let parent_folder_id = $(this).closest('.context_menu').data('parent_folder_id');
		let result_target = $(this).closest('.user_files_section').attr('id');
		let data = {
			user_id:user_id,
			folder_id:folder_id,
			display_folder_id:parent_folder_id,
			result_target:result_target
		};
		$(this).closest('.user_files_section').find('.context_menu').hide();
		handleUploadModal(data);

	});
	

	$('.user_files_section').on('click','.upload-user-file',function(){
		let user_id = $(this).closest('.user_files_section').data('user_id');
		let folder_id = $(this).data('current_folder_id');
		let result_target = $(this).closest('.user_files_section').attr('id');
		let data = {
			user_id:user_id,
			folder_id:folder_id,
			display_folder_id:folder_id,
			result_target:result_target
		};
		handleUploadModal(data);
	});

	$('.user_files_section').on('click','.folder-breadcrumb-item',function(){
		let folder_id = $(this).data('folder_id');
		let parent = $(this).closest('.user_files_section');
		let user_id = parent.data('user_id');
		let result_target = $(parent).attr('id');
		alert(result_target);
		makeRequest('/user/folders/get-user-folder-content/'+user_id,{folder_id:folder_id,result_target:result_target},function(response){
			if(isset(response.success) && response.success){
				$(parent).find('.user-folders-list').html(response.html);
			}
			else{
				error_message(response.error);
			}
		},'POST');
	});
});

