$(document).ready(function() {
	
	$('.ticket-list-item-block').on('click','.hide-description-btn', function(){
		var ticket_id = $(this).parent().parent().parent().data('ticket_id');
		$('#ticket-description-'+ticket_id).toggle();
		
		var button_text = $(this).text();
		
		if(button_text == 'hide') {
			$(this).text('show');
		}
		else if(button_text == 'show') {
			$(this).text('hide');
		}
	});
	
	
	$('.tab-content').on('click','.ticket-item-button',function(){
		var ticket_id = $(this).parent().data('ticket_id');
		$('#ticket-item-detail-'+ticket_id).toggle();		
	});
	
	$('.contact-ticket-list').on('click','#closed-tickets-btn', function(){
		$('#closed-tickets-container').toggle();
	});
	
	$('.ticket-queue-sortable').sortable({
		connectWith : ".ticket-queue-sortable",
		start: function(event, ui){
           $('.ticket-queue-sortable').each(function(){
			   if($(this).children().length < 2){
				   $(this).css('min-height', '50px');
			   }
		   });
		},
		stop : function(){
			 $('.ticket-queue-sortable').each(function(){
				$(this).css('min-height', '0px');
		   });
		},
		update: function (event, helper) {
			
			
			//get the container we have dropped in 
			var container = $(this);
			
			//container priority
			//var container_priority = $(container).data('priority');
			
			//the tciket that was moved
			var moved = $(helper.item[0]);
			
			if(!$.contains(container[0], moved[0])){
				return;
			}
			
			
						
			var closest = $(moved).prev();
			if(closest.length === 0){
				closest = $(moved).next();
				if(closest.length === 0){
					closest = container;
				}
			}
			
			var base_priority = closest.data('priority');
			
			$(moved).attr('data-priority', base_priority);
			
			//get all visible tickets with the same priority 
			
			var tickets_to_update = $('.ticket-item[data-priority="' + base_priority + '"]:visible');
			
			
			var data = {
				'priority' : base_priority,
				'tickets' : []
			};
			
			$(tickets_to_update).each(function(){
				
				data.tickets.push($(this).data('ticket-id'));
				
			});
			
			
			$.ajax({
				cache: false,
				url: 'ticket/edit/sort',
				type: "POST",
				dataType: 'json',
				data: data,
				success: function (response) {
					if (response.error == null) {
						success_message();
					} 
					else {
						error_message(response.error);
					}
				},
				error: function (error) {
					loaderClear();
				}
			});
			

		}
	});

});