const websocket_client = require("./websocket_client");

class softphone_websocket extends websocket_client {
    constructor(){
		super();
		this.type = 'softphone';
		this.port = 8081;
		// this is the magic part of th url that apache will pick up and redirect to the websocket server -- 
		// for chat it would be /wss2/
		this.magic_url = '/softphone_socket/'; 
		this.connection_type = '';
		this.softphone_url = '/softphone';
		this.socket_host = 'softphone.crm.discount';
	}

	sendMessage(action, payload){
		var to_send = JSON.stringify({
			'action' : action,
			'payload' : payload
		});
		if(this.isConnected()){
			this.connection.send(to_send);
		}
		else{
			this.deferMethod(function(){
				this.connection.send(to_send);
			});
		}

	}

	setConnectionType(){
		this.sendMessage('set_connection_type', {'connection_type' : this.connection_type});
	}

	clickToCall(phone_number){
		this.sendMessage('click_to_call', {
			'phone_number' : phone_number
		});
	}
	
	joinConference(external_id){
		this.sendMessage('join_conference', {
			'external_id' : external_id
		});
	}
	
	listenIn(external_id){
		this.sendMessage('listen_in', {
			'external_id' : external_id
		});
	}
		
	bargeIn(external_id){
		this.sendMessage('barge_in', {
			'external_id' : external_id
		});
	}

	updateUserStateMap(){
		this.sendMessage('update_user_state_map', {});
	}

	handleMake_softphoneMessage(payload){
		var url = this.softphone_url;
		if(Object.keys(payload).length){
			url += '?' + $.param(payload);
		}
		window.open(url, '_blank', 'menubar=no,status=no,titlebar=no,location=no,height=650,width=520,scrollbars=no');
	}

	handleMake_callMessage(payload){
		$('#softphone_input').val(payload.phone_number);
		var phone_number = payload.phone_number;
		twilio_interface.makeCall(phone_number);
	}
	
	handleListen_inMessage(payload){
		var external_id = payload.external_id;
		twilio_interface.listenIn(external_id);
	}
	
	handleBarge_inMessage(payload){
		var external_id = payload.external_id;
		twilio_interface.bargeIn(external_id);
	}
	
	handle_conferenceMessage(payload){
		var external_id = payload.external_id;
		twilio_interface.joinConference(external_id);
	}

	closedActions(){
		error_message('socket closed');
	}
	
	connectedActions(){
		this.setConnectionType()
		success_message('socket open');
	}

	handleCallStatusChangeMessage(payload){
		twilio_interface.handleCallStatusChange(payload);
	}
	
	handleContactStatusUpdateMessage(payload){
		console.log(payload);
		twilio_interface.updateContactStatus(payload);
	}

}

module.exports  = new softphone_websocket();
