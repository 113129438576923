class websocket_client {
    constructor(){
		this.connection = false;
		this.auth_token = false;
		this.reconnection_attempts = 0;
		this.reconnection_limit = 3;
		this.is_connected = false;
		this.type = '';
		this.port = 0;
		this.magic_url = ''; // this is the magic part of th url that apache will pick up and redirect to the websocket server -- 
							 // for chat it would be /wss2/
		this.deferred = [];
		this.socket_host = window.location.host;
	}

	start(config = {}){
		this.handleAuth();
		var that = this;
		$.each(config, function(key, value){
			that[key] = value;
		});
	}

	getAuthToken(callback = function(){}){
		var that = this;
		makeRequest('/token/get/' + this.type, {}, function(response){
			if(isset(response.success) && response.success && isset(response.token)){
				var token = response.token;
				localStorage.setItem( that.type +'_auth_token', token);
				that.auth_token = token;
				callback();
			}
		});
	}

	handleAuth(){
		var auth_token = localStorage.getItem(this.type +'_auth_token');
		var that = this;
		if(!auth_token){
			this.getAuthToken(function(){
				that.createConnection()
			});
		}
		else{
			this.auth_token = auth_token;
			this.createConnection();
		}
	}

	reAuth(){
		if(this.reconnection_attempts < this.reconnection_limit){
			var that = this;
			this.reconnection_attempts++;
			this.getAuthToken(function(){that.createConnection()});
		}
	}

	handleError(payload){
		if(isset(payload.resolution)){
			if(isset(this[payload.resolution])){
				this[payload.resolution](payload);
				return false;
			}
		}
		if(isset(payload.error)){
			error_message(payload.error);
			return false;
		}
		return true;
	}


	createConnection(){
		var that = this;
		var local = window.location.host.indexOf('local') !== -1; 
		var slug = local ? ':' + this.port : this.magic_url; 
		var protocol = local ? 'ws' : 'wss'; 
		var host = local ? '127.0.0.1' : this.socket_host;
		Pace.ignore(function(){
			that.connection = new WebSocket( protocol + '://' + host + slug );
			that.connection.onopen = function(){that.onConnect()};
			that.connection.onmessage = function(raw_message){that.onMessage(raw_message)};
			that.connection.onclose = function(){that.onClose()};
		});
	}

	closeConnection(){
		if(this.is_connected){
			this.connection.close();
		}
	}

	decodeMessagePayload(payload){
		var object = safe_json_decode(payload.data);
		if(isset(object.payload)){
			if(this.handleError(object.payload)){
				return object;
			}
		}
		return false;
	}

    onConnect(){
		this.connection.send(JSON.stringify({
			'action' : 'auth',
			'payload' : {
				'token' : this.auth_token
			}
		}));
	}
	

	connectedActions(){
	}

	handleAuthMessage(payload){
		if(isset(payload.success) && payload.success){
			this.is_connected = true;
			this.connectedActions();
			$.each(this.deferred, function(i, deferred_method){
				deferred_method();
			})
		}
		else{
			if(isset(payload.error)){
				error_message(payload.error);
			}
			this.connection.close();
		}
	}

	deferMethod(deferred_method){
		if(this.reconnection_attempts < this.reconnection_limit){
			this.deferred.push(deferred_method);
		}
		else{
			error_message('unable to send request');
		}
	}

	onMessage(message){
		var payload = this.decodeMessagePayload(message);
		if(payload){
			if(isset(payload.type) && isset(payload.payload)){
				var method_name = 'handle' + ucfirst(payload.type) + 'Message';
				if(isset(this[method_name]) && typeof(this[method_name]) == 'function'){
					this[method_name](payload.payload);
					return;
				}
			}
		}
	}
	

	shouldAlert(){
		var local = window.location.host.indexOf('local') !== -1; 
		if(local){
			return false;
		}
		var previous = localStorage.getItem('websocket_dev_alert_time');
		if(previous){
			var hours = moment().diff(moment.unix(previous), 'hours');
			if(hours < 24){
				return false;
			}
		}
		localStorage.setItem('websocket_dev_alert_time', moment().unix());
		return true;		
	}


	reportIssue(subject, body, callback){

		if(this.shouldAlert()){
			var data = {
				'subject' : subject,
				'body' : body
			};

			makeRequest('/alert/devs', data, function(response){
				callback(response);
			});
		}
	}


	startReconnectCycle(){
		if(this.reconnection_attempts < this.reconnection_limit){
			var that = this;
			setTimeout(function(){that.reAuth()}, 500);
			this.reconnection_attempts++;
		}
		else{
			//we have tried to reconnect 3 times 
			if(!this.is_connected){
				var url = window.location.href;
				//lets email the devs
				var subject = 'Cannot connect to web socket server : '+url;
				var body = 'is it running? try running php artisan websocket:init ' + this.type+' on the box';
				this.reportIssue(subject, body, function(response){
					if(!isset(response.success) || !response.success){
						//are you even connected to the internet?
						error_message('unable to connect to the crm, please check your internet connection');
					}
				});	
			}
		}

	}

	closedActions(){
	}

	onClose(){
		//our connection has closed -- let try to reconnect 3 times and if we still cant connect then lets send an email the to devs
		this.is_connected = false;
		this.startReconnectCycle();
		this.closedActions();
	}

	isConnected(){
		return this.is_connected;
	}

}

module.exports  = websocket_client;
