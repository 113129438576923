window.ultra_setup = function(callback) {
	if(isset(window.external.version))
	{
		// this lives here so we dont try to call it in the wrong place.
		var setUltraFields = function(external,callback){
			var ultra = {};
			for(var x=0;x<external.CallDetails_Count;x++){
				ultra[external.CallDetails_Key(x)] = external.CallDetails_Value_ByKey(external.CallDetails_Key(x));
			}
			callback(ultra);
		}

		setUltraFields(window.external,function(ultra){
            window.ultra = ultra;
			callback();
		});
	}
	else{
		callback();
	}
}


