$(document).ready(function() {
	$.fn.dataTable.ext.errMode = 'throw';
	
    const datatable = $('#compliance-table').DataTable({
        dom: 'flrtiBp',
		ajax: "/get/compliance",
        pageLength: 40,
        ordering: false,
		responsive: true,
		bAutoWidth:false,
		autoWidth: false,
        lengthMenu: [25, 40, 100],
		buttons: [
                'copyHtml5',
                'print',
                {
                    extend: 'collection',
                    text: 'Export',
                    buttons: [
                        'csvHtml5',
                        'pdfHtml5'   
                    ]
                }
        ],
    });
	
	if($('input[name="c-date-filter"]').length > 0){
		$(function() {
		//date picker for changing compliance report filter range (separate reload, may want to do more with this)
		$('input[name="c-date-filter"]').daterangepicker({

		"locale": {
			"format": 'DD/MM/YYYY',
			"separator": "-"
		},
		"linkedCalendars": false,
		"showDropdowns": true
		},
		function(start, end, label) {

			//console.log("Ok, changing date range...");

			const datatable = $('#compliance-table').DataTable({
				destroy: true,
				dom: 'flrtiBp',
				ajax: "/get/compliance?all=true&start="+start+"&end="+end,
				pageLength: 40,
				ordering: true,
				responsive: true,
				bAutoWidth:false,
				autoWidth: false,
				lengthMenu: [25, 40, 100],
				select: true,
				buttons: [
					'copyHtml5',
					'print',
					{
						extend: 'collection',
						text: 'Export',
						buttons: [
							'csvHtml5',
							'pdfHtml5'   
						]
					}
				],
			});
		});
		});
	}
	
    //if($("#lead_script").length) {
    // if($('div[name="compliance-energy"][id="lead_script"]').length) {
	// 	window.lead_script = new ScriptFlow($("#lead_script"),'compliance-energy');
    // } 
    
    
    $('#leads-table').on('click', '.delete-lead', function(e) {
        e.preventDefault();
        const id = $(this).data('id');
        const message = new MessageBox('deletelead', 'Delete lead id ' + id);
        message.setData({
            ajax: { url: '/leads/' + id },
            success: function(data) {
                datatable.ajax.reload();
            }
        });
        message.run();
    });
    
    //because yeah, lets just add 3 event listeners to this button... 
    // $("#edit-script").click(function(e) {
    //     e.preventDefault();
    //     window.lead_script.showEditBox();
    // });
    
        
    //delete compliance report
    $('#compliance-table').on('click','.delete-compliance',function(e) {
        e.preventDefault();
        console.log("Ok, delete button pressed, going to send...");
        var compliance_report_id = $(this).data('id');
     
        var message = 'Delete selected compliance report #'+compliance_report_id+'?';
        var message = new MessageBox('deletecompliance', message);
        message.setData({
            ajax: { 
                cache: false,
                url: '/compliance/delete',
                type: 'POST',
                dataType: 'json',
                data: {complianceReportID: compliance_report_id},
                success: function(response) {
                    datatable.ajax.reload();
                },
                error: function (error) {
                    //console.log(error);
                }
            }
        });
        message.run();
    });	
    
    
    //unlock compliance report (revert to status 8 for further editing)
    //and redirect us there
    $('#compliance-table').on('click','.unlock-compliance',function(e) {
        
        //console.log("Ok, unlock button pressed, going to send...");
        var compliance_report_id = $(this).data('id');
        var message = 'Unlock selected compliance report #'+compliance_report_id+'?';
        var message = new MessageBox('unlockcompliance', message);
        message.setData({
            ajax: { 
                cache: false,
                url: '/compliance/unlock',
                type: 'POST',
                dataType: 'json',
                data: {complianceReportID: compliance_report_id},
                success: function(response) {
                    //console.log(response);
                    window.location = '/order/'+response.order_id;
                },
                error: function (error) {
                    //console.log(error);
                }
            }
        });
        message.run();
    });	
      
    
});
