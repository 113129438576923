$(function() {

	$('.script').on('click', '.boiler-care-create-referral', function () {
		
		showLoader();

		var required_values = [
			{
				'key' : "boiler-serviceable",
				'error' : 'Please confirm the boiler age'
			},
			{
				'key' : "has-boiler-cover",
				'error' : 'Please confirm if the customer already has boiler cover'
			}
		];

		var data = sanitiseSerialedArray($('.boiler-care-follow-up-questions :input:visible').serializeArray());


		var bail = false;
		$.each(required_values, function(i, required){
			if(!isset(data[required['key']]) || !data[required['key']].length){
				error_message(required['error']);
				bail = true;
				return false;
			}
		});

		if(bail){
			hideLoader();
			return false;
		}
		
		var contact_id = $(this).data('contact-id');
		var to_send = {
			'contact_id' : contact_id,
			'data' : data
		};
		
		makeRequest('/boiler/lead/create', to_send, function(response){
			if(response.success){
				success_message('referred');
			}
			else{
				var error = 'unable to create referral';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});
	});

	$('.script').on('click','.complete-questionnaire',function(){
		var contact_id = $(this).data('contact_id');
		$(this).attr("disabled", true);
		makeRequest('/contact/add/event/'+contact_id, {
			'event_name' : 'customer_questionnaire_completed'
		}, function(response){
			if(isset(response.success) && response.success){
				window.location.href = '/';
			}
			// script.handleStep($(that).data('success-step'));
		});
	});

	$('.script').on('change', '.boiler-care-home-owner-section input', function () {

		if($(this).val() == 'Yes'){
			$('.not-home-owner-text').hide();
			$('.only-home-owner-section').show();
		}
		else{
			$('.not-home-owner-text').show();
			$('.only-home-owner-section').hide();
		}

	});
	
	$('.script').on('change', '[name="boiler-has-boiler-cover"]', function () {

		if($(this).val() == 'Yes'){
			$('.only-show-if-has-boiler-cover').show();
		}
		else{
			$('.only-show-if-has-boiler-cover').hide();
		}

	});



	// new boiler care follow up question handler

	$('.script').on('change', '.boiler-follow-up-question-section [name="has-boiler-cover"]', function () {

		var parent_container = $(this).closest('.boiler-follow-up-question-section');
		var contact_id = parent_container.data('contact_id');
		var has_boiler_cover = $(this).val();
		if(has_boiler_cover == 'Yes'){
			$('.boiler-current-provider-question-section').show();
			// $('.only-show-if-has-boiler-cover').show();
		}
		else{
			$('.boiler-current-provider-question-section').hide();
			$('.legal-services-section').show();
		}
		makeRequest('/contact/update/profile/'+contact_id, {
			'key' : 'customer:boiler_cover', 
			'value' : has_boiler_cover
		}, function(response){
			// script.handleStep($(that).data('success-step'));
		});
	});

	$('.script').on('change', '.boiler-follow-up-question-section [name="boiler-recently-serviced"]', function () {

		var parent_container = $(this).closest('.boiler-follow-up-question-section');
		var contact_id = parent_container.data('contact_id');
		var has_boiler_cover = $(this).val();
		$('.legal-services-section').show();
		makeRequest('/contact/update/profile/'+contact_id, {
			'key' : 'boiler:regularly_serviced', 
			'value' : has_boiler_cover
		}, function(response){
			// script.handleStep($(that).data('success-step'));
		});
	});

	$('.script').on('change','.broadband-section [name="has-broadband"]',function(){
		var has_broadband = $(this).val();
		if(has_broadband == 'Yes'){
			$('.customer-has-broadband-questions').show();
		}
		else{
			$('.customer-has-broadband-questions').hide();
		}
	});

	$('.script').on('change', '.broadband-section [name="current-broadband-provider"]', function () {

		var parent_container = $(this).closest('.broadband-section');
		var contact_id = parent_container.data('contact_id');
		var current_broadband_provider = $('.customer-has-broadband-questions [name="current-broadband-provider"]').val();
		console.log(current_broadband_provider);
		makeRequest('/contact/update/profile/'+contact_id, {
			'key' : 'customer:broadband_provider', 
			'value' : current_broadband_provider
		}, function(response){
			console.log(response);
			// script.handleStep($(that).data('success-step'));
		});
	});

	$('.script').on('blur', '.broadband-section [name="broadband-current-spend"]', function () {
		
		var parent_container = $(this).closest('.broadband-section');
		var contact_id = parent_container.data('contact_id');
		var current_broadband_spend = $('.broadband-section [name="broadband-current-spend"]').val();
		var input = $('.broadband-section [name="broadband-current-spend"]');
		var regex = new RegExp(input.attr('pattern'), 'g');
		setTimeout(function(){
			if(regex.test(current_broadband_spend)){
				makeRequest('/contact/update/profile/'+contact_id, {
					'key' : 'customer:broadband_current_spend', 
					'value' : current_broadband_spend
				}, function(response){
					// script.handleStep($(that).data('success-step'));
				});
			}
			else{
				error_message('Please enter a valid monthly spend');
			}
		}, 1);
		
	});

	$('.script').on('change','.home-owner-fact-find-question [name="customer-homeowner-type"]',function(){

		var quote_id = $('#lead_script').data("quoteid");

		var selected_value = $('.home-owner-fact-find-question input[type="radio"]:checked').val();

		var api = new apiInterface();
		api.updateHomeowner(quote_id,selected_value,false,function(response) {
			//console.log("Should be updated");
		});

		if(selected_value != 'No'){
			// $('.boiler-care-follow-up-questions').show();
			$('.customer-has-poa-section').hide();
			$('.legal-services-section').show();
			$('.home-insurance-section').hide();
			// $(".insurance-follow-up-label").html('<b>Do you have buildings and contents insurance?</b>');
			
		}
		else{
			$('.customer-has-poa-section').hide();
			$('.legal-services-section').show();
			// $('.boiler-care-follow-up-questions').hide();
			// $('.legal-services-section').show();
			// $(".insurance-follow-up-label").html('<b>Do you have home contents insurance?</b>');
			
		}
	});

	$('.script').on('change', '.customer-poa-review-section [name="customer-wants-poa-review-appointment"]', function () {
		
		var customer_wants_poa_review = $('.customer-poa-review-section input[type="radio"]:checked').val();
		if(customer_wants_poa_review == 'Yes'){
			$('.customer-wants-poa-appointment-advice-text').show();
		}
		else{
			$('.customer-wants-poa-appointment-advice-text').hide();
		}
	});
	

	$('.script').on('change', '.customer-has-poa-question-section [name="customer-has-poa"]', function () {
		var parent_container = $(this).closest('.customer-has-poa-question-section');
		var contact_id = parent_container.data('contact_id');
		var customer_has_poa = $('.customer-has-poa-question-section input[type="radio"]:checked').val();
		if(customer_has_poa == 'Yes'){
			// $('.customer-will-circumstances-changed-question').show();
			$('.customer-does-have-poa-section').show();
			$('.customer-does-not-have-poa-section').hide();
			$('.customer-does-not-have-poa-advice-text').hide();
			// $('.home-insurance-section').hide();
		}
		else{
			$('.customer-does-not-have-poa-section').show();
			$('.customer-does-not-have-poa-advice-text').show();
			$('.customer-does-have-poa-section').hide();
			// $('.customer-will-circumstances-changed-question').hide();
			// $('.home-insurance-section').show();
		}
		makeRequest('/contact/update/profile/'+contact_id, {
				'key' : 'customer:has_existing_poa', 
				'value' : customer_has_poa
			}, function(response){
				// script.handleStep($(that).data('success-step'));
			});
	});

	$('.script').on('change', '.customer-has-poa-question-section [name="customer-confirming-poa"]', function () {
		var parent_container = $(this).closest('.customer-has-poa-question-section');
		var contact_id = parent_container.data('contact_id');
		var customer_has_poa = $('.customer-has-poa-question-section input[type="radio"]:checked').val();
		if(customer_has_poa == 'Yes'){
			// $('.customer-will-circumstances-changed-question').show();
			$('.customer-poa-appointed-relative-section').show();
			$('.customer-does-not-have-poa-advice-text').hide();
			// $('.home-insurance-section').hide();
		}
		else{
			$('.customer-does-not-have-poa-advice-text').show();
			$('.customer-poa-appointed-relative-section').hide();
			// $('.customer-will-circumstances-changed-question').hide();
			// $('.home-insurance-section').show();
		}
	});

	$('.script').on('change', '.customer-has-poa-question-section [name="customer-poa-appointed-relative"]', function () {
		$('.customer-poa-type-section').show();
	});
	
	$('.script').on('change', '.customer-has-poa-question-section [name="customer-poa-type"]', function () {
		$('.customer-poa-review-section').show();
	});

	$('.script').on('change', '.customer-poa-review-appointment-section [name="customer-wants-poa-review-appointment"]', function () {
		var parent_container = $(this).closest('.customer-has-poa-question-section');
		var contact_id = parent_container.data('contact_id');
		var customer_wants_poa_review = $('.customer-poa-review-appointment-section input[type="radio"]:checked').val();
		if(customer_wants_poa_review == 'Yes'){
			$('.customer-wants-poa-review-appointment-advice-text').show();
		}
		else{
			$('.customer-wants-poa-review-appointment-advice-text').hide();
			$('.questionnaire-complete').show();
		}
	});

	

	$('.script').on('change', '.customer-poa-appointment-section [name="customer-wants-poa-appointment"]', function () {
		var parent_container = $(this).closest('.customer-poa-appointment-section');
		var contact_id = parent_container.data('contact_id');
		var customer_wants_poa_appointment = $('.customer-poa-appointment-section input[type="radio"]:checked').val();
		if(customer_wants_poa_appointment == 'Yes'){
			$('.customer-wants-poa-appointment-advice-text').show();
			$('.questionnaire-complete').show();

		}
		else{
			$('.customer-wants-poa-appointment-advice-text').hide();
		}
	});
	
	



	$('.script').on('change', '.boiler-follow-up-question-section [name="current-boiler-cover-provider"]', function () {

		var parent_container = $(this).closest('.boiler-follow-up-question-section');
		var contact_id = parent_container.data('contact_id');
		var current_boiler_cover_provider = $('.boiler-follow-up-question-section [name="current-boiler-cover-provider"]').val();
		if(current_boiler_cover_provider != ''){
			$('.boiler-current-payment-amount-section').show();
			$('.boiler-recently-serviced-section').show();
		}
		else{
			$('.boiler-current-payment-amount-section').hide();
			$('.boiler-recently-serviced-section').hide();
		}
		makeRequest('/contact/update/profile/'+contact_id, {
			'key' : 'boiler:cover_provider', 
			'value' : current_boiler_cover_provider
		}, function(response){
			console.log(response);
			// script.handleStep($(that).data('success-step'));
		});
	});

	$('.script').on('blur', '.boiler-follow-up-question-section [name="boiler-cover-current-spend"]', function () {
		
		var parent_container = $(this).closest('.boiler-follow-up-question-section');
		var contact_id = parent_container.data('contact_id');
		var current_boiler_cover_spend = $('.boiler-follow-up-question-section [name="boiler-cover-current-spend"]').val();
		var input = $('.boiler-follow-up-question-section [name="boiler-cover-current-spend"]');
		var regex = new RegExp(input.attr('pattern'), 'g');
		setTimeout(function(){
			if(regex.test(current_boiler_cover_spend)){
				$('.boiler-recently-serviced-section').show();
				makeRequest('/contact/update/profile/'+contact_id, {
					'key' : 'current_boiler_cover_spend', 
					'value' : current_boiler_cover_spend
				}, function(response){
					// script.handleStep($(that).data('success-step'));
				});
			}
			else{
				error_message('Please enter a valid monthly spend');
			}
		}, 1);
		
	});

	$('.script').on('change','.legal-services-section [name="has-existing-will"]',function(){
		var parent_container = $(this).closest('.legal-services-section');
		var contact_id = parent_container.data('contact_id');
		var has_will = $(this).val();
		if(has_will == 'Yes'){
			$('.customer-will-writen-years-ago-question').show();
			$('.customer-does-not-have-a-will-section').hide();
			$('.home-insurance-section').hide();
		}
		else{
			$('.has-will-question-group').hide();
			$('.customer-does-not-have-a-will-section').show();
			$('.customers-will-needs-updating-section').hide();
			$('.customer-will-circumstances-changed-question').hide();
			$('.home-insurance-section').show();
		}
		makeRequest('/contact/update/profile/'+contact_id, {
				'key' : 'customer:has_existing_will', 
				'value' : has_will
			}, function(response){
				// script.handleStep($(that).data('success-step'));
			});
	});

	$('.script').on('change','.legal-services-section [name="customer-will-writen-years-ago"]',function(){
		var parent_container = $(this).closest('.legal-services-section');
		var contact_id = parent_container.data('contact_id');
		var current_home_insurance_cover_spend = $('.legal-services-section [name="customer-will-writen-years-ago"]').val();
		var input = $('.legal-services-section [name="customer-will-writen-years-ago"]');
		var regex = new RegExp(input.attr('pattern'), 'g');
		setTimeout(function(){
			if(regex.test(current_home_insurance_cover_spend)){
				$('.customer-will-last-reviewed-years-ago-question').show();
			}
			else{
				error_message('Please enter a valid monthly spend');
			}
		}, 1);
	});
	$('.script').on('change','.legal-services-section [name="customer-will-last-reviewed-years-ago"]',function(){
		var parent_container = $(this).closest('.legal-services-section');
		var contact_id = parent_container.data('contact_id');
		var current_home_insurance_cover_spend = $('.legal-services-section [name="customer-will-last-reviewed-years-ago"]').val();
		var input = $('.legal-services-section [name="customer-will-last-reviewed-years-ago"]');
		var regex = new RegExp(input.attr('pattern'), 'g');
		setTimeout(function(){
			if(regex.test(current_home_insurance_cover_spend)){
				$('.customer-will-circumstances-changed-question').show();
			}
			else{
				error_message('Please enter a valid monthly spend');
			}
		}, 1);
	});
	

	$('.script').on('change','.legal-services-section [name="will-circumstances-changed"]',function(){
		var parent_container = $(this).closest('.legal-services-section');
		var contact_id = parent_container.data('contact_id');
		var will_circumstances_changed = $(this).val();
		if(will_circumstances_changed == 'Yes'){
			$('.customers-will-needs-updating-section').show();
		}
		else{
			$('.customer-will-circumstances-changed-house-move-question').show();
			$('.protecting-your-assets-section').show();
			$('.customer-does-not-have-a-will-section').hide();
			$('.customers-will-needs-updating-section').hide();
		}
		makeRequest('/contact/update/profile/'+contact_id, {
				'key' : 'customer:will_circumstances_changed', 
				'value' : will_circumstances_changed
			}, function(response){
				// script.handleStep($(that).data('success-step'));
			});
	});

	$('.script').on('change','.legal-services-section [name="customer-will-circumstances-changed-house-move"]',function(){
		var parent_container = $(this).closest('.legal-services-section');
		var contact_id = parent_container.data('contact_id');
		var will_circumstances_changed = $(this).val();
		if(will_circumstances_changed == 'Yes'){
			$('.customers-will-needs-updating-section').show();
			$('.customer-has-poa-section').hide();
		}
		else{
			$('.customer-has-poa-section').show();
			$('.customer-does-not-have-a-will-section').hide();
			$('.customers-will-needs-updating-section').hide();
		}
		makeRequest('/contact/update/profile/'+contact_id, {
				'key' : 'customer:will_circumstances_changed', 
				'value' : will_circumstances_changed
			}, function(response){
				// script.handleStep($(that).data('success-step'));
			});
	});
	

	$('.script').on('change','.customer-does-not-have-a-will-section [name="customer-wants-will-appointment"]',function(){
		var parent_container = $(this).closest('.legal-services-section');
		var contact_id = parent_container.data('contact_id');
		var customer_wants_will_appointment = $(this).val();
		
		if(customer_wants_will_appointment == 'Yes'){
			// show referal section
			$('.customer-wants-will-appointment').show();
			$('.questionnaire-complete').show();
		}
		else{
			$('.customer-wants-will-appointment').hide();
			$('.customer-has-poa-section').show();
			$('.questionnaire-complete').hide();
		}
	});

	$('.script').on('change','.customers-will-needs-updating-section [name="customer-wants-will-update-appointment"]',function(){
		var parent_container = $(this).closest('.legal-services-section');
		var contact_id = parent_container.data('contact_id');
		var customer_wants_will_appointment = $(this).val();
		
		if(customer_wants_will_appointment == 'Yes'){
			// show referal section
			$('.customer-wants-will-update-appointment').show();
			$('.questionnaire-complete').show();
		}
		else{
			$('.customer-wants-will-update-appointment').hide();
			$('.questionnaire-complete').hide();
			$('.customer-has-poa-section').show();
		}
	});

	$('.script').on('change','.protecting-your-assets-section [name="customer-has-considered-protecting-assets"]',function(){
		var parent_container = $(this).closest('.care-home-fees-section');
		var contact_id = parent_container.data('contact_id');
		var customer_has_considered_protecting_assets = $(this).val();
		
		// if(customer_has_considered_protecting_assets == 'Yes'){
		// 	// show referal section
		// 	$('.customer-has-considered-protecting-assets-advice-section').show();
		// 	$('.customer-has-not-considered-protecting-assets-advice-section').hide();
		// 	$('.questionnaire-complete').show();
		// }
		// else{
		// 	$('.customer-has-considered-protecting-assets-advice-section').hide();
		// 	$('.customer-has-not-considered-protecting-assets-advice-section').show();
		// 	$('.questionnaire-complete').show();
		// }
	});

	$('.script').on('change','.customer-has-not-considered-protecting-assets-advice-section [name="customer-wants-estate-planning-appointment"]',function(){
		var customer_has_considered_protecting_assets = $(this).val();
		// if(customer_has_considered_protecting_assets == 'Yes'){
		// 	// show referal section
		// 	$('.customer-wants-will-update-appointment-text').show();
		// 	$('.questionnaire-complete').show();
		// }
		// else{
		// 	$('.questionnaire-complete').show();
		// }
	});
	$('.script').on('change','.customer-has-considered-protecting-assets-advice-section [name="customer-wants-estate-planning-appointment"]',function(){
		var customer_has_considered_protecting_assets = $(this).val();
		// if(customer_has_considered_protecting_assets == 'Yes'){
		// 	// show referal section
		// 	$('.customer-wants-will-update-appointment-text').show();
		// 	$('.questionnaire-complete').show();
		// }
		// else{
		// 	$('.questionnaire-complete').show();
		// }
	});
	

	$('.script').on('change','.home-insurance-section [name="has-home-insurance"]',function(){
		var parent_container = $(this).closest('.home-insurance-section');
		var contact_id = parent_container.data('contact_id');
		var has_insurance = $(this).val();
		if(has_insurance == 'Yes'){
			$('.customer-has-home-insurance-questions').show();
			$('.questionnaire-complete').hide();
			$('.current-home-insurance-provider-section').show();
			if($('.current-home-insurance-provider-section').is(':visible') &&  $('.customer-has-home-insurance-questions [name="current-home-insurance-provider"]').val() != ''){
				if($('.current-home-insurance-spend-section').is(':visible') &&  $('.customer-has-home-insurance-questions [name="home-insurance-current-spend"]').val() != ''){
					$('.home-insurance-renewal-month-section').show();
				}
			}
			if($('.home-insurance-renewal-month-section').is(':visible') && $('.customer-has-home-insurance-questions [name="home-insurance-renewal-month"]').val() != ''){
				$('.questionnaire-complete').show();
			}
		}
		else{
			$('.customer-has-home-insurance-questions').hide();
			$('.current-home-insurance-provider-section').hide();
			$('.current-home-insurance-spend-section').hide();
			$('home-insurance-renewal-month-section').hide();
			$('.questionnaire-complete').show();
		}
		makeRequest('/contact/update/profile/'+contact_id, {
				'key' : 'insurance:has_cover', 
				'value' : has_insurance
			}, function(response){
				// script.handleStep($(that).data('success-step'));
			});
	});

	$('.script').on('change', '.customer-has-home-insurance-questions [name="current-home-insurance-provider"]', function () {

		var parent_container = $(this).closest('.home-insurance-section');
		var contact_id = parent_container.data('contact_id');
		var current_boiler_cover_provider = $('.customer-has-home-insurance-questions [name="current-home-insurance-provider"]').val();
		$('.current-home-insurance-spend-section').show();
		$('.home-insurance-renewal-month-section').show();
		makeRequest('/contact/update/profile/'+contact_id, {
			'key' : 'insurance:supplier', 
			'value' : current_boiler_cover_provider
		}, function(response){
			console.log(response);
			// script.handleStep($(that).data('success-step'));
		});
	});

	$('.script').on('blur', '.customer-has-home-insurance-questions [name="home-insurance-current-spend"]', function () {
		
		var parent_container = $(this).closest('.home-insurance-section');
		var contact_id = parent_container.data('contact_id');
		var current_home_insurance_cover_spend = $('.customer-has-home-insurance-questions [name="home-insurance-current-spend"]').val();
		var input = $('.customer-has-home-insurance-questions [name="home-insurance-current-spend"]');
		var regex = new RegExp(input.attr('pattern'), 'g');
		setTimeout(function(){
			if(regex.test(current_home_insurance_cover_spend)){
				$('.home-insurance-renewal-month-section').show();
				makeRequest('/contact/update/profile/'+contact_id, {
					'key' : 'insurance:monthly_spend', 
					'value' : current_home_insurance_cover_spend
				}, function(response){
					// script.handleStep($(that).data('success-step'));
				});
			}
			else{
				error_message('Please enter a valid monthly spend');
			}
		}, 1);
		
	});

	$('.script').on('change', '.customer-has-home-insurance-questions [name="home-insurance-renewal-month"]', function () {

		var parent_container = $(this).closest('.home-insurance-section');
		var contact_id = parent_container.data('contact_id');
		var insurance_renewal_month = $('.customer-has-home-insurance-questions [name="home-insurance-renewal-month"]').val();
		$('.questionnaire-complete').show();
		makeRequest('/contact/update/profile/'+contact_id, {
			'key' : 'insurance:renewal_month', 
			'value' : insurance_renewal_month
		}, function(response){
			console.log(response);
			// script.handleStep($(that).data('success-step'));
		});
	});
	

	

	$('.script').on('change', '.benefits-received-question input', function () {

		if($(this).val() == 'Yes'){
			$('.benefits-received-section').show();
		}
		else{
			$('.benefits-received-section').hide();
		}

	});
	
	$('.script').on('change, keyup', '.boiler-fact-find-questions .boiler-age', function () {

		if($(this).val() > 20){
			$('.boiler-not-servicable-text').show();
			$('.boiler-servicable-text').hide();
		}
		else{
			$('.boiler-servicable-text').show();
			$('.boiler-not-servicable-text').hide();
		}

	});
	
	$('.script').on('change', '.boiler-has-fault', function () {

		if($(this).val() == 'Yes'){
			$('.boiler-has-fault-section').show();
		}
		else{
			$('.boiler-has-fault-section').hide();
		}

	});

	$('.script').on('change', '.boiler-previous-claim', function () {

		if($(this).val() == 'Yes'){
			$('.boiler-previous-claim-description').show();
		}
		else{
			$('.boiler-previous-claim-description').hide();
		}

	});
	
	$('.script').on('click', '.jump-to-boiler-finance-quote', function () {

		error_message('This feature is not yet available');

	});
	
	$('.script').on('click', '.submit-boiler-care-fact-find', function () {

		// var home_owner = $('.boiler-care-home-owner-section input').serializeArray();
		var merged_data = $('.only-home-owner-section :input:visible').serializeArray();
		$.each($('.only-home-owner-section :input:visible:checkbox:not(:checked)'), function (i, item){ 
			merged_data.push({ name: item.name, value: 'No' }); 
		});
		// var merged_data = $.merge(home_owner,serial_data);
		var script_element = $(this).closest('.script');
		// if($('#homeowner-no:checked').length){
		// 	window[$(script_element).data('script-name') + '_script'].handleStep($(this).data('success-step'));
		// }

		if(!merged_data.length){
			error_message('Please fill in the required fields');
			return false;
		}

		var data = sanitiseSerialedArray(merged_data);

		
		var required = {
			'boiler-age' : {
				'error' : 'Please confirm the boiler age'
			},
			'boiler-make': {
				'error' : 'Please confirm the boiler make'
			},
			'boiler-has-fault' :{
				'error' : 'Please confirm if the boiler has a fault'
			},
			'email' : {
				'error' : 'Please provide a valid email',
				'regex' : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				'optional' : true
			}
		};

		if(isset(data["boiler-has-boiler-cover"]) && data["boiler-has-boiler-cover"] == 'Yes'){
			required["current-boiler-cover-spend"] = {
				'error' : 'Please confirm the current boiler cover spend'
			};
		}

		var bail = false;
		$.each(required, function(key, validation){
			if(!isset(data[key]) || !data[key].length){
				if(!isset(validation.optional) || !validation.optional){
					error_message(validation['error']);
					bail = true;
					return false;
				}
			}
			else if(isset(validation['regex']) && !validation['regex'].test(data[key])){
				error_message(validation['error']);
				bail = true;
				return false;
			}
		});

		if(data['boiler-has-fault'] == 'Yes'){
			if(!isset(data['boiler-fault-description']) || !data['boiler-fault-description'].length){
				error_message('Please provide a description of the boiler fault');
				bail = true;
				return false;
			}
		}
		if(data['boiler-previous-claim'] == 'Yes'){
			if(!isset(data['boiler-claim-description']) || !data['boiler-claim-description'].length){
				error_message('Please provide a description of the boiler claim');
				bail = true;
				return false;
			}
		}

		if(bail){
			return false;
		}

		showLoader();
		
		var quote_id =  $(script_element).data('quoteid');
		var to_send = {
			'quote_id' : quote_id,
			'data' : data
		};

		var that = this;
		makeRequest('/boiler/fact-find', to_send, function(response){
			if(response.success){
				window[$(script_element).data('script-name') + '_script'].handleStep($(that).data('success-step'));
				refeshContactDetails();
			}
			else{
				var error = 'unable to submit details';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});


	});

	$('.script').on('click', '.submit-boiler-care-pitch', function () {

		var script_element = $(this).closest('.script');
		var quote_id =  $(script_element).data('quoteid');
		var product_id = $(this).data('product-id');
		var data = {
			'quote_id' : quote_id,
			'product_id' : product_id
		};
		showLoader();
		var that = this;
		makeRequest('/boiler/add_product', data, function(response){
			if(response.success){
				window[$(script_element).data('script-name') + '_script'].handleStep($(that).data('success-step'));
			}
			else{
				var error = 'unable to add product';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();

		});

	});
	
	$('.script').on('click', '.show-more-features', function () {

		var list = $(this).closest('.feature-list');
		$(list).find('li').show();
		$(this).closest('li').hide();

	});
	
	$('.script').on('change', '.boiler-care-sort-switch', function () {

		
		var product_containers = $('.sort-container');
		var that = $(this);
		$.each(product_containers, function(index, container){

			var products = $(container).find('.tariff-box');
			var key_to_sort = 'price';
			if(!$(that).is(':checked')){
				key_to_sort = 'excess';
			}
			$(products).sort(function(a, b){
				return $(a).data(key_to_sort) - $(b).data(key_to_sort);
			}).appendTo(container);
		});
	});
	
	$('.script').on('change', '.boiler-care-landlords-switch', function () {

		if($(this).is(':checked')){
			$('.boiler-care-products').hide();
			$('.landlord-bc-products').show();
		}
		else{
			$('.landlord-bc-products').hide();
			$('.boiler-care-products').show();
		}
	
	});
	
	$('.script').on('change', '.boiler-care-inactive-plans-switch', function () {

		if($(this).is(':checked')){
			$('.sort-container').find('.tariff-box[data-active="0"]').hide();
		}
		else{
			$('.sort-container').find('.tariff-box[data-active="0"]').show();
		}
	
	});

	$('.script').on('click', '.boiler-care-products-section .tarrif-header', function () {
		// return false;
		showLoader();
		var product_id = $(this).closest('.tariff-box').data('product-id');
		var script_element = $(this).closest('.script');
		var quote_id =  $(script_element).data('quoteid');
		var data = {
			'product_id' : product_id,
			'quote_id' : quote_id
		};
		var header = $(this);
		makeRequest('/boiler/product/script/text', data, function(response){
			if(isset(response.success) && response.success){
				$('.original-script-text').hide();
				$('.replaceable-script-text').html(response.html);
				$('.replaceable-script-text').show();
				$('.tarrif-header').removeClass('selected');
				$('.tariff-box').removeClass('selected');
				$('.tariff-box').hide();
				$(header).addClass('selected');
				$(header).closest('.tariff-box').show();
				$(header).closest('.tariff-box').addClass('selected');
				$('#lead_script')[0].scrollIntoView({'behavior' : 'smooth'});
				$('.bc-reset-filter-toggle').show();
				var data = {
					'quote_id' : quote_id,
					'product_id' : product_id
				};
				var that = this;
				makeRequest('/boiler/add_product', data, function(response){
					if(response.success){
						success_message('Quote Emailed to Customer');
					}
				});
			}
			else{
				var error = 'unable to load product details';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});
	});

	$('.script').on('click', '.submit-boiler-care-aftersale-additional-questions', function () {
		
		var script_element = $(this).closest('.script');
		
		var raw_data = $(script_element).find('.bc-additional-questions :input:visible').serializeArray();
		$.each($(script_element).find('.bc-additional-questions :input:visible:checkbox:not(:checked)'), function (i, item){ 
			raw_data.push({ name: item.name, value: 'No' }); 
		});
		
		if(!raw_data.length){
			error_message('Please fill in the required fields');
			return false;
		}

		var data = sanitiseSerialedArray(raw_data);


		var bail = false;
		$.each(data, function(index, value){
			if(!value){	
				error_message('please provide a value for ' + index);
				bail = true;
			}
		});

		if(bail){
			return false;
		}

		showLoader();
		
		var quote_id =  $(script_element).data('quoteid');
		var to_send = {
			'quote_id' : quote_id,
			'data' : data
		};

		var that = this;
		makeRequest('/boiler/additional-questions', to_send, function(response){
			if(response.success){
				window[$(script_element).data('script-name') + '_script'].handleStep($(that).data('success-step'));
			}
			else{
				var error = 'unable to submit details';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});


	});

	$('.script').on('click', '.bc-reset-filter-toggle',function(){
		$('.original-script-text').show();
		$('.replaceable-script-text').hide();
		$('.tariff-box').show();
		$('.bc-reset-filter-toggle').hide();
	});

	$('.script').on('change', '#boiler-care-correspondence-address .boiler-care-correspondence-switch', function () {
		var checked = $(this).is(':checked');
		if(checked){
			$('#boiler-care-correspondence-address .boiler-care-capture-correspondence-address').hide();
		}
		else {
			$('#boiler-care-correspondence-address .boiler-care-capture-correspondence-address').show();
		}
	});

	$('.script').on('click', '#boiler-care-correspondence-address .no-shown-correspondence-address', function () {
		$('#boiler-care-correspondence-address .manual-correspondence-address-section').show();
		$('#boiler-care-correspondence-address .correspondence-address-select-section').hide();
	});

	$('.script').on('click', '#boiler-care-correspondence-address .show-correspondence-address-select', function () {
		$('#boiler-care-correspondence-address .manual-correspondence-address-section').hide();
		$('#boiler-care-correspondence-address .correspondence-address-select-section').show();
	});

	$('.script').on('click', '#confirm-correspondence-address-continue', function () {
		var that = this;
		var checked = $('.boiler-care-correspondence-switch').is(':checked');
		var script_element = $(this).closest('.script');
		var quote_id =  $(script_element).data('quoteid');
		var data = {
			'correspondence_address_same':checked,
			'quote_id':quote_id
		};
		if(!data.checked){
			if($('#gbg_section').is(':visible')){
				if(!$('#gbg_addresses').val() || $('#gbg_addresses').val() == 'PLEASE SELECT AN ADDRESS HERE'){
					error_message('you MUST select an address here');
					return false;
				} 
				var selected_option = $('#gbg_addresses').find('option:selected');
				data.type = $(selected_option).data('type');
				data.external_address_id = $('#gbg_addresses').val();
				data.address_text = $(selected_option).text();
				data.postcode = $(selected_option).data('postcode');
				if($(selected_option).data('full-address')){
					data.full_address = $(selected_option).data('full-address');
				}
			}
			else{
				// this is a manual address
				var container = $('.manual-correspondence-address-section');
				var input_data = container.find(':input').serializeArray();
				var address_data = sanitiseSerialedArray(input_data);
				$.extend(data,address_data);
				data.manual_address_id = true;
				data.type = 'manual_correspondence';
				console.log(data);
			}
		}
		showLoader();
		makeRequest('/address/set_correspondence_address', data, function(response){
			if(response.success) {
				window[$(script_element).data('script-name') + '_script'].handleStep($(that).data('success-step'));
			}
			else{
				hideLoader();
				error_message(response.error);
			}
		});
		// send to server
	});
	

	var sendEmotionalResponse = function(data){
		showLoader();
		makeRequest('/boiler/emotional-response', data, function(response){
			if(response.success){
				if(isset(response.redirect_url)){
					window.location = response.redirect_url;
				}
				else{
					success_message('Thank you');
					hideLoader();
				}
			}
			else{
				hideLoader();
				error_message(response.error);
			}
		});
	}

	$('.script').on('click', '.emotional-button', function () {
		var type = $(this).data('type');
		var script_element = $(this).closest('.script');
		var quote_id =  $(script_element).data('quoteid');
		var data = {
			'type':type,
			'quote_id':quote_id
		};

		if(type == 'sad'){
			customPrompt('Please provide notes about the cancellation', 'notes', function(value){
				if(value){
					data.notes = value;
					sendEmotionalResponse(data);
				}
				else{
					error_message('you must provide some notes');
				}
			});
		}
		else{
			if(type == 'money'){
				$(this).find('i').css('animation', 'bang 1s ease')
			}
			sendEmotionalResponse(data);
		}
		
	});

});



