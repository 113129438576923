var getPolicies = function (data, callback) {
    makeRequest('/consent-policies/get-policies', data, function (response) {
        if(isset(response.success) && response.success && isset(response.html)) {
            callback(response.html);
        }
        else {
            if(isset(response.error)) {
                error_message(response.error);
            }
            else {
                error_message('Issue Loading Policy');
            }
        }
    });
};

var getPolicy = function (data, callback) {
    makeRequest('/consent-policies/get-policy', data, function (response) {
        if(isset(response.success) && response.success && isset(response.html)) {
            callback(response.html);
        }
        else {
            if(isset(response.error)) {
                error_message(response.error);
            }
            else {
                error_message('Issue Loading Policy');
            }
        }
    });
};

var newPolicy = function (data, callback) {
    makeRequest('/consent-policies/new-policy', data, function (response) {
        if(isset(response.success) && response.success && isset(response.html)) {
            callback(response.html);
        }
        else {
            if(isset(response.error)) {
                error_message(response.error);
            }
            else {
                error_message('Issue Loading Policy');
            }
        }
    });
};

var validateCreation = function (data, callback) {
    makeRequest('/consent-policies/validate-creation', data, function (response) {
        if(isset(response.success) && response.success && isset(response.html)) {
            callback(response);
        }
        else {
            if(isset(response.error)) {
                error_message(response.error);
            }
            else {
                error_message('Issue Loading Policy');
            }
        }
    });
}

var storeCreation = function (data, callback) {
    makeRequest('/consent-policies/store-policy', data, function (response) {
        if(isset(response.success) && response.success) {
            callback(response.html);
        }
        else {
            if(isset(response.errors)) {
                error_message(response.errors);
            }
            else {
                error_message('Issue Loading Policy');
            }
        }
    });
}

$(document).ready(function() {

    $('body').on('click', '.preview-consent-policy', function () {
        var policy = $('.consent-policy-html-body').val();
        var container = ('.consent-policy-preview-container');

        $(container).html(policy).show();
    });

    $('#policies-list').on('click', '.view-policy', function () {
        var container = $('#policy-preview');
        var that = $(this).closest('.consent-policy-single');
        var data = {
            'policy_id': $(this).closest('.consent-policy-single').data('policy-id')
        }

        $('#policies-list').addClass('col-md-3').removeClass('col-md-4');
        $(container).html('Loading Policy <i class="fa-regular fa-circle-notch fa-spin" aria-hidden="true"></i>');
        $('#policies-list').find('.consent-policy-single').removeClass('active');
        $(that).addClass('active');

        getPolicy(data, function (html) {
            $(container).html(html);
            $(container).addClass('col-md-7').removeClass('col-md-6');
        });
    });

    $('#policies-list').on('click', '.edit-policy', function () {
        var container = $('#policy-preview');
        var that = $(this).closest('.consent-policy-single');
        var data = {
            'policy_id': $(this).closest('.consent-policy-single').data('policy-id'),
            'edit': true
        };

        $('#policies-list').addClass('col-md-3').removeClass('col-md-4');
        $(container).html('Loading Policy <i class="fa-regular fa-circle-notch fa-spin" aria-hidden="true"></i>');
        $('#policies-list').find('.consent-policy-single').removeClass('active');
        $(that).addClass('active');

        getPolicy(data, function (html) {
            $(container).html(html);
            $(container).addClass('col-md-7').removeClass('col-md-6');
            CKEDITOR.replace('policy-edit-textarea');
        });
    });

    $('#types-list').on('click', '.edit-policy-type', function () {
        var container = $('#policies-list');
        var that = $(this).closest('.consent-type-single');
        var data = {
            'type_id': $(this).closest('.consent-type-single').data('type-id')
        };

        $('#policy-preview').html('');
        $('#types-list').addClass('col-md-2').removeClass('col-md-4');
        $('#policies-list').addClass('col-md-4').removeClass('col-md-3');

        $(container).html('Loading Policies <i class="fa-regular fa-circle-notch fa-spin" aria-hidden="true"></i>');
        $('#types-list').find('.consent-type-single').removeClass('active');
        $(that).addClass('active');

        getPolicies(data, function (html) {
            $(container).html(html);
        });
    });

    $('#policies-list').on('click', '.new-consent-policy', function (e) {
        e.preventDefault();

        var container = $('#policy-preview');
        var that = $(this).closest('.consent-policy-single');
        var data = {
            'type_id': $(this).data('type-id'),
            'edit': true
        };

        $('#policies-list').addClass('col-md-3').removeClass('col-md-4');
        $(container).html('Loading Policy <i class="fa-regular fa-circle-notch fa-spin" aria-hidden="true"></i>');
        $('#policies-list').find('.consent-policy-single').removeClass('active');
        $(that).addClass('active');

        newPolicy(data, function (html) {
            $(container).html(html);
            $(container).addClass('col-md-7').removeClass('col-md-6');
            CKEDITOR.config.allowedContent = true;
            CKEDITOR.replace('policy-edit-textarea');
        });
    });

    $('#policy-preview').on('click', '.save-consent-policy', function (e) {
        e.preventDefault();

        if(isset(CKEDITOR.instances['policy-edit-textarea'])) {
            if($('#policy_changes').val().length === 0) {
                error_message('Please tell us what you are changing');
                return false;
            }
            var policy_text = CKEDITOR.instances['policy-edit-textarea'].getData();

            var data = {
                'policy_id': $('.policy-view').data('policy-id'),
                'content': policy_text
            }

            validateCreation(data, function (response) {
                createModal('consent_policy_comparison_modal', response.html);
                $('#consent_policy_comparison_modal').data('diff-html', response.diff_html);
            });
        }
    });

    $('body').on('click', '.save-confirm-policy', function (e) {
        e.preventDefault();
        if(isset(CKEDITOR.instances['policy-edit-textarea']) && $('#consent_policy_comparison_modal').length) {
            if($('#policy_changes').val().length === 0) {
                error_message('Please tell us what you are changing');
                return false;
            }

            var policy_text = CKEDITOR.instances['policy-edit-textarea'].getData();

			var action_data = $('.policy-action-toggle-list :input').serializeArray().map(action => action.name);

            var data = {
                'policy_id': $('.policy-view').data('policy-id'),
                'content': policy_text,
                'changes': $('#policy_changes').val(),
				'diff_html': $('#consent_policy_comparison_modal').data('diff-html'),
				'actions_to_enable' : action_data
            }

            storeCreation(data, function (response) {
               success_message();
               $('#policy-preview').html('');

                var container = $('#policies-list');
                var data_set = {
                    'type_id': $('#types-list').find('.consent-type-single.active').data('type-id')
                };

                $(container).html('Reloading Policies <i class="fa-regular fa-circle-notch fa-spin" aria-hidden="true"></i>');

                getPolicies(data_set, function (html) {
                    $(container).html(html);

                    $('.consent-policy-single').last().find('.view-policy').trigger('click');
                });
            });
        }
    });
});