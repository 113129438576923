var success_message = function(){
	$('.update-success').fadeIn().text('Updated');
	setTimeout(function(){
		$('.update-success').fadeOut();
	},5000);
};

var error_message = function(error){
	$('.update-error').fadeIn().text(error);
	setTimeout(function(){
		$('.update-error').fadeOut();
	},5000);
};

var setDefaultPaymentMethodTariff = function (data) {
	makeRequest('/admin/suppliers/tariffs/set-default', data, function (response) {
		if(isset(response.success) && response.success) {
			success_message();
		}
		else {
			if(isset(response.error)) {
				error_message(response.error);
			}
			else {
				error_message('Cannot Update Supplier Tariff Default');
			}
		}
	});
};

var selected_supplier_id = null;

$( document ).ready(function() {

	$('#suppliers-wrapper').on('click', '.suppliers-wrapper li', function() {
		var supplier_id = $(this).data('supplier-id');
		makeRequest('/admin/suppliers/tariffs/'+supplier_id, {}, function(response) {
			if(isset(response.success) && response.success && isset(response.html)) {
				$('#tariff-edit').show();
				$('#tariff-edit').html(response.html);
				$('.select2').select2({'width':'100%'});
			}
			else {
				error_message('Error loading supplier tariffs')
			}
		}, 'GET');
	});
	
	$('#suppliers-container').on('click', '.tariffs-wrapper li', function() {
		var tariff_id = $(this).data('tariff-id');
		$.ajax({
			data: {},
			url: '/admin/suppliers/tariffs/edit/'+tariff_id,
			success: function(response) {
				$('#tariff-settings').show();
				$('#tariff-settings').html(response.html);
//				$('.update-error').hide();
//				$('.update-success').fadeIn().text('Updated');
//				setTimeout(function(){
//					$('.update-success').fadeOut();
//				},5000);
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});
	
	$('#suppliers-container').on('keyup','.search-supplier',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#suppliers ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});
	
	$('#suppliers-container').on('keyup','.search-tariffs',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#tariffs ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});
	
	$('#suppliers-container').on('click', '.tariff-expired-toggle input', function(){
		
		var tariff_id = $(this).data('tariff-id');
		var action = $(this).is(':checked');
		
		$.ajax({ cache: false,
			url: "/admin/suppliers/tariffs/expire/" + tariff_id,
			type:"POST",
			dataType: 'json',
			data: { "action" : action },
			success: function (response) {
				if(response.success === true) {
					success_message();
				} 
				else {
					error_message('failed to change expiry');
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		
		
	});
	
	$('#suppliers-container').on('change', '.tariff-payment-method-default-select', function () {
		var that = $(this);
		var data = {
			'tariff_id': $(that).find(':selected').attr('data-tariff-id'),
			'supplier_id': $(that).find(':selected').attr('data-supplier-id'),
			'payment_id': $(that).closest('.payment-method-container').data('payment-id')
		};
		if('tariff_id' == 'none') {
			customConfirm('Are you sure you want to unset the default supplier tariff for this payment type?', function() {
				setDefaultPaymentMethodTariff(data);
			});
		}
		else {
			setDefaultPaymentMethodTariff(data);
		}
	});

	$('#suppliers-container').on('change', '.supersede-supplier-select', function () {
		var data = {
			'old_supplier_id': $(this).data('supplier-id'),
			'superseded_by_id': $(this).val()
		};
		makeRequest('/admin/suppliers/supersede', data, function (response) {
			if(isset(response.success) && response.success) {
				success_message();
			}
			else {
				var error = 'unable to supersede supplier';
				if(isset(response.error)) {
					error = response.error;
				}
				error_message(error);
			}
		});
	
	});

	$('#display_online_tariffs').on('click', '.remove-tariff', function(){
		var tariff_id = $(this).data('tariff-id');
		var container = $(this).closest('.tariff-container');
		var tariff_name = $(container).find('.group-name').text();
		var data = {
			'tariff_id' : tariff_id
		};
		customConfirm('Are you sure you want to remove the ' + tariff_name + ' tariff from the website?', function() {
			makeRequest('/tariffs/display-online/remove', data, function(response){
				if(isset(response.success) && response.success){
					$(container).remove();
					success_message('removed');
				}
				else{
					var error = 'unable to remove tariff';
					if(isset(response.error)) {
						error = response.error;
					}
					error_message(error);
				}
			});
		});
	});
	
	$('#display_online_tariffs').on('click', '.add-tariff', function(){
		var tariff_id = $(this).data('tariff-id');
		var data = {
			'tariff_id' : tariff_id
		};
		makeRequest('/tariffs/display-online/add', data, function(response){
			if(isset(response.success) && response.success){
				//go get new html for the left panel 
				if(isset(response.html)){
					$('#displaying_tariffs').html(response.html);
				}
				success_message('added');
			}
			else{
				var error = 'unable to add tariff';
				if(isset(response.error)) {
					error = response.error;
				}
				error_message(error);
			}
		});
	});

	var buildSearchURL = function(){
		return "/tariffs/display-online/search" + (selected_supplier_id != null ? '?supplier_id=' + selected_supplier_id : '');
	}

	$(".magic-tariff-search").select2({
		ajax: {
			url:  buildSearchURL,
			dataType: 'json',
			delay: 250,
			cache: false,
		},
		width:'100%',
		placeholder: 'Search for a tariff',
		minimumInputLength: 1,
		templateResult: formatTariff,
		templateSelection: formatTariffSelection
	});
	  
	function formatTariff (tariff) {
		if (tariff.loading) {
			return tariff.text;
		}	
		var $container = $(
		"<div class='select2-result-repository clearfix " + ( tariff.is_active ? 'active' : 'expired' ) + "'>" +
			"<div class='select2-result-repository__avatar'><img src='" + tariff.supplier_logo + "' /></div>" +
			"<div class='select2-result-repository__meta'>" +
			"<div class='select2-result-repository__title'>" + tariff.name + "</div>" +
			"<div class='select2-result-repository__description'>" + tariff.supplier_name + "</div>" +
			"<div class='select2-result-repository__statistics'>" +
				"<div class='select2-result-repository__label'><i class='fa-solid fa-calendar'></i> " + tariff.term + " </div>" +
				"<div class='select2-result-repository__label'><i class='fa-solid fa-bolt'></i> " + tariff.supply_type + " </div>" +
				"<div class='select2-result-repository__label'><i class='fa-regular fa-power-off'></i> " + tariff.active + " </div>" +
			"</div>" +
			"</div>" +
		"</div>"
		);
		
		return $container;
	}
	  
	function formatTariffSelection (tariff) {
		return tariff.name || tariff.text;
	}

	$('#display_online_tariffs').on('click', '#add_magic_tariff', function(){

		var container = $(this).closest('.action-group-create');
		var tariff_id = $(container).find('.magic-tariff-search').val();
		if(!tariff_id){
			error_message('please select a tariff to add');
			return false;
		}
		var data = {
			'tariff_id' : tariff_id
		};
		makeRequest('/tariffs/display-online/add', data, function(response){
			if(isset(response.success) && response.success){
				//go get new html for the left panel 
				if(isset(response.html)){
					$('#displaying_tariffs').html(response.html);
				}
				//reset the select box
				$(container).find('.magic-tariff-search').val(null).trigger('change');
				success_message('added');
			}
			else{
				var error = 'unable to add tariff';
				if(isset(response.error)) {
					error = response.error;
				}
				error_message(error);
			}
		});
	});

	$('#display_online_tariffs').on('click', '.supplier-container', function(){

		var supplier_id = $(this).data('supplier-id');
		var data = {
			'supplier_id' : supplier_id
		};
		selected_supplier_id = supplier_id;
		makeRequest('/tariffs/display-online/supplier', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('#not_shown_supplier_tariffs').html(response.html);
			}
			else{
				var error = 'unable to get supplier tariffs';
				if(isset(response.error)) {
					error = response.error;
				}
				error_message(error);
			}
		});
	});
});