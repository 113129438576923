$(document).ready(function(){
	var api = new apiInterface();
	var quote_id = $('#lead_script').data("quoteid");
	var bank_validator = new bankValidator();
	$('#lead_script').on('click','#bank-details-completed',function(e){
		//console.log('clicked to validate and set bank details');
		$(".error-notice").html('');
		bank_validator.validateAll(function(errors,data){
			if(errors !== null){
				$.each(errors,function(index,error){
					$('#'+error.target).html(error.message);
				});
				e.preventDefault();
			}
			else{
				//console.log('about to set bank details.');
				$.ajax({
					url: "/order/set_bank_details/"+quote_id,
					data: {details:data},
					type:"POST",
					dataType: 'json',
					success: function(response){
						if (!response.success) {
							//console.log('oops');
							$('#lead_script #sort-code-error').html(response.message);
							//$('#lead-script #account-number-error').html(response.message);
						} 
						else {
							//console.log('about to move forward');
							window.lead_script.handleStep('accident-protection.terms_1');
						}
					},
					error : function(error) {
						console.log(error);
					}		
				});
			}
		});
	});
});