$(document).on('ready', function(){
	$('body').on('change', '#dob_container select', function(){
		//we need to get the DOB and work out if the customer is of pensionable age - if they are then show some text
		var pensionable_age = 65;

		var container = $(this).closest('#dob_container');

		var day_element = $(container).find('select[name="DOBDay"]');
		if(day_element.length){
			var day = $(day_element).val();
			var month_element = $(container).find('select[name="DOBMonth"]');
			if(month_element.length){
				var month = $(month_element).val();
				var year_element = $(container).find('select[name="DOBYear"]');
				if(year_element.length){
					var year = $(year_element).val();
					var dob = 	moment([year, month -1, day]);
					if(dob < moment().subtract(pensionable_age, 'years')){
						$(container).parent().find('.passionable-age-warning').show();
						return;
					}
				}
			}
		}
		$(container).parent().find('.passionable-age-warning').hide();
	});
});