$(document).ready(function() {
    $('body').on('click', '#add_funeral_plan_product_to_basket', function () {
        var that = this;
        lead_script.handleForm(function () {
            var data = {};
            data['ident'] = $('#active_step').data('script-ident');
            data['quote_id'] = $('#lead_script').data('quoteid');
            data['product'] = 'funeralplan';

            makeRequest("/basket/addtobasket", data, function(response) {
                if(response.success) {
                    if(response.success == true) {
                        lead_script.handleStep($(that).data('success-step'));
                    }
                    else {
                        if(response.error) {
                            error_message(response.error);
                        }
                        else {
                            error_message('error, turn it off and on again');
                        }
                    }
                }
            });
        });
    });
});