class twilio_interface {
    constructor(){
		this.ready = false;
		this.connecting = false;
		this.deferred = [];
		this.on_state_change = [
			// 'state_name' => [function(){}]
		]
		this.on_contact_update = [];
		this.state_aliases = {
			'canceled' : 'completed'
		};
	}


	connect(token, callback = function(){}){
		var that = this;
		Pace.ignore(function(){
			try{
				
				Twilio.Device.setup(response.token);
				Twilio.Device.ready(function(device) {
					that.ready = true;
					callback();
					$.each(that.deferred, function(i, method_definition){
						method_definition.method.apply(that, method_definition.arguments);
						delete that.deferred[i];
					});
				});
			}
			catch(error){
				console.log(error);
			}
		});
	}

	getDiallerToken(callback = function(token, short_code){}){
		var that = this;
		makeRequest('/dialler/generate/token', {}, function(response){
			if(isset(response.success) && response.success && isset(response.token) && isset(response.web_short_code) && isset(response.twilio_token)){
				that.connect(response.twilio_token, function(){
					callback(response.token, response.web_short_code);
				});
			}
			else{
				console.log(response);
			}
		})
	}

	/**
	 * onAccept -- define a function to run when we connect to a call
	 *
	 * @memberof twilio_interface
	 */
	onAccept(to_run = function(){}){
		Twilio.Device.connect(function(connection){
			to_run(connection);
		})
	}
	

	/**
	 * onDisconnect-- define a function to run when we connect to a call
	 *
	 * @memberof twilio_interface
	 */
	onDisconnect(to_run = function(){}){
		Twilio.Device.disconnect(function(connection){
			to_run(connection);
		})
	}
	
	/**
	 * onIcomming -- define a function to run when we receive and incomming call
	 *
	 * @memberof twilio_interface
	 */
	onIncoming(to_run = function(connection){}){
		Twilio.Device.incoming(function(connection){
			to_run(connection);
		})
	}

	checkState(){
		if(!this.ready){
			this.deferred.push({
				'method' : arguments.callee.caller,
				'arguments' : arguments.callee.caller.arguments
			});
			return false;
		}
		return true;
	}

	makeCall(phone_number, onConnect = function(){}){
		if(this.checkState){
			this.getDiallerToken(function(token, web_short_code){
				if(phone_number[0] == '0'){
					phone_number = phone_number.replace('0', '44');
				}
				var params = { 
					'To': phone_number,
					'action' : 'manualDial',
					'dialler_token' : token,
					'web_short_code' : web_short_code
				};
				Twilio.Device.connect(params);
				onConnect();
			});
		}
	}

	listenIn(external_id){
		if(this.checkState){
			this.getDiallerToken(function(token, web_short_code){
				var params = { 
					'external_id': external_id,
					'action' : 'listenIn',
					'dialler_token' : token,
					'web_short_code' : web_short_code
				};
				Twilio.Device.connect(params);
			});
		}
	}
	
	bargeIn(external_id){
		if(this.checkState){
			this.getDiallerToken(function(token, web_short_code){
				var params = { 
					'external_id': external_id,
					'action' : 'bargeIn',
					'dialler_token' : token,
					'web_short_code' : web_short_code
				};
				Twilio.Device.connect(params);
			});
		}
	}
	
	joinConference(external_id){
		if(this.checkState){
			this.getDiallerToken(function(token, web_short_code){
				var params = { 
					'external_id': external_id,
					'action' : 'joinConference',
					'dialler_token' : token,
					'web_short_code' : web_short_code
				};
				Twilio.Device.connect(params);
			});
		}
	}
	
	listenInToUser(user_id, onConnect = function(){}){
		if(this.checkState){
			this.getDiallerToken(function(token, web_short_code){
				var params = { 
					'target_user_id': user_id,
					'action' : 'listenIn',
					'dialler_token' : token,
					'web_short_code' : web_short_code
				};
				Twilio.Device.connect(params);
				onConnect();
			});
		}
	}
	
	bargeInToUser(user_id, onConnect = function(){}){
		if(this.checkState){
			this.getDiallerToken(function(token, web_short_code){
				var params = { 
					'target_user_id': user_id,
					'action' : 'bargeIn',
					'dialler_token' : token,
					'web_short_code' : web_short_code
				};
				Twilio.Device.connect(params);
				onConnect();
			});
		}
	}
	
	makeInternalCall(user_id, onConnect = function(){}){
		if(this.checkState){
			this.getDiallerToken(function(token, web_short_code){
				var params = { 
					'To': user_id,
					'action' : 'internalDial',
					'dialler_token' : token,
					'web_short_code' : web_short_code
				};
				Twilio.Device.connect(params);
				onConnect();
			});
		}
	}

	setAvailable(){
		if(this.checkState){
			this.getDiallerToken(function(token, web_short_code){
				var params = {
					'action' : 'setAvailable',
					'dialler_token' : token,
					'web_short_code' : web_short_code
				};
				Twilio.Device.connect(params);
			});
		}
	}

	hangup(){
		Twilio.Device.disconnectAll();
	}

	// below is not a comment but rather keyword tags to help us find this function in the future
	// touchy mcTone -- touch tone keypad sound thingy 
	sendDTMF(key){
		var connection = Twilio.Device.activeConnection();
		if(connection){
			connection.sendDigits(String(key));
		}
	}

	quickHold(callback = function(){}){
		var connection = Twilio.Device.activeConnection();
		// check we actually have a connection
		if(connection){
			var our_call_sid = connection.parameters.CallSid;
			makeRequest('/dialler/toggle/customer-hold', {'call_sid' : our_call_sid, 'hold' : true}, function(response){
				callback(response);
			});
		}
		else{
			callback({'success' : false});
		}
	}
	
	quickUnhold(callback =function(){}){
		var connection = Twilio.Device.activeConnection();
		// check we actually have a connection
		if(connection){
			var our_call_sid = connection.parameters.CallSid;
			makeRequest('/dialler/toggle/customer-hold', {'call_sid' : our_call_sid, 'hold' : false}, function(response){
				callback(response)
			});
		}
		else{
			callback({'success' : false});
		}
	}


	addInternalUserToConference(user_id, callback = function(){}){
		var connection = Twilio.Device.activeConnection();
		// check we actually have a connection
		if(connection){
			var call_sid = connection.parameters.CallSid;
			makeRequest('/dialler/add-to/conference', {'call_sid' : call_sid, 'user_id' : user_id}, function(response){
				callback(response)
			});
		}
		else{
			callback({'success' : false});
		}
	}
	
	innternalTransferRequest(user_id, callback = function(response){}){
		var connection = Twilio.Device.activeConnection();
		// check we actually have a connection
		if(connection){
			var call_sid = connection.parameters.CallSid;
			makeRequest('/dialler/transfer/internal', {'call_sid' : call_sid, 'user_id' : user_id}, function(response){
				callback(response)
			});
		}
		else{
			callback({'success' : false});
		}
	}
	
	externalTransferRequest(number, callback = function(response){}){
		var connection = Twilio.Device.activeConnection();
		// check we actually have a connection
		if(connection){
			if(number.indexOf('0') === 0){
				number.replace('0', '44');
			}
			var call_sid = connection.parameters.CallSid;
			makeRequest('/dialler/transfer/external', {'call_sid' : call_sid, 'number' : number}, function(response){
				callback(response)
			});
		}
		else{
			callback({'success' : false});
		}
	}
	
	endSpecificConferenceLeg(user_id, callback = function(response){}){
		var connection = Twilio.Device.activeConnection();
		// check we actually have a connection
		if(connection){
			var call_sid = connection.parameters.CallSid;
			makeRequest('/dialler/end/user-conference-leg', {'call_sid' : call_sid, 'user_id' : user_id}, function(response){
				callback(response)
			});
		}
		else{
			callback({'success' : false});
		}
	}

	endSpecificConferenceLegByExternalId(external_id, callback = function(response){}){
		var connection = Twilio.Device.activeConnection();
		// check we actually have a connection
		if(connection){
			var call_sid = connection.parameters.CallSid;
			makeRequest('/dialler/end/external-id-conference-leg', {'call_sid' : call_sid, 'to_end_external_id' : external_id}, function(response){
				callback(response)
			});
		}
		else{
			callback({'success' : false});
		}
	}
	
	endTransferCallRequest(external_id, callback = function(response){}){
		var connection = Twilio.Device.activeConnection();
		// check we actually have a connection
		if(connection){
			var call_sid = connection.parameters.CallSid;
			makeRequest('/dialler/end/external-id-transfer-request', {'call_sid' : call_sid, 'to_end_external_id' : external_id}, function(response){
				callback(response)
			});
		}
		else{
			callback({'success' : false});
		}
	}
	
	completeTransfer(callback = function(response){}){
		var connection = Twilio.Device.activeConnection();
		// check we actually have a connection
		if(connection){
			var call_sid = connection.parameters.CallSid;
			makeRequest('/dialler/transfer/complete', {'call_sid' : call_sid}, function(response){
				callback(response)
			});
		}
		else{
			callback({'success' : false});
		}
	}

	handleCallStatusChange(payload){

		if(isset(payload.state)){
			var state = payload.state;
			if(isset(this.state_aliases[state])){
				state = this.state_aliases[state];
				payload.state = state;
			}
			var clean_state_name = ucfirst(state.replace(/\-/, ''));
			var method_name = 'updateState' + clean_state_name;
			if(isset(this[method_name]) && typeof(this[method_name]) == 'function'){
				this[method_name](payload);
			}
			else{
				this.genericStateUpdate(clean_state_name, payload);
			}
		}
	}

	addStateChangeMethod(state, to_run = function(){}){
		if(!isset(this.on_state_change[state])){
			this.on_state_change[state] = [];
		}
		this.on_state_change[state].push(to_run);
	}

	getStateChangeMethods(state){
		if(isset(this.on_state_change[state])){
			return this.on_state_change[state];
		}
		return [];
	}

	onRinging(to_run = function(){}){
		this.addStateChangeMethod('ringing', to_run);
	}

	onCallConnect(to_run = function(){}){
		this.addStateChangeMethod('connected', to_run);
	}
	
	onCallEnd(to_run = function(){}){
		this.addStateChangeMethod('completed', to_run);
	}
	
	onUserBusy(to_run = function(){}){
		this.addStateChangeMethod('user_busy', to_run)
	}

	getActiveSid(){
		var connection = Twilio.Device.activeConnection();
		// check we actually have a connection
		if(connection){
			var call_sid = connection.parameters.CallSid;
			return call_sid;
		}
	}

	updateStateRinging(payload){
		var connection = Twilio.Device.activeConnection();
		// check we actually have a connection
		if(connection){
			if(isset(payload.call_sid)){
				// so the call is ringing and it is the right call 
				// need to show this is the ui 
				var methods = this.getStateChangeMethods('ringing');
				$.each(methods, function(i, to_run){
					to_run(payload.call_sid);
				});
			}
		}
	}

	updateStateInprogress(payload){
		var connection = Twilio.Device.activeConnection();
		// check we actually have a connection
		if(connection){
			if(isset(payload.call_sid)){
				// so the call is ringing and it is the right call 
				// need to show this is the ui 
				var methods = this.getStateChangeMethods('connected');
				$.each(methods, function(i, to_run){
					to_run(payload.call_sid);
				})
			}
		}
	}
	
	updateStateCompleted(payload){
		var connection = Twilio.Device.activeConnection();
		// check we actually have a connection
		if(connection){
			if(isset(payload.call_sid) ){
				// so the call is ringing and it is the right call 
				// need to show this is the ui 
				var methods = this.getStateChangeMethods('completed');
				$.each(methods, function(i, to_run){
					to_run(payload.call_sid);
				})
			}
		}
	}
	
	updateStateBusy(payload){
		var connection = Twilio.Device.activeConnection();
		// check we actually have a connection
		if(connection){
			if(isset(payload.call_sid) ){
				// so the call is ringing and it is the right call 
				// need to show this is the ui 
				var methods = this.getStateChangeMethods('user_busy');
				$.each(methods, function(i, to_run){
					to_run(payload.call_sid);
				})
			}
		}
	}
	
	genericStateUpdate(state, payload){
		var connection = Twilio.Device.activeConnection();
		// check we actually have a connection
		if(connection){
			if(isset(payload.call_sid) ){
				// so the call is ringing and it is the right call 
				// need to show this is the ui 
				var methods = this.getStateChangeMethods(state.toLowerCase());
				$.each(methods, function(i, to_run){
					to_run(payload.call_sid);
				})
			}
		}
	}


	onContactStatusUpdate(to_run){
		this.on_contact_update.push(to_run);
	}

	updateContactStatus(payload){
		console.log(payload)
		$.each(this.on_contact_update, function(i, to_run){
			to_run(payload.user_short_code, payload.state);
		})

	}


}

module.exports  = new twilio_interface();