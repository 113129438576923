$(function () {
	var api = new apiInterface();

	$('#lead_script').on('click', '.eagle-mobile-options .tariff-option', function() {
		// Get the selected tariff ID
		var tariffId = $(this).data('tariff-id');
		var bundle_price = $(this).data('bundle_price');
		var bundle_data = $(this).data('bundle_data');
		$('#mobile_package_id').val(tariffId);
		// Deselect all tariffs and select the clicked one
		$('.tariff-option').removeClass('tariff-selected');
		$('.select-tariff').removeClass('tariff-selected').text('Select');
		$(this).addClass('tariff-selected');
		$(this).find('.select-tariff').addClass('tariff-selected').text('Selected');
		$('#lead_script').find('.mobile-pitch-text').find('.bundle-price').text(bundle_price);
		$('#lead_script').find('.mobile-pitch-text').find('.bundle-data').text(bundle_data);
	});

	$('#lead_script').on('click', '.eagle-mobile-options .select-tariff', function() {
		$(this).closest('tariff-option').trigger('click');
	});


	$('#lead_script').on('click', '#eagle_broadband_mobile_select_next', function(e) {
		showLoader();
		e.preventDefault();
		var next_step = $(this).data('success-step');

		var quote_id = $('#lead_script').data('quoteid');
		
		// Collect data from all active sim-detail divs
		var simDetails = $('.selected-sim-option').not('.hidden').find('.sim-detail').not('.hidden');
		var simData = [];

		simDetails.each(function() {
			var simDetail = $(this);
			var simInfo = {
				tariff: simDetail.data('tariff_id'), 
				budget_control: simDetail.find('input[name="budget_control"]').val(),
				keep_existing_number: simDetail.find('input[name="keep_existing_number"]').val(),
				pac_code: simDetail.find('input[name="pac_code"]').val(),
				cancel_existing_contract: simDetail.find('input[name="cancel_existing_contract"]').val(),
				stac_code: simDetail.find('input[name="stac_code"]').val(),
				existing_number: simDetail.find('input[name="existing_number"]').val()
			};
			simData.push(simInfo);
		});

		var data = {
			simDetails: simData,
			quote_id :quote_id
		};

		makeRequest('/quote/eagle-broadband/set-mobile-package', data, function(response) {
			if (response.success) {
				lead_script.handleStep(next_step);
				hideLoader();
			} else {
				error_message(response.error);
				hideLoader();
			}
		});
	});

	// $('#lead_script').on('click','#eagle_broadband_mobile_select_next',function(e){
	// 	e.preventDefault();
	// 	var next_step = $(this).data('success-step');

	// 	var quote_id = $('#lead_script').data('quoteid');

	// 	// find the selected option and make sure we only have one option selected. 
	// 	var selected_tariff_id = $('.eagle-mobile-options .tariff-selected').data('tariff-id');
	// 	if(selected_tariff_id > 0 || selected_tariff_id == 'remove-mobile'){
	// 		var data = {
	// 			quote_id : quote_id,
	// 			tariff_id :selected_tariff_id
	// 		};
	// 		showLoader();
	// 		makeRequest('/quote/eagle-broadband/set-mobile-package',data,function(response){
	// 			hideLoader();
	// 			if(isset(response.success) && response.success){
	// 				lead_script.handleStep(next_step);
	// 			}
	// 			else{
	// 				if(isset(response.error)){
	// 					error_message(response.error);
	// 				}
	// 				else{
	// 					error_message('something went wrong, unable to add item');
	// 				}
	// 			}
	// 		});
	// 	}
	// 	else{
	// 		error_message('Please select a tariff');
	// 	}
	// });

	$('#lead_script').on('click', '.eagle-broadband-options .tariff-option', function() {
		// Get the selected tariff ID
		var tariffId = $(this).data('tariff-id');
		$('#broadband_package_id').val(tariffId);
		// Deselect all tariffs and select the clicked one
		$('.tariff-option').removeClass('tariff-selected');
		$('.select-tariff').removeClass('tariff-selected').text('Select');
		$(this).addClass('tariff-selected');
		$(this).find('.select-tariff').addClass('tariff-selected').text('Selected');
	});

	

	$('#lead_script').on('click','#eagle_broadband_broadband_select_next',function(e){
		e.preventDefault();
		var next_step = $(this).data('success-step');

		var quote_id = $('#lead_script').data('quoteid');

		// find the selected option and make sure we only have one option selected. 
		var selected_tariff_id = $('.eagle-broadband-options .tariff-selected').data('tariff-id');
		if(selected_tariff_id > 0 || selected_tariff_id == 'remove-broadband'){
			var data = {
				quote_id : quote_id,
				tariff_id :selected_tariff_id
			};
			showLoader();
			makeRequest('/quote/eagle-broadband/set-broadband-package',data,function(response){
				hideLoader();
				if(isset(response.success) && response.success){
					if(isset(response.next_step)){
						lead_script.handleStep(response.next_step);
					}
					else{
						lead_script.handleStep(next_step);
					}
				}
				else{
					if(isset(response.error)){
						error_message(response.error);
					}
					else{
						error_message('something went wrong, unable to add item');
					}
				}
			});
		}
		else{
			error_message('Please select a tariff');
		}
	});

	$('#lead_script').on('click','#eagle_broadband_fact_find_next',function(e){
		e.preventDefault();
		var next_step = $(this).data('success-step');
		var quote_id = $('#lead_script').data('quoteid');

		var broadband_provider = $('.broadband-supplier-and-spend-section select[name="broadband_supplier"]').val();
		var broadband_spend = $('.broadband-supplier-and-spend-section input[name="current_broadband_spend"]').val();
		var data = {
			quote_id:quote_id,
			broadband_provider:broadband_provider,
			broadband_spend:broadband_spend
		};
		makeRequest('/quote/eagle-broadband/set-current-supply',data,function(response){
			hideLoader();
			if(isset(response.success) && response.success){
				lead_script.handleStep(next_step);
			}
			else{
				if(isset(response.error)){
					error_message(response.error);
				}
				else{
					error_message('something went wrong, unable to update profile');
				}
			}
		});

	});
	

	$('#lead_script').on('click','#confirm-eagle-broadband-order',function(e){
		e.preventDefault();
		var quote_id = $('#lead_script').data('quoteid');
		showLoader();
		var quotes_to_confirm = [];
		quotes_to_confirm.push($('#lead_script').data("quoteid"));
		var keys_to_update  = {};
		var data= {
			'quotes' : quotes_to_confirm,
			'checkout_actions' : keys_to_update
		};

		data['reset_confirmed_date'] = $(this).data('reset-confirmed-date');
		
		api.confirmOrders(data, function(response){
			if(response.success){
				window.location.href =  response.redirect_url;
			}
			else{
				hideLoader();
				if(isset(response.errrors)){
					response.errors.each(function(i,e){
						error_message(e);
					});
				}
				else{
					error_message('something went wrong');
				}
			}
		});
	});

	$('#lead_script').on('click','.change-broadband-payment-day-button',function(e){
		e.preventDefault();
		$('.change-broadband-upfront-payment-options').addClass('hide');
		$('.change-broadband-payment-day').removeClass('hide');
	});

	$('#lead_script').on('change','#broadband_payment_date',function(e){
		e.preventDefault();
		showLoader();
		var quote_id = $('#lead_script').data('quoteid');
		var payment_day = $(this).val();

		$('.change-broadband-upfront-payment-options').addClass('hide');
		var data = {
			quote_id:quote_id,
			payment_day:payment_day
		}
		makeRequest('/quote/eagle-broadband/update-payment-day',data,function(response){
			hideLoader();
			if(isset(response.success) && response.success && isset(response.html)){
				$('.deposit-payment-summary').html(response.html);
				success_message('Payment Day Updated');
			}
			else if(isset(response.error)){
				error_message(response.error);
			} 
			else {
				error_message('unable to update payment day');
			}

		})
	});

	$('#lead_script').on('click','.take-broadband-deposit-card-payment-button',function(e){
		e.preventDefault();
		$('.change-broadband-upfront-payment-options').addClass('hide');
		showLoader();
		var quote_id = $('#lead_script').data('quoteid');
		var data = {
			quote_id:quote_id,
		}
		makeRequest('/quote/eagle-broadband/get-deposite-payment-html',data,function(response){
			hideLoader();
			if(isset(response.success) && response.success && isset(response.html)){
				$('.take-broadband-deposit-card-payment').html(response.html);
				$('.take-broadband-deposit-card-payment').removeClass('hide');
			}
			else if(isset(response.error)){
				error_message(response.error);
			} 
			else {
				error_message('unable to update payment day');
			}

		});
	});

	$('#lead_script').on('click','#add-deposit-payment-to-broadband-order',function(e){
		e.preventDefault();
		var wrapper = $(this).closest('.broadband-deposit-input-wrapper');
		var payment_amount = $(wrapper).find('input[name="payment_value"]').val();
		var card_type = $(wrapper).find('select[name="card_type"]').val();
		var quote_id = $('#lead_script').data('quoteid');
		var payment_reference = $(wrapper).find('input[name="payment_reference"]').val();

		var data = {
			payment_amount:payment_amount,
			card_type:card_type,
			quote_id:quote_id,
			payment_reference:payment_reference
		};

		makeRequest('/quote/eagle-broadband/add-deposite-payment',data,function(response){
			hideLoader();
			if(isset(response.success) && response.success && isset(response.html)){
				$('.deposit-payment-summary').html(response.html);
				$('.take-broadband-deposit-card-payment').addClass('hide');
				$('.next-button').removeClass('hide');
				$('.upfront-payment-option-buttons-wrapper').addClass('hide');
				success_message('Payment Added');
				
			}
			else if(isset(response.error)){
				error_message(response.error);
			} 
			else {
				error_message('unable to update payment day');
			}

		})

	});
	


});