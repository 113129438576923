$(document).ready(function() {
    const datatable = $('#web-users-table').DataTable({
        dom: 'flrtiBp',
        ajax: "/get/web-users",
        ordering: true,
        responsive: true,
        bAutoWidth:false,
        order: [[ 4, "desc" ]],
        autoWidth: false,
        lengthMenu: [25, 40, 100],
        select: true,
        buttons: [
                'copyHtml5',
                'print',
                {
                    extend: 'collection',
                    text: 'Export',
                    buttons: [
                        'csvHtml5',
                        'pdfHtml5'   
                    ]
                }
        ],
        initComplete: function () {}
    });
	
	
	
	if($('input[name="web-users-date-filter"]').length > 0){
        $('input[name="web-users-date-filter"]').daterangepicker({

            "locale": {
                "format": 'DD/MM/YYYY',
                "separator": "-"
            },
            "linkedCalendars": false,
            "showDropdowns": true
        },
        function(start, end, label) {

            const datatable = $('#web-users-table').DataTable({
                destroy: true,
                dom: 'flrtiBp',
                ajax: "/get/web-users?all=true&start="+start+"&end="+end,
                pageLength: 40,
                ordering: true,
                responsive: true,
                order: [[ 4, "desc" ]],
                bAutoWidth:false,
                autoWidth: false,
                lengthMenu: [25, 40, 100],
                select: true,
                buttons: [
                    'copyHtml5',
                    'print',
                    {
                        extend: 'collection',
                        text: 'Export',
                        buttons: [
                            'csvHtml5',
                            'pdfHtml5'   
                        ]
                    }
                ],
                
                initComplete: function () {} 
            });		
        });
    }
	
});    



