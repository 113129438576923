let setupCustomHtml = function () {
        $('.select-2').select2({
            width:'100%',
            placeholder: "Select",
            allowClear: true
        });

        $('body').on('change', '#bundle_product_type_id', function () {
            let $that = $(this),
                select = $('#bundle_group_id');

            $.each($(select).find('option'), function (i, $element) {
                if($($element).data('product-type-id') == $($that).find(':selected').val()) {
                    $($element).removeAttr('disabled');
                } 
				else {
                    if($($element).is(':selected')) {
                        $($element).removeAttr('selected');
                    }
                    $($element).attr('disabled', 'disabled');
                }
            });
            $(select).val('').trigger('change');
            $(select).select2("destroy").select2({
                width:'100%',
                placeholder: "Select",
                allowClear: true
            });
        });
    },

    saveBundleVariables = function () {
        var data = {
            bundle_id: $('.save-product-bundle').data('bundle-id'),
            variables: $('#bundle_variables_form').serialize()
        }

        makeRequest('/product/bundle/variables/update', data, function (response) {
            if(isset(response.success) && response.success) {
                success_message('Updated Variables');
                return;
            }
            handleRequestError(response);
        });
    },

    saveBundleCriteria = function () {
        var data = {
            bundle_id: $('.save-product-bundle').data('bundle-id'),
            retired: $('#bundle_retire').val(),
            active: $('#bundle_active').val(),
            sort_order: $('#bundle_sort_order').val(),
            featured_position: $('#bundle_featured').val()
        }

        makeRequest('/product/bundle/criteria/update', data, function (response) {
            if(isset(response.success) && response.success) {
                success_message('Updated Criteria');
                return;
            }
            handleRequestError(response);
        });
    },

    updateProductChannels = function () {
        let data = {
            product_ids: $('.channels-wrapper').data('product-ids'),
            channel_ids: []
        };
        $.each($('#product_channel_sort').find('li'), function (i, $element) {
            data['channel_ids'].push($($element).data('channel-id'));
            if($($element).find('.delete-trigger').length === 0) {
                $($element).find('.edit-trigger').remove();
                $($element).append(
                    '<div class="delete-trigger remove-product-channel">' +
                    '<i class="fa-solid fa-minus-circle" aria-hidden="true"></i>' +
                    '</div>'
                );
            }
        });
        makeRequest('/product/channels/update', data, function (response) {
            if(isset(response.success) && response.success) {
                success_message('Updated channels');
                return;
            }
            handleRequestError(response);
        });
    },

    updateBundleProducts = function () {
        let data = {
            bundle_id: $('.save-product-bundle').data('bundle-id'),
            product_ids: []
        };
        $.each($('#bundle_product_sort').find('li'), function (i, $element) {
            data['product_ids'].push($($element).data('product-id'));
            if($($element).find('.delete-trigger').length === 0) {
                $($element).append(
                    '<div class="delete-trigger remove-bundle-product">' +
                    '<i class="fa-solid fa-minus-circle" aria-hidden="true"></i>' +
                    '</div>'
                );
            }
        });
        makeRequest('/product/bundle/products/update', data, function (response) {
            if(isset(response.success) && response.success) {
                success_message('Updated Products');
                reloadAllProducts();
                return;
            }
            handleRequestError(response);
        });
    },

    makeProductsSortable = function () {
        $('#bundle_product_sort').sortable({
            update: function (e, ui) {
                updateBundleProducts();
            }
        });

        $('#all_product_sort').sortable({
            connectWith: '#bundle_product_sort'
        });
    },

    makeChannelsSortable = function () {
        $('#product_channel_sort').sortable({
            update: function (e, ui) {
                updateProductChannels();
            }
        });

        $('#all_channels_sort').sortable({
            connectWith: '#product_channel_sort'
        });
    },

    editBundle = function (bundle_id) {
        showLoader();
        let data = {
            bundle_id
        }
        makeRequest('/product/bundle/edit', data, function (response) {
            if(isset(response.success) && response.success && isset(response.html)) {
                $('#bundle_container').html(response.html);
                setupCustomHtml();
                hideLoader();
                success_message('Loaded');
                return;
            }
            hideLoader();
            handleRequestError(response);
        });
    },

    updateBundle = function (data) {
        showLoader();
        $('.product-bundle-options').hide();
        makeRequest('/product/bundle/update', data, function (response) {
            if(isset(response.success) && response.success) {
                success_message('Updated');
                hideLoader();
                return;
            }
            hideLoader();
            handleRequestError(response);
        });
    },

    storeBundle = function (data) {
        makeRequest('/product/bundle/store', data, function (response) {
            if (isset(response.success) && response.success && isset(response.html) && isset(response.html_second)) {
                $('.unit-wrapper').append(response.html);
                $('#bundle_container').html(response.html_second);
                success_message('Stored');
                return;
            }
            handleRequestError(response);
        });
    },

    destroyBundle = function (bundle_id, $element) {
        makeRequest('/product/bundle/destroy', {bundle_id}, function (response) {
            if(isset(response.success) && response.success) {
                $element.remove();
                $('#bundle_container').html('');
                success_message('Deleted');
                return;
            }
            handleRequestError(response);
        });
    },

    createBundle = function (name, description) {
        makeRequest('/product/bundle/create', {}, function (response) {
            if(isset(response.success) && response.success && isset(response.html)) {
                let $container = $('#bundle_container');
                $container.html(response.html);
                $container.find('#bundle_name').val(name);
                $container.find('#bundle_description').val(description);
                setupCustomHtml();

                success_message('Template Started');
                return;
            }
            handleRequestError(response);
        });
    },

    getBundleProducts = function ($element) {
        showLoader();
        let data = {
            bundle_id: $($element).closest('.product-option-container').data('bundle-id')
        }
        makeRequest('/product/bundle/products', data, function (response) {
            if (isset(response.success) && response.success && isset(response.html) && isset(response.html_second)) {
                $('#bundle_products_container').html(response.html)
                $('#product-bundle-products-pane').html(response.html_second);
                makeProductsSortable();
                hideLoader();
                return;
            }
            hideLoader();
            handleRequestError(response);
        });

    },

    getBundleVariables = function ($element) {
        let data = {
            bundle_id: $($element).closest('.product-option-container').data('bundle-id')
        }
        makeRequest('/product/bundle/variables', data, function (response) {
            if (isset(response.success) && response.success && isset(response.html) && isset(response.html)) {
                $('#product-bundle-variables-pane').html(response.html);
                if(isset(response.warning)) {
                    error_message(response.warning);
                    $('.save-variables').hide();
                }
                hideLoader();
                return;
            }
            hideLoader();
            handleRequestError(response);
        });
    },

    getBundleCriteria = function ($element) {
        $('.criteria-select2').select2({'width':'62%'});
    },

    uploadImage = function () {
        let $image_file = $('#bundle-image-file');
        let file = $image_file[0].files[0];
        let bundle_id = $('.save-product-bundle').data('bundle-id');

        let data = new FormData();
        data.append('file', file);
        data.append('bundle_id', bundle_id);

        showLoader();
        makeFileRequest('/product/bundle/image/upload', data, function(response) {
            if (response.success) {
                success_message();
                return;
            }
            handleRequestError(response);
            hideLoader();
        });
        hideLoader();
    },

    saveEditedChannel = function () {
        let $image_file = $('#channel-image-file');
        let file = $image_file[0].files[0];
        let channel_id = $('#channel_edit_store').data('channel-id');

        let data = new FormData();
        data.append('name', $('#channel_name').val());
        data.append('group_id', $('#channel_group option:selected').val());
        data.append('file', file);
        data.append('channel_id', channel_id);

        showLoader();
        makeFileRequest('/product/channels/edit-save', data, function (response) {
            if (response.success) {
                success_message();
                hideLoader();
                return;
            }
            handleRequestError(response);
            hideLoader();
        });
    },

    reloadAllProducts = function () {
        let data = {
            bundle_id: $('.save-product-bundle').data('bundle-id')
        }
        makeRequest('/product/bundle/products', data, function (response) {
            if (isset(response.success) && response.success && isset(response.html)) {
                $('#bundle_products_container').html(response.html)
                success_message('Stored');
                makeProductsSortable();
                return;
            }
            handleRequestError(response);
        });
    },

    addProductsToBasket = function (callback) {
        let data = {
            quote_id : $('#lead_script').data('quoteid'),
            product_ids : []
        };

        $.each($('div.add-on-product-details.selected'), function (i, $element) {
            data['product_ids'].push($($element).data('product-id'));
        });

         makeRequest('/quote/add-on-products-modified', data, function(response){
             if(isset(response.success) && response.success) {
                 success_message();
                 callback();
                 return;
             }
             handleRequestError(response);
        });
    },

    editChannel = function (channel_id) {
        let data = {
            channel_id
        };

        makeRequest('/product/channels/edit-channel', data, function(response){
            if(isset(response.success) && response.success && isset(response.html)) {
                $('#edit-channel-container').html(response.html);
                $('.select-2').select2({'width':'100%'});

                $('body').on('click', '#channel_edit_store', function () {
                    saveEditedChannel();
                });

                success_message('Loaded');
                return;
            }
            handleRequestError(response);
        });
    },

    loadAction = {
        'products': function ($element) { getBundleProducts($element) },
        'variables': function ($element) { getBundleVariables($element) },
        'criteria' : function ($element) { getBundleCriteria($element) }

};

$(function () {
    $('body')
        .on('click', '.edit-bundle', function () {
            editBundle($(this).closest('.bundle-single').data('bundle-id'));
            $('#bundle_products_container').html('');
        })

        .on('click', '.save-product-bundle', function () {
            let name = $('#bundle_name').val(),
                description = $('#bundle_description').val(),
                bundle_id = $(this).data('bundle-id'),
                product_type_id = $('#bundle_product_type_id :selected').val(),
                brand_id = $('#bundle_brand_id :selected').val(),
                group_id = $('#bundle_group_id :selected').val();

            if(name.length === 0) {
                error_message('Please enter a name');
            }

            if(description.length === 0) {
                error_message('Please enter a description');
            }

            let data = {
                name, description, product_type_id, brand_id, group_id
            }

            if(bundle_id.length === 0) {
                storeBundle(data);
            } 
			else {
                data['bundle_id'] = bundle_id;
                updateBundle(data);
            }
        })

        .on('click', '.create-bundle', function () {
            let name = $('#bundles-search').val();
            if(name.length === 0) {
                error_message('Please enter a name');
                return;
            }
            createBundle(name, name.concat(' - description'));
            $('#bundle_products_container').html('');
        })

        .on('click', '.destroy-bundle', function () {
            let $element = $(this).closest('.bundle-single');
            destroyBundle($element.data('bundle-id'), $element);
            $('#bundle_products_container').html('');
        })

        .on('click', '.tab-pane-products', function () {
            $('#bundle_products_container').html('');
            let target = $(this).data('target');
            $('.product-bundle-options').hide();
            $('#' + target).show();

            let method = $(this).data('method');
            if(isset(method) && method.length) {
                loadAction[method]($(this));
            }
        })

        .on('click', '.remove-bundle-product', function () {
            $(this).closest('li').remove();
            updateBundleProducts();
        })

        .on('click', '.remove-product-channel', function () {
            $(this).closest('li').remove();
            updateProductChannels();
        })

        .on('keyup', '#channels-search', function () {
            var term = $(this).val();

            if (term != '') {
                $.each($('#all_channels_container').find('li'), function (i, row) {
                    var text = $(row).find('.name').text();
                    if (text.indexOf(term) !== -1) {
                        $(row).show();
                    } 
					else {
                        $(row).hide();
                    }
                });
            } 
			else {
                $('#all_channels_container').find('li').show();
            }
        })

        .on('click', '.save-variables', function (e) {
            e.preventDefault();
            saveBundleVariables();
        })

        .on('click', '.save-criteria', function () {
            saveBundleCriteria();
        })

        .on('click', '#bundle_image_upload', function() {
            uploadImage();
        })

        .on('change', '#bundle-image-file', function() {
            let file_name = $(this).val().split('\\').pop();

            $('#bundle_image_upload').prop('disabled', file_name.length === 0);

            if (file_name.length === 0) {
                file_name = 'Select File For Upload';
            }

            $('#bundle-image-file-name').html(file_name);
        })

        .on('change', '#channel-image-file', function() {
            let file_name = $(this).val().split('\\').pop();

            $('#channel_image_upload').prop('disabled', file_name.length === 0);

            if (file_name.length === 0) {
                file_name = 'Select File For Upload';
            }

            $('#channel-image-file-name').html(file_name);
        })

        .on('click', '#edit-channel', function () {
            editChannel($(this).data('channel-id'));
        });

    $('.script').on('click','.product-bundle-tabs',function(){
        let target = $(this).data('target');
        $('.product-bundle-tabs').removeClass('bundle-active-tab');
        $(this).addClass('bundle-active-tab');
        $('.bundle-details-container').addClass('hidden');
        $('#'+target).removeClass('hidden');
    });

    $('.script').on('click','.select-bundle',function(){
        let data = {
            bundle_id : $(this).data('bundle_id'),
            quote_id : $('#lead_script').data('quoteid')
        };

        makeRequest('/quote/add-bundle/'+data.quote_id, data, function(response){
            if(isset(response.success) && response.success) {
                window.lead_script.handleStep($('.select-bundle').data('success-step'));
            }
            else {
                if(isset(response.error)) {
                    error_message(response.error);
                    return;
                }
                error_message('oops something went wrong');
            }
        });
    });

	$('.script').on('click','.select-product',function(){
        let data = {
            product_id : $(this).data('product_id'),
            quote_id : $('#lead_script').data('quoteid')
        };

        makeRequest('/quote/add-broadband-product/'+data.quote_id, data, function(response){
            if(isset(response.success) && response.success) {
                window.lead_script.handleStep($('.select-product').data('success-step'));
            }
            else {
                if(isset(response.error)) {
                    error_message(response.error);
                    return;
                }
                error_message('oops something went wrong');
            }
        });
    });

    $('.script').on('click','.broadband-package-changed',function(){
        let data = {
            product_id : $(this).data('product_id'),
            quote_id : $('#lead_script').data('quoteid')             
        };
        let element = this;
        makeRequest('/quote/change-broadband-package/'+data.quote_id, data, function(response){
            if(isset(response.success) && response.success) {    
                // remove the add buttons
                $('.broadband-package-container').find('.broadband-package-details').removeClass('active');
                $('.broadband-package-container').find('.broadband-package-changed').removeClass('hidden');
                $(element).closest('.broadband-package-details').addClass('active');
                
                $(element).addClass('hidden');
                $(element).siblings().removeClass('hidden');
            }
           else {
               if(isset(response.error)) {    
                   error_message(response.error);
                   return;
               }
               error_message('oops something went wrong');
           }
       });
    });

    $('.script').on('click','.broadband-package-removed',function(){
        let data = {
            product_id : $(this).data('product_id'),
            quote_id : $('#lead_script').data('quoteid')             
        };
        let element = this;
        makeRequest('/quote/remove-broadband-package/'+data.quote_id, data, function(response){
            if(isset(response.success) && response.success) {    
                // remove the add buttons
                $('.broadband-package-container').find('.broadband-package-details').removeClass('active');
                $('.broadband-package-container').find('.broadband-package-changed').removeClass('hidden');
                // $(element).closest('.broadband-package-details').addClass('active');
                
                $(element).addClass('hidden');
                $(element).siblings().removeClass('hidden');
            }
           else {
               if(isset(response.error)) {    
                   error_message(response.error);
                   return;
               }
               error_message('oops something went wrong');
           }
       });
    });

    $('.script').on('click','#bundle-product-basket-check',function(){
        addProductsToBasket(function () {
            window.lead_script.checkStep($('#bundle-product-basket-check').data('success-step'), function (response) {
                window.lead_script.handleStep(response.step);
            });
        });
    });

    $('.script').on('click','.add-on-product-removed',function(){
        let data = {
            product_id : $(this).data('product_id'),
            quote_id : $('#lead_script').data('quoteid')             
        };

        let element = this;
        makeRequest('/quote/add-on-product-removed/'+data.quote_id, data, function(response){
            if(isset(response.success) && response.success) {    
                // remove the add buttons
                $(element).addClass('hidden');
                $(element).siblings().removeClass('hidden');
                
                success_message();
                return;
            }
           else {
               if(isset(response.error)) {    
                   error_message(response.error);
                   return;
               }
               error_message('oops something went wrong');
           }
       });
    });

    // var selectDependents = function (products, add) {
    //     var element = null;
    //     var more_products = '';
    //     $.each(products, function (i, dependent) {
    //         if(add) {
    //             element = $("[data-product-id='" + dependent + "']");
    //
    //             if($(element).length) {
    //                 element.addClass('selected');
    //                 more_products = $(element).data('dependents').toString().split(',');
    //                 selectDependents(more_products, true);
    //             }
    //         } else {
    //             element = $("[data-product-id='" + dependent + "']");
    //
    //             if($(element).length) {
    //                 element.removeClass('selected');
    //                 more_products = $(element).data('dependencies').toString().split(',');
    //                 selectDependents(more_products, false);
    //             }
    //         }
    //     });
    // };

    var selectDependents = function (products) {
        $.each(products, function (i, product) {
            var element = $("[data-product-id='" + product + "']");
            var title = $(element).find('.product-selection-title').text();

            if($(element).length) {
                if(!$(element).hasClass('selected')) {
                    window.show_additional = true;
                    $(element).addClass('selected');
                }
                $(element).find('.product-selection-banner').html('Selected');
                $(element).find('button').html('Remove Item');

                notification = notification + ' - ' + title;

                var more_products = $(element).data('dependents').toString().split(',');
                if(more_products.length) {
                    selectDependents(more_products);
                }
            }
        });
    }

    var unSelectDependencies = function (products) {
        $.each(products, function (i, product) {
            var element = $("[data-product-id='" + product + "']");
            var title = $(element).find('.product-selection-title').text();
            if($(element).length) {
                if($(element).hasClass('selected')) {
                    window.show_additional = true;
                    $(element).removeClass('selected');
                }
                $(element).find('.product-selection-banner').html('Choose');
                $(element).find('button').html('Add Item');

                notification = notification + ' - ' + title;

                var more_products = $(element).data('dependencies').toString().split(',');
                if(more_products.length) {
                    unSelectDependencies(more_products);
                }
            }
        });
    }

	$('.script')
        .on('click', '.add-on-product-details', function() {
            showLoader();
            window.show_additional = false;
            window.notification = '';
            var title = $(this).find('.product-selection-title').text();
            var products = [];
            var add_text = false;
            var remove = false;
            if($(this).hasClass('selected')) {
                remove = true;
                $(this).removeClass('selected');
                $(this).find('.product-selection-banner').html('Choose');
                $(this).find('button').html('Add Item');

                products = $(this).data('dependencies').toString().split(',');
                window.notification = title + ' has been removed.';
                if(products.length) {
                    add_text = true;
                    unSelectDependencies(products);
                }
            } 
			else {
                $(this).addClass('selected');
                $(this).find('.product-selection-banner').html('Selected');
                $(this).find('button').html('Remove Item');
                products = $(this).data('dependents').toString().split(',');

                window.notification = title + ' has been added.';

                if(products.length) {
                    add_text = true;
                    selectDependents(products);
                }
            }

            if(window.show_additional == true) {
                if (add_text) {
                    var concat = 'relies';
                    var concat_2 = 'it';
                    var concat_3 = 'has';
                    if (products.length > 1) {
                        concat = 'rely';
                        concat_2 = 'they';
                        concat_3 = 'have';
                    }
                    concat_4 = 'added';
                    if(remove) {
                        var concat_4 = 'removed';
                    }
                    var notification_message = window.notification + ' ' + concat + ' on it so ' + concat_2 + ' ' + concat_3 + ' also been ' + concat_4;
                }
            }
            hideLoader();
            success_message(notification_message);
        })

        .on('click','.required-checkbox',function(){
            let data = {
                product_id : $(this).data('product_id'),
                quote_id : $('#lead_script').data('quoteid')
            };
            let element = this;
            makeRequest('/quote/add-on-product-terms-agreed/'+data.quote_id, data, function(response){
                if(isset(response.success) && response.success) {    
                    // if all terms agreed show the next buttons
                    let checked_count = $('.add-on-product-terms').find('input.required-checkbox:checked').length;
                    let checkbox_count = $('.add-on-product-terms').find('.required-checkbox').length;
                    if(checkbox_count === checked_count){
                        $('.script-footer').removeClass('hidden');
                    }
                    return;
                }
               else {
                   if(isset(response.error)) {    
                       error_message(response.error);
                       return;
                   }
                   error_message('oops something went wrong');
               }
           });
        })

        .on('click', '.option-selection', function () {
             let target = $(this).data('target');
             let class_name = '.' + target;

             $(class_name).toggle();
             if(!$(class_name).is(':visible')) {
                 $(this).removeClass('option-selection-active')
             } 
			 else {
                 $(this).addClass('option-selection-active');
             }
        })

        .on('click', '.channel-tab', function () {
            let target = $(this).data('target');

            $('.channel-block').hide();
            $("[data-group='"+target+"']").show();
        });

    if($('#product_channel_sort').length) {
        makeChannelsSortable();
    }
});
