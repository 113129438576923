$(document).ready(function() {

	$('#lead_script').on('click', '.magic-script-play', function() {
		var script = $(this).closest('.script');
		var script_id = $(this).data('script-id');
		var script_object = window[$(script).data('script-name') + '_script']

		if(isset(script_object) && typeof(script_object.changeScript) == 'function') {
			script_object.changeScript(script_id);
		}
		else {
			error_message('Please Tell IT #1238a9');
		}
	});
	
	
	$('#lead_script').on('click', '.magic-expandy-summary-item', function(e) {
		e.stopPropagation();
		var that = $(this);
		var script_id = $(this).data('script-id');
		
		var data = {
			'script_id' : script_id,
			'quote_id' : lead_script.quoteId
		};
		
		var that = this;
		showLoader();
		makeRequest('/script/summary/item', data, function(response){
			if(isset(response.success) && response.success && isset(response.html)){
				$('#summary_details_' + script_id).html(response.html);
				$('#summary_details_' + script_id).show();
				$(that).closest('.summary-item').addClass('expanded-summary-item');
				if($('#summary_details_' + script_id).children().length == 0){
					success_message('no details captured in this script');
				}
				//$(that).addClass('fa-rotate-90');
			}
			else{
				var error = 'failed to get script summary - please refresh and try again';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
			hideLoader();
		});
	});

	$('#lead_script').on('click', '.expanded-summary-item', function() {
		$(this).removeClass('expanded-summary-item');
		var details = $(this).next();

		if($(details).hasClass('summary-details')) {
			$(details).hide();
		}
	});

	$('body').on('click', '#confirm-policy-continue', function () {
		var that = this;
		lead_script.handleForm(function () {

			var data = {};
			data['policy_confirmed'] = ($('.policy-confirm-check').is(':checked')) ? true : false;
			data['quote_id'] = $('#lead_script').data('quoteid');
			data['policy_id'] = $('.policy-translation-container').data('policy-id');

			if($('.policy-confirm-check').is(':checked')) {
				makeRequest("/policies/accept-policy", data, function (response) {
					if (response.success) {
						if (response.success == true) {
							lead_script.handleStep($(that).data('success-step'));
						}
						else {
							error_message('error, turn it off and on again');
						}
					}
					else {
						if (response.error) {
							error_message(response.error);
						} 
						else {
							error_message('error, turn it off and on again');
						}
					}
				});
			}
			else {
				error_message('You must agree and consent.');
			}
		});
	});

	$('.script').on('click', '.show-contact-change-modal-link', function(e) {
		e.preventDefault();
		var contact_id = $(this).data('contact-id');
		var data = {
			'contact_id' : contact_id,
		};
		var script = $(this).closest('.script');
		buildDynamicModal('change_supplier_modal', data, function () {
			$('.change-supplier-modal .select2.tariff-select').select2({
				'width' : '100%',
				'templateResult' : style_expired_tariffs
			});
			$('.change-supplier-modal .confirm-quick-supply-change').data('script-name', $(script).data('script-name'));
		});	
	});

	$('#modal_container').on('change', '.change-supplier-modal #change_supplier_select', function(e) {
		var supplier = $(this).val();
		var contact_id = $(this).closest('.change-supplier-modal').data('contact-id');

		var data = {
			'supplier' : supplier,
			'contact_id' : contact_id,
		};

		var input_container = $('.change-supplier-modal #change_tariff_select').closest('.quick-change-modal-input-container');
		$(input_container).addClass('loading');

		//we need to go get a new list of tariffs
		makeRequest('/api/get-supplier-tariffs', data, function(response) {
			if(isset(response.html)){
				$('.change-supplier-modal #change_tariff_select').replaceWith(response.html);
				$('.change-supplier-modal .select2.tariff-select').select2({
					'width' : '100%',
					'templateResult' : style_expired_tariffs
				});
				success_message('tariffs updated');
			}
			else{
				error_message('failed to get tariffs for this supplier');
			}
			$(input_container).removeClass('loading');
		});
	});

	$('#modal_container').on('click', '.change-supplier-modal .confirm-quick-supply-change', function() {
		var contact_id = $(this).data('contact-id');
		var supply_type = $('.change-supplier-modal #change_supply_type_select').val();
		var supplier_name = $('.change-supplier-modal #change_supplier_select').val();
		var payment_method = $('.change-supplier-modal #change_payment_select').val();
		var tariff_name = $('.change-supplier-modal #change_tariff_select').val();

		var data = {
			'contact_id' : contact_id,
			'supply_type' : supply_type,
			'supplier_name' : supplier_name,
			'payment_method' : payment_method,
			'tariff_name' : tariff_name,
		};

		var script_name = $(this).data('script-name');
		var key = 'lead_script';
		if(isset(window[script_name + '_script'])){
			key = script_name + '_script';
		}

		makeRequest('/api/quick-change-supply', data, function(response) {
			if(response.success){
				success_message('supply updated');
				$('#change_supplier_modal').modal('hide');
				window[key].reloadStep();
				refeshContactProfile();
			}
			else{
				var error = 'failed to update supply';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		});

	});

	$('.check-all input').on('change', function(){
		var checked = $(this).is(':checked');
		$('.script .tick-box input').prop('checked', checked).trigger('change');
	});
});
