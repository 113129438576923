var updateTriggers = function(){
	var data = $('#triggersort').sortable('serialize');
	data = data + '&action-group='+$("input[name='action-group-name']").attr('id');
	$.ajax({
		data: data,
		type: 'POST',
		url: '/action-group/update-triggers',
		dataType: 'json',
		success: function(response) {
			//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var editTrigger = function(trigger_id,callback){
	$.ajax({
		url: '/action-trigger/edit/'+trigger_id,
			success: function(response) {
				if(response.success){
					$('#trigger-edit-container').html(response.html);
					$('body,html').animate({
						scrollTop: 0
					}, 800);
					$('#triggers-edit').on('click','#update-action-trigger',function(){
						var name = $(this).parent().find('input[name="action-trigger-name"]').val();
						var target = $(this).parent().find('input[name="action-trigger-name"]').attr('id').replace('action-trigger-','');
						updateActionTriggerName(target,name,function(target,name){
							$('#trigger-'+target).find('.trigger').html(name);
						});
						$('.update-error').hide(); //this is here just incase the errors are actually visible
						$('.update-success').fadeIn().text('Updated - '+name);
						setTimeout(function(){
							$('.update-success').fadeOut();
						},5000);
						loadScripts();
					});
					callback();
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var testTrigger = function(trigger_id,callback){
	$.ajax({
		url: '/action-trigger/test/'+trigger_id,
		dataType: 'json',
		success: function(response) {
			if(response.success){
				callback(response);
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var runActionForContact = function(data,callback){
	$.ajax({
		url: '/action-group/run',
		type: 'POST',
		data : data,
		dataType: 'json',
		success: function(response) {			
			callback(response);
		},
		error: function (response) {
			console.log(response);
		}
	});
}

var downloadResults = function(trigger_id,callback){
	$.ajax({
		url: '/action-trigger/download/'+trigger_id,
		dataType: 'json',
		success: function(response) {
			if(response.success){
				callback(response);
			}
		},
		error: function (error) {
			//console.log(error);
		}
	});
}

//extract Trigger links from the provided sortable list
var extractTriggerLinks = function (sortableList) {
	//console.log("extracting triggerLinkIDs for uniqueness");
	var triggerLinkIDs = [];
	
	triggerLinkIDs = $(sortableList).sortable( "toArray",{attribute:"data-trigger-rule"});
	return triggerLinkIDs;
};


var extractActionGroupLinks = function (sortableList) {
	
//	console.log("extracting actionGroupLinkIDs for uniqueness");
	var actionGroupLinkIDs = [];
	
	actionGroupLinkIDs = $(sortableList).sortable( "toArray",{attribute:"data-action-group-link-id"});
	return actionGroupLinkIDs;
};


//update the given trigger rule list entry with new trigger rule link id 
var updateTriggerRuleID = function (triggerRule, newTriggerRuleID) {
	triggerRule.attr('data-trigger-rule', newTriggerRuleID);
};

var updateTriggerRuleName = function (triggerRule, newName) {
	triggerRule.find('.group-name').text(newName);
};

//update the given action group link entry with new action group link id and name
var updateActionGroupLinkID = function (actionGroupLink, newActionGroupLinkID) {
	actionGroupLink.attr('data-action-group-link-id', newActionGroupLinkID);
};

var updateActionGroupLinkName = function (actionGroupLink, newName) {
	actionGroupLink.children().eq(1).text(newName);
};

//find the specific trigger rule or orphan 
var getTriggerRuleByID = function (listsToCheck,triggerRuleID) {
	var triggerRuleTarget;
	//for each list to check
	$.each(listsToCheck, function (index, list) {
		//can we find an element id for a trigger rule with no id?
		if ($(list+' li').filter("[data-trigger-rule='"+triggerRuleID+"']").attr('id')) {
			//that's the one
			triggerRuleTarget = $(list+' li').filter("[data-trigger-rule='"+triggerRuleID+"']");
		}
	});

	return triggerRuleTarget;
};

//find the specific action group link or orphan
var getActionGroupLinkByID = function (listsToCheck, actionGroupLinkID) {
	var actionGroupLinkTarget;
//	console.log(listsToCheck);
//	console.log(actionGroupLinkID);
	//for each list to check
	$.each(listsToCheck, function (index, list) {
		//can we find an element id for an action with no id?
//		console.log($(list+' li').filter("[data-action-group-link-id='"+actionGroupLinkID+"']").attr('id'));
		if ($(list+' li').filter("[data-action-group-link-id='"+actionGroupLinkID+"']").attr('id')) {
			//that's the one
			actionGroupLinkTarget = $(list+' li').filter("[data-action-group-link-id='"+actionGroupLinkID+"']");
		}
	});
//	console.log(actionGroupLinkTarget);
	return actionGroupLinkTarget;
};

var sort = function(target) {
		var sortableList = $('#'+target);
		var listitems = $('li', sortableList);
		listitems.sort(function (a, b) {
				return ($(a).text().toUpperCase() > $(b).text().toUpperCase())  ? 1 : -1;
		});
		sortableList.append(listitems);
};

var loadScripts = function(){
	$( "#triggersort, #triggersort2" ).sortable({
		connectWith: ".triggerSortable",
		update: function (event, ui) {
			if($(event.target).attr('id') === 'triggersort'){
				updateTriggers();
				$('#trigger-edit-container').hide();
			}
			else{
				if($(event.target).attr('id') === 'triggersort2'){
					sort('triggersort2');
				}
			}
		}
	}).disableSelection();
	
	$('#action-triggers').on('click','.delete-trigger',function(){
		var element = $(this).parent();
		$('#saved-triggers ul').prepend(element);
		sort('triggersort2');
		updateTriggers();
	});
	
	$("#rulesort1, #rulesort2, #rulesort3" ).sortable({
		connectWith: ".ruleSortable",
		receive: function(event, ui) {

			if (($(event.target).attr('id')!='rulesort3' && $(ui.sender).attr('id')=='rulesort3') || $(ui.sender).attr('id')=='rulesort3') {
				$(ui.item).clone().appendTo(ui.sender);
			} 
			
			//double population logic
			if ($(event.target).attr('id')=='rulesort1') {
				var oppositeRuleCheck = $('#rulesort2').children('li:contains(' + ui.item.text() + ')').length;
			}
			if ($(event.target).attr('id')=='rulesort2') {
				var oppositeRuleCheck = $('#rulesort1').children('li:contains(' + ui.item.text() + ')').length;
			}
			if ($(event.target).attr('id')=='rulesort3') {
				oppositeRuleCheck = 1;
			}
			if (oppositeRuleCheck>0) {
				$(event.target).children('li:contains(' + ui.item.text() + ')').first().remove();
			}
			
			//stop dupes being dropped into a single target
			var dupeCheck = $(event.target).children('li:contains(' + ui.item.text() + ')').length; 
			if (dupeCheck>1) {
				$(event.target).children('li:contains(' + ui.item.text() + ')').first().remove();
			}
				},
		update: function (event, ui) {
			//console.log($(event.target).attr('id'));
			if($(event.target).attr('id') != 'rulesort3'){
				var id_destination_list = $(ui.item).parent().attr('id'); 
				var id_list_event = $(event.target).attr('id'); 
				
				//avoid dupe events
				if (id_destination_list == id_list_event || id_destination_list == "rulesort3") {
					var data = [];
					data.new_trigger_rule = $(ui.item);
					data.new_rule_id = $(ui.item).attr('id').replace('rule-','');
					data.pass_rules = extractTriggerLinks('#rulesort1');
					data.fail_rules = extractTriggerLinks('#rulesort2');
					data.trigger_id = $("input[name='action-trigger-name']").attr('id');
					data.is_deleting = id_destination_list === "rulesort3"?1:0;
					update_trigger_rules(data,function(response){
						if(response.success){
							$('#rules-edit-container').hide();

							if (response.trigger_rule_id) {
								//patching trigger rule orphan or updating existing?
								var listsToCheck = ['#rulesort1','#rulesort2'];

								if (response.trigger_rule_state=='new') {
									//currently an orphan rule, so likely the only one with a 0 in trigger
									var triggerRuleTarget = getTriggerRuleByID(listsToCheck,0);
								} 
								else {
									var triggerRuleTarget = getTriggerRuleByID(listsToCheck,response.trigger_rule_id);
								}	
								if (triggerRuleTarget) {
									//console.log('patching trigger rule link...')
									updateTriggerRuleID(triggerRuleTarget,response.trigger_rule_id);
									//updateTriggerRuleName(triggerRuleTarget,response.new_trigger_rule_name);				
								} 
								else {
									console.log('uh oh - could not find a custom trigger rule link!');
								}
							}
							// should probably unlock the rules pane at this point
						} 
						else{
							// display any errors returned
						}
					});
				}
			}
		}
	}).disableSelection();

};

var updateActionGroupName = function(data){
	$.ajax({
		data: data,
		type: 'POST',
		url: '/action-group/update-name',
		dataType: 'json',
		success: function(response) {
			//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var updateActionTriggerName = function(target,name,callback){
	$.ajax({
		data: {trigger_id : target, trigger_name : name},
		type: 'POST',
		url: '/action-trigger/update-name',
		dataType: 'json',
		success: function(response) {
			callback(target,name);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

var toggle_lock = function (sortable_list, state,rules) {
	//console.log('toggling rules');
	if (!rules) {
		//all in selected sortable list
		$(sortable_list).children().toggleClass('ui-state-disabled', state);
	} 
	else {
		//some in selected sortable list
		$(sortable_list).children(rules).toggleClass('ui-state-disabled', state);
	}
};

	var add_date_picker = function(target){
		
		$(target).daterangepicker({
			locale: {
			format: 'DD/MM/YYYY h:mm A' },
			"singleDatePicker": true,
			"timePicker24Hour": true,
			"timePicker": true,
			"timePickerIncrement": 10,
			"autoApply": true,
			"showCustomRangeLabel": false	
	//		"startDate": "03/08/2017",
	//		"endDate": "02/23/2017"
		},function(start,end,label){
			$(target).val(start.format('D, MMMM, YYYY'));
		});
	};
	
	var build_rule_list = function (lists) {
		
		//build the multiple selector 
		var rulelist ='';
		
		for (var listCounter =0; listCounter < lists.length; listCounter++) {
			var rules = $('#'+lists[listCounter]).children();
			if (rules.length>0) {
				for (var counter =0; counter < rules.length; counter++) {
					//console.log(rules[counter].attr('id'));
					rulelist += rules[counter].attr('id')+',';
				}
			}
		}
		if (rules.length>0) {
			rulelist = rulelist.substr(0, rulelist.length-1);
		} 
		return rulelist;
	};	
	
	var delete_trigger = function(triggerID,trigger_element) {
		$.ajax({
			data: {triggerID: triggerID},
			type: 'POST',
			url: '/action-group/delete-trigger',
			dataType: 'json',
			success: function(response) {
				trigger_element.remove();
			},
			error: function (error) {
				//console.log(error);
			}
		});	
	};	
	
	//visually remove trigger rule link(s) matching deleted rule
	var delete_trigger_rule_links = function(deleted_rule_id) {
		var listsToCheck = ['#rulesort1','#rulesort2'];
		$.each(listsToCheck, function (index, list) {
			//can we find matching trigger rules with this rule id?
			if ($(list+' li').filter('#rule-'+deleted_rule_id)) {
				//that's the one, so visually remove it
				$(list+' li').filter("#rule-"+deleted_rule_id).remove();
			}
		});
	};
	
	//delete a saved rule 
	var delete_saved_rule = function(ruleID,rule_element) {
		$.ajax({
			data: {ruleID: ruleID},
			type: 'POST',
			url: '/action-trigger/delete-saved-rule',
			dataType: 'json',
			success: function(response) {
				rule_element.remove();
				//grab returned rule id - just to be sure we're in sync with server
				var deleted_rule_id = response.rule_deleted;
				if (deleted_rule_id>0) {
					delete_trigger_rule_links(deleted_rule_id);
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});	
	};	
	
	var update_trigger_rules = function(data,callback){
		toggle_lock('#rulesort1',true);
		toggle_lock('#rulesort2',true);
		toggle_lock('#rulesort3',true);
		data.new_rule_id = data.new_rule_id ? data.new_rule_id : false;
		data.is_amending = data.is_amending ? data.is_amending : 0;
		data.existing_trigger_rule_id = data.existing_trigger_rule_id ? data.existing_trigger_rule_id : 0;
				
		$.ajax({
			data: {
				'new_rule_id': data.new_rule_id,
				'existing_trigger_rule_id' : data.existing_trigger_rule_id,
				'pass_rules' : data.pass_rules,
				'fail_rules' : data.fail_rules,
				'trigger_id' : data.trigger_id,
				'is_deleting': data.is_deleting,
				'is_amending': data.is_amending,
				'fields' : data.fields
			},
			type: 'POST',
			url: '/action-group/update-trigger-rules',
			dataType: 'json',
			success: function(response) {
				if(response.success){
					if(response.action == 'edit') {
						//show rule config dialog
						$('#rules-edit-container').html(response.html);
						$('#rules-edit-container').show();
						add_date_picker('#custom-from-date');
						add_date_picker('#custom-to-date');
						$('#rules-edit-container').find('.select2').select2();
						
					}
					else{
						if (response.new_trigger_rule_id && response.new_trigger_rule_id >0) {
							//console.log("about to update the trigger rule id for the newly added trigger rule");
							
						}
						
						toggle_lock('#rulesort1',false);
						toggle_lock('#rulesort2',false);
						toggle_lock('#rulesort3',false);
						// another action as required
						callback(response);
					}
				}
				else{
					// ctach error and do something
				}
			},
			error: function (error) {
				console.log(error);
			}
		});
	};
	
	window.handle_special_fields = function(form_container){
		var form = $(form_container).find('form');
		// deal with window fields
		var active = $('#time-controller').find('.active-tab').attr('id');
		if(active){
			active = active.replace('-toggle','');
			// we may have some time fields to process
			// set window to represent the active tab
			var window = form.find('input[name="window"]');
			if(window.length == 0){
				window = form.find('input[name="future_window"]');
				active = active.replace('future-','');
			}
			window.val(active);
		}
		var form_data = form.serializeArray();
		
		var data = {};
		
		$.each(form_data, function(i, serialized_data){
			data[serialized_data['name']] = serialized_data['value'];
		});
		// now look for any select2 inputs
		$(form).find('select.select2').each(function(){
			var input_name = $(this).attr('name');
			if(isset(data[input_name])){
				var input_data = [];
				$(this).find(':selected').each(function(){
					input_data.push($(this).val());
				});
				data[input_name] = input_data;
			}
		});
		return data;
	};

	var update_group_actions = function(data,callback) {
		$.ajax({
			data: {
				'action_group_id':data.action_group_id,
				'new_action_id': data.new_action_id,
				'existing_group_action_link_id' : data.existing_group_action_link_id,
				'actions' : data.actions,
				'is_deleting': data.is_deleting,
				'is_amending': data.is_amending,
				'fields' : data.fields
			},
			type: 'POST',
			url: '/action-group/update-group-actions',
			dataType: 'json',
			success: function(response) {
				if(response.success){
					if(response.action == 'edit') {
						//switch to correct tab and then show rule config dialog
						$('#trigger-interface-wrapper').hide();
						$('#schedule-interface-wrapper').hide();
						$('#action-interface-wrapper').show();
						$('#actions-edit-container').html(response.html);
						$('#actions-edit-container').show();
						add_date_picker('#custom-from-date');
						add_date_picker('#custom-to-date');
						$('#actions-edit-container').find('.select2').select2();
					}
					else{
						// another action as required
						callback(response);
					}
				}
				else{
					// catch error and do something
				}
			},
			error: function (error) {
			}
		});
	};

let cloneActionGroupListener = function () {
	$('body').on('click', '#confirm_action_group_clone', function () {
		let data = {
			action_group_id: $(this).data('action-group-id'),
			action_group_name: $('#action_group_name').val()
		};

		makeRequest('/action-group/clone', data, function (response) {
			if(isset(response.success) && response.success) {
				success_message();
				window.location='/action-group/' + response.action_group_id;
			} 
			else {
				if(isset(response.error)) {
					error_message(response.error);
					return;
				}
				error_message();
			}
		});
	});
}

$(function() {
	$('#saved-action-groups').on('click', '.clone-action-group', function () {
		let data = {
			action_group_id: $(this).data('action-group-id')
		};
		buildDynamicModal('dynamic_action_group_clone_modal', data, function () {
			cloneActionGroupListener();
		});
	});



	$('#action-group-active').on('click', '#add-action-group', function() {
		var parent = $(this).parent(); 
//		parent.find('span.errors').html('');
		parent.parent().find('.module-error').hide().text('');
		var action_group_name = parent.find('input[name="action-group-name"]').val();
		if(action_group_name.length <= 5) {
			parent.parent().find('.module-error').fadeIn('fast').text('You must enter a name');
		} 
		else {
			$.ajax({ cache: false,
				url: "/action-group/create",
				type:"POST",
				dataType: 'json',
				data: { "action-group-name" : action_group_name },
				success: function (response) {
 					if(response.success === true) {
						//redirects to the create new action group page
						window.location.href = "/action-group/"+response.action_group.id;
					} 
					else {
						if(response.error) {
							parent.parent().find('.module-error').fadeIn('fast').text(response.error);
						}
					}
				},
				error: function (error) {
					//console.log(error);
				}
			});
		};
	});
	
	$('#action-group-active').on('change', '.active-action-group-switch', function() {
		var parent = $(this).parent().parent().parent().data("action_group_id");
		var active = $(this).is(":checked");
		$.ajax({ 
			cache: false,
			url: "/action-group/toggle-active/"+parent,
			type:"POST",
			dataType: 'json',
			data: { "active-state" : active },
			success: function (response) {
				if(response.success === true) {
					if(active) {
						$('.update-error').hide();
						$('.update-success').fadeIn().text('Activated');
						setTimeout(function(){
							$('.update-success').fadeOut();
						},5000);
					} 
					else {
						$('.update-success').hide();
						$('.update-error').fadeIn().text('Deactivated');
						setTimeout(function(){
							$('.update-error').fadeOut();
						},5000);
					}
				} 
				else {
					if(response.error) {
						parent.parent().find('.module-error').fadeIn('fast').text(response.error);
//							parent.parent().find('.module-error').html(response.error);
					}
				}
			},
			error: function (error) {
				console.log(error);
			}
		});
				
	});
	
	$('#action-group-active').on('keyup','.search-action-groups',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-action-groups ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		};
	});
	
	$('.action-trigger-create').on('click', '#add-action-trigger', function() {
		var parent = $(this).parent(); 
//		parent.find('span.errors').html('');
		parent.parent().find('.module-error').hide().text('');
		var action_trigger_name = parent.find('input[name="action-trigger-name"]').val();
		if(action_trigger_name.length <= 5) {
			parent.parent().find('.module-error').fadeIn('fast').text('You must enter a name');
		} 
		else {
			$.ajax({ 
				cache: false,
				url: "/action-trigger/create",
				type:"POST",
				dataType: 'json',
				data: { "action-trigger-name" : action_trigger_name },
				success: function (response) {
 					if(response.success === true) {
						//redirects to the create new action group page
						$('#saved-triggers ul').append(response.html);
						//setTimeout(function(){
							sort('triggersort2');
							parent.find('input[name="action-trigger-name"]').val('');
							$('.update-error').hide();
							$('.update-success').fadeIn().text('Created - '+action_trigger_name);
							setTimeout(function(){
								$('.update-success').fadeOut();
							},5000);
							loadScripts();
						//},500);
						
					} 
					else {
						if(response.error) {
							parent.parent().find('.module-error').fadeIn('fast').text(response.error);
//							parent.parent().find('.module-error').html(response.error);
						}
					}
				},
				error: function (error) {
					console.log(error);
				}
			});
		};
	});
	
	// jquery drag and drop function
	$( "#triggersort, #triggersort2" ).sortable({
		connectWith: ".triggerSortable",
		zIndex: 1004,
		update: function (event, ui) {
			if($(event.target).attr('id') === 'triggersort'){
				var data = $('#triggersort').sortable('serialize');
				data = data + '&action-group='+$("input[name='action-group-name']").attr('id');
				$.ajax({
					data: data,
					type: 'POST',
					url: '/action-group/update-triggers',
					dataType: 'json',
					success: function(response) {
						//console.log(response);
					},
					error: function (error) {
						//console.log(error);
					}
				});
			}
		}
	}).disableSelection();

	//edit an existing trigger
	$('#trigger-saved-container, #action-container').on('click', '.edit-trigger', function() {
		var target = $(this).parent().attr('id').replace('trigger-','');
		editTrigger(target,function(){
			$('#trigger-edit-container').show();
			loadScripts();
		});

		//dusable trigger code follows - disabled for now
		//var source_lists = ['rulesort','rulesort2'];
		//var rule_list = build_rule_list(source_lists);
		//disable_rules('#rulesort3',rule_list);

	});
	
	$('#trigger-interface-wrapper').on('click','.run-action-from-trigger-result-button',function(){
		var data = {};
		data.contact_id = $(this).data('contact_id');
		var url = window.location.href;
		data.action_group_id = url.split('/').pop();
		runActionForContact(data,function(response){
			if(response.success){
				success_message('action ran for contact :'+data.contact_id);
			}
			else{
				//error_message('WHaaa! action didn\'t run for contact :'+data.contact_id);
				$.each(response.summary,function(index,action){
					if(action.outcome != 'success'){
						error_message(action.errors.shift());
						return false;
					}
				});
			}
		});
		
	});
	
	$('#trigger-interface-wrapper').on('click','#simulation-start',function(){
		var trigger_id = $(this).data('trigger_id');
		$('#show-example-data').hide();
		testTrigger(trigger_id,function(response){
			if(response.success){
				$('.update-error').hide();
				$('.update-success').fadeIn().text('Simulation complete');
				setTimeout(function(){
					$('.update-success').fadeOut();
				},5000);
				$.each(response.summary,function(index,value){
					var container = $('#trigger-edit-container').find('*[data-trigger-rule="'+index+'"]');
					container.find('.group-stats').html(value.out+' records comply and took '+value.time+' seconds').show();
				});
				$('#show-example-data .example-data').html(response.example_data);
				$('#show-example-data').show();
			}
		});
	});
	
	$('#trigger-interface-wrapper').on('click','#download-data',function(){
		var trigger_id = $(this).data('trigger_id');
		downloadResults(trigger_id,function(response){
			if(response.success){
				$('.update-error').hide();
				success_message('file generated');

				if(response.file){
					window.location = '/download/file/' + response.file;
				}
				
			}
		});
	});
	
	$('#trigger-interface-wrapper').on('click','.close-section',function(){
		$('#show-example-data .example-data').html('');
		$('#show-example-data').hide();
	});
	
	$('#action-container').on('click','#update-action-group',function(){
		var data = {};
		var container = $(this).closest('.action-group-create');
		data.action_group_name = $(container).find('input[name="action-group-name"]').val();
		data.action_group_type = $(container).find('select[name="action-group-type"]').val();
		data.action_group_priority = $(container).find('select[name="action-group-priority"]').val();
		data.action_group_id = $(container).find('input[name="action-group-name"]').attr('id').replace('action-group-','');
		data.action_group_action_limit = $(container).find('select[name="action-group-limit"]').val();
		
		$('.update-error').hide();
		$('.update-success').fadeIn().text('Updated - '+data.action_group_name);
		setTimeout(function(){
			$('.update-success').fadeOut();
		},5000);
		updateActionGroupName(data);
		loadScripts();
	});
	
	loadScripts();	
	
	
	//delete existing trigger rule
	$('#trigger-edit-container').on('click', '.delete-rule', function() {
		//console.log('deleting an added trigger rule');
		var triggerRuleID = $(this).parent().attr('data-trigger-rule');
		var parentList = $(this).parent().parent().attr('id');
		var triggerRuleTarget = getTriggerRuleByID(['#'+parentList],triggerRuleID);
		triggerRuleTarget.remove();
		
		var data = [];
		//trigger update as if we've moved it
		data.pass_rules = extractTriggerLinks('#rulesort1');
		data.fail_rules = extractTriggerLinks('#rulesort2');
		data.trigger_id = $("input[name='action-trigger-name']").attr('id');
		data.is_deleting = 1;
		update_trigger_rules(data,function(){
			// do something after 
		});
	});
	
	//edit existing trigger rule
	$('#trigger-edit-container').on('click', '.edit-rule', function() {
		//console.log('editing an added trigger rule');
		//lock rules etc.
		toggle_lock('#rulesort1',true);
		toggle_lock('#rulesort2',true);
		toggle_lock('#rulesort3',true);
		
		var data = [];
		//trigger update as if we've moved it
		data.new_trigger_rule = $(this).parent();
		data.new_rule_id = $(this).parent().attr('id').replace('rule-','');
		data.existing_trigger_rule_id = $(this).parent().attr('data-trigger-rule');
		data.pass_rules = extractTriggerLinks('#rulesort1');
		data.fail_rules = extractTriggerLinks('#rulesort2');
		data.trigger_id = $("input[name='action-trigger-name']").attr('id');
		data.is_deleting = 0;
		data.is_amending = 1;
		
		update_trigger_rules(data,function(){
			//things to do
		});
		
		
	});
	
	//delete saved rule
	$('#rules-saved-container').on('click', '.delete-rule', function() {
		//console.log('deleting a saved rule');
		var rule_id = $(this).parent().attr('id').replace('rule-','');
		var ruleElement = $(this).parent();
		
		$.ajax({
			data: {ruleID: rule_id},
			type: 'POST',
			url: '/action-trigger/get-trigger-rule-links',
			dataType: 'json',
			success: function(response) {
				var affectedTriggerRules = response.trigger_rule_links;
				if (affectedTriggerRules) {
					var ruleLinkName = null;
					var affectedTriggerRuleList = "<ol>"; 
					$.each(affectedTriggerRules, function( index, triggerRuleLink ) {
						if (triggerRuleLink.attributes.length>0) {
							$.each(triggerRuleLink.attributes, function (index, triggerRuleLinkAttribute) {
								if (triggerRuleLinkAttribute.name==="name") {
									ruleLinkName = triggerRuleLinkAttribute.pivot.value;
								}
							});
						} 
						//no name attribute found
						if (!ruleLinkName) {
							//grab the rule name
							ruleLinkName = $(this).parent().attr('id');
						}
						affectedTriggerRuleList = affectedTriggerRuleList + '<li><b>'+ruleLinkName+' </b></div>';
					});
					
					affectedTriggerRuleList = affectedTriggerRuleList + "</ol>"; 
					$('#warning-rules-content').html(affectedTriggerRuleList);
					$('#warning-rules-qty').html(affectedTriggerRules.length+' trigger rule(s):');

					$( "#delete-saved-rules-warning-dialog" ).dialog({
						resizable: false,
						height: "auto",
						width: 400,
						modal: true,
						buttons: {
							"Delete this saved rule": function() {
								//delete the rule
								delete_saved_rule(rule_id, ruleElement);
								$( this ).dialog( "close" );
							},
							Cancel: function() {
								$( this ).dialog( "close" );
							}
						}
					});
				} 
				else {
					//delete the rule
					delete_saved_rule(rule_id, ruleElement);
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});
	
	//edit saved rule (rhs, old naming convention; core rules)
	$('#rules-saved-container').on('click', '.edit-rule', function() {
		//console.log('editing a saved rule');
		var rule_id = $(this).parent().attr('id').replace('rule-','');
		var destination = '/action-group/edit-rule/';
		window.location=destination+rule_id;
	});
	
	//edit core action (lhs)
	$('#actions-saved-container').on('click', '.edit-action', function() {
		var action_id = $(this).parent().attr('id').replace('action-','');
		var destination = '/action-group/edit-action/';
		window.location=destination+action_id;
	});
	
	
	$('#trigger-saved-container').on('click', '.delete-trigger', function() {

		var triggerID = $(this).parent().attr('id').replace('trigger-','');
		var triggerElement = $(this).parent();
		$.ajax({
			data: {triggerID: triggerID},
			type: 'POST',
			url: '/action-group/get-trigger-action-groups',
			dataType: 'json',
			success: function(response) {
			
				var affectedActions = response.action_groups;
				if (affectedActions) {
					var affectedActionsList = "<ol>"; 
					$.each(affectedActions, function( index, action ) {
						affectedActionsList = affectedActionsList + '<li><b>'+action.name+' </b>:<i>'+action.description+'</i></div>';
					});
					affectedActionsList = affectedActionsList + "</ol>"; 
					$('#warning-content').html(affectedActionsList);
					$('#warning-qty').html(affectedActions.length+' action group(s):');

					$( "#delete-trigger-warning-dialog" ).dialog({
						resizable: false,
						height: "auto",
						width: 400,
						modal: true,
						buttons: {
							"Delete this trigger": function() {
								//delete the trigger
								delete_trigger(triggerID, triggerElement);
								$( this ).dialog( "close" );
							},
							Cancel: function() {
								$( this ).dialog( "close" );
							}
						}
					});
				} 
				else {
					//delete the trigger
					delete_trigger(triggerID, triggerElement);
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});
	
	$('#actions-saved-container').on('keyup','.search-actions',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-actions ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		 };
	});
	
	// search for actions
	$('#trigger-saved-container').on('keyup','.search-triggers',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-triggers ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		 };
	});
	
	// search for rules while typing
	$('#rules-saved-container').on('keyup','.search-rules',function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-rules ul');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		 };
	});

	//configure rule edit discard button
	$('#rules-edit').on('click','#discard-rule-options',function() {
		var form_container = $('#rules-edit-container').find('form').parent();
		//var form_container = $(this).parent().parent().find('.rules-create');
		var listsToCheck = ['#rulesort1','#rulesort2'];
		
		//are we deleting temporary or existing trigger rule link?
		var triggerRuleID = form_container.data('trigger_rule_id')?form_container.data('trigger_rule_id'):0;
		var triggerRuleTarget = getTriggerRuleByID(listsToCheck,triggerRuleID);
		triggerRuleTarget.remove();
		
		toggle_lock('#rulesort1',false);
		toggle_lock('#rulesort2',false);
		toggle_lock('#rulesort3',false);
		$('#rules-edit-container').hide();
			
		//delete the existing trigger rule link from the server too
		if (triggerRuleID >0) {
			var data = [];
			//trigger update as if we've moved it
			data.new_trigger_rule = $(this).parent();
			data.new_rule_id = $(this).parent().attr('id').replace('rule-','');
			data.existing_trigger_rule_id = $(this).parent().attr('data-trigger-rule');
			data.pass_rules = extractTriggerLinks('#rulesort1');
			data.fail_rules = extractTriggerLinks('#rulesort2');
			data.trigger_id = $("input[name='action-trigger-name']").attr('id');
			data.is_deleting = 1;
			data.is_amending = 0;
		
			update_trigger_rules(data,function(){
				//do something here
			});	
		}
		
	});


	//configure rule edit save button
	$('#rules-edit').on('click','#save-rule-options',function(){
		var form_container = $('#rules-edit-container').find('form').parent();
		//var form_container = $(this).parent().parent().find('.rules-create');
		var data = [];
		data.trigger_id = form_container.data('trigger_id');
		data.new_rule_id = form_container.data('rule_id');
		data.existing_trigger_rule_id = form_container.data('trigger_rule_id');
		data.is_amending = 1;
		data.pass_rules = extractTriggerLinks('#rulesort1');
		data.fail_rules = extractTriggerLinks('#rulesort2');
		data.fields = handle_special_fields(form_container); 
		update_trigger_rules(data,function(response){
			if(response.success){
				$('.update-error').hide();
				$('.update-success').fadeIn().text('Updated rule');
				setTimeout(function(){
					$('.update-success').fadeOut();
				},5000);
				$('#rules-edit-container').hide();
				if (response.trigger_rule_id) {
					//patching trigger rule orphan or updating existing?
					var listsToCheck = ['#rulesort1','#rulesort2'];
					
					if (response.trigger_rule_state=='new') {
						//currently an orphan rule, so likely the only one with a 0 in trigger
						var triggerRuleTarget = getTriggerRuleByID(listsToCheck,0);
					} 
					else {
						var triggerRuleTarget = getTriggerRuleByID(listsToCheck,response.trigger_rule_id);
					}	
					if (triggerRuleTarget) {
						//console.log('patching trigger rule link...')
						updateTriggerRuleID(triggerRuleTarget,response.trigger_rule_id);
						updateTriggerRuleName(triggerRuleTarget,response.new_trigger_rule_name);				
					} 
					else {
						console.log('uh oh - could not find a custom trigger rule link!');
					}
				}
				// should probably unlock the rules pane at this point
			} 
			else{
				// display any errors returned
			}
			// do something useful afterwards
			
		});
		
	});
	
	$('#rules-edit-container, #attribute-table, #actions-edit,#editable_attribute_container, body').on('click', '.time-selectors-1 .btn, .time-selectors .btn', function(e) {
		e.preventDefault();
		var button_clicked = $(this);
		var button_text = $(this).text();
		var button_clicked_container = button_clicked.parent().parent();
		var allow_change = true;
		
		button_clicked_container.siblings().each(function(){
//			if($(this).attr('id') !== button_clicked_container.attr('id')){
				var buttons = $(this).find('.btn');
				var inactive_buttons = $(this).find('.inactive , .disabled');
				if(inactive_buttons.length === buttons.length -1){
					buttons.each(function(){
						if($(this).text() === button_clicked.text() && !$(this).hasClass('inactive') && !$(this).hasClass('disabled')){
							allow_change = false;
						}
					});
				}
//			}
		});
		if(allow_change === true){
			$(button_clicked).parent().find('input[name=predefined-from-date]').val(button_text);
			$(button_clicked).parent().find('input[name=predefined-to-date]').val(button_text);
			$(button_clicked).parent().find('.btn').addClass('inactive');
			$(button_clicked).removeClass('inactive');
			// now go disable the matching button on the other side
			button_clicked_container.siblings().each(function(){
			//	if($(this).attr('id') !== button_clicked_container.attr('id')){
					var buttons = $(this).find('.btn');
					buttons.each(function(){
						if($(this).text() === button_clicked.text()){
							// add your class here
							$(this).addClass('disabled');
						} 
						else {
							if($(this).hasClass('disabled')){
								$(this).removeClass('disabled');
								$(this).addClass('inactive');
							}
							
						}
					});
			//	}
			});
		}
	});
	
	//this code makes the time range selector show an active state
	$('body').on('click', '#time-controller button', function() {           
		$(this).addClass('active-tab').siblings().removeClass('active-tab');           
	});

	$('body').on('click', '#future-time-controller button', function() {           
		$(this).addClass('active-tab').siblings().removeClass('active-tab');           
	});
	
	$('#page-content-wrapper').on('click', '#save_and_preview_rule', function() {
		var parent = $(this).parent().parent();
		var target_rule = $('#rule-edit-wrapper').data('rule_id');
		var data = {};
		data.rule_name = parent.find("input[name*='action-group-name']").val();
		data.rule_type = parent.find("option:selected").val();
		data.attr = [];
		data.edit = [];
		$("input[name='has_attribute']:checked").each( function () {
			data.attr.push($(this).val());
		});
		$("input[name='is_editable']:checked").each( function () {
			data.edit.push($(this).val());
		});
		$.ajax({
			data: data,
			type: 'POST',
			url: '/action-group/update-rule/'+target_rule,
			dataType: 'json',
			success: function(response) {
				if(response.success){
					$('#rule-value-edit').html(response.html);
					$('.update-error').hide();
					$('.update-success').fadeIn();
					setTimeout(function(){
						$('.update-success').fadeOut();
					},5000);
				}
			},
			error: function (error) {
				//console.log(error);
				$('.update-success').hide();
				$('.update-error').fadeIn();
				setTimeout(function(){
					$('.update-error').fadeOut();
				},5000);
			}
		});
		
	});
	$('#rules-saved-container').on('click','#create-new-rule',function(){
		var parent = $(this).parent();
		parent.parent().find('.module-error').hide().text('');	
		var name = $(this).parent().find('input').val();
		if(name.length < 3){
			parent.parent().find('.module-error').fadeIn('fast').text('a new rule needs a longer name');
		}
		else{
			// lets post what we have and setup a new rule
			$.ajax({
			data: {name:name},
			type: 'POST',
			url: '/action-group/create-rule',
			dataType: 'json',
			success: function(response) {
				if(response.success){
					// redirect to the edit rule page
					window.location.href = '/action-group/edit-rule/'+response.rule_id;
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		}
	});
	
	$('#actions-saved-container').on('click','#create-new-action',function(){
		var parent = $(this).parent();
		parent.parent().find('.module-error').hide().text('');	
		var name = $(this).parent().find('input').val();
		if(name.length < 3){
			parent.parent().find('.module-error').fadeIn('fast').text('a new action needs a longer name');
		}
		else{
			// lets post what we have and setup a new rule
			$.ajax({
			data: {name:name},
			type: 'POST',
			url: '/action-group/create-action',
			dataType: 'json',
			success: function(response) {
				if(response.success){
					// redirect to the edit rule page
					window.location.href = '/action-group/edit-action/'+response.action_id;
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		}
	});
	
	$('#action-container').on('click', '.trigger-icon', function() {
		var button = $(this).data('target');
		$(".trigger-icon").removeClass("active-trigger");
		$(this).addClass("active-trigger");
		$('#'+button).siblings().hide();
		$('#'+button).show();
		
		//for now we're only locking but we may want to do more eventually
		var firstDelim = button.indexOf('-');
		var activeTab = button.substring(0, firstDelim);
		switch (activeTab) {
			case 'trigger': toggle_lock('#action-drop-zone',true);
							toggle_lock('#triggersort',false);
							break;
			case 'action':	toggle_lock('#action-drop-zone',false);
							toggle_lock('#triggersort',true);
							break;
			case 'schedule':toggle_lock('#action-drop-zone',true);
							toggle_lock('#triggersort',true);
							break;
		}
	});
	
	$('#rule-value-edit').on('click', '#save_rule_defaults', function() {
		var target_rule = $('#rule-edit-wrapper').data('rule_id');
		var form_container = $('#attribute-table');
		var form = handle_special_fields(form_container);
		console.log(form);
//		$('#rule-value-edit').find('#attribute-table :input').each(function(){
//			form.append($(this).clone());
//		});
		$.ajax({
			data: form,
			type: 'POST',
			url: '/action-group/update-rule-defaults/'+target_rule,
			dataType: 'json',
			success: function(response) {
				$('#rule-value-edit').html(response.html);
				$('.update-error').hide();
				$('.update-success').fadeIn();
				setTimeout(function(){
					$('.update-success').fadeOut();
				},5000);
			},
			error: function (error) {
				$('.update-success').hide();
				$('.update-error').fadeIn();
				setTimeout(function(){
					$('.update-error').fadeOut();
				},5000);
			}
		});
	});
	
	$('#action-value-edit').on('click', '#save_action_defaults', function() {
		var target_action = $('#action-edit-wrapper').data('action_id');
		var form_container = $('#attribute-table');
		var form = handle_special_fields(form_container);
		$.ajax({
			data: form,
			type: 'POST',
			url: '/action-group/update-action-defaults/'+target_action,
			dataType: 'json',
			success: function(response) {
				$('#rule-value-edit').html(response.html);
				$('.update-error').hide();
				$('.update-success').fadeIn().text('Action defaults saved');
				setTimeout(function(){
					$('.update-success').fadeOut();
				},5000);
			},
			error: function (error) {
				//console.log(error);
			}
		});
	});
	
	$('#page-content-wrapper').on('click', '#save_and_preview_action', function() {
		var parent = $(this).parent().parent();
		var target_action = $('#action-edit-wrapper').data('action_id');
		var data = {};
		data.action_name = parent.find("input[name*='action-group-name']").val();
		data.action_type = parent.find("option:selected").val();
		data.attr = [];
		data.edit = [];
		$("input[name='has_attribute']:checked").each( function () {
			data.attr.push($(this).val());
		});
		$("input[name='is_editable']:checked").each( function () {
			data.edit.push($(this).val());
		});
		$.ajax({
			data: data,
			type: 'POST',
			url: '/action-group/update-action/'+target_action,
			dataType: 'json',
			success: function(response) {
				if(response.success){
					$('#rule-value-edit').html(response.html);
					$('.update-error').hide();
					$('.update-success').fadeIn().text('Saved - '+data.action_name);
					setTimeout(function(){
						$('.update-success').fadeOut();
					},5000);
					$('#action-value-edit').html(response.html);
				}
			},
			error: function (error) {
				//console.log(error);
			}
		});
		
	});

	
	// jquery drag and drop function for actions
	$( "#action-drop-zone, #action-grab" ).sortable({
		connectWith: ".actionSortable",
		receive: function(event, ui) {

			//dupe the action being sent from the saved actions panel
			if ($(ui.sender).attr('id')=='action-grab') {
				$(ui.item).clone().appendTo(ui.sender);
			} 
			console.log($(event.target).attr('id'));
			if ($(event.target).attr('id')=="action-grab") {
				$(ui.item).remove();
			}
				},
		update: function (event, ui) {
			//console.log($(event.target).attr('id'));
			if($(event.target).attr('id') != 'action-grab'){
				var id_destination_list = $(ui.item).parent().attr('id'); 
				var id_list_event = $(event.target).attr('id'); 
				
				//avoid dupe events
				if (id_destination_list == id_list_event || id_destination_list == "action-grab") {
					var data = [];
					data.action_group_id = $("input[name='action-group-name']").attr('id');
					data.new_group_action = $(ui.item);
					data.new_action_id = $(ui.item).attr('id').replace('action-','');
					data.actions = extractActionGroupLinks('#action-drop-zone');
					data.is_deleting = id_destination_list === "action-grab"?1:0;
					update_group_actions(data,function(response){
						if(response.success){
							$('#action-edit-container').hide();
							if (response.action_group_link_id) {
								//patching trigger rule orphan or updating existing?
								var listsToCheck = ['#action-drop-zone'];

								if (response.action_group_link_state=='new') {
									//currently an orphan action, so likely the only one with a 0 in actio
									var actionGroupLinkTarget = getActionGroupLinkByID(listsToCheck,0);
								} 
								else {
									var actionGroupLinkTarget = getActionGroupLinkByID(listsToCheck,response.action_group_link_id);
								}	
								if (actionGroupLinkTarget) {
									console.log('patching action group link...');
									updateActionGroupLinkID(actionGroupLinkTarget,response.action_group_link_id);
									updateActionGroupLinkName(actionGroupLinkTarget,response.new_action_group_link_name);				
								} 
								else {
									console.log('uh oh - could not find action group link ('+response.action_group_link_id+')!');
								}
							}
							// should probably unlock stuff at this point
						} 
						else{
							// display any errors returned
						}
					});
				}
			}
		}
	}).disableSelection();
	
	//delete existing action group link
	$('#action-container').on('click', '.delete-action', function() {
		console.log('deleting an added action');
		var actionGroupLinkID = $(this).parent().attr('data-action-group-link-id');
		var parentList = $(this).parent().parent().attr('id');
		var actionGroupLinkTarget = getActionGroupLinkByID(['#'+parentList],actionGroupLinkID);
		actionGroupLinkTarget.remove();
		
		var data = [];
		//actions update as if we've moved it
		data.action_group_id = $("input[name='action-group-name']").attr('id');
		data.new_action_id = $(this).parent().attr('id').replace('action-','');
		data.actions = extractActionGroupLinks('#action-drop-zone');
		data.is_deleting = 1;
		update_group_actions(data,function() {
			// do something after 
		});
	});
	
	//configure action group link edit discard button
	$('#actions-edit').on('click','#discard-action-options',function() {
		var form_container = $('#actions-edit-container').find('form').parent();
		var listsToCheck = ['#action-drop-zone'];
		
		//are we deleting temporary or existing action group link?
		var actionGroupLinkID = form_container.data('action_group_link_id')?form_container.data('action_group_link_id'):0;
		var actionGroupLinkTarget = getActionGroupLinkByID(listsToCheck, actionGroupLinkID);
		actionGroupLinkTarget.remove();
		
		$('#actions-edit-container').hide();
			
		//delete the existing action group link from the server too if it already exists
		if (actionGroupLinkID >0) {
			var data = [];
			//do update as if we've moved it
			
			data.action_group_id = $("input[name='action-group-name']").attr('id');
			data.new_action_id = form_container.data('action_id');
			data.existing_group_action_link_id =  form_container.data('action_group_link_id')?form_container.data('action_group_link_id'):0;
			data.actions = extractActionGroupLinks('#action-drop-zone');
			data.is_deleting = 1;
			data.is_amending = 0;
			
			update_group_actions(data,function(){
				toggle_lock('#action-drop-zone',false);
				toggle_lock('#action-drag',false);
			});	
		
		}
		
	});
	
	//configure action edit save button
	$('#actions-edit').on('click','#save-action-options',function(){
		var form_container = $('#actions-edit-container').find('form').parent();
		var data = [];
		
		data.action_group_id = $("input[name='action-group-name']").attr('id');
		data.existing_group_action_link_id = form_container.data('action_group_link_id');
		data.new_action_id = form_container.data('action_id');
		data.actions = extractActionGroupLinks('#action-drop-zone');
		data.is_deleting = 0;
		data.is_amending = 1;
		data.fields = handle_special_fields(form_container);
		update_group_actions(data,function(response){
			if(response.success){
				$('.update-error').hide();
				$('.update-success').fadeIn().text('Saved');
				setTimeout(function(){
					$('.update-success').fadeOut();
				},5000);
				$('#actions-edit-container').hide();
				//free actions for further editing once edited one updated
				toggle_lock('#action-drop-zone',false);
				toggle_lock('#action-grab',false);
				if (response.action_group_link_id) {
					//patching trigger rule orphan or updating existing?
					var listsToCheck = ['#action-drop-zone'];

					if (response.action_group_link_state=='new') {
						//currently an orphan action, so likely the only one with a 0 in actio
						var actionGroupLinkTarget = getActionGroupLinkByID(listsToCheck,0);
					} 
					else {
						var actionGroupLinkTarget = getActionGroupLinkByID(listsToCheck,response.action_group_link_id);
					}	
					if (actionGroupLinkTarget) {
						console.log('patching action group link...');
						updateActionGroupLinkID(actionGroupLinkTarget,response.action_group_link_id);
						updateActionGroupLinkName(actionGroupLinkTarget,response.new_action_group_link_name);				
					} 
					else {
						console.log('uh oh - could not find action group link ('+response.action_group_link_id+')!');
					}
				}
			} 
			else{
				// display any errors returned
			}
			// do something useful afterwards
			
		});
		
	});
	
	//tedit existing action from lhs
	$('#action-container').on('click', '.edit-action', function() {
		//lock action etc.
		toggle_lock('#action-drop-zone',true);
		toggle_lock('#action-grab',true);
		
		var data = [];
		
		//call action update as if we've moved it
		data.action_group_id =$("input[name='action-group-name']").attr('id');
		data.new_action_id = $(this).parent().attr('id').replace('action-','');
		data.existing_group_action_link_id =  $(this).parent().attr('data-action-group-link-id');
		data.actions = extractActionGroupLinks('#action-drop-zone');
		data.is_deleting = 0;
		data.is_amending = 1;
		update_group_actions(data,function(){
			//things to do afterwards...
		});
	});
	
	//configure action edit cancel button - abandon the edit
	$('#actions-edit').on('click','#cancel-action-options', function() {
		$('#actions-edit-container').hide();
		toggle_lock('#action-drop-zone',false);
		toggle_lock('#action-grab',false);
	});
		
	$('.back-to-previous').click(function(e) {
		e.preventDefault();
		history.back(1);
	});	

	$('#rules-edit').on('click','#get_profile_distinct_values',function(e){
		e.preventDefault();

		
		var container = $(this).closest('form.config-rule-form');
		var value_container = $(this).closest('.rule-group-edit');

		var value_input = $(value_container).find('input');
		
		var target = $(container).find('select[name="target_id"]');

		if(!$(target).length){
			error_message('could not find a profile key select box - is the rule configured properly?');
			return false;
		}
		
		var key_id = $(target).val();

		var data = {
			'key_id' : key_id,
			'type_id' : 1 // contact
		};

		showLoader();
		makeRequest('/profile-potential-values/modal', data, function(response){
			hideLoader();
			if(isset(response.success) && response.success && isset(response.html)){
				createModal('profile_potential_values_modal', response.html);
				$('#profile_potential_values_modal #profile_potential_confirm').off();
				$('#profile_potential_values_modal #profile_potential_confirm').on('click', function(){
					var value = $('#profile_potential_values_modal').find('#profile_potential_value').val();
					console.log(value_input);
					$(value_input).val(value);
				});
			}
			else{
				var error = 'unable to load potential values';
				if(isset(response.error)){
					error = response.error;
				}
				error_message(error);
			}
		})

	});
		
});