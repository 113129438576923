var openManagementReview = function(report_id) {
	showLoader();
	makeRequest('/management-review/open', {'report_id' : report_id}, function(response){
		hideLoader();
		if(isset(response.success) && response.success && isset(response.html)){
			createModal('management_review_report', response.html, function () {
				var container = $('#management_review_report');
				$(container).find('.modal-body').find(':input').attr('disabled', 'disabled');
			});
		}
		else{
			var error = 'whoops';
			if(isset(response.error)){
				error = response.error;
			}
			error_message(error);
		}
	});
};

var completeObjective = function (data) {
	showLoader();
	makeRequest('/management-review/complete-objective', data, function (response) {
		hideLoader();
		if(response.success) {
			success_message();
			return true;
		}
		else {
			if(response.error) {
				error_message(response.error);
			}
			return false;
		}
	});
};

var storeManagementReview = function (data) {
	showLoader();
	makeRequest('/management-review/store', data, function (response) {
		hideLoader();
		if(response.success) {
			success_message();
			return true;
		}
		else {
			if(response.error) {
				error_message(response.error);
			}
			return false;
		}
	});
};

var createNewManagementReview = function (data) {
	showLoader();
	makeRequest('/management-review/create', data, function (response) {
		hideLoader();
		if(response.success) {
			if(response.html) {
				createModal('management_review_report', response.html);
				if($('.range-slider-input').length) {
					$.each($('.range-slider-input'), function (i, input) {
						var value = $(input).val();
						$(input).closest('.range-container').next().find('#slidervalue').html(value);
					});
					$('#edit-new-management-review').show();
				}
				return true;
			}
		}
		else {
			if(response.error) {
				error_message(response.error);
			}
			return false;
		}
	});
};

var setupStoreManagementReview = function (data) {
	//loop over data and store objectives differently,
	// also don't grab the checkboxes for the 'previous' objectives
	var container = $('#management_review_report');
	data['objectives'] = [];
	$.each($(container).find('.modal-body').find(':input'), function (i, input) {
		var label = $(input).data('for');
		var value = $(input).val();
		if(isset(value) && value != '' ) {
			if ($(input).hasClass('sub-objective')) {
				if (!isset(data['objectives'][data['objectives'].length])) {
					data['objectives'][data['objectives'].length] = {"question": value, "complete": 0};
				}
			}
			else {
				if (!isset(data[label])) {
					data[label] = '';
				}
				data[label] = value;
			}
		}
	});
	storeManagementReview(data);
	// data = {
	//		'objectives': {
	//			'question.length': 'answer',
	//			..
	//		}
	//		'question' : 'answer',
	//		..
	// }
};

var newObjectiveInput = function (text = '') {
	var container = $('.objectives-box');
	var length = $(container).find(':input').length;
	var input = '<input class="form-control newly-added sub-objective" type="text" data-for="objective_'+length+'" value=""><br/>';
	$(container).append(input);
	$(container).find('.newly-added').val(text);
	$(container).find('.newly-added').removeClass('newly-added');
};

var renderDatatable = function () {
	$('#management-review-table').DataTable().ajax.reload();
};

$().ready(function() {
	$.fn.dataTable.ext.errMode = 'throw';

	const datatable = $('#management-review-table').DataTable({
		dom: 'flrtiBp',
		ajax: "/get/management-review",
		pageLength: 40,
		ordering: true,
		responsive: true,
		bAutoWidth:false,
		autoWidth: false,
		lengthMenu: [25, 40, 100],
		buttons: [
			'copyHtml5',
			'print',
			{
				extend: 'collection',
				text: 'Export',
				buttons: [
					'csvHtml5',
					'pdfHtml5'
				]
			}
		],
		initComplete : function(settings, json){
			hideLoader();

			if(urlParam('id')){
				if($('.management-review-report-open[data-report-id="'+urlParam('id')+'"]').length){
					$('.management-review-report-open[data-report-id="'+urlParam('id')+'"]').trigger('click');
				}
			}
			this.api().columns([0,1,2,3,4]).every( function () {
				var column = this;
				if($(column.header()).find('span').length){
					var title = $(column.header()).find('span').text();
				}
				else{
					var title = $(column.header()).text();
				}

				var select = $('<br><select class="pdf-hide" style="text-align: center; margin-right:20px; border-radius:4px; color:black; width:100%;"><option></option></select>')
					.on( 'change', function (e) {
						e.stopPropagation();
						var val = $.fn.dataTable.util.escapeRegex(
							$(this).val()
						);

						column
							.search( val ? ''+val+'' : '', true, false )
							.draw();
					} );
				column.data().unique().sort().each( function ( d, j ) {
					if(column.search() === '^'+d+'$'){
						select.append( '<option value=\''+$(d).text()+'\' selected="selected">'+$(d).text()+'</option>' )
					} 
					else {
						if($(d).length){
							if($(d).filter('i.fa-star-o').length || $(d).filter('i.fa-star').length){
								select.append( '<option value=\''+$(d).filter('i.fa-star').length+'\'>'+$(d).filter('i.fa-star').length+'</option>' )
								select.unbind();
								select.on( 'change', function () {
									column
										.search( 'fa-star-o', true, false )
										.draw();
								} );
							}
							else{
								select.append( '<option value=\''+$(d).text()+'\'>'+$(d).text()+'</option>' )
							}
						}
						else{
							select.append( '<option value=\''+d+'\'>'+d+'</option>' )
						}
					}
				} );
				$(column.header()).html('<span>'+title+'</span>');
				$(column.header()).append(select);
			});
		}
	});
	
	if($('input[name="management-review-date-filter"]').length > 0){
		$(function() {
		//date picker for changing compliance report filter range (separate reload, may want to do more with this)
		$('input[name="management-review-date-filter"]').daterangepicker({

		"locale": {
			"format": 'DD/MM/YYYY',
			"separator": "-"
		},
		"linkedCalendars": false,
		"showDropdowns": true
		},
		function(start, end, label) {

			const datatable = $('#management-review-table').DataTable({
				destroy: true,
				dom: 'flrtiBp',
				ajax: "/get/management-review?all=true&start="+start+"&end="+end,
				pageLength: 40,
				ordering: true,
				responsive: true,
				bAutoWidth:false,
				autoWidth: false,
				lengthMenu: [25, 40, 100],
				select: true,
				buttons: [
					'copyHtml5',
					'print',
					{
						extend: 'collection',
						text: 'Export',
						buttons: [
							'csvHtml5',
							'pdfHtml5'   
						]
					}
                ],
                initComplete: function () {
					this.api().columns([0,1,2,3,4]).every( function () {
						var column = this;
						if($(column.header()).find('span').length){
							var title = $(column.header()).find('span').text();
						}
						else{
							var title = $(column.header()).text();
						}
		
						var select = $('<br><select class="pdf-hide" style="text-align: center; margin-right:20px; border-radius:4px; color:black; width:100%;"><option></option></select>')
							.on( 'change', function (e) {
								e.stopPropagation();
								var val = $.fn.dataTable.util.escapeRegex(
									$(this).val()
								);
		
								column
									.search( val ? ''+val+'' : '', true, false )
									.draw();
							} );
						column.data().unique().sort().each( function ( d, j ) {
							if(column.search() === '^'+d+'$'){
								select.append( '<option value=\''+$(d).text()+'\' selected="selected">'+$(d).text()+'</option>' )
							} 
							else {
								if($(d).length){
									if($(d).filter('i.fa-star-o').length || $(d).filter('i.fa-star').length){
										select.append( '<option value=\''+$(d).filter('i.fa-star').length+'\'>'+$(d).filter('i.fa-star').length+'</option>' )
										select.unbind();
										select.on( 'change', function () {
											column
												.search( 'fa-star-o', true, false )
												.draw();
										} );
									}
									else{
										select.append( '<option value=\''+$(d).text()+'\'>'+$(d).text()+'</option>' )
									}
								}
								else{
									select.append( '<option value=\''+d+'\'>'+d+'</option>' )
								}
							}
						} );
						$(column.header()).html('<span>'+title+'</span>');
						$(column.header()).append(select);
					});
                }
			});
		});
		});
	}

    //delete management-review report
    // $('#management-review-table').on('click','.delete-management-review',function(e) {
    //     e.preventDefault();
    //     console.log("Ok, delete button pressed, going to send...");
    //     var management_review_report_id = $(this).data('id');
    //
    //     var message = 'Delete selected management-review report #'+management_review_report_id+'?';
    //     var message = new MessageBox('deletemanagement-review', message);
    //     message.setData({
    //         ajax: {
    //             cache: false,
    //             url: '/management-review/delete',
    //             type: 'POST',
    //             dataType: 'json',
    //             data: {management_reviewReportID: management_review_report_id},
    //             success: function(response) {
    //                 datatable.ajax.reload();
    //             },
    //             error: function (error) {
    //                 //console.log(error);
    //             }
    //         }
    //     });
    //     message.run();
    // });

    $('body').on('click', '.create-new-management-review', function () {
		var data = {
			'all_users': 'firstname'
		};
		buildDynamicModal('dynamic_management_review_modal', data);
	});

    $('body').on('click', '#confirm-new-management-review', function () {
		var data = {
			'review_subject_id': $('#review_subject_id').val()
		};
		createNewManagementReview(data);
	});

	$('body').on('input', '.range-slider-input', function () {
		var value = $(this).val();
		$(this).closest('.range-container').next().find('#slidervalue').html(value);
	});

	$('body').on('click', '.management-review-report-open', function() {
		var report_id = $(this).data('report-id');
		openManagementReview(report_id);
	});

	$('body').on('click', '#edit-new-management-review', function () {
		var that = $(this);
		var data = {};
		data['report_id'] = $(that).data('id');
		setupStoreManagementReview(data);
		renderDatatable();
	});

	$('body').on('click', '#unlock-edit-management-review', function () {
		var container = $('#management_review_report');
		$('#edit-new-management-review').show();
		$.each($(container).find('.modal-body').find(':input'), function (i, input) {
			$(input).removeAttr('disabled');
		});
	});

	$('body').on('click', '#add-objective', function () {
		newObjectiveInput();
	});

	$('body').on('click', '.reuse-objective', function () {
		var text = $(this).parent().data('objective-text');
		newObjectiveInput(text);
	});

	$('body').on('click', '.remove-objective', function () {
		$(this).previous().remove()
	});

	$('body').on('click', '.previous-focus-objective', function () {
		var value = "0";
		if($(this).val() == "0") {
			$(this).val("1");
			value = "1";
		}
		var report_id = $(this).data('report-id');
		var question = $(this).data('question');

		var data = {
			'report_id': report_id,
			'question': question,
			'value': value
		};

		completeObjective(data);
	});
});