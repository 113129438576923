$(document).ready(function () {
	if($('#dispositions-table').length){
		const datatable = $('#dispositions-table').DataTable({
			dom: 'flrtiBp',
			ajax: "/get/dispositions",
			pageLength: 40,
			ordering: true,
			responsive: true,
			bAutoWidth: false,
			autoWidth: false,
			lengthMenu: [25, 40, 100],
			select: true,
			buttons: [
				'copyHtml5',
				'print',
				{
					extend: 'collection',
					text: 'Export',
					buttons: [
						'csvHtml5',
						'pdfHtml5'
					]
				}
			],
			initComplete: function () {
				this.api().columns([2, 3]).every(function () {
					var column = this;
					var title = $(column.header()).html();
					var select = $('<select style="float:right; margin-right:20px; border-radius:4px; color:black;"><option></option></select>')
							.on('change', function () {
								var val = $.fn.dataTable.util.escapeRegex(
										$(this).val()
										);

								column
										.search(val ? '^' + val + '$' : '', true, false)
										.draw();
							});
					column.data().unique().sort().each(function (d, j) {
						if (column.search() === '^' + d + '$') {
							select.append('<option value="' + d + '" selected="selected">' + d + '</option>')
						} 
						else {
							select.append('<option value="' + d + '">' + d + '</option>')
						}
					});
					$(column.header()).html('<span>' + title + '</span>');
					$(column.header()).append(select);
				});

			}
		});

		if($('input[name="o-date-filter"]').length > 0){
			$(function() {
			//date picker for changing order filter range (separate reload, may want to do more with this)
			$('input[name="o-date-filter"]').daterangepicker({

				"locale": {
					"format": 'DD/MM/YYYY',
					"separator": "-"
				},
				"linkedCalendars": false,
				"showDropdowns": true
				},
				function(start, end, label) {

					//console.log("Ok, changing date range...");

					const datatable = $('#dispositions-table').DataTable({
						destroy: true,
						dom: 'flrtiBp',
						ajax: "/get/dispositions?all=true&start="+start+"&end="+end,
						pageLength: 40,
						ordering: true,
						responsive: true,
						bAutoWidth:false,
						autoWidth: false,
						lengthMenu: [25, 40, 100],
						select: true,
						buttons: [
							'copyHtml5',
							'print',
							{
								extend: 'collection',
								text: 'Export',
								buttons: [
									'csvHtml5',
									'pdfHtml5'   
								]
							}
						],

					initComplete: function () {
							this.api().columns([3,5]).every( function () {
							var column = this;
							var title = $(column.header()).find('span').html();
							var select = $('<select style="float:right; margin-right:20px; border-radius:4px; color:black;"><option></option></select>')
								.on( 'change', function () {
									var val = $.fn.dataTable.util.escapeRegex(
										$(this).val()
									);

									column
										.search( val ? '^'+val+'$' : '', true, false )
										.draw();
								} );
							column.data().unique().sort().each( function ( d, j ) {
							if(column.search() === '^'+d+'$'){
								select.append( '<option value="'+d+'" selected="selected">'+d+'</option>' )
							} 
							else {
								select.append( '<option value="'+d+'">'+d+'</option>' )
							}
							} );
							$(column.header()).html('<span>'+title+'</span>');
							$(column.header()).append(select);
						} );

					} 
					});

				});
			});
		}
	}
	if($('#training-dispositions-table').length){
		
		const datatable = $('#training-dispositions-table').DataTable({
			dom: 'flrtiBp',
			ajax: "/get/training/dispositions",
			pageLength: 40,
			ordering: true,
			responsive: true,
			bAutoWidth: false,
			autoWidth: false,
			lengthMenu: [25, 40, 100],
			select: true,
			buttons: [
				'copyHtml5',
				'print',
				{
					extend: 'collection',
					text: 'Export',
					buttons: [
						'csvHtml5',
						'pdfHtml5'
					]
				}
			],
			initComplete: function () {
				this.api().columns([2, 3]).every(function () {
					var column = this;
					var title = $(column.header()).html();
					var select = $('<select style="float:right; margin-right:20px; border-radius:4px; color:black;"><option></option></select>')
							.on('change', function () {
								var val = $.fn.dataTable.util.escapeRegex(
										$(this).val()
										);

								column
										.search(val ? '^' + val + '$' : '', true, false)
										.draw();
							});
					column.data().unique().sort().each(function (d, j) {
						if (column.search() === '^' + d + '$') {
							select.append('<option value="' + d + '" selected="selected">' + d + '</option>')
						} 
						else {
							select.append('<option value="' + d + '">' + d + '</option>')
						}
					});
					$(column.header()).html('<span>' + title + '</span>');
					$(column.header()).append(select);
				});

			}
		});
	}
});
    