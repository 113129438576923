/*Edit a list Item*/
var editProfile = function(profile_id,callback) {
	$.ajax({
		url: '/admin/profile/key/edit/'+profile_id,
			success: function(response) {
				// console.log(response);
				if(response.success){
					$('#edit-profile-container').html(response.html);
					$('body,html').animate({
						scrollTop: 0
					}, 800);
					callback();
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

		
/*Save a list Item*/
var saveProfile = function(profile_data, callback) {
	$.ajax({
		url: '/admin/profile/key/save',
		data: profile_data,
		type: 'POST',
		dataType: 'json',
		success: function(response) {
			if(response.success){
					$('#edit-profile-container').hide();
					success_message('profile updated :'+response.updated.added+' added, '+response.updated.removed+' removed');
					$('body,html').animate({
						scrollTop: 0
					}, 800);
					callback();
			}
				//console.log(response);
		},
		error: function (error) {
			//console.log(error);
		}
	});
};

/*Create a list Item*/
var createProfile = function(name){
	$.ajax({
			data: {name : name},
			type: 'POST',
			url: '/admin/profile/key/create',
			dataType: 'json',
			success: function(response) {
				if(response.success && response.html){
					$('#edit-profile-container').html(response.html);
					$('#edit-profile-container').show();
				}
				else{
					if(response.error){
						error_message(response.error);
					}
					else{
						error_message("whaaaa!");
					}
				}
			},
			error: function (error) {
				//console.log(error);
			}
	});
};
	

var validation_rules = {
	'profile_name' : '.{4, 30}',
	'profile_ident' : '\d{3,4}',
	'product_type_id' : '\d{1,2}'
};
var validate_data = function(data){
	$.each(data, function(key, value){
		if(validation_rules[key]){
			if(!value.match(validation_rules[key])){
				return false;
			}
		}
	});
	return true;
};

$('body').on('click', '.select-option-event', function () {
	var target = $(this).closest('.roles-row').data('content');
	// console.log(target);
	$(this).closest('.module-inner')
		.find(".profile-panel-body[data-target='" + target + "']")
		.find('.edited-icon')
		.show();
});

$( document ).ready(function() {
	//checking for list messages
	

	//editing a list item
	$('#saved-profiles').on('click', '.edit-event', function() {
		//console.log('editing a list');
		var profile_id = $(this).parent().data('profile-id');

		editProfile(profile_id,function() {
			$('.module-error').hide();
			$('#edit-profile-container').show();
		});
	});
	
	//saving a list item
	$('#edit-profile-container').on('click', '#save-profile', function() {
		//console.log('saving list');
		var form = $('#edit-profile-form-data');
		var data = {'profile_key_id':$(form).data('profile-id'),'states':[]};
		$(form).find('input').each(function(){
			var parts = $(this).val().split('-');
			data.states.push({
				'product_type_id': parts[0],
				'role_id':parts[1],
				'state': $(this).is(':checked')});
		});

		//console.log(list_data);
		saveProfile(data, function() {            
			$('#edit-profile-container').hide();
			$('#saved-profiles').load(location.href + " #saved-profiles");
		});
	});
	
	//creating a list item
	$('#profile-wrapper').on('click','#create-profile', function() {
		
		//console.log('creating a list');
		var name = $(this).parent().find('input[name="new-profile-name"]').val();
//		var target = $(this).parent();
//		
		if(name.length < 3) {
			error_message("Please enter a longer name");
		} 
		else {
			createList(name);
		}
	});
		
	
	$('#profile-wrapper').on('keyup', '#new-profile-name', function(){
		var valThis = $(this).val().toLowerCase();
		var target = $(this).parent().parent().find('#saved-profiles');
		if(valThis === ""){
			$(target).find('li').show();
		} 
		else {
			$(target).find('li').each(function(){
				var text = $(this).text();
				var compare = text.toLowerCase();
				(compare.indexOf(valThis) >=0) ? $(this).show() : $(this).hide();
			});
		}
	});

	$('body').on('click', '.expand-roles', function () {
		var target = $(this).data('target');
		var that = $(this);
		$(this).addClass('panel-body-clicked');
		$('.expand-roles').each(function(i, button){
			if($(button).data('target') != target) {
				$(button).removeClass('panel-body-clicked');
			}
		});

		$('.roles-row').each(function(i, row){
			if($(row).data('content') == target) {
				$(row).show();
			}
			else {
				$(row).hide();
			}
		});

		$('body').on('click', '.select-option-event', function () {
			var target = $(this).closest('.roles-row').data('content');
			$(this).closest('.module-inner')
				.find('.profile-panel-body[data-target="' + target + '"]')
				.find('.edited-icon')
				.show();
		});
	});

	$('body').on('click', '.select-all-options', function () {
		var that = $(this);
		var input_count = $(that).closest('.roles-row').find(':input').length;

		if($(that).closest('.roles-row').find(':input:checked').length != input_count) {
			$(that).closest('.roles-row').find(':input').each(function (i, input) {
				$(input).prop('checked', 'checked');
			});
		}
		else {
			$(that).closest('.roles-row').find(':input').each(function (i, input) {
				$(input).prop('checked', false);
			});
		}


		$('body').on('click', '.select-option-event', function () {
			var target = $(this).closest('.roles-row').data('content');
			// console.log(target);
			$(this).closest('.module-inner')
				.find(".profile-panel-body[data-target='" + target + "']")
				.find('.edited-icon')
				.show();
		});
	});
});